export const changeAlpha = (rgba, alphaVal) => {
  if (!rgba.includes('rgba')) {
    return rgba;
  }

  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line no-param-reassign
  rgba = rgba.replace('rgba(', '');
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line no-param-reassign
  rgba = rgba.replace(')', '');
  const [red, green, blue] = rgba.split(',');

  return `rgba(${red}, ${green}, ${blue}, ${alphaVal})`;
};

export const nearlyEqual = (a, b) => {
  const absA = Math.abs(a);
  const absB = Math.abs(b);
  const diff = Math.abs(a - b);

  const EPSILON = 10e-7;

  if (a === b) {
    // shortcut, handles infinities
    return true;
  }

  if (a * b === 0) {
    // a or b or both are zero
    // relative error is not meaningful here
    return diff < EPSILON * EPSILON;
  }

  // use relative error
  return diff / (absA + absB) < EPSILON;
};

export const unixEpochStrToDate = unixEpoch =>
  unixEpoch !== '-1' ? new Date(parseInt(unixEpoch) * 1000) : null;

export const createEmptyRange = () => {
  return {
    base: {
      min: Infinity,
      max: -Infinity,
      valid: false,
    },
    left: {
      min: Infinity,
      max: -Infinity,
      valid: false,
    },
    right: {
      min: Infinity,
      max: -Infinity,
      valid: false,
    },
  };
};

export const mergeRanges = inRanges => {
  const ranges = Array.isArray(inRanges) ? inRanges : [inRanges];

  const base = ranges.map(range => range.base).filter(range => range.valid);
  const left = ranges.map(range => range.left).filter(range => range.valid);
  const right = ranges.map(range => range.right).filter(range => range.valid);

  return {
    base: {
      min: Math.min(...base.map(range => range.min)),
      max: Math.max(...base.map(range => range.max)),
      valid: base.some(range => range.valid),
    },
    left: {
      min: Math.min(...left.map(range => range.min)),
      max: Math.max(...left.map(range => range.max)),
      valid: left.some(range => range.valid),
    },
    right: {
      min: Math.min(...right.map(range => range.min)),
      max: Math.max(...right.map(range => range.max)),
      valid: right.some(range => range.valid),
    },
  };
};

export const getLargestGraphRange = columnGroups => {
  let min = Infinity;
  let max = -Infinity;

  columnGroups.forEach(group => {
    min = Math.min(min, group.range.min);
    max = Math.max(max, group.range.max);
  });

  return {
    min,
    max,
  };
};

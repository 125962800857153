let wakeLock = null;

export async function requestWakeLock() {
  /**
   * Wake lock can be lost when the page loses focus; request again when visible
   */
  function handleVisibilityChange() {
    if (wakeLock != null && document.visibilityState === 'visible') {
      requestWakeLock();
    }
  }

  if (wakeLock == null) {
    try {
      wakeLock = await navigator.wakeLock.request();
      wakeLock.addEventListener('release', () => {
        wakeLock = null;
        document.removeEventListener('visibilitychange', handleVisibilityChange);
      });
      document.addEventListener('visibilitychange', handleVisibilityChange);
    } catch (err) {
      console.error(`${err.name}, ${err.message}`);
    }
  }
}

export async function releaseWakeLock() {
  if (wakeLock) {
    try {
      await wakeLock.release();
    } catch (err) {
      console.error(`${err.name}, ${err.message}`);
    }
  }
}

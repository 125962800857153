import { css } from 'lit';

export const saSettingsStyles = css`
  :host {
    padding: var(--vst-space-m);
    overflow: auto;
  }

  /* the spec params - integration time, etc. */
  .spec-params {
    margin: 0;
  }

  .spec-params__fieldset {
    border: 1px solid var(--vst-color-bg-secondary);
    padding: var(--vst-space-m);
    margin-bottom: var(--vst-space-m);
    border-radius: var(--vst-radius);
  }

  .spec-connected__summary-container {
    background: var(--vst-color-bg-tertiary);
    border: 1px solid var(--vst-color-bg-secondary);
    border-radius: var(--vst-radius);
    padding: var(--vst-space-s);
    margin-block-end: var(--vst-space-2xs);
  }

  .spec-connected__summary {
    display: flex;
    align-items: center;
  }

  .spec-connected__summary-container .link {
    text-decoration: none;
    font-weight: 700;
  }

  .sidebar span {
    white-space: nowrap;
  }

  .sidebar div {
    align-items: baseline;
  }

  .input-group {
    display: flex;
    align-items: baseline;
    inline-size: 6rem;
  }

  .input-group input,
  .input-group select {
    inline-size: 100%;
    margin-inline-end: var(--vst-space-xs);
  }

  .caption {
    white-space: nowrap;
  }

  #spec_params {
    inline-size: max-content;
  }
  .spec-params__fieldset:last-of-type {
    margin-bottom: 0;
  }

  .spec-params__excitation-wavelength {
    margin-top: 0.5rem;
    padding: 0;
  }

  .spec-params__dc-spectrum-modes {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: var(--vst-space-xs);
    padding: var(--vst-space-xs);
    margin: 0;
  }

  .spec-params__dc-spectrum-mode {
    display: grid;
    align-items: center;
    grid-template-columns: auto 1fr;
  }

  .spec-params__legend {
    padding: 0.5rem;
    margin: 0 0 -1.25rem -0.5rem;
    display: inline-block;
    font-weight: bold;
    background: var(--vst-color-bg);
  }

  .spec-params__legend-grid {
    display: grid;
    grid-template-columns: auto 1fr;
    grid-gap: 0.5rem;
  }

  .spec-params__spinner {
    width: 1.25rem;
    height: 1.25rem;
  }

  .spec-params__grid {
    display: grid;
    grid-template-columns: 1fr 4.25rem 1.5rem;
    grid-gap: 0.5rem;
    align-items: center;
  }
  .spec-params__grid--interval {
    margin-top: 0.5rem;
  }

  .spec-params__label {
    padding-right: 0.5rem;
    margin-bottom: 0;
  }

  .spec-params__calibrate {
    grid-column-start: 1;
    grid-column-end: 4;
  }
  .spec-params__calibrate-btn--2-line {
    /*FIXME @include button('secondary'); */
    text-transform: none;
    line-height: 1.2 !important;
  }

  .spec-params[disabled] .spec-params__submit-btn,
  .spec-params__calibrate-btn {
    opacity: 0.2;
    cursor: default;
    pointer-events: none;
  }

  /* the green connection box */
  .spec-connected {
    /* FIXME @include ble-connected-details(); */
    display: block;
    margin-bottom: var(--vst-space-m);
    margin-top: 0;
  }

  .no-spec-connected {
    background: var(--vst-color-warning-bg);
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 3px solid var(--vst-color-warning-ui);
    border-radius: var(--vst-radius);
    padding: var(--vst-space-l);
  }

  .no-spec-connected .heading {
    color: var(--vst-color-warning);
    margin-block-end: var(--vst-space-m);
    font-weight: 700;
  }
`;

export const getScalingRestrictedRange = (
  scalingMode,
  proposedRange,
  currentRange,
  ignoreManualMode,
) => {
  const { min, max } = proposedRange;

  switch (scalingMode) {
    case 'manual_scaling':
      return ignoreManualMode ? proposedRange : currentRange;
    case 'show_zero_scaling':
      if (Math.sign(min) * Math.sign(max) >= 0) {
        return Math.abs(max) >= Math.abs(min) ? { min: 0, max } : { min, max: 0 }; // set min or max to 0 if both are positive or negative
      }
      return proposedRange;
    default:
      // 'automatic_scaling'
      return proposedRange;
  }
};

export const getScalingModeRestrictor =
  getScalingRestrictedRange => (graphState, ignoreManualMode) => (updateValue, updateKey) => {
    const axisMap = {
      baseRange: 'base',
      leftRange: 'left',
      rightRange: 'right',
    };

    const scalingProp = axisMap[updateKey];
    if (graphState && scalingProp) {
      const currentRange = graphState.options[updateKey];
      const scalingMode = graphState.scalingModes[scalingProp];
      if (currentRange && scalingMode) {
        return getScalingRestrictedRange(scalingMode, updateValue, currentRange, ignoreManualMode);
      }
    }

    return updateValue;
  };

export const makeScalingModeRangeRestrictor = getScalingModeRestrictor(getScalingRestrictedRange);

import { LitElement, html } from 'lit';
import { MobxReactionUpdate } from '@adobe/lit-mobx/lib/mixin.js';

import { Requester } from '@components/mixins/vst-core-requester-mixin.js';
import { getText } from '@utils/i18n.js';
import { EventBinder } from '@utils/EventBinder.js';
import { globalStyles } from '@styles/vst-style-global.css.js';
import { repeat } from 'lit/directives/repeat.js';
import { isPrivilegedIframe } from '@utils/isPrivilegedIframe.js';
import { serviceWorkerInitializer } from '@common/utils/serviceWorker/ServiceWorkerInitializer.js';
import vstCoreMeterContainerStyles from './vst-core-meter-container.css.js';
import '@components/vst-ui-meter/vst-ui-meter.js';
import '@components/vst-ui-popover/vst-ui-popover.js';
import '@components/vst-ui-pro-only/vst-ui-pro-only.js';

class VstCoreMeterContainer extends Requester(MobxReactionUpdate(LitElement)) {
  static get properties() {
    return {
      hideMeterButtons: { type: Boolean },
      ignoreServiceEvents: { type: Boolean },
      inContentCreatorBlock: {
        type: Boolean,
        state: true,
      },
      isCollecting: { type: Boolean },
      message: { type: String },
      metersize: {
        type: String,
        reflect: true,
      },
      showAllMeters: { type: Boolean },
      showCheckboxes: { type: Boolean },
      showMessage: { type: Boolean },
    };
  }

  static get styles() {
    return [globalStyles, vstCoreMeterContainerStyles];
  }

  constructor() {
    super();
    this.hideMeterButtons = false;
    this.ignoreServiceEvents = false;
    this.isCollecting = false;
    this.message = '';
    this.metersize = 'large';
    this.showAllMeters = false;
    this.showCheckboxes = false;
    this.showMessage = false;
  }

  get inContentCreatorBlock() {
    return this.$urlHandler?.isContentCreatorSession;
  }

  firstUpdated() {
    const eventBinder = new EventBinder();
    this.eventBinder = eventBinder;

    [
      this.$dataWorld,
      this.$sensorWorld,
      this.$deviceManager,
      this.$toast,
      this.$popoverManager,
      this.$urlHandler,
    ] = this.requestServices([
      'dataWorld',
      'sensorWorld',
      'deviceManager',
      'toast',
      'popoverManager',
      'urlHandler',
    ]);
    // Re-render to get MobxReactionUpdate to capture `dataWorld.meters`
    this.requestUpdate();
  }

  async showSensorActions({ detail: { anchor, sensorId, metersize } }) {
    const { $sensorWorld, $deviceManager, $popoverManager, $toast, $dataWorld } = this;

    const sensor = $sensorWorld.getSensorById(sensorId);

    if (!sensor) {
      console.warn('No sensor associated with this meter');
    } else {
      const device = $deviceManager.getDevice(sensor.deviceId);
      const sensorUnits = sensor.availableUnits;

      let sensorListboxOrientation = 'bottom';
      if (metersize === 'mini') {
        sensorListboxOrientation = 'top';
      } else if (metersize === 'medium') {
        sensorListboxOrientation = 'left';
      }

      // build an object of based on sensor object here
      const sensorActions = [];

      if (sensor.canCalibrate || sensor.canCalibrateGDX) {
        sensorActions.push({
          id: 'calibrate',
          title: getText('Calibrate'),
          selectAction: async () => {
            if (!sensor) {
              return undefined;
            }

            let calibrationElType;
            if (sensor.dataMode.toLowerCase() === 'drop') {
              await import(
                '@components/vst-core-calibration-drop-counter/vst-core-calibration-drop-counter.js'
              );
              calibrationElType = 'vst-core-calibration-drop-counter';
            } else {
              const gdxSensorCalibation = sensor.canCalibrateGDX;
              if (gdxSensorCalibation) {
                await import('@components/vst-core-calibration-gdx/vst-core-calibration-gdx.js');
                calibrationElType = 'vst-core-calibration-gdx';
              } else {
                await import('@components/vst-core-calibration/vst-core-calibration.js');
                calibrationElType = 'vst-core-calibration';
              }
            }

            return $popoverManager.presentDialog(calibrationElType, {
              title: `${getText('Calibrate')} ${sensor.name}`,
              properties: {
                sensor,
              },
            });
          },
        });
      }

      if (sensor.canZero) {
        sensorActions.push({
          id: 'zero',
          title: getText('Zero'),
          selectAction: () => {
            if (!sensor) {
              return Promise.resolve();
            }

            return sensor.zero().then(() => {
              $toast.makeToast(getText('Sensor Zeroed'));
            });
          },
        });
      }

      if (sensor.canReverse) {
        sensorActions.push({
          id: 'reverse',
          type: 'switch',
          title: getText('Reverse'),
          checked: sensor.isReversed,
          checkedChanged: checked => {
            if (sensor && sensor.isReversed !== checked) {
              return sensor.reverse().catch(err => console.error(err));
            }
            return Promise.resolve();
          },
        });
      }

      if (sensor.canZeroOnCollect) {
        sensorActions.push({
          id: 'zero_on_collect',
          type: 'switch',
          title: getText('Reset (Zero) on Collect'),
          checked: sensor.zeroOnCollect,
          checkedChanged: checked => {
            if (sensor && sensor.zeroOnCollect !== checked) {
              return sensor.setZeroOnCollect(checked).catch(err => console.error(err));
            }
            return Promise.resolve();
          },
        });
      }

      if (sensor.hasX4Mode) {
        sensorActions.push({
          id: 'X4_mode',
          type: 'switch',
          title: getText('High-Resolution Mode'),
          checked: sensor.x4Mode,
          checkedChanged: checked => {
            if (sensor && sensor.x4Mode !== checked) {
              return sensor.setModeX4(checked).catch(err => console.error(err));
            }
            return Promise.resolve();
          },
        });
      }

      if (sensorUnits.length > 1) {
        sensorActions.push({
          id: 'sensor_units',
          title: getText('Units'),
          type: 'select',
          options: sensorUnits.map(value => ({ text: value, value })),
          selection: sensor.units,
          selectionChanged: units => {
            const columnGroup = $dataWorld
              .getColumnGroups()
              .find(group => group.sensorId === sensorId);

            return $dataWorld.updateColumnGroup(columnGroup.id, {
              units,
            });
          },
        });
      }

      // TODO: (smart-fan) move this logic to Device (or Sensor) object
      const getDeviceAccesories = function getDeviceAccesories(_device) {
        const gdxCartAccessories = [
          {
            id: 'smart_fan',
            title: 'Smart Fan',
            selectAction: () => {
              // TODO: (smart-fan) make this a floating dialog
              $popoverManager.presentDialog('ga-fan-controls', {
                title: 'Smart Fan',
                properties: {
                  deviceId: _device.id,
                  thrustVal: _device.gdxCartFanThrustVal,
                  isFanAlwaysEnabled: _device.isGdxCartFanAlwaysEnabled,
                },
              });
            },
          },
          {
            id: 'vector',
            title: 'Vector',
            selectAction: () => {},
          },
        ];

        return _device.deviceName.startsWith('GDX-CART') ? gdxCartAccessories : [];
      };

      const deviceAccessories = getDeviceAccesories(device);

      if (device) {
        await import('@components/vst-core-sensor-actions/vst-core-sensor-actions.js');
        $popoverManager.presentPopover('vst-core-sensor-actions', {
          anchor,
          orientation: sensorListboxOrientation,
          properties: {
            header: {
              title: sensor.name,
              subtitle: device.deviceName,
              hasInfo: device.connected,
            },
            battery: device.battery,
            sensorActions,
            deviceAccessories,
          },
          events: ({ completeWorkflow }) => ({
            'show-sensor-info': async () => {
              if (!sensor) {
                return;
              }
              await import('@components/vst-core-device-info/vst-core-device-info.js');
              $popoverManager.presentDialog('vst-core-device-info', {
                title: getText('Device Information'),
                properties: {
                  sensor,
                  device,
                },
              });
              completeWorkflow();
            },
            'accessory-button-hit': event => {
              const id = event.detail;
              deviceAccessories.find(accessory => accessory.id === id).selectAction();
              completeWorkflow();
            },
          }),
        });
      }
    }
  }

  /**
   * determines whether meter should be shown based on type of meter container
   * @param {import('@common/mobx-stores/Meter.js').Meter} meter
   * @returns {Boolean}
   */
  isMeterShown(meter) {
    if (this.ignoreServiceEvents || this.showAllMeters) return true;

    if (this.metersize === 'mini') {
      return meter.isVisibleInBottomBar;
    }
    return meter.isVisibleInMeterPane;
  }

  render() {
    const { meters } = this.$dataWorld ?? { meters: [] };
    const visibleMeters = meters.filter(meter => this.isMeterShown(meter));
    return html`
      ${this.showMessage && !meters.length > 0
        ? html` <div class="message">${this.message}</div> `
        : ''}
      ${repeat(
        visibleMeters,
        meter => meter.id,
        meter =>
          html`
            <!-- TODO: (@ejdeposit) check meterId id change in ia -->
            <vst-ui-meter
              id="meter-${meter.id}"
              .meterId=${meter.id}
              ?showCheckBox=${this.showCheckboxes}
              .isVisible=${meter.isVisibleInMeterPane}
              .value=${meter.value}
              .units=${meter.units}
              .name=${meter.name}
              .sensorId=${meter.sensorInfo.id}
              .color=${meter.color}
              .metersize=${this.metersize}
              ?buttonEnabled=${(!this.hideMeterButtons &&
                !this.isCollecting &&
                this.$dataWorld.sessionType === 'DataCollection' &&
                this.$sensorWorld.isSensorOnline(meter.sensorInfo.id)) ||
              this.metersize !== 'mini'}
              ?showButton=${!this.hideMeterButtons &&
              (!isPrivilegedIframe() || serviceWorkerInitializer.authoringMode)}
              @show-meter-actions-clicked=${this.showSensorActions}
            ></vst-ui-meter>
          `,
      )}
      ${visibleMeters.length === 0 && this.metersize !== 'mini' && !isPrivilegedIframe()
        ? html` <div class="add-meters">
            <vst-ui-pro-only
              preview-position="bottom-right"
              feature-name="${getText('metering on all columns')}"
              .authorizedClickHandler="${() => {
                this.dispatchEvent(
                  new CustomEvent('add-remove-meters-clicked', { bubbles: true, composed: true }),
                );
              }}"
            >
              <button class="btn">${getText('add meters')}</button>
            </vst-ui-pro-only>
          </div>`
        : ''}
    `;
  }
}

customElements.define('vst-core-meter-container', VstCoreMeterContainer);

import { LitElement, html } from 'lit';
import { isFeatureFlagEnabled } from '@services/featureflags/featureFlags.js';
import { getText } from '@utils/i18n.js';

import {
  externalLink,
  welcomeAbsorbance,
  welcomeTransmittance,
  welcomeEmissions,
  welcomeFluorescence,
  welcomeAdvancedFullSpectrum,
} from '@icons';

import { globalStyles } from '@styles/vst-style-global.css.js';
import { saWelcomeStyles } from './sa-welcome.css.js';

import '@components/vst-ui-icon/vst-ui-icon.js';
import '@components/vst-ui-accordion/vst-ui-accordion.js';
import '@components/vst-ui-spinner/vst-ui-spinner.js';

const sessionCategories = [
  {
    spectrumMode: 'absorbance',
    label: getText('Absorbance'),
    icon: welcomeAbsorbance,
    collectionModes: [
      {
        label: getText('vs. Wavelength (Full spectrum)'),
        mode: 'full-spectrum',
      },
      {
        label: getText("vs. Concentration (Beer's Law)"),
        mode: 'events-with-entry',
      },
      {
        label: getText('vs. Time (Kinetics)'),
        mode: 'time-based',
      },
    ],
  },
  {
    spectrumMode: 'transmittance',
    label: getText('Transmittance'),
    icon: welcomeTransmittance,
    collectionModes: [
      {
        label: getText('vs. Wavelength (Full spectrum)'),
        mode: 'full-spectrum',
      },
      {
        label: getText("vs. Concentration (Beer's Law)"),
        mode: 'events-with-entry',
      },
      {
        label: getText('vs. Time (Kinetics)'),
        mode: 'time-based',
      },
    ],
  },
  {
    spectrumMode: 'fluorescence',
    label: getText('Fluorescence'),
    icon: welcomeFluorescence,
    collectionModes: [
      {
        label: getText('vs. Wavelength (Full spectrum)'),
        mode: 'full-spectrum',
      },
      {
        label: getText('vs. Concentration'),
        mode: 'events-with-entry',
      },
      {
        label: getText('vs. Time (Kinetics)'),
        mode: 'time-based',
      },
    ],
  },
  {
    spectrumMode: 'intensity',
    label: getText('Emissions'),
    icon: welcomeEmissions,
    collectionModes: [
      {
        label: getText('vs. Wavelength (Full spectrum)'),
        mode: 'full-spectrum',
      },
      {
        label: getText('vs. Event'),
        mode: 'events-with-entry',
      },
      {
        label: getText('vs. Time'),
        mode: 'time-based',
      },
    ],
  },
];

class SaWelcome extends LitElement {
  static get properties() {
    return {
      _openedCategoryId: { state: true },
      dataCollectionError: {
        type: String,
      },
      spectrumMode: {
        type: String,
        reflect: true,
      },
      collectionMode: {
        type: String,
        reflect: true,
      },
      supportedSpectrumModes: {
        type: Array,
      },
      advancedModeEnabled: {
        type: Boolean,
      },
    };
  }

  constructor() {
    super();
    this.dataCollectionError = '';
    this.collectionMode = ''; // full-spectrum, events-with-entry, time-based
    this.spectrumMode = ''; // absorbance, transmittance, fluorescence, intensity
    this.supportedSpectrumModes = [];
    this.advancedModeEnabled = false;
  }

  static get styles() {
    return [globalStyles, saWelcomeStyles];
  }

  get isStartingSession() {
    return !!this.collectionMode || !!this.spectrumMode;
  }

  _dispatchAddSoftDevice() {
    this.dispatchEvent(new CustomEvent('add-soft-device'));
  }

  _dispatchNewSession(e, { collectionMode, spectrumMode, advanced = false }) {
    this.dispatchEvent(
      new CustomEvent('session-type-selected', {
        detail: {
          collectionMode,
          spectrumMode,
          advanced,
        },
      }),
    );
  }

  _dispatchOpenFile() {
    this.dispatchEvent(new CustomEvent('open-file'));
  }

  _handleAccordionToggled({ detail: open, target }) {
    this._openedCategoryId = open ? target.id : null;
  }

  render() {
    const shouldDisableCategory = mode => {
      if (!this.supportedSpectrumModes) {
        return false;
      }

      return !this.supportedSpectrumModes.includes(mode);
    };

    const shouldShowLoading = mode => {
      // When choosing advanced mode, don't show extra loading indicator for session category
      if (this.spectrumMode === mode && this.advancedModeEnabled) {
        return false;
      }

      return this.spectrumMode === mode && this.isStartingSession;
    };

    return html`
      <div class="main-content">
        <section class="section-new">
          <h2 class="overline" ?disabled="${this.isStartingSession}">
            ${getText('New Experiment')}
          </h2>
          <ul class="stack" gap="l">
            ${sessionCategories.map(
              category => html`
                <li>
                  <vst-ui-accordion
                    id="${category.spectrumMode}"
                    ?disabled=${shouldDisableCategory(category.spectrumMode) ||
                    shouldShowLoading(category.spectrumMode)}
                    .open=${this._openedCategoryId === category.spectrumMode}
                    @accordion-toggled=${this._handleAccordionToggled}
                  >
                    <h3 slot="label" class="inline" gap="s">
                      <div>
                        <div
                          class="session-icon ${shouldShowLoading(category.spectrumMode)
                            ? 'starting-session'
                            : ''}"
                        >
                          <vst-ui-spinner class="loading-spinner"></vst-ui-spinner>
                          <vst-ui-icon
                            class="new-session__icon"
                            .icon="${category.icon}"
                          ></vst-ui-icon>
                        </div>
                        <span class="heading" size="l">${getText(category.label)}</span>
                      </div>
                    </h3>
                    <ul class="new-session__subcat stack" gap="s" slot="content">
                      ${category.collectionModes.map(
                        subcategory => html`
                          <li>
                            <button
                              class="link"
                              type="button"
                              ?disabled="${this.isStartingSession ||
                              shouldDisableCategory(category.spectrumMode)}"
                              @click="${e =>
                                this._dispatchNewSession(e, {
                                  collectionMode: subcategory.mode,
                                  spectrumMode: category.spectrumMode,
                                  advanced: false,
                                })}"
                            >
                              ${getText(subcategory.label)}
                            </button>
                          </li>
                        `,
                      )}
                    </ul>
                  </vst-ui-accordion>
                </li>
              `,
            )}
            <li>
              <div
                class="new-session__category new-session__category--advanced"
                id="advanced"
                ?disabled="${this.isStartingSession && !this.advancedModeEnabled}"
              >
                <button
                  class="new-session__btn"
                  id="advanced"
                  type="button"
                  @click="${e =>
                    this._dispatchNewSession(e, {
                      collectionMode: 'full-spectrum',
                      spectrumMode: this.supportedSpectrumModes
                        ? this.supportedSpectrumModes[0]
                        : 'absorbance',
                      advanced: true,
                    })}"
                >
                  <h3 slot="label" class="inline" gap="s">
                    <div>
                      <div
                        class="session-icon ${this.advancedModeEnabled ? 'starting-session' : ''}"
                      >
                        <vst-ui-spinner class="loading-spinner"></vst-ui-spinner>
                        <vst-ui-icon
                          class="new-session__icon"
                          .icon="${welcomeAdvancedFullSpectrum}"
                        ></vst-ui-icon>
                      </div>
                      <span class="heading" size="l">${getText('Advanced Full Spectrum')}</span>
                    </div>
                  </h3>
                </button>
              </div>
            </li>
          </ul>
        </section>

        <div class="stack" gap="l">
          <section>
            <h2 class="overline" ?disabled="${this.isStartingSession}">
              ${getText('Open Saved File')}
            </h2>

            <button
              class="btn"
              id="open_local_exp_btn"
              variant="outline"
              ?disabled="${this.isStartingSession}"
              @click="${this._dispatchOpenFile}"
            >
              ${getText('Choose File')}
            </button>

            ${isFeatureFlagEnabled('ff-sa-expose-soft-device')
              ? html`
                  <button
                    class="btn"
                    id="add_soft_device_btn"
                    variant="outline"
                    margin="block-start-xs"
                    ?disabled="${this.isStartingSession}"
                    @click="${this._dispatchAddSoftDevice}"
                  >
                    ${getText('Add Soft Spec')}
                  </button>
                `
              : html``}
          </section>

          <section>
            <h2 class="overline" ?disabled="${this.isStartingSession}">
              ${getText('From Vernier.com')}
            </h2>
            <ul class="vernier-links stack" gap="2xs">
              <li>
                <a
                  class="link"
                  href="https://www.vernier.com/sa4-manual"
                  target="_blank"
                  ?disabled="${this.isStartingSession}"
                >
                  <vst-ui-icon
                    class="vernier-links__icon"
                    .icon="${externalLink}"
                    color="${window.matchMedia('(prefers-color-scheme: dark)').matches
                      ? 'var(--vst-color-light-100)'
                      : 'var(--vst-color-brand)'}"
                  ></vst-ui-icon>
                  <span>${getText('User Manual')}</span></a
                >
              </li>
              <li>
                <a
                  class="link"
                  href="https://www.vernier.com/support/sa4/#experiments"
                  target="_blank"
                  ?disabled="${this.isStartingSession}"
                >
                  <vst-ui-icon
                    class="vernier-links__icon"
                    .icon="${externalLink}"
                    color="${window.matchMedia('(prefers-color-scheme: dark)').matches
                      ? 'var(--vst-color-light-100)'
                      : 'var(--vst-color-brand)'}"
                  ></vst-ui-icon>
                  <span>${getText('Sample Data')}</span>
                </a>
              </li>
              <li>
                <a
                  class="link"
                  href="https://www.vernier.com/products/sensors/spectrometers/"
                  target="_blank"
                  ?disabled="${this.isStartingSession}"
                >
                  <vst-ui-icon
                    class="vernier-links__icon"
                    .icon="${externalLink}"
                    color="${window.matchMedia('(prefers-color-scheme: dark)').matches
                      ? 'var(--vst-color-light-100)'
                      : 'var(--vst-color-brand)'}"
                  ></vst-ui-icon>
                  <span>${getText('Vernier Spectrometers')}</span>
                </a>
              </li>
            </ul>
          </section>
          <vst-ui-logo-vernier class="vernier-logo" id="vernier_logo"></vst-ui-logo-vernier>
        </div>
      </div>
    `;
  }
}

customElements.define('sa-welcome', SaWelcome);

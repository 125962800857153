import { html, css } from 'lit';

export const truncateText = ({ strEl, strEls, str, maxWidth }) => {
  const truncatedStr = str.replace(/\s/g, '');
  if (maxWidth && strEl) strEl.style.setProperty('max-inline-size', maxWidth); // Table

  // ? I am not sure why the element needs to query one selector in the table but has to querySelectorAll in the graph but until we find an alternate solution I have to just do different things for each
  if (strEl) {
    // Table
    strEl.style.setProperty('overflow', '');
    strEl.classList.remove('str--truncate');
    strEl.classList.add('str-container--truncate');
  } else if (strEls) {
    // Graph
    strEls.forEach(el => {
      el.classList.add('str-container');
      el.classList.add('str-container--truncate');
      el.style.setProperty('overflow', '');
      el.classList.remove('str--truncate');
    });
  }

  if (/[a-zA-Z]+\d+(\.\d+)?velocity$/i.test(truncatedStr)) {
    const text = truncatedStr.match(/[a-zA-Z]+\d+(\.\d+)?velocity$/i)[0];

    const name = text.match(/^[a-zA-Z]+/i)[0];
    const num = text.match(/\d+(\.\d+)?/)[0];
    const velocity = text.match(/velocity$/i)[0];

    return html`
      <span class="str__inner--truncate str--truncate">${name}</span>
      <span>${num}</span>
      <span class="str__inner--truncate str--truncate">${velocity}</span>
    `;
  }

  if (/\d+(\.\d+)?$/.test(truncatedStr)) {
    const name = truncatedStr.match(/[a-zA-Z]+/g)[0];
    const num = truncatedStr.match(/\d+(\.\d+)?$/)[0];

    return html`
      <span class="str__inner--truncate str--truncate"> ${name} </span>
      <span>${num}</span>
    `;
  }

  if (strEl) {
    strEl.style.setProperty('overflow', 'hidden');
    strEl.classList.add('str--truncate');
  }

  return html`${truncatedStr}`;
};

export const truncateTextStyles = css`
  .str-container {
    position: relative;
  }

  .str-container--truncate {
    display: flex;
  }

  .str__inner--truncate {
    position: relative;
    overflow: hidden;
  }

  .str--truncate::after {
    display: block;
    font-weight: normal;
    content: '';
    position: absolute;
    bottom: 0;
    right: 0;
    background: var(--vst-color-bg-secondary);
  }
`;

import { css } from 'lit';
import { button } from '../styles/index.js';

export default css`
  .body-content {
    width: 30rem;
    max-inline-size: 100%;
    margin-block-end: var(--vst-space-l);
  }

  .btn-container {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-end;
  }

  .btn {
    margin: 0 var(--vst-space-xs) 0 0;
  }
  .btn:last-child {
    margin-inline-end: 0;
  }

  ${button(css`.btn-secondary`, 'secondary')}
  ${button(css`.btn-tertiary`, 'tertiary')}
  ${button(css`.btn-error`, 'error')}

  .btn-left {
    margin-inline-end: auto;
  }

  .margin-inline-end-0 {
    margin-inline-end: 0;
  }
`;

import { applyVerticalScroll } from './_internal/applyVerticalScroll.js';
import { applyHorizontalScroll } from './_internal/applyHorizontalScroll.js';
import { createScrollState } from './_internal/createScrollState.js';
import { createGridLayout } from './_internal/createGridLayout.js';
import { createRowCache } from './_internal/createRowCache.js';
import { createHeaderCache } from './_internal/createHeaderCache.js';
import { createRenderAccumulator } from './_internal/createRenderAccumulator.js';
import { createGridApi } from './_internal/createGridApi.js';

const noop = () => {};

export const createGrid = ({
  gridEl,

  // must be duplicated in css
  padding = 0.3125,
  borderSize = 0.0625,
  sidebarWidth = 3.75,
  scrollerSize = 0.9375,
  rowHeight = 2,
  cellWidth = 6.25,

  prepareHeaders = [],
  prepareRowSidebar = () => noop,
  prepareGridCell = () => noop,
  prepareGrid = noop,
  minRows: minRowsConfig = 10,

  onScroll: onScrollHandler = noop,
} = {}) => {
  // define variables
  const minRows = minRowsConfig > 1 ? minRowsConfig : 1;
  const totalHeaderRows = prepareHeaders.length;

  //
  /// /
  /// / get helper objects that each define their functions and properties
  const accumulator = createRenderAccumulator();

  const gridLayout = createGridLayout({
    rowHeight,
    borderSize,
    sidebarWidth,
    scrollerSize,
    padding,
    cellWidth,
    totalHeaderRows,
    gridEl,
    prepareGrid,
  });

  const rowCache = createRowCache({
    gridLayout,
    prepareGridCell,
    prepareRowSidebar,
    minRows,
  });

  const headerCache = createHeaderCache({
    gridLayout,
    prepareHeaders,
  });

  const scrollState = createScrollState({ onScrollHandler });
  /// / get helper objects
  //

  // add 'scroll' event listeners to the grid body and header ELs that handle scrolling by calling
  // the passed in scrollState function
  applyHorizontalScroll({
    scrollState,
    gridLayout,
  });

  // add a 'scroll' event listener to gridLayout.gridBodyViewportEl. Note that 'scroll' event is
  // handled by calling scrollState(), rowCache.rerender(...), and then accumulator.applyChanges()
  applyVerticalScroll({
    scrollState,
    gridLayout,
    rowCache,
    headerCache,
    accumulator,
  });

  // pass in new resizeHandler function which is called by within gridLayout
  // whenever the gridEL element is resized
  gridLayout.onResize((newItemsPerScreen, topItem) => {
    rowCache.resize(accumulator, newItemsPerScreen, topItem, headerCache.columnDataList);
    accumulator.applyChanges();
  });

  const api = createGridApi({ gridLayout, rowCache, headerCache, scrollState });

  // initialize the grid and row cache upon startup
  rowCache.initCache(accumulator, gridLayout.itemsPerScreen, headerCache.columnDataList);
  rowCache.changeTotalRows(accumulator, minRows, headerCache.columnDataList);
  accumulator.applyChanges();
  //

  return api;
};

/* eslint-disable lit-a11y/click-events-have-key-events */
import { LitElement, html, css, nothing } from 'lit';
import { repeat } from 'lit/directives/repeat.js';
import { when } from 'lit/directives/when.js';
import { createRef, ref } from 'lit/directives/ref.js';
import { ContextProvider } from '@lit-labs/context';
import { isEqual, mapKeys, throttle, uniqBy } from 'lodash-es';
import { computed, makeObservable } from 'mobx';

import { isFeatureFlagEnabled } from '@services/featureflags/featureFlags.js';

import { Requester } from '@components/mixins/vst-core-requester-mixin.js';
import { conditionalTemplate } from '@components/directives/conditionalTemplate.js';
import { truncateText, truncateTextStyles } from '@utils/truncateText.js';
import { GraphGestures } from '@utils/GraphGestures.js';
import { getText } from '@utils/i18n.js';
import { mergeRanges, getLargestGraphRange, createEmptyRange } from '@utils/helpers.js';
import { getTraceColor } from '@utils/traceColor/getTraceColor.js';
import { EventBinder } from '@utils/EventBinder.js';
import { rgb2hex } from '@utils/colorHelpers.js';
import { nextRepaintComplete } from '@utils/nextRepaintComplete.js';

import { vstPresentationStore } from '@stores/vst-presentation.store.js';
import { vstAuthStore } from '@stores/vst-auth.store.js';
import { ObservableProperties } from '@mixins/vst-observable-properties-mixin.js';

import { sprintf } from '@libs/sprintf.js';

import { promptDeleteColumn } from '@common/utils/promptDeleteColumn.js';
import { promptDeleteDataSet } from '@common/utils/promptDeleteDataSet.js';

import { Trace } from '@components/vst-core-graph/Trace.js';
import { Graph } from '@components/vst-core-graph/Graph.js';

import {
  arrowRight,
  graphOptions,
  info,
  zoomAutoscale,
  zoomIn,
} from '@components/vst-ui-icon/index.js';
import { globalStyles } from '@styles/vst-style-global.css.js';
import { menuStyles } from '@styles/vst-style-menu/vst-style-menu.css.js';
import { ErrorBarType } from '@api/common/ColumnGroup.js';
import { ColumnDataType } from '@api/common/Column.js';
import graphOptionsContext from '@common/contexts/graph-options-context.js';
import { Actor } from '@common/stores/actions.js';
import vstCoreGraph from './vst-core-graph.css.js';

import '@components/vst-ui-graph-context-menu/vst-ui-graph-context-menu.js';
import '@components/vst-ui-popover/vst-ui-popover.js';
import '@components/vst-ui-icon/vst-ui-icon.js';
import '@components/vst-ui-switch/vst-ui-switch.js';
import '@components/vst-ui-tooltip/vst-ui-tooltip.js';
import '@components/vst-style-tooltip/vst-style-tooltip.js';
import '@components/vst-ui-pro-info/vst-ui-pro-info.js';

export default class VstCoreGraph extends Requester(ObservableProperties(LitElement)) {
  static get observableProperties() {
    return {
      colorMode: vstPresentationStore,
      authorized: vstAuthStore,
    };
  }

  static get properties() {
    return {
      _contextMenuLeft: { state: true },
      accessibilityScale: { type: Number },
      activeAxis: { type: String },
      autoscalePadding: { type: Number },
      axisScalingModes: { type: Object },
      baseColumnId: { type: String },
      baseColumnLabel: { type: String },
      baseUnits: { type: String },
      containsCategorical: { type: Boolean },
      dataManager: { type: Object },
      defaultBottomAxisName: { type: String },
      defaultVerticalAxisName: { type: String },
      disableAxisLabelBtns: { type: Boolean },
      disableAxisTranslate: { type: Boolean },
      disableGraphTools: { type: Boolean },
      disableMenu: { type: Boolean },
      enableFft: { type: Boolean },
      enableHistogram: { type: Boolean },
      hidden: { type: Boolean },
      hideGraphActionBtns: { type: Boolean },
      interpolateEnabled: { type: Boolean },
      isConfiguratorView: { type: Boolean },
      // TODO: we turn on and off various graph features based on this, but we
      // don't really want core graph to know too much about stuff like FFTs et
      // al. It would be better for the parent component to use our other
      // properties to set up the graph to its liking.
      isInFFTMode: { type: Boolean },
      isInHistogramMode: { type: Boolean },
      isSessionEmpty: { type: Boolean },
      isLegendDisabled: { type: Boolean },
      isLegendVisible: { type: Boolean, reflect: true },
      isMiniGraphDisabled: { type: Boolean, reflect: true },
      isMiniGraphVisible: { type: Boolean, reflect: true },
      labelSize: { type: Number },
      leftColumnIds: { type: Array },
      leftColumnLabels: { type: Array },
      miniGraphSupported: { type: Boolean, reflect: true },
      options: { type: Object },
      rawTracePairs: { type: Array },
      rightAxisEnabled: { type: Boolean, reflect: true },
      rightColumnIds: { type: Array },
      rightColumnLabels: { type: Array },
      selectionsById: { type: Object },
      shouldTruncateLeftLabels: { type: Boolean },
      shouldTruncateRightLabels: { type: Boolean },
      showWavelengthRainbow: { type: Boolean },
      splitSelectionsData: { type: Object },
      tangentEnabled: { type: Boolean },
      tempSelection: { type: Object },
      traces: { type: Array },
      udmId: { type: Number },
    };
  }

  constructor() {
    super();

    this._contextMenuLeft = '0';
    this._deferredErrorBarColumnIds = new Set();
    this._fftProOnlyPopoverRef = createRef();
    this._addGraphManualFitTracesPopoverRef = createRef();
    this._graphOptionsContextProvider = new ContextProvider(this, graphOptionsContext);
    this._histogramProOnlyPopoverRef = createRef();
    this._manualFitProOnlyPopoverRef = createRef();
    this._restoreAllProOnlyPopoverRef = createRef();
    this._strikethroughProOnlyPopoverRef = createRef();
    this._throttleUpdate = throttle(allowSpecialAutoscale => {
      this._internalUpdate(allowSpecialAutoscale);
    }, 100);
    this.accessibilityScale = 1;
    this.disableAxisTranslate = false;
    this.disableAxisLabelBtns = false;
    this.disableGraphTools = false;
    this.disableMenu = false;
    this.enableFft = false;
    this.enableHistogram = false;
    this.hideGraphActionBtns = false;
    this.labelSize = 0.67;
    this.leftColumnIds = [];
    this.rightColumnIds = [];
    this.isLegendVisible = false;
    this.isMiniGraphVisible = false;
    this.isSessionEmpty = true;
    this.miniGraphSupported = false;
    this.isMiniGraphDisabled = false;
    this.interpolateEnabled = false;
    this.tangentEnabled = false;
    this.tempSelection = false;
    this.udmId = 0;
    this.leftColumnLabels = [];
    this.rightColumnLabels = [];
    this.shouldTruncateLeftLabels = false;
    this.shouldTruncateRightLabels = false;
    this.showWavelengthRainbow = false;
    this.pendingGraphUpdate = {};
    this.pendingTracesUpdate = [];
    this.baseColumnGroup = null;
    this.baseColumn = null;
    this.autoscalePadding = 0.1;
    this.fitToNewData = false;
    this.scaleLargerToNewData = false;
    this.traces = [];
    this.defaultLineWeight = 2;
    this.isInFFTMode = false;
    this.isInHistogramMode = false;
    this.containsCategorical = false;
    this.isLegendDisabled = false;
    this.isConfiguratorView = false;

    this.fitTraces = [];
    this.integralTraces = [];
    this.manualFitTraces = [];
    this.tangentTraces = [];
    this.peakIntegralTracesData = [];

    this.getNextTraceColor = (/* preferredColor */) => '#000000'; // overwritten by setGetTraceColor()

    this._useResizeObserver = false;

    if ('ResizeObserver' in window) {
      this._useResizeObserver = true;
    }

    makeObservable(this, {
      manualFits: computed,
    });
  }

  get _manualFitTargets() {
    return this.getLeftTraces().map(trace => {
      const dataSet = this.$dataWorld
        .getDataSets()
        .find(
          ds =>
            ds.columnIds.includes(trace.yColumn.id) && ds.columnIds.includes(trace.baseColumn.id),
        );
      return {
        name: `${dataSet.name}|${trace.yColumn.name}`,
        trace,
      };
    });
  }

  _deleteTempSelection() {
    if (this.tempSelection) {
      this.dispatchEvent(new CustomEvent('selection-deleted', { detail: this.tempSelection.id }));
    }
  }

  _handleAddManualFitClicked(event) {
    if (this.leftColumnIds.length > 1) {
      this._addGraphManualFitTracesPopoverRef.value.show();
    } else {
      const [trace] = this.getLeftTraces();
      if (trace) this.dispatchGraphToolsEvent(event, { type: 'manual-fit', trace });
    }
  }

  _handleColumnGroupAdded(columnGroup) {
    if (columnGroup.errorBarColumnId)
      this._deferredErrorBarColumnIds.add(columnGroup.errorBarColumnId);

    columnGroup.on('error-bar-type-changed', this._handleErrorBarParametersChanged, this);
    columnGroup.on('error-bar-value-changed', this._handleErrorBarParametersChanged, this);
    columnGroup.on('error-bar-column-id-changed', ({ columnId: columnGroupId }) => {
      if (columnGroupId) this._deferredErrorBarColumnIds.add(columnGroupId);
      else this._deferredErrorBarColumnIds.delete(columnGroupId);
      this._handleErrorBarParametersChanged();
    });
  }

  _handleErrorBarParametersChanged() {
    this.updatePlotData({ allowSpecialAutoscale: true });
  }

  _handleDataSetDeleted(event) {
    const { detail: dataset } = event;

    promptDeleteDataSet.call(this, this.$dataWorld, {
      id: dataset.id,
      name: dataset.name,
    });
  }

  /**
   * Calculate error value
   *
   * @param {number} value Value
   * @param {import('@api/common/ColumnGroup.js').ErrorBarType} errorBarType Error bar type
   * @param {number} errorBarValue Error bar value; meaning is dependent on error bar type
   * @param {number} errorColumnValue Error bar value to use when a column is given
   * @returns {number[]} An array with the min and max error bar values in that order
   */
  static calculateErrorValue(value, errorBarType, errorBarValue, errorColumnValue) {
    let errorValue;
    switch (errorBarType) {
      case ErrorBarType.FIXED: {
        const errorBarValueNumber = Math.abs(parseFloat(errorBarValue));
        if (Number.isNaN(errorBarValueNumber)) break;
        errorValue = [value - errorBarValueNumber, value + errorBarValueNumber];
        break;
      }
      case ErrorBarType.PERCENTAGE: {
        const errorBarValueNumber = Math.abs(parseFloat(errorBarValue));
        if (Number.isNaN(errorBarValueNumber)) break;
        const percentage = Math.abs((value * errorBarValueNumber) / 100);
        errorValue = [value - percentage, value + percentage];
        break;
      }
      case ErrorBarType.COLUMN: {
        const errorBarValueNumber = Math.abs(errorColumnValue);
        errorValue = [value - errorBarValueNumber, value + errorBarValueNumber];
        break;
      }
      default:
        break;
    }
    return errorValue;
  }

  get resized() {
    return new Promise(resolve => {
      if (!this.resizing) {
        resolve();
        return;
      }
      this.addEventListener(
        'core-graph-resize-finished',
        () => {
          resolve();
        },
        { passive: true, once: true },
      );
    });
  }

  /**
   * Check whether the given graph tools event type requires Pro
   * @param {string} graphToolsEventType Graph tools event type
   * @param {HTMLElement} target Target
   * @return {boolean} Whether to continue or cancel
   */
  _continueOrShowProOnlyMessage(graphToolsEventType, target) {
    if (this.authorized) return true;

    switch (graphToolsEventType) {
      case 'fft':
        this._fftProOnlyPopoverRef.value.for = target;
        this._fftProOnlyPopoverRef.value.show();
        break;
      case 'histogram':
        this._histogramProOnlyPopoverRef.value.for = target;
        this._histogramProOnlyPopoverRef.value.show();
        break;
      case 'restore-all-data':
        this._restoreAllProOnlyPopoverRef.value.for = target;
        this._restoreAllProOnlyPopoverRef.value.show();
        break;
      case 'strikethrough':
        this._strikethroughProOnlyPopoverRef.value.for = target;
        this._strikethroughProOnlyPopoverRef.value.show();
        break;
      case 'manual-fit':
        this._manualFitProOnlyPopoverRef.value.for = target;
        this._manualFitProOnlyPopoverRef.value.show();
        break;
      default:
        return true;
    }

    return false;
  }

  createRenderRoot() {
    const root = super.createRenderRoot();
    // Catch graph tools events from children, e.g. vst-ui-graph-context-menu;
    // this needs access to the render root to avoid Lit retargeting events from
    // the shadow DOM. See https://lit.dev/docs/components/events/#adding-event-listeners-to-the-component-or-its-shadow-root/.
    root.addEventListener('graph-tools-item-clicked', e => {
      if (!this._continueOrShowProOnlyMessage(e.detail.type, e.detail.target)) e.stopPropagation();
    });

    return root;
  }

  firstUpdated() {
    this.$dataWorld = this.requestService('dataWorld');
    this.$sensorWorld = this.requestService('sensorWorld');
    this.$dataCollection = this.requestService('dataCollection');
    this.$popoverManager = this.requestService('popoverManager');

    this._updateTraceList();

    this.eventBinder = new EventBinder();

    this.defaultBottomAxisName = getText('[ x-axis ]');
    this.defaultVerticalAxisName = getText('[ y-axis ]');

    this.initGraph();
    this.refreshBaseLabel();
    this.refreshLeftLabel();
    this.refreshRightLabel();

    this.predictionsEl = this.querySelector('vst-core-graph-predictions');
    this.miniGraphWrapper = this.querySelector('#graph_mini_wrapper');
    this.graphToolsPopoverEl = this.shadowRoot.querySelector('#graph_tools_popover');
    this.graphToolsBtnEl = this.shadowRoot.querySelector('#graph_tools_btn');
    this.autoscaleBtnEl = this.shadowRoot.querySelector('#autoscale_btn');

    this.chartCanvas = this.shadowRoot.querySelector('#chart_canvas');

    this.leftAxisLabelWrapper = this.shadowRoot.querySelector('.left-axis-label-wrapper');
    this.rightAxisLabelWrapper = this.shadowRoot.querySelector('.right-axis-label-wrapper');
    this.leftAxisLabel = this.shadowRoot.querySelector('.left-axis-label');
    this.rightAxisLabel = this.shadowRoot.querySelector('.right-axis-label');

    const coreGraphAnalysis = this.querySelector('vst-core-graph-analysis');
    const spectrumAnalysis = this.querySelector('vst-sa-spectrum-analysis');

    this.leftPlotManagerPopoverEl = this.shadowRoot.querySelector(
      '#graph_left_plot_manager_popover',
    );

    this.annotations = this.$dataWorld.annotations;
    this.analysisEl = coreGraphAnalysis || spectrumAnalysis;
    this.hasCoreAnalysis = Boolean(coreGraphAnalysis);

    if (this.analysisEl) {
      this.analysisEl.coreGraphEl = this;
      this.analysisEl.graphInstance = this.graphInstance;
      this.analysisEl.graphId = this.id;
    }

    this._graphInstance.resize();

    if (this._useResizeObserver) {
      const _throttleResizeGraph = throttle(this.resizeGraph.bind(this), 30); // tweek this to whatever we want
      this.resizeObserver = new ResizeObserver(() => {
        _throttleResizeGraph();
        this.resizing = true;
        clearTimeout(this._resizeFinished);
        this._resizeFinished = setTimeout(() => {
          this.resizing = false;
          this.dispatchEvent(
            new CustomEvent('core-graph-resize-finished', {
              detail: this,
              composed: true,
              bubbles: true,
            }),
          );
        }, 1000);
      });

      this.resizeObserver.observe(this);

      const _throttleLeftAxisLabelResized = throttle(entries => {
        this.leftAxisLabelWidth = entries[0].contentRect.width;
        this.shouldTruncateLeftLabels =
          this.leftAxisLabelWidth + 40 >= this.leftAxisLabelWrapperWidth;
        this.requestUpdate();
      }, 30);

      const _throttleRightAxisLabelResized = throttle(entries => {
        this.rightAxisLabelWidth = entries[0].contentRect.width;
        this.shouldTruncateRightLabels =
          this.rightAxisLabelWidth + 40 >= this.rightAxisLabelWrapperWidth;
        this.requestUpdate();
      }, 30);

      const _throttleLeftAxisLabelWrapperResized = throttle(entries => {
        this.leftAxisLabelWrapperWidth = entries[0].contentRect.width;
      }, 30);

      const _throttleRightAxisLabelWrapperResized = throttle(entries => {
        this.rightAxisLabelWrapperWidth = entries[0].contentRect.width;
      }, 30);

      // ---------------------------------------------------------- //

      this._leftAxisLabelResizeObserver = new ResizeObserver(_throttleLeftAxisLabelResized);

      this._leftAxisLabelWrapperResizeObserver = new ResizeObserver(
        _throttleLeftAxisLabelWrapperResized,
      );

      this._rightAxisLabelResizeObserver = new ResizeObserver(_throttleRightAxisLabelResized);

      this._rightAxisLabelWrapperResizeObserver = new ResizeObserver(
        _throttleRightAxisLabelWrapperResized,
      );

      // ---------------------------------------------------------- //

      this._leftAxisLabelResizeObserver.observe(this.leftAxisLabel);
      this._leftAxisLabelWrapperResizeObserver.observe(this.leftAxisLabelWrapper);

      this._rightAxisLabelResizeObserver.observe(this.rightAxisLabel);
      this._rightAxisLabelWrapperResizeObserver.observe(this.rightAxisLabelWrapper);
    }
    this.eventBinder.bindListeners({
      source: this.$dataWorld,
      target: this,
      eventMap: {
        'column-added': 'onColumnAdded',
        'column-group-added': '_handleColumnGroupAdded',
        'column-removed': 'onColumnRemoved',
        'column-group-unit-change-finished': 'onColumnGroupUnitsChanged',
        'column-strikethrough-changed': 'onColumnStrikethroughChanged',
      },
    });
  }

  updated(changedProperties) {
    changedProperties.forEach(async (oldValue, propName) => {
      switch (propName) {
        case 'accessibilityScale':
          this._accessibilityScaleChanged(this.accessibilityScale);
          break;
        case 'containsCategorical':
          this.dispatchEvent(
            new CustomEvent('contains-categorical-changed', {
              detail: this.containsCategorical,
              bubbles: true,
              composed: true,
            }),
          );
          break;
        case 'colorMode':
          this.updatePlotData();
          break;
        case 'labelSize':
          this._labelSizeChanged(this.labelSize);
          break;
        case 'rightAxisEnabled':
          this._rightAxisEnabledChanged(this.rightAxisEnabled);
          break;
        case 'baseColumnId':
          this._baseColumnIdChanged(this.baseColumnId);
          break;
        case 'leftColumnIds':
          this._leftColumnIdsChanged(this.leftColumnIds, oldValue);
          this._labelSizeChanged(this.labelSize);
          this._updateTraceList();
          this.adjustGraphForCategorical();
          break;
        case 'rightColumnIds':
          this._rightColumnIdsChanged(this.rightColumnIds, oldValue);
          this._labelSizeChanged(this.labelSize);
          this._updateTraceList();
          this.adjustGraphForCategorical();
          break;
        case 'options':
          this._optionsChanged(this.options, oldValue);
          break;
        case 'tempSelection':
          this._updateHighlightGraphTools(this.tempSelection);
          break;
        case 'udmId':
          this._notifyUdmIdChanged(this.udmId);
          break;
        case 'axisScalingModes':
          this._scalingModesChanged(this.axisScalingModes);
          break;
        case 'hidden':
          this._hiddenChanged(this.hidden);
          break;
        case 'selectionsById':
          this.tempSelection = VstCoreGraph._computeTempSelection(this.selectionsById);
          break;
        case 'rawTracePairs':
          this._rawTracePairsChanged(this.rawTracePairs);
          this._updateTraceList();
          break;
        case 'splitSelectionsData':
          this.setSplitSelectionsData(this.splitSelectionsData);
          break;
        case 'isLegendVisible':
          this._optionsChanged({ legend: this.isLegendVisible }, { legend: oldValue });
          break;
        case 'tangentEnabled':
          this._optionsChanged({ tangent: this.tangentEnabled }, { tangent: oldValue });
          break;
        case 'interpolateEnabled':
          this._optionsChanged({ interpolate: this.interpolateEnabled }, { interpolate: oldValue });
          break;

        default:
      }
    });
  }

  disconnectedCallback() {
    if (this._useResizeObserver) {
      this.resizeObserver.unobserve(this);
    }
    this.eventBinder.unbindAll();
  }

  _getTraceList(axis = this.activeAxis) {
    const { $dataWorld } = this;
    const columnIdsProp = `${axis}ColumnIds`;

    const dataSets = [
      ...$dataWorld.getDataSets().map(set => ({
        id: set.id,
        name: set.name,
        columnIds: set.columnIds,
      })),
    ];

    const columns = [
      ...$dataWorld
        .getColumns()
        .filter(column => column.group.id !== this?.getBaseColumn()?.group.id) // we need to filter out all base columns
        .map(column => ({
          id: column.id,
          name: column.name,
          units: column.units,
          setId: column.setId,
          groupId: column.groupId,
          color: rgb2hex(column.color),
          symbol: column.symbol,
          deletable: column.deletable,
          range: column.range,
          plotted: this[columnIdsProp]?.includes(column.id),
        })),
    ];

    const predictions = [
      ...$dataWorld
        .getSpecialDataSets()
        .filter(set => set.type === 'prediction')
        .map(prediction => ({
          id: prediction.id,
          name: prediction.name,
          baseColumnId: prediction.columnIds[0],
          traceColId: prediction.columnIds[1],
          isActive: !!this.getTrace(prediction.columnIds[1], axis),
        })),
    ];

    const graphMatches = [
      ...$dataWorld
        .getSpecialDataSets()
        .filter(set => set.type === 'graph-match')
        .map(match => ({
          id: match.id,
          name: match.name,
          baseColumnId: match.columnIds[0],
          traceColId: match.columnIds[1],
          isActive: !!this.getTrace(match.columnIds[1], axis),
        })),
    ];

    const traceList = {
      baseColumnGroup: this?.getBaseColumn()?.group,
      columns,
      graphMatches,
      predictions,
      dataSets,
    };

    return traceList;
  }

  _updateTraceList() {
    if (!this.$dataWorld) return;
    const { dataSets, columns, predictions, graphMatches } = this._getTraceList(this.activeAxis);

    this.plotManagerTraceList = {
      dataSets,
      columns,
      predictions,
      graphMatches,
    };

    this.requestUpdate();
  }

  initGraph() {
    this._graphInstance = new Graph(this);
    this._graphInstance.addEventListener('axis-range-updated', ({ detail: { min, max, axis } }) => {
      const update = {};
      update[`${axis.name}Range`] = { min, max };
      this.updateOptions(update);
    });
    this.dispatchEvent(
      new CustomEvent('graph-instance-created', {
        composed: true,
        bubbles: true,
        detail: this._graphInstance,
      }),
    );
    this.graphInstance = this._graphInstance.readOnly;
    this.shadowRoot.appendChild(this._graphInstance.root);
    this.gestures = new GraphGestures(
      this._graphInstance.getAxis('base'),
      this._graphInstance.getAxis('left'),
      this._graphInstance.getAxis('right'),
      this,
      changes => {
        this._graphInstance.setGestureRanges(changes);
      },
    );

    // Add the graphs array as a global
    // TODO: this is only good for development
    window.graphs = window.graphs || [];
    window.graphs.push(this._graphInstance);
  }

  graphToolsHandler(e) {
    this.dispatchEvent(new CustomEvent('graph-tools-clicked', { detail: e }));
  }

  dispatchGraphToolsEvent(e, detail, keepToolsOpen = false) {
    if (this._continueOrShowProOnlyMessage(detail.type, e?.target ?? {})) {
      if (!keepToolsOpen) this.graphToolsPopoverEl.hide();
      this.dispatchEvent(new CustomEvent('graph-tools-item-clicked', { detail }));
    }
  }

  onAutoscaleButtonClick() {
    const { tempSelection } = this;
    let targetRanges;

    if (tempSelection) {
      const selectionRange = {
        min: tempSelection.range.min,
        max: tempSelection.range.max,
      };
      this._deleteTempSelection();

      targetRanges = {
        baseRange: selectionRange,
        leftRange: this.getAutoscaledLeftRange(selectionRange),
      };
    } else {
      const autoscaleRanges = this.getAutoscaledRanges();
      targetRanges = mapKeys(autoscaleRanges, (range, axis) => `${axis}Range`); // keys of the form 'baseRange' or 'leftRange'
    }

    this.updateUserAutoscale(targetRanges, !!tempSelection);
  }

  baseAxisClicked(event) {
    const { $dataWorld, $popoverManager } = this;
    const uniqueColumns = uniqBy($dataWorld.getColumns(), column => column.groupId);
    const columnNames = uniqueColumns.map(column => column?.group.getNameUnits());

    const columnClicked = index => {
      const column = uniqueColumns[index];
      // const currentBaseColumnId = this.getBaseColumn()?.id ?? null;

      // if (currentBaseColumnId !== column.id) {
      this.setBaseColumnId(column.id);

      // Defer to make the toggle snappier
      setTimeout(() => {
        this.autoscale();
      });
      // }
    };

    const items = [];
    const activeColumnName = this.getBaseColumn()?.group?.getNameUnits();

    let activeId;

    uniqueColumns.forEach((column, i) => {
      if (activeColumnName === columnNames[i]) activeId = `${i}`;

      items.push({
        id: `${i}`,
        title: columnNames[i],
        selectAction: () => columnClicked(i),
      });
    });

    if (uniqueColumns.length === 0) {
      items.push({
        id: '0',
        title: getText('No Columns'),
        disabled: 'disabled',
      });
    }

    $popoverManager.presentPopoverList({
      items,
      icons: false,
      checkmarks: true,
      active: activeId,
      anchor: event.target,
      orientation: 'top',
    });
  }

  // auto plot new calc columns that are set to repalce their dependents
  async _replaceDependentTraces(column) {
    const { $dataWorld } = this;

    if (
      column.type === 'calc' &&
      column.replaceDependent &&
      column.group.calcDependentGroups.length
    ) {
      // replace any dependent y-axis columns
      if (column.plotted) {
        const dependentGroups = column.group.calcDependentGroups.map(groupId =>
          $dataWorld.getColumnGroupById(groupId),
        );

        const leftTraces = this._getTraceList('left');
        const rightTraces = this._getTraceList('right');
        const replaceLeft = [];
        const replaceRight = [];

        dependentGroups.forEach(group => {
          group.columns.forEach(col => {
            const leftTrace = leftTraces.columns.find(column => column.id === col.id);
            const rightTrace = rightTraces.columns.find(column => column.id === col.id);
            if (leftTrace?.plotted) replaceLeft.push(col);
            if (rightTrace?.plotted) replaceRight.push(col);
          });
        });

        if (replaceLeft.length) {
          replaceLeft.forEach(col => this.updateRemoveColumn(col.id, 'left'));
          this.updateAddColumn(column.id, 'left');
        }

        if (replaceRight.length) {
          replaceLeft.forEach(col => this.updateRemoveColumn(col.id, 'right'));
          this.updateAddColumn(column.id, 'right');
        }

        // FIXME: rework how autoscale interacts with state store to eliminate these
        await this.updateComplete;
        await this.updateComplete;

        if (column.dataSet.type === 'regular' && column.values.length > 0) {
          this.autoscale({ autoscaleSize: 'normal' });
        }
      }
    }
  }

  onColumnAdded(column) {
    this._replaceDependentTraces(column);

    column.on('values-changed', () => {
      if (this._deferredErrorBarColumnIds.has(column.group.id)) {
        this._handleErrorBarParametersChanged();
      }
    });
  }

  // we cannot rely on removing just the column group, because the underlying columns
  // may be removed in DataWorld before we get to the point of removing the trace
  onColumnRemoved(column) {
    // update redux graph state
    if (this.leftColumnIds.includes(column.id)) this.updateRemoveColumn(column.id, 'left');
    if (this.rightColumnIds.includes(column.id)) this.updateRemoveColumn(column.id, 'right');

    // remove all traces that may rely on column either as baseColumn or yColumn
    const baseTraces = this.getTracesByBaseColId(column.id);
    const yTraces = this.getTracesByColId(column.id);

    [...baseTraces, ...yTraces].forEach(({ yColumn, axis }) => {
      this.removeTrace(yColumn.id, axis);
      this.autoscale();
    });

    // if all columns are gone for this group remove the baseColumn
    if (this?.baseColumnGroup?.id === column?.groupId) {
      const count = column.group.columns.length;
      if (count === 0) this.removeBaseColumn();
    }

    // since we track the baseColumn rather than the group we need to
    // update the baseColumnId to the latest of the same column
    if (column.id === this?.baseColumnId) {
      const newBaseColumn = this.getBaseColumn();
      if (newBaseColumn) {
        this.setBaseColumnId(newBaseColumn.id);
      }
    }

    if (column.group.errorBarColumnId) {
      this._deferredErrorBarColumnIds.delete(column.group.errorBarColumnId);
      this._handleErrorBarParametersChanged();
    }
  }

  onColumnGroupUnitsChanged(columnGroup) {
    const { $dataWorld } = this;
    const activeLeftColumns = this._getTraceList('left').columns.filter(group => group.plotted);
    const activeRightColumns = this._getTraceList('right').columns.filter(group => group.plotted);

    if (this?.baseColumn?.groupId === columnGroup.id) {
      this.dispatchEvent(new CustomEvent('base-units-updated', { detail: columnGroup.units }));
      return;
    }

    if (activeLeftColumns.find(column => column.groupId === columnGroup.id)) {
      if (!$dataWorld.isCurrentDataSetEmpty() && this.baseColumn) {
        this.autoscale({ axes: ['base', 'left'] });
      } else {
        const { min, max } = getLargestGraphRange(activeLeftColumns);
        this.updateOptions({ leftRange: { min, max } });
      }
    }

    if (activeRightColumns.find(column => column.groupId === columnGroup.id)) {
      if (!$dataWorld.isCurrentDataSetEmpty() && this.baseColumn) {
        this.autoscale({ axes: ['base', 'right'] });
      } else {
        const { min, max } = getLargestGraphRange(activeRightColumns);
        this.updateOptions({ rightRange: { min, max } });
      }
    }
  }

  onColumnStrikethroughChanged() {
    this.requestUpdate();
  }

  async plotManagerColumnSelected({ detail: { columnId } }) {
    const oppositeAxis = this.activeAxis === 'right' ? 'left' : 'right';
    this.updateAddColumn(columnId);
    this.updateRemoveColumn(columnId, oppositeAxis); // you cannot plot a group on left and right axis simultaneously

    // TODO: look for a clearner way for this. We need to make sure
    // traces are added before updating verticle ranges
    await this.updateComplete;
    await this.updateComplete;

    this._updateVerticalRange();
  }

  containsActiveCategoricalTraces() {
    return this._getTraceList(this.activeAxis)
      .columns.filter(trace => trace?.plotted) // get plotted y columns
      .map(trace => this.getBaseColumn(trace?.setId)) // get corresponding plotted base columns
      .some(baseCol => baseCol?.dataType === ColumnDataType.TEXT); // return true if any contain categorical data
  }

  adjustGraphForCategorical(visibleTraces = []) {
    // FFT + histograms do not need to transition to categorical, setting isCategortical to false messes up axis offset
    if (this.isInHistogramMode || this.isInFFTMode) {
      return;
    }

    // containsActiveCategoricalTraces does not return true before categorical data is actually added to
    // the graph, so have to use the last check to jump start the categorical graphing process
    const isCategoricalData =
      this.containsActiveCategoricalTraces() ||
      visibleTraces.some(trace => trace.baseColumn?.dataType === ColumnDataType.TEXT);

    this.containsCategorical = isCategoricalData;
    this.graphInstance.categorical = isCategoricalData;

    if (isCategoricalData) {
      this.updateOptions({
        appearance: { bars: true, lines: false, points: false },
      });
    }
  }

  async plotManagerColumnDeselected({ detail: { columnId } }) {
    this.updateRemoveColumn(columnId);

    // TODO: look for a clearner way for this. We need to make sure
    // traces are added before updating verticle ranges
    await this.updateComplete;
    await this.updateComplete;

    this._updateVerticalRange();
  }

  plotManagerColumnDeleted({ detail: groupId }) {
    promptDeleteColumn.bind(this, this.$dataWorld, this.$dataWorld.getColumnGroupById(groupId))();
  }

  async plotManagerColumnTraceUpdated({ detail: { columnId, color, symbol } }) {
    await this.$dataWorld.updateColumnAppearance(columnId, color, symbol);
  }

  plotManagerSpecialDatasetSelected(event) {
    const { columnId, type } = event.detail;

    const getAutoscaleOpts = () => {
      const axes = this.$dataWorld.isCollecting ? [this.activeAxis] : ['base', this.activeAxis];
      return { axes };
    };

    this.addTrace(columnId);
    if (type === 'prediction') this.autoscale(getAutoscaleOpts());
  }

  plotManagerSpecialDatasetDeselected(event) {
    const { columnId } = event.detail;

    this.removeTrace(columnId);
  }

  async plotManagerShowDataSetOptions(event) {
    const { $dataWorld, $popoverManager } = this;
    const { item: dataSet } = event.detail;
    const { name } = $dataWorld.getDataSetByID(dataSet.id);

    await import('@components/vst-core-rename/vst-core-rename.js');
    $popoverManager.presentDialog('vst-core-rename', {
      title: getText('Rename Data Set', 'general', 'Dialog title'),
      properties: {
        name,
        nameDescription: getText('Data Set Name'),
        nameMaxLength: 100,
        nameMaxLengthError: sprintf(getText('Data set name has %s character limit.'), String(100)),
      },
      events: ({ component, completeWorkflow, cancelWorkflow }) => ({
        save: () => {
          $dataWorld.updateDataSet(dataSet.id, {
            name: component.name,
          });
          completeWorkflow();
        },
        cancel: () => {
          cancelWorkflow();
        },
      }),
    });
  }

  plotManagerShowPredictionOptions(event) {
    const { $dataWorld, $popoverManager } = this;
    const { item: prediction, anchor } = event.detail;

    const renamePredictionSelected = async () => {
      await import('@components/vst-core-rename/vst-core-rename.js');
      $popoverManager.presentDialog('vst-core-rename', {
        title: getText('Rename Prediction'),
        properties: {
          name: prediction.name,
          nameDescription: getText('Prediction Name'),
          nameMaxLength: 100,
          nameMaxLengthError: sprintf(
            getText('Prediction name has %s character limit.'),
            String(100),
          ),
        },
        events: ({ component, completeWorkflow, cancelWorkflow }) => ({
          save: () => {
            $dataWorld.updateDataSet(prediction.id, {
              name: component.name,
            });
            completeWorkflow();
          },
          cancel: () => {
            cancelWorkflow();
          },
        }),
      });
    };

    $popoverManager.presentPopoverList({
      anchor,
      orientation: 'right',
      items: [
        {
          id: 'rename_prediction',
          title: getText('Rename Prediction'),
          selectAction: renamePredictionSelected,
        },
        {
          id: 'delete_data_set',
          title: getText('Delete Prediction'),
          selectAction() {
            return $dataWorld.removeDataSet(prediction.id).catch(err => console.error(err));
          },
        },
      ],
    });
  }

  plotManagerShowGraphMatchOptions(event) {
    const { $dataWorld, $popoverManager } = this;
    const { item: graphMatch, anchor } = event.detail;

    $popoverManager.presentPopoverList({
      anchor,
      orientation: 'right',
      items: [
        {
          id: 'delete_data_set',
          title: getText('Delete Graph Match'),
          selectAction() {
            return $dataWorld.removeDataSet(graphMatch.id).catch(err => console.error(err));
          },
        },
      ],
    });
  }

  _togglePlotManagerPopover(axis) {
    import('@components/vst-core-graph-plot-manager/vst-core-graph-plot-manager.js');
    if (axis === 'left') this.shadowRoot.querySelector('#graph_left_plot_manager_popover').show();
    if (axis === 'right') this.shadowRoot.querySelector('#graph_right_plot_manager_popover').show();
    this.activeAxis = axis;
    this._updateTraceList();
    this.requestUpdate();
  }

  _updateVerticalRange(axis = this.activeAxis) {
    const { $dataWorld } = this;

    const activeColumns = this._getTraceList(axis).columns.filter(trace => trace.plotted);

    const getAutoscaleOpts = () => {
      const axes = $dataWorld.isCollecting ? [axis] : ['base', axis];
      return { axes };
    };

    if ($dataWorld.isCurrentDataSetEmpty() && activeColumns.length) {
      const { min, max } = getLargestGraphRange(activeColumns);
      this.updateOptions({
        leftRange: { min, max },
      });
    } else if (activeColumns.length) {
      this.autoscale(getAutoscaleOpts());
    }
  }

  _onInterpolateSwitchChanged({ detail: checked }) {
    // interpolate switch is incomparible with tangent
    this?.analysisEl?.fireExamineSettingsUpdate({
      interpolate: checked,
      tangentEnabled: false,
    });

    if (!checked)
      this?.analysisEl?.fireExaminePositionUpdate({
        xPosition: this.analysisEl?.examinePin?.examinePosition?.closestXPt,
      });
  }

  _onTangentSwitchChanged({ detail: checked }) {
    // tangent switch is incompatible with interpolate
    this?.analysisEl?.fireExamineSettingsUpdate({
      interpolate: false,
      tangentEnabled: checked,
    });
  }

  getClosestX(params) {
    return this.analysisEl.getClosestX(params);
  }

  _labelSizeChanged(size = this.labelSize) {
    if (this.leftAxisLabel && this._graphInstance) {
      this.style.setProperty('--label-size', `${size}em`);
      const leftWidth = this.leftAxisLabel.clientHeight + 18;
      this.style.setProperty('--vertical-axis-width', `${leftWidth}px`);
      this.resizeGraph();
    }
  }

  _calculateContextMenuLeft() {
    if (this.tempSelection?.range) {
      const { max: range0, min: range1 } = this.tempSelection.range;
      const maxRange = Math.max(range0, range1);
      return this.graphInstance.getAxis('base').p2c(maxRange);
    }

    return 0;
  }

  resizeGraph() {
    if (this._graphInstance) {
      this._graphInstance.resize();
      this.dispatchEvent(new CustomEvent('resize'));
    }

    this._contextMenuLeft = this._calculateContextMenuLeft();
  }

  static _computeTempSelection(selectionsById = {}) {
    return Object.values(selectionsById).find(s => !s.permanent);
  }

  _generateErrorBars(trace) {
    const {
      errorBarType: baseErrorBarType,
      errorBarValue: baseErrorBarValue,
      errorBarColumnId: baseErrorBarColumnId,
    } = trace.baseColumn.group;
    const {
      errorBarType: yErrorBarType,
      errorBarValue: yErrorBarValue,
      errorBarColumnId: yErrorBarColumnId,
    } = trace.yColumn.group;
    let baseErrorColumnValues = [];
    let yErrorColumnValues = [];

    if (baseErrorBarType === ErrorBarType.COLUMN && baseErrorBarColumnId)
      baseErrorColumnValues = this.$dataWorld
        .getColumnGroupById(baseErrorBarColumnId)
        .columns.find(errorBarColumn => errorBarColumn.setId === trace.baseColumn.setId).values;
    if (yErrorBarType === ErrorBarType.COLUMN && yErrorBarColumnId)
      yErrorColumnValues = this.$dataWorld
        .getColumnGroupById(yErrorBarColumnId)
        .columns.find(errorBarColumn => errorBarColumn.setId === trace.yColumn.setId).values;

    return trace.seriesData.map((row, i) => {
      const [baseValue, yValue] = row;
      const baseErrorBar = VstCoreGraph.calculateErrorValue(
        baseValue,
        baseErrorBarType,
        baseErrorBarValue,
        baseErrorColumnValues[i],
      );
      const yErrorBar = VstCoreGraph.calculateErrorValue(
        yValue,
        yErrorBarType,
        yErrorBarValue,
        yErrorColumnValues[i],
      );
      return [baseErrorBar, yErrorBar];
    });
  }

  _updateHighlightGraphTools(tempSelection) {
    this._contextMenuLeft = this._calculateContextMenuLeft();
    if (this.autoscaleBtnEl) {
      const zoomIcon = this.autoscaleBtnEl.querySelector('vst-ui-icon');
      const autoscaleBtnTooltipEl = this.shadowRoot.querySelector(
        '#autoscale_btn_container [role="tooltip"]',
      );

      if (tempSelection) {
        this.autoscaleBtnEl.classList.add('graph-actions__btn--look-at-me');
        autoscaleBtnTooltipEl.innerText = getText('Zoom to Selection');
        zoomIcon.icon = zoomIn;
      } else {
        this.autoscaleBtnEl.classList.remove('graph-actions__btn--look-at-me');
        autoscaleBtnTooltipEl.innerText = getText('Zoom to all Data');
        zoomIcon.icon = zoomAutoscale;
      }
    }
  }

  _notifyUdmIdChanged(udmId) {
    if (udmId !== 0) {
      this.dispatchEvent(new CustomEvent('udm-id-set', { detail: udmId }));
    }
  }

  updateOptions(options, actor = Actor.USER) {
    this.dispatchEvent(new CustomEvent('options-change', { detail: { ...options, actor } }));
  }

  _accessibilityScaleChanged() {
    if (this._graphInstance) {
      this.updatePlotData();
      this.resizeGraph();
    }
  }

  _scalingModesChanged(newModes, oldModes) {
    if (this._graphInstance && !isEqual(newModes, oldModes)) {
      this._graphInstance.setScalingModes(newModes);

      const udmProps = {};
      ['base', 'left', 'right'].forEach(name => {
        if (newModes[name]) {
          udmProps[`${name}ScalingMode`] = newModes[name];
        }
      });

      if (Object.keys(udmProps).length !== 0) {
        this.updateUdm(udmProps);
      }
    }
  }

  _rightAxisEnabledChanged(enabled) {
    if (enabled)
      this.rightPlotManagerPopoverEl = this.shadowRoot.querySelector(
        '#graph_right_plot_manager_popover',
      );

    if (this._graphInstance) {
      this._graphInstance.setRightAxisEnabled(enabled);
      this.resizeGraph();
    }

    this.updateUdm({ rightAxisEnabled: enabled });
  }

  async _hiddenChanged(hidden) {
    if (!this._graphInstance) {
      return;
    }

    await this.updateComplete;

    if (!hidden) {
      this._labelSizeChanged();
      await nextRepaintComplete(); // need to let the graph actually show up, then we can update the plot data it seems.
      this.updatePlotData();
    }
  }

  _optionsChanged(newOptions = {}, oldOptions = {}) {
    if (!this._graphInstance) {
      return;
    }

    this._contextMenuLeft = this._calculateContextMenuLeft();

    // TODO: remove redundant updates in this method
    this.updateUdm(newOptions);

    if ((!oldOptions.title && newOptions.title) || (oldOptions.title && !newOptions.title)) {
      this.resizeGraph();
    }

    if (!isEqual(newOptions.appearance, oldOptions.appearance)) {
      const { lines, points, bars } = newOptions.appearance;
      this._graphInstance.showBars(bars);
      this._graphInstance.showLines(lines);
      this._graphInstance.showPoints(points);
      this.updateUdm({ lines, points, bars });
      if (newOptions.appearance?.bars !== oldOptions.appearance?.bars) this.autoscale();
    }

    if (!isEqual(newOptions.baseRange, oldOptions.baseRange)) {
      const { baseRange } = newOptions;
      const prevRange = this._graphInstance.getAxis('base').getRange();
      const isValid = value => Number.isFinite(value) || typeof value === 'string';
      // FIXME: we are mutating Redux state here
      baseRange.min = isValid(baseRange.min) ? baseRange.min : prevRange.min;
      baseRange.max = isValid(baseRange.max) ? baseRange.max : prevRange.max;
      this._graphInstance.setBaseRange(baseRange.min, baseRange.max);
      this.updateUdm({ xMin: baseRange.min, xMax: baseRange.max });

      this._graphInstance.addEventListener(
        'graph-grid-updated',
        () => {
          this.traces.forEach(trace => trace.trimAllSeriesData());
        },
        { once: true },
      );

      // TODO: move these listeners elsewhere where they can be created just once on initialization
      this._graphInstance.addEventListener('axis-moving-started', () => {
        const analysisEl = this.querySelector('vst-core-graph-analysis');
        if (analysisEl) analysisEl.axisMoving = true;
        this.dispatchEvent(new CustomEvent('axis-moving-started'));
      });
      this._graphInstance.addEventListener('axis-moving-ended', () => {
        const analysisEl = this.querySelector('vst-core-graph-analysis');
        if (analysisEl) analysisEl.axisMoving = false;
      });
    }

    if (!isEqual(newOptions.leftRange, oldOptions.leftRange)) {
      const { leftRange } = newOptions;
      const prevRange = this._graphInstance.getAxis('left').getRange();
      // FIXME: we are mutating Redux state here
      leftRange.min = Number.isFinite(leftRange.min) ? leftRange.min : prevRange.min;
      leftRange.max = Number.isFinite(leftRange.max) ? leftRange.max : prevRange.max;
      this._graphInstance.setLeftRange(leftRange.min, leftRange.max);
      this.updateUdm({ yMin: leftRange.min, yMax: leftRange.max });
    }

    if (!isEqual(newOptions.rightRange, oldOptions.rightRange)) {
      const { rightRange } = newOptions;
      const prevRange = this._graphInstance.getAxis('right').getRange();
      // FIXME: we are mutating Redux state here
      rightRange.min = Number.isFinite(rightRange.min) ? rightRange.min : prevRange.min;
      rightRange.max = Number.isFinite(rightRange.max) ? rightRange.max : prevRange.max;
      this._graphInstance.setRightRange(rightRange.min, rightRange.max);
      this.updateUdm({ rightYMin: rightRange.min, rightYMax: rightRange.max });
    }

    if (newOptions.labelSize !== oldOptions.labelSize) {
      this.labelSize = parseFloat(newOptions.labelSize);
      this.resizeGraph();
    }
  }

  _rawTracePairsChanged(rawTracePairs) {
    this._graphInstance.rawTracePairs = rawTracePairs;
    this.updatePlotData();
  }

  _leftColumnIdsChanged(newIds = [], oldIds = []) {
    if (!this.graphInstance) {
      return;
    }

    oldIds.forEach(id => {
      if (!newIds.includes(id)) {
        this.removeTrace(id, 'left');
      }
    });

    newIds.forEach(id => {
      if (!oldIds.includes(id)) {
        this.addTrace(id, 'left');
      }
    });

    if (Array.isArray(newIds)) {
      this.refreshVerticalAxisLabel('left');
    }

    this.dispatchEvent(new CustomEvent('regular-traces-updated'));
  }

  _rightColumnIdsChanged(newIds = [], oldIds = []) {
    if (!this.graphInstance) {
      return;
    }

    oldIds.forEach(id => {
      if (!newIds.includes(id)) {
        this.removeTrace(id, 'right');
      }
    });

    newIds.forEach(id => {
      if (!oldIds.includes(id)) {
        this.addTrace(id, 'right');
      }
    });

    if (Array.isArray(newIds)) {
      this.refreshVerticalAxisLabel('right');
    }

    this.dispatchEvent(new CustomEvent('regular-traces-updated'));
  }

  setGetTraceColor(func) {
    this.getNextTraceColor = func;
  }

  addTrace(columnId, axis = 'left') {
    const { $dataWorld } = this;

    const column = $dataWorld.getColumnById(columnId);
    const dataSet = $dataWorld.getDataSetByID(column?.setId);
    let traceBaseColumn = this.getBaseColumn(dataSet?.id);

    // Stand alone traces don't belong to groups, so the above does not produce
    // a result; just take the first column in the column's dataset
    if (!traceBaseColumn && dataSet) {
      const baseColId = dataSet.columnIds[0];
      traceBaseColumn = $dataWorld.getColumnById(baseColId);
    }

    if (!column || !traceBaseColumn) {
      // TODO not sure why this even gets called now cleanup with a column that doesn't actually exist graphprefs sync
      console.warn(`Unable to add trace for columnId=${columnId}`);
      return undefined;
    }

    let trace = null;
    let color = 'rgba(120,120,120,0.7)';
    let symbol = 'circle';
    let lineWeight = 6; // default weight for non-left traces

    if (!this.baseColumn) {
      console.warn(`attempting to add trace ${columnId} before base column set!`);
      return undefined;
    }

    // TODO: make sure we don't have this column yet
    if (!this.getTrace(columnId, axis)) {
      const columnGroup = column.group;

      if (['regular', 'fft', 'histogram'].includes(dataSet.type)) {
        if (column.color) {
          color = column.color;
        } else {
          color = getTraceColor(column, this, $dataWorld, this.getNextTraceColor.bind(this));
          column.color = color;
        }

        if (column.symbol) {
          symbol = column.symbol;
        }

        lineWeight = this.defaultLineWeight * this.accessibilityScale;
      }

      trace = new Trace(this._graphInstance, {
        type: dataSet.type,
        yColumn: column,
        baseColumn: traceBaseColumn,
        axis,
        color,
        lineWeight,
        symbol,
        experimentId: $dataWorld.experimentId,
      });

      this.traces = [...this.traces, trace];
      trace.bindAll();

      trace.on('data-points-updated', () => this.updatePlotData({ allowSpecialAutoscale: true }));

      trace.on('point-symbols-updated', () => this.updatePlotData());

      trace.on('base-column-data-type-updated', () => {
        this.adjustGraphForCategorical();
      });

      const refreshAxisLabel = axis === 'left' ? this.refreshLeftLabel : this.refreshRightLabel;

      // column group properties
      ['name', 'units', 'wavelength'].forEach(key => {
        columnGroup.on(`${key}-changed`, refreshAxisLabel, this);
      });

      column.on('color-changed', newColor => {
        const _trace = this.getTrace(column.id, axis);
        if (_trace) {
          _trace.color = newColor;
          this.refreshVerticalAxisLabel(axis);
          this.updatePlotData();
        }
      });

      if (trace.type === 'prediction') {
        dataSet.on('name-changed', refreshAxisLabel, this);
      }

      if (this.udmId === 0) {
        this.pendingTracesUpdate.push(trace);
      } else {
        // This only works for traces that have a group id, so if it
        // does not work, we get the first column from the dataset, that
        // works for things like predictions
        const baseColumn = trace.getBaseColumn();
        let baseColumnId;

        if (baseColumn) {
          baseColumnId = baseColumn.id;
        } else {
          [baseColumnId] = dataSet.columnIds;
          console.assert(baseColumnId !== columnId);
        }

        const traces = [
          {
            baseColumnId: parseInt(baseColumnId),
            traceColumnId: parseInt(columnId),
            isRightAxisTrace: axis === 'right',
            experimentId: trace.experimentId,
          },
        ];

        // If we are not importing or closing a document just now, pass these onto the
        // backend (if we are importing, these came from the backend in the
        // first place)
        if (!$dataWorld.importing && !$dataWorld.sessionClosing) {
          $dataWorld.addGraphTraces(this.udmId, traces).catch(error => {
            console.warn(error);
          });
        }
      }

      refreshAxisLabel.bind(this)();
      this.updatePlotData();

      return trace;
    }

    return undefined;
  }

  removeTrace(columnId, axis = 'left') {
    const trace = this.getTrace(columnId, axis);

    if (trace) {
      // TODO: unbind from column
      this.traces = this.traces.filter(t => t.id !== trace.id || t.axis !== axis);

      trace.off('data-points-updated');
      trace.off('point-symbols-updated');
      trace.off('base-column-data-type-updated');

      const refreshAxisLabel = axis === 'left' ? this.refreshLeftLabel : this.refreshRightLabel;
      ['name', 'units', 'wavelength'].forEach(key => {
        trace.yColumn.group?.off(`${key}-changed`, refreshAxisLabel, this);
      });

      refreshAxisLabel.bind(this)();

      trace.unbindAll();
      this.updatePlotData({ allowSpecialAutoscale: true });

      if (this.udmId === 0) {
        const idx = this.pendingTracesUpdate.indexOf(trace);

        if (idx !== -1) {
          this.pendingTracesUpdate[idx] = null;
        }
      } else if (trace.getBaseColumn()) {
        // If baseColumnId was provided, then use it (we are removing a trace while
        // the base column is changing under our feet)
        const traces = [
          {
            baseColumnId: parseInt(trace.getBaseColumn().id),
            traceColumnId: parseInt(columnId),
            isRightAxisTrace: axis === 'right',
            experimentId: trace.experimentId,
          },
        ];

        this.$dataWorld.removeGraphTraces(this.udmId, traces);
      }
    }
  }

  removeRegularTraces() {
    const traces = this.traces.filter(trace => trace.type === 'regular');
    traces.forEach(trace => this.removeTrace(trace.yColumn.id, trace.axis));
  }

  // remove all traces of the given type
  removeTracesByType(type) {
    const traces = this.traces.filter(trace => trace.type === type);
    traces.forEach(trace => this.removeTrace(trace.yColumn.id, trace.axis));
  }

  /**
   * Get regular traces on both left and right axes
   * @returns {Trace[]}
   */
  getAllRegularTraces() {
    return this.traces.filter(trace => trace.type === 'regular');
  }

  /**
   * Get regular traces on left axis
   * @returns {Trace[]}
   */
  getLeftTraces() {
    return this.traces.filter(trace => trace.axis === 'left' && trace.type === 'regular');
  }

  /**
   * Get trace by y-column id and axis
   * @param {number|string} columnId
   * @param {string} axis
   * @returns {Trace|undefined}
   */
  getTrace(columnId, axis = this.activeAxis) {
    // Make this work for both numbers and strings
    return this.traces.find(t => t.yColumn.id === `${columnId}` && t.axis === axis);
  }

  /**
   * Get traces for y-column id on either axis
   * @param {number|string} columnId
   * @returns {Trace[]}
   */
  getTracesByColId(columnId) {
    return this.traces.filter(t => t.yColumn.id === `${columnId}`);
  }

  /**
   * Get traces by base-column id and axis
   * @param {number|string} baseColumnId
   * @param {string} axis
   * @returns {Trace[]}
   */
  getTracesByBaseColId(baseColumnId, axis = this.activeAxis) {
    return this.traces.filter(t => t.baseColumn.id === `${baseColumnId}` && t.axis === axis);
  }

  /**
   * Get trace by trace id irrespective of axis
   * @param {string} traceId
   * @returns {Trace|undefined}
   */
  getTraceByTraceId(traceId) {
    return this.traces.find(t => t.id === traceId);
  }

  /**
   * Get all traces of given type irrespective of axis
   * @param {string} type
   * @returns {Trace[]}
   */
  getTracesByType(type) {
    return this.traces.filter(trace => trace.type === type);
  }

  /**
   * Helper to get all traces across all axes to which to apply examine-pin or
   * selections analysis.
   * @returns {Trace[]} a list of analyses-ready traces including those on the
   * right axis when enabled.
   */
  getAnalysisTraces() {
    const { rightAxisEnabled } = this;
    return this.traces.filter(
      trace =>
        ['regular', 'fft', 'histogram'].includes(trace.type) &&
        (trace.axis === 'left' || rightAxisEnabled),
    );
  }

  setBaseColumnId(baseColumnId) {
    this.dispatchEvent(
      new CustomEvent('graph-base-column-id-update', {
        detail: { baseColumnId },
      }),
    );
  }

  updateAddColumn(columnId, axis = this.activeAxis) {
    this.dispatchEvent(
      new CustomEvent('axis-column-id-added', {
        detail: { columnId, axis },
      }),
    );
  }

  updateRemoveColumn(columnId, axis = this.activeAxis) {
    this.dispatchEvent(
      new CustomEvent('axis-column-id-removed', {
        detail: { columnId, axis },
      }),
    );
  }

  // Bulk update: call this with an array of columnIds to update what's plotted on the graph
  updateColumnIds(columnIds = [], axis = this.activeAxis) {
    this.dispatchEvent(
      new CustomEvent('axis-column-ids-updated', {
        detail: { columnIds, axis },
      }),
    );
  }

  updateScalingMode({ detail }) {
    this.dispatchEvent(new CustomEvent('axis-scaling-mode-changed', { detail }));
  }

  updateAutomaticAutoscale(rangeUpdates) {
    this.dispatchEvent(new CustomEvent('automatic-autoscale', { detail: { rangeUpdates } }));
  }

  updateUserAutoscale(rangeUpdates, isZoomToSelectionRange) {
    this.dispatchEvent(
      new CustomEvent('user-requested-autoscale', {
        detail: { rangeUpdates, isZoomToSelectionRange },
      }),
    );
  }

  updateRightAxisEnabled(enabled) {
    this.dispatchEvent(new CustomEvent('right-axis-enabled-update', { detail: enabled }));
  }

  get selections() {
    let selections = [];
    if (this.analysisEl) {
      [selections] = this.analysisEl;
    } else {
      console.warn('vst-core-graph-analysis not found');
    }

    return selections;
  }

  get hasTrace() {
    return Boolean(this.getAllRegularTraces().length);
  }

  get manualFits() {
    return this.$dataWorld?.manualFits.filter(manualFit => manualFit.graphId === this.udmId) || [];
  }

  getBaseRange() {
    return this._graphInstance.getAxisRange('base');
  }

  getBaseIndexRange() {
    return this._graphInstance.getAxis('base').indexRange;
  }

  getLeftRange() {
    return this._graphInstance.getAxisRange('left');
  }

  getLeftColumnIds() {
    return this._graphInstance ? this._graphInstance.getLeftColumnIds() : [];
  }

  setSelectionsData(selectionsDataById) {
    const fitSelections = Object.values(selectionsDataById).filter(
      s => s.analysisType === 'curveFits',
    );
    const manualFitSelections = Object.values(selectionsDataById).filter(
      selection => selection.analysisType === 'manual-fits',
    );
    const integralSelections = Object.values(selectionsDataById).filter(
      s => s.analysisType === 'integrals',
    );

    // FIXME (@mossymaker): this is a whole lot of duplication and munging
    const fitTracesData = fitSelections.reduce(
      (data, selection) => [...data, ...selection.traceDataSets],
      [],
    );
    const manualFitTracesData = manualFitSelections.reduce(
      (data, selection) => [...data, ...selection.traceDataSets],
      [],
    );
    const integralTracesData = integralSelections.reduce(
      (data, selection) => [...data, ...selection.traceDataSets],
      [],
    );

    const updateRequired =
      this.fitTraces.length ||
      this.integralTraces.length ||
      this.manualFitTraces.length ||
      fitTracesData.length ||
      integralTracesData.length ||
      manualFitTracesData.length;

    if (updateRequired) {
      this.fitTraces = fitTracesData;
      this.manualFitTraces = manualFitTracesData;
      this.integralTraces = integralTracesData;
      this.updatePlotData();
    }
  }

  setSplitSelectionsData(splitSelections) {
    this.peakIntegralTracesData = [
      ...Object.values(splitSelections).map(({ peakSeriesData, traceInfo }) => ({
        peakSeriesData,
        traceColor: traceInfo.color,
        traceAxis: traceInfo.axis,
      })),
    ];

    this.updatePlotData();
  }

  addTangentTrace(tangentTrace) {
    this.tangentTraces.push(tangentTrace);
  }

  removeAllTangentTraces() {
    if (this.tangentTraces.length) {
      this.tangentTraces = [];
      this.updatePlotData();
    }
  }

  /**
   * This is the internal bottle-neck for graph updating. It should only be called via
   * the throttle, e.g. by calling @see `updatePlotData()`.
   * @param {boolean} [allowSpecialAutoscale] should we perform a special autoscale
   * when updating the data.
   */
  _internalUpdate(allowSpecialAutoscale = false) {
    const isTraceVisible = traceItem => traceItem.axis !== 'right' || this.rightAxisEnabled;
    const visibleTraces = this.traces.filter(isTraceVisible);
    // detect & enable/disable categorical data graphing
    this.adjustGraphForCategorical(visibleTraces);

    const params = {
      errorBars: visibleTraces.map(trace => this._generateErrorBars(trace)),
      traces: visibleTraces,
      fitTraces: this.fitTraces,
      manualFitTraces: this.manualFitTraces,
      integralTraces: this.integralTraces,
      tangentTraces: this.tangentTraces,
      peakIntegralTracesData: this.peakIntegralTracesData,
    };

    this._graphInstance.updateData(params, allowSpecialAutoscale);
  }

  /**
   * This is the main external calling point for updating graph on the data.
   * Calling this method is the preferred way to request an update for graph
   * data. It will appropriately throttle all update requests.
   * @param {Object} param Object
   * @param {boolean} param.allowSpecialAutoscale should we perform an
   * autoscale during update?
   */
  updatePlotData({ allowSpecialAutoscale = false } = {}) {
    this._throttleUpdate(allowSpecialAutoscale);
  }

  updateUdm(props) {
    const { $dataWorld } = this;

    if (this.udmId === 0) {
      this.pendingGraphUpdate = {
        ...this.pendingGraphUpdate,
        ...props,
      };
    } else {
      $dataWorld.changeGraphProperties(this.udmId, props);
    }
  }

  async addGraphToUdm() {
    const { $dataWorld } = this;
    const range = this.graphInstance.getAxisRange('base');
    const rangeY = this.graphInstance.getAxisRange('left');

    const props = {};
    // read visible status from connected graph container component
    // TODO: move this logic to initialize UDM to a higher level to avoid accessing parent elements like this
    props.visible = !this.parentNode.host.hidden;
    props.xMin = range.min;
    props.xMax = range.max;
    props.yMin = rangeY.min;
    props.yMax = rangeY.max;
    ({ bars: props.bars, lines: props.lines, points: props.points } = this.options.appearance);
    props.title = this.options.title;

    const { graphId } = await $dataWorld.addGraph(props);
    this.udmId = graphId;
    this.$dataWorld.registerGraphId(this.id, this.udmId);

    const [, index] = this.id.split('_');
    await $dataWorld.changeGraphProperties(this.udmId, {
      ...this.pendingGraphUpdate,
      index: parseInt(index),
    });
    this.pendingGraphUpdate = {};

    if (this.pendingTracesUpdate.length > 0) {
      const traces = [];
      this.pendingTracesUpdate.forEach(trace => {
        if (trace !== null) {
          traces.push({
            baseColumnId: parseInt(this.baseColumnId),
            traceColumnId: parseInt(trace.yColumn.id),
            isRightAxisTrace: trace.axis === 'right',
            experimentId: $dataWorld.session.experimentId,
          });
        }
      });

      this.pendingTracesUpdate = [];
      // if there where no non-null pending traces then don't call the back end. it just makes it 😖
      if (traces.length > 0) {
        try {
          $dataWorld.addGraphTraces(this.udmId, traces);
        } catch (error) {
          console.warn(error);
        }
      }
    }
  }

  applyImportedGraphState(options) {
    this.udmId = options.graphId;
    this.$dataWorld.registerGraphId(this.id, this.udmId);

    if (options.curveFits && options.curveFits.length > 0) {
      options.traces = options.traces.filter(
        trace => !options.curveFits.find(fit => fit.fitId === trace.traceColumnId),
      );
    }

    if (options.baseColumnId) {
      this.setBaseColumnId(`${options.baseColumnId}`);
    }

    // update options in a single go
    const update = {};

    // update display points/lines setting
    update.appearance = { lines: options.lines, points: options.points, bars: options.bars };

    const { min: xMin, max: xMax } = this._graphInstance.getBaseRange();

    if (xMin !== options.xMin || xMax !== options.xMax) {
      update.baseRange = { min: options.xMin, max: options.xMax };
    }

    const leftRange = this._graphInstance.getLeftRange();
    const yMin = leftRange.min;
    const yMax = leftRange.max;

    if (yMin !== options.yMin || yMax !== options.yMax) {
      update.leftRange = { min: options.yMin, max: options.yMax };
    }

    if (options.title) {
      update.title = options.title;
    }

    const { min: rightYMin, max: rightYMax } = this._graphInstance.getRightRange();
    if (rightYMin !== options.rightYMin || rightYMax !== options.rightYMax) {
      update.rightRange = { min: options.rightYMin, max: options.rightYMax };
    }

    this.updateOptions(update, Actor.AUTOMATIC);
    this.updateRightAxisEnabled(!!options.rightAxisEnabled);

    const baseScalingMode = this._graphInstance.getAxis('base').autoscaleMode;
    const leftScalingMode = this._graphInstance.getAxis('left').autoscaleMode;
    const rightScalingMode = this._graphInstance.getAxis('right').autoscaleMode;

    if (
      baseScalingMode !== options.baseScalingMode ||
      leftScalingMode !== options.leftScalingMode ||
      rightScalingMode !== options.rightScalingMode
    ) {
      // TODO: re-work updateScalingMode to not require an event as argument
      this.updateScalingMode({
        detail: {
          base: options.baseScalingMode,
          left: options.leftScalingMode,
          right: options.rightScalingMode,
        },
      });
    }

    ['left', 'right'].forEach(axis => this.updateColumnIds([], axis)); // clear what's plotted

    if (options.traces) {
      const columnIds = { left: [], right: [] };

      // FIXME: This needs to be rewitten, becuase we're adding the same data sets and groups multiple times
      // FIXME FIXME: This needs to be hoisted to a hight level, I'd suggest the app level for file import
      options.traces.forEach(async trace => {
        const { $dataWorld } = this;

        const traceAxis = trace.isRightAxisTrace ? 'right' : 'left';
        const traceId = `${trace.traceColumnId}`; // need trace id as a string

        // we use the base column to look up the dataset; the base column id
        // always represents a real column; the trace id might be derrived, e.g.,
        // curve fit trace id is id of the function model, so it could not be used
        // to look up the dataset
        const baseId = `${trace.baseColumnId}`;
        const baseColumn = $dataWorld.getColumnById(baseId);
        const dataset = $dataWorld.getDataSetByID(baseColumn.setId);

        if (dataset.type === 'regular') {
          const column = $dataWorld.getColumnById(traceId);
          if (column) columnIds[traceAxis].push(column.id);
        } else if (dataset.type === 'prediction' || dataset.type === 'graph-match') {
          // await to ensure baseColumn is there before adding prediction or graph-match trace
          const { parentNode = {} } = this; // FIXME: don't access parentNode like this
          const { updateComplete } = parentNode.host || {};
          const vstAppGraphUpdateComplete = updateComplete || Promise.resolve();
          await vstAppGraphUpdateComplete;
          await this.updateComplete;
          this.addTrace(dataset.columnIds[1], traceAxis);
        }
      });

      ['left', 'right'].forEach(axis => {
        const axisColumnIds = columnIds[axis];

        // bulk update columns
        if (axisColumnIds.length) {
          this.updateColumnIds([...new Set(axisColumnIds)], axis);
        }
      });
    }

    if (options.annotations) {
      if (!this.hasCoreAnalysis) throw new Error('vst-core-analysis not enabled');
      options.annotations.forEach(annotation => {
        this.analysisEl.importAnnotation(annotation);
      });
    }

    if (options.legend) {
      this.isLegendVisible = options.legend;
      this.dispatchGraphToolsEvent(
        null,
        {
          type: 'graph_legend',
          checked: options.legend,
        },
        true,
      );
    }

    if (options.tangent) {
      this.tangentEnabled = options.tangent;
      this?.analysisEl?.fireExamineSettingsUpdate({
        interpolate: false,
        tangentEnabled: options.tangent,
      });
    }

    // interpolate and tangent are mutually exclusive, so in the unlikely
    // case they are both set in the document, we ignore interpolate
    if (options.interpolate && !this.tangentEnabled) {
      this.interpolateEnabled = options.interpolate;
      this?.analysisEl?.fireExamineSettingsUpdate({
        interpolate: options.interpolate,
        tangentEnabled: false,
      });
    }

    this.dispatchEvent(
      new CustomEvent('graph-entity-options-update', {
        detail: { options, entity: this._graphInstance },
      }),
    );
  }

  _baseColumnIdChanged(newBaseColumnId) {
    if (!this.graphInstance) {
      return;
    }

    // TODO: clean up when we disable auto layout on imported sessions
    // if new baseColumnId represents a new baseColumnGroup, remove analysis
    if (this.analysisEl) {
      if (
        !this.baseColumnGroup ||
        !this.baseColumnGroup.columns.map(c => c.id).includes(newBaseColumnId)
      ) {
        this.dispatchEvent(new CustomEvent('obsolete-analysis'));
      }
    }

    if (!this.baseColumn || this.baseColumn.id !== newBaseColumnId) {
      const column = this.$dataWorld.getColumnById(newBaseColumnId);

      if (!column) {
        this.removeBaseColumn();
      } else {
        const columnGroup = column.group;

        // TODO: eliminate baseColumn here in favor of baseColumnGroup
        this.baseColumnGroup = columnGroup;
        this.baseColumn = column;

        columnGroup.columns.forEach(column => {
          if (this.leftColumnIds.includes(column.id)) {
            this.updateRemoveColumn(column.id, 'left');
          }

          if (this.rightColumnIds.includes(column.id)) {
            this.updateRemoveColumn(column.id, 'right');
          }
        });

        this.refreshBaseLabel();
        this.updatePlotData({ allowSpecialAutoscale: true });

        // TODO: These listeners need to be unbound too
        ['name', 'units'].forEach(key => {
          columnGroup.on(`${key}-changed`, () => {
            this.refreshBaseLabel();
          });
        });

        this.updateUdm({ baseColumnId: newBaseColumnId });
      }

      // clear previous traces and generate traces based on the new base column
      this.removeRegularTraces();
      this.leftColumnIds.forEach(columnId => this.addTrace(columnId, 'left'));
      this.rightColumnIds.forEach(columnId => this.addTrace(columnId, 'right'));

      this.dispatchEvent(new CustomEvent('regular-traces-updated'));
    }
  }

  removeBaseColumn() {
    // We can't have traces without base column, and they have to
    // removed first, while we still can get at the base column id
    this.removeRegularTraces();

    this.baseColumnGroup = null;
    this.baseColumn = null;
    this.refreshBaseLabel();
    this.updatePlotData({ allowSpecialAutoscale: true });
  }

  // FIXME: eliminate baseColumn here and make the dataSetId parameter required
  // dataSetId is currently optional
  getBaseColumn(dataSetId) {
    const { $dataWorld } = this;
    let _dataSetId = dataSetId;

    if (!this.baseColumnGroup || !$dataWorld.getDataSets || !$dataWorld.getColumnForGroupAndSet) {
      return null;
    }

    // FIXME: Remove this block, since it breaks for special data sets like FFTs
    // If no dataSetId, use the latest regular data set
    if (dataSetId === undefined || dataSetId === null) {
      const regularSets = $dataWorld.getDataSets();
      const lastSet = regularSets[regularSets.length - 1];
      _dataSetId = lastSet ? lastSet.id : null;
    }

    return $dataWorld.getColumnForGroupAndSet(this.baseColumnGroup.id, _dataSetId);
  }

  refreshBaseLabel() {
    let label = null;

    if (this.baseColumnGroup) {
      label = this.baseColumnGroup.getNameUnits();
    } else {
      label = this.defaultBottomAxisName;
    }

    this.baseColumnLabel = label;
  }

  refreshLeftLabel() {
    this.refreshVerticalAxisLabel('left');
  }

  refreshRightLabel() {
    this.refreshVerticalAxisLabel('right');
  }

  refreshVerticalAxisLabel(axis) {
    const axisLabelItems = this.generateVerticalAxisLabelItems(axis, this.defaultVerticalAxisName);

    if (axis === 'left') {
      this.leftColumnLabels = axisLabelItems;
    } else if (axis === 'right') {
      this.rightColumnLabels = axisLabelItems;
    }
  }

  generateVerticalAxisLabelItems(axis = 'left', defaultLabelText) {
    const { $dataWorld } = this;
    const axisTraces = this.traces.filter(trace => trace.axis === axis);
    const columnIdsProp = `${axis}ColumnIds`;
    const labels = [];

    if (axisTraces.length > 0) {
      axisTraces.forEach(trace => {
        const { yColumn } = trace;
        const columnGroup = yColumn.group;

        if (columnGroup) {
          const isSpecial = yColumn.special;

          if (isSpecial) {
            const dataSet = $dataWorld.getDataSetByID(yColumn.setId);
            let axisLabel;

            if (trace.type === 'fft') {
              axisLabel = getText('Amplitude');
            } else if (trace.type === 'histogram') {
              axisLabel = getText('Frequency');
            } else {
              axisLabel = dataSet.name;
            }

            labels.push({
              text: axisLabel,
              colors: [trace.color],
            });
          } else {
            labels.push({
              text: columnGroup.name,
              units: columnGroup.units,
              colors: [trace.color],
            });
          }
        }
      });

      const uniqueLabels = [];
      labels.forEach(label => {
        const match = uniqueLabels.find(uniquelabel => uniquelabel.text === label.text);
        if (match) {
          match.colors = match.colors.concat(label.colors);
        } else {
          uniqueLabels.push(label);
        }
      });

      return uniqueLabels;
    }

    if (this[columnIdsProp].length) {
      this[columnIdsProp].forEach(id => {
        const column = $dataWorld.getColumnById(id);

        if (column) {
          labels.push({
            text: column.name,
            units: column.units,
            colors: ['transparent'],
          });
        } else {
          // fallback to default left axis if something goes wrong
          labels.push({ text: defaultLabelText });
        }
      });

      return labels;
    }

    return [{ text: defaultLabelText }];
  }

  /**
   * Aggregates properties from yColumns of regular traces on given axis
   * @param {string} axis
   * @param {Object} baseRegion Only include points whose base values fall within this range
   * @returns {Object}
   */
  getVerticalMinMaxSmaxyFromZero(axis = 'left', baseRegion) {
    let min = null;
    let max = null;
    let smaxy = 0;
    let autoscaleFromZero = false;

    const traces = this.traces.filter(t => t.type !== 'graph-match').filter(t => t.axis === axis);

    traces.forEach(trace => {
      const col = this.$dataWorld.getColumnById(trace.yColumn.id);
      const values = baseRegion ? trace.getYValuesInBaseRange(baseRegion) : col.filteredValues;

      for (let i = 0; i < values.length; ++i) {
        const value = values[i];

        if (!Number.isNaN(value)) {
          if (min === null && max === null) {
            min = value;
            max = value;
          } else {
            if (value < min) {
              min = value;
            }

            if (value > max) {
              max = value;
            }
          }
        }
      }

      if (col.smaxy > smaxy) {
        smaxy = col.smaxy;
      }

      if (!autoscaleFromZero && col.autoscaleFromZero) {
        autoscaleFromZero = true;
      }
    });

    return {
      min,
      max,
      smaxy,
      autoscaleFromZero,
    };
  }

  // eslint-disable-next-line class-methods-use-this
  _autoscaleBaseLarger(options) {
    const {
      dataExtremes,
      currentRanges: { base: currentRange },
    } = options;
    let { max } = currentRange;

    const fudgeFactor = 0.00000001; // Kluge: this is an arbitrary number used to cover high precision on floating points number comparisons

    // add 20% to the base-axis max when we've exceeded the current max
    if (dataExtremes.base.max > max + fudgeFactor) {
      if (dataExtremes.base.max > max * 1.2) {
        max = dataExtremes.base.max;
      } else {
        max *= 1.2;
      }
    }

    return { min: dataExtremes.base.min, max };
  }

  // eslint-disable-next-line class-methods-use-this
  _autoscaleVerticalLarger(options) {
    const { dataExtremes, currentRanges, axis } = options;
    let { min, max } = currentRanges[axis];

    const currentRangeDelta = max - min;
    const dataMin = dataExtremes[axis].min;
    const dataMax = dataExtremes[axis].max;

    // if the y-range is greater, expand by 20%
    if (dataMin < min) {
      min = dataMin - currentRangeDelta * 0.2;
    }
    if (dataMax > max) {
      max = dataMax + currentRangeDelta * 0.2;
    }

    return { min, max };
  }

  _autoscaleBaseToFit(options) {
    const {
      currentRanges: { base: currentRange },
      dataExtremes,
    } = options;
    let { min: baseMin, max: baseMax } = dataExtremes.base;

    // Try to do something logical if only one point is on the graph
    if (this.baseColumn && this.baseColumn.values.length === 1) {
      const { min: currentBaseAxisMin } = currentRange;

      if (baseMin === baseMax) {
        baseMin = Math.min(baseMin, currentBaseAxisMin);
        baseMax = Math.max(baseMax, currentBaseAxisMin);
      }
    }

    return { min: baseMin, max: baseMax };
  }

  _autoscaleVerticalToFit(options) {
    const { dataExtremes, currentRanges, axis } = options;

    let dataMin = dataExtremes[axis].min;
    let dataMax = dataExtremes[axis].max;

    // Try to do something logical if only one point is on the graph
    if (this.baseColumn && this.baseColumn.values.length === 1) {
      const { min: currentAxisMin, max: currentAxisMax } = currentRanges[axis];

      if (dataMin === dataMax) {
        dataMin = Math.min(dataMin, currentAxisMin);
        dataMax = Math.max(dataMax, currentAxisMax);
      }
    }

    return { min: dataMin, max: dataMax };
  }

  // eslint-disable-next-line class-methods-use-this
  _autoscaleBaseNormal(options) {
    const {
      padding,
      currentRanges: { base: currentRange },
      dataExtremes,
      axis,
    } = options;
    const { min, max } = dataExtremes[axis];

    return {
      min: min !== null ? min - padding.left : currentRange.min,
      max: max !== null ? max + padding.right : currentRange.max,
    };
  }

  _autoscaleVerticalAxisNormal(options) {
    const { padding, ignoreSmaxy, currentRanges, axis, padBottomSixPercent, baseAxisRegion } =
      options;
    const currentRange = currentRanges[axis];

    const { min, max, smaxy, autoscaleFromZero } = this.getVerticalMinMaxSmaxyFromZero(
      axis,
      baseAxisRegion,
    );

    const defaultPadding = (max - min) * this.autoscalePadding;
    const extraBottomPadding = padBottomSixPercent ? (max - min) * 0.06 : 0.0;
    const totalBottomPadding = defaultPadding + padding.bottom + extraBottomPadding;
    const totalTopPadding = defaultPadding + padding.top;

    const useSmaxy = !ignoreSmaxy && smaxy !== 0;
    const bottomMargin = useSmaxy ? smaxy : totalBottomPadding;
    const topMargin = useSmaxy ? smaxy : totalTopPadding;

    const range = {
      min: min !== null ? min - bottomMargin : currentRange.min,
      max: max !== null ? max + topMargin : currentRange.max,
    };

    if (autoscaleFromZero) {
      if (Math.abs(range.max) >= Math.abs(range.min)) {
        range.min = 0;
      } else {
        range.max = 0;
      }
    }

    return range;
  }

  /**
   * Gets left range determined by applying autoscale logic to points within a base-axis range
   * @param {Object} baseAxisRegion
   * @returns {Object}
   */
  getAutoscaledLeftRange(baseAxisRegion) {
    return this._autoscaleVerticalAxisNormal({
      axis: 'left',
      currentRanges: { left: this._graphInstance.getLeftRange() },
      padding: {
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
      },
      baseAxisRegion,
    });
  }

  /**
   * Get a description of the min & max values of all plotted data for each
   * axis, including predictions and graph-matches
   */
  getDataExtremes() {
    return mergeRanges(this.traces.map(trace => trace.range));
  }

  getErrorBarExtremes() {
    return mergeRanges(
      this.traces
        // Only calculate error bar extremes when they're enabled
        .filter(
          trace =>
            trace.baseColumn.group.errorBarType !== ErrorBarType.NONE ||
            trace.yColumn.group.errorBarType !== ErrorBarType.NONE,
        )
        .map(trace => {
          const baseErrorBarsList = [];
          const yErrorBarsList = [];
          const range = createEmptyRange();

          // Separate the base and left axis error bar values
          this._generateErrorBars(trace).forEach(([baseErrorBars, yErrorBars]) => {
            if (baseErrorBars) baseErrorBarsList.push(baseErrorBars);
            if (yErrorBars) yErrorBarsList.push(yErrorBars);
          });

          // Find min and max for the base axis
          if (baseErrorBarsList && baseErrorBarsList.some(Boolean)) {
            range.base = {
              min: Math.min(...baseErrorBarsList.map(b => b[0]).filter(Number.isFinite)),
              max: Math.max(...baseErrorBarsList.map(b => b[1]).filter(Number.isFinite)),
              valid: true,
            };
          }

          // Find min and max for the left axis
          if (yErrorBarsList && yErrorBarsList.some(Boolean)) {
            range.left = {
              min: Math.min(...yErrorBarsList.map(b => b[0]).filter(Number.isFinite)),
              max: Math.max(...yErrorBarsList.map(b => b[1]).filter(Number.isFinite)),
              valid: true,
            };
          }

          return range;
        }),
    );
  }

  // Pass in an optional array of axes to only autoscale some of the axes
  getAutoscaledRanges({
    autoscaleSize = 'normal',
    autoscaleFromZero = false,
    axes,
    ignoreSmaxy,
    padding,
    padBottomSixPercent,
  } = {}) {
    // eslint-disable-next-line no-param-reassign
    padding = {
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      ...padding,
    };

    const dataExtremes = this.getDataExtremes();
    const errorBarExtremes = this.getErrorBarExtremes();

    const autoscaleAxis = axis => {
      const autoscaleRoutines = {
        'base-axis-normal': '_autoscaleBaseNormal',
        'left-axis-normal': '_autoscaleVerticalAxisNormal',
        'right-axis-normal': '_autoscaleVerticalAxisNormal',
        'base-axis-to-fit': '_autoscaleBaseToFit',
        'left-axis-to-fit': '_autoscaleVerticalToFit',
        'right-axis-to-fit': '_autoscaleVerticalToFit',
        'base-axis-larger': '_autoscaleBaseLarger',
        'left-axis-larger': '_autoscaleVerticalLarger',
        'right-axis-larger': '_autoscaleVerticalLarger',
      };

      const currentRanges = {
        base: this._graphInstance.getBaseRange(),
        left: this._graphInstance.getLeftRange(),
        right: this._graphInstance.getRightRange(),
      };

      const autoscaleRoutine = autoscaleRoutines[`${axis}-axis-${autoscaleSize}`];
      const options = {
        padding,
        ignoreSmaxy,
        dataExtremes,
        currentRanges,
        axis,
        padBottomSixPercent,
      };

      if (autoscaleRoutine && this[autoscaleRoutine]) {
        const result = this[autoscaleRoutine](options);
        if (autoscaleFromZero && axis !== 'base') {
          result.min = Math.min(result.min, 0);
          result.max = Math.max(result.max, 0);
        }

        // Make room for error bars
        result.min = Math.min(result.min, errorBarExtremes[axis].min);
        result.max = Math.max(result.max, errorBarExtremes[axis].max);

        return result;
      }

      return currentRanges[axis];
    };

    const axisEnabled = axis => axis !== 'right' || this.rightAxisEnabled;

    const validAxes = ['base', 'left', 'right'];
    const axesRequested =
      axes && axes.length ? axes.filter(axis => validAxes.includes(axis)) : validAxes;
    const axesToAutoscale = axesRequested
      .filter(axisEnabled)
      .filter(axis => dataExtremes[axis].valid);

    return axesToAutoscale.reduce(
      (rangeUpdates, axis) => ({
        ...rangeUpdates,
        [axis]: autoscaleAxis(axis),
      }),
      {},
    );
  }

  autoscale(args = {}, actor = 'automatic') {
    const _args = args;
    if (this.options.appearance.bars) {
      _args.autoscaleFromZero = true;
    }
    const axisRanges = this.getAutoscaledRanges(_args);

    const ranges = mapKeys(axisRanges, (_, axis) => `${axis}Range`); // keyed by Redux state range props, i.e. 'baseRange', etc.

    if (actor === Actor.AUTOMATIC) {
      this.updateAutomaticAutoscale(ranges);
    } else if (actor === Actor.USER) {
      this.updateUserAutoscale(ranges);
    }
  }

  enableScaleLargerToNewData() {
    this.scaleLargerToNewData = true;
  }

  disableScaleLargerToNewData() {
    this._throttleUpdate?.flush();
    this.scaleLargerToNewData = false;
  }

  enableFitToNewData() {
    this.fitToNewData = true;
  }

  disableFitToNewData() {
    this.fitToNewData = false;
  }

  getTraceDataSetIds() {
    const dataSetIds = new Set();

    [...this.leftColumnIds, ...this.rightColumnIds].forEach(columnId => {
      const column = this.$dataWorld.getColumnById(columnId);
      if (column) dataSetIds.add(column.setId);
    });
    return Array.from(dataSetIds.values());
  }

  _toggleGraphToolsPopover() {
    this.graphToolsPopoverEl.show();
  }

  get _canStrikeThrough() {
    return this.tempSelection;
  }

  get _canRestoreAll() {
    if (!this.$dataWorld) return false;
    const dataSetIds = this.getTraceDataSetIds();
    return dataSetIds.some(dataSetId => this.$dataWorld.checkHasStruckRowsForDataSet(dataSetId));
  }

  // call this anytime the plot's axis changes
  // TODO: this needs to be called on graph internal changes too (anytime the grid gets recomputed
  _recalcBoxes() {
    const root = this.shadowRoot.querySelector('#graph');
    const chartCanvas = this.shadowRoot.querySelector('#chart_canvas');
    if (this.graphInstance) {
      const plotBox = this.shadowRoot.querySelector('#plot_box');
      const chartRect = this.graphInstance.plot.chartArea;

      // correctly size the plotBox over the underlying plot
      plotBox.style.height = `${chartRect.height}px`;
      plotBox.style.width = `${chartRect.width}px`;
      plotBox.style.left = `${chartRect.left + chartCanvas.closest('#graph_box').offsetLeft}px`;
      plotBox.style.top = `${chartRect.top + chartCanvas.offsetTop}px`;
    }

    this.traces.forEach(trace => trace.trimAllSeriesData());

    // clip the base and left-axis label text
    // Note: We rotate the left-axis label by -90 degrees via a CSS
    // transform, bounds calculations don't take this into account
    // Therefore the left-axis label's "max-inline-size" is height.
    // this.el.baseAxisLabel.css({'max-inline-size': baseEventParent.width() });
    root.querySelector('.left-axis-label-wrapper').style.width = `${chartCanvas.offsetHeight}px`; // see note above
    root.querySelector('.right-axis-label-wrapper').style.width = `${chartCanvas.offsetHeight}px`; // see note above

    if (this.analysisEl) {
      this.analysisEl.updateExamineAndTangent(); // reposition examine pin
      this.dispatchEvent(new CustomEvent('update-all-curve-fits'));
    }
  }

  static get styles() {
    return [
      globalStyles,
      menuStyles,
      truncateTextStyles,
      vstCoreGraph,
      css`
        #graph_tools_menu {
          max-block-size: 75vh;
          overflow-y: auto;
          min-inline-size: 11.25rem;
          max-inline-size: 25rem;
        }
      `,
    ];
  }

  render() {
    const disableAddAnnotation = this.$dataWorld?.getSession()?.isCollecting;
    const disableCurveFit = this.containsCategorical || !this.hasTrace || this.isSessionEmpty;
    const disableManualFit =
      this.manualFits.length >= 3 || this.getLeftTraces().length === 0 || this.containsCategorical;
    const disableFFT =
      this.containsCategorical || !this.hasTrace || this.isSessionEmpty || this.rightAxisEnabled;
    const disableHistogram = !this.hasTrace || this.isSessionEmpty || this.rightAxisEnabled;
    const disableIntegral = this.containsCategorical || !this.hasTrace || this.isSessionEmpty;
    const disableRestoreAll = !this._canRestoreAll;
    const disableStatistics = !this.hasTrace || this.isSessionEmpty;
    const disableStrikethrough = !this._canStrikeThrough;
    const hideAddAnnotation = !this.analysisEl || this.isInFFTMode || this.isInHistogramMode;
    const hideCurveFit =
      !this.hasCoreAnalysis ||
      (this.isInHistogramMode && !isFeatureFlagEnabled('ff-ga-histogram-analysis'));
    const hideFFT = !this.hasCoreAnalysis || !this.enableFft || this.isInHistogramMode;
    const hideHistogram = !this.hasCoreAnalysis || !this.enableHistogram || this.isInHistogramMode;
    const hideIntegral =
      !this.hasCoreAnalysis ||
      (this.isInHistogramMode && !isFeatureFlagEnabled('ff-ga-histogram-analysis')) ||
      (APP_ID === 'IA' && this.$dataWorld.sessionSubtype !== 'ia-cvs-be');
    const hideStatistics =
      !this.hasCoreAnalysis ||
      (this.isInHistogramMode && !isFeatureFlagEnabled('ff-ga-histogram-analysis'));
    const hideStrikethrough = APP_ID === 'IA' || APP_ID === 'SA';
    this._graphOptionsContextProvider.setValue({
      disableAddAnnotation,
      disableCurveFit,
      disableFFT,
      disableHistogram,
      disableIntegral,
      disableRestoreAll,
      disableStatistics,
      disableStrikethrough,
      hideAddAnnotation,
      hideCurveFit,
      hideFFT,
      hideHistogram,
      hideIntegral,
      hideStatistics,
      hideStrikethrough,
    });

    return html`
      <div class="graph" id="graph">
        ${this?.options?.title
          ? html` <h2 class="title" id="graph_title">${this.options.title}</h2> `
          : ''}
        <div class="graph-wrapper">
          <div class="graph-left-axis">
            <div class="left-axis-label-wrapper">
              <button
                aria-label="${getText('Left Axis')}"
                id="left_axis_label"
                class="left-axis-label"
                @click="${() => this._togglePlotManagerPopover('left')}"
                ?disabled="${this.disableAxisLabelBtns}"
              >
                ${this.leftColumnLabels.map(
                  label => html`
                    <div class="left-axis-label__colname-color-wrapper">
                      <div class="left-axis-label__color-indicator-wrapper">
                        ${label.colors
                          ? label.colors.map(
                              color => html`
                                <div
                                  class="left-axis-label__color-indicator"
                                  .style="background-color:${color}"
                                ></div>
                              `,
                            )
                          : ''}
                      </div>
                      <div class="left-axis-label__colname-wrapper">
                        <span class="left-axis-label__colname">
                          ${this.shouldTruncateLeftLabels
                            ? truncateText({
                                strEls: this.shadowRoot.querySelectorAll(
                                  '.left-axis-label__colname',
                                ),
                                str: label.text,
                              })
                            : label.text}
                        </span>
                        ${label.units
                          ? html` <span class="left-axis-label__colunits">(${label.units})</span> `
                          : ''}
                      </div>
                    </div>
                  `,
                )}
              </button>
            </div>
            ${conditionalTemplate(
              !this.disableAxisLabelBtns,
              html`
                <vst-ui-popover
                  placement="right"
                  id="graph_left_plot_manager_popover"
                  for="left_axis_label"
                >
                  <vst-core-graph-plot-manager
                    .dataSets="${this.plotManagerTraceList?.dataSets}"
                    .disableDelete=${this.$dataWorld?.sessionType === 'DataShare'}
                    .predictions="${this.plotManagerTraceList?.predictions}"
                    .graphMatches="${this.plotManagerTraceList?.graphMatches}"
                    .columns="${this.plotManagerTraceList?.columns}"
                    @column-deleted="${this.plotManagerColumnDeleted}"
                    @column-selected="${this.plotManagerColumnSelected}"
                    @column-deselected="${this.plotManagerColumnDeselected}"
                    @column-trace-updated="${this.plotManagerColumnTraceUpdated}"
                    @dataset-deleted=${this._handleDataSetDeleted}
                    @special-dataset-selected="${this.plotManagerSpecialDatasetSelected}"
                    @special-dataset-deselected="${this.plotManagerSpecialDatasetDeselected}"
                    @show-data-set-options="${this.plotManagerShowDataSetOptions}"
                    @show-prediction-options="${this.plotManagerShowPredictionOptions}"
                    @show-graph-match-options="${this.plotManagerShowGraphMatchOptions}"
                  ></vst-core-graph-plot-manager>
                </vst-ui-popover>
              `,
            )}
          </div>
          <div id="graph_box" class="flex-col">
            <div class="graph-placeholder">
              <canvas id="chart_canvas"></canvas>
            </div>
            <div class="base-axis-wrapper">
              <div class="graph-baseAxis graph-base-axis">
                <button
                  aria-label="${getText('Base Axis')}"
                  class="base-axis-label"
                  @click="${this.baseAxisClicked}"
                  ?disabled="${this.disableAxisLabelBtns}"
                  id="base_column_label"
                >
                  ${this.baseColumnLabel}
                </button>
                <vst-ui-tooltip
                  for="#base_column_label"
                  placement="top"
                  content="${getText('Change x-axis')}"
                ></vst-ui-tooltip>
              </div>
              <slot name="fft-btn-group"></slot>
              <slot name="histogram-btn-group"></slot>
            </div>
          </div>
          <div class="graph-right-axis" ?hidden="${!this.rightAxisEnabled}">
            <div class="right-axis-label-wrapper">
              <button
                aria-label="${getText('Right Axis')}"
                id="right_axis_label"
                class="right-axis-label"
                @click="${() => this._togglePlotManagerPopover('right')}"
                ?disabled="${this.disableAxisLabelBtns}"
              >
                ${this.rightColumnLabels.map(
                  label => html`
                    <div class="right-axis-label__colname-color-wrapper">
                      <div class="right-axis-label__color-indicator-wrapper">
                        ${label.colors
                          ? label.colors.map(
                              color => html`
                                <div
                                  class="right-axis-label__color-indicator"
                                  .style="background-color:${color}"
                                ></div>
                              `,
                            )
                          : ''}
                      </div>
                      <div class="right-axis-label__colname-wrapper">
                        <span class="right-axis-label__colname">
                          ${this.shouldTruncateRightLabels
                            ? truncateText({
                                strEls: this.shadowRoot.querySelectorAll(
                                  '.right-axis-label__colname',
                                ),
                                str: label.text,
                              })
                            : label.text}
                        </span>
                        ${label.units
                          ? html` <span class="right-axis-label__colunits">(${label.units})</span> `
                          : ''}
                      </div>
                    </div>
                  `,
                )}
              </button>
            </div>
            ${conditionalTemplate(
              this.rightAxisEnabled && !this.disableAxisLabelBtns,
              html`
                <vst-ui-popover
                  placement="left"
                  id="graph_right_plot_manager_popover"
                  for="right_axis_label"
                >
                  <vst-core-graph-plot-manager
                    .dataSets="${this.plotManagerTraceList?.dataSets}"
                    .disableDelete=${this.$dataWorld?.sessionType === 'DataShare'}
                    .predictions="${this.plotManagerTraceList?.predictions}"
                    .graphMatches="${this.plotManagerTraceList?.graphMatches}"
                    .columns="${this.plotManagerTraceList?.columns}"
                    @column-deleted="${this.plotManagerColumnDeleted}"
                    @column-selected="${this.plotManagerColumnSelected}"
                    @column-deselected="${this.plotManagerColumnDeselected}"
                    @column-trace-updated="${this.plotManagerColumnTraceUpdated}"
                    @dataset-deleted=${this._handleDataSetDeleted}
                    @special-dataset-selected="${this.plotManagerSpecialDatasetSelected}"
                    @special-dataset-deselected="${this.plotManagerSpecialDatasetDeselected}"
                    @show-data-set-options="${this.plotManagerShowDataSetOptions}"
                    @show-prediction-options="${this.plotManagerShowPredictionOptions}"
                    @show-graph-match-options="${this.plotManagerShowGraphMatchOptions}"
                  ></vst-core-graph-plot-manager>
                </vst-ui-popover>
              `,
            )}
          </div>
          <div id="event_box">
            <div id="plot_box">
              ${when(
                APP_ID !== 'IA' &&
                  this.tempSelection &&
                  !this.tempSelection.highlightOnly &&
                  !this.disableMenu &&
                  !this.isInFFTMode &&
                  !this.isInHistogramMode,
                () => html`
                  <vst-ui-graph-context-menu
                    style="--left:${this._contextMenuLeft}px"
                  ></vst-ui-graph-context-menu>
                `,
              )}
              <slot name="mini_graph"></slot>
              <slot name="graph_legend"></slot>
              <slot name="analysis"></slot>
              <div class="rainbow-wrapper">
                <slot name="rainbow"></slot>
              </div>
            </div>
          </div>
          ${this.rightAxisEnabled
            ? html`
                <vst-ui-tooltip
                  for="#left_axis_label"
                  placement="right"
                  content="${getText('Change left y-axis')}"
                ></vst-ui-tooltip>
              `
            : html`
                <vst-ui-tooltip
                  for="#left_axis_label"
                  placement="right"
                  content="${getText('Change y-axis')}"
                ></vst-ui-tooltip>
              `}
          <vst-ui-tooltip
            for="#right_axis_label"
            placement="left"
            content="${getText('Change right y-axis')}"
          ></vst-ui-tooltip>
        </div>
      </div>

      <div class="graph-actions">
        ${conditionalTemplate(
          !this.hideGraphActionBtns && !this.disableGraphTools,
          html`
            <vst-style-tooltip slot="graph-tools">
              <button
                aria-label="${getText('Graph Options')}"
                class="btn"
                id="graph_tools_btn"
                variant="graph"
                @click="${this._toggleGraphToolsPopover}"
              >
                <vst-ui-icon .icon="${graphOptions}"></vst-ui-icon>
              </button>
              <span role="tooltip" position="block-start-start">${getText('Graph Options')}</span>
            </vst-style-tooltip>
          `,
        )}
        ${this.hideGraphActionBtns
          ? ''
          : html`
              <vst-style-tooltip id="autoscale_btn_container">
                <button
                  aria-label="${getText('Zoom to all Data')}"
                  class="btn"
                  variant="graph"
                  id="autoscale_btn"
                  @click="${this.onAutoscaleButtonClick}"
                >
                  <vst-ui-icon .icon="${zoomAutoscale}"></vst-ui-icon>
                </button>
                <span
                  role="tooltip"
                  position="${this.disableGraphTools ? 'block-start-start' : 'block-start'}"
                  >${getText('Zoom to all Data')}</span
                >
              </vst-style-tooltip>
            `}
        <slot name="additional-action-btn" class="graph-actions__btn"></slot>
      </div>
      <vst-ui-popover
        placement="right"
        id="graph_tools_popover"
        for="graph_tools_btn"
        ?hidden="${this.disableMenu}"
      >
        <ul class="menu" id="graph_tools_menu">
          <li ?hidden="${this.isInHistogramMode}">
            <vst-ui-switch
              id="graph_legend"
              label="${getText('Graph Legend')}"
              label-placement="left"
              ?checked="${this.isLegendVisible}"
              ?disabled="${this.isLegendDisabled}"
              @switch-changed="${e =>
                this.dispatchGraphToolsEvent(
                  e,
                  {
                    type: 'graph_legend',
                    checked: e.target.checked,
                  },
                  true,
                )}"
            ></vst-ui-switch>
          </li>
          <li ?hidden="${this.isInHistogramMode || !this.miniGraphSupported}">
            <vst-ui-switch
              id="mini_graph"
              label="${getText('Graph Inset')}"
              label-placement="left"
              ?checked="${this.isMiniGraphVisible}"
              ?disabled="${this.isMiniGraphDisabled}"
              @switch-changed="${e =>
                this.dispatchGraphToolsEvent(
                  e,
                  {
                    type: 'mini_graph',
                    checked: e.target.checked,
                  },
                  true,
                )}"
            ></vst-ui-switch>
          </li>

          ${conditionalTemplate(
            this.isInFFTMode || this.isInHistogramMode,
            nothing,
            html`
              <li class="menu-divider" role="presentation"></li>
              <li ?hidden="${!this.hasCoreAnalysis}">
                <vst-ui-switch
                  id="interpolate"
                  label="${getText('Interpolate')}"
                  label-placement="left"
                  ?checked="${this.interpolateEnabled}"
                  @switch-changed="${this._onInterpolateSwitchChanged}"
                  ?disabled="${this.containsCategorical}"
                ></vst-ui-switch>
              </li>
              <li ?hidden="${!this.hasCoreAnalysis}">
                <vst-ui-switch
                  id="tangent"
                  label="${getText('Tangent')}"
                  label-placement="left"
                  ?checked="${this.tangentEnabled}"
                  @switch-changed="${this._onTangentSwitchChanged}"
                  ?disabled="${this.containsCategorical || !this.hasTrace || this.isSessionEmpty}"
                ></vst-ui-switch>
              </li>
            `,
          )}
          ${conditionalTemplate(
            this.isInFFTMode ||
              (this.isInHistogramMode && !isFeatureFlagEnabled('ff-ga-histogram-analysis')),
            nothing,
            html`
              <li class="menu-divider" role="presentation"></li>
              <li ?hidden=${hideStatistics} id="statistics">
                <button
                  @click="${e => this.dispatchGraphToolsEvent(e, { type: 'statistics' })}"
                  ?disabled="${disableStatistics}"
                >
                  ${getText('View Statistics')}
                </button>
              </li>
              <li ?hidden=${hideIntegral}>
                <button
                  id="integral"
                  ?disabled=${disableIntegral}
                  @click="${e => this.dispatchGraphToolsEvent(e, { type: 'integral' })}"
                >
                  ${getText('View Integral')}
                </button>
              </li>
              <li ?hidden=${hideCurveFit}>
                <button
                  id="curve_fit"
                  ?disabled=${disableCurveFit}
                  @click="${e =>
                    this.dispatchGraphToolsEvent(e, {
                      target: this.graphToolsBtnEl,
                      type: 'curve_fit',
                    })}"
                >
                  ${getText('Apply Curve Fit')}
                </button>
              </li>
              <li ?hidden="${hideFFT}">
                <button
                  id="apply_fft_btn"
                  ?disabled=${disableFFT}
                  @click=${e => this.dispatchGraphToolsEvent(e, { type: 'fft' }, !this.authorized)}
                >
                  ${getText('Apply FFT')}
                </button>
              </li>
              <li ?hidden=${hideHistogram}>
                <button
                  id="apply_histogram_btn"
                  ?disabled="${disableHistogram}"
                  @click="${e =>
                    this.dispatchGraphToolsEvent(e, { type: 'histogram' }, !this.authorized)}"
                >
                  ${getText('Apply Histogram')}
                </button>
              </li>
              <li ?hidden=${hideStrikethrough}>
                <button
                  id="apply-strikethrough"
                  ?disabled=${disableStrikethrough}
                  @click=${e =>
                    this.dispatchGraphToolsEvent(e, { type: 'strikethrough' }, !this.authorized)}
                >
                  ${getText('Strikethrough')}
                </button>
              </li>
              <li ?hidden=${hideStrikethrough}>
                <button
                  id="restore-all-data"
                  ?disabled=${disableRestoreAll}
                  @click=${e =>
                    this.dispatchGraphToolsEvent(e, { type: 'restore-all-data' }, !this.authorized)}
                >
                  ${getText('Restore All')}
                </button>
              </li>
            `,
          )}

          <li class="menu-divider" role="presentation"></li>

          <li ?hidden="${hideAddAnnotation}" id="annotation">
            <button
              id="add-annotation-btn"
              @click=${e => this.dispatchGraphToolsEvent(e, { type: 'annotation' })}
              ?disabled="${disableAddAnnotation}"
            >
              ${getText('Add Annotation')}
            </button>
          </li>
          <li
            ?hidden=${!isFeatureFlagEnabled('ff-manual-linear-fit') ||
            this.isInFFTMode ||
            this.isInHistogramMode}
          >
            <button
              id="add-manual-fit"
              @click=${this._handleAddManualFitClicked}
              ?disabled=${disableManualFit}
            >
              <div class="inline" style="--justify: space-between;">
                <div>
                  ${getText('Add Manual Fit')}
                  ${when(
                    this.leftColumnIds.length > 1,
                    () =>
                      html`<vst-ui-icon .icon=${arrowRight} style="width: 10px;"></vst-ui-icon>`,
                  )}
                </div>
              </div>
            </button>
            <vst-ui-popover
              placement="right"
              id="manual-fit-traces-popover"
              for="add-manual-fit"
              ${ref(this._addGraphManualFitTracesPopoverRef)}
            >
              <ul class="menu">
                ${repeat(
                  this._manualFitTargets,
                  manualFitTarget => manualFitTarget.trace.id,
                  manualFitTarget => html`
                    <li>
                      <button
                        type="button"
                        @click=${e =>
                          this.dispatchGraphToolsEvent(e, {
                            type: 'manual-fit',
                            trace: manualFitTarget.trace,
                          })}
                      >
                        <div class="inline">
                          <div>
                            ${manualFitTarget.name}&nbsp;
                            <vst-style-tooltip style="--background-color: var(--vst-color-brand);">
                              <vst-ui-icon
                                size="xs"
                                .icon="${info}"
                                color="var(--vst-color-fg-tertiary)"
                              ></vst-ui-icon>
                              <span role="tooltip" position="block-start-start">
                                ${getText(`
                                  The selected trace will be used for RMSE
                                  calculation against the model line.
                                `)}
                              </span>
                            </vst-style-tooltip>
                          </div>
                        </div>
                      </button>
                    </li>
                  `,
                )}
              </ul>
            </vst-ui-popover>
          </li>
          <li ?hidden="${!this.predictionsEl || this.isInFFTMode || this.isInHistogramMode}">
            <button
              id="prediction"
              @click="${e => this.dispatchGraphToolsEvent(e, { type: 'prediction' })}"
              ?disabled="${this.$dataWorld?.getSession()?.isCollecting}"
            >
              ${getText('Add Prediction')}
            </button>
          </li>
          <li
            ?hidden="${this?.$dataWorld?.getGraphMatchColumns().length === 0 ||
            this?.$dataCollection?.mode !== 'time-based' ||
            this.isInFFTMode ||
            this.isInHistogramMode}"
          >
            <button
              id="graph_match"
              @click="${e =>
                this.dispatchGraphToolsEvent(e, {
                  target: this.graphToolsBtnEl,
                  type: 'graph_match',
                })}"
              ?disabled="${this.$dataWorld?.getSession()?.isCollecting}"
            >
              ${getText('Add Graph Match')}
            </button>
          </li>
          <li>
            <button
              id="graph_options"
              @click="${e =>
                this.dispatchGraphToolsEvent(e, {
                  target: this.graphToolsBtnEl,
                  type: 'graph_options',
                })}"
            >
              ${getText('Edit Graph Options')}
            </button>
          </li>
        </ul>
      </vst-ui-popover>
      ${conditionalTemplate(
        this.disableMenu,
        nothing,
        html`
          <vst-ui-popover
            id="strikethrough-pro-preview-popover"
            for="apply-strikethrough"
            placement="right"
            ${ref(this._strikethroughProOnlyPopoverRef)}
          >
            <p class="body" margin="s">
              ${getText('Activate to strikethough rows')}
              <vst-ui-pro-info></vst-ui-pro-info>
            </p>
          </vst-ui-popover>
          <vst-ui-popover
            id="fft_pro_preview_popover"
            for="apply_fft_btn"
            placement="right"
            ${ref(this._fftProOnlyPopoverRef)}
          >
            <p class="body" margin="s">
              ${getText('Activate to apply FFT')}
              <vst-ui-pro-info></vst-ui-pro-info>
            </p>
          </vst-ui-popover>
          <vst-ui-popover
            id="histogram_pro_preview_popover"
            for="apply_histogram_btn"
            placement="right"
            ${ref(this._histogramProOnlyPopoverRef)}
          >
            <p class="body" margin="s">
              ${getText('Activate to apply Histogram')}
              <vst-ui-pro-info></vst-ui-pro-info>
            </p>
          </vst-ui-popover>
          <vst-ui-popover
            id="restore-all-pro-preview-popover"
            for="restore-all-data"
            placement="right"
            ${ref(this._restoreAllProOnlyPopoverRef)}
          >
            <p class="body" margin="s">
              ${getText('Activate to restore all struck rows')}
              <vst-ui-pro-info></vst-ui-pro-info>
            </p>
          </vst-ui-popover>
          <vst-ui-popover
            id="manual-fit-pro-preview-popover"
            for="add-manual-fit"
            placement="right"
            ${ref(this._manualFitProOnlyPopoverRef)}
          >
            <p class="body" margin="s">
              ${getText('Activate to add manual fit')}
              <vst-ui-pro-info></vst-ui-pro-info>
            </p>
          </vst-ui-popover>
        `,
      )}
      <slot name="predictions"></slot>
    `;
  }
}

customElements.define('vst-core-graph', VstCoreGraph);

import { uniqueId, pick } from 'lodash-es';
import { formatter, sigFig } from '@utils/formatter.js';
import { vstCurvefitStore } from '@common/mobx-stores/vst-curvefit.store.js';
import { rotateColor } from '@utils/colorHelpers.js';
import { getText } from '@utils/i18n.js';

export const makeAnalysisForSelectionsMethod = (
  dataAnalysis,
  graphMethods,
  getUnitsForColumnId,
) => {
  const _applyManualFitsForSelection = async selection => {
    const analysisData = {
      traceDataSets: [],
      analysisItems: [],
      infoBoxData: {},
    };
    const { tracesInfo = [] } = selection;

    const manualFit = dataAnalysis.dataWorld.manualFits.find(
      fit => fit.id === selection.manualFitId,
    );
    const { baseColumnId, coefficients, fitType, yColumnId } = manualFit;
    const trace = tracesInfo.find(
      trace => parseInt(trace.xid, 10) === baseColumnId && parseInt(trace.yid, 10) === yColumnId,
    );

    // Traces list can be empty early in the update during export
    if (tracesInfo.length === 0 || !trace) return analysisData;

    const manualFitsForTrace = dataAnalysis.dataWorld.manualFits.filter(
      manualFit =>
        trace.xid === `${manualFit.baseColumnId}` && trace.yid === `${manualFit.yColumnId}`,
    );
    const manualFitIndex = manualFitsForTrace.findIndex(fit => fit === manualFit);
    const range = graphMethods.getBaseRange();
    const fx = (m, x, b) => m * x + b;
    function rmse(fitValues, targetValues) {
      const sumOfSquaredDifferences = fitValues.reduce(
        (sum, value, i) => sum + (value - targetValues[i]) ** 2,
        0,
      );
      const averageSquaredDifference = sumOfSquaredDifferences / fitValues.length;
      return Math.sqrt(averageSquaredDifference);
    }

    const [slope, intercept] = coefficients;
    const { coefficients: coefficientLabels, y } = vstCurvefitStore.getFitById(fitType);
    const xColumn = dataAnalysis.dataWorld.getColumnById(baseColumnId);
    const yColumn = dataAnalysis.dataWorld.getColumnById(yColumnId);
    const p0 = [range.min, fx(slope, range.min, intercept)];
    const p1 = [range.max, fx(slope, range.max, intercept)];
    const traceColor = rotateColor(trace.traceColor, manualFitIndex * 60 + 60);
    const rmseValue = rmse(
      xColumn.filteredValues.map(x => fx(slope, x, intercept)),
      yColumn.filteredValues,
    );

    manualFit.setStats({ rmse: rmseValue });

    analysisData.analysisItems.push({
      ...trace,
      coeffs: coefficientLabels,
    });

    analysisData.traceDataSets.push({
      dataPoints: [p0, p1],
      traceColor,
      yColumnId: trace.yid,
      axis: trace.axis,
    });

    analysisData.infoBoxData = {
      curveFitName: getText('Manual Fit'),
      manualFit,
      showUncertainty: false,
      relatedTrace: trace,
      curveFitInfo: [
        {
          coefficients: [
            { name: 'm', value: sigFig(slope, 5) },
            { name: 'b', value: sigFig(intercept, 5) },
          ],
          rmse: sigFig(rmseValue, 5),
          traceColor,
          y,
          yUnits: yColumn.units,
        },
      ],
    };

    return analysisData;
  };

  // TODO: use await rather than promise syntax here
  const _applyCurveFitsForSelection = selection => {
    const { range, curveFitType, tracesInfo = [], showUncertainty } = selection;

    const fitDescription = vstCurvefitStore.getFit(curveFitType);
    const curveFitName = fitDescription ? getText(fitDescription.name, 'mathematical') : '';
    const numOfCoeffs = fitDescription ? fitDescription.coefficients.length : 0;

    const traceDataSets = [];
    const infoBoxFitsData = [];
    const analysisItems = [];

    // calculate and apply curve fits
    const promises = tracesInfo.map(async trace => {
      try {
        const xUnits = getUnitsForColumnId(trace.xid) || '';
        const yUnits = getUnitsForColumnId(trace.yid) || '';
        await dataAnalysis.vstAnalysis.setUseRadians(xUnits !== '°');

        const result = await dataAnalysis.calculateFitByType(
          curveFitType,
          trace.xid,
          trace.yid,
          range,
        );

        if (!result?.error) {
          const { coefficients = [], fitInfo = {} } = result;
          // for infobox
          fitInfo.traceColor = trace.traceColor;
          fitInfo.yUnits = yUnits;

          const graphRange = graphMethods.getBaseRange();
          const pixelDelta = Math.abs(graphRange.max - graphRange.min) / graphMethods.getPxWidth();

          const traceData = await dataAnalysis.computeTraceByType(
            curveFitType,
            coefficients,
            graphRange,
            pixelDelta,
          );

          infoBoxFitsData.push(fitInfo);
          traceDataSets.push({
            dataPoints: traceData.points,
            traceColor: trace.traceColor,
            yColumnId: trace.yid,
            axis: trace.axis,
          });
          analysisItems.push({
            ...trace,
            coeffs: coefficients,
            rmse: Number(fitInfo.rmse),
            correlation: Number(fitInfo.r),
          });
        } else {
          infoBoxFitsData.push({ traceColor: trace.traceColor, error: result.error });
          traceDataSets.push({
            dataPoints: [],
            traceColor: trace.traceColor,
            yColumnId: trace.yid,
            axis: trace.axis,
          });
          analysisItems.push({
            ...trace,
            coeffs: new Array(numOfCoeffs), // empty array
          });
        }
      } catch (err) {
        console.error(err);
        traceDataSets.push({
          dataPoints: [],
          traceColor: 'rgba(0,0,0,1)',
          yColumnId: trace.yid,
          axis: trace.axis,
        });
      }
    });

    return Promise.all(promises).then(() => ({
      traceDataSets,
      analysisItems,
      infoBoxData: {
        curveFitName,
        showUncertainty,
        curveFitInfo: infoBoxFitsData,
      },
    }));
  };

  const _applyIntegralsForSelection = selection => {
    const { range } = selection;

    const traceDataSets = [];
    const infoBoxData = [];
    const analysisItems = [];

    selection.tracesInfo.forEach(trace => {
      const result = dataAnalysis.calculateIntegralAndGetData(trace.xid, trace.yid, range) || {};

      if (result.success) {
        const xUnits = getUnitsForColumnId(trace.xid) || '';
        const yUnits = getUnitsForColumnId(trace.yid) || '';
        const unitSeparator = xUnits.length && yUnits.length ? '⋅' : ''; // use the floating dot symbol (U+22C5) to indicate multiplication of units

        traceDataSets.push({
          dataPoints: result.integralData,
          traceColor: trace.traceColor,
          axis: trace.axis,
        });

        infoBoxData.push({
          integralValue: formatter.getValue(result.integralValue),
          traceColor: trace.traceColor,
          units: `${yUnits}${unitSeparator}${xUnits}`,
        });

        analysisItems.push({ ...trace });
      } else {
        traceDataSets.push({
          dataPoints: [],
          traceColor: 'rgba(0,0,0,1)',
          axis: trace.axis,
        });

        analysisItems.push({ ...trace });

        infoBoxData.push({});
      }
    });

    return Promise.resolve({ traceDataSets, infoBoxData, analysisItems });
  };

  const _applyStatisticsForSelection = selection => {
    const { range, isRangeIndexBased } = selection;
    const analysisItems = [];

    // an index-based range is used for stats on text-valued categorical data, so we omit deltaX in that case
    const infoBoxData = {
      xStats: {
        deltaX: !isRangeIndexBased ? formatter.getValue(range.max - range.min) : '',
      },
      yStats: [],
    };

    selection.tracesInfo.forEach(trace => {
      const { success, stats, error } = dataAnalysis.calculateStatistics(
        trace.xid,
        trace.yid,
        range,
        isRangeIndexBased,
      );

      if (success) {
        const yUnits = getUnitsForColumnId(trace.yid) || '';

        analysisItems.push({ ...trace });

        // handle both numeric and categorical data appropriately
        const getFormattedXVal = xVal =>
          typeof xVal === 'number' ? formatter.getValue(xVal) : `"${xVal}"`;

        // for the info box
        infoBoxData.yStats.push({
          yUnits,
          traceColor: trace.traceColor,
          deltaY: formatter.getValue(stats.max.y - stats.min.y),
          samples: stats.points.length,
          mean: formatter.getValue(stats.mean),
          stdDev: formatter.getValue(stats.stdDev),
          min: {
            value: formatter.getValue(stats.min.y),
            at: getFormattedXVal(stats.min.x),
          },
          max: {
            value: formatter.getValue(stats.max.y),
            at: getFormattedXVal(stats.max.x),
          },
        });
      } else if (error) {
        console.error(error);
      }
    });

    return Promise.resolve({ infoBoxData, analysisItems });
  };

  const getAnalysisDataForSelections = selections => {
    const selectionsData = {};
    const promises = [];

    Object.values(selections).forEach(selection => {
      const { id } = selection;

      switch (selection.analysisType) {
        case 'curveFits':
          promises.push(
            _applyCurveFitsForSelection(selection).then(data => {
              selectionsData[id] = data;
            }),
          );
          break;
        case 'integrals':
          promises.push(
            _applyIntegralsForSelection(selection).then(data => {
              selectionsData[id] = data;
            }),
          );
          break;
        case 'statistics':
          promises.push(
            _applyStatisticsForSelection(selection).then(data => {
              selectionsData[id] = data;
            }),
          );
          break;
        case 'manual-fits':
          promises.push(
            _applyManualFitsForSelection(selection).then(data => {
              selectionsData[id] = data;
            }),
          );
          break;
        default:
          promises.push(
            Promise.resolve().then(() => {
              selectionsData[id] = {};
            }),
          );
          break;
      }
    });

    return Promise.all(promises).then(() => selectionsData);
  };

  return getAnalysisDataForSelections;
};

export const makeAnalysisForSplitSelectionsMethod = (dataAnalysis, getUnitsForColumnId) =>
  function getAnalysisDataForSplitSelections(splitSelections) {
    const splitSelectionResults = {};

    Object.keys(splitSelections).forEach(selectionName => {
      const selection = splitSelections[selectionName];
      const { xid, yid, splitRegions } = selection;

      // Calculate the total range -- this gets passed into the peak integral calculation.
      const minBoundaries = splitRegions.map(region => region.minBoundary.pointVal);
      const maxBoundaries = splitRegions.map(region => region.maxBoundary.pointVal);

      const totalRange = {
        min: Math.min(...minBoundaries, ...maxBoundaries),
        max: Math.max(...minBoundaries, ...maxBoundaries),
      };

      // Calculate the peak integral info for each region
      const splitRegionsData = [];
      let peakSeriesData = [];

      splitRegions.forEach(region => {
        const subRange = {
          min: region.minBoundary.pointVal,
          max: region.maxBoundary.pointVal,
        };

        const result = dataAnalysis.calculatePeakIntegral(xid, yid, subRange, totalRange);
        if (result.success) {
          const xUnits = getUnitsForColumnId(xid) || '';
          const yUnits = getUnitsForColumnId(yid) || '';
          const unitSeparator = xUnits.length && yUnits.length ? '⋅' : ''; // use the floating dot symbol (U+22C5) to indicate multiplication of units

          splitRegionsData.push({
            integralValue: result.integralValue,
            retentionTime: result.retentionTime,
            units: `${yUnits}${unitSeparator}${xUnits}`,
            slope: result.slope,
            intercept: result.intercept,
          });

          // Prevent duplications of overlapping data points across split regions by removing the last
          // point if it shares x value with the first new point.
          if (
            peakSeriesData.length &&
            peakSeriesData[peakSeriesData.length - 1][0] === result.integralData[0][0]
          ) {
            peakSeriesData.pop();
          }
          peakSeriesData = peakSeriesData.concat(result.integralData);
        } else {
          splitRegionsData.push({});
        }
      });

      splitSelectionResults[selectionName] = {
        peakSeriesData,
        splitRegions: splitRegionsData,
      };
    });

    return splitSelectionResults;
  };

export const initAnalysisMiddleware = (dataWorld, createSelection) => {
  let middlewareAnalysis = [];
  let nextAnalysisItemId = 1;

  const updateUdm = updateType => (graphId, artifact) => {
    const udmRoutinesLookup = {
      curveFits: {
        add: dataWorld.addGraphCurveFit.bind(dataWorld),
        remove: dataWorld.removeGraphCurveFit.bind(dataWorld),
      },
      integrals: {
        add: dataWorld.addGraphIntegral.bind(dataWorld),
        remove: dataWorld.removeGraphIntegral.bind(dataWorld),
      },
      'manual-fits': {
        add: async () => {},
        remove: async () => {},
      },
      statistics: {
        add: dataWorld.addGraphStats.bind(dataWorld),
        remove: dataWorld.removeGraphStats.bind(dataWorld),
      },
    };

    const udmRoutine = udmRoutinesLookup[artifact.type][updateType];

    const udmParams = {
      fitId: artifact.udmId, // udm will modify not create if the udmId is already defined
      integralId: artifact.udmId,
      statsId: artifact.udmId,
      baseColumnId: artifact.xid,
      traceColumnId: artifact.yid,
      xMin: artifact.range.min,
      xMax: artifact.range.max,
    };

    if (artifact.type === 'curveFits') {
      Object.assign(
        udmParams,
        pick(artifact, ['coeffs', 'rmse', 'correlation', 'fitType', 'showUncertainty']),
      );
    }

    const udmArgument = updateType === 'remove' ? artifact.udmId : udmParams;

    return udmRoutine(graphId, udmArgument)
      .then(({ fitId, integralId, statsId } = {}) => ({
        udmId: fitId || integralId || statsId,
      }))
      .catch(err => console.error(err));
  };

  const addAnalysisToUdm = updateUdm('add');
  const removeAnalysisFromUdm = updateUdm('remove');
  const changeAnalysisInUdm = updateUdm('add');

  const updateUdmWithAppState = async (graphId, analysisSelections) => {
    // this is the set of analysis items incoming from the application
    const appAnalysis = Object.values(analysisSelections).reduce(
      (
        analysis,
        { analysisItems = [], range, analysisType, curveFitType, id, showUncertainty },
      ) => [
        ...analysis,
        ...analysisItems.map(item => ({
          ...item,
          range,
          type: analysisType,
          fitType: curveFitType,
          selectionId: id,
          showUncertainty,
        })),
      ],
      [],
    );

    const sameSelectionTraceAndType = a => b =>
      a.xid === b.xid && a.yid === b.yid && a.selectionId === b.selectionId && a.type === b.type;
    const itemAdded = item => !middlewareAnalysis.find(sameSelectionTraceAndType(item));
    const itemRemoved = item => !appAnalysis.find(sameSelectionTraceAndType(item));
    const removedAnalysis = middlewareAnalysis.filter(itemRemoved);
    const addedAnalysis = appAnalysis.filter(itemAdded);
    const changedAnalysis = appAnalysis.filter(item => !itemAdded(item));

    removedAnalysis.forEach(item => {
      const itemIndex = middlewareAnalysis.findIndex(sameSelectionTraceAndType(item));
      middlewareAnalysis.splice(itemIndex, 1);

      if (item.udmId) {
        removeAnalysisFromUdm(graphId, item);
      }
    });

    await Promise.all(
      addedAnalysis.map(async item => {
        const middlewareItemId = nextAnalysisItemId++;
        middlewareAnalysis.push({ ...item, middlewareId: middlewareItemId });

        const { udmId } = await addAnalysisToUdm(graphId, item);
        item.udmId = udmId;

        const sameMiddlewareId = item => item.middlewareId === middlewareItemId;
        const middlewareItem = middlewareAnalysis.find(sameMiddlewareId);
        const hasItemBeenRemoved = !middlewareItem;

        if (hasItemBeenRemoved) {
          removeAnalysisFromUdm(graphId, { ...middlewareItem, udmId });
        } else {
          middlewareItem.udmId = udmId;

          if (middlewareItem.udmUpdateRequested) {
            middlewareItem.udmUpdateRequested = false;
            changeAnalysisInUdm(graphId, middlewareItem);
          }
        }
      }),
    );

    await Promise.all(
      changedAnalysis.map(async item => {
        const middlewareItem = middlewareAnalysis.find(sameSelectionTraceAndType(item));

        // update any changed props
        middlewareItem.range = { ...item.range };
        middlewareItem.coeffs = item.coeffs;
        middlewareItem.rmse = item.rmse;
        middlewareItem.correlation = item.correlation;
        middlewareItem.fitType = item.fitType;
        middlewareItem.showUncertainty = item.showUncertainty;

        if (middlewareItem.udmId) {
          changeAnalysisInUdm(graphId, middlewareItem);
          item.udmId = middlewareItem.udmId;
        } else {
          middlewareItem.udmUpdateRequested = true;
        }
      }),
    );
    return { addedAnalysis, changedAnalysis };
  };

  const updateAppWithUdmState = udmState => {
    // apply curve-fits, integrals, and stats
    // HERE: this probably needs to be deleted if the persistence is going to be
    // handled by DW MobX reactions directly. But also maybe not?
    ['curveFits', 'integrals', 'manual-fits', 'statistics'].forEach(analysisType => {
      if (udmState[analysisType]) {
        const uniqueSelections = udmState[analysisType].reduce((selections, analysisUDMItem) => {
          // build up object representing unique graph selections
          const {
            range: { min, max } = {},
            fitType,
            infoBox,
            showUncertainty = false,
            id = 0,
          } = analysisUDMItem;
          const manualFitId = id !== 0 && analysisType === 'manual-fits' ? id : 0;
          const selectionKey = `${min}-${max}-${analysisType}-${fitType}${manualFitId ? '-' : ''}${
            manualFitId || ''
          }`;
          const { analysisItems = [] } = selections[selectionKey] || {};
          return {
            ...selections,
            [selectionKey]: {
              range: { min, max },
              curveFitType: fitType,
              infoBox,
              analysisItems: [...analysisItems, analysisUDMItem],
              showUncertainty,
              manualFitId,
            },
          };
        }, {});

        Object.values(uniqueSelections).forEach(selection => {
          const selectionId = uniqueId('selection-');

          selection.analysisItems.forEach(item => {
            // store analysis item descriptions including udmId in middleware state
            middlewareAnalysis.push({
              middlewareId: nextAnalysisItemId++,
              selectionId,
              type: analysisType,
              range: { ...selection.range },
              fitType: item.fitType,
              xid: `${item.baseColumnId}`,
              yid: `${item.traceColumnId}`,
              udmId: item.fitId || item.integralId || item.statisticId || item.manualFitId, // grab udmId for analysis item
              showUncertainty: selection.showUncertainty,
            });
          });

          // selection ranges on categorical data specify index not position values
          const isRangeIndexBased = selection.analysisItems.some(
            item => dataWorld.getColumnById(item.baseColumnId)?.isCategorical,
          );

          const selectionInit = {
            infoBox: selection.infoBox,
            autoGenerated: true,
            permanent: true,
            range: selection.range,
            curveFitType: selection.curveFitType,
            analysisType,
            isRangeIndexBased,
            showUncertainty: selection.showUncertainty,
            manualFitId: selection.manualFitId,
          };

          createSelection(selectionInit, () => selectionId);
        });
      }
    });
  };

  const reset = () => {
    middlewareAnalysis = [];
  };

  return {
    updateUdmWithAppState,
    updateAppWithUdmState,
    reset,
    _getAnalysisState: () => middlewareAnalysis, // use for testing only
  };
};

export const initPeakIntegralMiddleware = (dataWorld, dispatchMethods, _uniqueId) => {
  let peakCache = {};

  // Sync up the back end UDM state with peak integral information from redux.
  const updateUdmWithAppState = (graphId, analysisSelections) => {
    // Helper function strips off the region- prefix from regionIds and returns an int suitable
    // for use as a udm peakNumber value.
    const regionIdToInt = rgnId => {
      const matches = rgnId.match(/\d+$/);
      return matches ? ~~matches[0] : 0; // eslint-disable-line
    };

    if (!analysisSelections) return;

    // Iterate selections and regions, making up lists of newly added peaks, updated peaks, and removed peaks.
    const peaksToAdd = [];
    const peaksIdsToRemove = [];
    const peaksToUpdate = [];
    const incomingPeakIds = new Set();

    Object.values(analysisSelections).forEach(selection => {
      const { xid, yid, splitRegions } = selection;
      const allPeaks = [];
      splitRegions.forEach(region => {
        // Create a peak object based on the incoming selection's regions.
        const peak = {
          baseColumnId: xid,
          traceColumnId: yid,
          xMin: region.minBoundary.pointVal,
          xMax: region.maxBoundary.pointVal,
          name: region.name,
          regionId: region.id,
          leftmostId: 0,
          rightmostId: 0,
          peakNumber: regionIdToInt(region.id),
        };

        // Record the region's ID so we can later determine if a peak has been removed.
        incomingPeakIds.add(region.id);
        allPeaks.push(peak);

        // See if this peak is already in our cache. If it's not, we'll add it. If it is, we'll see if it has changed.
        const existingPeak = peakCache[region.id];
        if (existingPeak) {
          // Check for changes to the name, min, max, and peak, left, right ids.
          if (
            peak.name !== existingPeak.name ||
            peak.xMin !== existingPeak.xMin ||
            peak.xMax !== existingPeak.xMax ||
            peak.peakNumber !== existingPeak.peakNumber ||
            peak.leftmostId !== existingPeak.leftmostId ||
            peak.rightmostId !== existingPeak.rightmostId
          ) {
            peaksToUpdate.push(peak);
          }
        } else {
          peaksToAdd.push(peak);
        }
      });

      let i;
      for (i = 0; i < allPeaks.length; i += 1) {
        allPeaks[i].leftmostId = i === 0 ? 0 : regionIdToInt(allPeaks[i - 1].regionId);
        allPeaks[i].rightmostId =
          i === allPeaks.length - 1 ? 0 : regionIdToInt(allPeaks[i + 1].regionId);
      }
    });

    // Look for peaks that have been removed.
    Object.keys(peakCache).forEach(peakId => {
      if (!incomingPeakIds.has(peakId)) {
        peaksIdsToRemove.push(peakId);
      }
    });

    // Push changes to UDM.
    peaksToAdd.forEach(async peak => {
      // Cache this peak, without the udm integralId.
      peakCache[peak.regionId] = peak;
      // Ask udm to create the new peak and return a peak id
      const { integralId } = await dataWorld.addGraphPeakIntegral(graphId, peak);

      // Take the cached peak and add integralId
      const cachedPeak = { ...peakCache[peak.regionId], integralId };

      // Check if the user has deleted the peak
      if (cachedPeak.wantsRemoval) {
        // Remove peak
        delete peakCache[peak.regionId];
        dataWorld.removeGraphPeakIntegral(integralId);
        return;
      }

      // Check if the user has updated the peak.
      if (cachedPeak.wantsUpdate) {
        // Update peak, first clearing the wantsUpdate flag.
        delete cachedPeak.wantsUpdate;
        dataWorld.addGraphPeakIntegral(graphId, cachedPeak);
      }

      // And record the peak in the cache
      peakCache[peak.regionId] = cachedPeak;
    });

    peaksToUpdate.forEach(updatedPeak => {
      // Grab the integralId (udm) from the cache, and append it to the incoming peak. Missing integralId means
      // the Promise to add the peak has not yet completed.
      const { integralId } = peakCache[updatedPeak.regionId];
      if (integralId) {
        const integralUpdatedPeak = { ...updatedPeak, integralId };
        // Cache the new values and then call udm to update itself with the new information.
        peakCache[integralUpdatedPeak.regionId] = integralUpdatedPeak;
        dataWorld.addGraphPeakIntegral(graphId, integralUpdatedPeak);
      } else {
        // No integralId means it's still being added to udm, so we'll set a flag for later.
        peakCache[updatedPeak.regionId] = {
          ...updatedPeak,
          wantsUpdate: true,
        };
      }
    });

    peaksIdsToRemove.forEach(peakId => {
      // Fetch the integralId from the cache, remove the cache entry, and remove from udm.
      const cachedPeak = peakCache[peakId];
      const { integralId } = cachedPeak;
      if (integralId) {
        delete peakCache[peakId];
        dataWorld.removeGraphPeakIntegral(integralId);
      } else {
        // No integralId means we haven't returned from add. Once again, we'll set a flag for evaluation later.
        peakCache[peakId] = { ...cachedPeak, wantsRemoval: true };
      }
    });
  };

  // Sync redux to peak integral information from the newly opened file.
  const updateAppWithUdmState = importedState => {
    const { peakIntegrals } = importedState;

    if (!peakIntegrals) return;

    // buckets is an array of Sets containing integer ids corresponding to individual peak-ids.
    let buckets = [];
    // peakMap is an object containing peaks keyed by peak number.
    const peakMap = {};
    // UDM provides us with a bunch of separate peaks which we'd like to group into split integral regions.
    // We use the peak numbers and leftmost and rightmost IDs to do this.
    peakIntegrals.forEach(udmPeak => {
      const { integralId, peakNumber: _peakNumber, leftmostId, rightmostId } = udmPeak;
      // Fall back to integralId if peakNumber is 0
      const peakNumber = _peakNumber || integralId;

      // Add to the peak map. We use this later on to build a list of Redux style peaks.
      peakMap[peakNumber] = udmPeak;

      // Logic we use to add peak Ids to a bucket.
      const addToBucket = (b, n, l, r) => {
        b.add(n);
        if (l > 0) b.add(l);
        if (r > 0) b.add(r);
      };

      // Logic for filtering bucket array into matching and non-matching buckets.
      const matchBucket = (b, n, l, r) =>
        (n > 0 && b.has(n)) || (l > 0 && b.has(l)) || (r > 0 && b.has(r));

      const matchingBuckets = buckets.filter(bucket =>
        matchBucket(bucket, peakNumber, leftmostId, rightmostId),
      );

      // Consolidate buckets with matching IDs into one new bucket.
      if (matchingBuckets.length) {
        const combinedSet = new Set();
        addToBucket(combinedSet, peakNumber, leftmostId, rightmostId);
        matchingBuckets.forEach(bucket => {
          bucket.forEach(id => {
            combinedSet.add(id);
          });
        });
        // Re-assign buckets array with new consolidated ids combined with buckets with no matches.
        const nonMatchingBuckets = buckets.filter(
          bucket => !matchBucket(bucket, peakNumber, leftmostId, rightmostId),
        );
        buckets = [...nonMatchingBuckets, combinedSet];
      } else {
        // If the ids don't match any existing buckets, then we can create new bucket.
        const newBucket = new Set();
        addToBucket(newBucket, peakNumber, leftmostId, rightmostId);
        buckets.push(newBucket);
      }
    });

    buckets.forEach(bucket => {
      const splitRegions = [];
      let baseId = 0;
      let traceId = 0;
      const boundaries = [];
      bucket.forEach(peakId => {
        const peak = peakMap[peakId];

        const { range, peakName, baseColumnId, traceColumnId, integralId } = peak;
        // create split boundaries for the split selection
        const [minBoundaryId, maxBoundaryId] = [range.xMin, range.xMax].map(pointVal => {
          // Re-use boundaries with the same pointVal. This gives us contiguous selections.
          const boundary = boundaries.find(boundary => boundary.pointVal === pointVal);
          if (boundary) {
            return boundary.id;
          }

          // Create new spit boundary
          const id = _uniqueId('split-boundary-');
          const newBoundary = { pointVal, id };
          dispatchMethods.createBoundary(newBoundary);
          boundaries.push(newBoundary);
          return id;
        });

        // Save off the base and trace ids of the first peak in the bucket:
        if (baseId === 0) {
          baseId = baseColumnId;
          traceId = traceColumnId;
        }

        // Rather then re-use the peakNumber, let's create a new ID so we don't end up with a name collision.
        const regionId = _uniqueId('region-');

        // Record the peak info in the cache.
        peakCache[regionId] = {
          baseColumnId,
          traceColumnId,
          xMin: range.xMin,
          xMax: range.xMax,
          name: peakName,
          regionId,
          integralId,
        };
        // Create a split region and add it to our list
        const newSplitRegion = {
          name: peakName,
          minBoundary: minBoundaryId,
          maxBoundary: maxBoundaryId,
          xMin: range.xMin,
          id: regionId,
        };
        splitRegions.push(newSplitRegion);
      });
      // Sort split regions on xMin, then remove xMin property.
      splitRegions.sort((r1, r2) => r1.xMin - r2.xMin);
      splitRegions.forEach(r => delete r.xMin);
      // Finally tell redux to create the split integral.
      dispatchMethods.createSplit({
        xid: baseId.toString(),
        yid: traceId.toString(),
        splitRegions,
      });
    });
  };

  const reset = () => {
    peakCache = {};
  };

  return {
    updateUdmWithAppState,
    updateAppWithUdmState,
    reset,
  };
};

import { LitElement, html } from 'lit';

import { info } from '@components/vst-ui-icon/index.js';
import { getText } from '@utils/i18n.js';
import { globalStyles } from '@styles/vst-style-global.css.js';

import vstUiConnectionStatusStyles from './vst-ui-connection-status.css.js';

import '@components/vst-ui-icon/vst-ui-icon.js';
import '@components/vst-style-tooltip/vst-style-tooltip.js';

export class VstUiConnectionStatus extends LitElement {
  static get properties() {
    return {
      connectionActionText: {
        type: String,
      },
      deviceName: {
        type: String,
      },
      disconnectedText: {
        type: String,
      },
      getAttention: {
        type: Boolean,
      },
      hasConnectedDevice: {
        type: Boolean,
      },
      showDisconnectButton: {
        type: Boolean,
      },
    };
  }

  constructor() {
    super();
    this.hasConnectedDevice = false;
    this.disconnectedText = getText('No device connected.');
    this.connectionActionText = getText('Connect a Device');
    this.showDisconnectButton = false;
  }

  updated(changedProperties) {
    if (changedProperties.has('getAttention') && this.getAttention) {
      this.triggerNoDeviceHighlight();
    }
  }

  infoClicked() {
    this.dispatchEvent(new CustomEvent('device-info-clicked'));
  }

  disconnectDeviceClicked() {
    this.dispatchEvent(new CustomEvent('disconnect-device-clicked'));
  }

  triggerNoDeviceHighlight() {
    const el = this.shadowRoot.querySelector('.no-device-connected');
    if (el) {
      el.classList.remove('no-device-connected--highlight');
      void el.offsetWidth; // eslint-disable-line no-void
      el.classList.add('no-device-connected--highlight');
    }
    this.getAttention = false;
  }

  onConnectBtnClicked() {
    this.dispatchEvent(new CustomEvent('connect-btn-clicked'));
  }

  static get styles() {
    return [globalStyles, vstUiConnectionStatusStyles];
  }

  render() {
    return html`
      <div class="status ${this.hasConnectedDevice ? 'status--has-devices' : ''}">
        ${this.hasConnectedDevice
          ? html`
              <div class="device-connected__container">
                <div class="device-connected__summary">
                  <span margin="inline-end-xs">${this.deviceName} ${getText('connected')}</span>
                  <vst-style-tooltip>
                    <button
                      class="btn"
                      variant="icon"
                      type="button"
                      size="xs"
                      id="info_button"
                      @click="${this.infoClicked}"
                    >
                      <vst-ui-icon class="info-icon" .icon="${info}"> </vst-ui-icon>
                    </button>
                    <span role="tooltip" position="block-start"
                      >${getText('Connection status info')}</span
                    >
                  </vst-style-tooltip>
                </div>
                ${this.showDisconnectButton
                  ? html`
                      <button
                        id="disconnect_button"
                        class="btn"
                        variant="text"
                        color="danger"
                        @click="${this.disconnectDeviceClicked}"
                      >
                        ${getText('Disconnect')}
                      </button>
                    `
                  : ''}
              </div>
            `
          : html`
              <div class="no-device-connected">
                <p class="heading" size="s" margin="block-end-xs s-block-end-0 s-inline-end-m">
                  ${this.disconnectedText}
                </p>
                <button
                  id="connect_button"
                  class="btn"
                  type="button"
                  @click="${this.onConnectBtnClicked}"
                  size="s"
                >
                  ${this.connectionActionText}
                </button>
              </div>
            `}
      </div>
    `;
  }
}

customElements.define('vst-ui-connection-status', VstUiConnectionStatus);

import { css } from 'lit';
import { breakpoint } from '@styles/mixins/breakpoints.css.js';
import { dialogDefault } from '@styles/mixins/dialogs.css.js';

export default css`
  ${dialogDefault}

  ${breakpoint(
    'm',
    css`
      :host {
        width: 31.25rem;
      }
    `,
  )}

  .content {
    text-align: center;
    padding: 0 1.5em;
  }

  .content sup {
    font-size: 0.675em;
  }

  .content a {
    white-space: nowrap;
  }

  .autoupdate {
    margin: 0 auto;
  }

  .version-legal {
    color: var(--vst-color-fg-tertiary);
    font-size: var(--vst-font-size-xs);

    margin-bottom: 2em;
  }

  .app-logo {
    width: 23.4375rem;
    max-width: 100%;
    display: block;
    margin: 0 auto;
  }

  .vernier-logo {
    display: block;
    margin: 3em auto 1em;
    width: 9.125rem;
    max-width: 100%;
  }

  .terms {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .terms__links {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-block-end: var(--vst-space-xs);
  }

  .link {
    margin-block-end: var(--vst-space-xs);
    text-align: center;
  }

  ${breakpoint(
    's',
    css`
      .terms__links {
        display: grid;
        grid-template-columns: repeat(3, auto);
        justify-content: center;
        grid-gap: var(--vst-space-xs);
      }

      .link {
        margin: 0;
      }
    `,
  )}
`;

// Colors from GA - https://docs.google.com/a/vernier.com/document/d/1TTmZy_sTwDj7zZDQluwj8Rzg1EfDne_WaS340dtdpsg/edit
// Note - GAi and GAa cycle through the colors per graph.  We cycle globally (should probably fix to be consistent with them)
export const TRACE_COLORS = [
  'rgba(216,38,47,1)',
  // $red
  'rgba(59,139,204,1)',
  // $baby-blue
  'rgba(186,178,30,1)',
  // $vomit
  'rgba(51,69,170,1)',
  // $blue
  'rgba(226,96,148,1)',
  // $pink
  'rgba(91,44,99,1)',
  // $purple
];

export const createColorCycling = graphEls => {
  let nextColorIndex = 0;

  // preferredColor may be undefined
  const getNext = preferredColor => {
    const colors = TRACE_COLORS;
    const colorIndex = nextColorIndex;
    let color = null;

    const colorAvailable = (() => {
      // look through all traces and keep track of what colors are used
      const usedColors = {};
      Array.from(graphEls).forEach(graph => {
        graph.getLeftTraces().forEach(trace => {
          usedColors[trace.getColor()] = true;
        });
      });

      // return a function to do the check
      return color => usedColors[color] === undefined;
    })();

    // can we use the preferredColor?
    if (preferredColor && colors.indexOf(preferredColor) >= 0) {
      if (colorAvailable(preferredColor)) {
        color = preferredColor;
      }
    }

    if (!color) {
      let i;
      // find the next available color that isn't already plotted
      for (i = 0; i < colors.length; ++i) {
        color = colors[(colorIndex + i) % colors.length];
        if (colorAvailable(color)) {
          break;
        }
      }

      if (i === 0) {
        nextColorIndex++;
        nextColorIndex %= colors.length;
      }
    }

    return color;
  };

  graphEls.forEach(graphEl => graphEl.setGetTraceColor(getNext));

  const reset = () => {
    nextColorIndex = 0;
  };

  return { reset, getNext };
};

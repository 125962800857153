import { createGrid } from '../grid/createGrid.js';
import { createPrepareGrid } from './_internal/prepareRender/createPrepareGrid.js';
import { createPrepareRowSidebar } from './_internal/prepareRender/createPrepareRowSidebar.js';
import { createPrepareGridCell } from './_internal/prepareRender/createPrepareGridCell.js';
import { createPrepareHeaders } from './_internal/prepareRender/createPrepareHeaders.js';
import { createDataSetCollection } from './_internal/model/createDataSetCollection.js';
import { createInteractionContext } from './_internal/createInteractionContext.js';
import { createSelectionApi } from './_internal/createSelectionApi.js';
import { completeCellResolver } from './_internal/completeCellResolver.js';
import { completeAssign } from '../_internal/completeAssign.js';
import { createGridTouchEvents } from './_internal/createGridTouchEvents.js';
import { priv } from './_internal/priv.js';

export const createDataSetGrid = ({
  gridEl,

  padding = 0.3125,
  borderSize = 0.0625,
  sidebarWidth = 3.75,
  scrollerSize = 0.9375,
  itemHeight = 2,
  itemWidth = 6.25,
  allowsAlphanumeric = false,

  cellResolver: cellResolverConfig,

  minRows = 10,

  onShowSelectContextMenu = () => {},
  onUiSelection = () => {},
  onUserInputChange = () => {},
  onUiScroll = () => {},

  renderDataSetContext = () => {},
  renderColumnContext = () => {},
}) => {
  const dataSetGrid = dsIndex => dataSetGrid.getDataSet(dsIndex);

  gridEl.style.setProperty('--vst-grid-cell-width', `${itemWidth}rem`);

  const callbacks = { onShowSelectContextMenu, onUiSelection, onUserInputChange };
  const gridTouchEvents = createGridTouchEvents({ dataSetGrid, onUiScroll, gridEl });

  const cellResolver = completeCellResolver(cellResolverConfig);
  const interactionContext = createInteractionContext({
    dataSetGrid,
    cellResolver,
    callbacks,
    gridTouchEvents,
    allowsAlphanumeric,
  });
  const selection = createSelectionApi({ interactionContext, dataSetGrid });
  const prepareGrid = createPrepareGrid({ interactionContext, dataSetGrid });
  const prepareGridCell = createPrepareGridCell({ interactionContext });
  const prepareRowSidebar = createPrepareRowSidebar({ interactionContext, dataSetGrid });
  const prepareHeaders = createPrepareHeaders({
    interactionContext,
    renderDataSetContext,
    renderColumnContext,
  });

  const { select, scrollToRow, getSelection } = selection;

  const onScroll = uiScroll => gridTouchEvents.onScroll(uiScroll);

  const grid = createGrid({
    gridEl,

    padding,
    borderSize,
    sidebarWidth,
    scrollerSize,
    rowHeight: itemHeight,
    cellWidth: itemWidth,
    prepareHeaders,
    prepareGridCell,
    prepareRowSidebar,
    prepareGrid,
    minRows,

    onScroll,
  });

  const {
    addDataSet,
    getDataSet,
    getLength,
    getDataSets,
    getColumns,
    getColspan,
    getRawColumns,
    dataSets,
    getReadonly,
    clear: clearDataSet,
    refreshHeaders,
  } = createDataSetCollection({ dataSetGrid, cellResolver, interactionContext });
  const { clear: clearInterations } = interactionContext;
  const { clear: clearGrid } = grid;

  const resize = () => grid.resize();

  const getTotalRows = () => grid.totalRows;
  const focus = () => interactionContext.focus();

  const clear = () => {
    scrollToRow(0, true);
    clearGrid();
    clearDataSet();
  };

  return completeAssign(dataSetGrid, {
    get dataSetLength() {
      return getLength();
    },
    get totalRows() {
      return getTotalRows();
    },
    get colspan() {
      return getColspan();
    },
    get selection() {
      return selection;
    },
    get columns() {
      return getColumns();
    },
    get dataSets() {
      return getDataSets();
    },
    get readonly() {
      return getReadonly();
    },
    get disabled() {
      return interactionContext.gridDisabled;
    },
    set disabled(value) {
      interactionContext.gridDisabled = value;
    },
    get scrollDisabled() {
      return interactionContext.scrollDisabled;
    },
    set scrollDisabled(value) {
      interactionContext.scrollDisabled = value;
    },
    get interactionsDisabled() {
      return interactionContext.interactionsDisabled;
    },
    set interactionsDisabled(value) {
      interactionContext.interactionsDisabled = value;
    },
    get topVisibleRowIndex() {
      return grid.topVisibleRowIndex;
    },
    get bottomVisibleRowIndex() {
      return grid.bottomVisibleRowIndex;
    },
    get leftVisibleColumn() {
      return grid.leftVisibleColumn;
    },
    get rightVisibleColumn() {
      return grid.rightVisibleColumn;
    },

    addDataSet,
    getDataSet,
    select,
    scrollToRow,
    getSelection,
    clearInterations,
    deepEditCell: interactionContext.deepEditCell,
    focus,
    clear,
    resize,
    refreshHeaders,

    [priv]: {
      dataSets,
      grid,
      minRows,

      getRawColumns,
    },
  });
};

import { css } from 'lit';
import { dialogDefault } from '../styles/index.js';

export const vstUiEweStyles = css`
  ${dialogDefault}

  table {
    border-collapse: collapse;
    inline-size: 100%;
  }

  th {
    background: var(--vst-color-bg-secondary);
    font-weight: normal;
  }

  td,
  th {
    border: 1px solid var(--vst-color-bg-primary);
    font-size: 0.75rem;
    line-height: 1.4;
    vertical-align: middle;
    height: 2.0625rem;
    padding: 0 0.5rem;
  }

  .column-header {
    min-inline-size: 8ch;
  }

  .spacer-gif {
    width: 3.125rem;
  }

  .run {
    font-weight: bold;
  }

  .event {
    padding: 0;
  }

  tbody th {
    text-align: center;
  }

  tbody td,
  .event input {
    text-align: right;
    height: 2.0625rem;
    padding: 0 0.5rem;
  }

  label {
    display: block;
    margin: 0;
  }

  .flex {
    display: flex;
    justify-content: flex-end;
    inline-size: 100%;
  }

  .reel {
    display: flex;
    overflow: auto;
  }

  input {
    --border: 0;
    --border-radius: 0;
    inline-size: 100%;
  }

  #event {
    --vst-outline-offset: 0px;
  }
`;

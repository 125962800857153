export const OutsideClickReceiver = Base =>
  class extends Base {
    connectedCallback() {
      super.connectedCallback();
      document.addEventListener('click', this._handleDocumentClick);
    }

    disconnectedCallback() {
      super.disconnectedCallback();
      document.removeEventListener('click', this._handleDocumentClick);
    }

    _handleDocumentClick = event => {
      if (this.outsideClickCallbackFn(event)) this.handleOutsideClick(event);
    };

    /**
     * Called when the document receives a click with a composed path that
     * doesn't include this element.
     *
     * @param {Event} event
     * @abstract
     */
    // eslint-disable-next-line class-methods-use-this
    handleOutsideClick() {
      console.error('OutsideClickReceiver: `handleOutsideClick` must be overridden.');
    }

    outsideClickCallbackFn(event) {
      return !event.composedPath().includes(this);
    }
  };

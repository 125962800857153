import { LitElement, html, css } from 'lit';
import { styleMap } from 'lit/directives/style-map.js';

const WAVELENGTH_HIGH = 780;
const WAVELENGTH_LOW = 380;
function calculateRainbowDimensions(baseAxis) {
  const { width: plotWidth } = baseAxis.graph.plotBox;
  const low = baseAxis.p2c(WAVELENGTH_LOW);
  const high = baseAxis.p2c(WAVELENGTH_HIGH);

  return {
    left: low,
    right: plotWidth - high,
    width: high - low,
  };
}

class SaWavelengthRainbow extends LitElement {
  static get styles() {
    return css`
      .rainbow {
        position: absolute;
        bottom: 2%;
        height: 1.25%;
        min-height: 2px;
        z-index: 10;
      }
    `;
  }

  static get properties() {
    return {
      _left: { state: true },
      _right: { state: true },
      _width: { state: true },
      graph: { type: Object },
    };
  }

  constructor() {
    super();

    this._left = 0;
    this._right = 0;
    this._width = 0;

    this._updateDimensions = async () => {
      // fixes timing issues with graph animation affecting the rainbow width & Axis.p2c
      // being unreliable when axis-range-updated firess
      await this.graph.coreGraphEl.resized;

      ({
        left: this._left,
        right: this._right,
        width: this._width,
      } = calculateRainbowDimensions(this.graph.getAxis('base')));
    };
  }

  disconnectedCallback() {
    super.disconnectedCallback();

    this.parentElement.removeEventListener('resize', this._updateDimensions);
    this.graph.getAxis('base').removeEventListener('axis-range-updated', this._updateDimensions);
    this.graph.getAxis('left').removeEventListener('axis-range-updated', this._updateDimensions);
  }

  updated(changedProperties) {
    changedProperties.forEach((previousValue, propName) => {
      if (propName === 'graph' && !previousValue) {
        // When the vst-core-graph is resized, re-render with new dimensions
        this.parentElement.addEventListener('resize', this._updateDimensions);
        // When the axis range is updated, re-render with new dimensions
        this.graph.getAxis('base').addEventListener('axis-range-updated', this._updateDimensions);
        this.graph.getAxis('left').addEventListener('axis-range-updated', this._updateDimensions);
      }
    });
  }

  render() {
    const styles = {};

    styles.left = `${this._left}px`;
    styles.right = `${this._right}px`;
    styles.width = `${this._width}px`;

    return html`
      <img
        style=${styleMap(styles)}
        alt="wavelength rainbow"
        src="./wavelength-rainbow.png"
        class="rainbow"
      />
    `;
  }
}

customElements.define('sa-wavelength-rainbow', SaWavelengthRainbow);

import { LitElement, html } from 'lit';

import { Requester } from '@components/mixins/vst-core-requester-mixin.js';
import { EventBinder } from '@utils/EventBinder.js';
import { getText } from '@utils/i18n.js';
import * as Gestures from '@polymer/polymer/lib/utils/gestures.js';
import { globalStyles } from '@styles/vst-style-global.css.js';

import vstCoreGraphPredictionsStyles from './vst-core-graph-predictions.css.js';

import '@components/vst-ui-form/vst-ui-form.js';

class VstCoreGraphPredictions extends Requester(LitElement) {
  static get properties() {
    return {
      graphId: {
        type: String,
      },
      graphModeTransition: {
        type: Object,
      },
      isActive: {
        type: Boolean,
        reflect: true,
      },
      predictionName: {
        type: String,
      },
      examinePin: {
        type: Object,
      },
      restoreExaminePin: {
        type: Boolean,
      },
    };
  }

  constructor() {
    super();

    this.graphId = '';
    this.isActive = false;
    this.predictionName = '';
    this.examinePin = {};
    this.restoreExaminePin = false;

    this._gestureCallbacks = {};
  }

  shouldUpdate() {
    return this.graphModeTransition;
  }

  firstUpdated() {
    this.$dataWorld = this.requestService('dataWorld');
    this.eventBinder = new EventBinder();
    this.svgCanvas = this.shadowRoot.querySelector('#svg_canvas');
    this.polylineEl = this.svgCanvas.querySelector('#svg_canvas_polyline');
    this.introEl = this.shadowRoot.getElementById('intro');
    this.introSvgEl = this.shadowRoot.querySelector('#intro_svg');
    this.introSvgPathEl = this.shadowRoot.querySelector('#intro_svg_path');
    this.predictionDrawingAreaEl = this.shadowRoot.getElementById('prediction_drawing_area');

    this.graph = this.parentElement;

    this.predictionFormEl = this.shadowRoot.querySelector('#prediction_form');
    this.titleEl = this.predictionFormEl.querySelector('#prediction_title');

    this._gestureCallbacks.track = this.drawHandler.bind(this);
    this._gestureCallbacks.down = this.downHandler.bind(this);
    Object.keys(this._gestureCallbacks).forEach(key => {
      const func = this._gestureCallbacks[key];
      Gestures.addListener(this.predictionDrawingAreaEl, key, func);
    });

    this.eventBinder.on(window, 'resize', () => {
      requestAnimationFrame(() => {
        this._resizeHandler();
      });
    });

    this.graphInstance = this.graph.graphInstance;
  }

  disconnectedCallback() {
    super.disconnectedCallback();

    this.eventBinder?.unbindAll();

    Object.keys(this._gestureCallbacks).forEach(key => {
      const func = this._gestureCallbacks[key];
      Gestures.removeListener(this.predictionDrawingAreaEl, key, func);
    });

    this._gestureCallbacks = {};
  }

  updated(changedProperties) {
    changedProperties.forEach((oldValue, propName) => {
      if (propName === 'isActive') {
        this.isActiveChanged(this.isActive);
      }
    });
  }

  savePrediction() {
    // only save the prediction if points were drawn
    const { points } = this.polylineEl;
    if (points.numberOfItems > 0) {
      const name = this.titleEl.value;

      this._savePrediction({
        rawPoints: points,
        name,
      });

      this._closePredictions();
      this._clearPrediction();
    } else {
      this.cancelClicked();
    }
  }

  cancelClicked() {
    this._cancelPrediction();
    this._closePredictions();
  }

  downHandler() {
    this.introEl.classList.add('hidden');
  }

  drawHandler(e) {
    const { state } = e.detail;

    if (state === 'start') {
      this._clearPrediction();
    } else if (state === 'track') {
      this._drawPrediction(e);
    }
  }

  isActiveChanged(isActive) {
    if (!this.graph) {
      return;
    }

    const plotBoxEls = Array.from(this.graph.shadowRoot.querySelector('#plot_box').children);

    if (isActive) {
      this.graphModeTransition.open(this.graph);

      this._initPrediction();

      this.titleEl.value = this.predictionName;

      // HACK until analysis plugin can handle this
      plotBoxEls.forEach(plotBoxEl => {
        plotBoxEl.classList.add('hidden');
      });

      this.introEl.classList.remove('hidden');
      this.introEl.classList.add('animate-in');
      this.showIntro();

      this.eventBinder.on(document, 'keyup', e => {
        // listen for esc. key
        if (e.which === 27) {
          this.cancelClicked();
        } else if (e.which === 13) {
          this.savePrediction();
        }
      });
    } else {
      this.graphModeTransition.close(this.graph);

      // HACK until analysis plugin can handle this
      plotBoxEls.forEach(plotBoxEl => {
        plotBoxEl.classList.remove('hidden');
      });

      this.introEl.classList.remove('animate-in');
      this.predictionFormEl.querySelector('#prediction_title');
      this.titleEl.value = '';
      this.eventBinder.unbindAll();
    }
  }

  setDrawingAreaSize() {
    const plotBoxRect = this.graph.shadowRoot.querySelector('#plot_box').getBoundingClientRect(); // a bit nasty
    const drawingArea = this.shadowRoot.querySelector('.prediction-drawing');

    drawingArea.style.top = `${plotBoxRect.top}px`;
    drawingArea.style.right = `${plotBoxRect.right}px`;
    drawingArea.style.bottom = `${plotBoxRect.bottom}px`;
    drawingArea.style.left = `${plotBoxRect.left}px`;
    drawingArea.style.width = `${plotBoxRect.width}px`;
    drawingArea.style.height = `${plotBoxRect.height}px`;

    this.svgCanvas.setAttribute('viewBox', `0 0 ${plotBoxRect.width} ${plotBoxRect.height}`);
  }

  showIntro() {
    const self = this;
    const width = 300;
    const height = 200;

    const prettyPaths = [
      'M10,79 S 75,136 150,68 225,69 290,96',
      'M10,67 S 75,130 150,87 225,46 290,85',
      'M10,39 S 75,129 150,47 225,91 290,107',
      'M10,39 S 75,133 150,87 225,137 290,95',
    ];

    this.introSvgEl.setAttribute('width', width);
    this.introSvgEl.setAttribute('height', height);
    this.introSvgEl.setAttribute('viewBox', `0 0 ${width} ${height}`);

    function randomInt(minimum, maximum) {
      return Math.floor(Math.random() * (maximum - minimum + 1) + minimum);
    }

    function generateLine() {
      // let buffer = height/6;
      // let segments = 5; // must be an odd number since we're 'curving'
      // let xPoints = [];
      // let yPoints = [];

      function buildPathPoints() {
        //     random path points
        //     let pathD = `M${xPoints[0]},${yPoints[0]} S`;
        //     for (let i=1; i<yPoints.length; ++i) {
        //         pathD = pathD + ' ' + xPoints[i] + ',' + yPoints[i];
        //     }
        //     return pathD;
        return prettyPaths[randomInt(0, prettyPaths.length - 1)];
      }

      // for (let i=0; i<segments; ++i) {
      //     xPoints.push(i*(width / (segments-1)));
      //     yPoints.push(randomInt(buffer,height-buffer));
      // }

      // bump the beginning and end so we see the whole path
      // xPoints[0] += 10;
      // xPoints[(xPoints.length - 1)] -= 10;

      self.introSvgPathEl.setAttribute('d', buildPathPoints());
      const length = self.introSvgPathEl.getTotalLength();

      // set the correct length of the path for animation. Path length is different each time.
      self.introSvgPathEl.style.strokeDasharray = length;
      self.introSvgPathEl.style.strokeDashoffset = length;
    }

    generateLine();
  }

  addTrace(traceColId) {
    const { graph } = this;

    graph.addTrace(traceColId, 'left');
  }

  _formatPredictionData(data) {
    const { graphInstance } = this;

    const points = [];

    for (let i = 0; i < data.numberOfItems; i++) {
      const pointPair = data.getItem(i);
      const point = graphInstance.canvasToPoint(pointPair);
      points.push([point.x, point.y]);
    }

    return points;
  }

  _drawPrediction(e) {
    const { svgCanvas, polylineEl, predictionDrawingAreaEl } = this;

    const rect = predictionDrawingAreaEl.getBoundingClientRect();
    const point = svgCanvas.createSVGPoint();
    point.x = e.detail.x - rect.left;
    point.y = e.detail.y - rect.top;
    polylineEl.points.appendItem(point);

    this.predictionPoints.push({ x: e.detail.x, y: e.detail.y });
    this.predictionRectOriginal = rect;
  }

  _resizeHandler() {
    const { svgCanvas, polylineEl, predictionDrawingAreaEl } = this;

    this.setDrawingAreaSize();

    if (this.predictionRectOriginal) {
      polylineEl.points.clear();

      const rect = predictionDrawingAreaEl.getBoundingClientRect();
      const rectWidthMultiplier = rect.width / this.predictionRectOriginal.width;
      const rectHeightMultiplier = rect.height / this.predictionRectOriginal.height;

      this.predictionPoints.forEach(pointPair => {
        const point = svgCanvas.createSVGPoint();
        point.x = (pointPair.x - rect.left) * rectWidthMultiplier;
        point.y = (pointPair.y - rect.top) * rectHeightMultiplier;
        polylineEl.points.appendItem(point);
      });
    }
  }

  _clearPrediction() {
    this.predictionPoints = [];
    this.polylineEl.points.clear();
  }

  _initPrediction() {
    this._hideGraphAnalysis(true);
    this.predictionName = `${getText('Prediction')} ${
      this.$dataWorld.getSpecialDataSets().filter(set => set.type === 'prediction').length + 1
    }`;
  }

  _closePredictions() {
    this.dispatchEvent(
      new CustomEvent('close-predictions', {
        detail: { graphEl: this.graph },
      }),
    );
  }

  _cancelPrediction() {
    this._clearPrediction();
    this._hideGraphAnalysis(false);
  }

  async _savePrediction({ rawPoints, name } = {}) {
    const points = this._formatPredictionData(rawPoints);
    this._hideGraphAnalysis(false);
    this.graph.removeTracesByType('prediction');

    const traceColId = await this.$dataWorld.createPrediction(points, {
      name,
    });
    this.addTrace(traceColId);
  }

  _hideGraphAnalysis(hide) {
    // this is a bit heavy handed since we call updateData
    const { graphInstance } = this;

    graphInstance.hideFits = hide;
    graphInstance.hideIntegrals = hide;
    graphInstance.hideTangents = hide;
    graphInstance.hidePeakIntegrals = hide;

    this.graph.updatePlotData();

    // when hide === false, restore examine to previous hidden status
    if (hide) {
      this.restoreExaminePin = !this.examinePin.examinePosition.examineHidden;
    }

    this.dispatchEvent(
      new CustomEvent('examine-positioning-changed', {
        detail: {
          graphId: this.graphId,
          positionUpdate: {
            examineHidden: hide || !this.restoreExaminePin,
          },
        },
      }),
    );
  }

  static get styles() {
    return [globalStyles, vstCoreGraphPredictionsStyles];
  }

  render() {
    return html`
      <div class="prediction-wrapper">
        <vst-ui-form @submit="${this.savePrediction}">
          <form class="prediction-toolbar" id="prediction_form">
            <div class="prediction-title-wrapper">
              <label visually-hidden for="prediction_title">${getText('Prediction Title')}</label>
              <input type="text" id="prediction_title" placeholder="${getText('Prediction')} 1" />
            </div>
            <div class="prediction-btn-wrapper">
              <button
                type="button"
                class="btn"
                variant="text"
                id="cancel"
                margin="inline-end-xs"
                @click="${this.cancelClicked}"
              >
                ${getText('Cancel')}
              </button>
              <button type="submit" id="submit" class="btn">${getText('Save')}</button>
            </div>
          </form>
        </vst-ui-form>
        <div class="prediction-drawing-wrapper">
          <div class="intro" id="intro">
            <p class="intro-text">${getText('Draw a Prediction')}</p>
            <svg class="intro-svg" id="intro_svg">
              <path
                class="intro-svg-path"
                id="intro_svg_path"
                fill="transparent"
                stroke-linecap="round"
                d=""
              />
            </svg>
          </div>
          <div id="prediction_drawing_area" class="prediction-drawing">
            <svg id="svg_canvas" class="svg-canvas" preserveAspectRatio="none">
              <polyline id="svg_canvas_polyline" />
            </svg>
          </div>
        </div>
      </div>
    `;
  }
}

customElements.define('vst-core-graph-predictions', VstCoreGraphPredictions);

export const createCollection = () => {
  const list = [];
  const hash = {};

  const remove = (key, index) => {
    list.splice(index, 1);
    delete hash[key];
  };

  const empty = {};

  return {
    append: (key, value) => {
      if (hash[key]) {
        return;
      }

      hash[key] = value;
      list.push({ key, value });
    },

    insert: (position, key, value) => {
      if (hash[key]) {
        return;
      }

      hash[key] = value;
      list.splice(position, 0, { key, value });
    },

    hasKey: key => {
      return !!hash[key];
    },

    removeByIndex: index => {
      const { key } = empty || list[index];
      if (key) {
        remove(key, index);
      }
    },

    removeByKey: key => {
      const index = list.findIndex(({ key: storedKey }) => storedKey === key);
      if (index !== -1) {
        remove(key, index);
      }
    },

    clear: () => {
      list.splice(0, 1);
      Object.keys(hash).forEach(key => {
        delete hash[key];
      });
    },

    findByIndex: index => (list[index] || empty).value,
    findByKey: key => hash[key],

    indexForKey: key => list.findIndex(item => item.key === key),
    keyForIndex: index => (list[index] || empty).key,

    forEach: fn => {
      for (let i = 0; i < list.length; i++) {
        const { key, value } = list[i];
        fn(value, key, i);
      }
    },

    reverse: fn => {
      for (let i = list.length - 1; i >= 0; i--) {
        const { key, value } = list[i];
        fn(value, key, i);
      }
    },

    map: fn => list.map(({ key, value }, index) => fn(value, key, index)),
    reduce: (fn, init) => {
      const reducer = (result, { key, value }, index) => fn(result, value, key, index);

      return list.reduce(reducer, init);
    },

    every: fn => list.every(({ value, key }, index) => fn(value, key, index)),
    some: fn => list.some(({ value, key }, index) => fn(value, key, index)),

    toList: () => list.map(item => item.value),
    get length() {
      return list.length;
    },
  };
};

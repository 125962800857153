import { priv } from './priv.js';
import { createClipboardContext } from './createClipboardContext.js';
import { createOverscrollContext } from './createOverscrollContext.js';

export const createInteractionContext = ({
  dataSetGrid,
  cellResolver,
  callbacks,
  gridTouchEvents,
  allowsAlphanumeric,
}) => {
  let interaction = null;
  let clipboardContext = null;
  let uiSelectionOccurring = false;
  let shiftSelected = false;
  const getShiftSelected = () => shiftSelected;
  const setShiftSelected = value => {
    shiftSelected = value;
  };

  const getInteractionsDisabled = () => gridTouchEvents.interactionsDisabled;
  const setInteractionsDisabled = value => {
    gridTouchEvents.interactionsDisabled = value;
  };

  const getScrollDisabled = () => dataSetGrid[priv].grid.scrollDisabled;
  const setScrollDisabled = value => {
    dataSetGrid[priv].grid.scrollDisabled = value;
  };

  const getGridDisabled = () => getInteractionsDisabled() && getScrollDisabled();
  const setGridDisabled = value => {
    if (value) {
      setInteractionsDisabled(true);
      setScrollDisabled(true);
    } else {
      setInteractionsDisabled(false);
      setScrollDisabled(false);
    }
  };

  const greaterOf = (compare1, compare2, val1 = undefined, val2 = undefined) => {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line no-nested-ternary
    return compare1 > compare2
      ? val1 !== undefined
        ? val1
        : compare1
      : val2 !== undefined
      ? val2
      : compare2;
  };

  const lesserOf = (compare1, compare2, val1 = undefined, val2 = undefined) => {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line no-nested-ternary
    return compare1 <= compare2
      ? val1 !== undefined
        ? val1
        : compare1
      : val2 !== undefined
      ? val2
      : compare2;
  };

  let clear = null;

  const updateRowRange = (lowerIndex, higherIndex) => {
    const { grid } = dataSetGrid[priv];

    grid.applyChanges(context => {
      for (let rowIndex = lowerIndex; rowIndex <= higherIndex; rowIndex++) {
        for (let colIndex = 0; colIndex < grid.totalColumns; colIndex++) {
          context.updateCell(rowIndex, colIndex);
        }
      }
    });
  };

  const updateColumnRange = (lowerColIndex, higherColIndex) => {
    const { grid } = dataSetGrid[priv];

    grid.applyChanges(context => {
      const lowerRowIndex = context.renderedStartRowIndex;
      const higherRowIndex = context.renderedLastRowIndex;

      for (let rowIndex = lowerRowIndex; rowIndex <= higherRowIndex; rowIndex++) {
        for (let colIndex = lowerColIndex; colIndex <= higherColIndex; colIndex++) {
          context.updateCell(rowIndex, colIndex);
        }
      }
    });
  };

  const updateDataSetRange = (lowerDataSetIndex, higherDataSetIndex) => {
    const { dataSets } = dataSetGrid[priv];
    const lowerDataSet = dataSets.findByIndex(lowerDataSetIndex);
    const higherDataSet = dataSets.findByIndex(higherDataSetIndex);

    const ldsColumns = lowerDataSet[priv].columns;
    const lowerColumnIndex = ldsColumns.findByIndex(0).gridPosition;

    const hdsColumns = higherDataSet[priv].columns;
    const higherColumnIndex = hdsColumns.findByIndex(hdsColumns.length - 1).gridPosition;

    updateColumnRange(lowerColumnIndex, higherColumnIndex);
  };

  const updateCell = (colIndex, rowIndex) => {
    const { grid } = dataSetGrid[priv];

    grid.applyChanges(context => {
      context.updateCell(rowIndex, colIndex);
    });
  };

  const updateCellRange = (lowerColIndex, higherColIndex, lowerRowIndex, higherRowIndex) => {
    const { grid } = dataSetGrid[priv];

    grid.applyChanges(context => {
      for (let rowIndex = lowerRowIndex; rowIndex <= higherRowIndex; rowIndex++) {
        for (let colIndex = lowerColIndex; colIndex <= higherColIndex; colIndex++) {
          context.updateCell(rowIndex, colIndex);
        }
      }
    });
  };

  const hasInteractions = () => !!interaction;

  const activeCellSelection = (column, rowIndex) => {
    if (interaction && interaction.cellsSelected) {
      const lowerColIndex = lesserOf(interaction.startColIndex, interaction.endColIndex);
      const higherColIndex = greaterOf(interaction.startColIndex, interaction.endColIndex);
      const lowerRowIndex = lesserOf(interaction.startRowIndex, interaction.endRowIndex);
      const higherRowIndex = greaterOf(interaction.startRowIndex, interaction.endRowIndex);

      const colIndex = column.gridPosition;

      return (
        colIndex >= lowerColIndex &&
        colIndex <= higherColIndex &&
        rowIndex >= lowerRowIndex &&
        rowIndex <= higherRowIndex
      );
    }

    return false;
  };

  const activeRowSelection = rowIndex => {
    if (interaction && interaction.rowsSelected) {
      const lowerRowIndex = lesserOf(interaction.startRowIndex, interaction.endRowIndex);
      const higherRowIndex = greaterOf(interaction.startRowIndex, interaction.endRowIndex);

      return rowIndex >= lowerRowIndex && rowIndex <= higherRowIndex;
    }

    return false;
  };

  const activeColumnSelection = column => {
    if (interaction && interaction.columnsSelected) {
      const lowerColIndex = lesserOf(interaction.startColIndex, interaction.endColIndex);
      const higherColIndex = greaterOf(interaction.startColIndex, interaction.endColIndex);

      const colIndex = column.gridPosition;

      return colIndex >= lowerColIndex && colIndex <= higherColIndex;
    }

    return false;
  };

  const activeDataSetSelection = dataSet => {
    if (interaction && interaction.dataSetsSelected) {
      const lowerDataSetIndex = lesserOf(
        interaction.startDataSetIndex,
        interaction.endDataSetIndex,
      );
      const higherDataSetIndex = greaterOf(
        interaction.startDataSetIndex,
        interaction.endDataSetIndex,
      );

      const dataSetIndex = dataSet.position;

      return dataSetIndex >= lowerDataSetIndex && dataSetIndex <= higherDataSetIndex;
    }

    return false;
  };

  const singleCellSelected = (column, rowIndex) => {
    return (
      interaction &&
      interaction.cellsSelected &&
      interaction.startCol === column &&
      interaction.endCol === column &&
      interaction.startRowIndex === rowIndex &&
      interaction.endRowIndex === rowIndex
    );
  };

  const singleRowSelected = rowIndex => {
    return (
      interaction &&
      interaction.rowsSelected &&
      interaction.startRowIndex === rowIndex &&
      interaction.endRowIndex === rowIndex
    );
  };

  const singleColumnSelected = column => {
    return (
      interaction &&
      interaction.columnsSelected &&
      interaction.startCol === column &&
      interaction.endCol === column
    );
  };

  const singleDataSetSelected = dataSet => {
    return (
      interaction &&
      interaction.dataSetsSelected &&
      interaction.startDataSet === dataSet &&
      interaction.endDataSet === dataSet
    );
  };

  const getHasSelection = () => {
    return interaction && !interaction.editingCell;
  };

  const getInteractionInfo = () => {
    const interactionInfo = { ...interaction };
    if (interactionInfo.cellReselect) {
      interactionInfo.cellReselect = { ...interactionInfo.cellReselect };
    }

    return interactionInfo;
  };

  const selectAll = () => {
    clear();

    interaction = {
      allSelected: true,
      allRows: true,
      allColumns: true,
    };

    updateRowRange(0, dataSetGrid.totalRows - 1);
  };

  const startRowSelection = rowIndex => {
    clear();

    interaction = {
      rowsSelected: true,
      startRowIndex: rowIndex,
      endRowIndex: rowIndex,
    };

    updateRowRange(interaction.startRowIndex, interaction.endRowIndex);
  };

  const expandRowSelection = rowIndex => {
    if (interaction && interaction.rowsSelected) {
      let lowerIndex = lesserOf(interaction.startRowIndex, interaction.endRowIndex);
      let higherIndex = greaterOf(interaction.startRowIndex, interaction.endRowIndex);

      if (rowIndex < lowerIndex) {
        lowerIndex = rowIndex;
      } else if (rowIndex > higherIndex) {
        higherIndex = rowIndex;
      }

      interaction.endRowIndex = rowIndex;

      updateRowRange(lowerIndex, higherIndex);
    }
  };

  const getCellState = (column, rowIndex) => {
    let isSelected = false;
    let isFocused = false;
    let isEditing = false;
    let isDeepEdit = false;

    if (interaction) {
      if (interaction.allSelected) {
        isSelected = true;
      } else if (interaction.rowsSelected) {
        const lowerIndex = lesserOf(interaction.startRowIndex, interaction.endRowIndex);
        const higherIndex = greaterOf(interaction.startRowIndex, interaction.endRowIndex);

        isSelected = rowIndex >= lowerIndex && rowIndex <= higherIndex;
      } else if (interaction.columnsSelected) {
        const colIndex = column.gridPosition;
        const lowerIndex = lesserOf(interaction.startColIndex, interaction.endColIndex);
        const higherIndex = greaterOf(interaction.startColIndex, interaction.endColIndex);

        isSelected = colIndex >= lowerIndex && colIndex <= higherIndex;
      } else if (interaction.dataSetsSelected) {
        const dataSetIndex = column.dataSet.position;
        const lowerIndex = lesserOf(interaction.startDataSetIndex, interaction.endDataSetIndex);
        const higherIndex = greaterOf(interaction.startDataSetIndex, interaction.endDataSetIndex);

        isSelected = dataSetIndex >= lowerIndex && dataSetIndex <= higherIndex;
      } else if (interaction.cellsSelected) {
        const colIndex = column.gridPosition;
        const lowerColIndex = lesserOf(interaction.startColIndex, interaction.endColIndex);
        const higherColIndex = greaterOf(interaction.startColIndex, interaction.endColIndex);
        const lowerRowIndex = lesserOf(interaction.startRowIndex, interaction.endRowIndex);
        const higherRowIndex = greaterOf(interaction.startRowIndex, interaction.endRowIndex);

        isSelected =
          colIndex >= lowerColIndex &&
          colIndex <= higherColIndex &&
          rowIndex >= lowerRowIndex &&
          rowIndex <= higherRowIndex;
        isFocused = colIndex === interaction.endColIndex && rowIndex === interaction.endRowIndex;
      } else if (interaction.editingCell) {
        const colIndex = column.gridPosition;
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line no-multi-assign
        isEditing = isFocused =
          colIndex === interaction.colIndex && rowIndex === interaction.rowIndex;
        isDeepEdit = isEditing && interaction.deepEdit;
      }
    }

    return {
      isSelected,
      isFocused,
      isEditing,
      isDeepEdit,
    };
  };

  const startDataSetSelection = dataSet => {
    clear();

    const dataSetIndex = dataSet.position;

    interaction = {
      dataSetsSelected: true,
      startDataSetIndex: dataSetIndex,
      startDataSet: dataSet,
      endDataSetIndex: dataSetIndex,
      endDataSet: dataSet,
    };

    updateDataSetRange(dataSetIndex, dataSetIndex);
  };

  const expandDataSetSelection = dataSet => {
    if (interaction && interaction.dataSetsSelected) {
      const dataSetIndex = dataSet.position;

      let lowerIndex = lesserOf(interaction.startDataSetIndex, interaction.endDataSetIndex);
      let higherIndex = greaterOf(interaction.startDataSetIndex, interaction.endDataSetIndex);

      if (dataSetIndex < lowerIndex) {
        lowerIndex = dataSetIndex;
      } else if (dataSetIndex > higherIndex) {
        higherIndex = dataSetIndex;
      }

      interaction.endDataSet = dataSet;
      interaction.endDataSetIndex = dataSetIndex;

      updateDataSetRange(lowerIndex, higherIndex);
    }
  };

  const startColumnSelection = column => {
    clear();

    const colIndex = column.gridPosition;

    interaction = {
      columnsSelected: true,
      startColIndex: colIndex,
      startCol: column,
      endColIndex: colIndex,
      endCol: column,
    };

    updateColumnRange(colIndex, colIndex);
  };

  const expandColumnSelection = column => {
    if (interaction) {
      if (interaction.columnsSelected) {
        const colIndex = column.gridPosition;

        let lowerIndex = lesserOf(interaction.startColIndex, interaction.endColIndex);
        let higherIndex = greaterOf(interaction.startColIndex, interaction.endColIndex);

        if (colIndex < lowerIndex) {
          lowerIndex = colIndex;
        } else if (colIndex > higherIndex) {
          higherIndex = colIndex;
        }

        interaction.endCol = column;
        interaction.endColIndex = colIndex;

        updateColumnRange(lowerIndex, higherIndex);
      } else if (interaction.dataSetsSelected) {
        expandDataSetSelection(column.dataSet);
      }
    }
  };

  const startCellSelection = (column, rowIndex) => {
    clear();

    const colIndex = column.gridPosition;

    interaction = {
      cellsSelected: true,
      startCol: column,
      startColIndex: colIndex,
      endCol: column,
      endColIndex: colIndex,
      startRowIndex: rowIndex,
      endRowIndex: rowIndex,
    };

    updateCellRange(colIndex, colIndex, rowIndex, rowIndex);
  };

  const expandCellSelection = (column, rowIndex) => {
    if (interaction) {
      if (interaction.rowsSelected) {
        expandRowSelection(rowIndex);
      } else if (interaction.columnsSelected) {
        expandColumnSelection(column);
      } else if (interaction.dataSetsSelected) {
        expandDataSetSelection(column.dataSet);
      } else if (interaction.cellsSelected) {
        const colIndex = column.gridPosition;

        let lowerColIndex = lesserOf(interaction.startColIndex, interaction.endColIndex);
        let higherColIndex = greaterOf(interaction.startColIndex, interaction.endColIndex);

        if (colIndex < lowerColIndex) {
          lowerColIndex = colIndex;
        } else if (colIndex > higherColIndex) {
          higherColIndex = colIndex;
        }

        interaction.endCol = column;
        interaction.endColIndex = colIndex;

        let lowerRowIndex = lesserOf(interaction.startRowIndex, interaction.endRowIndex);
        let higherRowIndex = greaterOf(interaction.startRowIndex, interaction.endRowIndex);

        if (rowIndex < lowerRowIndex) {
          lowerRowIndex = rowIndex;
        } else if (rowIndex > higherRowIndex) {
          higherRowIndex = rowIndex;
        }

        interaction.endRowIndex = rowIndex;

        updateCellRange(lowerColIndex, higherColIndex, lowerRowIndex, higherRowIndex);
      }
    }
  };

  const deepEditCell = (col, rowIndex) => {
    clear();
    if (!col.readonly) {
      const colIndex = col.gridPosition;

      interaction = {
        editingCell: true,
        deepEdit: true,
        colIndex,
        rowIndex,
      };

      const { grid } = dataSetGrid[priv];
      grid.isDeepEditing = true;
      grid.scrollTo(col, rowIndex, true);

      updateCell(colIndex, rowIndex);
    }
  };

  const editCell = (col, rowIndex, suppressClear = false) => {
    if (!suppressClear) {
      clear();
    }
    if (!col.readonly) {
      const colIndex = col.gridPosition;

      interaction = {
        editingCell: true,
        deepEdit: false,
        colIndex,
        rowIndex,
      };

      const { grid } = dataSetGrid[priv];
      grid.scrollTo(col, rowIndex, true);

      updateCell(colIndex, rowIndex);
    }
  };

  const notifyUIChange = changes => {
    const changeArgs = changes.map(({ column, rowIndex }) => ({
      column,
      rowIndex,
      value: column[priv].values.getSingle(rowIndex),
    }));

    callbacks.onUserInputChange(changeArgs);
  };

  const getColumnsFromRange = (start, end) => {
    const colStartIndex = lesserOf(start, end);
    const colEndIndex = greaterOf(start, end);

    const allColumns = dataSetGrid[priv].getRawColumns();

    const result = allColumns.slice(colStartIndex, colEndIndex + 1);
    return result;
  };

  const getDataSetsFromRange = (start, end) => {
    const dataSetStartIndex = lesserOf(start, end);
    const dataSetEndIndex = greaterOf(start, end);

    const allDataSets = dataSetGrid.dataSets;

    const result = allDataSets.slice(dataSetStartIndex, dataSetEndIndex + 1);
    return result;
  };

  const getSelection = () => {
    if (interaction && !interaction.editingCell) {
      const selection = {};

      if (interaction.rowsSelected || interaction.cellsSelected) {
        selection.rows = {
          start: lesserOf(interaction.startRowIndex, interaction.endRowIndex),
          end: greaterOf(interaction.startRowIndex, interaction.endRowIndex),
        };
      } else {
        selection.allRows = true;
      }
      if (interaction.columnsSelected || interaction.cellsSelected) {
        selection.columns = getColumnsFromRange(interaction.startColIndex, interaction.endColIndex);
        selection.dataSets = [];
      } else if (interaction.dataSetsSelected) {
        const dataSets = getDataSetsFromRange(
          interaction.startDataSetIndex,
          interaction.endDataSetIndex,
        );
        const startDataSet = dataSets[0];
        const startColumns = startDataSet[priv].columns;
        const startColIndex = startColumns.findByIndex(0).gridPosition;

        const endDataSet = dataSets[dataSets.length - 1];
        const endColumns = endDataSet[priv].columns;
        const endColIndex = endColumns.findByIndex(endColumns.length - 1).gridPosition;

        selection.dataSets = dataSets;
        selection.columns = getColumnsFromRange(startColIndex, endColIndex);
      } else {
        selection.allColumns = true;
        selection.allDataSets = true;
      }
      return selection;
    }
    return null;
  };

  clipboardContext = createClipboardContext({
    dataSetGrid,
    getSelection,
    cellResolver,
    callbacks,
    allowsAlphanumeric,
  });

  const notifyUiSelection = () => {
    callbacks.onUiSelection(getSelection());
  };

  const uiSelectAll = () => {
    selectAll();
    notifyUiSelection();
  };

  const uiStartRowSelection = rowIndex => {
    startRowSelection(rowIndex);
    uiSelectionOccurring = true;
    notifyUiSelection();
  };

  const uiExpandRowSelection = rowIndex => {
    expandRowSelection(rowIndex);
    uiSelectionOccurring = true;
    notifyUiSelection();
  };

  const uiStartCellSelection = (column, rowIndex) => {
    startCellSelection(column, rowIndex);
    uiSelectionOccurring = true;
    notifyUiSelection();
  };

  const uiExpandCellSelection = (column, rowIndex) => {
    expandCellSelection(column, rowIndex);
    uiSelectionOccurring = true;
    notifyUiSelection();
  };

  const uiStartColumnSelection = column => {
    startColumnSelection(column);
    uiSelectionOccurring = true;
    notifyUiSelection();
  };

  const uiExpandColumnSelection = column => {
    expandColumnSelection(column);
    uiSelectionOccurring = true;
    notifyUiSelection();
  };

  const uiStartDataSetSelection = dataSet => {
    startDataSetSelection(dataSet);
    uiSelectionOccurring = true;
    notifyUiSelection();
  };

  const uiExpandDataSetSelection = dataSet => {
    expandDataSetSelection(dataSet);
    uiSelectionOccurring = true;
    notifyUiSelection();
  };

  const uiEndSelection = () => {
    uiSelectionOccurring = false;
  };

  const { checkOverscroll, clearOverscroll } = createOverscrollContext({
    dataSetGrid,
    getInteraction: () => interaction,
    getUiSelectionOccurring: () => uiSelectionOccurring,
    uiStartColumnSelection,
    uiExpandColumnSelection,
    uiStartRowSelection,
    uiExpandRowSelection,
    uiStartDataSetSelection,
    uiExpandDataSetSelection,
    uiStartCellSelection,
    uiExpandCellSelection,
  });

  const getToggledTouchContextMenu = () => interaction && interaction.toggledTouchContextMenu;

  const toggleSelectContextMenu = ({ clientX, clientY, cellEl, isTouch }) => {
    interaction.toggledTouchContextMenu = false;
    const onContextClose = () => {
      if (interaction && isTouch) {
        interaction.toggledTouchContextMenu = true;
      }
    };

    clipboardContext.toggleSelectContextMenu({
      cellEl,
      clientX,
      clientY,
      isTouch,
      onContextClose,
    });
  };

  const closeSelectContextMenu = () => {
    clipboardContext.closeContextMenu();
  };

  const executeDelete = () => clipboardContext.executeDelete();

  const uiSetCellReselect = (cellEl, column, rowIndex) => {
    if (interaction) {
      interaction.cellReselect = {
        cellEl,
        column,
        rowIndex,
      };
      uiSelectionOccurring = true;
    }
  };

  const uiClearCellReselect = () => {
    if (interaction.cellReselect) {
      const { column, rowIndex } = interaction.cellReselect;
      interaction.cellReselect = null;

      uiStartCellSelection(column, rowIndex);
    }
  };

  clear = () => {
    if (interaction) {
      if (interaction.allSelected) {
        interaction = null;
        updateRowRange(0, dataSetGrid.totalRows - 1);
      } else if (interaction.rowsSelected) {
        const lowerIndex = lesserOf(interaction.startRowIndex, interaction.endRowIndex);
        const higherIndex = greaterOf(interaction.startRowIndex, interaction.endRowIndex);
        interaction = null;
        updateRowRange(lowerIndex, higherIndex);
      } else if (interaction.columnsSelected) {
        const lowerIndex = lesserOf(interaction.startColIndex, interaction.endColIndex);
        const higherIndex = greaterOf(interaction.startColIndex, interaction.endColIndex);
        interaction = null;
        updateColumnRange(lowerIndex, higherIndex);
      } else if (interaction.dataSetsSelected) {
        const lowerIndex = lesserOf(interaction.startDataSetIndex, interaction.endDataSetIndex);
        const higherIndex = greaterOf(interaction.startDataSetIndex, interaction.endDataSetIndex);
        interaction = null;
        updateDataSetRange(lowerIndex, higherIndex);
      } else if (interaction.cellsSelected) {
        const lowerColIndex = lesserOf(interaction.startColIndex, interaction.endColIndex);
        const higherColIndex = greaterOf(interaction.startColIndex, interaction.endColIndex);
        const lowerRowIndex = lesserOf(interaction.startRowIndex, interaction.endRowIndex);
        const higherRowIndex = greaterOf(interaction.startRowIndex, interaction.endRowIndex);
        interaction = null;
        updateColumnRange(lowerColIndex, higherColIndex, lowerRowIndex, higherRowIndex);
      } else if (interaction.editingCell) {
        const { colIndex } = interaction;
        const { rowIndex } = interaction;
        interaction = null;
        updateCell(colIndex, rowIndex);
      }
    }

    clipboardContext.closeContextMenu();

    const { grid } = dataSetGrid[priv];
    grid.isDeepEditing = false;
    interaction = null;
  };

  const checkToExtend = (rowIndex, valueText = null) => {
    if (!dataSetGrid.readonly) {
      const { totalRows } = dataSetGrid;
      const passedValue = valueText !== null;
      const parseResult = passedValue ? cellResolver.textToValue(valueText) : null;
      if (
        rowIndex === totalRows - 1 &&
        (!passedValue || (!cellResolver.isEmpty(parseResult.value) && !parseResult.parseFailed))
      ) {
        const { grid } = dataSetGrid[priv];
        grid.applyChanges(context => {
          context.changeTotalRows(totalRows + 1);
        });
      }
    }
  };

  const focusCell = (col, rowIndex, extendSelection) => {
    const { grid } = dataSetGrid[priv];

    grid.scrollTo(col, rowIndex, true);
    if (!extendSelection) {
      clear();
      if (!col.readonly) {
        editCell(col, rowIndex);
      } else {
        uiStartCellSelection(col, rowIndex);
      }
    } else {
      uiExpandCellSelection(col, rowIndex);
    }
  };

  const focus = () => {
    if (interaction && interaction.editingCell) {
      updateCell(interaction.colIndex, interaction.rowIndex);
    } else if (interaction && interaction.cellsSelected) {
      updateCell(interaction.endColIndex, interaction.endRowIndex);
    } else {
      const { grid } = dataSetGrid[priv];
      grid.focus();
    }
  };

  const getSelectContextMenuOpen = () => clipboardContext.menuOpen;

  return {
    get hasInteractions() {
      return hasInteractions();
    },
    get hasSelection() {
      return getHasSelection();
    },
    get gridDisabled() {
      return getGridDisabled();
    },
    set gridDisabled(value) {
      setGridDisabled(value);
    },
    get scrollDisabled() {
      return getScrollDisabled();
    },
    set scrollDisabled(value) {
      setScrollDisabled(value);
    },
    get interactionsDisabled() {
      return getInteractionsDisabled();
    },
    set interactionsDisabled(value) {
      setInteractionsDisabled(value);
    },
    get toggledTouchContextMenu() {
      return getToggledTouchContextMenu();
    },
    get selectContextMenuOpen() {
      return getSelectContextMenuOpen();
    },
    get uiSelectionOccurring() {
      return uiSelectionOccurring;
    },
    get shiftSelected() {
      return getShiftSelected();
    },
    set shiftSelected(value) {
      setShiftSelected(value);
    },
    get cellsSelected() {
      return !!interaction && interaction.cellsSelected;
    },
    get rowsSelected() {
      return !!interaction && interaction.rowsSelected;
    },
    get columnsSelected() {
      return !!interaction && interaction.columnsSelected;
    },
    get dataSetsSelected() {
      return !!interaction && interaction.dataSetsSelected;
    },

    get events() {
      return gridTouchEvents;
    },

    activeCellSelection,
    activeRowSelection,
    activeColumnSelection,
    activeDataSetSelection,
    singleCellSelected,
    singleColumnSelected,
    singleRowSelected,
    singleDataSetSelected,

    selectAll,
    startRowSelection,
    expandRowSelection,
    startCellSelection,
    expandCellSelection,
    startColumnSelection,
    expandColumnSelection,
    startDataSetSelection,
    expandDataSetSelection,
    uiSelectAll,
    uiStartRowSelection,
    uiExpandRowSelection,
    uiStartCellSelection,
    uiExpandCellSelection,
    uiStartColumnSelection,
    uiExpandColumnSelection,
    uiStartDataSetSelection,
    uiExpandDataSetSelection,
    uiEndSelection,
    getCellState,
    editCell,
    deepEditCell,
    focusCell,
    focus,
    clear,
    notifyUIChange,
    getSelection,
    toggleSelectContextMenu,
    closeSelectContextMenu,
    executeDelete,
    checkToExtend,
    checkOverscroll,
    clearOverscroll,
    getInteractionInfo,
    uiSetCellReselect,
    uiClearCellReselect,
  };
};

import { createDoubleLinkedList } from './createDoubleLinkedList.js';

export const createHeaderCache = ({ gridLayout, prepareHeaders }) => {
  const rootHeaderCells = [];
  const headerRowCollection = [];
  const totalHeaderRows = prepareHeaders.length;
  const { gridHeaderViewportEl, gridEl } = gridLayout;
  const columnDataList = [];

  const getRowCellList = index => {
    return headerRowCollection[index].cellList;
  };

  const createHeaderCell = (headerData, parent) => {
    const rowIndex = parent ? parent.rowIndex + 1 : 0;

    if (rowIndex >= totalHeaderRows) {
      throw Error('Can not add a child to parent deeper than number of header rows');
    }

    const headerCellEl = document.createElement('div');
    headerCellEl.className = 'grid_header_cell';

    const { prepareHeader } = headerRowCollection[rowIndex];
    const update = prepareHeader({ headerCellEl, gridEl });
    const children = [];

    return {
      rowIndex,
      update,
      children,
      parent,

      headerData,
      headerCellEl,
      colspan: 1,
      resizeColspan: false,

      rowCellHandle: null,
    };
  };

  const getSiblingList = headerCell => {
    const { parent } = headerCell;
    const isTopRow = !parent;
    const siblings = isTopRow ? rootHeaderCells : parent.children;

    return { isTopRow, parent, siblings };
  };

  const insertInDom = (headerCell, rowEl, cellList) => {
    const { rowCellHandle, headerCellEl } = headerCell;
    const nextSibling = cellList.valueAfter(rowCellHandle);
    if (nextSibling) {
      const { headerCellEl: siblingEl } = nextSibling;
      return accumulator =>
        accumulator.queueRender(() => {
          rowEl.insertBefore(headerCellEl, siblingEl);
        });
    }
    return accumulator =>
      accumulator.queueRender(() => {
        rowEl.appendChild(headerCellEl);
      });
  };

  const flagResize = target => {
    if (!target || target.resizeColspan) {
      return;
    }

    target.resizeColspan = true;
    flagResize(target.parent);
  };

  const resizeCells = () => {
    const resizeCell = headerCell => {
      if (headerCell.resizeColspan) {
        const { children, headerCellEl } = headerCell;
        headerCell.resizeColspan = false;
        headerCell.colspan =
          children.length > 0
            ? // TODO: Fix this the next time the file is edited.
              // eslint-disable-next-line no-return-assign, no-param-reassign
              children.reduce((result, current) => (result += current.colspan), 0)
            : 1;
        const colspanRem = gridLayout.calcColspanRem(headerCell.colspan);

        headerCellEl.style.minWidth = colspanRem;
        headerCellEl.style.width = colspanRem;
        headerCellEl.style.maxWidth = colspanRem;
      }
    };

    for (let rowIndex = headerRowCollection.length - 1; rowIndex >= 0; rowIndex--) {
      const { cellList } = headerRowCollection[rowIndex];
      cellList.forEach(resizeCell);
    }
  };

  const createAppend = headerCell => {
    const { rowEl, cellList } = headerRowCollection[headerCell.rowIndex];
    const { siblings } = getSiblingList(headerCell);
    const parentCell = headerCell.parent;

    if (siblings.length > 0) {
      // has existing siblings
      const lastSiblingHandle = siblings[siblings.length - 1].rowCellHandle;
      headerCell.rowCellHandle = cellList.insertAfter(headerCell, lastSiblingHandle);
    } else if (!parentCell) {
      // empty grid and is root sibling

      headerCell.rowCellHandle = cellList.insertAfter(headerCell, null);
    } else {
      const parentCellList = headerRowCollection[parentCell.rowIndex].cellList;

      if (parentCellList.isFirst(parentCell.rowCellHandle)) {
        // header is appended to parent at the begining of the row, with no siblings

        headerCell.rowCellHandle = cellList.insertBefore(headerCell);
      } else {
        // header is appended to parent not at the begining of the row, with no siblings

        const parentPreviousSibling = parentCellList.valueBefore(parentCell.rowCellHandle);
        const lastRowSibling =
          parentPreviousSibling.children[parentPreviousSibling.children.length - 1];
        const lastRowSiblingHandle = lastRowSibling.rowCellHandle;
        headerCell.rowCellHandle = cellList.insertAfter(headerCell, lastRowSiblingHandle);
      }
    }

    siblings.push(headerCell);

    flagResize(headerCell);

    return insertInDom(headerCell, rowEl, cellList);
  };

  const createInsert = (headerCell, index) => {
    const { rowEl, cellList } = headerRowCollection[headerCell.rowIndex];
    const { siblings } = getSiblingList(headerCell);

    const insertSiblingHandle = siblings[index].rowCellHandle;
    headerCell.rowCellHandle = cellList.insertBefore(headerCell, insertSiblingHandle);
    siblings.splice(index, 0, headerCell);

    flagResize(headerCell);

    return insertInDom(headerCell, rowEl, cellList);
  };

  const createRemove = headerCell => {
    const { headerCellEl } = headerCell;
    const { siblings } = getSiblingList(headerCell);
    const rowCellList = getRowCellList(headerCell.rowIndex);
    const headerCellIndex = siblings.indexOf(headerCell);
    siblings.splice(headerCellIndex, 1);
    rowCellList.remove(headerCell.rowCellHandle);

    flagResize(headerCell.parent);

    return accumulator =>
      accumulator.queueRender(() => {
        headerCellEl.remove();
      });
  };

  const resolveCacheData = () => {
    const lastRowCells = headerRowCollection[totalHeaderRows - 1].cellList;
    columnDataList.splice(0);

    lastRowCells.forEach(headerCell => {
      columnDataList.push(headerCell.headerData);
    });
  };

  const initHeaderRows = () => {
    prepareHeaders.forEach((prepareHeader, rowIndex) => {
      const rowEl = document.createElement('div');
      const cellList = createDoubleLinkedList();
      rowEl.className = 'grid_header_row';
      gridHeaderViewportEl.appendChild(rowEl);

      const headerRow = {
        rowEl,
        rowIndex,
        cellList,
        prepareHeader,
      };

      headerRowCollection.push(headerRow);
    });
  };

  const getHeaderElement = (...indices) => {
    let currentHeaders = rootHeaderCells;
    let header = null;
    for (const index of indices) {
      if (index < 0 || index >= currentHeaders.length) {
        header = null;
        break;
      }

      header = currentHeaders[index];
      currentHeaders = header.children;
    }

    if (header) {
      return header.headerCellEl;
    }

    return undefined;
  };

  initHeaderRows();

  return {
    createHeaderCell,
    createAppend,
    createInsert,
    createRemove,
    resizeCells,

    resolveCacheData,

    getRowCellList,
    getHeaderElement,

    get columnDataList() {
      return columnDataList;
    },

    get rootHeaderCells() {
      return rootHeaderCells;
    },

    get totalHeaderRows() {
      return totalHeaderRows;
    },
  };
};

import { priv } from '../priv.js';

export const renderColumns = ({ newColumns, dataSet, maxLength, append = false, index = 0 }) => {
  const dataSetGrid = dataSet.grid;
  const { grid } = dataSetGrid[priv];

  const gridLength = grid.length;
  const dataSetPosition = dataSet.position;

  grid.applyChanges(context => {
    const dataSetHeader = context.getHeader(dataSetPosition);

    if (append) {
      newColumns.forEach(col => {
        dataSetHeader.appendChildHeader({ data: col });
      });
    } else {
      newColumns.forEach((col, newColIndex) => {
        const position = index + newColIndex;
        dataSetHeader.insertChildHeader(position, { data: col });
      });
    }

    if (maxLength > gridLength) {
      context.changeTotalRows(maxLength);
    }
  });
};

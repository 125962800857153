import { css } from 'lit';
import { baseAnimation, fadeInDown, fadeOut, fadeOutUp } from '../styles/index.js';

export default css`
  /* ---single-to-multi-line---
////////////////////////
 ---end--- */
  @keyframes fadeInDown {
    0% {
      opacity: 0;
      transform: translate3d(0, -10px, 0);
    }
    100% {
      opacity: 1;
      transform: none;
    }
  }

  /* ---single-to-multi-line---
////////////////////////
 ---end--- */
  @keyframes fadeInLeft {
    0% {
      opacity: 0;
      transform: translate3d(-10px, 0, 0);
    }
    100% {
      opacity: 1;
      transform: none;
    }
  }

  /* ---single-to-multi-line---
////////////////////////
 ---end--- */
  @keyframes fadeInRight {
    0% {
      opacity: 0;
      transform: translate3d(10px, 0, 0);
    }
    100% {
      opacity: 1;
      transform: none;
    }
  }

  /* ---single-to-multi-line---
////////////////////////
 ---end--- */
  @keyframes fadeInUp {
    0% {
      opacity: 0;
      transform: translate3d(0, 10px, 0);
    }
    100% {
      opacity: 1;
      transform: none;
    }
  }

  /* ---single-to-multi-line---
////////////////////////
 ---end--- */
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  /* ---single-to-multi-line---
////////////////////////
 ---end--- */
  @keyframes fadeOut {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

  /* ---single-to-multi-line---
////////////////////////
 ---end--- */
  @keyframes fadeOutDown {
    0% {
      opacity: 1;
      transform: translate3d(0, -10px, 0);
    }
    100% {
      opacity: 0;
      transform: none;
    }
  }

  /* ---single-to-multi-line---
////////////////////////
 ---end--- */
  @keyframes fadeOutDownFar {
    0% {
      opacity: 1;
      transform: none;
    }
    100% {
      opacity: 0;
      transform: translate3d(0, 20px, 0);
    }
  }

  /* ---single-to-multi-line---
////////////////////////
 ---end--- */
  @keyframes fadeOutUp {
    0% {
      opacity: 1;
      transform: none;
    }
    100% {
      opacity: 0;
      transform: translate3d(0, -10px, 0);
    }
  }

  /* ---single-to-multi-line---
////////////////////////
 ---end--- */
  @keyframes glow {
    0% {
      box-shadow: 0 0 0 0 var(--vst-color-info);
    }
    25% {
      box-shadow: 0 0 10px 2px var(--vst-color-info);
    }
    50% {
      box-shadow: 0 0 0 0 var(--vst-color-info);
    }
    75% {
      box-shadow: 0 0 10px 2px var(--vst-color-info);
    }
    100% {
      box-shadow: 0 0 0 0 var(--vst-color-info);
    }
  }

  :host {
    display: none;
    position: absolute;
    width: 100%;
    height: calc(100% - var(--chrome-menubar-height, 0px));
    /*  stylelint-disable-line length-zero-no-unit */
    top: var(--chrome-menubar-height, 0px);
    /*  stylelint-disable-line length-zero-no-unit */
    left: 0;
    z-index: calc(var(--vst-z-popover) - 1);
  }

  /*  :host([is-active]) { */
  /*      display: block; */
  /*  } */
  :host(.animate-in),
  .animate-in {
    ${baseAnimation}
    ${fadeInDown}
  }

  :host(.animate-out),
  .animate-out {
    ${baseAnimation}
    ${fadeOutUp}
  }

  :host(.fade-out),
  .fade-out {
    ${baseAnimation}
    ${fadeOut}
  }

  .prediction-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: calc(100% - var(--chrome-menubar-height, 0px));
    /*  stylelint-disable-line length-zero-no-unit */
  }

  .prediction-toolbar {
    flex: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: var(--vst-color-prediction-toolbar-bg);
    box-shadow: var(--vst-shadow-m);
    padding: var(--vst-space-xs);
  }

  .prediction-drawing-wrapper {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
  }

  .prediction-drawing {
    display: flex;
    position: fixed;
    top: 0;
    bottom: 3.25rem;
    left: calc(4.25rem + constant(safe-area-inset-left));
    /*  iOS notch 11.0 */
    left: calc(4.25rem + env(safe-area-inset-left));
    /*  iOS notch 11.2 */
    right: calc(1.0625rem + constant(safe-area-inset-right));
    /*  iOS notch 11.0 */
    right: calc(1.0625rem + env(safe-area-inset-right));
    /*  iOS notch 11.2 */
    background: var(--vst-color-predictions-overlay-bg);
  }

  .prediction-title-wrapper {
    display: flex;
    align-items: center;
  }

  #prediction_title {
    inline-size: 100%;
  }
  .prediction-btn-wrapper {
    display: flex;
    flex-shrink: 0;
  }

  .svg-canvas {
    flex: 1;
  }
  .svg-canvas polyline {
    stroke: rgba(120, 120, 120, 0.7);
    stroke-width: 5;
    fill: none;
  }

  .intro {
    flex: 1;
    text-align: center;
    z-index: 2;
    /*  what? why do we have to do this? */
    pointer-events: none;
    max-inline-size: 50%;
    /*  keep it from being off the graph in small screens */
  }

  .intro.hidden {
    display: none;
  }

  .intro-svg {
    max-inline-size: 100%;
  }

  .intro-svg-path {
    stroke: rgba(120, 120, 120, 0.7);
    stroke-width: 10px;
    animation: dashoffset 1s ease-in-out forwards;
  }

  .intro-text {
    font-size: var(--vst-font-size-xl);
    font-weight: bold;
    margin: 0;
  }

  @keyframes dashoffset {
    to {
      stroke-dashoffset: 0;
    }
  }
`;

import EventTarget from '@ungap/event-target';
import { mapKeys, throttle } from 'lodash-es';
import { pointerTracker } from '@utils/pointerTracker.js';
import {
  Chart,
  LinearScale,
  CategoryScale,
  LineController,
  BarController,
  PointElement,
  LineElement,
  BarElement,
} from 'chart.js';
import {
  BarWithErrorBar,
  BarWithErrorBarsController,
  LineWithErrorBarsController,
  PointWithErrorBar,
  ScatterWithErrorBarsController,
} from 'chartjs-chart-error-bars';
import { changeAlpha } from '@utils/helpers.js';
import { AnnotationType } from '@common/mobx-stores/Annotation.js';

import { Axis } from './Axis.js';

const HIGHLIGHT_SIZE = 5;

/**
 * Threshold in MS above which subsequent update() calls will be throttled
 * during user gesturing.
 */
const THROTTLE_UPDATE_THRESHOLD_MS = 80;

/**
 * A slop value we add to the new throttle duration to keep graph update ahead
 * of the stream of gestures pouring in.
 */
const THROTTLE_DURATION_EXTRA_MS = 10;

/**
 * A timeout value we use to simulate the missing 'touchend' events on iOS.
 */
const GESTURE_RESET_INTERVAL_MS = 1200;

Chart.register(
  BarController,
  BarElement,
  BarWithErrorBar,
  BarWithErrorBarsController,
  CategoryScale,
  LinearScale,
  LineController,
  LineElement,
  LineWithErrorBarsController,
  PointElement,
  PointWithErrorBar,
  ScatterWithErrorBarsController,
  {
    id: 'VstIntegralFill',
    beforeDatasetDraw(chart, args) {
      if (!args.meta?.dataset || args.meta.dataset.options.fill !== 'vst-integral') return;

      const {
        data: points,
        dataset: {
          options: { backgroundColor: color },
        },
        vScale: scale,
      } = args.meta;
      const {
        ctx: context,
        chartArea: { left, top, width, height },
      } = chart;
      const yZero = scale.getPixelForValue(0);

      context.save();
      context.beginPath();
      context.rect(left, top, width, height);
      context.clip();
      context.fillStyle = color;
      context.fill(
        new Path2D(`
          M${points[0].x},${yZero}
          L${points.map(point => `${point.x} ${point.y}`).join('L')}
          L${points[points.length - 1].x},${yZero}
          Z`),
      );
      context.restore();
    },
  },
  {
    id: 'VstPeakIntegralFill',
    beforeDatasetDraw(chart, args) {
      if (!args.meta?.dataset || args.meta.dataset.options.fill !== 'vst-peak-integral') return;

      const {
        data: points,
        dataset: {
          options: { backgroundColor: color },
        },
        vScale: scale,
      } = args.meta;
      const { ctx: context } = chart;
      const startPoint = points.at(0);
      const endPoint = points.at(-1);
      const yZero = scale.getPixelForValue(0);

      context.save();
      context.beginPath();

      // Set a clip path so that only area below the line is filled
      context.clip(
        // Draw a line from the selection's left edge at y=0, down to the first
        // point, then a line along the trace, then back up along the
        // selection's right edge up to y=0, closing to the origin.
        new Path2D(`
          M ${startPoint.x},${yZero}
          L ${args.meta.data.map(point => `${point.x},${point.y}`).join('L')}
          L ${endPoint.x},${yZero}
          Z
        `),
      );

      context.beginPath();
      context.fillStyle = color;

      //    x    Draw a quadrilateral from the selection's left edge at y=0,
      //   o——>  down to the first point, then a line to the selection's right
      // y |     edge at the last point, closing to the origin.
      //   V
      context.fill(
        new Path2D(`
          M ${startPoint.x},0
          L ${startPoint.x},${startPoint.y}
          L ${endPoint.x},${endPoint.y}
          L ${endPoint.x},0
          z
        `),
      );

      context.restore();
    },
  },
);

// Setting up custom defaults
Chart.defaults.font.family = `-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif`;
Chart.defaults.font.size = 12;

/**
 * A formatting function to get the data from the array of arrays Flot used to the array of objects Chart.js uses
 * @param {Array} arrayChartData An array of chart data such as [[x1, y1], [x2, y2], ...]
 * @returns {Array} The data formatted as an array of Objects: [{x: x1, y: y1}, {x: x2, y: y2}, ...]
 */
function arrayChartDataToObjectChartData(arrayChartData) {
  return arrayChartData.map(data => ({
    x: data[0],
    y: data[1],
  }));
}

function afterFit(context) {
  // ensure always having a 0 line
  if (context.ticks.length <= 1) return;
  if (context.min <= 0 && context.max >= 0 && !context.ticks.find(tick => tick.value === 0))
    context.ticks.push({ value: 0, label: '0' });
}

// #region deprecation warning helpers
const deprecationWarnings = {
  set: {
    name: 'DEPRECATED SET ACTIONS',
    errs: [],
  },
  call: {
    name: 'DEPRECATED FUNCTION CALLS',
    errs: [],
  },
  access: {
    name: 'DEPRECATED INTERNAL ACCESSES',
    errs: [],
  },
};

const warn = msg => {
  const warn = false;
  if (warn) console.warn(msg);
};

let inStartupPhase = true;
setTimeout(() => {
  inStartupPhase = false;

  Object.values(deprecationWarnings).forEach(val => {
    warn(`${val.name}: ${val.errs.length} instances`);
    val.errs.forEach(errItem => {
      warn(errItem);
    });
  });
}, 7000);

function generateWarning(type, msg) {
  if (inStartupPhase) {
    const err = new Error(msg);
    if (!deprecationWarnings[type].errs.find(item => item.stack === err.stack)) {
      deprecationWarnings[type].errs.push(err);
    }
  } else {
    warn(msg);
  }
}
// #endregion

/**
 * Get the various elements used in setting up the graph
 * @param {VstCoreGraph} vstCoreGraph the vst-core-graph element
 * @returns {Object}
 */
function _getElements(vstCoreGraph) {
  // TODO: remove the axis and boxes from vst-core-graph.js and use the Chart.js built ins-for ease and performance in resizes-with non-rendering DOM backers for interactive pieces
  const graphEl = vstCoreGraph.shadowRoot.querySelector('.graph');

  const elObj = {
    root: graphEl,
    wrapper: graphEl.querySelector('.graph-wrapper'),
    chartCanvas: graphEl.querySelector('#chart_canvas'),
    baseAxis: graphEl.querySelector('.graph-base-axis'),
    leftAxis: graphEl.querySelector('.graph-left-axis'),
    rightAxis: graphEl.querySelector('.graph-right-axis'),
    box: graphEl.querySelector('#event_box'),
    plotBox: graphEl.querySelector('#plot_box'),
    baseAxisBtnEl: graphEl.querySelector('.base-axis-label'),
    leftAxisBtnEl: graphEl.querySelector('.left-axis-label'),
    rightAxisBtnEl: graphEl.querySelector('.right-axis-label'),
  };

  return elObj;
}

/**
 * The Graph object wrapping the Chart.js library with an EventTarget in order to provide an abstraction over the charting library
 */
export class Graph extends EventTarget {
  // #region Initializers

  /**
   * @returns default Chart.js options
   */
  static get defaultConfig() {
    return {
      type: 'line',
      options: {
        animation: false,
        maintainAspectRatio: false,
        responsive: true,
        plugins: {
          legend: {
            display: false,
          },
          Filler: false,
        },
      },
    };
  }

  get categorical() {
    return this._categorical;
  }

  set categorical(value) {
    this._categorical = value;
    const axis = this.getAxis('base');
    axis.options.beginAtZero = this._categorical;
    axis.options.type = this._categorical ? 'category' : 'linear';
  }

  /**
   * Build scales from Axis objects
   * @returns {Object} Scales
   */
  get _scales() {
    return Object.fromEntries(this.axes.map(axis => [axis.id, axis.options]));
  }

  _axisOptions() {
    return [
      [
        this,
        'x',
        {
          display: true,
          position: 'bottom',
          name: 'base',
          axis: 'x',
          min: 0,
          max: 1,
          title: {
            display: false,
            text: 'x',
          },
          afterFit,
          afterUpdate: () => {
            // here we are going to change the bar thickness if we have any bar charts and the visible amount of bars is less than than 2px per bar across
            const barCharts = this.plot.data.datasets.filter(dataset => dataset.type === 'bar');
            for (const barChart of barCharts) {
              barChart.barThickness = undefined;
              const { min: xMin, max: xMax } = this.plot.scales.x;
              const visibleBars = barChart.data.filter(({ x }) => x >= xMin && x <= xMax);
              if (visibleBars.length > this.plotAreaPxWidth / 2) barChart.barThickness = 2;
            }
          },
        },
      ],
      [
        this,
        'yAxis1',
        {
          display: true,
          position: 'left',
          name: 'left',
          axis: 'y',
          min: 0,
          max: 1,
          title: {
            display: false,
            text: 'y',
          },
          afterFit,
          afterUpdate: scale => {
            // check all graph yaxis sizes and align them to the maximum
            if (!this.graphGroup || !this.graphGroup.graphInstances || this.coreGraphEl.hidden)
              return;
            // grab it before we set it so we are always the maximum of the originals rather than our fudged width
            this._originalYAxisWidth = scale.width;
            // base our calcs on the originals
            const filteredInstances = this.graphGroup.graphInstances.filter(
              graph => graph !== this && graph._originalYAxisWidth && !graph.coreGraphEl.hidden,
            );
            const instanceWidths = filteredInstances.map(graph => graph._originalYAxisWidth);
            scale.width = Math.max(scale.width, ...instanceWidths);
            if (scale.width !== this._priorYAxisWidth && !this._artificalYAxisUpdate) {
              filteredInstances.forEach(async graphInstance => {
                clearTimeout(graphInstance._updateYAxisTimeout);
                graphInstance._artificalYAxisUpdate = true;
                graphInstance._updateYAxisTimeout = setTimeout(
                  () => graphInstance.plot.update(),
                  50,
                );
              });
            }
            delete this._artificalYAxisUpdate;
            this._priorYAxisWidth = scale.width;
          },
        },
      ],
      [
        this,
        'yAxis2',
        {
          grid: {
            drawTicks: true,
            drawOnChartArea: false,
          },
          display: false,
          position: 'right',
          name: 'right',
          axis: 'y',
          min: 0,
          max: 1,
          title: {
            display: false,
            text: 'y',
          },
          afterFit,
        },
      ],
    ];
  }

  // #endregion

  /**
   * Create the Graph object
   * @param {VstCoreGraph} vstCoreGraph the vst-core-graph element necessary for positioning
   */
  constructor(vstCoreGraph) {
    super();

    this.rawTracePairs = [];
    this.isInterpolating = false;
    this.hideFits = false;
    this.hideIntegrals = false;
    this.hideTangents = false;
    this.hidePeakIntegrals = false;

    this._categorical = false;

    this.drawOptions = {
      bars: false,
      lines: true,
      points: false,
      symbol: 'circle',
    };

    this.el = _getElements(vstCoreGraph);
    this.coreGraphEl = vstCoreGraph;

    // TODO: a tad brittle ಠ_ಠ clean this up in move to vst-ui-graph move
    this.graphGroup = this.coreGraphEl.parentNode.host.closest('#graph_group');
    this.axes = this._axisOptions().map(axisOptions => new Axis(...axisOptions));
    this.axes.forEach(axis => {
      axis.addEventListener('axis-range-updated', ({ detail: { min, max } }) => {
        this.dispatchEvent(new CustomEvent('axis-range-updated', { detail: { min, max, axis } }));
      });
    });

    this.plot = new Chart(this.el.chartCanvas, Graph.defaultConfig);
    this.update();

    const axisDrag = {};
    pointerTracker(
      this.coreGraphEl,
      event => {
        const {
          currentTarget,
          detail: { state, x, y },
        } = event;
        if (this.coreGraphEl.disableAxisTranslate || currentTarget !== this.coreGraphEl) return;
        const onStart = () => {
          const chartBox = this.coreGraphEl.shadowRoot
            .querySelector('#chart_canvas')
            .getBoundingClientRect();
          if (
            !(
              x >= chartBox.left &&
              x <= chartBox.right &&
              y >= chartBox.top &&
              y <= chartBox.bottom
            )
          )
            return;
          axisDrag.started = true;
          axisDrag.lastPoint = { x, y };
          axisDrag.chartRect = this.el.chartCanvas.getBoundingClientRect();
          axisDrag.axisY = this.getAxis('left');
          axisDrag.axisX = this.getAxis('base');
          const canvasX = x - axisDrag.chartRect.x;
          const canvasY = y - axisDrag.chartRect.y;
          if (axisDrag.axisX.overTicks(canvasX)) {
            axisDrag.movingY = true;
            this.dispatchEvent(new CustomEvent('axis-moving-started'));
          }
          if (axisDrag.axisY.overTicks(canvasY)) {
            axisDrag.movingX = true;
            this.dispatchEvent(new CustomEvent('axis-moving-started'));
          }
        };
        const onEnd = () => {
          if (!axisDrag.started) return;
          axisDrag.movingX = false;
          axisDrag.movingY = false;
          // This will clean up gesture throttling code and then broadcast
          // the new axes values to everyone else.
          this.setGestureRanges({ gestureEnding: true });
          requestAnimationFrame(() => {
            this.dispatchEvent(new CustomEvent('axis-moving-ended'));
          });
        };
        const onMove = () => {
          if (!axisDrag.started) return;
          // Run 1 finger gestures through the setGestureRanges() bottleneck to
          // utilize its throttling (MEG-2618).
          const newRanges = {
            gestureEnding: false,
          };
          if (axisDrag.movingX) {
            const deltaX = axisDrag.axisX.c2p(0) - axisDrag.axisX.c2p(axisDrag.lastPoint.x - x);
            newRanges.base = axisDrag.axisX.translate(deltaX, false);
          }
          if (axisDrag.movingY) {
            const deltaY = axisDrag.axisY.c2p(0) - axisDrag.axisY.c2p(axisDrag.lastPoint.y - y);
            newRanges.left = axisDrag.axisY.translate(deltaY, false);
          }
          this.setGestureRanges(newRanges);
          axisDrag.lastPoint = { x, y };
        };

        if (state === 'start') onStart();
        if (state === 'end') onEnd();
        if (state === 'moving') onMove();
      },
      this.coreGraphEl,
    );
  }

  get plotBox() {
    const heightRange = this.getAxis('left').rangePx;
    const widthRange = this.getAxis('base').rangePx;
    return {
      height: heightRange.min - heightRange.max,
      width: widthRange.max - widthRange.min,
    };
  }

  get readOnly() {
    if (PRODUCTION) {
      return this;
    }

    return new Proxy(this, {
      get(graph, prop, receiver) {
        if (
          // Any mutators (or have side-effects that mutate)...
          typeof graph[prop] === 'function' &&
          (prop.startsWith('_') ||
            prop.startsWith('set') ||
            prop.startsWith('update') ||
            (prop.startsWith('add') && prop !== 'addEventListener') ||
            prop.startsWith('create') ||
            prop.startsWith('enable') ||
            prop.startsWith('disable') ||
            prop.startsWith('show') ||
            prop.startsWith('queue') ||
            prop.startsWith('refresh') ||
            prop.startsWith('plotBox') ||
            (prop.startsWith('remove') && prop !== 'removeEventListener') ||
            prop === 'redraw' ||
            prop === 'resize' ||
            prop === 'pointToCanvas' ||
            prop === 'canvasToPoint' ||
            prop === 'autoscale')
        ) {
          generateWarning(
            'call',
            `Calling mutation function '${prop}' is deprecated on read-only graph; use vst-core-graph's API.`,
          );
        }

        if (typeof graph[prop] === 'object') {
          generateWarning(
            'access',
            `Access to internal object '${prop}' is deprecated on read-only graph; use vst-core-graph's API.`,
          );
        }
        const reflection = Reflect.get(graph, prop, receiver);
        return typeof reflection === 'function' ? reflection.bind(graph) : reflection;
      },
      set(graph, prop, value) {
        generateWarning(
          'set',
          `Setting '${prop}' is deprecated on read-only graph; use vst-core-graph's API.`,
        );

        return Reflect.set(graph, prop, value);
      },
    });
  }

  get plotAreaPxWidth() {
    return this.plot.width;
  }

  get plotAreaPxHeight() {
    return this.plot.height;
  }

  /**
   * the size of the plot area
   * @returns {Number}
   */
  get plotSize() {
    return { width: this.plot.chartArea.width, height: this.plot.chartArea.height };
  }

  get root() {
    return this.el.root;
  }

  /**
   * Calculates a point size value for the given index
   * @param {number} index Series data index
   * @param {number[]} seriesAlignedPointIndexes Series-aligned point indexes
   * @param {Array<{ startIndex: number, endIndex: number }>} seriesAlignedRanges Series-aligned range indexes
   * @returns {number}
   */
  static _getPointSize(index, seriesAlignedPointIndexes, seriesAlignedRanges) {
    return seriesAlignedPointIndexes.includes(index) ||
      seriesAlignedRanges.some(
        ({ startIndex, endIndex }) => index >= startIndex && index <= endIndex,
      )
      ? HIGHLIGHT_SIZE / 2
      : 0;
  }

  /**
   * Calculates a border size value for the given segment
   * @param {object} context Chart.js scriptable context
   * @param {Array<{ startIndex: number, endIndex: number }>} seriesAlignedRangeIndexes Series-aligned range indexes
   * @returns {Number}
   */
  static _getSegmentBorderSize(context, seriesAlignedRangeIndexes) {
    return seriesAlignedRangeIndexes.some(
      ({ startIndex, endIndex }) =>
        // p0-p1 Segment is within the annotation's range
        context.p0DataIndex >= startIndex && context.p1DataIndex <= endIndex,
    )
      ? HIGHLIGHT_SIZE
      : undefined;
  }

  /**
   * Get an axis
   * @param {'base' | 'left' | 'right'} axisid the id of the axis
   * @returns {Axis}
   */
  getAxis(axisid) {
    return this.axes.find(axis => axis.options.name === axisid);
  }

  /**
   * Get the range of an axis
   * @param {'base' | 'left' | 'right'} axisid the id of the axis to get the range from
   * @returns {Object} the {min, max} range of the axis
   */
  getAxisRange(axisid) {
    return this.getAxis(axisid).range;
  }

  // #region TODO: remove these for getAxisRange or replace them with getters
  getBaseRange() {
    return this.getAxisRange('base');
  }

  getLeftRange() {
    return this.getAxisRange('left');
  }

  getRightRange() {
    return this.getAxisRange('right');
  }
  // #endregion

  getLeftColumnIds() {
    return this.coreGraphEl.getLeftTraces().map(t => t.yColumn.id);
  }

  /**
   * Set the range of an axis
   * @param {'base' | 'left' | 'right'} axisid the id of the axis to set
   * @param {Object} range the new range of the axis
   * @param {Number} range.min the minimum for the range
   * @param {Number} range.max the maximum for the range
   */
  setAxisRange(axisid, { min, max }) {
    if (!Number.isNaN(min) && !Number.isNaN(max)) {
      const axis = this.getAxis(axisid);
      axis.setRange({ min, max });
      this.update();
    }
  }

  // #region TODO: remove these for setAxisRange or if replacing the get{Axis}Range with getters, make these setters
  setBaseRange(min, max) {
    this.setAxisRange('base', { min, max });
  }

  setLeftRange(min, max) {
    this.setAxisRange('left', { min, max });
  }

  setRightRange(min, max) {
    this.setAxisRange('right', { min, max });
  }
  // #endregion

  /**
   * Convert a point to/from the Canvas/DOM
   * @param {Object} point an x/y pair representing a point
   * @param {Number} point.x the x coordinate of the point
   * @param {Number} point.y the y corrdinate of the point
   * @param {String} direction either 'p2c' for DOM point to Canvas, or 'c2p' for Canvas point to DOM
   * @returns the converted x/y pair point
   */
  _pointConvertion({ x, y }, direction) {
    const baseAxis = this.getAxis('base');
    const leftAxis = this.getAxis('left');

    return {
      x: baseAxis[direction](x),
      y: leftAxis[direction](y),
    };
  }

  /**
   * Convert a DOM point to a Canvas point
   * @param {Object} point an x/y pair representing a point in the DOM
   * @param {Number} point.x the x coordinate of the point
   * @param {Number} point.y the y corrdinate of the point
   * @returns an x/y pair representing a point in the Canvas
   */
  pointToCanvas(point) {
    return this._pointConvertion(point, 'p2c');
  }

  /**
   * Convert a Canvas point to a DOM point
   * @param {Object} point an x/y pair representing a point in the Canvas
   * @param {Number} point.x the x coordinate of the point
   * @param {Number} point.y the y corrdinate of the point
   * @returns an x/y pair representing a point in the DOM
   */
  canvasToPoint(point) {
    return this._pointConvertion(point, 'c2p');
  }

  /**
   * required functions to resize the graph appropriately
   */
  resize() {
    this.plot.resize();
    this.update();
  }

  /**
   * turn on/off the right axis
   * @param {Boolean} enabled The enabled state
   */
  setRightAxisEnabled(enabled) {
    this.getAxis('right').options.display = !!enabled;
    this.update();
  }

  /**
   * Set the scaling mode for all the axes. Options for each axis are "automatic_scaling", "show_zero_scaling", "manual_scaling"
   * @param {Object} modes the scaling modes of the various axes
   * @param {String} modes.base the scaling mode for the base axis
   * @param {String} modes.left the scaling mode for he left axis
   * @param {String} modes.right the scaling mode for the right axis
   */
  setScalingModes(modes) {
    this.axes
      .filter(({ name }) => modes[name])
      .forEach(axis => {
        axis.autoscaleMode = modes[axis.name];
      });
  }

  /**
   * show traces using bars rather than lines or points
   * NOTE: showing bars excludes points and lines
   * @param {boolean} show
   */
  showBars(show) {
    this.drawOptions.bars = show;
    this.getAxis('base').options.offset = show;
    this.coreGraphEl.updatePlotData();
  }

  /**
   * turn on/off the graph lines
   * @param {Boolean} show the show state of the graph lines
   */
  showLines(show) {
    this.drawOptions.lines = show;
    this.coreGraphEl.updatePlotData();
  }

  /**
   * turn on/off the graph points
   * @param {Boolean} show the show state of the graph points
   */
  showPoints(show) {
    this.drawOptions.points = show;
    this.coreGraphEl.updatePlotData();
  }

  _pointRadiusCalculation(trace, element) {
    if (!element) return 0;
    const { x, y } = element;
    const { left, top, right, bottom } = this.plot.chartArea;
    if (
      x < left ||
      x > right ||
      y < top ||
      y > bottom ||
      !(trace?.drawOptions?.points ?? this.drawOptions.points)
    )
      return 0;
    if (trace.pointSizeFactor) return trace.lineWeight * trace.pointSizeFactor;
    return trace.lineWeight * 1.5 * this.coreGraphEl.accessibilityScale;
  }

  /**
   * Update the graph data
   * @param {Object} tracesData The various traces that make up the graph
   * @param {Boolean} allowSpecialAutoscale whether to allow special autoscale or not
   */
  async updateData(tracesData, allowSpecialAutoscale) {
    const {
      errorBars,
      traces,
      fitTraces,
      manualFitTraces,
      integralTraces,
      tangentTraces,
      peakIntegralTracesData,
    } = tracesData;

    const tracePoints = {};
    const tangentData = this.hideTangents
      ? []
      : tangentTraces.flatMap(({ axis, tangentLines }) =>
          tangentLines.map(line => ({
            backgroundColor: 'rgba(0,0,0,1)',
            borderColor: 'rgba(0,0,0,1)',
            borderWidth: line.lineWidthFactor * this.coreGraphEl.accessibilityScale,
            data: arrayChartDataToObjectChartData(line.data),
            ignoreAutoscale: true,
            pointRadius: 0,
            showLine: true,
            yAxisID: axis === 'right' ? 'yAxis2' : 'yAxis1',
          })),
        );
    const getChartType = trace => {
      let chartType = Graph.defaultConfig.type;
      if (this.drawOptions.bars && trace.type !== 'prediction') {
        chartType = 'bar';
      }
      if (this.drawOptions.bars && trace.hasErrorBars) {
        chartType = BarWithErrorBarsController.id;
      }
      if (this.drawOptions.lines && trace.hasErrorBars) {
        chartType = LineWithErrorBarsController.id;
      }
      if (this.drawOptions.points && trace.hasErrorBars) {
        chartType = ScatterWithErrorBarsController.id;
      }
      return chartType;
    };
    const calculateClip = trace => {
      const CLIP_PADDING_FACTOR = 1.5;
      const ERROR_BAR_CLIP_PADDING = 7;
      const EXTRA_CLIP_PADDING = 2;

      const baseClip = this.drawOptions.points
        ? trace.lineWeight * CLIP_PADDING_FACTOR * this.coreGraphEl.accessibilityScale +
          EXTRA_CLIP_PADDING
        : 0;
      const errorBarClip = trace.hasErrorBars ? ERROR_BAR_CLIP_PADDING : 0;

      return Math.max(baseClip, errorBarClip);
    };
    const datasets = [
      ...tangentData,
      ...this.rawTracePairs.map(trace => ({
        type: this.drawOptions.bars ? 'bar' : Graph.defaultConfig.type,
        backgroundColor: trace.color,
        borderColor: trace.color,
        borderWidth: trace.lineWeight,
        data: arrayChartDataToObjectChartData(trace.points),
        ignoreAutoscale: true,
        pointRadius: ({ element }) => this._pointRadiusCalculation(trace, element),
        clip: trace.lineWeight * trace.pointSizeFactor + 2,
        showLine: trace?.drawOptions?.lines ?? this.drawOptions.lines,
        yaxisID: 'yAxis1',
      })),
      ...traces.map((trace, i) => {
        const annotationsForTrace = this.coreGraphEl.annotations.filter(annotation =>
          annotation.containsTargetColumn(trace.yColumn.id),
        );
        const pointIndexesForAnnotations = annotationsForTrace
          .filter(annotation => annotation.type === AnnotationType.POINT)
          .map(annotation => annotation.getPointIndexForColumn(trace.yColumn.id));
        const rangesIndexesForAnnotations = annotationsForTrace
          .filter(annotation => annotation.type === AnnotationType.RANGE)
          .map(annotation => annotation.getRangeIndexesForColumn(trace.yColumn.id));
        const seriesAlignedPointIndexes = pointIndexesForAnnotations.map(point =>
          trace.getIndexOffsetFromSeries(point),
        );
        const seriesAlignedRangeIndexes = rangesIndexesForAnnotations.map(
          ({ startIndex, endIndex }) => ({
            startIndex: trace.getIndexOffsetFromSeries(startIndex),
            endIndex: trace.getIndexOffsetFromSeries(endIndex),
          }),
        );
        return {
          type: getChartType(trace, errorBars[i].length),
          backgroundColor: trace.fillPoint ? trace.color : null,
          borderColor: trace.color,
          borderWidth: trace.lineWeight * this.coreGraphEl.accessibilityScale,
          borderJoinStyle: 'round', // we used to set this to 'miter' unless it was a prediction
          data: arrayChartDataToObjectChartData(trace.seriesData).map((point, j) => {
            const [baseErrorBars, yErrorBars] = errorBars[i][j];

            if (baseErrorBars) [point.xMin, point.xMax] = baseErrorBars;
            if (yErrorBars) [point.yMin, point.yMax] = yErrorBars;
            return point;
          }),
          errorBarColor: this.coreGraphEl.colorMode === 'dark' ? '#ffffff' : '#000000',
          errorBarWhiskerColor: this.coreGraphEl.colorMode === 'dark' ? '#ffffff' : '#000000',
          errorBarWhiskerSize: 13,
          errorBarLineWidth: 2,
          errorBarWhiskerLineWidth: 2,
          pointRadius: ({ element }) => this._pointRadiusCalculation(trace, element),
          pointWithErrorBarRadius: ({ element }) => this._pointRadiusCalculation(trace, element),
          clip: calculateClip(trace),
          pointStyle: ({ dataIndex }) => {
            const highlight = Graph._getPointSize(
              dataIndex,
              seriesAlignedPointIndexes,
              seriesAlignedRangeIndexes,
            );
            if (typeof trace.drawOptions?.symbol === 'function') {
              if (!tracePoints[`${trace.id}${highlight ? '-highlight' : ''}`]) {
                const pointSize =
                  trace.lineWeight * 5 * this.coreGraphEl.accessibilityScale + highlight;
                tracePoints[`${trace.id}${highlight ? '-highlight' : ''}`] =
                  trace.drawOptions.symbol(pointSize, trace.color);
              }
              return tracePoints[`${trace.id}${highlight ? '-highlight' : ''}`];
            }
            return 'circle';
          },
          showLine: trace?.drawOptions?.lines ?? this.drawOptions.lines,
          yAxisID: trace.axis === 'right' ? 'yAxis2' : 'yAxis1',
          segment: {
            borderWidth: ctx => Graph._getSegmentBorderSize(ctx, seriesAlignedRangeIndexes),
          },
        };
      }),
      ...(this.hideFits
        ? []
        : fitTraces.map(trace => ({
            backgroundColor: changeAlpha(trace.traceColor, '0.5'),
            borderColor: changeAlpha(trace.traceColor, '0.5'),
            borderWidth:
              this.coreGraphEl.defaultLineWeight * 3 * this.coreGraphEl.accessibilityScale,
            data: arrayChartDataToObjectChartData(trace.dataPoints),
            ignoreAutoscale: true,
            pointRadius: 0,
            showLine: true,
            yAxisID: trace.axis === 'right' ? 'yAxis2' : 'yAxis1',
          }))),
      ...(this.hideFits
        ? []
        : manualFitTraces.map(trace => ({
            backgroundColor: trace.traceColor,
            borderColor: trace.traceColor,
            data: arrayChartDataToObjectChartData(trace.dataPoints),
            ignoreAutoscale: true,
            pointRadius: 0,
            showLine: true,
            yAxisID: trace.axis === 'right' ? 'yAxis2' : 'yAxis1',
          }))),
      ...(this.hideIntergrals
        ? []
        : integralTraces.map(trace => ({
            backgroundColor: changeAlpha(trace.traceColor, '0.5'),
            borderColor: changeAlpha(trace.traceColor, '0.5'),
            borderWidth: 0,
            data: arrayChartDataToObjectChartData(trace.dataPoints),
            fill: 'vst-integral',
            ignoreAutoscale: true,
            pointRadius: 0,
            showLine: true,
            yAxisID: trace.axis === 'right' ? 'yAxis2' : 'yAxis1',
          }))),
      ...peakIntegralTracesData.map(trace => ({
        backgroundColor: changeAlpha(trace.traceColor, '0.5'),
        data: arrayChartDataToObjectChartData(trace.peakSeriesData),
        fill: 'vst-peak-integral',
        pointRadius: 0,
        showLine: false,
      })),
    ];

    if (
      allowSpecialAutoscale &&
      (this.coreGraphEl.scaleLargerToNewData || this.coreGraphEl.fitToNewData)
    ) {
      this.autoscale(this.drawOptions.bars);
    }

    this.plot.data = { datasets };
    this.plot.options.scales = this._scales;

    if (this.drawOptions.bars) {
      if (this.categorical) {
        this.plot.data.labels = traces
          .map(trace => trace.baseColumn.values)
          .reduce((acc, next) => acc.concat(next), [])
          .filter((element, index, baseLabels) => baseLabels.indexOf(element) === index);
        this.setAxisRange('base', { min: 0, max: this.plot.data.labels.length });
      } else {
        this.plot.data.labels = traces.map(trace => trace.seriesData.map(data => data[0])).flat();
      }
    }
    this.plot.update();
    this.dispatchEvent(new CustomEvent('chart-data-updated'));
    this.dispatchEvent(new CustomEvent('data-values-updated', { composed: true, bubbles: true }));
  }

  /**
   * Common functionality necessary to appropriately autoscale the graph
   */
  autoscale(autoscaleFromZero) {
    let autoscaleSize = this.coreGraphEl.fitToNewData ? 'to-fit' : 'larger';
    if (this.coreGraphEl.showWavelengthRainbow) {
      autoscaleSize = 'normal';
    }
    const axisRanges = this.coreGraphEl.getAutoscaledRanges({ autoscaleSize, autoscaleFromZero });
    const rangeUpdates = mapKeys(axisRanges, (_, axis) => `${axis}Range`);
    this.coreGraphEl.dispatchEvent(
      new CustomEvent('automatic-autoscale', {
        detail: { rangeUpdates },
      }),
    );
  }

  /**
   * Update the graph
   */
  update() {
    // Update now keeps track of its performance so we can properly throttle
    // our 2 finger pinch gestures. See `setGestureRanges()` below.
    const start = performance.now();
    this.plot.options.scales = this._scales;
    this.plot.update();
    this.coreGraphEl._recalcBoxes();
    this.dispatchEvent(new CustomEvent('graph-grid-updated'));
    this._lastUpdateDuration = performance.now() - start;
  }

  /**
   * A bottleneck for distributing user pinch gestures to the graph and its
   * related components. It employs a very simple throttling strategy to
   * prevent gestures from piling up in the event that graph drawing becomes
   * too slow.
   * @param {import('../../utils/GraphGestures.js').GestureRangeMessage} rangeUpdateMessage
   * contains ranges for left, right, and base axes.
   */
  setGestureRanges(rangeUpdateMessage) {
    const { gestureEnding } = rangeUpdateMessage;

    ['left', 'right', 'base'].forEach(axisId => {
      const range = rangeUpdateMessage[axisId];
      if (range) {
        const { min, max } = range;
        const axis = this.getAxis(axisId);
        // If gestureEnding is true, this will cause the axis to emit a
        // change notification which will make everything everywhere update
        // itself all at once.
        axis.setRange({ min, max }, gestureEnding);
      }
    });

    // Set up the throttle interval to the base threshold if we haven't already.
    if (!this._throttleInterval) this._throttleInterval = THROTTLE_UPDATE_THRESHOLD_MS;
    // If it's taking longer to update than the throttle interval, we'll bump
    // up the throttle interval to match the update time, plus a little extra.
    if (this._lastUpdateDuration > this._throttleInterval) {
      this._throttleInterval = this._lastUpdateDuration + THROTTLE_DURATION_EXTRA_MS;
      this._throttledUpdate?.cancel();
      this._throttledUpdate = throttle(this.update.bind(this), this._throttleInterval, {
        leading: true,
      });
    }

    // Throttle the update if its available, else call regular update.
    if (this._throttledUpdate) this._throttledUpdate();
    else this.update();

    const resetValues = () => {
      // Hack: because iOS lacks any way of telling us the pinch gesture has
      // ended, we've gotta tell our axes to re-broadcast their ranges in order
      // to update core graph, redux, udm etc. which would've already been
      // handled above (see "everything, everywhere update itself all at once.")
      ['left', 'right', 'base'].forEach(axisId => {
        const axis = this.getAxis(axisId);
        axis?.setRange(axis.getRange());
      });
      this._lastUpdateDuration = null;
      this._throttleInterval = null;
      this._throttledUpdate?.cancel();
      this._throttledUpdate = null;
      this._gestureCancelTimerId = null;
    };

    // Due to the fact that WebKit on iOS doesn't support `touchend` or
    // `touchcancel` events, we've got to set a timeout to reset the values
    if (this._gestureCancelTimerId) clearTimeout(this._gestureCancelTimerId);
    this._gestureCancelTimerId = setTimeout(resetValues.bind(this), GESTURE_RESET_INTERVAL_MS);

    // Reset the clock when the gesture ends.
    if (gestureEnding) resetValues();
  }
}

import EventEmitter from 'eventemitter3';
import { EventBinder } from '@utils/EventBinder.js';

const sensorModeIdMap = {
  absorbance: 'SPEC_ABSORBANCE',
  transmittance: 'SPEC_TRANSMITTANCE',
  intensity: 'SPEC_INTENSITY',
  fluorescence: 'SPEC_FLUORESCENCE',
  raw: 'SPEC_RAW_DATA',
};
export class SpecDevice extends EventEmitter {
  constructor({ deviceManager, dataCollection, sensorWorld, rpc }) {
    super();
    this._awaitBinding = null;
    this._awaitTimeout = null;
    this._currentLampWarmupTime = 0;
    /**
     * Whether setup has completed for the connected device
     * @type {boolean}
     */
    this._initialConnection = false;
    this._initialRecalibrationTime = 0;
    this._initialWarmupTime = 0;
    this._isCalibrated = false;
    this._lampWarmupTimer = null;
    this._noSkipTime = 0;
    this._recalibrationTime = 0;
    this.dataCollection = dataCollection;
    this.deviceManager = deviceManager;
    this.rpc = rpc;
    this.sensorWorld = sensorWorld;

    this.eventBinder = new EventBinder();
    this.eventBinder.bindListeners({
      source: this.deviceManager,
      target: this,
      eventMap: {
        'device-list-changed': '_handleDeviceManagerDeviceListChanged',
      },
    });
  }

  get canSkipWarmUp() {
    const warmupTime = this._isCalibrated ? this._recalibrationTime : this._initialWarmupTime;
    const skippableTime = warmupTime - this._noSkipTime;
    return this._currentLampWarmupTime < skippableTime;
  }

  get currentLampWarmupTime() {
    return this._currentLampWarmupTime;
  }

  get initialWarmupTime() {
    return this._initialWarmupTime;
  }

  get isCalibrated() {
    return this._isCalibrated;
  }

  get isWarmedUp() {
    return this._currentLampWarmupTime <= 0;
  }

  get noSkipTime() {
    return this._noSkipTime;
  }

  get recalibrationTime() {
    return this._recalibrationTime;
  }

  // TODO: bind to changes to the list of *connected* devices here
  _handleDeviceManagerDeviceListChanged() {
    const { deviceManager } = this;

    if (deviceManager.hasConnectedDevices) {
      const device = this.deviceManager.getConnectedDevices()[0];

      device.on('calibrated-changed', calibrated => {
        if (calibrated) this._isCalibrated = true;
        this.emit('calibration-status-updated', calibrated);
      });

      this.dataCollection.on('spectrum-mode-changed', spectrumMode => {
        this._setUpSpectrumMode(spectrumMode);
        this.startLampTimer(this._currentLampWarmupTime);
      });

      // start the countdown lamp timer
      if (!this._initialConnection) {
        this._setUpTimers(device);
        this._setUpSpectrumMode(device.spectrumMode);
        this.startLampTimer(this._currentLampWarmupTime);
        this._initialConnection = true;
      }
    } else {
      this._resetSpecVariables();
      this.emit('spec-disconnected');
      this._initialConnection = false;
    }
  }

  _resetSpecVariables() {
    clearInterval(this._lampWarmupTimer);
    this._isCalibrated = false;
    this._lampWarmupTimer = null;
    this.emit('calibration-status-updated', false);

    const sensor = this.sensorWorld.getFirstSensor();
    if (sensor) {
      sensor.wavelength = 0;
    }
  }

  _setUpSpectrumMode(spectrumMode) {
    // Fluorescence and intensity mode have no warmup time
    const needsWarmUp = spectrumMode !== 'fluorescence' && spectrumMode !== 'intensity';
    this._isCalibrated = !needsWarmUp;
    this._recalibrationTime = needsWarmUp ? this._initialRecalibrationTime : 0;
    this._currentLampWarmupTime = needsWarmUp ? this._initialWarmupTime : 0;
    this.emit('lamp-warmup-time-changed', this._currentLampWarmupTime);
  }

  _setUpTimers(device) {
    this._initialWarmupTime = device.initialWarmupTime;
    this._initialRecalibrationTime = device.recalibrationTime;
    this._recalibrationTime = device.recalibrationTime;
    this._noSkipTime = device.noSkipTime;
  }

  async calibrateSpectrometer(experimentId, sensorID) {
    try {
      return await this.sensorWorld.calibrateSpectrometer(experimentId, sensorID);
    } catch (ex) {
      console.error(ex);
      throw ex;
    }
  }

  async setSpectrumDataMode(mode) {
    this.emit('spec-settings-changing');

    try {
      await this.dataCollection.setSpectrumDataMode(mode);
    } catch (err) {
      console.error(err);
    } finally {
      this.emit('spec-settings-changed');
    }
  }

  async setSpecSettings(experimentId, sensorID, params) {
    this.emit('spec-settings-changing');

    try {
      return await this.sensorWorld.setSpecSettings(experimentId, sensorID, params);
    } catch (ex) {
      console.error(ex);
      throw ex;
    } finally {
      this.emit('spec-settings-changed');
    }
  }

  skipWarmup() {
    clearInterval(this._lampWarmupTimer);
    this._lampWarmupTimer = null;
    this._currentLampWarmupTime = 0;
    this.emit('lamp-warmup-time-changed', this._currentLampWarmupTime);
  }

  startLampTimer(startingTime) {
    this._currentLampWarmupTime = startingTime;
    this.emit('lamp-warmup-time-changed', this._currentLampWarmupTime);
    if (!this._lampWarmupTimer && startingTime) {
      this._lampWarmupTimer = setInterval(() => {
        this._currentLampWarmupTime--;
        this.emit('lamp-warmup-time-changed', this._currentLampWarmupTime);
        if (this._currentLampWarmupTime <= 0) {
          clearInterval(this._lampWarmupTimer);
          this._lampWarmupTimer = null;
        }
      }, 1000);
    }
  }

  takeDarkReference(experimentId, sensorID) {
    return this.sensorWorld.takeDarkReference(experimentId, sensorID);
  }

  /**
   * Will call a completion when sensor configuration matches a given spectrum
   * mode, or after a certain timeout.
   * @param { String } spectrumMode mode 'absorbance', 'transmittance' etc. to
   * match sensor configuration to.
   * @param { Boolean } advanced in advanced full spectrum mode?
   * @param { Number } timeout timeout ms. after which the completion will be
   * called.
   */
  awaitSensorForMode(spectrumMode, advanced, timeout = 20000) {
    if (this._awaitBinding || this._awaitTimeout)
      throw new Error('Calls to awaitSensorForMode may not be overlapped.');

    return new Promise(resolve => {
      const { sensorWorld } = this;

      const expectedSensorId = sensorModeIdMap[spectrumMode];
      const sensor = this.sensorWorld.getFirstSensor();

      if (sensor && sensor.sensorId === expectedSensorId) {
        resolve();
        return;
      }

      const finish = () => {
        resolve();

        this.eventBinder.off(this._awaitBinding);
        this._awaitBinding = null;

        clearTimeout(this._awaitTimeout);
        this._awaitTimeout = null;
      };

      this._awaitTimeout = setTimeout(finish, timeout);

      this._awaitBinding = this.eventBinder.on(sensorWorld, 'sensor-added', s => {
        if (s.sensorId === expectedSensorId || advanced) {
          finish();
        }
      });
    });
  }
}

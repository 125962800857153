import { sprintf } from '@libs/sprintf.js';
import { getText } from '@utils/i18n.js';
import { closeCommonDialogEvent } from '@utils/closeCommonDialogEvent.js';

export function promptDeleteDataSet($dataWorld, dataSet) {
  this.dispatchEvent(
    new CustomEvent('open-dialog', {
      bubbles: true,
      composed: true,
      detail: {
        dialog: 'message_box',
        params: {
          title: sprintf(getText('Delete %s?'), dataSet.name),
          content: getText(`This deletion can't be undone.`),
          actions: [
            {
              id: 'cancel',
              message: getText('Cancel'),
              variant: 'text',
              onClick: () => {
                this.dispatchEvent(closeCommonDialogEvent('message_box'));
              },
            },
            {
              id: 'delete',
              message: getText('Delete'),
              variant: 'danger',
              onClick: async () => {
                await $dataWorld.removeDataSet(dataSet.id);
                this.dispatchEvent(closeCommonDialogEvent('message_box'));
              },
            },
          ],
        },
      },
    }),
  );
}

import { sprintf } from '@libs/sprintf.js';
import { getText } from '@utils/i18n.js';
import { closeCommonDialogEvent } from '@utils/closeCommonDialogEvent.js';

export function promptDeleteColumn($dataWorld, group) {
  return new Promise(resolve => {
    this.dispatchEvent(
      new CustomEvent('open-dialog', {
        bubbles: true,
        composed: true,
        detail: {
          dialog: 'message_box',
          params: {
            title: sprintf(getText('Delete Column %s?'), group.name),
            content: getText(
              'Column will be deleted in all data sets. The deletion cannot be undone.',
            ),
            actions: [
              {
                id: 'cancel',
                message: getText('Cancel'),
                variant: 'text',
                onClick: () => {
                  this.dispatchEvent(closeCommonDialogEvent('message_box'));
                  resolve(false);
                },
              },
              {
                id: 'delete',
                message: getText('Delete'),
                variant: 'danger',
                onClick: async () => {
                  await $dataWorld.removeColumnGroup(group.id);
                  this.dispatchEvent(closeCommonDialogEvent('message_box'));
                  resolve(true);
                },
              },
            ],
          },
        },
      }),
    );
  });
}

// EVENTS:
//
// touch events:
// -------------
// grid-touch-select-start
// grid-touch-select-enter
// grid-touch-select-move
// grid-touch-select
// grid-touch-tap
// grid-touch-edit
//
// mouse events:
// -------------
// grid-mouse-context-menu
// grid-mouse-select-start
// grid-mouse-select-move
// grid-mouse-select
// grid-mouse-edit

const touchWaitThreshhold = 300;
const clickCounterWaitThreshhold = 300;

let interactionsDisabled = false;
let eventContext = {};
let tapCounter = {};

const elementFromPoint = (x, y) => {
  let result;
  let source = document;
  let count = 50;

  // Found a case on iOS where elementFromPoint() never returns null, and we loop forever. After some testing, I found
  // that on the average, hit-testing view hieararchies never goes deeper than 3-5, but I've seen up to 9 levels deep
  // in one instance, so I think exiting after 50 levels is reasonable.
  // GA4-1360 / GA4-2991 JK 20190612.
  while (source !== null && count-- > 0) {
    result = source.elementFromPoint(x, y);
    source = result ? result.shadowRoot : null;
  }

  return result;
};

export const createGridTouchEvents = ({ dataSetGrid, onUiScroll, gridEl }) => {
  const onScroll = uiScroll => {
    eventContext.scrolled = true;
    try {
      if (uiScroll) {
        onUiScroll();
      }
    } catch (e) {
      console.error('error on vst-grid scroll'); // eslint-disable-line no-console
    }
  };

  const clearTapCounter = () => {
    if (tapCounter.timeoutHandle) {
      clearTimeout(tapCounter.timeoutHandle);
    }
    tapCounter = {};
  };

  const checkDoubleTap = (type, element) => {
    const { type: previousType, element: previousElement } = tapCounter;
    clearTapCounter();

    if (previousType === type && element === previousElement) {
      return true;
    }
    tapCounter.type = type;
    tapCounter.element = element;
    tapCounter.timeoutHandle = setTimeout(() => clearTapCounter(), clickCounterWaitThreshhold);
    return false;
  };

  const createEventRegister = (type, condition) => (name, fn) => {
    gridEl.addEventListener(name, e => {
      if (condition(e)) {
        fn(e);
      }
    });
  };

  const startTouchEvent = createEventRegister('touch begin event', () => !eventContext.mouse);
  const startMouseEvent = createEventRegister('mouse begin event', () => !eventContext.touch);
  const addMouseEvent = createEventRegister('mouse event', () => eventContext.mouse);
  const addTouchEvent = createEventRegister('touch event', () => eventContext.touch);

  const fireEvent = (element, name, detail = {}) => {
    if (!element) {
      return;
    }

    detail.dataSetGrid = dataSetGrid;

    const evt = new CustomEvent(name, {
      bubbles: true,
      composed: true,
      detail,
    });
    element.dispatchEvent(evt);
  };

  // mouse events

  startMouseEvent('mousedown', e => {
    const { clientX, clientY } = e;

    const rightClick = e.buttons === 2;

    eventContext = {
      mouse: true,
      startElement: elementFromPoint(clientX, clientY),
      rightClick,
      clientX,
      clientY,
    };

    fireEvent(eventContext.startElement, 'grid-pre-click');

    if (eventContext.rightClick) {
      fireEvent(eventContext.startElement, 'grid-pre-left-click');
    } else {
      fireEvent(eventContext.startElement, 'grid-pre-right-click');
    }

    if (interactionsDisabled) {
      return;
    }

    if (rightClick) {
      fireEvent(eventContext.startElement, 'grid-mouse-context-menu', { clientX, clientY });
    } else {
      eventContext.lastSelectedElement = eventContext.startElement;
      eventContext.inGridSelection = true;
      fireEvent(eventContext.startElement, 'grid-mouse-select-start', { clientX, clientY });
    }
  });

  addMouseEvent('mousemove', e => {
    if (interactionsDisabled) {
      return;
    }

    if (e.buttons === 0 || eventContext.rightClick) {
      return;
    }

    const { clientX, clientY } = e;
    const newSelectedElement = elementFromPoint(clientX, clientY);

    if (eventContext.inGridSelection) {
      fireEvent(newSelectedElement, 'grid-mouse-select-move', { clientX, clientY });
    }

    if (eventContext.lastSelectedElement !== newSelectedElement) {
      fireEvent(newSelectedElement, 'grid-mouse-select-enter', { clientX, clientY });
      eventContext.lastSelectedElement = newSelectedElement;
    }
  });

  addMouseEvent('mouseup', e => {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line no-return-assign
    setTimeout(() => (eventContext = {}));

    if (interactionsDisabled) {
      return;
    }

    if (eventContext.rightClick) {
      return;
    }

    const { clientX, clientY } = e;
    const doubleTap = checkDoubleTap('mouse', eventContext.startElement);

    if (!doubleTap) {
      fireEvent(eventContext.startElement, 'grid-mouse-select', { clientX, clientY });
    } else {
      const currentElement = elementFromPoint(clientX, clientY);
      fireEvent(currentElement, 'grid-mouse-edit', { clientX, clientY });
    }
  });

  // touch events

  startTouchEvent('touchstart', e => {
    const { touches } = e;

    const { clientX, clientY } = touches[0] || {};

    eventContext = {
      touch: true,
      startElement: elementFromPoint(clientX, clientY),
      clientX,
      clientY,
    };

    if (touches.length !== 1) {
      return;
    }

    if (interactionsDisabled) {
      return;
    }

    eventContext.selectionStartHandle = setTimeout(() => {
      if (!eventContext.scrolled) {
        eventContext.inGridSelection = true;
        fireEvent(eventContext.startElement, 'grid-touch-select-start', { clientX, clientY });
      }
    }, touchWaitThreshhold);
  });

  addTouchEvent('touchmove', e => {
    if (interactionsDisabled) {
      return;
    }

    const { touches } = e;

    if (touches.length !== 1) {
      return;
    }

    const { clientX, clientY } = touches[0];
    eventContext.clientX = clientX;
    eventContext.clientY = clientY;
    if (eventContext.inGridSelection) {
      const newSelectedElement = elementFromPoint(clientX, clientY);

      fireEvent(newSelectedElement, 'grid-touch-select-move', { clientX, clientY });

      if (eventContext.lastSelectedElement !== newSelectedElement) {
        fireEvent(newSelectedElement, 'grid-touch-select-enter', { clientX, clientY });
        eventContext.lastSelectedElement = newSelectedElement;
        e.stopPropagation();
        if (e.cancelable) {
          e.preventDefault();
        }
      }
    }
  });

  addTouchEvent('touchend', e => {
    setTimeout(() => {
      eventContext = {};
    });

    fireEvent(eventContext.startElement, 'grid-pre-touchend');

    if (interactionsDisabled) {
      return;
    }

    const { clientX, clientY } = eventContext;

    if (eventContext.inGridSelection) {
      const newSelectedElement = elementFromPoint(clientX, clientY);

      fireEvent(newSelectedElement, 'grid-touch-select', { clientX, clientY });
    } else if (!eventContext.scrolled) {
      const handle = eventContext.selectionStartHandle;
      clearTimeout(handle);
      const eventName = checkDoubleTap('touch', eventContext.startElement)
        ? 'grid-touch-edit'
        : 'grid-touch-tap';
      fireEvent(eventContext.startElement, eventName, { clientX, clientY });
    }

    e.stopPropagation();
    if (e.cancelable) {
      e.preventDefault();
    }
  });

  const registerGridEvent = (element, name, callback, ...other) => {
    const filteredCallback = e => {
      if (e.detail.dataSetGrid === dataSetGrid) {
        callback(e);
      }
    };
    element.addEventListener(name, filteredCallback, ...other);
  };

  const registerSystemEvent = (element, name, callback, ...other) => {
    const filteredCallback = e => {
      if (!interactionsDisabled) {
        callback(e);
      }
    };
    element.addEventListener(name, filteredCallback, ...other);
  };

  return {
    get interactionsDisabled() {
      return interactionsDisabled;
    },
    set interactionsDisabled(value) {
      interactionsDisabled = value;
    },

    registerGridEvent,
    registerSystemEvent,

    onScroll,
  };
};

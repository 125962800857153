import { css } from 'lit';

export default css`
  .stack > div + div {
    margin-block-start: 0;
  }

  [inset] > * {
    --inset: 0;
  }

  :host {
    width: max-content;
  }

  /*clipping path creates the triangle shape if just on container it breaks*/
  :host(:not([editing])) .container {
    border-radius: var(--vst-radius) 0 0 var(--vst-radius);
    clip-path: polygon(0% 0%, 80% 0%, 100% 50%, 80% 100%, 0% 100%);
  }

  :host([editing]) .container {
    border-radius: var(--vst-radius);
  }

  #data-mark-container-inner {
    background: var(--vst-color-bg);
    padding-inline-end: var(--vst-space-xl);
    position: relative;
    white-space: normal;
  }

  #data-mark-container-outer {
    background: var(--vst-color-bg);
    box-shadow: 0px var(--vst-color-brand-300);
    padding: 1px;
  }

  :host(:not([editing])) #data-mark-container-outer:hover {
    background: var(--vst-color-brand-300);
  }

  :host(:not([editing])) #data-mark-container-outer:focus,
  :host(:not([editing])) #data-mark-container-outer:active {
    background: var(--vst-color-brand-300);
    box-shadow: var(--vst-shadow-s);
  }

  #outside {
    flex-wrap: nowrap;
  }

  p,
  input,
  label {
    line-height: var(--vst-line-height-s);
    max-inline-size: 15ch;
  }

  #data-mark-notes-input {
    border: 1px;
    border-radius: var(--vst-radius);
    flex: 1;
    margin: var(--vst-space-xs);
    margin-top: 2px;
    padding: var(--vst-space-2xs);
  }

  #data-mark-notes-input:focus {
    --vst-outline-offset: 0px;
  }

  #edit-data-mark-btn {
    padding: 4px;
  }

  #delete-data-mark-btn {
    padding: var(--vst-space-2xs);
  }

  #delete-data-mark-btn:hover,
  #edit-data-mark-btn:hover {
    border: 1px solid var(--vst-color-brand-300);
  }

  #edit-data-mark-btn:active,
  #edit-data-mark-btn:focus {
    background: var(--vst-color-brand-300);
    border: 1px solid var(--vst-color-brand-300);
    color: #ffffff;
  }

  :host(([editing])) .container {
    border-radius: var(--vst-radius);
    clip-path: polygon(0% 0%, 80% 0%, 100% 50%, 80% 100%, 0% 100%);
  }

  :host(:not([editing])) #data-mark-container-outer {
    background: var(--vst-color-bg-primary);
    padding: 1px;
  }

  :host([editing]) #data-mark-container-outer {
    background: var(--vst-color-bg);
    border: solid 1px var(--vst-color-bg-primary);
    padding: 1px;
  }

  .inline > div {
    justify-content: var(--justify, flex-end);
  }

  .note {
    height: auto;
    width: 90%;
    word-break: break-word;
  }
`;

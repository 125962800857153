import { cloneDeep, groupBy } from 'lodash-es';
import { formatter } from '@utils/formatter.js';

const verboseLogging = false;

const GRAPH_COUNT = 3;

const getLPSColumn = (sensorWorld, columns) => {
  let column = null;

  columns.forEach(c => {
    if (c.type === 'sensor') {
      const sensor = sensorWorld.getSensorById(c.sensorId);
      if (sensor && sensor.sensorId === 'LPS') {
        column = c;
      }
    }
  });

  return column;
};

export const computeGraphsAutoLayout = (
  dataWorld,
  sensorWorld,
  collectionParams,
  connectedDevice = {},
  rightColumnIds = [],
) => {
  const settings = { ...collectionParams };

  const activeSensorIds = sensorWorld.sensors.map(sensor => sensor.id);

  const columns = dataWorld.currentDataSet
    ? dataWorld
        .getColumnsForSet(dataWorld.currentDataSet.id)
        // sensor is removed before column occasionally on sensor-removed event
        .filter(
          column =>
            column.type !== 'sensor' || activeSensorIds.includes(column.sensorId) || column.frozen,
        )
    : []; // TODO: figure out why this can be called when there is no currentDataSet

  const graphAutoLayouts = [];
  const appAutoLayout = {
    graph_1: true,
    graph_2: false,
    graph_3: false,
    table: false,
    meter: false,
  };

  const params = cloneDeep(settings.params);
  const { mode } = settings;
  const isContinuous = params && params.continuous;
  const baseColumns = columns.filter(column => column.prefersBase);
  let timeColumn = baseColumns.find(col => col.type === 'time'); // give baseColumn priority to time column.
  let baseColumn = timeColumn || baseColumns[0];

  if (!baseColumn) {
    // This currently happens with manual session documents -- we need to
    // review how the column baseness is handled/stored in the backend.
    if (verboseLogging) {
      console.warn('No baseColumn in dataset!');
    }
    [baseColumn] = columns;
  }

  // special handling for DAK: Diffraction Apparatus
  const lpsColumn = getLPSColumn(sensorWorld, columns);
  if (lpsColumn) {
    baseColumn = lpsColumn;
    timeColumn = null;
  }

  // graphing information
  const graphBaseRange = { min: 0, max: 30 }; // default range for continuous
  let baseColumnId = baseColumn ? baseColumn.id : null;

  if (mode === 'time-based' && !isContinuous) {
    graphBaseRange.max = formatter.convertTimeUnits(params.duration, 's', params.units);
  } else if (mode.match('events')) {
    graphBaseRange.max = 1; // just some kind of value that isn't 30
    appAutoLayout.table = true;
  } else if (mode.match('drop-counting')) {
    // use the base column to drive the range
    graphBaseRange.min = baseColumn ? baseColumn.range.min : 0;
    graphBaseRange.max = baseColumn ? baseColumn.range.max : 1;
    appAutoLayout.table = true;
  } else if (mode === 'photogate-timing') {
    appAutoLayout.table = true;
    if (params.subMode === 'motion') {
      graphBaseRange.min = 0;
      graphBaseRange.max = 0.25;
    } else if (['gate', 'pulse'].includes(params.subMode)) {
      graphBaseRange.min = 0;
      graphBaseRange.max = 5;
    }
  } else if (mode === 'full-spectrum') {
    const { minWavelength, maxWavelength } = connectedDevice;
    graphBaseRange.min = minWavelength;
    graphBaseRange.max = maxWavelength;
  }

  // Look for replaceDependant columns and replace dependents on graph
  columns.forEach(column => {
    if (
      column.type === 'calc' &&
      column.replaceDependent &&
      column.group.calcDependentGroups.length
    ) {
      const getGroups = groupIds => groupIds.map(dataWorld.getColumnGroupById.bind(dataWorld));
      const dependentGroups = getGroups(column.group.calcDependentGroups);

      // replace any dependent y-axis groups
      if (column.plotted) {
        dependentGroups.forEach(group => dataWorld.updateColumnGroup(group.id, { plotted: false }));
      }

      // replace the baseColumn if applicable
      if (dependentGroups.length === 1 && dependentGroups[0].id === baseColumn.groupId) {
        column.prefersBase = true;
        baseColumn = column;
        baseColumnId = column.id;
      }
    }
  });

  const connectLines = mode === 'time-based' || mode === 'full-spectrum';

  for (let i = 0; i < GRAPH_COUNT; ++i) {
    graphAutoLayouts.push({
      baseColumnId,
      leftUnits: [],
      leftColumnIds: [],
      rightUnits: [],
      rightColumnIds: [],
      options: {
        appearance: {
          lines: connectLines,
          points: !connectLines,
        },
        baseRange: graphBaseRange,
        leftRange: { min: NaN, max: NaN },
      },
    });
  }

  const _isMotionDetectorGroup = group => {
    const dependentGroup =
      group && group.calcDependentGroups.length
        ? dataWorld.getColumnGroupById(group.calcDependentGroups[0])
        : null;
    return (
      (group && group.sensorId && sensorWorld.isMotionDetector(group.sensorId)) ||
      (dependentGroup &&
        dependentGroup.sensorId &&
        sensorWorld.isMotionDetector(dependentGroup.sensorId))
    );
  };

  const _isRotationGroup = group => {
    const dependentGroup =
      group && group.calcDependentGroups.length
        ? dataWorld.getColumnGroupById(group.calcDependentGroups[0])
        : null;
    return (
      (group && group.sensorId && sensorWorld.isRotationSensor(group.sensorId)) ||
      (dependentGroup &&
        dependentGroup.sensorId &&
        sensorWorld.isRotationSensor(dependentGroup.sensorId))
    );
  };

  function setGraphRanges(graph, column) {
    if (graph.leftColumnIds.includes(column.id)) {
      if (Number.isNaN(graph.options.leftRange.min)) {
        graph.options.leftRange.min = column.range.min;
      } else {
        graph.options.leftRange.min = Math.min(graph.options.leftRange.min, column.range.min);
      }

      if (Number.isNaN(graph.options.leftRange.max)) {
        graph.options.leftRange.max = column.range.max;
      } else {
        graph.options.leftRange.max = Math.max(graph.options.leftRange.max, column.range.max);
      }
    }
  }

  function matchColumnToGraph(column) {
    const columnGroup = column.group;
    let uniqueUnits = {};
    // group sensor units and count unique units for choosing which MD calc columns we plot automatically
    if (sensorWorld.sensors.length) {
      uniqueUnits = groupBy(sensorWorld.sensors, 'units');
    }
    const unitCount = Object.keys(uniqueUnits).length;

    for (let i = 0; i < graphAutoLayouts.length; ++i) {
      const graph = graphAutoLayouts[i];
      const graphLeftUnits = graph.leftUnits;
      const graphRightUnits = graph.rightUnits;

      if (
        column.id !== baseColumnId &&
        (i === graphAutoLayouts.length - 1 ||
          !graphLeftUnits.length ||
          graphLeftUnits.includes(columnGroup.units) ||
          Object.keys(connectedDevice).length !== 0)
      ) {
        const dependentGroup =
          columnGroup && columnGroup.calcDependentGroups.length
            ? dataWorld.getColumnGroupById(columnGroup.calcDependentGroups[0])
            : null;
        const isMD = _isMotionDetectorGroup(dependentGroup);
        const isRotation = _isRotationGroup(dependentGroup);

        let velCalcCol = false;
        let accCalcCol = false;

        if (isMD) {
          velCalcCol = column.type === 'calc' && column.sensorMapId.startsWith('CC_MDV');
          accCalcCol = column.type === 'calc' && column.sensorMapId.startsWith('CC_MDA');
        } else if (isRotation) {
          velCalcCol = column.type === 'calc' && column.sensorMapId.endsWith('MV');
          accCalcCol = column.type === 'calc' && column.sensorMapId.endsWith('MA');
        }

        let addToGraph = false;

        if ((accCalcCol && unitCount === 1) || (velCalcCol && unitCount < 3)) {
          // special case for MD calc columns. We assume the order of the columns to make this work right
          addToGraph = true;
        } else if (column.plotted && !velCalcCol && !accCalcCol) {
          addToGraph = true;
        }

        if (lpsColumn && column.type === 'time') {
          addToGraph = false;
        }

        if (addToGraph) {
          if (rightColumnIds.includes(column.id)) {
            graphRightUnits.push(columnGroup.units);
            graph.rightColumnIds.push(column.id);
          } else {
            graphLeftUnits.push(columnGroup.units);
            graph.leftColumnIds.push(column.id);
          }

          graph.baseUnits = baseColumn.units;
          if (appAutoLayout.table && i === 0) {
            // if we're in a view with table visible by default, we currently only support one graph
            appAutoLayout.graph_1 = true;
          } else if (!appAutoLayout.table) {
            appAutoLayout[`graph_${i + 1}`] = column.plotted;
          }

          setGraphRanges(graph, column);
        }

        break;
      }
    }

    // Special handling for VPG timing submodes.
    const { mode, params: { subMode = '', params: subParams = {} } = {} } = collectionParams;
    const { gateSeparation = 0 } = subParams;

    if (mode === 'photogate-timing' && subMode === 'pulse') {
      // We need to look for a specific column based on gateSeparation value.
      let replacementKey = null;
      if (gateSeparation > 0) replacementKey = 'CC_VPG_VELOCITY';
      else if (gateSeparation === -1) replacementKey = 'CC_VPG_PULSE';

      if (replacementKey) {
        // Look for a column whose sensorMapId matches the key:
        const replacementColumn = dataWorld
          .getColumns()
          .find(col => col.sensorMapId.startsWith(replacementKey));
        if (replacementColumn) {
          // Swap out graph 1 and 2 left column ids with the correct column.
          const graph1Cols = graphAutoLayouts[0].leftColumnIds;
          if (graphAutoLayouts.length >= 2) graphAutoLayouts[1].leftColumnIds = graph1Cols;
          graphAutoLayouts[0].leftColumnIds = [replacementColumn.id];
        }
      }
    }
  }

  columns
    .sort((colA, colB) => {
      const a = _isMotionDetectorGroup(colA.group) || _isRotationGroup(colA.group);
      const b = _isMotionDetectorGroup(colB.group) || _isRotationGroup(colB.group);

      if (a === b) {
        return 0;
      }

      return a ? 1 : -1;
    })
    .forEach(column => {
      matchColumnToGraph(column);
    });

  const searchParams = new URLSearchParams(window.location.search);
  const searchOverrides = {};

  if (searchParams.has('graph_3')) {
    searchOverrides.graph_3 = searchParams.get('graph_3') === 'true';
  }
  if (searchOverrides.graph_3 || searchParams.has('graph_2')) {
    searchOverrides.graph_2 = !!(searchParams.get('graph_2') === 'true' || searchOverrides.graph_3);
    if (!searchOverrides.graph_2) searchOverrides.graph_3 = false;
  }
  if (searchOverrides.graph_3 || searchOverrides.graph_2 || searchParams.has('graph_1')) {
    searchOverrides.graph_1 = !!(
      searchParams.get('graph_1') !== 'false' ||
      searchOverrides.graph_2 ||
      searchOverrides.graph_3
    );
    if (!searchOverrides.graph_1) {
      searchOverrides.graph_2 = false;
      searchOverrides.graph_3 = false;
    }
  }

  if (searchParams.has('table')) searchOverrides.table = searchParams.get('table') === 'true';
  if (searchParams.has('meter')) searchOverrides.meter = searchParams.get('meter') === 'true';

  if (searchParams.has('video')) searchOverrides.video = searchParams.get('video') === 'true';
  if (searchParams.has('notes')) searchOverrides.notes = searchParams.get('notes') === 'true';
  if (searchParams.has('configurator'))
    searchOverrides.configurator = searchParams.get('configurator') === 'true';

  return {
    graphAutoLayouts,
    appAutoLayout: { ...appAutoLayout, ...searchOverrides },
  };
};

/**
 * returns a single object array for manual mode graph setup
 * @name computeManualEntryLayout
 * @returns {array}
 */
export const computeManualEntryLayout = dataWorld => {
  const baseColumn = dataWorld.getColumns().find(c => c.prefersBase);
  const baseColumnId = baseColumn ? baseColumn.id : null;
  const leftColumns = dataWorld.getColumns().filter(c => !c.prefersBase && c.id !== baseColumnId);
  const leftColumnIds = leftColumns.map(c => c.id);

  return [
    {
      baseColumnId,
      leftColumnIds,
      rightColumnIds: [],
      options: {
        appearance: {
          lines: false,
          points: true,
        },
        baseRange: { min: 0, max: 1 },
        leftRange: { min: 0, max: 1 },
      },
    },
  ];
};

import { priv } from '../priv.js';

export const createPrepareGridCell =
  ({ interactionContext }) =>
  ({ cellEl }) => {
    const cellState = {
      rowIndex: null,
      column: null,
      selectAll: false,
      isReverting: false,
      isEditing: false,
      isDeepEdit: false,
      isSelected: false,
      isFocused: false,
      readonly: null,
      tapRowIndex: null,
      cellText: null,
      checkToExtend: true,
    };

    const { events } = interactionContext;

    const isAndroid = navigator.userAgent.match(/Android/i);
    const isIOS = navigator.userAgent.match(/iPhone/i);

    const span = document.createElement('span');
    const input = document.createElement('input');
    const isMobile = isAndroid || isIOS; // TODO: possibly try to determine `isSoftKeyboard` rather than platform type

    const defaultInputProperties = {
      type: 'text',
      autocomplete: 'off',
      autocorrect: 'off',
      autocapitalize: 'off',
      spellcheck: false,
    };

    input.classList.add('grid_text');

    Object.assign(input, defaultInputProperties);

    const noop = () => {};

    let showInput;
    let showInputOnce;
    let hideInput;
    let hideInputOnce;

    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line prefer-const
    showInputOnce = () => {
      input.style.display = 'inline';
      cellEl.classList.add('shows_text');
      hideInput = hideInputOnce;
      showInput = noop;
    };

    hideInputOnce = () => {
      input.style.display = 'none';
      cellEl.classList.remove('shows_text');
      showInput = showInputOnce;
      hideInput = noop;
    };

    showInput = showInputOnce;
    hideInput = hideInputOnce;

    let showSpan;
    let showSpanOnce;
    let hideSpan;
    let hideSpanOnce;

    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line prefer-const
    showSpanOnce = () => {
      span.style.display = 'inline';
      showSpan = noop;
      hideSpan = hideSpanOnce;
    };

    hideSpanOnce = () => {
      span.style.display = 'none';
      hideSpan = noop;
      showSpan = showSpanOnce;
    };

    hideSpan = hideSpanOnce;
    showSpan = showSpanOnce;

    hideSpan();
    hideInput();

    cellEl.appendChild(span);
    cellEl.appendChild(input);

    const leftKey = 37;
    const upKey = 38;
    const rightKey = 39;
    const downKey = 40;

    const tabKey = 9;
    const enterKey = 13;
    const escapeKey = 27;
    const deleteKey = 46;
    const backspaceKey = 8;

    const isTextChar = ({ key }) => {
      if (key.length !== 1) {
        return false;
      }

      const code = key.charCodeAt(0);

      return code >= 32 && code <= 126;
    };

    events.registerSystemEvent(cellEl, 'keydown', e => {
      const keyPressed = {};

      if (e.keyCode === tabKey) {
        keyPressed.tabKey = true;
      } else if (e.keyCode === enterKey) {
        keyPressed.enterKey = true;
      } else if (e.which === upKey) {
        keyPressed.upKey = true;
      } else if (e.which === downKey) {
        keyPressed.downKey = true;
      } else if (e.which === leftKey) {
        keyPressed.leftKey = true;
      } else if (e.keyCode === rightKey) {
        keyPressed.rightKey = true;
      } else if (e.keyCode === escapeKey) {
        keyPressed.escapeKey = true;
      } else if (e.keyCode === deleteKey || e.keyCode === backspaceKey) {
        keyPressed.deleteKey = true;
      } else if (isTextChar(e)) {
        keyPressed.inputCharacter = true;
        keyPressed.char = e.key;
      } else {
        keyPressed.unknown = true;
      }

      keyPressed.shiftKey = e.shiftKey;
      keyPressed.actionKey = e.ctrlKey || e.metaKey || e.altKey;

      if (keyPressed.escapeKey) {
        e.preventDefault();
        cellState.isReverting = true;
        interactionContext.startCellSelection(cellState.column, cellState.rowIndex);
      } else if (
        cellState.isDeepEdit &&
        (keyPressed.leftKey || keyPressed.rightKey || keyPressed.upKey || keyPressed.downKey)
      ) {
        e.stopPropagation();
      } else if (!cellState.isDeepEdit && keyPressed.actionKey && keyPressed.enterKey) {
        e.stopPropagation();
        e.preventDefault();
        interactionContext.deepEditCell(cellState.column, cellState.rowIndex);
      } else if (keyPressed.escapeKey) {
        e.preventDefault();
        if (cellState.isFocused && cellState.isEditing) {
          cellState.isReverting = true;
          interactionContext.startCellSelection(cellState.column, cellState.rowIndex);
        }
      } else if (keyPressed.inputCharacter) {
        if (cellState.isEditing && keyPressed.char === 'a' && keyPressed.actionKey) {
          e.preventDefault();
          e.stopPropagation();
          cellState.selectAll = true;
          interactionContext.editCell(cellState.column, cellState.rowIndex, true);
        } else if (
          cellState.isFocused &&
          !cellState.isEditing &&
          !keyPressed.actionKey &&
          (!interactionContext.hasSelection ||
            interactionContext.singleCellSelected(cellState.column, cellState.rowIndex))
        ) {
          e.preventDefault();
          cellState.replaceEdit = { value: keyPressed.char };
          interactionContext.editCell(cellState.column, cellState.rowIndex, true);
        }
      } else if (keyPressed.deleteKey) {
        if (cellState.isEditing) {
          e.stopPropagation();
        }
      } else if (cellState.isFocused && !keyPressed.unknown) {
        e.preventDefault();

        let index = cellState.rowIndex;
        let { column } = cellState;

        const dataSetGrid = column.dataSet.grid;
        const { dataSets } = dataSetGrid[priv];
        const { dataSetLength } = dataSetGrid;

        let value =
          keyPressed.downKey ||
          (keyPressed.tabKey &&
            !keyPressed.shiftKey &&
            column.gridPosition === dataSetGrid.colspan - 1)
            ? null
            : input.value;

        // if we're editing a manual column within a dataset that isn't the last dataset, we still want to create a new row if we hit the bottom cell
        if (keyPressed.enterKey && !keyPressed.shiftKey && !cellState.readonly) {
          value = null;
        }

        interactionContext.checkToExtend(index, value);

        const getNextCell = (column, index) => {
          const { totalRows } = dataSetGrid;
          const columnPosition = column.position;
          const { dataSet } = column;
          const { columns } = dataSet[priv];
          const dataSetColspan = dataSet.colspan;
          const dataSetPosition = dataSet.position;

          let nextCell = null;

          const setNext = (col, rIndex) => {
            nextCell = {
              column: col,
              rowIndex: rIndex,
            };
          };

          if (keyPressed.upKey) {
            const nextIndex = index - 1;
            if (nextIndex >= 0) {
              setNext(column, nextIndex);
            }
          } else if (keyPressed.downKey) {
            const nextIndex = index + 1;
            if (nextIndex !== totalRows) {
              setNext(column, nextIndex);
            }
          } else if ((!keyPressed.shiftKey && !keyPressed.leftKey) || keyPressed.rightKey) {
            if (columnPosition === dataSetColspan - 1) {
              if (keyPressed.enterKey) {
                const nextIndex = index + 1;
                const column = columns.findByIndex(0);

                if (nextIndex !== totalRows) {
                  setNext(column, nextIndex);
                }
              } else if (keyPressed.tabKey || keyPressed.rightKey) {
                if (dataSetPosition === dataSetLength - 1) {
                  if (keyPressed.tabKey) {
                    const nextIndex = index + 1;
                    const column = dataSets.findByIndex(0)[priv].columns.findByIndex(0);
                    if (nextIndex !== totalRows) {
                      setNext(column, nextIndex);
                    }
                  }
                } else {
                  const column = dataSets
                    .findByIndex(dataSetPosition + 1)
                    [priv].columns.findByIndex(0);
                  setNext(column, index);
                }
              }
            } else {
              const column = columns.findByIndex(columnPosition + 1);
              setNext(column, index);
            }
          } else if (columnPosition === 0) {
            if (keyPressed.enterKey) {
              const nextIndex = index - 1;
              if (nextIndex !== -1) {
                const lastColumn = columns.findByIndex(dataSet.colspan - 1);
                setNext(lastColumn, nextIndex);
              }
            } else if (keyPressed.tabKey || keyPressed.leftKey) {
              if (dataSetPosition === 0) {
                if (keyPressed.tabKey) {
                  const nextIndex = index - 1;
                  if (nextIndex !== -1) {
                    const lastDataSet = dataSets.findByIndex(dataSetLength - 1);
                    const lastColumn = lastDataSet[priv].columns.findByIndex(
                      lastDataSet.colspan - 1,
                    );
                    setNext(lastColumn, nextIndex);
                  }
                }
              } else {
                const lastDataSet = dataSets.findByIndex(dataSetPosition - 1);
                const lastColumn = lastDataSet[priv].columns.findByIndex(lastDataSet.colspan - 1);
                setNext(lastColumn, index);
              }
            }
          } else {
            const column = columns.findByIndex(columnPosition - 1);
            setNext(column, index);
          }
          return nextCell;
        };

        let nextCell = getNextCell(column, index);

        const hasManualColumn = cellState.column.dataSet.columns.find(c => !c.readonly);

        if (hasManualColumn && keyPressed.enterKey) {
          do {
            nextCell = getNextCell(column, index);
            if (nextCell) {
              column = nextCell.column;
              index = nextCell.rowIndex;
            }
          } while (nextCell && nextCell.column.readonly);
        }

        if (nextCell) {
          if (keyPressed.enterKey || keyPressed.tabKey) {
            cellState.checkToExtend = false;
            interactionContext.clear();
            if (nextCell.column && nextCell.column.readonly) {
              interactionContext.uiStartCellSelection(nextCell.column, nextCell.rowIndex);
            } else if (isMobile) {
              interactionContext.deepEditCell(nextCell.column, nextCell.rowIndex);
            } else {
              interactionContext.startCellSelection(nextCell.column, nextCell.rowIndex);
            }
          } else if (
            keyPressed.shiftKey &&
            interactionContext.activeCellSelection(cellState.column, cellState.rowIndex)
          ) {
            cellState.checkToExtend = false;
            interactionContext.uiExpandCellSelection(nextCell.column, nextCell.rowIndex);
          } else {
            interactionContext.startCellSelection(nextCell.column, nextCell.rowIndex);
          }
        } else if (cellState.isEditing) {
          interactionContext.startCellSelection(cellState.column, cellState.rowIndex);
        }
      }
    });

    events.registerGridEvent(
      cellEl,
      'grid-touch-tap',
      e => {
        const dataSetGrid = cellState.column.dataSet.grid;
        const { grid } = dataSetGrid[priv];

        if (cellState.isSelected) {
          if (
            interactionContext.toggledTouchContextMenu ||
            !interactionContext.selectContextMenuOpen
          ) {
            interactionContext.clear();
          } else {
            interactionContext.closeSelectContextMenu();
          }
        } else if (grid.isDeepEditing) {
          interactionContext.deepEditCell(cellState.column, cellState.rowIndex);
        } else {
          interactionContext.uiStartCellSelection(cellState.column, cellState.rowIndex);
        }

        cellState.tapRowIndex = cellState.rowIndex;
        e.stopPropagation();
      },
      false,
    );

    events.registerGridEvent(cellEl, 'grid-touch-edit', e => {
      if (!cellState.isEditing && cellState.tapRowIndex === cellState.rowIndex) {
        interactionContext.deepEditCell(cellState.column, cellState.rowIndex);
      }
      e.stopPropagation();
    });

    events.registerGridEvent(cellEl, 'grid-touch-select-start', () => {
      if (!cellState.isSelected) {
        interactionContext.uiStartCellSelection(cellState.column, cellState.rowIndex);
      } else {
        interactionContext.uiSetCellReselect(cellEl, cellState.column, cellState.rowIndex);
      }
    });

    events.registerGridEvent(cellEl, 'grid-touch-select-move', () => {
      interactionContext.uiClearCellReselect();
    });

    events.registerGridEvent(cellEl, 'grid-touch-select-enter', () => {
      interactionContext.uiExpandCellSelection(cellState.column, cellState.rowIndex);
    });

    events.registerGridEvent(cellEl, 'grid-mouse-context-menu', e => {
      if (!cellState.isSelected) {
        interactionContext.uiStartCellSelection(cellState.column, cellState.rowIndex);
      }

      const { clientX, clientY } = e.detail;
      interactionContext.toggleSelectContextMenu({ clientX, clientY, isTouch: false, cellEl });
      e.stopPropagation();
    });

    events.registerGridEvent(cellEl, 'grid-mouse-edit', e => {
      if (!cellState.isEditing && cellState.tapRowIndex === cellState.rowIndex) {
        interactionContext.deepEditCell(cellState.column, cellState.rowIndex);
      }
      e.stopPropagation();
    });

    events.registerGridEvent(cellEl, 'grid-mouse-select-start', e => {
      cellState.tapRowIndex = cellState.rowIndex;
      if (interactionContext.shiftSelected && interactionContext.cellsSelected) {
        interactionContext.uiExpandCellSelection(cellState.column, cellState.rowIndex);
        interactionContext.scrollDisabled = true;
      } else if (!interactionContext.activeCellSelection(cellState.column, cellState.rowIndex)) {
        interactionContext.uiStartCellSelection(cellState.column, cellState.rowIndex);
        interactionContext.scrollDisabled = true;
      } else {
        interactionContext.clear();
      }
      e.stopPropagation();
    });

    events.registerGridEvent(cellEl, 'grid-mouse-select-enter', e => {
      interactionContext.uiExpandCellSelection(cellState.column, cellState.rowIndex);
      e.stopPropagation();
    });

    const assignText = value => {
      input.value = value;
    };

    const assignSpan = value => {
      span.innerText = null;
      if (!value) {
        span.innerText = '';
      } else {
        span.innerText = value;
      }
    };

    const emptyValue = {};

    return (column, rowIndex) => {
      const init = column !== cellState.column || cellState.rowIndex !== rowIndex;
      cellState.rowIndex = rowIndex;
      cellState.column = column;

      if (init) {
        cellState.isEditing = null;
        cellState.isSelected = null;
        cellState.cellText = emptyValue;
        cellState.readonly = column.readonly;
      }

      const currentData = column[priv].getCellData(rowIndex);

      if (column.readonly) {
        cellEl.classList.add('readonly');
      } else {
        cellEl.classList.remove('readonly');
      }

      if (currentData.isDeepEdit) {
        input.classList.add('deepedit');
      } else {
        input.classList.remove('deepedit');
      }

      if (column?.context.isRowStruck && column.context.isRowStruck(rowIndex)) {
        cellEl.classList.add('grid_body_cell_strikethrough');
      } else {
        cellEl.classList.remove('grid_body_cell_strikethrough');
      }

      cellEl.tabIndex = undefined;
      if (currentData.isSelected) {
        cellEl.classList.add('selected');

        if (currentData.isFocused) {
          cellEl.tabIndex = 1;
          cellEl.focus();
        }
      } else {
        cellEl.classList.remove('selected');
      }

      let cellText;
      if (currentData.isEditing !== cellState.isEditing) {
        if (currentData.isEditing) {
          cellText = !cellState.replaceEdit ? currentData.getText() : cellState.replaceEdit.value;
          hideSpan();
          assignText(cellText);
          showInput();

          const textFocus = () => {
            if (!cellState.replaceEdit) {
              // For iPhone X's and other devices with weird screen geometries, for some reason we are not always
              // able to re-select cells for editing after the keyboard is dismissed. Through relentless experimentation
              // I found that by jiggering the focus of the current element, and then focusing the new input, that it
              // not only brings up the keyboard, but it then correctly scrolls the input into view.
              // GA4-2871 JK 20190607.
              if (window.vstMobileScreenGeometry === 'notched') {
                if (document.activeElement !== input) {
                  document.activeElement.blur();
                  document.activeElement.focus();
                }
                input.focus();
              } else {
                input.select();
              }
            } else {
              input.focus();
            }
          };

          if (isAndroid) {
            let hiddenInput = document.querySelector('#vst_grid_keyboard_input');

            if (!hiddenInput) {
              hiddenInput = document.createElement('input');
              Object.assign(hiddenInput, defaultInputProperties);

              hiddenInput.id = 'vst_grid_keyboard_input';
              hiddenInput.style.left = '-99999em';
              hiddenInput.style.position = 'absolute';

              document.body.appendChild(hiddenInput);
            }

            hiddenInput.focus();

            setTimeout(() => {
              textFocus();
              // TODO: we should probably animate this scroll so it's not confusing to the user
              const dataSetGrid = cellState.column.dataSet.grid;
              dataSetGrid.scrollToRow(cellState.rowIndex - 1);
            });
          } else {
            textFocus();
          }
        } else {
          if (!init && !cellState.isReverting && currentData.setValue(input.value)) {
            if (cellState.checkToExtend) {
              interactionContext.checkToExtend(rowIndex);
            }
            interactionContext.notifyUIChange([{ column, rowIndex }]);
          }

          cellText = currentData.getText();
          hideInput();

          assignSpan(cellText); // assigning what goes into the span
          showSpan();
        }
      } else {
        cellText = currentData.getText();
        if (cellState.selectAll) {
          input.select();
          input.focus();
        } else if (!cellState.replaceEdit && cellText !== cellState.cellText) {
          if (currentData.isEditing) {
            assignText(cellText);
          } else {
            assignSpan(cellText);
          }
        }
      }

      cellState.checkToExtend = true;
      cellState.isEditing = currentData.isEditing;
      cellState.isDeepEdit = currentData.isDeepEdit;
      cellState.isSelected = currentData.isSelected;
      cellState.isFocused = currentData.isFocused;
      cellState.isReverting = false;
      cellState.replaceEdit = !cellState.isEditing ? null : cellState.replaceEdit;
      cellState.selectAll = false;
      cellState.cellText = cellText;
    };
  };

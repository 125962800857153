import { serviceWorkerInitializer } from '@common/utils/serviceWorker/ServiceWorkerInitializer.js';

import '@common/libs/array.prototype.at.js';
// Core components
import '@components/vst-core-accessibility/vst-core-accessibility.js';
import '@components/vst-core-autoupdate/vst-core-autoupdate.js';
import '@components/vst-core-calc-functions/vst-core-calc-functions.js';
import '@components/vst-core-clipboard/vst-core-clipboard.js';
import '@components/vst-core-column-options/vst-core-column-options.js';
import '@components/vst-core-content-layout/vst-core-content-layout.js';
import '@components/vst-core-curvefit-selector/vst-core-curvefit-selector.js';
import '@components/vst-core-download-data/vst-core-download-data.js';
import '@components/vst-core-device-info/vst-core-device-info.js';
// import '@components/vst-core-ewe-entry/vst-core-ewe-entry.js';
import '@components/vst-core-device-manager/vst-core-device-manager.js';
import '@components/vst-core-feature-flags/vst-core-feature-flags.js';
import '@components/vst-core-graph-analysis/vst-core-graph-analysis.js';
import '@components/vst-core-graph-group/vst-core-graph-group.js';
import '@components/vst-core-graph-legend/vst-core-graph-legend.js';
import '@components/vst-core-graph-options/vst-core-graph-options.js';
import '@components/vst-core-graph-plot-manager/vst-core-graph-plot-manager.js';
import '@components/vst-core-graph-predictions/vst-core-graph-predictions.js';
import '@components/vst-core-graph-selection/vst-core-graph-selection.js';
import '@components/vst-core-graph/vst-core-graph.js';
import '@components/vst-core-infobox/vst-core-infobox.js';
import '@components/vst-core-message-box/vst-core-message-box.js';
import '@components/vst-core-meter-container/vst-core-meter-container.js';
import '@components/vst-ui-meter/vst-ui-meter.js';
import '@components/vst-core-popover/vst-core-popover.js';
import '@components/vst-core-rename/vst-core-rename.js';
import '@components/vst-core-table/vst-core-table.js';

// UI components
import '@components/vst-ui-changelog/vst-ui-changelog.js';
import '@components/vst-ui-chromebar/vst-ui-chromebar.js';
import '@components/vst-ui-connection-status/vst-ui-connection-status.js';
import '@components/vst-ui-connected-device-list/vst-ui-connected-device-list.js';
import '@components/vst-ui-draggable/vst-ui-draggable.js';
import '@components/vst-ui-form/vst-ui-form.js';
import '@components/vst-ui-graph-annotation/vst-ui-graph-annotation.js';
import '@components/vst-ui-graph-selection/vst-ui-graph-selection.js';
import '@components/vst-ui-icon/vst-ui-icon.js';
import '@components/vst-ui-listbox/vst-ui-listbox.js';
import '@components/vst-ui-logo-vernier/vst-ui-logo-vernier.js';
import '@components/vst-ui-prompt/vst-ui-prompt.js';
import '@components/vst-ui-range/vst-ui-range.js';
import '@components/vst-ui-spinner/vst-ui-spinner.js';
import '@components/vst-ui-splash-screen/vst-ui-splash-screen.js';
import '@components/vst-ui-switch/vst-ui-switch.js';
import '@components/vst-ui-toast/vst-ui-toast.js';
import '@components/vst-ui-toolbar/vst-ui-toolbar.js';
import '@components/vst-ui-tooltip/vst-ui-tooltip-wrapper.js';
import '@components/vst-ui-tooltip/vst-ui-tooltip.js';
import '@components/vst-ui-wait/vst-ui-wait.js';

// App component
import './sa-app.js';

serviceWorkerInitializer.init();

if (!PRODUCTION && !('toJs' in window)) {
  (async () => {
    const mobx = await import('mobx');
    window.toJS = mobx.toJS;
  })();
}

import { priv } from '../priv.js';

export const renderRemoveDataSet = ({ dataSet }) => {
  const dataSetPosition = dataSet.position;
  const { grid } = dataSet.grid[priv];

  grid.applyChanges(context => {
    context.getHeader(dataSetPosition).remove();
  });
};

import { css } from 'lit';

export const createDataSetGridStyles = css`
  /* CSS custom properties for theming */
  /* --vst-grid-border-color */
  /* --vst-grid-selected-background-color */
  /* --vst-grid-selected-border-color */
  /* --vst-grid-readonly-selected-background-color */
  /* --vst-grid-header-background-color */
  /* --vst-grid-header-font-family */
  /* --vst-grid-header-text-color */
  /* --vst-grid-body-background-color */
  /* --vst-grid-body-font-family */
  /* --vst-grid-body-text-color */
  /* CSS custom properties which are set by javascript when the grid is instiantiated. Overriding them is foolish. */
  /* --vst-grid-cell-width */
  /* must be duplicated in javascript TODO: Make these custom properties so we don't need the JS duplication. */
  :host {
    --vst-grid-row-height: 2rem;
    --vst-grid-border-size: 0.0625rem;
    --vst-grid-sidebar-width: 3.75rem;
    --vst-grid-scroller-size: 0.9375rem;
    --vst-grid-padding: 0.3125rem;
    --vst-grid-cell-width: 6.25rem;
    --vst-grid-colspan-test: 2;
    /* others */
    /* --vst-grid-border-color: #888; */
    /* --vst-grid-selected-background-color: #ccccff;
    --vst-grid-readonly-selected-background-color: #eeeeff; */
    --vst-grid-header-background-color: #bbb;
    --vst-grid-header-text-color: #000;
    --vst-grid-header-font-family: sans-serif;
    /* --vst-grid-body-background-color: var(--vst-color-bg); */
    --vst-grid-body-text-color: #000;
    --vst-grid-body-font-family: monospace;
    --vst-grid-total-height: 100%;
    --vst-grid-total-width: 100%;
  }

  *,
  *:before,
  *:after {
    box-sizing: border-box;
  }

  input[type='number'] {
    -moz-appearance: textfield;
  }

  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  .grid_container {
    -webkit-user-select: none;
    user-select: none;
    position: relative;
    display: flex;
    height: var(--vst-grid-total-height);
    width: var(--vst-grid-total-width);
    color: var(--vst-grid-body-text-color);
    font-size: var(--vst-font-size-xs);
    font-variant-numeric: tabular-nums;
    outline: none;
  }

  .grid_header {
    position: absolute;
    font-family: var(--vst-grid-header-font-family);
  }

  .grid_header_sidebar {
    position: absolute;
  }

  .grid_header_sidebar_block {
    border-style: solid;
    border-color: var(--vst-grid-border-color);
    border-width: var(--vst-grid-border-size) var(--vst-grid-border-size)
      var(--vst-grid-border-size) var(--vst-grid-border-size);
    background-color: var(--vst-grid-header-background-color);
    overflow: hidden;
  }

  .grid_header_viewport_container {
    overflow: hidden;
    position: absolute;
  }

  .grid_header_viewport {
    overflow-x: auto;
    overflow-y: hidden;
    position: absolute;
    border-style: solid;
    border-color: var(--vst-grid-border-color);
    border-width: 0 0 var(--vst-grid-border-size) 0;
    -webkit-overflow-scrolling: touch;
  }

  .grid_header_row {
    /* font-weight: bold; */
    display: flex;
    align-items: center;
  }

  .grid_header_row:nth-last-child(1) .grid_header_cell {
    border-width: var(--vst-grid-border-size) var(--vst-grid-border-size)
      var(--vst-grid-border-size) 0 !important;
  }

  .grid_header_cell {
    display: flex;
    align-items: center;
    border-width: var(--vst-grid-border-size) var(--vst-grid-border-size) 0 0;
    height: var(--vst-grid-row-height);
    border-style: solid;
    border-color: var(--vst-grid-border-color);
    padding: var(--vst-grid-padding);
    background-color: var(--vst-grid-header-background-color);
    color: var(--vst-grid-header-text-color);
    min-width: var(--vst-grid-cell-width, var(--vst-grid-cell-width));
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 1.35;
    font-size: var(--vst-font-size-xs);
  }

  .grid_header_cell.data_set_header {
    min-width: calc(
      var(--vst-grid-cell-width) * --vst-grid-colspan-test +
        ((var(--vst-grid-padding) * 2) + var(--vst-grid-border-size)) *
        (var(--vst-grid-colspan-test) - 1)
    );
  }

  .grid_body_viewport {
    height: 100%;
    max-width: 100%;
    overflow: auto;
    position: absolute;
    -webkit-overflow-scrolling: touch;
    overflow-x: hidden;
  }

  .grid_body_sidebar {
    min-width: var(--vst-grid-sidebar-width);
    position: absolute;
  }

  .grid_body_sidebar_cell {
    display: table-cell;
    width: var(--vst-grid-sidebar-width);
    min-width: var(--vst-grid-sidebar-width);
    max-width: var(--vst-grid-sidebar-width);
    height: var(--vst-grid-row-height);
    max-height: var(--vst-grid-row-height);
    border-style: solid;
    border-color: var(--vst-grid-border-color);
    border-width: 0 var(--vst-grid-border-size) var(--vst-grid-border-size)
      var(--vst-grid-border-size);
    color: var(--vst-grid-header-text-color);
    font-family: var(--vst-grid-header-font-family);
    font-size: var(--vst-font-size-xs);
    background-color: var(--vst-grid-header-background-color);
    padding: var(--vst-grid-padding);
    vertical-align: middle;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-align: center;
  }

  .grid_dataset_header_cell_title,
  .grid_header_cell_title > span,
  .grid_body_sidebar_cell > span,
  .grid_body_cell > span {
    -webkit-user-select: none;
    user-select: none;
    cursor: pointer;
  }

  .grid_body_container {
    position: relative;
    overflow: hidden;
  }

  .grid_body {
    overflow-x: auto;
    overflow-y: hidden;
    -webkit-overflow-scrolling: touch;
    position: absolute;
    padding-bottom: 0.9375rem;
    font-family: var(--vst-grid-body-font-family);
  }

  .grid_body::-webkit-scrollbar {
    /* webkit/blink specific hack to hide the extra scrollbar. Will need to address firefox/ie if we go to browser */
    display: none;
  }

  .grid_body_cell {
    border-width: 0 var(--vst-grid-border-size) var(--vst-grid-border-size) 0;
    block-size: var(--vst-grid-row-height);
    display: table-cell;
    border-style: solid;
    border-color: var(--vst-grid-border-color);
    padding: var(--vst-space-2xs) var(--vst-space-xs);
    position: relative;
    background-color: var(--vst-grid-body-background-color);
    min-inline-size: var(--vst-grid-cell-width, var(--vst-grid-cell-width));
    max-inline-size: var(--vst-grid-cell-width, var(--vst-grid-cell-width));
    text-align: end;
    vertical-align: middle;
    overflow: hidden;
    text-overflow: ellipsis;
    outline: none;
    cursor: pointer;
  }

  .grid_body_cell:focus {
    --vst-outline: 0 0 0 0.125rem var(--vst-color-brand) inset;
  }

  .grid_body_cell > input {
    inline-size: 100%;
    text-align: end;
    font-size: var(--vst-font-size-xs);
    background: transparent;
    color: var(--vst-color-fg-primary);
  }

  .grid_text {
    --padding: var(--vst-space-2xs) var(--vst-space-xs);
    max-inline-size: calc(var(--vst-grid-cell-width, var(--vst-grid-cell-width)) - 1px);
    --border: 0;
    --border-radius: 0;
    margin: 0;
    block-size: var(--vst-grid-row-height);
  }

  .grid_text.deepedit {
    font-weight: 600;
    --border: 2px solid var(--vst-color-brand);
    --font-size: var(--vst-font-size-xs);
  }

  .grid_body_cell.selected,
  .grid_body_cell.readonly.selected {
    background-color: var(--vst-grid-selected-background-color);
  }

  .grid_body_cell.shows_text {
    padding: 0;
  }

  /* styling for cells that have been struck-through */
  .grid_body_cell_strikethrough:after {
    content: '';
    border-top: 1px solid var(--vst-color-fg-tertiary);
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
  }
`;

/**
 * Calculate the angle between two Cartesian points
 *
 * @param {number} x1
 * @param {number} y1
 * @param {number} x2
 * @param {number} y2
 *
 * @return {number} Angle
 */
export const theta = (x1, y1, x2, y2) => {
  const xDistance = x2 - x1;
  const yDistance = y2 - y1;
  let theta = Math.atan2(yDistance, xDistance);
  theta *= 180 / Math.PI;
  return theta;
};

/**
 * Calculate distance between two Cartesian points
 *
 * @param {number} x1
 * @param {number} y1
 * @param {number} x2
 * @param {number} y2
 *
 * @return {number} Distance
 */
export const distance = (x1, y1, x2, y2) => Math.sqrt((x2 - x1) ** 2 + (y2 - y1) ** 2);

/**
 * Calculate midpoint between two Cartesian points
 *
 * @param {number} x1
 * @param {number} y1
 * @param {number} x2
 * @param {number} y2
 *
 * @return {{ x: number, y: number }} Hash with x and y values
 */
export const midpoint = (x1, y1, x2, y2) => ({ x: (x1 + x2) / 2, y: (y1 + y2) / 2 });

export function getTraceColor(column, coreGraphEl, dataWorld, getNextTraceColor) {
  const columnGroup = column.group;
  const columnColor = columnGroup.color;
  const dependentGroups = columnGroup.calcDependentGroups;
  const leftColumnGroups = [
    ...new Set(coreGraphEl.leftColumnIds.map(id => dataWorld.getColumnById(id).group)),
  ];

  const leftColumns = coreGraphEl.getLeftColumnIds().map(id => dataWorld.getColumnById(id));

  if (column.color) {
    const currentColor = column.color;
    const colorAlreadyPlotted = leftColumns.find(col => col.color === currentColor);

    return colorAlreadyPlotted ? getNextTraceColor(currentColor) : currentColor;
  }

  const isGroupDependent = leftColumnGroups.find(group =>
    group.calcDependentGroups.includes(columnGroup.id),
  );

  let newColor;

  if (columnColor) {
    newColor = columnColor;
  } else if (dependentGroups.length > 0) {
    for (let i = 0; i < dependentGroups.length; ++i) {
      const dependentColGroup = dataWorld.getColumnGroupById(dependentGroups[i]);
      if (dependentColGroup.calcDependentGroups.length === 0) {
        const dependentColumnColor = dependentColGroup.color;

        if (dependentColumnColor) {
          newColor = dependentColumnColor;
          break;
        } else {
          const currentDependentCol = dependentColGroup.columns.find(
            col => col.setId === column.setId,
          );
          const isCurrentDependentColPlotted =
            currentDependentCol && coreGraphEl.getTrace(currentDependentCol.id);

          const plottedColumnWithSameDependent = leftColumns.find(col =>
            col.group.calcDependentGroups.find(id =>
              dataWorld
                .getColumnGroupById(id)
                .columns.find(col => col.id === currentDependentCol.id),
            ),
          );
          const isColumnWithSameDepententPlotted =
            plottedColumnWithSameDependent && plottedColumnWithSameDependent.id !== column.id;

          // make sure we don't have this dependant column plotted which would cause a color collision
          if (!isCurrentDependentColPlotted && !isColumnWithSameDepententPlotted) {
            newColor = currentDependentCol.color || getNextTraceColor(column.color);
          }
          break;
        }
      }
    }

    newColor = newColor || getNextTraceColor(column.color);
  } else if (isGroupDependent) {
    // if this column group isn't dependant on another group, but any currently plotted
    // column groups are dependent on IT give it a unique color to avoid collisions
    newColor = getNextTraceColor(column.color);
  } else {
    newColor = getNextTraceColor(column.color);
  }

  return newColor;
}

export const createColumnValueCollection = ({ cellResolver, column }) => {
  let values = [];
  const stringValues = [];

  const refresh = onUpdate => {
    for (let i = 0; i < values.length; i++) {
      const value = values[i];
      stringValues[i] = cellResolver.valueToText(value, column, false);
      if (onUpdate) {
        onUpdate(i);
      }
    }
  };

  const matches = (index, value) => values[index] === value;

  const trimArray = array => {
    const currentLength = array.length;
    let newLength = currentLength;

    for (let i = currentLength - 1; i >= 0; i--) {
      if (!cellResolver.isEmpty(array[i])) {
        break;
      }
      newLength -= 1;
    }

    array.length = newLength;
    return array;
  };

  const trimCollection = () => {
    stringValues.length = trimArray(values).length;
  };

  const setAll = (newValues, onUpdate) => {
    const oldValues = values;
    values = newValues;
    stringValues.length = values.length;

    for (let i = 0; i < values.length; i++) {
      const value = values[i];
      if (oldValues[i] !== newValues[i]) {
        stringValues[i] = cellResolver.valueToText(value, column, false);
        onUpdate(i);
      }
    }
    for (let i = values.length; i < oldValues.length; i++) {
      if (!cellResolver.isEmpty(oldValues[i])) {
        onUpdate(i);
      }
    }
  };

  const getAll = () => values;

  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line no-unused-vars
  const setSingle = (index, newValue, stringValue = '') => {
    values[index] = newValue;
    stringValues[index] = cellResolver.valueToText(newValue, column, false);
  };

  const getSingle = index => values[index];

  const getReadText = index => {
    const stringValue = stringValues[index];
    return stringValue === undefined ? '' : stringValue;
  };

  const getEditText = index => {
    const value = values[index];
    return cellResolver.valueToText(value, column, true);
  };

  const load = clonedValues => {
    values = clonedValues;
    refresh();
  };

  return {
    get length() {
      return values.length;
    },

    load,

    refresh,
    trimArray,
    trimCollection,
    getAll,
    setAll,
    getSingle,
    setSingle,

    matches,
    getEditText,
    getReadText,
  };
};

import { css } from 'lit';
import { baseAnimation, fadeInUp, fadeOut, fadeOutDownFar } from '../styles/index.js';

export default css`
  /* ---single-to-multi-line---
////////////////////////
 ---end--- */
  @keyframes fadeInDown {
    0% {
      opacity: 0;
      transform: translate3d(0, -10px, 0);
    }
    100% {
      opacity: 1;
      transform: none;
    }
  }

  /* ---single-to-multi-line---
////////////////////////
 ---end--- */
  @keyframes fadeInLeft {
    0% {
      opacity: 0;
      transform: translate3d(-10px, 0, 0);
    }
    100% {
      opacity: 1;
      transform: none;
    }
  }

  /* ---single-to-multi-line---
////////////////////////
 ---end--- */
  @keyframes fadeInRight {
    0% {
      opacity: 0;
      transform: translate3d(10px, 0, 0);
    }
    100% {
      opacity: 1;
      transform: none;
    }
  }

  /* ---single-to-multi-line---
////////////////////////
 ---end--- */
  @keyframes fadeInUp {
    0% {
      opacity: 0;
      transform: translate3d(0, 10px, 0);
    }
    100% {
      opacity: 1;
      transform: none;
    }
  }

  /* ---single-to-multi-line---
////////////////////////
 ---end--- */
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  /* ---single-to-multi-line---
////////////////////////
 ---end--- */
  @keyframes fadeOut {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

  /* ---single-to-multi-line---
////////////////////////
 ---end--- */
  @keyframes fadeOutDown {
    0% {
      opacity: 1;
      transform: translate3d(0, -10px, 0);
    }
    100% {
      opacity: 0;
      transform: none;
    }
  }

  /* ---single-to-multi-line---
////////////////////////
 ---end--- */
  @keyframes fadeOutDownFar {
    0% {
      opacity: 1;
      transform: none;
    }
    100% {
      opacity: 0;
      transform: translate3d(0, 20px, 0);
    }
  }

  /* ---single-to-multi-line---
////////////////////////
 ---end--- */
  @keyframes fadeOutUp {
    0% {
      opacity: 1;
      transform: none;
    }
    100% {
      opacity: 0;
      transform: translate3d(0, -10px, 0);
    }
  }

  /* ---single-to-multi-line---
////////////////////////
 ---end--- */
  @keyframes glow {
    0% {
      box-shadow: 0 0 0 0 var(--vst-color-info);
    }
    25% {
      box-shadow: 0 0 10px 2px var(--vst-color-info);
    }
    50% {
      box-shadow: 0 0 0 0 var(--vst-color-info);
    }
    75% {
      box-shadow: 0 0 10px 2px var(--vst-color-info);
    }
    100% {
      box-shadow: 0 0 0 0 var(--vst-color-info);
    }
  }

  :host {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: calc(100vh - var(--chrome-menubar-height, 0px));
    /*  stylelint-disable-line length-zero-no-unit */
    position: absolute;
    top: var(--chrome-menubar-height);
    left: 0;
    z-index: var(--vst-z-dialog);
    background-color: var(--vst-color-bg-secondary);
    /* transition: var(--transition-default)(); */
    transition-delay: 555ms;
    padding: var(--vst-space-l);
  }

  :host(.transition-out) {
    ${baseAnimation}
    ${fadeOut}
    animation-duration: 500ms;
    animation-delay: 250ms;
  }
  :host(.transition-out) .wrapper {
    ${baseAnimation}
    ${fadeOutDownFar}
    animation-duration: 250ms;
  }

  .wrapper {
    ${baseAnimation}
    ${fadeInUp}
    margin-block-start: var(--splash-wrapper-margin-start, -5vh);
    /*  make it look vertically centered visually. */
    display: block;
    width: 100%;
    max-inline-size: var(--splash-wrapper-inline-size, 23.4375rem);
    background: var(--progress-wrapper-color, var(--vst-color-bg));
    text-align: center;
    padding: var(--splash-wrapper-padding, var(--vst-space-l));
    border-radius: var(--vst-radius);
  }

  .error-message a {
    color: var(--vst-color-brand);
  }
  .error-message a:hover {
    color: var(--vst-color-brand-dark);
  }

  .error-message-title {
    margin: 0 0 1em 0;
    color: var(--vst-color-danger);
  }

  .error-message-extended {
    font-size: var(--vst-font-size-xs);
    color: var(--vst-color-fg-secondary);
    text-align: left;
  }
  .error-message-extended p {
    margin-block-end: 0;
  }
  .error-message-extended span {
    font-family: monospace;
  }

  .logo {
    display: block;
    width: 100%;
    margin: 0 auto;
  }

  /*  All Progress Bars */
  /*  TODO Make this a ui-component */
  .progress-bar {
    appearance: none;
    /*  Reset the default appearance */
    background: var(--progress-bar-background-color, var(--vst-color-bg-secondary));
    box-shadow: 0;
    border-radius: 0;
    border: 0;
    width: 100%;
    height: 0.1875rem;
    margin-block-start: var(--vst-space-xs);
  }

  /*  Determinate */
  .progress-bar[value] {
    appearance: none;
    /*  Reset the default appearance */
  }

  /*  Indeterminate */
  .progress-bar:not([value]) {
    appearance: none;
    /*  Reset the default appearance */
  }

  .progress-bar[value]::-webkit-progress-bar {
    background-color: var(--progress-bar-background-color, var(--vst-color-bg-tertiary));
  }

  .progress-bar::-webkit-progress-value {
    background-color: var(--vst-color-brand);
    background-image: linear-gradient(
      to right,
      var(--progress-bar-color, var(--vst-color-brand-dark)),
      var(--progress-bar-color, var(--vst-color-brand)),
      96%
    );
    border-radius: 0;
    box-shadow: 0;
    transition: var(--transition-default);
  }

  /*  For some reason if we combine this selector with webkit-progress-value Chrome won't honor it -rl. */
  .progress-bar::-moz-progress-bar {
    background-color: var(--vst-color-brand);
    border-radius: 0;
    box-shadow: 0;
    transition: var(--transition-default);
  }
`;

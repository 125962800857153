import { css } from 'lit';

export default css`
  :host([metersize='large']),
  :host([metersize='medium']) {
    flex-direction: column;
    overflow-y: auto;
    overflow-x: hidden;
    padding-block-start: var(--vst-space-xs);
    background: var(--vst-color-bg-secondary);
  }

  :host([metersize='large']) vst-ui-meter,
  :host([metersize='medium']) vst-ui-meter {
    margin: 0 var(--vst-space-xs) var(--vst-space-xs);
  }
  :host([metersize='mini']) vst-ui-meter {
    margin: var(--vst-space-xs) calc(var(--vst-space-xs) / 2);
  }

  :host([metersize='mini']) {
    flex-direction: row;
    display: flex;
  }

  :host([disabled]) {
    display: none;
  }

  .message {
    font-size: 0.67em;
    color: var(--vst-color-fg-secondary);
    align-self: center;
    margin: 0 0.375rem;
  }
  .add-meters {
    display: flex;
    justify-content: center;
    padding: var(--vst-space-xs) var(--vst-space-xl);
  }
  :host vst-ui-pro-only {
    display: flex;
    justify-content: center;
    flex-grow: 2;
    flex-direction: column;
  }
  .btn {
    flex-grow: 2;
    inline-size: 100%;
  }
`;

import { priv } from '../priv.js';

export const createPrepareHeaders = ({
  interactionContext,
  renderDataSetContext,
  renderColumnContext,
}) => {
  const { events } = interactionContext;

  const focusGrid = gridEl => {
    gridEl.tabIndex = 1;
    gridEl.focus();
  };

  const renderDataSetHeader =
    ({ headerCellEl, gridEl }) =>
    dataSet => {
      events.registerGridEvent(headerCellEl, 'grid-touch-select-start', () => {
        interactionContext.uiStartDataSetSelection(dataSet);
        focusGrid(gridEl);
      });

      events.registerGridEvent(headerCellEl, 'grid-touch-select-enter', () => {
        interactionContext.uiExpandDataSetSelection(dataSet);
        focusGrid(gridEl);
      });

      events.registerGridEvent(headerCellEl, 'grid-mouse-select-start', e => {
        if (interactionContext.shiftSelected && interactionContext.dataSetsSelected) {
          interactionContext.uiExpandDataSetSelection(dataSet);
          interactionContext.scrollDisabled = true;
        } else if (!interactionContext.activeDataSetSelection(dataSet)) {
          interactionContext.uiStartDataSetSelection(dataSet);
          interactionContext.scrollDisabled = true;
        } else {
          interactionContext.clear();
        }
        focusGrid(gridEl);
        e.stopPropagation();
      });

      events.registerGridEvent(headerCellEl, 'grid-mouse-select-enter', () => {
        interactionContext.uiExpandDataSetSelection(dataSet);
        focusGrid(gridEl);
      });

      events.registerGridEvent(headerCellEl, 'grid-mouse-context-menu', e => {
        if (!interactionContext.activeDataSetSelection(dataSet)) {
          interactionContext.uiStartDataSetSelection(dataSet);
        }
        const { clientX, clientY } = e.detail;
        interactionContext.toggleSelectContextMenu({
          clientX,
          clientY,
          isTouch: false,
          cellEl: headerCellEl,
        });

        e.stopPropagation();
      });

      dataSet[priv].renderContext = renderDataSetContext({ dataSet, headerCellEl });

      dataSet[priv].renderContext(dataSet.context);
    };

  const renderDataColumnHeader =
    ({ headerCellEl, gridEl }) =>
    column => {
      events.registerGridEvent(headerCellEl, 'grid-touch-select-start', () => {
        interactionContext.uiStartColumnSelection(column);
        focusGrid(gridEl);
      });

      events.registerGridEvent(headerCellEl, 'grid-touch-select-enter', () => {
        interactionContext.uiExpandColumnSelection(column);
        focusGrid(gridEl);
      });

      events.registerGridEvent(headerCellEl, 'grid-mouse-select-start', e => {
        if (interactionContext.shiftSelected && interactionContext.columnsSelected) {
          interactionContext.uiExpandColumnSelection(column);
          interactionContext.scrollDisabled = true;
        } else if (!interactionContext.activeColumnSelection(column)) {
          interactionContext.uiStartColumnSelection(column);
          interactionContext.scrollDisabled = true;
        } else {
          interactionContext.clear();
        }
        focusGrid(gridEl);
        e.stopPropagation();
      });

      events.registerGridEvent(headerCellEl, 'grid-mouse-select-enter', () => {
        interactionContext.uiExpandColumnSelection(column);
        focusGrid(gridEl);
      });

      events.registerGridEvent(headerCellEl, 'grid-mouse-context-menu', e => {
        if (!interactionContext.activeColumnSelection(column)) {
          interactionContext.uiStartColumnSelection(column);
        }

        const { clientX, clientY } = e.detail;
        interactionContext.toggleSelectContextMenu({
          clientX,
          clientY,
          isTouch: false,
          cellEl: headerCellEl,
        });

        e.stopPropagation();
      });

      column[priv].renderContext = renderColumnContext({ column, headerCellEl });

      column[priv].renderContext(column.context);
    };

  return [renderDataSetHeader, renderDataColumnHeader];
};

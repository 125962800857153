import { css } from 'lit';
import { breakpoint } from '@styles/mixins/breakpoints.css.js';
import { buttonReset, buttonLink } from '../styles/index.js';

export default css`
  :host {
    inline-size: 100%;
  }
  .status {
    font-size: var(--vst-font-size-xs);
    width: 100%;
    background: var(--vst-color-bg-tertiary);
    border: 1px solid var(--vst-color-bg-secondary);
    border-bottom-left-radius: var(--vst-radius);
    border-bottom-right-radius: var(--vst-radius);
  }

  .info-icon {
    width: 0.75rem;
  }

  ${buttonReset(css`.info-btn`)}
  .info-btn {
    background-color: transparent;
    padding: 0;
    display: inline-flex;
    align-items: baseline;
    align-content: baseline;
    width: 0.75rem;
    border: 0;
  }

  .device-connected__container {
    padding: var(--vst-space-xs) var(--vst-space-l);
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
  }

  .device-connected__summary {
    display: flex;
    align-items: center;
  }

  ${buttonLink(css`.device-connected__disconnect-btn`)};
  ${breakpoint(
    's',
    css`
      .device-connected__disconnect-btn {
        margin-block-start: 0;
        margin-block-end: 0;
      }
    `,
  )}
  #disconnect_button {
    padding-inline-start: 0;
  }

  .no-device-connected {
    border: 0;
    padding: var(--vst-space-s) var(--vst-space-l);
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    inline-size: 100%;
    text-align: left;
  }

  ${breakpoint(
    's',
    css`
      .no-device-connected {
        justify-content: space-between;
      }
    `,
  )}

  #connect_button {
    display: block;
    overflow: hidden;
  }

  .no-device-connected--highlight {
    animation-name: highlightBar;
    animation-duration: 1s;
    animation-fill-mode: both;
    animation-timing-function: ease-in;
    animation-iteration-count: 1;
  }

  @keyframes highlightBar {
    0% {
      background-color: var(--vst-color-warning-bg);
    }
    50% {
      background-color: var(--vst-color-warning-ui);
      color: #fff;
    }
  }
`;

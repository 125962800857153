import { priv } from '../priv.js';

export const renderDataSet = ({ dataSet, maxLength }) => {
  const { grid } = dataSet.grid[priv];
  const { columns } = dataSet[priv];
  const currentGridLength = grid.totalRows;

  grid.applyChanges(context => {
    const columnsHeaderConfig = columns.map(col => ({
      data: col,
    }));

    const dataSetConfig = {
      data: dataSet,
      children: columnsHeaderConfig,
    };

    context.appendHeader(dataSetConfig);

    if (maxLength > currentGridLength) {
      context.changeTotalRows(maxLength);
    }
  });
};

import { LitElement, html } from 'lit';
import { getText } from '@utils/i18n.js';
import { globalStyles } from '@styles/vst-style-global.css.js';
import '@components/vst-ui-tooltip/vst-ui-tooltip.js';
import { ObservableProperties } from '@mixins/vst-observable-properties-mixin.js';
import { vstPresentationStore } from '@stores/vst-presentation.store.js';
import { overflow } from '@icons';
import { styleMap } from 'lit/directives/style-map.js';
import { createRef, ref } from 'lit/directives/ref.js';
import vstUiMeterStyles from './vst-ui-meter.css.js';
import '@components/vst-core-sensor-actions/vst-core-sensor-actions.js';
import '@components/vst-core-meter-actions/vst-core-meter-actions.js';
import '@components/vst-style-tooltip/vst-style-tooltip.js';
import '@components/vst-ui-pro-only/vst-ui-pro-only.js';

class VstUiMeter extends ObservableProperties(LitElement) {
  static get observableProperties() {
    return {
      colorMode: vstPresentationStore,
    };
  }

  static get properties() {
    return {
      _isSelected: { state: true },
      active: {
        type: Boolean,
        reflect: true,
      },
      buttonEnabled: {
        type: Boolean,
        value: false,
      },
      channel: {
        type: String,
      },
      color: {
        type: String,
        reflect: true,
      },
      metersize: {
        type: String,
        reflect: true,
      },
      name: {
        type: String,
      },
      units: {
        type: String,
      },
      value: {
        type: String,
      },
      sensorId: { type: Number },
      showButton: { type: Boolean },
      sensorReadingStyles: { type: Object },
      sensorStyles: { type: Object },
      showCheckBox: { type: Boolean },
      isVisible: { type: Boolean },
      showSensorActions: { type: Boolean },
      meterId: { type: Number },
    };
  }

  constructor() {
    super();
    this._bigMeterButton = createRef();
    this._isSelected = false;
    this._miniMeterButton = createRef();
    this.active = false;
    this.channel = '';
    this.color = '';
    this.buttonEnabled = false;
    this.metersize = '';
    this.name = '';
    this.units = '';
    this.value = '';
    this.sensorId = 0;
    this.showButton = false;
    this.sensorReadingStyles = {};
    this.sensorStyles = {};
    this.showCheckBox = false;
    this.isVisible = false;
    this.showSensorActions = false;
    this.meterId = 0;
  }

  firstUpdated() {
    this._isSelected = this.isVisible;
  }

  updateStyles() {
    this.sensorReadingStyles =
      this.metersize !== 'mini' && this.colorMode === 'light' ? { color: this.color } : {};
    this.sensorStyles = this.colorMode === 'dark' ? { backgroundColor: this.color } : {};
  }

  updated(changedProperties) {
    if (['colorMode', 'color', 'meterSize'].some(prop => changedProperties.has(prop)))
      this.updateStyles();
  }

  static get styles() {
    return [globalStyles, vstUiMeterStyles];
  }

  dispatchMeterVisibilityChange() {
    this.dispatchEvent(
      new CustomEvent('checked-meter-changed', {
        bubbles: true,
        composed: true,
        detail: {
          id: this.meterId,
          checked: this._isSelected,
        },
      }),
    );
  }

  meterTemplate() {
    const sensorName = html`
      <span id="sensor-name">${this.name}</span
      ><span class="divider-symbol" ?hidden="${this.showNameOnly}">:</span>
    `;
    return html`
      ${this.showCheckBox
        ? html`
            <span class="sensor__name"
              ><button
                style=${styleMap(this.sensorStyles)}
                aria-label="${getText('select/deselect meter')}"
                class="sensor-checkbox-btn"
                @click=${() => {
                  this._isSelected = !this._isSelected;
                  this.dispatchMeterVisibilityChange();
                }}
              >
                ${sensorName}
              </button>
            </span>
          `
        : html` <span class="sensor__name">${sensorName}</span> `}
      <span style=${styleMap(this.sensorReadingStyles)} class="sensor__reading">
        <span>${this.value}</span>
        <span class="sensor__reading-units" ?hidden="${this.value === ''}">${this.units}</span>
      </span>
    `;
  }

  showMeterActions() {
    if (this.metersize !== 'mini') {
      this.togglePopover(!this.showSensorActions);
    } else {
      const anchor =
        this.metersize === 'mini' ? this._miniMeterButton.value : this._bigMeterButton.value;
      this.dispatchEvent(
        new CustomEvent('show-meter-actions-clicked', {
          detail: {
            anchor,
            meterId: this.meterId,
            sensorId: this.sensorId,
            metersize: this.metersize,
          },
          bubbles: true,
          composed: true,
        }),
      );
    }
  }

  async togglePopover(state = true) {
    this.showSensorActions = state;
  }

  render() {
    return html`
      ${this.metersize === 'mini'
        ? html`
            <vst-ui-tooltip
              for="#meter-btn-mini"
              placement="top"
              content="${getText('Sensor Actions')}"
            ></vst-ui-tooltip>
            <button
              ${ref(this._miniMeterButton)}
              class="sensor"
              id="meter-btn-mini"
              ?active="${this.active}"
              type="button"
              aria-label="${this.name} ${getText('Sensor Actions')}"
              ?disabled="${!this.buttonEnabled}"
              @click=${this.showMeterActions}
            >
              ${this.meterTemplate()}
            </button>
          `
        : html` <div style=${styleMap(this.sensorStyles)} class="sensor">
            ${this.meterTemplate()}
            ${this.showCheckBox
              ? html`
                  <div class="checkbox">
                    <input
                      aria-labelledby="sensor-name"
                      .checked=${this._isSelected}
                      type="checkbox"
                      name="show-meter"
                      value="show-meter"
                      @change="${({ target: { checked } }) => {
                        this._isSelected = checked;
                        this.dispatchMeterVisibilityChange();
                      }}"
                    />
                    <span class="check"></span>
                  </div>
                `
              : html`
                  <vst-ui-pro-only
                    preview-position="bottom-right"
                    feature-name="${getText('metering on all columns')}"
                    .authorizedClickHandler="${this.showMeterActions.bind(this)}"
                  >
                    <vst-style-tooltip>
                      <button
                        ${ref(this._bigMeterButton)}
                        ?hidden=${!this.showButton}
                        id="meter-btn-big"
                        ?active="${this.active}"
                        class="btn over-flow-menu"
                        variant="icon"
                        aria-label="${this.name} ${getText('Meter Actions')}"
                        ?disabled="${!this.buttonEnabled}"
                      >
                        <vst-ui-icon .icon="${overflow}"></vst-ui-icon>
                      </button>
                      <span role="tooltip" position="inline-start"
                        >${getText('Meter All Columns')}</span
                      >
                    </vst-style-tooltip>
                  </vst-ui-pro-only>
                `}
          </div>`}
      <vst-ui-popover
        id=${`meter-popover-${this.meterId}`}
        ?open=${this.showSensorActions}
        @closed=${() => {
          this.showSensorActions = false;
          this.sensorActions = null;
        }}
        placement="top"
        for="meter-btn-${this.metersize === 'mini' ? 'mini' : 'big'}"
      >
        <vst-core-meter-actions
          .name=${this.name}
          .meterId=${this.meterId}
          @meter-action-clicked=${() => {
            this.togglePopover(false);
          }}
        ></vst-core-meter-actions>
      </vst-ui-popover>
    `;
  }
}

customElements.define('vst-ui-meter', VstUiMeter);

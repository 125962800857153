import { makeAutoObservable /* , runInAction, reaction */ } from 'mobx';
import { distance } from '@common/utils/math.js';

export const MeasurementType = Object.freeze({
  UNKNOWN: 'unknown',
  DISTANCE: 'distance',
  ANGLE: 'angle',
});

function createMeasurement(udmId = 0, type = MeasurementType.UNKNOWN) {
  return {
    udmId,
    type,
    toUDM() {
      return {
        type: this.type,
        udmId: this.udmId,
        x1: this.x1,
        y1: this.y1,
        x2: this.x2,
        y2: this.y2,
        cx: this.cx ?? 0,
        cy: this.cy ?? 0,
      };
    },
  };
}

function createPath(x1, y1, x2, y2) {
  return {
    /**
     * First coordinate x value in video pixels
     * @type {number}
     */
    x1,
    /**
     * First coordinate y value in video pixels
     * @type {number}
     */
    y1,
    /**
     * Second coordinate x value in video pixels
     * @type {number}
     */
    x2,
    /**
     * Second coordinate y value in video pixels
     * @type {number}
     */
    y2,
    /**
     * Calculate path distance for given scale parameters
     *
     * @param {Number} distancePerPixel Distance per pixel
     * @return {Number} Distance in video units
     */
    distance(distancePerPixel) {
      return distance(this.x1, this.y1, this.x2, this.y2) * distancePerPixel;
    },
    setCoordinates(x1 = this.x1, y1 = this.y1, x2 = this.x2, y2 = this.y2) {
      this.x1 = x1;
      this.y1 = y1;
      this.x2 = x2;
      this.y2 = y2;
    },
  };
}

function createVertex(cx, cy) {
  return {
    /**
     * Vertex coordinate x value in video pixels
     * @type {Number}
     */
    cx,
    /**
     * Vertex coordinate y value in video pixels
     * @type {Number}
     */
    cy,
    /**
     * Vertex angle
     * @return {Number} Angle
     */
    get angle() {
      const slope1 = (this.cy - this.y1) / (this.cx - this.x1);
      const slope2 = (this.cy - this.y2) / (this.cx - this.x2);
      return Math.atan((slope1 - slope2) / (1 + slope1 * slope2));
    },
    setCoordinates(
      x1 = this.x1,
      y1 = this.y1,
      cx = this.cx,
      cy = this.cy,
      x2 = this.x2,
      y2 = this.y2,
    ) {
      this.x1 = x1;
      this.y1 = y1;
      this.cx = cx;
      this.cy = cy;
      this.x2 = x2;
      this.y2 = y2;
    },
  };
}

export function createDistanceMeasurement(x1, y1, x2, y2, udmId = 0) {
  return makeAutoObservable({
    ...createMeasurement(udmId, MeasurementType.DISTANCE),
    ...createPath(x1, y1, x2, y2),
  });
}

export function createAngleMeasurement(x1, y1, cx, cy, x2, y2, udmId = 0) {
  return makeAutoObservable({
    ...createMeasurement(udmId, MeasurementType.ANGLE),
    ...createPath(x1, y1, x2, y2),
    ...createVertex(cx, cy),
  });
}

export function createMeasurementFromUdm(params) {
  switch (params.type) {
    case MeasurementType.DISTANCE: {
      const { id, x1, y1, x2, y2 } = params;
      return createDistanceMeasurement(x1, y1, x2, y2, id);
    }
    case MeasurementType.ANGLE: {
      const { id, x1, y1, cx, cy, x2, y2 } = params;
      return createAngleMeasurement(x1, y1, cx, cy, x2, y2, id);
    }
    default:
      throw new Error(`Unknown VA measurement tool type ${params.type}`);
  }
}

export const createDoubleLinkedList = () => {
  let firstNode = null;
  let lastNode = null;
  let cacheData = null;

  const checkDirtyState = () => {
    if (!cacheData) {
      let index = 0;
      for (let node = firstNode; node !== null; node = node.next) {
        node.index = index;
        index++;
      }

      cacheData = { listLength: index };
    }
  };

  const insertBefore = (value, nextNode) => {
    cacheData = null;

    const node = {
      previous: null,
      next: null,
      index: null,
      value,
    };

    if (!nextNode) {
      if (!firstNode) {
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line no-multi-assign
        firstNode = lastNode = node;
      } else {
        firstNode.previous = node;
        node.next = firstNode;
        firstNode = node;
      }
    } else {
      node.next = nextNode;
      node.previous = nextNode.previous;
      nextNode.previous = node;

      if (!node.previous) {
        firstNode = node;
      } else {
        node.previous.next = node;
      }
    }

    return node;
  };

  const indexOf = compareNode => {
    checkDirtyState();

    return compareNode ? compareNode.index : -1;
  };

  const isFirst = compareNode => {
    return compareNode === firstNode;
  };

  const isLast = compareNode => {
    return compareNode === lastNode;
  };

  const insertAfter = (value, previousNode) => {
    cacheData = null;

    const node = {
      previous: null,
      next: null,
      index: null,
      value,
    };

    if (!previousNode) {
      if (!lastNode) {
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line no-multi-assign
        firstNode = lastNode = node;
      } else {
        lastNode.next = node;
        node.previous = lastNode;
        lastNode = node;
      }
    } else {
      node.previous = previousNode;
      node.next = previousNode.next;
      previousNode.next = node;

      if (!node.next) {
        lastNode = node;
      } else {
        node.next.previous = node;
      }
    }

    cacheData = null;

    return node;
  };

  const remove = node => {
    cacheData = null;

    const { previous, next } = node;

    if (previous) {
      previous.next = next;
    } else {
      firstNode = next;
    }

    if (next) {
      next.previous = previous;
    } else {
      lastNode = previous;
    }

    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line no-multi-assign
    node.previous = node.next = null;
    node.value = null;
    node.index = null;
  };

  const toArray = () => {
    const result = [];

    for (let currentNode = firstNode; currentNode !== null; currentNode = currentNode.next) {
      result.push(currentNode.value);
    }

    return result;
  };

  const map = fn => {
    const result = [];

    for (let currentNode = firstNode; currentNode !== null; currentNode = currentNode.next) {
      result.push(fn(currentNode.value));
    }

    return result;
  };

  const forEach = fn => {
    let index = 0;
    for (let currentNode = firstNode; currentNode !== null; currentNode = currentNode.next) {
      fn(currentNode.value, index);
      index++;
    }
  };

  const valueAfter = node => {
    const { next } = node;
    return next ? next.value : null;
  };

  const valueBefore = node => {
    const { previous } = node;
    return previous ? previous.value : null;
  };

  const getLength = () => {
    checkDirtyState();
    return cacheData.listLength;
  };

  return {
    insertBefore,
    insertAfter,
    remove,
    toArray,
    map,
    forEach,
    indexOf,

    valueBefore,
    valueAfter,

    isFirst,
    isLast,

    get length() {
      return getLength();
    },
  };
};

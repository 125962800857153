import { merge, set, get } from '@utils/dot-prop-immutable-es.js';
import { cloneDeep, mapValues, uniq, isEqual } from 'lodash-es';
import { vstLayoutStore } from '@stores/vst-layout.store.js';
import { vstAnalysisGroupStore } from '../../mobx-stores/vst-analysis-group.store';

import {
  GRAPH_INIT,
  GRAPH_UDM_ID_UPDATE,
  GRAPH_OPTIONS_UPDATE,
  AXIS_SCALING_MODE_UPDATE,
  GRAPH_RIGHT_AXIS_ENABLED_UPDATE,
  GRAPH_IS_DRAW_PREDICTIONS_ACTIVE_UPDATE,
  GRAPH_BASE_COLUMN_ID_UPDATE,
  GRAPH_BASE_UNITS_UPDATE,
  GRAPH_AUTOSCALE_PADDING_UPDATE,
  GRAPH_IS_LEGEND_VISIBLE_UPDATE,
  AXIS_COLUMN_ID_ADDITION,
  AXIS_COLUMN_ID_REMOVAL,
  AXIS_COLUMN_IDS_UPDATE,
  Actor,
} from '../actions';

export const defaultOptionsState = {
  title: '',
  appearance: { lines: true, points: false, bars: false },
  lineWeight: 2, // in px
  labelSize: 0.67, // in ems
  baseRange: { min: 0, max: 1 },
  leftRange: { min: 0, max: 1 },
  rightRange: { min: 0, max: 1 },
};

export const defaultScalingModesState = {
  base: 'automatic_scaling',
  left: 'automatic_scaling',
  right: 'automatic_scaling',
};

export const defaultState = {
  isDrawPredictionsActive: false,
  baseColumnId: '',
  baseUnits: '',
  autoscalePadding: 0.1,
  isLegendVisible: false,
  options: defaultOptionsState,
  columnIds: { left: [], right: [] },
  scalingModes: defaultScalingModesState,
  rightAxisEnabled: false,
};

export const graphs = (state = {}, action) => {
  const { payload, meta } = action;

  switch (action.type) {
    case GRAPH_INIT:
      return merge(state, action.meta.id, {
        ...defaultState,
        id: action.meta.id,
      });
    case GRAPH_UDM_ID_UPDATE:
      return set(state, `${action.meta.graphId}.udmId`, action.payload);
    case GRAPH_OPTIONS_UPDATE: {
      const payload = cloneDeep(action.payload);
      let newState = state;
      const {
        meta: { id },
      } = action;

      // If baseRange has been modified and the indicated graph is one that is
      // linked to other graphs, we'll happily update those graphs' baseRange to
      // match the new value.
      if (payload.baseRange && meta.actor === Actor.USER) {
        const { examineLinks } = vstAnalysisGroupStore;
        const { baseRange: newBaseRange } = payload;
        const { baseRange: oldBaseRange = newBaseRange } = state[id].options;
        const isGraphEnabled = vstLayoutStore[id];

        if (!isEqual(newBaseRange, oldBaseRange) && examineLinks.has(id) && isGraphEnabled) {
          Array.from(examineLinks)
            .filter(graphId => graphId !== id)
            .forEach(graphId => {
              newState = merge(newState, `${graphId}.options`, { baseRange: newBaseRange });
            });
        }
      }
      // FIXME: merge() can only merge objects one level deep, so the nested objects are overwriting, not merging
      return merge(newState, `${id}.options`, payload);
    }
    case AXIS_SCALING_MODE_UPDATE:
      return set(state, `${meta.graphId}.scalingModes.${meta.axis}`, payload);
    case GRAPH_IS_DRAW_PREDICTIONS_ACTIVE_UPDATE:
      return set(state, `${action.meta.id}.isDrawPredictionsActive`, action.payload);
    case GRAPH_BASE_COLUMN_ID_UPDATE:
      return set(state, `${action.meta.id}.baseColumnId`, action.payload);
    case GRAPH_BASE_UNITS_UPDATE:
      return set(state, `${action.meta.id}.baseUnits`, action.payload);
    case GRAPH_AUTOSCALE_PADDING_UPDATE:
      return mapValues(state, graph => set(graph, 'autoscalePadding', action.payload));
    case AXIS_COLUMN_ID_ADDITION:
      return set(
        state,
        `${action.meta.graphId}.columnIds.${action.meta.axis}`,
        uniq([
          ...get(state, `${action.meta.graphId}.columnIds.${action.meta.axis}`),
          action.meta.columnId,
        ]),
      );
    case AXIS_COLUMN_ID_REMOVAL:
      return set(
        state,
        `${action.meta.graphId}.columnIds.${action.meta.axis}`,
        state[action.meta.graphId].columnIds[action.meta.axis].filter(
          columnId => columnId !== action.meta.columnId,
        ),
      );
    case AXIS_COLUMN_IDS_UPDATE:
      return set(state, `${action.meta.graphId}.columnIds.${action.meta.axis}`, action.payload);
    case GRAPH_RIGHT_AXIS_ENABLED_UPDATE:
      return set(state, `${action.meta.graphId}.rightAxisEnabled`, action.payload);
    case GRAPH_IS_LEGEND_VISIBLE_UPDATE:
      return set(state, `${action.meta.graphId}.isLegendVisible`, action.payload);
    default:
      return state;
  }
};

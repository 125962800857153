// This function allows one to add a render action function to a queue of such functions
// and then later to invoke each of the functions in the queue
//
// The returned method createRenderAccumulator().queueRender(renderAction) allows one to add a
// render action function to the queue
//
// The method createRenderAccumulator().applyChanges() invokes each function in the queue

export const createRenderAccumulator = () => {
  // let maxTableCols = 0;
  // let currentMaxCols = 0;
  const renderQueue = [];
  // let resized = false;

  return {
    queueRender: renderAction => {
      renderQueue.push(renderAction);
    },

    applyChanges: () => {
      renderQueue.forEach(renderAction => renderAction());
      renderQueue.splice(0);
    },
  };
};

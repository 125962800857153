import { createStore, applyMiddleware, combineReducers } from 'redux';
import { enableBatching } from 'redux-batched-actions';
// import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import thunk from 'redux-thunk';

import * as commonReducers from '@common/stores/reducers.js';

const commonMiddleware = [thunk];

export const configureStore = (appReducers, appMiddleware, preloadedState) => {
  const middleware = [...commonMiddleware, ...appMiddleware];
  return createStore(
    enableBatching(combineReducers({ ...commonReducers, ...appReducers })),
    preloadedState,
    applyMiddleware(...middleware), // use redux devtools comment out this line and uncommend the line below. We have multiple machines that break when composeWithDevTools is used
    // composeWithDevTools(applyMiddleware(...middleware)),
  );
};

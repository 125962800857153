import { LitElement, html, css } from 'lit';

export class VstCoreGraphGroup extends LitElement {
  graphInstances = [];

  static get styles() {
    return [
      css`
        :host {
          display: grid;
          grid-auto-rows: 1fr;
          block-size: 100%;
        }

        :host([disabled]) {
          display: none;
        }
      `,
    ];
  }

  constructor() {
    super();
    this._resizeVisibleGraphs = this._resizeVisibleGraphs.bind(this);
  }

  async firstUpdated() {
    this.addEventListener('graph-instance-created', ({ detail: graphInstance }) => {
      this.graphInstances.push(graphInstance);
    });
    this.connectedGraphs = [...this.querySelectorAll('.app-graph')];
    await this.updateComplete;

    this.connectedGraphs.forEach(graph => {
      graph.addEventListener('hidden-changed', () => {
        this._resizeVisibleGraphs();
      });
    });

    this._resizeVisibleGraphs();
    window.addEventListener('resize', this._resizeVisibleGraphs);
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    window.removeEventListener('resize', this._resizeVisibleGraphs);
  }

  _resizeVisibleGraphs() {
    this.connectedGraphs.forEach(connectedGraph => {
      const { coreGraphEl } = connectedGraph;
      if (coreGraphEl && coreGraphEl.graphInstance && !connectedGraph.hidden) {
        coreGraphEl.resizeGraph();
        coreGraphEl.updatePlotData();
      }
    });
  }

  render() {
    return html`<slot name="graph"></slot>`;
  }
}

customElements.define('vst-core-graph-group', VstCoreGraphGroup);

import { LitElement, html, css } from 'lit';
import { Requester } from '@mixins/vst-core-requester-mixin.js';
import { getText } from '@utils/i18n.js';
import { EventBinder } from '@utils/EventBinder.js';
import { globalStyles } from '@styles/vst-style-global.css.js';

import '@components/vst-style-spinner/vst-style-spinner.js';

export class SaCalibrate extends Requester(LitElement) {
  static get properties() {
    return {
      // #region private state reactive properties
      _isCalibrating: { state: true },
      _isWarmedUp: { state: true },
      _spectrumMode: { state: true },
      // #endregion
      // #region public reactive properties
      // #endregion
    };
  }

  // static get observableProperties() {
  // 	return {
  //
  // 	}
  // }

  static get styles() {
    return [
      globalStyles,
      css`
        .stack {
          --inline-size: 25rem;
          max-inline-size: 100%;
          overflow: hidden;
        }

        #skip_btn {
          display: inline-block;
        }

        #calibrate_btn {
          align-self: flex-end;
        }

        .spinner {
          block-size: 1.75rem;
          inline-size: 1.75rem;
        }
      `,
    ];
  }

  constructor() {
    super();

    this._isCalibrating = false;
    this._isWarmedUp = false;
    this._spectrumMode = '';
    this.$sensorWorld = null;
    this.$dataCollection = null;
    this.$specDevice = null;
    this.$toast = null;
    this.$power = null;
    this.eventBinder = null;
  }

  // #region Lit Lifecycle Callbacks
  firstUpdated() {
    this.$sensorWorld = this.requestService('sensorWorld');
    this.$dataCollection = this.requestService('dataCollection');
    this.$specDevice = this.requestService('specDevice');
    this.$toast = this.requestService('toast');
    this.$power = this.requestService('power');

    this.eventBinder = new EventBinder();

    this.eventBinder.bindListeners({
      source: this.$specDevice,
      target: this,
      eventMap: {
        'lamp-warmup-time-changed': '_onSpecDeviceLampWarmupTimeChanged',
      },
    });

    // In case the back end is slow to switch modes, we can fetch the newer sensor when we get 'sensor-added' event.
    this.eventBinder.bindListeners({
      source: this.$sensorWorld,
      target: this,
      eventMap: {
        'sensor-added': '_fetchSensor',
      },
    });

    this._isWarmedUp = this.$specDevice.isWarmedUp;
    this._spectrumMode = this.$dataCollection.spectrumMode;

    this._fetchSensor();
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    this.eventBinder.unbindAll();
  }
  // #endregion

  // #region getters/setters

  // #endregion

  // #region private methods
  _fetchSensor() {
    this.sensor = this.$sensorWorld.getFirstSensor();
    if (this.sensor) {
      this.$specDevice.takeDarkReference(this.sensor.experimentId, this.sensor.id);
      if (this.$specDevice.isCalibrated) {
        this.$specDevice.startLampTimer(this.$specDevice.recalibrationTime);
      }
    }
  }

  async _finishCalibration() {
    const { $specDevice, $toast, $power } = this;

    // Do not fail on wakelock errors, this currently doesn't work for web
    try {
      $power.requestWakeLock('system');
    } catch (e) {
      console.warn(e);
    }

    try {
      this._isCalibrating = true;

      await $specDevice.calibrateSpectrometer(this.sensor.experimentId, this.sensor.id);
      this.dispatchEvent(new CustomEvent('dialog-close'));
    } catch (err) {
      console.error(err);
      $toast.makeToast(getText('Calibration Failed'));
    } finally {
      this._isCalibrating = false;
      try {
        $power.releaseWakeLock('system');
      } catch (e) {
        console.warn(e);
      }
    }
  }

  _onSpecDeviceLampWarmupTimeChanged() {
    this._isWarmedUp = this.$specDevice.isWarmedUp;
    this.requestUpdate();
  }
  // #endregion

  // #region public methods
  // #endregion

  render() {
    const warmUpTime = this.$specDevice?.currentLampWarmupTime;

    return html`
      <div class="stack" gap="m">
        ${this._isCalibrating
          ? html`
              <p>${getText('Calibrating...')}</p>
              <vst-style-spinner></vst-style-spinner>
            `
          : html`
              ${this._isWarmedUp
                ? html`
                    ${this._spectrumMode === 'intensity'
                      ? html`
                          <p class="place-cuvette">
                            ${getText('Ensure your light source is off and the room is dark.')}
                          </p>
                        `
                      : html`
                          <p class="place-cuvette">
                            ${getText('Place a blank cuvette in the device.')}
                          </p>
                        `}
                  `
                : html`
                    <p class="lamp-countdown">
                      <span>${getText('Waiting')}</span>
                      <span>${warmUpTime}</span>
                      <span>${getText('seconds for lamp to warm up')}</span>.
                      <button
                        type="button"
                        class="btn"
                        id="skip_btn"
                        variant="text"
                        ?disabled="${!this.$specDevice?.canSkipWarmUp}"
                        @click="${() => this.$specDevice?.skipWarmup()}"
                      >
                        ${getText('Skip')}
                      </button>
                    </p>
                  `}

              <button
                type="button"
                class="btn"
                id="calibrate_btn"
                @click="${this._finishCalibration}"
                ?disabled="${!this._isWarmedUp}"
              >
                ${getText('Finish Calibration')}
              </button>
            `}
      </div>
    `;
  }
}
customElements.define('sa-calibrate', SaCalibrate);

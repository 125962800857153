import { priv } from '../priv.js';

export const renderRemoveColumn = ({ column }) => {
  const columnPosition = column.position;
  const { dataSet } = column;
  const dataSetPosition = dataSet.position;
  const { grid } = dataSet.grid[priv];

  grid.applyChanges(context => {
    context.getHeader(dataSetPosition).getChildHeader(columnPosition).remove();
  });
};

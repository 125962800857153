import { css } from 'lit';
import { dialogDefault } from '../styles/index.js';

export default css`
  ${dialogDefault}
  label {
    margin-block-end: 0.375rem;
    display: block;
  }

  strong {
    font-weight: normal;
  }

  .file-extension {
    margin: 0.5em 0 0 0.35em;
    width: 2.125em;
    color: var(--vst-color-fg-tertiary);
  }

  .input-wrapper,
  .decimal-wrapper {
    display: flex;
    justify-content: space-between;
  }

  .input-wrapper input,
  .decimal-wrapper label {
    flex: 1;
  }

  .decimal-wrapper label:first-child {
    flex: 0.33;
  }
`;

const verboseLogging = false;

export function preventFlagOverlap(
  yPoints,
  initialFlagHeight,
  topBounds,
  bottomBounds,
  allowedFlagOverlap,
) {
  // Overview:
  // Calculate the usable height of a flag by subtracting the amount that flags are allowed to cover each other, *allowedFlagOverlap*, from *initialFlagHeight*
  // Loop through the *yPoints* and create a "flagTop" property so that the flag can be in a different position from its point protector. If the flag will be cutoff by the *topBounds* or *bottomBounds* of the graph, nudge it in place now.
  // Loop through the yPoints and figure out which flags will overlap eachother
  // While we have overlapping flags... push each flag away from it's overlapper evenly. If one flag is at *topBounds*, push the other flag down. If one flag is at the *bottomBounds*, push the other up. Keep doing this until we have no more overlapping flags.
  // Return the new set of points with the additional flagTop property.

  const flagHeight = initialFlagHeight - allowedFlagOverlap;

  const sortedList = yPoints
    .sort(
      (
        a,
        b, // sort the list by top
      ) =>
        parseFloat(a.top) > parseFloat(b.top) ? 1 : parseFloat(b.top) > parseFloat(a.top) ? -1 : 0, //eslint-disable-line
    )
    .map(point => {
      point.flagTop = parseFloat(point.top); // strip px units and add a flagTop property
      if (point.flagTop > bottomBounds - flagHeight) {
        // prevent any flags from being cutoff on the top or bottom of the bounds
        point.flagTop = bottomBounds - flagHeight + allowedFlagOverlap;
      } else if (point.flagTop < topBounds) {
        point.flagTop = topBounds;
      }
      return point;
    });

  function makeOverlapPairs(arr) {
    // returns an array of pairs that overlap
    const result = [];
    let prev;
    arr.forEach(item => {
      if (!prev || item.flagTop < prev.flagTop + flagHeight) {
        if (!prev) {
          prev = item;
        } else {
          result.push([prev, item]);
          prev = item;
        }
      } else {
        prev = item;
      }
    });
    return result;
  }

  let loopCount = 0;
  const maxLoops = sortedList.length * flagHeight; // Failsafe. The majority of the time it will be under 5 loops
  while (makeOverlapPairs(sortedList).length && loopCount < maxLoops) {
    loopCount++;

    // loop over the array of pairs and push each item in the pair away from the other
    makeOverlapPairs(sortedList).forEach(flagPair => {
      while (flagPair[1].flagTop - flagPair[0].flagTop <= flagHeight) {
        if (flagPair[0].flagTop > topBounds) {
          flagPair[0].flagTop--;
        } else {
          flagPair[0].flagTop = topBounds;
        }
        if (flagPair[1].flagTop >= bottomBounds - flagHeight + allowedFlagOverlap) {
          flagPair[1].flagTop--;
        } else {
          flagPair[1].flagTop++;
        }
      }
    });
  }

  if (verboseLogging) {
    console.log(`Loops taken: ${loopCount}.  Loops allowed: ${maxLoops}`);
  }

  const finalList = sortedList.map(point => {
    point.flagTop = `${point.flagTop - parseFloat(point.top)}px`; // subtract top from flagTop since they're absolutely positioned within each other in CSS
    return point;
  });

  return finalList;
}

import { priv } from '../priv.js';

export const renderClear = ({ dataSetGrid }) => {
  const { grid, minRows } = dataSetGrid[priv];
  const lastDataSetIndex = dataSetGrid.dataSetLength - 1;

  grid.applyChanges(context => {
    for (let dataSetIndex = lastDataSetIndex; dataSetIndex >= 0; dataSetIndex--) {
      context.getHeader(dataSetIndex).remove();
    }
    context.changeTotalRows(minRows);
  });
};

import { LitElement, html } from 'lit';

import { Requester } from '@components/mixins/vst-core-requester-mixin.js';
import { getText, parseFloatLocale, isLocaleRationalNumber } from '@utils/i18n.js';
import { sprintf } from '@libs/sprintf.js';
import { EventBinder } from '@utils/EventBinder.js';
import { promptDeleteColumn } from '@common/utils/promptDeleteColumn.js';
import { promptDeleteDataSet } from '@common/utils/promptDeleteDataSet.js';
import { isPrivilegedIframe } from '@utils/isPrivilegedIframe.js';

import { Column } from '@api/common/Column.js';
import { DataSetType } from '@api/common/DataSet.js';

import { overflow } from '@components/vst-ui-icon/index.js';
import { globalStyles } from '@styles/vst-style-global.css.js';

import { createDataSetGrid, createDataSetGridStyles } from '@components/vst-grid';

import vstCoreTableStyles from './vst-core-table.css.js';

import '@components/vst-ui-icon/vst-ui-icon.js';

const _getColumnMapping = ({ id, name, units, readonly = false, format, isRowStruck, values }) => ({
  id,
  context: {
    id,
    name,
    units,
    format,
    isRowStruck,
  },
  readonly,
  values,
});

export class VstCoreTable extends Requester(LitElement) {
  static get properties() {
    return {
      _isCategoricalConfirmationDialogOpen: { state: true },
      disabled: { type: Boolean, reflect: true },
      scrollDisabled: { type: Boolean, reflect: true },
      interactionsDisabled: { type: Boolean, reflect: true },
      cellWidth: { type: Number, reflect: true },
      examinePinIndex: { type: Number },
      dataSets: { type: Array, reflect: true },
      hidden: { type: Boolean },
      showColumnOptions: { type: Boolean },
      columnOptionSettings: { type: Object },
      alphanumeric: { type: Boolean },
      extractSelectedWavelength: { type: Boolean },
    };
  }

  constructor() {
    super();
    /** @type import('@common/services/dataworld/DataWorld.js').DataWorld */
    this.$dataWorld = null;
    this._isCategoricalConfirmationDialogOpen = false;
    this._cachedUserChanges = null;
    this._userInputChangeResults = null;
    this.disabled = false;
    this.scrollDisabled = false;
    this.interactionsDisabled = false;
    this.cellWidth = 6.25;
    this.dataSets = [{}];
    this.columnOptionSettings = {};
    this.alphanumeric = false;
    this.extractSelectedWavelength = false;
    this._suppressUpdates = false;
    this._cachedColumnIds = new Set();
  }

  updated(changedProperties) {
    changedProperties.forEach((oldValue, propName) => {
      if (propName === 'disabled') {
        this._disabledChanged(this.disabled);
      } else if (propName === 'scrollDisabled') {
        this._scrollDisabledChanged(this.scrollDisabled);
      } else if (propName === 'interactionsDisabled') {
        this._interactionsDisabledChanged(this.interactionsDisabled);
      } else if (propName === 'examinePinIndex') {
        this.selectRow(this.examinePinIndex);
      } else if (propName === 'hidden') {
        if (!this.hidden) {
          requestAnimationFrame(() => {
            this.resizeGrid();
            this.refreshHeaders();
          });
        }
      }
    });
  }

  async firstUpdated() {
    [this.$dataWorld, this.$popoverManager, this.$sensorWorld, this.$toast] = this.requestServices([
      'dataWorld',
      'popoverManager',
      'sensorWorld',
      'toast',
    ]);
    this.newDataSets = {};
    this.eventBinder = new EventBinder();

    this.eventBinder.bindListeners({
      source: this.$dataWorld,
      target: this,
      eventMap: {
        'data-set-added': 'onDataWorldDataSetAdded',
        'column-added': 'onDataWorldColumnAdded',
        'dataset-name-changed': 'onDataWorldDataSetNameChanged',
        'column-removed': 'onDataWorldColumnRemoved',
        'column-format-string-changed': 'onDataWorldColumnFormatStringChanged',
        'column-group-updated': 'onDataWorldColumnGroupUpdated',
        'column-strikethrough-changed': 'onDataWorldColumnStrikethroughChanged',
        'column-values-changed': 'onDataWorldColumnValuesChanged',
        'collection-triggered': 'onDataWorldCollectionTriggered',
        'collection-stopped': 'onDataWorldCollectionStopped',
        'session-ended': 'onDataWorldSessionEnded',
        'rpc-time-warning': '_onRPCTimeWarning',
      },
    });

    const gridEl = this.shadowRoot.querySelector('#placeholder');

    const registerTapOverride = (el, fn) => {
      el.addEventListener('click', () => {
        setTimeout(fn);
      });

      el.addEventListener('touchstart', () => {
        setTimeout(fn);
      });

      const stopPropagatingEvents = [
        'grid-mouse-select-start',
        'grid-touch-select-start',
        'grid-touch-select',
      ];

      stopPropagatingEvents.forEach(evt => {
        this.eventBinder.bind(el, evt, e => e.stopPropagation());
      });
    };

    // this is where we get the grid into core-table
    this.grid = createDataSetGrid({
      gridEl,
      padding: 0.3125,
      borderSize: 0.0625,
      sidebarWidth: 3.75,
      scrollerSize: 0.9375,
      itemHeight: 2,
      itemWidth: this.cellWidth,
      allowsAlphanumeric: this.alphanumeric,

      /**
       * Object containing methods for adapting the grid to app-specific data types.
       */
      cellResolver: {
        /**
         * Transforms text entered by user (typing in cell OR pasting) into a value suitable for storing in the grid.
         * @returns { Object } describing the success or failure and computed value (see `completCellResolver.js` in vst-grid)
         */
        textToValue: (text, makeParseResult) => {
          const trimmedText = text.trim();
          if (trimmedText.length > 0) {
            // First parse value to number.
            const attemptedValue = Number(parseFloatLocale(trimmedText));
            // Return various results based on whether the text could be parsed.
            if (!isLocaleRationalNumber(trimmedText))
              return this.alphanumeric
                ? makeParseResult('alphanumeric', trimmedText)
                : makeParseResult('failure');
            return makeParseResult('success', attemptedValue);
          }
          return makeParseResult('empty');
        },
        /**
         * Transforms a raw value stored in the grid to a String value suitable for displaying in the table.
         */
        valueToText: (value, column, isEditing) => {
          if (isEditing || !column.context.format) {
            return String(value);
          }
          return column.context.format(value);
        },
        /**
         * Predicate for what constitutes an empty value (in terms of grid), true if empty, false if not.
         */
        isEmpty: Column.isValueEmpty,
      },

      /**
       * User input change.
       * @typedef {Object} UserInputChange
       * @property {Object} column
       * @property {number} rowIndex
       * @property {(number|string)} value
       */

      /**
       * @param {Array.<UserInputChange>} changes -
       */
      onUserInputChange: async changes => {
        const { $dataWorld } = this;
        let hasDataTypeChangedToText = false;

        /**
         * Result of user input changes.
         * @typedef {Object} UserInputChangeResult
         * @property {string} id
         * @property {Column} column
         * @property {Array.<(number|string)>} values
         * @property {Array.<number>} changedRows
         */

        /**
         * Results of user input changes.
         * @type {Array.<UserInputChangeResult>}
         */
        const results = changes.reduce((result, current) => {
          /* pull the column id, row index, and value from 'current' */
          const {
            column: { id },
            rowIndex,
            value,
          } = current;
          let columnRows = result[id]; /* sets columnRows to hold id, column, values, changedRows */
          const dwColumn = $dataWorld.getColumnById(id);

          /* update flag for changed data type if it was numeric but the user
           * has entered a non-numeric character (i.e. not a digit, '.', ',', or '-') */
          if (
            dwColumn.dataType === 'numeric' &&
            value !== undefined &&
            !isLocaleRationalNumber(value)
          ) {
            hasDataTypeChangedToText = true;
          }

          /* if there are no rows... */
          if (!columnRows) {
            /* ... add it as an object to the result Object, with an empty changedRows */
            // eslint-disable-next-line no-multi-assign
            columnRows = result[id] = {
              id,
              column: dwColumn,
              values: [...dwColumn.values],
              changedRows: [],
            };
          }

          const { values, changedRows } = columnRows;
          values[rowIndex] = value;
          changedRows.push(rowIndex);

          return result;
        }, {});

        // handle user entering text when dataType is numeric
        if (hasDataTypeChangedToText) {
          await import(
            '@components/vst-ui-categorical-confirmation/vst-ui-categorical-confirmation.js'
          );

          this._isCategoricalConfirmationDialogOpen = true;
          this._userInputChangeResults = results;
          this._cachedUserChanges = changes;
        } else {
          this._updateDataWorldColumns(results);
        }
      },

      onUiSelection: selection => {
        if (
          selection.allColumns &&
          !selection.allRows &&
          selection.rows.start === selection.rows.end
        ) {
          this.dispatchEvent(
            new CustomEvent('row-clicked', {
              detail: { rowIndex: selection.rows.start },
            }),
          );
        }
      },

      onUiScroll: () => {
        this.autoScrollOnCollection = false;
      },

      onShowSelectContextMenu: async ({ clipboard, cellEl }) => {
        const { $dataWorld, $popoverManager } = this;
        const { deletable, pasteable } = clipboard;
        const selection = this.grid.getSelection();
        /** @returns {string[]} data set ID */
        function getDataSetIdsForSelection() {
          if (selection.allColumns && selection.allDataSets) {
            return $dataWorld.getDataSets().map(dataSet => dataSet.id);
          }
          return [selection.columns[0].dataSet.id];
        }
        /**
         * @param {object} params
         * @param {boolean} params.allRows whether to get range for all rows
         * @returns {Object} hash with start and count
         */
        function getStrikethroughRangeForSelection({ allRows } = {}) {
          if (selection.allRows || allRows) {
            const count = getDataSetIdsForSelection().reduce((previousCount, dataSetId) => {
              return Math.max(
                previousCount,
                ...$dataWorld.getColumnsForSet(dataSetId)?.map(column => column.values.length),
              );
            }, 0);
            return {
              start: 0,
              count,
            };
          }
          return {
            start: selection.rows.start,
            count: selection.rows.end - selection.rows.start + 1,
          };
        }
        const hasAuxDataSets =
          $dataWorld.dataSets.filter(
            dataSet => dataSet.type === DataSetType.FFT || dataSet.type === DataSetType.HISTOGRAM,
          ).length > 0;
        const showStrikethrough = !hasAuxDataSets && !['IA', 'SA'].includes(APP_ID);
        const showRestoreAll =
          !hasAuxDataSets &&
          getDataSetIdsForSelection().some(dataSetId =>
            $dataWorld.checkHasStruckRowsForDataSet(dataSetId),
          );
        let completeWorkflow;

        clipboard.onClose(() => {
          completeWorkflow();
        });
        await import('@components/vst-core-clipboard/vst-core-clipboard.js');
        $popoverManager
          // TODO (@mossymaker): rename to vst-ui-context-menu
          .presentPopover('vst-core-clipboard', {
            anchor: cellEl,
            orientation: 'top',
            properties: {
              deletable,
              pasteable,
              showStrikethrough,
              showRestoreAll,
              onCut: () => {
                clipboard.cut();
              },
              onCopy: () => {
                clipboard.copy();
              },
              onPaste: () => {
                clipboard.paste(() => {
                  this.$toast = this.requestService('toast');
                  this.$toast.makeToast(
                    getText(
                      'Paste action is unavailable using the edit menu. Use CTRL-V or CMD-V to paste.',
                    ),
                    {
                      duration: 5000,
                    },
                  );
                });
              },
              onStrikethrough: async () => {
                const { start, count } = getStrikethroughRangeForSelection();
                await Promise.all(
                  getDataSetIdsForSelection().map(dataSetId =>
                    this.$dataWorld.strikeRows(dataSetId, start, count, true),
                  ),
                );
                completeWorkflow();
              },
              onRestoreAll: async () => {
                const { start, count } = getStrikethroughRangeForSelection({ allRows: true });
                await Promise.all(
                  getDataSetIdsForSelection().map(dataSetId =>
                    this.$dataWorld.unstrikeRows(dataSetId, start, count),
                  ),
                );
                completeWorkflow();
              },
            },
            preventCancel: true,
            outsideTapEvents: {
              touchend: 'grid-pre-touchend',
              click: 'grid-pre-click',
            },
            events: ctx => {
              // eslint-disable-next-line prefer-destructuring
              completeWorkflow = ctx.completeWorkflow;
            },
          })
          .then(result => {
            if (result && result.cancelled) {
              setTimeout(() => {
                clipboard.close();
              });
            }
          });
      },

      renderDataSetContext: ({ headerCellEl, dataSet }) => {
        const nameSpanEl = document.createElement('span');
        nameSpanEl.classList.add('grid_dataset_header_cell_title');

        const optionsButtonEl = document.createElement('button');
        optionsButtonEl.classList.add('grid_header_cell_btn');
        optionsButtonEl.id = `grid_header_dataset_cell_btn_${dataSet.id}`;

        const optionsButtonIconEl = document.createElement('vst-ui-icon');
        optionsButtonIconEl.classList.add('grid_header_cell_btn_icon');
        optionsButtonIconEl.icon = overflow;

        import('@components/vst-ui-tooltip/vst-ui-tooltip.js');
        const optionsTooltipEl = document.createElement('vst-ui-tooltip');
        optionsTooltipEl.content = getText('Data Set Options');
        optionsTooltipEl.for = `#grid_header_dataset_cell_btn_${dataSet.id}`;
        optionsTooltipEl.placement = 'bottom';

        optionsButtonEl.appendChild(optionsButtonIconEl);
        headerCellEl.appendChild(nameSpanEl);
        headerCellEl.appendChild(optionsButtonEl);
        this.shadowRoot.appendChild(optionsTooltipEl); // Adding it to root node as workaround to a paper-tooltip + scrolled container element bug. https://github.com/PolymerElements/paper-tooltip/issues/24

        registerTapOverride(optionsButtonEl, () => {
          const { $dataWorld, $popoverManager } = this;

          const { name } = $dataWorld.getDataSetByID(dataSet.id);

          const renameDataSetSelected = async () => {
            await import('@components/vst-core-rename/vst-core-rename.js');
            $popoverManager.presentDialog('vst-core-rename', {
              title: getText('Rename Data Set', 'general', 'Dialog title'),
              properties: {
                name,
                nameDescription: getText('Data Set Name'),
                nameMaxLength: 100,
                nameMaxLengthError: sprintf(
                  getText('Data set name has %s character limit.'),
                  String(100),
                ),
              },
              events: ({ component, completeWorkflow, cancelWorkflow }) => ({
                save: () => {
                  $dataWorld.updateDataSet(dataSet.id, {
                    name: component.name,
                  });
                  completeWorkflow();
                },
                cancel: () => {
                  cancelWorkflow();
                },
              }),
            });
          };

          const addNewDataSetSelected = () => $dataWorld.createNewDataSet();

          $popoverManager.presentPopoverList({
            anchor: optionsButtonEl,
            orientation: 'bottom',
            items: [
              {
                id: 'rename_data_set',
                title: getText('Rename Data Set', 'general', 'Column options popover item'),
                selectAction: renameDataSetSelected,
              },
              {
                id: 'add_new_data_set',
                title: getText('Add New Data Set'),
                include: $dataWorld.sessionType === 'ManualEntry',
                selectAction: addNewDataSetSelected,
              },
              {
                id: 'delete_data_set',
                title: getText('Delete Data Set', 'general', 'Column options popover item'),
                selectAction: promptDeleteDataSet.bind(this, $dataWorld, {
                  id: dataSet.id,
                  name,
                }),
                disabled: $dataWorld.sessionType === 'DataShare',
              },
            ],
          });
        });

        return context => {
          nameSpanEl.innerText = context.name;
        };
      },

      renderColumnContext: ({ headerCellEl, column }) => {
        const nameContainerEl = document.createElement('div');
        nameContainerEl.classList.add('grid_header_cell_title', 'grid-column-header');

        const nameSpanEl = document.createElement('span');
        nameSpanEl.classList.add('grid_header_cell_name');

        const unitsEl = document.createElement('span');
        unitsEl.classList.add('grid-header-units');

        const optionsButtonEl = document.createElement('button');
        optionsButtonEl.id = `grid_header_column_cell_btn_${column.id}`;
        optionsButtonEl.classList.add('grid_header_cell_btn');

        const optionsButtonIconEl = document.createElement('vst-ui-icon');
        optionsButtonIconEl.classList.add('grid_header_cell_btn_icon');
        optionsButtonIconEl.icon = overflow;

        const optionsTooltipEl = document.createElement('vst-ui-tooltip');
        optionsTooltipEl.content = getText('Column Options');
        optionsTooltipEl.for = `#grid_header_column_cell_btn_${column.id}`;
        optionsTooltipEl.placement = 'bottom';

        optionsButtonEl.appendChild(optionsButtonIconEl);
        headerCellEl.appendChild(nameContainerEl);
        nameContainerEl.appendChild(nameSpanEl);
        nameContainerEl.appendChild(unitsEl);
        headerCellEl.appendChild(optionsButtonEl);
        this.shadowRoot.appendChild(optionsTooltipEl); // Adding it to root node as workaround to a paper-tooltip + scrolled container element bug. https://github.com/PolymerElements/paper-tooltip/issues/24

        registerTapOverride(optionsButtonEl, () => {
          const { $dataWorld, $popoverManager } = this;

          const { group } = $dataWorld.getColumnById(column.id);
          const groupId = group.id;

          const columnOptionsSelected = () => {
            const columnOptionSettings = {
              sourceGroupId: groupId,
              sourceColumnId: column.id,
              columnAction: 'edit',
              title: getText('Column Options'),
              onColumnTraceUpdated: async (columnId, color, symbol) => {
                await this.$dataWorld.updateColumnAppearance(columnId, color, symbol);
              },
            };
            this.dispatchEvent(
              new CustomEvent('open-dialog', {
                bubble: true,
                composed: true,
                detail: {
                  dialog: 'column_options',
                  params: {
                    columnOptionSettings,
                  },
                },
              }),
            );
            this.requestUpdate();
          };

          const addManualColumnSelected = () => {
            const columnOptionSettings = {
              sourceGroupId: groupId,
              sourceColumnId: column.id,
              columnAction: 'add',
              columnType: 'manual',
              title: getText('Add Manual Column'),
            };
            this.dispatchEvent(
              new CustomEvent('open-dialog', {
                bubble: true,
                composed: true,
                detail: {
                  dialog: 'column_options',
                  params: {
                    columnOptionSettings,
                  },
                },
              }),
            );
            this.requestUpdate();
          };

          const addCalcColumnSelected = () => {
            const columnOptionSettings = {
              sourceGroupId: groupId,
              sourceColumnId: column.id,
              columnAction: 'add',
              columnType: 'calc',
              title: getText('Add Calculated Column'),
              onCalcColumnCreated: calcColumn =>
                this.dispatchEvent(
                  new CustomEvent('calc-column-created', {
                    composed: true,
                    bubbles: true,
                    detail: calcColumn,
                  }),
                ),
            };
            this.dispatchEvent(
              new CustomEvent('open-dialog', {
                bubble: true,
                composed: true,
                detail: {
                  dialog: 'column_options',
                  params: {
                    columnOptionSettings,
                  },
                },
              }),
            );
            this.requestUpdate();
          };

          $popoverManager.presentPopoverList({
            anchor: optionsButtonEl,
            orientation: 'bottom',
            items: [
              {
                id: 'column_options',
                title: getText('Column Options'),
                selectAction: columnOptionsSelected,
              },
              {
                id: 'add_manual_column',
                title: getText('Add Manual Column'),
                selectAction: addManualColumnSelected,
              },
              {
                id: 'add_calc_column',
                title: getText('Add Calculated Column'),
                selectAction: addCalcColumnSelected,
              },
              {
                id: 'delete_column',
                title: getText('Delete Column'),
                include: group.deletable,
                selectAction: promptDeleteColumn.bind(this, $dataWorld, group),
              },
            ],
          });
        });

        const extractSelectedWavelengthFromName = context => {
          const match = context.name.match(/@ \d{3} nm$/);
          if (match) {
            return {
              name: context.name.slice(0, match.index).trim(),
              selectedWavelength: ` ${match[0]}`,
            };
          }
          return context;
        };

        return context => {
          const { units } = context;
          const { name, selectedWavelength = '' } = this.extractSelectedWavelength
            ? extractSelectedWavelengthFromName(context)
            : context;

          nameSpanEl.textContent = name;

          if (units) {
            unitsEl.textContent = `${selectedWavelength} (${units})`;
          } else if (selectedWavelength) {
            unitsEl.textContent = `${selectedWavelength}`;
          } else {
            unitsEl.textContent = '';
          }
        };
      },
    });

    this.grid.disabled = this.disabled;
    this.grid.scrollDisabled = this.scrollDisabled;
    this.grid.interactionsDisabled = this.interactionsDisabled;

    await this.updateComplete;
    this.resizeGrid();
  }

  _updateDataWorldColumns(results) {
    Object.values(results).forEach(({ id, values, changedRows }) => {
      this.$dataWorld.updateColumnValues(id, values, changedRows, true); // values set HERE
    });
    // set cached properties back to null
    this._cachedUserChanges = null;
    this._userInputChangeResults = null;
  }

  _updateCellFocus() {
    this.shadowRoot.querySelector('.selected')?.focus();
  }

  _resetCellFocus(changes) {
    const { column, rowIndex } = changes[0];

    // revert cell to editing mode (deepEdit adds focus around input box)
    this.grid.deepEditCell(column, rowIndex);
    this.shadowRoot.querySelector('.deepedit').value = '';
    // set cached properties back to null
    this._cachedUserChanges = null;
    this._userInputChangeResults = null;
  }

  onDataWorldDataSetAdded(dataset) {
    this.newDataSets[dataset.id] = true;
  }

  onDataWorldColumnAdded(column) {
    if (column.special) {
      return;
    }

    if (this._noUpdates) {
      this._cachedColumnIds.add(column.id);
      return;
    }

    const { $dataWorld } = this;
    const { collecting, autoScrollOnCollection } = this;

    if (!this.newDataSets[column.setId] && !this.hasDataSet(column.setId)) {
      this.newDataSets[column.setId] = true;
    }

    const format = value =>
      this.alphanumeric && typeof value === 'string'
        ? String(value)
        : column.group.getFormattedValue(value);
    const scrollToRow = collecting && autoScrollOnCollection;

    // new dataset condition
    if (this.newDataSets[column.setId]) {
      const dataSet = $dataWorld.getDataSetByID(column.setId);
      const dataSetColumns = $dataWorld.getColumnsForSet(column.setId);
      const columns = dataSetColumns.map(col => ({
        id: col.id,
        name: col.group.name,
        units: col.group.units,
        values: col.values,
        readonly: !col.editable,
        isRowStruck: col.isRowStruck.bind(col),
        format,
      }));

      this.addDataSet({
        id: dataSet.id,
        name: dataSet.name,
        columns,
        scrollToRow,
      });
      delete this.newDataSets[column.setId];

      // new column position
    } else {
      const dataSetColumns = $dataWorld.getOrderedColumnsForSet(column.setId);
      const position = dataSetColumns.findIndex(col => col.id === column.id);

      this.addColumn({
        id: column.id,
        name: column.group.name,
        units: column.group.units,
        position,
        values: column.values,
        readonly: !column.editable,
        isRowStruck: column.isRowStruck.bind(column),
        format,
        dataSetId: column.setId,
        scrollToRow,
      });
    }
  }

  onDataWorldDataSetNameChanged(dataSet) {
    // don't call updateDataSetName on the table for dataSets that aren't shown on the table
    if (dataSet.type === 'regular') {
      this.updateDataSetName({
        id: dataSet.id,
        name: dataSet.name,
      });
    }
  }

  onDataWorldColumnRemoved(column) {
    if (column.special) {
      return;
    }

    const { _cachedColumnIds } = this;
    if (_cachedColumnIds.has(column.id)) {
      _cachedColumnIds.delete(column.id);
      if (this._noUpdates) return;
    }

    this.removeColumn({ id: column.id, dataSetId: column.setId });
  }

  onDataWorldColumnFormatStringChanged(column) {
    if (column.special) {
      return;
    }
    if (this._noUpdates) return;

    const format = value =>
      this.alphanumeric && typeof value === 'string'
        ? String(value)
        : column.group.getFormattedValue(value);
    this.updateColumnFormatter({
      id: column.id,
      dataSetId: column.setId,
      format,
    });
  }

  onDataWorldColumnGroupUpdated(group) {
    if (this._noUpdates) return;

    group.columns.forEach(col =>
      this.updateColumnName({
        id: col.id,
        dataSetId: col.setId,
        name: col.group.name,
        units: col.group.units,
      }),
    );
  }

  onDataWorldColumnValuesChanged(column, values) {
    if (column.special) {
      return;
    }

    if (this._noUpdates) {
      this._cachedColumnIds.add(column.id);
      return;
    }

    const dwColumn = this.$dataWorld.getColumnById(column.id);
    this.updateColumnValues({
      id: column.id,
      dataSetId: column.setId,
      values,
      scrollToRow: this.collecting && this.autoScrollOnCollection,
    });

    dwColumn.group.checkAutomaticPrecision();
  }

  onDataWorldCollectionTriggered() {
    this.collecting = true;
    this.autoScrollOnCollection = true;
    this.interactionsDisabled = true;
  }

  onDataWorldCollectionStopped() {
    this.collecting = false;
    this.interactionsDisabled = false;
    this._uncacheUpdates();
  }

  onDataWorldColumnStrikethroughChanged(column) {
    const { grid } = this;
    const gridColumn = grid(column.dataSet.id)(column.id);
    gridColumn.refresh();
  }

  onDataWorldSessionEnded() {
    this.clearGrid();
    this._suppressUpdates = false;
    this._cachedColumnIds.clear();
  }

  /**
   * @returns {boolean} true if we should squelch/cache updates, false if we
   * should handle all updates.
   */
  get _noUpdates() {
    return this.collecting && this._suppressUpdates;
  }

  /**
   * Handles data world RPC time warnings. We look for specific RPC calls above
   * a certain duration, and adjust our performance accordingly.
   * @param {object} params
   * @param {string} params.rpcId RPC call which has gone over threshold.
   * @param {number} params.duration time in MS it took for the RPC call to
   * complete.
   */
  _onRPCTimeWarning(params) {
    const { duration, rpcId } = params;
    const PERFORMACE_THRESHOLD = 100;
    // We are interested in the raw RPC calls for adding columns (typically at
    // the start of collection) and for updating data group properties. These
    // calls cause an inordinate amount of layout thrashing which becomes
    // untennable with larger data sizes.
    if (
      !this._suppressUpdates &&
      duration >= PERFORMACE_THRESHOLD &&
      (rpcId === 'dw:data-column-added' || rpcId === 'dw:data-group-properties-changed')
    ) {
      // prettier-ignore
      console.warn(`RPC method ${rpcId} took ${duration} ms. Table will suppress updates during data collection.`);
      this._suppressUpdates = true;
    }
  }

  /**
   * Releases any cached column-adds, column-value-updates, etc. that were
   * cached while the _suppressUpdates property was true.
   */
  _uncacheUpdates() {
    const { _cachedColumnIds } = this;
    const { $dataWorld } = this;

    // Apparently we only need to add 1 column, and it seems smart enough to
    // then add the data set, and all the OTHER columns.
    const [colId] = _cachedColumnIds;
    const col = $dataWorld.getColumnById(colId);
    try {
      if (col) this.onDataWorldColumnAdded(col);
    } catch (err) {
      console.warn(err);
    }

    _cachedColumnIds.clear();
  }

  addDataSet({ id, name, columns, scrollToRow = true }) {
    const { grid, autoScrollDisabled } = this;

    if (columns.length === 0) {
      throw Error('need at least on column with dataset');
    }

    const columnParams = columns.map(_getColumnMapping);

    if (scrollToRow && !autoScrollDisabled) {
      grid.scrollToRow(0, true);
    }

    // here's one location where data gets added
    grid
      .addDataSet({
        id,
        context: { name },
        columns: columnParams,
      })
      .scrollTo();

    this._disableMoreBtns();
  }

  hasDataSet(id) {
    const { grid } = this;
    return !!grid.getDataSet(id);
  }

  addColumn(columnInfo) {
    const { grid, autoScrollDisabled } = this;
    const { dataSetId, scrollToRow = true, position } = columnInfo;
    const dataSet = grid(dataSetId);
    const columnParam = _getColumnMapping(columnInfo);

    if (scrollToRow && !autoScrollDisabled) {
      grid.scrollToRow(0, true);
    }

    if (position >= dataSet.colspan) {
      dataSet.appendColumns([columnParam])[0].scrollTo();
    } else {
      dataSet.insertColumns(position, [columnParam])[0].scrollTo();
    }

    this._disableMoreBtns();
  }

  scrollToColumn(columnId) {
    const column = this.$dataWorld.getColumnById(columnId);
    const grid = this.grid(column.dataSet.id);
    grid.getColumnById(columnId).scrollTo();
  }

  calcColumnCreated(calcColumnGroup) {
    this.dispatchEvent(new CustomEvent('calc-column-created', { detail: calcColumnGroup }));
  }

  updateColumnName({ id, dataSetId, name, units }) {
    const { grid } = this;
    const dataSet = grid(dataSetId);
    if (dataSet) {
      const column = dataSet(id);
      const { context } = column;
      context.name = name;
      context.units = units;
      column.context = context;
    }
  }

  updateDataSetName({ id, name }) {
    const { grid } = this;
    const dataSet = grid(id);
    const { context } = dataSet;
    context.name = name;
    dataSet.context = context;
  }

  updateColumnFormatter({ id, dataSetId, format }) {
    const { grid } = this;
    const column = grid(dataSetId)(id);
    const { context } = column;
    context.format = format;
    column.refresh();
  }

  updateColumnValues({ id, dataSetId, values, scrollToRow = true }) {
    const { grid, disableAutoScroll } = this;
    grid(dataSetId)(id).setValues(values);
    if (!disableAutoScroll && scrollToRow) {
      const index = values.length > 0 ? values.length - 1 : 0;
      grid.scrollToRow(index, true);
    }
  }

  refreshHeaders() {
    const { grid } = this;
    grid.refreshHeaders();
  }

  removeColumn({ id, dataSetId }) {
    const { grid } = this;
    const dataSet = grid(dataSetId);
    const column = dataSet(id);

    if (dataSet.colspan > 1) {
      column.remove();
    } else {
      dataSet.remove();
    }
  }

  clearGrid() {
    const { grid } = this;
    grid.clear();
  }

  resizeGrid() {
    const { grid } = this;
    grid.resize();
  }

  selectRow(rowIndex) {
    const { grid } = this;

    if (grid && rowIndex !== undefined) {
      grid.select({ rowIndex });
    }
  }

  clearInterations() {
    const { grid } = this;
    grid.clearInterations();
  }

  _disabledChanged(value) {
    const { grid } = this;
    if (grid) {
      grid.disabled = value;
    }
  }

  _scrollDisabledChanged(value) {
    const { grid } = this;
    if (grid) {
      grid.scrollDisabled = value;
    }
  }

  _interactionsDisabledChanged(value) {
    const { grid } = this;
    if (grid) {
      grid.interactionsDisabled = value;
    }

    this._disableMoreBtns();
  }

  _disableMoreBtns() {
    const moreOptionsBtnEls = Array.from(this.shadowRoot.querySelectorAll('.grid_header_cell_btn'));
    moreOptionsBtnEls.forEach(btnEl => {
      btnEl.disabled = this.interactionsDisabled;
    });
  }

  // event handlers

  gridTapped() {
    /* if app is in "undefined" state (DataCollection mode but no sensor connected),
     * switch to Manual mode when user begins to use the grid */
    if (
      APP_ID === 'GA' &&
      this.$dataWorld.sessionType === 'DataCollection' &&
      this.$dataWorld.isSessionEmpty &&
      this.$sensorWorld.sensors.length === 0 &&
      !isPrivilegedIframe()
    ) {
      this.$dataWorld.resetSession('ManualEntry');
      this.$toast.makeToast(getText('Mode changed to Manual Entry.'), { duration: 5000 });
    }
    this.$popoverManager.closeAll();
    this.autoScrollOnCollection = true;
  }

  static get styles() {
    return [globalStyles, createDataSetGridStyles, vstCoreTableStyles];
  }

  renderDialogs() {
    return html` <vst-ui-dialog
      @dialog-close="${() => {
        this._isCategoricalConfirmationDialogOpen = false;
      }}"
      id="categorical-confirmation"
      ?open="${this._isCategoricalConfirmationDialogOpen}"
    >
      <h2 slot="header">${getText('Entering Categorical Mode')}</h2>
      <vst-ui-categorical-confirmation
        @categorical-canceled="${() => {
          this._resetCellFocus(this._cachedUserChanges);
          this._isCategoricalConfirmationDialogOpen = false;
        }}"
        @categorical-confirmed="${() => {
          this._updateDataWorldColumns(this._userInputChangeResults);
          this._updateCellFocus();
          this._isCategoricalConfirmationDialogOpen = false;
        }}"
        slot="content"
      ></vst-ui-categorical-confirmation>
    </vst-ui-dialog>`;
  }

  render() {
    return html`
      <div
        class="grid_container"
        tabindex="-1"
        id="placeholder"
        @click="${this.gridTapped}"
        @touchstart="${this.gridTapped}"
        @keydown="${e => (e.keycode === 13 ? this.gridTapped : '')}"
      >
        <div class="grid_header">
          <div class="grid_header_sidebar">
            <div class="grid_header_sidebar_block">&nbsp;</div>
          </div>
          <div class="grid_header_viewport_container">
            <div class="grid_header_viewport"></div>
          </div>
        </div>
        <div class="grid_body_viewport">
          <div class="grid_body_sidebar"></div>
          <div class="grid_body_container">
            <div class="grid_body"></div>
          </div>
        </div>
      </div>

      ${this.renderDialogs()}
    `;
  }
}
customElements.define('vst-core-table', VstCoreTable);

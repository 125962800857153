import { LitElement, html } from 'lit';
import { unsafeHTML } from 'lit/directives/unsafe-html.js';
import { getText } from '@utils/i18n.js';
import { globalStyles } from '@styles/vst-style-global.css.js';

import vstUiChangeLogStyles from './vst-ui-changelog.css.js';

class VstUiChangelog extends LitElement {
  static get properties() {
    return {
      releases: {
        type: Array,
      },
      showAll: {
        type: Boolean,
      },
    };
  }

  constructor() {
    super();
    this.releases = [];
    this.showAll = false;
  }

  toggleShowAll() {
    this.showAll = !this.showAll;
  }

  static get styles() {
    return [globalStyles, vstUiChangeLogStyles];
  }

  render() {
    return html`
      <div id="content" class="content">
        ${this.releases.map(
          (release, index) =>
            html`
              <section class="release" ?hidden="${index > 0 && !this.showAll}">
                <h3 class="heading" size="l" id="version-${release.version}">
                  Version ${release.version}
                </h3>
                <p class="caption" variant="light" margin="block-end-m">
                  Released ${release.releaseDate}
                </p>
                <ul class="release__change-list">
                  ${release.changes.map(
                    change => html`
                      <li class="release__change">
                        <b class="label label--${change.type}" margin="block-end-xs"
                          >${change.type}</b
                        >
                        <p>${unsafeHTML(change.description)}</p>
                        <div class="details">
                          <ul>
                            ${change?.details?.map(detail => html` <li><p>${detail}</p></li> `)}
                          </ul>
                        </div>
                      </li>
                    `,
                  )}
                </ul>
              </section>
            `,
        )}
        <button
          class="btn"
          variant="outline"
          id="show_all_button"
          margin="block-start-auto"
          ?hidden="${this.releases.length < 2}"
          @click="${this.toggleShowAll}"
        >
          ${this.showAll ? getText('Show Less') : getText('Show All Releases')}
        </button>
      </div>
    `;
  }
}

customElements.define('vst-ui-changelog', VstUiChangelog);

import { LitElement, html } from 'lit';
import { getText } from '@utils/i18n.js';
import * as Gestures from '@polymer/polymer/lib/utils/gestures.js';
import { trash } from '@components/vst-ui-icon/index.js';
import { autorun } from 'mobx';
import { MobxReactionUpdate } from '@adobe/lit-mobx/lib/mixin.js';
import { clamp } from 'lodash-es';

import { globalStyles } from '@styles/vst-style-global.css.js';
import vstCoreGraphAnnotationStyles from './vst-ui-graph-annotation.css.js';

import '@components/vst-ui-form/vst-ui-form.js';
import '@components/vst-ui-icon/vst-ui-icon.js';

const PROPORTIONAL_CONST = 1000;

class VstUiGraphAnnotation extends MobxReactionUpdate(LitElement) {
  static get properties() {
    return {
      _text: { state: true },
      _hasMoved: { state: true },
      annotation: { type: Object },
      bounds: { type: Object },
      graphUdmId: { type: Number },
    };
  }

  static get styles() {
    return [globalStyles, vstCoreGraphAnnotationStyles];
  }

  constructor() {
    super();
    this._hasMoved = false;
    this._text = '';
    this.annotation = null;
    this.bounds = null;
  }

  get pixelInset() {
    const { x: proportionalX, y: proportionalY } = this.annotation.getPositionOnGraph(
      this.graphUdmId,
    );
    return {
      x: (proportionalX / PROPORTIONAL_CONST) * this.bounds.width,
      y: (proportionalY / PROPORTIONAL_CONST) * this.bounds.height,
    };
  }

  get textElement() {
    return this.renderRoot.querySelector('#annotation_input');
  }

  connectedCallback() {
    super.connectedCallback();
    document.addEventListener('click', this._handleOutsideClick);
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    document.removeEventListener('click', this._handleOutsideClick);
  }

  firstUpdated() {
    const annotationEl = this.renderRoot.querySelector('.annotation');

    this._text = this.annotation.text;

    Gestures.addListener(annotationEl, 'tap', () => {
      this._edit();
    });
    Gestures.addListener(annotationEl, 'track', this._handleDrag.bind(this));
    Gestures.addListener(annotationEl, 'up', e => e.stopPropagation(e));

    this.addEventListener('keydown', e => {
      // Escape key
      if (e.keyCode === 27) {
        this._save();
      }
    });

    autorun(() => {
      // Toggle the editing boolean attribute
      if (this.annotation.editingGraphId === this.graphUdmId && this.graphUdmId !== 0) {
        this.setAttribute('editing', '');
        requestAnimationFrame(() => {
          // Focus the input when editing starts
          if (this.textElement) this.textElement.focus();
        });
      } else {
        this.removeAttribute('editing');
      }

      requestAnimationFrame(() => {
        this._dispatchPositionUpdated();
      });
    });

    autorun(() => {
      // Update the text state when it changes on the annotation, e.g. when
      // it's changed on another graph
      this._text = this.annotation.text;
    });
  }

  updated() {
    if (!this._hasMoved) {
      const { x, y } = this.pixelInset;
      this._setPosition(x, y);
    }
  }

  /**
   * Calculate position within the rectangle given in the `bounds` prop
   * @param {number} [xDelta] Change in x position
   * @param {number} [yDelta] Change in y position
   * @returns {{ x: number, y: number }}
   */
  _calculateBoundedPosition(xDelta = 0, yDelta = 0) {
    const { x, y } = this.pixelInset;
    const annotationRectangle = this.getBoundingClientRect();

    return {
      x: clamp(x + xDelta, 0, this.bounds.width - annotationRectangle.width),
      y: clamp(y + yDelta, 0, this.bounds.height - annotationRectangle.height),
    };
  }

  _dispatchPositionUpdated() {
    this.dispatchEvent(
      new CustomEvent('position-updated', {
        detail: { annotation: this.annotation },
      }),
    );
  }

  _edit() {
    this.annotation.setEditingOnGraph(this.graphUdmId);
    this.resize();
  }

  _handleDrag(e) {
    e.preventDefault();
    e.stopPropagation();

    if (this.annotation.editingGraphId === this.graphUdmId) return;

    const { dx, dy, state } = e.detail;
    const { x: xTranslation, y: yTranslation } = this._calculateBoundedPosition(dx, dy);

    if (state === 'start') {
      this.style.willChange = 'transform';
      this._hasMoved = true;
    } else if (state === 'track') {
      this._setPosition(xTranslation, yTranslation);
      this._dispatchPositionUpdated();
    } else if (state === 'end') {
      this.style.removeProperty('will-change');
      this.annotation.setPositionOnGraph(this.graphUdmId, {
        x: (xTranslation / this.bounds.width) * PROPORTIONAL_CONST,
        y: (yTranslation / this.bounds.height) * PROPORTIONAL_CONST,
      });
    }
  }

  _handleOutsideClick = e => {
    if (this.annotation.editingGraphId === this.graphUdmId && !e.composedPath().includes(this))
      this._save();
  };

  _handleTextChange(e) {
    this._text = e.target.value;
  }

  _save() {
    if (this._text.length > 0) {
      this.annotation.setEditingOnGraph(0);
      this.annotation.setText(this._text);
    }
    this.resize();
  }

  _setPosition(dx, dy) {
    this.style.transform = `translate(${dx}px, ${dy}px)`;
  }

  deleteAnnotation() {
    this.dispatchEvent(new CustomEvent('annotation-deleted', { detail: this.annotation }));
  }

  resize() {
    const { x, y } = this._calculateBoundedPosition();
    this._setPosition(x, y);
    this._dispatchPositionUpdated();
  }

  render() {
    return html`
      <div
        class="annotation"
        id="annotation"
        @keyup="${({ key }) => {
          if (key === 'Enter') this._edit();
        }}"
      >
        <div class="annotation__static">${this.annotation.text}</div>
        <div class="annotation__overflow"></div>
        <vst-ui-form @submit="${this._save}">
          <form class="annotation__form" id="${this.id}">
            <div class="annotation__actions" id="annotation_actions">
              <button
                id="delete-annotation"
                class="btn"
                variant="icon"
                type="button"
                @click="${this.deleteAnnotation}"
                @keydown="${e => {
                  if (e.shiftKey && e.key === 'Tab') this._save();
                }}"
              >
                <vst-ui-icon class="annotation__actions-icon" .icon="${trash}"></vst-ui-icon>
              </button>
            </div>
            <input
              class="annotation__input"
              id="annotation_input"
              type="text"
              placeholder="${getText('Enter an annotation')}"
              autocomplete="off"
              autocorrect="off"
              autocapitalize="off"
              spellcheck="false"
              .value="${this.annotation.text}"
              @input="${this._handleTextChange}"
              @keyup="${e => {
                // Prevent submit from causing an _edit() call by way of the
                // keyup event on the top-level div
                if (e.key === 'Enter') e.stopPropagation();
              }}"
              @keydown="${e => {
                if (!e.shiftKey && e.key === 'Tab') this._save();
              }}"
            />
          </form>
        </vst-ui-form>
      </div>
    `;
  }
}

customElements.define('vst-ui-graph-annotation', VstUiGraphAnnotation);

/* global vstRequestDevice */
import { html, css } from 'lit';
import { createRef, ref } from 'lit/directives/ref.js';
import { MobxReactionUpdate } from '@adobe/lit-mobx/lib/mixin.js';
import { VseApp } from '@components/vse-app/vse-app.js';

import { connect } from 'pwa-helpers';
import platform from 'platform';
import { sprintf } from '@libs/sprintf.js';
import { throttle } from 'lodash-es';

import { file, overflow, gear, view } from '@icons';

import { Provider } from '@mixins/vst-core-provider-mixin.js';
import { DialogMediator } from '@mixins/vst-core-dialog-mediator-mixin.js';
import { ObservableProperties } from '@mixins/vst-observable-properties-mixin.js';
import { closeCommonDialogEvent } from '@utils/closeCommonDialogEvent.js';

import { initServices, addProgressCallback } from '@services/init-services.js';
import { applyNativeCompatibility } from '@services/adapters/native-compatibility/index.js';
import { keyboardEvents } from '@utils/keyboardEvents.js';
import { nextTick } from '@utils/nextTick.js';
import { computeGraphsAutoLayout } from '@utils/appAutoLayout.js';
import { getText } from '@utils/i18n.js';
import { promptConfirmNewSession } from '@utils/promptConfirmNewSession.js';
import { conditionalTemplate } from '@components/directives/conditionalTemplate.js';
import { getOpenableTypes } from '@utils/fileio-helpers.js';
import { autorun } from 'mobx';

import { globalStyles } from '@styles/vst-style-global.css.js';
import { customPropertyStyles } from '@styles/vst-style-custom-properties.css.js';

import { addColumnId } from '@common/stores/actions.js';

import { vstLayoutStore } from '@stores/vst-layout.store.js';
import { vstPresentationStore } from '@stores/vst-presentation.store.js';
import { graphStore } from './stores/graph.store.js';

import { resetSession, updateGraphsAutoLayout, updateIsSessionEmpty } from './redux/actions.js';

import { store } from './redux/store.js';

import { initSaServices } from './services/init-sa-services.js';

import changelog from './changelog.json';

import './sa-logo.js';
import './sa-welcome/sa-welcome.js';
import './sa-settings/sa-settings.js';

import './sa-about/sa-about.js';
import './sa-main-content/sa-main-content.js';
import './sa-wavelength-chooser/sa-wavelength-chooser.js';
import './sa-calibrate.js';
import '@components/vst-ui-dropdown/vst-ui-dropdown.js';
import '@components/vst-core-device-manager/vst-core-device-manager.js';
import '@components/vst-ui-content-layout-options/vst-ui-content-layout-options.js';
import '@components/vst-ui-dialog/vst-ui-dialog.js';
import '@components/vst-ui-dialog/vst-ui-dialog-manager.js';
import '@components/vst-style-tooltip/vst-style-tooltip.js';
import '@components/vst-ui-ewe/vst-ui-ewe.js';

/** @type {Array<{label:string, type:string}} */
const webUSBOptions = [{ label: getText('SpectroVis'), type: 'specUSB' }];

export class SaApp extends connect(store)(
  Provider(DialogMediator(MobxReactionUpdate(ObservableProperties(VseApp)))),
) {
  static get observableProperties() {
    return {
      colorMode: vstPresentationStore,
    };
  }

  static get properties() {
    return {
      _advModeCompletedCollections: { state: true },
      _experimentName: { state: true },
      _isCollecting: { state: true },
      _isUnsupportedBrowserMessageVisible: { state: true },
      _rightAxisGroupId: { state: true },
      _sessionReferenceRange: { state: true },
      _sessionReferenceTrace: { state: true },
      _sessionSpectrumInfo: { state: true },
      _showViewMenu: { state: true },
      _specIntegrationTime: { state: true },
      _specTemporalAveraging: { state: true },
      _specWavelengthSmoothing: { state: true },
      _useRightAxis: { state: true },
      $services: { type: Object },
      advancedModeEnabled: { type: Boolean },
      canCollect: { type: Boolean },
      connectedDevice: { type: Object },
      connectionStatusAttention: { type: Boolean },
      graphs: { type: Object },
      isChangingSettingsSpectrumModes: { type: Boolean },
      isImportedSession: { type: Boolean },
      isSavingSpecSettings: { type: Boolean }, // FIXME private state
      notify: { type: String },
      progress: { type: Number },
      sessionSpectrumMode: { type: String },
      showAbout: { type: Boolean },
      showCalibrate: { type: Boolean },
      showChangelog: { type: Boolean },
      showDeviceManagerDialog: { type: Boolean },
      showFileMenu: { type: Boolean },
      showOtherOptions: { type: Boolean },
      showSettings: { type: Boolean },
      showWavelengthSelectorDialog: { type: Boolean },
      showWelcomeDialog: { type: Boolean },
    };
  }

  /**
   * @returns {VseAppInfo} information about the app that we can refer to when
   * saving and opening files et al.
   * @override
   */
  // eslint-disable-next-line class-methods-use-this
  get appInfo() {
    return {
      appName: 'Vernier Spectral Analysis',
      fileExtension: '.smbl',
    };
  }

  constructor() {
    super();
    applyNativeCompatibility(window);

    this._advModeCompletedCollections = [];
    /**
     * Holds resolve, reject for _chooseDevice promise
     * @type {Object}
     */
    this._deviceMgrPromiseFns = null;
    this._experimentName = getText('Untitled');
    this._isCollecting = false;
    this._isUnsupportedBrowserMessageVisible = false;
    this._newRightColumnIds = [];
    this._rightAxisGroupId = 0;
    this._sessionReferenceRange = null;
    this._sessionReferenceTrace = null;
    this._sessionSpectrumInfo = null;
    this._showViewMenu = false;
    this._specIntegrationTime = 0; // FIXME move out of sa app
    this._specSettingsCache = {};
    this._specTemporalAveraging = 0; // FIXME move out of sa app
    this._specWavelengthSmoothing = 0; // FIXME move out of sa app
    this._splashScreenEl = null;
    this._toolbarRef = createRef();
    this._useRightAxis = false;
    this.advancedModeEnabled = false;
    this.appVersion = '';
    this.canCollect = false;
    this.connectionStatusAttention = false;
    this.graphs = {};
    this.isChangingSettingsSpectrumModes = false;
    this.isImportedSession = false;
    this.isSavingSpecSettings = false;
    this.progress = 0;
    this.sessionSpectrumMode = '';
    this.showAboutDialog = false;
    this.showCalibrate = false;
    this.showChangelogDialog = false;
    this.showDeviceManagerDialog = false;
    this.showFileMenu = false;
    this.showOtherOptions = false;
    this.showSettings = false;
    this.showWavelengthSelectorDialog = false;

    this.__updateSpectrumInfo();
    this._updateSpectrumInfo = throttle(() => this.__updateSpectrumInfo(), 1000);
  }

  static get styles() {
    return [
      globalStyles,
      customPropertyStyles,
      css`
        #new_btn {
          text-transform: none;
          font-weight: bold;
        }

        #header_version {
          color: #fff;
        }
        .header__logo {
          max-inline-size: 13rem;
        }

        #welcome_dialog {
          --dialog-close-icon-color: #fff;
          --dialog-close-icon-hover-background: var(--vst-color-brand-dark);
          --dialog-border-block-start: none;
          --dialog-header-background: var(--vst-color-brand-300);
          --dialog-header-font-color: #fff;
        }

        vst-core-accessibility {
          block-size: auto;
        }
      `,
    ];
  }

  static fileMenuItems() {
    return {
      items: [
        [
          {
            id: 'new',
            title: getText('New Experiment'),
          },
        ],
        [
          {
            id: 'open',
            title: getText('Open...'),
          },
        ],
        [
          {
            id: 'save',
            title: getText('Save'),
          },
          {
            id: 'save_as',
            title: getText('Save As...'),
          },
        ],
        [
          {
            id: 'export',
            title: getText('Export'),
            class: 'has-submenu',
          },
        ],
      ],
    };
  }

  static otherOptions() {
    return {
      items: [
        [
          {
            id: 'about',
            title: getText('About'),
          },
          {
            id: 'app_preferences',
            title: getText('App Preferences'),
          },
          {
            id: 'manual',
            title: getText('User Manual'),
          },
          {
            id: 'changelog',
            title: getText("What's New"),
          },
        ],
      ],
    };
  }

  static deviceManagerStrings() {
    return {
      connectedDevices: getText('Connected Spectrometers'),
      connectHowTo: getText('Connect to a wireless spectrometer below or connect via USB.'),
      connectHowToNoUSB: getText('Connect to a wireless spectrometer below.'),
      discoveredWireless: getText('Discovered Wireless Devices'),
      filterDeviceList: getText('Filter Spectrometer List'),
      bluetoothDevice: getText('Bluetooth Spectrometer'),
      searching: getText('Searching for devices...'),
      noDevicesConnected: getText('No Spectrometer Connected'),
      noDevicesFound: getText(
        `Don't see your spectrometer? Visit our <a class="link" href="https://www.vernier.com/til/4118" target="_blank">support page</a> for troubleshooting tips.`,
      ),
    };
  }

  stateChanged(state) {
    this.isSessionEmpty = state.isSessionEmpty;
    this.graphs = state.graphs;
  }

  connectedCallback() {
    super.connectedCallback();

    if (platform.name === 'Chrome' && PLATFORM_ID !== 'web') {
      const vstUiChromebarEl = document.createElement('vst-ui-chromebar');
      const appEl = document.querySelector('#app');
      document.body.insertBefore(vstUiChromebarEl, appEl);
      document.body.style.setProperty('--chrome-menubar-height', '32px');
    }
  }

  async firstUpdated() {
    window.ELECTRON_DISABLE_SECURITY_WARNINGS = true;
    this.classList = this.colorMode;
    this._splashScreenEl = this.shadowRoot.querySelector('#splash_screen');

    if (platform.name === 'Chrome') {
      // if we're running the chrome packaged app on Mac or Windows, stop the user from moving forward.
      if (
        PLATFORM_ID !== 'web' &&
        (platform.os.family.includes('OS X') || platform.os.family.includes('Windows'))
      ) {
        this._showChromeOnMacWinDialog();
      } else {
        this._splashScreenEl.showProgress = true;
      }

      addProgressCallback(progress => {
        this.progress = progress;
      });
    }

    // Show a message when running the PWA in a browser that's missing support
    // for all connectivity types
    if (
      PLATFORM_ID === 'web' &&
      !vstRequestDevice.hasBluetooth &&
      !vstRequestDevice.hasHID &&
      !vstRequestDevice.hasUSB
    ) {
      this._splashScreenEl.hide();
      this._isUnsupportedBrowserMessageVisible = true;
      return;
    }

    const coreServices = await initServices();
    const { deviceManager, dataCollection, sensorWorld, rpc } = coreServices;
    const saServices = initSaServices({ deviceManager, dataCollection, sensorWorld, rpc });
    this.$services = { ...coreServices, ...saServices };
    this.provideServices(this.$services);

    const { appManifest } = this.$services;
    this.appVersion = appManifest.getAppVersion();

    this.initApp();
  }

  updated(changedProperties) {
    changedProperties.forEach((oldValue, propName) => {
      switch (propName) {
        case 'colorMode':
          this.classList = this.colorMode;
          break;
        case 'sessionSpectrumMode':
          this._updateSpectrumInfo();
          this._addFluorWavelengthToDataSetName();
          break;
        case 'connectedDevice':
          if (this.connectedDevice === undefined) {
            this.updateMinigraph();
          }
          break;
        default:
      }
    });
  }

  /**
   * Called first during app init and whenever devices are connected or disconnected from the user's computer.
   * @param { Object } event specify { suppressModeChange: true } to prevent mode changes.
   */
  async _deviceListChanged({ suppressModeChange }) {
    const connectedDevices = this.$services.deviceManager.getConnectedDevices() || [];
    const oldSerialNumber = this.connectedDevice?.serialNumber ?? '';

    const oldDevice = this.connectedDevice;
    [this.connectedDevice] = connectedDevices;
    if (!oldDevice) this._resetSpecSettingsCache();

    // Set up newly added device to use the current spectrum mode, filtering out spurious device-list-changed events by checking serial number.
    if (
      (this.connectedDevice?.serialNumber ?? '') !== oldSerialNumber &&
      !suppressModeChange &&
      !this.showWelcomeDialog
    ) {
      await this._prepareDeviceForSession(this.sessionSpectrumMode, this.advancedModeEnabled);
    }
  }

  /**
   * Initializes application business logic. Called at `firstUpdated()`
   * @override
   */
  async initApp() {
    super.initApp();

    const { dataWorld, dataCollection, sensorMap, fileIO, popoverManager, sensorWorld } =
      this.$services;

    this.registerDialogTemplate([
      'vst-core-column-options',
      'vst-ui-message-box',
      'vst-ui-presentation',
      'vst-core-export',
      'vst-ui-prompt',
      'vst-core-feature-flags',
      'vst-core-device-info',
    ]);

    this._setupAutoLayout();

    // iOS hack to make sure DataCollection is up and ready to talk after a force quit
    await nextTick(10);
    await dataCollection.init({
      sensorMap: sensorMap.getSensorMap(),
      hasFirmwareUpdater: false,
    });

    this.eventBinder.bindListeners({
      source: dataCollection,
      target: this,
      eventMap: {
        'keep-values-ready': '_handleKeepValuesReady',
        'ble-communication-error': '_onDataCollectionBleCommunicationError',
        'collection-params-changed': '_onDataCollectionCollectionParamsChanged',
      },
    });

    this.resetStoreSession = () => {
      // Cancel autorun of layout store.
      if (this._stopLayoutAutorun) this._stopLayoutAutorun();
      this._stopLayoutAutorun = undefined;

      const graphIds = ['graph_1', 'graph_2', 'graph_3'];
      store.dispatch(resetSession(graphIds));
    };

    dataWorld.on('session-ended', this.resetStoreSession);
    this.resetStoreSession();

    try {
      const sessionType = 'DataCollection';
      const sessionConfig = {
        type: 'full-spectrum',
        spectrumMode: 'absorbance',
      };

      const session = await dataWorld.startNewSession(sessionType, sessionConfig);
      if (session && session.cancelled) {
        return;
      }
    } catch (error) {
      console.error(error);
      const errorMsg = error.message || getText('Error starting new session');
      this.dataCollectionError = errorMsg;
    }

    dataWorld.on('session-started', config => {
      this.isImportedSession = config.imported;
      this._updateExperimentTitle();
      // Set up an autorun that responds to changes to the layout store and
      // saves the values to UDM.
      this._stopLayoutAutorun = autorun(() => {
        dataWorld.setLayoutProperties({
          graph_1: vstLayoutStore.graph_1,
          graph_2: vstLayoutStore.graph_2,
          graph_3: vstLayoutStore.graph_3,
          table: vstLayoutStore.table,
          meter: vstLayoutStore.meter,
          video: vstLayoutStore.video,
          notes: vstLayoutStore.notes,
          configurator: vstLayoutStore.configurator,
        });
      });
    });

    dataWorld.on('prompt-collecting-confirm-new-session', ({ appName, resolution }) => {
      resolution(promptConfirmNewSession.call(this, appName));
    });

    dataWorld.on('collection-preparing', () => this._addFluorWavelengthToDataSetName());

    dataWorld.on('column-values-changed', () => {
      store.dispatch(updateIsSessionEmpty(false));
    });

    const wrapper = this.shadowRoot.querySelector('#popover_wrapper');
    popoverManager.attachWrapper(wrapper);

    this._toggleWelcomeDialog(true);
    this._splashScreenEl.hide();

    this._deviceListChanged({ suppressModeChange: true });
    this._deviceListChangedHandler = this._deviceListChanged.bind(this);
    this.eventBinder.on(
      this.$services.deviceManager,
      'device-list-changed',
      this._deviceListChangedHandler,
    );

    this.eventBinder.bindListeners({
      source: sensorWorld,
      target: this,
      eventMap: {
        'sensor-added': '_onSensorChanged',
        'sensor-removed': '_onSensorChanged',
      },
    });

    this.eventBinder.bindListeners({
      source: dataWorld,
      target: this,
      eventMap: {
        'column-added': '_updateSpectrumInfo',
        'column-removed': '_updateSpectrumInfo',
        'column-group-updated': '_updateSpectrumInfo',
      },
    });

    dataWorld.on('is-collecting-changed', isCollecting => {
      this._isCollecting = isCollecting;
      if (
        isCollecting &&
        this.advancedModeEnabled &&
        !this._advModeCompletedCollections.includes(this.sessionSpectrumMode)
      ) {
        this._advModeCompletedCollections.push(this.sessionSpectrumMode);
      }
    });

    const toastWrapper = this.shadowRoot.querySelector('#toast_wrapper');
    this.$services.toast.attachWrapper(toastWrapper);

    this._updateExperimentTitle();

    fileIO.onSave(() => {
      this._updateExperimentTitle();
    });
  }

  async _autoLayout() {
    const { dataWorld, dataCollection, sensorWorld } = this.$services;

    if (dataWorld.sessionConfig?.imported) {
      const layoutFlags = await dataWorld.getLayoutProperties();
      if (layoutFlags) vstLayoutStore.updateLayout(layoutFlags);
    } else {
      // too much of a sledgehammer? maybe better to not combine results from two graphs
      const rightColumnIds = [
        ...this._newRightColumnIds,
        ...this.graphs?.graph_1?.columnIds?.right,
        ...this.graphs?.graph_2?.columnIds?.right,
        ...this.graphs?.graph_3?.columnIds?.right,
      ];
      this._newRightColumnIds = [];

      const { appAutoLayout, graphAutoLayouts } = computeGraphsAutoLayout(
        dataWorld,
        sensorWorld,
        dataCollection.getCollectionParams(),
        this.connectedDevice,
        rightColumnIds,
      );

      // add back in old left traces before updating if they are associated with a group column i.e. not removed
      const columnIdHasActiveGroupId = columnId =>
        !!dataWorld.getColumnGroupById(dataWorld.getColumnById(columnId)?.groupId);

      const combineColumnIds = (columnIds, graphLayoutColumnIds) => {
        if (columnIds) {
          return Array.from(
            new Set([...graphLayoutColumnIds, ...columnIds.filter(columnIdHasActiveGroupId)]),
          );
        }
        return graphLayoutColumnIds;
      };

      // only needed for advanced mode to retain traces in between switching spectrum modes
      if (this.advancedModeEnabled) {
        graphAutoLayouts.forEach((graphLayout, index) => {
          graphLayout.leftColumnIds = combineColumnIds(
            this.graphs[`graph_${index + 1}`]?.columnIds?.left,
            graphLayout.leftColumnIds,
          );

          graphLayout.rightColumnIds = combineColumnIds(
            this.graphs[`graph_${index + 1}`]?.columnIds?.right,
            graphLayout.rightColumnIds,
          );
        });
      }

      // Always show table
      appAutoLayout.table = true;
      // Show the meter for certain modes
      if (dataCollection.mode === 'full-spectrum') {
        appAutoLayout.meter = false;
      } else if (['events-with-entry', 'time-based'].includes(dataCollection.mode)) {
        appAutoLayout.meter = true;
      }

      store.dispatch(updateGraphsAutoLayout(graphAutoLayouts));
      vstLayoutStore.updateLayout(appAutoLayout);
    }
  }

  _setupAutoLayout() {
    const { dataWorld } = this.$services;
    const { eventBinder } = this;

    eventBinder.bindListeners({
      source: dataWorld,
      target: this,
      eventMap: {
        'column-added': '_handleColumnAdded',
        'session-started': '_autoLayout',
      },
    });
  }

  _handleColumnAdded(column) {
    const { dataWorld } = this.$services;
    const newColumnGroup = dataWorld.getColumnGroupById(column.groupId);
    let rightColumnIdAdded = false;

    // add trace to graph if it matches an existing groupId (checking both left and right columns)
    for (const graph of Object.values(this.graphs)) {
      for (const existingColumn of graph.columnIds.left) {
        if (dataWorld.getColumnById(existingColumn)?.groupId === column.groupId) {
          store.dispatch(addColumnId(column.id, graph.id, 'left'));
        }
      }
      for (const existingColumn of graph.columnIds.right) {
        if (dataWorld.getColumnById(existingColumn)?.groupId === column.groupId) {
          store.dispatch(addColumnId(column.id, graph.id, 'right'));
        }
      }
    }

    if (column.type === 'sensor') {
      if (column.group.columns.length === 1) {
        if (this._useRightAxis) {
          if (!dataWorld.isCurrentDataSetEmpty()) {
            this._newRightColumnIds.push(column.id);
            this._rightAxisGroupId = newColumnGroup.id;
          } else if (newColumnGroup.id === this._rightAxisGroupId) {
            this._newRightColumnIds.push(column.id);
            rightColumnIdAdded = true;
          }
        }
        if (this._rightAxisGroupId && !rightColumnIdAdded) {
          if (newColumnGroup.id === this._rightAxisGroupId) {
            this._newRightColumnIds.push(column.id);
          }
        }
        this._autoLayout();
      }
    }
  }

  _handleKeepValuesReady(keepValues) {
    const { dataWorld, popoverManager } = this.$services;
    const eventColumn = dataWorld
      .getColumns()
      .filter(col => col.type === 'event' && col.setId === dataWorld.currentDataSet.id)[0];
    // TODO (@mossymaker): consider making this a utility
    const arrayFromObjectValues = values =>
      Object.entries(values).map(([columnId, value]) => ({
        column: dataWorld.getColumnById(columnId),
        value,
      }));
    const keepResults = arrayFromObjectValues(keepValues);

    const eweDialogOpts = {
      canClose: false,
      events: ({ completeWorkflow, cancelWorkflow }) => ({
        'kept-point': e => {
          const {
            detail: { eventColId, userValue, readings },
          } = e;
          dataWorld.addEventData(eventColId, userValue, readings, false);
          completeWorkflow();
          this._toolbarRef.value.focusKeep();
        },
        cancel: () => {
          cancelWorkflow();
          this._toolbarRef.value.focusKeep();
        },
      }),
      properties: {
        view: {
          eventColumn,
          keepResults,
        },
      },
      title: getText('Keep Point'),
    };

    popoverManager.presentDialog('vst-ui-ewe', eweDialogOpts);
  }

  _onDataCollectionBleCommunicationError() {
    const { toast } = this.$services;
    toast.makeToast(getText('The Bluetooth connection has been lost'), { duration: 5000 });
  }

  /**
   * Called when the back end reports that its data collection params have been
   * updated.
   * @param {object} newSettings settings after updating.
   * @param {object} oldSettings settings before updating.
   */
  _onDataCollectionCollectionParamsChanged(newSettings, oldSettings) {
    const MIN_SPEC_DELTA = 1.0;
    const { mode: newMode, params: newParams } = newSettings;
    const { delta: newDelta } = newParams;

    if (newMode === 'time-based' && newDelta < MIN_SPEC_DELTA) {
      // If we receive a bad delta AFTER receiving an acceptable one, we should
      // revert it back to the acceptable one. This works around a race
      // condition appearing in certain OS / HW / Device configurations.
      // (MEG-3278).
      const {
        params: { delta: oldDelta },
      } = oldSettings;

      if (oldDelta >= MIN_SPEC_DELTA) {
        console.warn('Discrepancy between reported delta. Reverting to last good value.');
        this.$services.dataCollection.setCollectionParams(newMode, {
          ...newParams,
          delta: oldDelta,
        });
      }
    }
  }

  async disconnectDevice() {
    try {
      await this.$services.deviceManager.disconnectDevice(this.connectedDevice);
    } catch (error) {
      console.error(error);
      console.error(this.connectedDevice);
    } finally {
      this.connectedDevice = null;
    }
  }

  onDataWorldCollectionControlChanged(canControl) {
    this.sessionCanControl = canControl;
  }

  onDataWorldCollectionStopped() {
    if (this.isUIDisabled) {
      this.toggleUIUpdates();
    }
  }

  _toggleWelcomeDialog(show) {
    this.showWelcomeDialog = show !== undefined ? show : !this.showWelcomeDialog;
  }

  async _handleFileMenuItemClicked({ detail: id }) {
    if (id === 'open') {
      this.onOpenFile();
    } else if (id === 'save') {
      this.onSaveFile({ saveAs: !this._experimentName });
    } else if (id === 'save_as') {
      this.onSaveFile({ saveAs: true });
    } else if (id === 'new') {
      // TODO call try save
      try {
        const { cancelled } = await this.trySave().catch(rejection => rejection);
        if (cancelled) {
          // FIXME what if x pressed instead of cancel
          return;
        }
        this.sessionCollectionMode = '';
        this.sessionSpectrumMode = '';
        this.advancedModeEnabled = false;
        this._toggleWelcomeDialog(true);
      } catch (err) {
        console.error(err);
      }
    } else if (id === 'export') {
      this.onExport();
    }

    this._toggleFileMenu();
  }

  async _handleOtherOptionClicked({ detail: id }) {
    if (id === 'about') {
      this._toggleAboutDialog(true);
    } else if (id === 'manual') {
      window.open('https://www.vernier.com/sa4-manual', '_blank');
    } else if (id === 'app_preferences') {
      this._togglePresentationDialog(true);
    } else if (id === 'changelog') {
      this._toggleChangelogDialog(true);
    }

    this._toggleOtherOptions(false);
  }

  async onExport() {
    const exportTabs = [
      ...Array.from(
        document
          .querySelector('#app')
          .shadowRoot.querySelector('#main_content')
          .shadowRoot.querySelectorAll('.app-graph:not([hidden])'),
      ).map((graphEl, index) => {
        return {
          name: sprintf(getText('Graph %s'), index + 1),
          type: 'graph',
          output: 'image',
          graphEl,
          extension: 'png',
        };
      }),
      {
        name: getText('CSV'),
        type: 'csv',
        output: 'file',
        tooltip: getText('Comma Separated Values'),
        extension: 'csv',
      },
    ];
    this.dispatchEvent(
      new CustomEvent('open-dialog', {
        bubbles: true,
        composed: true,
        detail: {
          dialog: 'export',
          params: {
            tabs: exportTabs,
          },
        },
      }),
    );
  }

  _toggleFileMenu(state) {
    // this way if it fires on mouseup and click, it fires the same direction (open/close)
    this.showFileMenu = state;
  }

  _toggleSettings(state) {
    this.showSettings = state;
  }

  _toggleOtherOptions(state) {
    this.showOtherOptions = state;
  }

  _toggleAboutDialog(state) {
    this.showAboutDialog = state;
  }

  _toggleChangelogDialog(state) {
    this.showChangelogDialog = state;
  }

  _toggleCalibrateDialog(state = false) {
    this.showCalibrate = state;
  }

  _completeCalibration() {
    this._toggleWavelengthSelectorDialog(this.requiresWavelengthChooser);
    this._toggleCalibrateDialog(false);
  }

  _togglePresentationDialog(state) {
    this.showPresentation = state;
  }

  /**
   * @returns {Boolean} Whether the connected device needs to be calibrated
   */
  get requiresCalibration() {
    return this.connectedDevice && !this.connectedDevice.calibrated;
  }

  /**
   * @returns {Boolean} true if the current collection mode requires the user to pick a wavelength, if they haven't done so already.
   */
  get requiresWavelengthChooser() {
    const { sessionCollectionMode } = this;
    const { sensorWorld } = this.$services;
    const sensor = sensorWorld.getFirstSensor();

    return (
      (sessionCollectionMode === 'time-based' || sessionCollectionMode === 'events-with-entry') &&
      !sensor.wavelength
    );
  }

  /**
   * @returns {Boolean} true if current experiment needs to display a spectrum minigraph.
   */
  get requiresMinigraph() {
    const { sessionCollectionMode } = this;
    return sessionCollectionMode === 'time-based' || sessionCollectionMode === 'events-with-entry';
  }

  _updateExperimentTitle() {
    const { dataWorld } = this.$services;
    this._experimentName = dataWorld.sessionName;
  }

  async onOpenFile() {
    try {
      const { appManifest, dataWorld, fileIO, popoverManager, dataCollection } = this.$services;
      const { cancelled } = await this.trySave().catch(rejection => rejection);
      if (cancelled) {
        return;
      }

      const fileData = await fileIO.showOpenDialog([
        {
          extensions: getOpenableTypes(),
          name: appManifest.getAppName(),
        },
        {
          extensions: ['csv'],
          name: getText('Comma Separated Values'),
        },
      ]);

      if (!fileData) return; // cancelled

      const fileOpened = await dataWorld.importData({ fileData });

      if (fileOpened) {
        this.sessionCollectionMode = dataCollection.mode;
        this.sessionSpectrumMode = dataCollection.spectrumMode;
        this._experimentName = fileData.file.name;

        // Set the device's spectrum mode:
        await this._prepareDeviceForSession(dataCollection.spectrumMode, false);

        // If we've got more than 2 columns, it means we're in advanced mode (we do this after sensors finish churning)
        this.advancedModeEnabled = dataWorld.currentDataSet.columnIds.length > 2;

        // This sets the various flags in SpecDevice correctly.
        this.updateMinigraph();

        this._toggleWelcomeDialog(false);
        popoverManager.closeAll();
      }
    } catch (err) {
      console.error(err);
    }
  }

  currentViewChanged() {
    if (this.afterViewChange) {
      this.afterViewChange();
    }
  }

  whatWhatsNewChanged() {
    if (this.showWhatsNew) {
      const toolbarEl = this.querySelector('vst-ui-toolbar');

      if (toolbarEl) {
        toolbarEl.notify = 'whats-new';
      }
    }
  }

  async _showChromeOnMacWinDialog() {
    const { _splashScreenEl } = this;
    let simpleOSName;
    if (platform.os.family.includes('OS X')) {
      simpleOSName = 'macOS';
    } else if (platform.os.family.includes('Windows')) {
      simpleOSName = 'Windows';
    }

    _splashScreenEl.errorMsg = `You are running Spectral Analysis as a Chrome Packaged App. Please download the native app for ${simpleOSName}.`;
    await this.updateComplete;
    const wrapperEl = _splashScreenEl.shadowRoot.querySelector('.wrapper');
    const downloadBtnEl = document.createElement('button');
    downloadBtnEl.innerText = 'Download';
    downloadBtnEl.classList.add('download-btn');
    downloadBtnEl.addEventListener('click', () => {
      window.open('https://vernier.com/products/software/spectral-analysis/', '_blank');
      setTimeout(() => {
        document.dispatchEvent(new CustomEvent('close-app', { bubbles: true, composed: true }));
      });
    });
    wrapperEl.appendChild(downloadBtnEl);

    // add a way to move forward if you're a dev or QA
    keyboardEvents.on('dev-override', () => {
      keyboardEvents.off('dev-override');
      downloadBtnEl.remove();
      _splashScreenEl.errorMsg = '';
      this._splashScreenEl.showProgress = true;
    });
  }

  async startCollection() {
    const { dataCollection, dataWorld, power } = this.$services;
    const isEventsMode = dataCollection.getCollectionParams().mode.match('events');
    let append = false;

    if (this.requiresCalibration) {
      this._toggleCalibrateDialog(true);
    } else if (this.requiresWavelengthChooser) {
      this._toggleWavelengthSelectorDialog(true);
    } else {
      if (isEventsMode && !dataWorld.isCurrentDataSetEmpty(true)) {
        await new Promise(resolve => {
          this.dispatchEvent(
            new CustomEvent('open-dialog', {
              bubbles: true,
              composed: true,
              detail: {
                dialog: 'message_box',
                params: {
                  title: getText('Append or Create New?'),
                  choiceRequired: true,
                  content: getText(
                    'Append additional data to the current data set or create a new data set?',
                  ),
                  actions: [
                    {
                      id: 'append',
                      message: getText('Append'),
                      variant: 'outline',
                      onClick: () => {
                        append = true;
                        this.dispatchEvent(closeCommonDialogEvent('message_box'));
                      },
                    },
                    {
                      id: 'create_new',
                      message: getText('Create new Data Set'),
                      onClick: () => {
                        this.dispatchEvent(closeCommonDialogEvent('message_box'));
                      },
                    },
                  ],
                },
                onClose: () => {
                  resolve();
                },
              },
            }),
          );
        });
      }

      dataWorld.startCollection({ appendToDataSet: append });
      power.requestWakeLock();
    }
  }

  stopCollection() {
    this.$services.power.releaseWakeLock();
    this.$services.dataWorld.stopCollection();
  }

  _toggleWavelengthSelectorDialog(state) {
    this.showWavelengthSelectorDialog = state;
  }

  /**
   * Opens the device manager dialog allowing the user to choose a device.
   * @returns {Promise} promise that resolves with the newly added device.
   * @throws {Error} describing whether the user canceled or a device failed to
   * connect. Error `cause` will be `{userCanceled: true}`.
   */
  _chooseDevice() {
    console.assert(!this._deviceMgrPromiseFns && !this.showDeviceManagerDialog);

    this.showDeviceManagerDialog = true;

    return new Promise((resolve, reject) => {
      this._deviceMgrPromiseFns = { resolve, reject };
    });
  }

  _closeDeviceManager() {
    // If we're already closed, just ignore, otherwise bad things happen.
    if (!this.showDeviceManagerDialog) return;

    if (!this.connectedDevice) {
      // In the PWA this path always triggers, since the device manager merely triggers a long async
      // operation that eventually gives us the device, and these values need to be preserved
      // until such time -- we clear them once the device is connected.
      if (PLATFORM_ID !== 'web') {
        // If we want to do this in the PWA, we need to handle cancellation in requestDevice() flow.
        this.connectionStatusAttention = true;

        this.sessionCollectionMode = '';
        this.sessionSpectrumMode = '';
        this.advancedModeEnabled = false;
      }
    }

    // Call the promise functions if we have them. Anyone awaiting on the
    // return from the call to `_chooseDevice()` will become unblocked.
    if (this._deviceMgrPromiseFns) {
      const { reject, resolve } = this._deviceMgrPromiseFns;
      if (this.connectedDevice) resolve(this.connectedDevice);
      else
        reject(
          new Error(getText('User canceled device connection'), { cause: { userCanceled: true } }),
        );
      this._deviceMgrPromiseFns = null;
    }

    this.showDeviceManagerDialog = false;
  }

  async _addSoftDevice() {
    const { sensorWorld } = this.$services;
    await sensorWorld.enableSpecialSoftSensor(0);
    this.requestUpdate(); // needs to rerender the connection-status bar
  }

  showDeviceInfo() {
    this.dispatchEvent(
      new CustomEvent('open-dialog', {
        bubbles: true,
        composed: true,
        detail: {
          dialog: 'device_info',
          params: {
            title: getText('Spectrometer Information'),
            device: this.connectedDevice,
          },
        },
      }),
    );
  }

  /**
   * Prepares connected or newly connected devices for data collection after file open or when a sensor is added.
   * @param { String } spectrumMode mode for device.
   * @param { Boolean } advanced are we in advanced mode?
   */
  async _prepareDeviceForSession(spectrumMode, advanced) {
    const { dataCollection, specDevice } = this.$services;

    // Don't bother trying to change the mode if no device is connected.
    if (!this.connectedDevice) {
      return;
    }

    this._toggleProgressSpinner(true);
    try {
      await dataCollection.setSpectrumDataMode(spectrumMode);
      await specDevice.awaitSensorForMode(spectrumMode, advanced);
    } catch (err) {
      console.error(err);
    } finally {
      this._toggleProgressSpinner(false);
    }
  }

  /**
   * Handler for 'session-type-selected' message from welcome dialog.
   * @param {String} detail.spectrumMode spectrum collection mode: 'absorbance', 'transmittance', 'intensity', 'fluorescence'
   * @param {String} detail.collectionMode 'full-spectrum', 'events-with-entry', 'time-based'.
   * @param {Boolean} detail.advanced true if user has selected Advanced Full Spectrum, else false.
   */
  async onWelcomeSessionSelected({ detail: { collectionMode, spectrumMode, advanced } }) {
    this.sessionCollectionMode = collectionMode;
    this.sessionSpectrumMode = spectrumMode;
    this.advancedModeEnabled = advanced;

    await this._createNewSession(collectionMode, spectrumMode, advanced);
  }

  async _createNewSession(collectionMode, spectrumMode, advanced) {
    const { dataWorld, specDevice } = this.$services;

    this.dataCollectionError = '';
    this._rightAxisGroupId = 0;
    this._advModeCompletedCollections = [];
    this._useRightAxis = false;
    this.sessionWavelength = 0; // (MEG-26)
    this._deferOpenSettings = false;

    this.updateMinigraph();
    this.requestUpdate();

    // start the new session
    try {
      const sessionType = 'DataCollection';
      const sessionConfig = {
        type: collectionMode,
        spectrumMode,
      };

      const session = await dataWorld.startNewSession(sessionType, sessionConfig);
      if (session && session.cancelled) {
        return;
      }

      // This will wait until sensors finish churning before proceeding with the remainder of start session tasks.
      this._toggleProgressSpinner(true);
      if (!this.connectedDevice) await this._chooseDevice();

      await specDevice.awaitSensorForMode(spectrumMode, advanced);
      this._finishSessionStartup(advanced);
      this._toggleProgressSpinner(false);
    } catch (error) {
      const { cause: { userCanceled = false } = {} } = error;
      console.error(error);
      if (!userCanceled) {
        const errorMsg = error.message || getText('Error starting new session');
        this.dataCollectionError = errorMsg;
      }
    }
  }

  async _finishSessionStartup(advanced) {
    const { dataCollection, toast } = this.$services;
    const {
      requiresCalibration,
      requiresWavelengthChooser,
      sessionCollectionMode,
      sessionSpectrumMode,
    } = this;

    const params = {
      ...dataCollection.timeBasedParams,
      continuous: true,
      // FIXME (Jordan): Hack to make sure units passed in
      units: 's',
    };

    // Delta is defaulting to .5 which makes the timeToWait/Bail of the keep too short. --Jimmy
    if (sessionCollectionMode === 'events-with-entry') {
      params.delta = 2;
    }

    try {
      await dataCollection.setCollectionParams(sessionCollectionMode, params);
    } catch (_ex) {
      toast.makeToast(getText('Setting collection parameters failed'));
    }

    // This will ensure that settings are always opened in all fluor modes (MEG-34).
    let openSettings = advanced;
    if (sessionSpectrumMode === 'fluorescence') {
      if (sessionCollectionMode !== 'full-spectrum') {
        this._deferOpenSettings = true;
      } else {
        openSettings = true;
      }
    }

    this._addFluorWavelengthToDataSetName();
    this._toggleSettings(openSettings);
    this._toggleCalibrateDialog(requiresCalibration && !advanced);
    this._toggleWavelengthSelectorDialog(!requiresCalibration && requiresWavelengthChooser);
    this._toggleWelcomeDialog(false);
    this._resetSpecSettingsCache();
  }

  updateMinigraph() {
    const { requiresMinigraph } = this;
    graphStore.setIsMiniGraphVisible(requiresMinigraph);
    graphStore.setIsMiniGraphDisabled(!requiresMinigraph);
  }

  /**
   * Cache user's custom spec settings for the current mode. If we're in advanced operation, this will preserve those settings across mode changes.
   * @param {Object } settings contains settings for integration time, wavelength smoothing, and temporal averaging.
   */
  _cacheSpecSettings(settings) {
    this._specSettingsCache[this.sessionSpectrumMode] = settings;
    this._reapplySpecSettings();
  }

  /**
   * Resets the cache where we store user's custom spec settings (typically advanced mode). Call this on file->new and when connecting a device.
   */
  _resetSpecSettingsCache() {
    this._specSettingsCache = {};
    this._reapplySpecSettings();
  }

  /**
   * Applies integration time, temporal averaging, and wavelength smoothing values for current mode based on device settings or cached user entry.
   */
  async _reapplySpecSettings(applyToDevice = false) {
    const { connectedDevice: device } = this;

    // Uncache custom user values for the given mode.
    const values = this._specSettingsCache[this.sessionSpectrumMode];
    if (values) {
      this._specIntegrationTime = values.integrationTime;
      this._specTemporalAveraging = values.temporalAveraging;
      this._specWavelengthSmoothing = values.wavelengthSmoothing;
      if (applyToDevice) await this._changeSpecSettings(values);
    } else if (device) {
      // Note: smoothing and averaging are per device, and yet we wish to create the illusion that they are per spectrum mode.
      // To do this, cache the default values as `defaults` entry in the settings cache. We can then use these whenever
      // the user changes to a mode for which they already haven't entered custom values.
      const { integrationTime, wavelengthSmoothing, temporalAveraging } = device;
      const { defaults = { wavelengthSmoothing, temporalAveraging } } = this._specSettingsCache;

      this._specIntegrationTime = integrationTime;
      this._specWavelengthSmoothing = defaults.wavelengthSmoothing;
      this._specTemporalAveraging = defaults.temporalAveraging;
      this._specSettingsCache.defaults = defaults;
    }
  }

  async _changeSpecSettings(newSettings) {
    const { specDevice, sensorWorld } = this.$services;
    const sensor = sensorWorld.getFirstSensor();

    try {
      await specDevice.setSpecSettings(sensor.experimentId, sensor.id, newSettings);
    } catch (error) {
      console.error(error);
    }
  }

  /**
   * This is called in response to 'update-spec-settings' events from the settings dialog popover.
   * @param {Object} e.detail object containing user-entered parameters.
   */
  async onUpdateSpecSettings(e) {
    const { dataCollection, specDevice, sensorWorld } = this.$services;

    const {
      LEDIntensity,
      LEDWavelength,
      collectionInterval,
      integrationTime,
      temporalAveraging,
      wavelengthSmoothing,
    } = e.detail;

    const specSettings = {
      LEDIntensity,
      LEDWavelength,
      integrationTime,
      temporalAveraging,
      wavelengthSmoothing,
    };

    // Cache any settings values changed by the user.
    this._cacheSpecSettings(specSettings);

    const sensor = sensorWorld.getFirstSensor();
    this.isSavingSpecSettings = true;

    try {
      await specDevice.setSpecSettings(sensor.experimentId, sensor.id, specSettings);
    } catch (error) {
      console.error(error);
    }

    if (this.sessionCollectionMode === 'time-based') {
      const params = {
        ...dataCollection.timeBasedParams,
      };
      params.delta = collectionInterval;

      try {
        await dataCollection.setCollectionParams(this.sessionCollectionMode, params);
      } catch (_ex) {
        this.$services.toast.makeToast(getText('Setting collection parameters failed'));
      }
    }

    this._onSpecDeviceSpecSettingsChanged();

    setTimeout(() => {
      this.isSavingSpecSettings = false;
    }, 500);
  }

  /**
   * Called in response to 'set-spectrum-mode' events from the settings popover
   * when user changes Advanced Full Spectrum mode.
   *
   * @param {String} e.detail.mode "transmittance", "absorbance", "fluorescence",
   * "intensity", "raw"
   */
  async _handleSetSpectrumMode(e) {
    const { mode } = e.detail;

    try {
      this.isChangingSettingsSpectrumModes = true;
      await this._prepareDeviceForSession(mode, true);
    } catch (err) {
      console.error(err);
    } finally {
      this.sessionSpectrumMode = mode;
      // If we've got cached settings for the new mode, we'll apply them here. Pass in true so the new values are applied to the device.
      await this._reapplySpecSettings(true);

      this.isChangingSettingsSpectrumModes = false;

      if (this._advModeCompletedCollections.length === 1) {
        this._useRightAxis = true;
        // not really true, but this retains behavior of release: any subsequent mode switch will switch back to using left axis
        this._advModeCompletedCollections.push(mode);
      } else if (this._advModeCompletedCollections.length > 1) {
        this._useRightAxis = false;
      }
    }

    this._onSpecDeviceSpecSettingsChanged();
  }

  /**
   * This is called in response to 'update-spec-wavelength' events from the wavelength chooser when user picks a wavelength.
   * @param {Number} detail.wavelength wavelength (nm) chosen by the user.
   */
  async updateSpecWavelength({ detail }) {
    const { sensorWorld } = this.$services;

    const sensor = sensorWorld.getFirstSensor();
    if (detail.wavelength) {
      sensor.wavelength = detail.wavelength;
      this.sessionWavelength = detail.wavelength;
      this._sessionReferenceTrace = detail.referenceTrace;
      this._sessionReferenceRange = detail.referenceRange;
      this._toggleWavelengthSelectorDialog(false);
      this._updateSpectrumInfo();
    }

    if (this._deferOpenSettings) {
      this._toggleSettings(true);
      this._deferOpenSettings = false;
    }
  }

  /**
   * This is called when the user changes the excitation wavelength in the wavelength picker.
   * @param { Number } ledWavelength new wavelength, typically 500 or 405.
   */
  async _ledWavelengthChanged(ledWavelength) {
    const { specDevice, sensorWorld } = this.$services;
    // TODO: decide a better source of truth for SpecSettings. This is getting out of hand.
    const specSettings = {
      integrationTime: this._specIntegrationTime,
      LEDWavelength: parseInt(ledWavelength),
      LEDIntensity: 100,
      wavelengthSmoothing: this._specWavelengthSmoothing,
      temporalAveraging: this._specTemporalAveraging,
    };
    const sensor = sensorWorld.getFirstSensor();
    this.isSavingSpecSettings = true;

    try {
      await specDevice.setSpecSettings(sensor.experimentId, sensor.id, specSettings);
    } catch (error) {
      console.error(error);
    } finally {
      this.isSavingSpecSettings = false;
    }
    this._updateSpectrumInfo();
    this._onSpecDeviceSpecSettingsChanged();
  }

  /**
   * Builds a spectrum info object that's consumed by wavelength picker and minigraph. Updated whenever devices, sensors, or modes change.
   */
  __updateSpectrumInfo() {
    const { connectedDevice: device } = this;

    const sensor = this.$services?.sensorWorld?.getFirstSensor();
    const unit = sensor?.sensorInfo?.calibration?.baseUnit;

    // Create a live trace if we've got one.
    let liveTrace;
    if (this.liveDataPairs) {
      liveTrace = {
        points: this.liveDataPairs,
        lineWeight: 2,
        pointSizeFactor: 1.25,
      };
    }

    const [firstSensorColumn] =
      this.$services?.dataWorld?.getAllColumns().filter(col => col.type === 'sensor') ?? [];

    this._sessionSpectrumInfo = {
      minRange: unit?.minRange ?? 0,
      maxRange: unit?.maxRange ?? 0,
      columnName: unit?.columnName ?? '',
      units: unit?.units,
      columnColor: firstSensorColumn ? firstSensorColumn.color : 'rgba(216,38,47,1)',
      referenceColor: this.colorMode === 'dark' ? 'rgba(250,250,250,1)' : 'rgba(0,0,0,1)',
      baseName: getText('Wavelength', 'sensormap', 'Spec base column name'),
      baseUnits: getText('nm', 'sensormap', 'Units for Spec Wavelength column'),
      minWavelength: device?.minWavelength ?? 300,
      maxWavelength: device?.maxWavelength ?? 800,
      liveTrace,
      referenceTrace: this._sessionReferenceTrace,
      referenceRange: this._sessionReferenceRange,
      selectedWavelength: this.sessionWavelength,
      supportedLedWavelengths: this.connectedDevice?.supportedLedWavelengths ?? [],
      currentLedWavelength: this.connectedDevice?.ledWavelength ?? 0,
      scaleYLarger: this.sessionSpectrumMode === 'intensity',
    };
  }

  /**
   * Called whenever sensors are added or removed. Adds a listener for live spectrum updates.
   */
  _onSensorChanged() {
    if (this.liveSpectrumBinding) {
      this.firstSensor?.off(this.liveSpectrumBinding);
      this.liveSpectrumBinding = null;
    }

    this.firstSensor = this.$services?.sensorWorld?.getFirstSensor();

    if (this.firstSensor) {
      this.liveSpectrumBinding = this.firstSensor.on('live-spectrum-changed', spectrum => {
        this.liveDataPairs = spectrum;
        this._updateSpectrumInfo();
      });
    }
  }

  /**
   * Adds fluoresences excitation wavelength to the dataset name where applicable.
   */
  _addFluorWavelengthToDataSetName() {
    const dataWorld = this.$services?.dataWorld;
    if (!dataWorld) {
      return;
    } // Services not available yet.

    // Do not change the data set name if it already contains data.
    if (!dataWorld.isCurrentDataSetEmpty()) {
      return;
    }

    const { currentDataSet } = dataWorld;
    const [dataSetNumber] = currentDataSet.name.match(/(\d+)/) || '';

    if (this.sessionSpectrumMode === 'fluorescence') {
      const { deviceManager } = this.$services;
      const device = deviceManager.getConnectedDevices()[0];

      if (device && device.ledWavelength) {
        dataWorld.updateDataSet(currentDataSet.id, {
          name: `${sprintf(getText('Data Set %s'), dataSetNumber)}, Fl Ex @ ${
            device.ledWavelength
          } nm`,
        });
      }
    } else if (currentDataSet.name.includes('Fl Ex @ ')) {
      // if we're not in fluorescence mode anymore but previously were (advanced mode)
      dataWorld.updateDataSet(currentDataSet.id, {
        name: `${sprintf(getText('Data Set %s'), dataSetNumber)}`,
      });
    }
  }

  _onSpecDeviceSpecSettingsChanged() {
    const { dataWorld } = this.$services;

    if (
      dataWorld.isCurrentDataSetEmpty() &&
      dataWorld.dataSets.filter(ds => ds.type === 'regular').length === 1
    ) {
      this._addFluorWavelengthToDataSetName();
    }
  }

  _toggleViewMenu() {
    this._showViewMenu = !this._showViewMenu;
  }

  _toggleProgressSpinner(show) {
    // TODO: (@joeybladb) we need a modal progress spinner for lengthy operations, such as switching between modes (see SA4-1250)
    this.showProgressSpinner = show;
  }

  _keepPoint() {
    const { dataCollection } = this.$services;
    dataCollection.keepData();
  }

  _handleViewMenuItemChanged({ detail: { layoutOptions, graphOptions } }) {
    // TODO: there is some tech debt here that needs to be addressed, if a popover isn't shown its still
    // in the DOM and the components are still being updated, so you can have unintended side-effects
    // like this method being called unexpectedly
    if (!this._showViewMenu) return; // if the menu is closed the view was updated via a file hydration

    const checkedlayoutIds = layoutOptions
      .filter(option => option.checked)
      .map(option => option.id);

    const newLayout = {
      graph_1: false,
      graph_2: false,
      graph_3: false,
      table: false,
      meter: false,
    };

    checkedlayoutIds.forEach(id => {
      if (id === 'graphs') {
        newLayout.graph_1 = true;
        if (graphOptions.length) {
          newLayout.graph_2 = graphOptions[1].selected;
          newLayout.graph_3 = graphOptions[2].selected;
        }
      } else {
        newLayout[id] = true;
      }
    });

    if (this.joinedGraphs === undefined && newLayout.graph_2 === true) this.joinedGraphs = true;

    vstLayoutStore.updateLayout(newLayout);
  }

  static getSpectrumModeStrings(sessionSpectrumMode) {
    const sessionSpectrumModeStrings = {
      absorbance: getText('Absorbance'),
      fluorescence: getText('Fluorescence'),
      transmittance: getText('Transmittance'),
      intensity: getText('Emissions'),
      raw: getText('Raw', 'raw data sensor mode'),
    };
    return sessionSpectrumModeStrings[sessionSpectrumMode];
  }

  render() {
    return html`
      <style>
        /* TODO: Move these back into the get styles() as long as it works in Firefox and Safari */
        :host {
          min-height: calc(100vh - var(--chrome-menubar-height, 0px));
          display: flex;
          flex-direction: column;
        }

        .content-wrapper {
          display: flex;
          flex-direction: column;
          height: calc(100vh - var(--chrome-menubar-height, 0px));
        }

        .toolbar {
          border-bottom: 1px solid var(--vst-color-bg-primary);
        }
      </style>

      <vst-ui-dialog no-close no-escape ?open=${this._isUnsupportedBrowserMessageVisible}>
        <h1 slot="header">${getText('Unsupported Browser')}</h1>
        <p slot="content">
          ${getText('A recent Chrome browser is required. iPhone and iPad are not supported.')}
        </p>
      </vst-ui-dialog>

      <vst-ui-splash-screen id="splash_screen" .percentLoaded="${this.progress}">
        <sa-logo slot="logo"></sa-logo>
      </vst-ui-splash-screen>

      ${this.$services
        ? html`
            <div class="content-wrapper">
              <vst-ui-toolbar
                class="toolbar"
                notify="${this.notify}"
                ?canControl="${this.connectedDevice}"
                ?canKeep=${this._isCollecting && this.sessionCollectionMode === 'events-with-entry'}
                ?isCollecting="${this._isCollecting}"
                .collectBtnMoreText=${this.advancedModeEnabled
                  ? SaApp.getSpectrumModeStrings(this.sessionSpectrumMode)
                  : ''}
                @start-collection="${this.startCollection}"
                @stop-collection="${this.stopCollection}"
                @keep-point=${this._keepPoint}
                ${ref(this._toolbarRef)}
              >
                <vst-ui-dropdown
                  ?open="${this.showFileMenu}"
                  label="File Menu"
                  slot="toolbar_left"
                  @closed="${() => this._toggleFileMenu(!this.showFileMenu)}"
                  position="bottom-left"
                  variant="dropdown"
                >
                  <vst-style-tooltip slot="anchor">
                    <button
                      class="btn"
                      variant="toolbar"
                      id="new_btn"
                      @click="${() => this._toggleFileMenu(!this.showFileMenu)}"
                    >
                      <vst-ui-icon .icon="${file}" margin="inline-end-2xs"></vst-ui-icon>
                      <div class="toolbar__filename">${this._experimentName}</div>
                    </button>
                    <span role="tooltip" position="block-end-start">${getText('File Menu')}</span>
                  </vst-style-tooltip>
                  <vst-ui-listbox
                    slot="content"
                    .view="${SaApp.fileMenuItems()}"
                    @list-item-clicked="${this._handleFileMenuItemClicked}"
                  ></vst-ui-listbox>
                </vst-ui-dropdown>

                <button
                  class="btn"
                  variant="toolbar"
                  id="settings_btn"
                  slot="toolbar_right"
                  @click="${() => this._toggleSettings(!this.showSettings)}"
                >
                  <vst-ui-icon .icon="${gear}"></vst-ui-icon>
                </button>
                <vst-ui-popover
                  for="settings_btn"
                  placement="bottom"
                  ?open=${this.showSettings}
                  @closed="${() => this._toggleSettings(false)}"
                  slot="toolbar_right"
                >
                  <sa-settings
                    .collectionInterval="${this.$services?.dataCollection?.timeBasedParams?.delta ||
                    0}"
                    .specConnected="${this.connectedDevice}"
                    .sessionCollectionMode="${this.sessionCollectionMode}"
                    .spectrumMode="${this.sessionSpectrumMode}"
                    .isCollecting="${this._isCollecting}"
                    .isSavingSettings="${this.isSavingSpecSettings}"
                    .changingSpectrumModes="${this.isChangingSettingsSpectrumModes}"
                    .integrationTime="${this._specIntegrationTime}"
                    .wavelengthSmoothing="${this._specWavelengthSmoothing}"
                    .temporalAveraging="${this._specTemporalAveraging}"
                    .advancedModeEnabled="${this.advancedModeEnabled}"
                    .ledWavelength="${this._sessionSpectrumInfo.currentLedWavelength}"
                    @show-device-manager=${this._chooseDevice}
                    @show-calibrate=${() => this._toggleCalibrateDialog(true)}
                    @device-info-clicked="${this.showDeviceInfo}"
                    @set-spectrum-mode="${this._handleSetSpectrumMode}"
                    @update-spec-settings="${this.onUpdateSpecSettings}"
                    @disconnect-ble-spec="${this.disconnectDevice}"
                  ></sa-settings>
                </vst-ui-popover>
                <vst-ui-dropdown
                  position="bottom-right"
                  variant="dropdown"
                  slot="toolbar_right"
                  ?open="${this._showViewMenu}"
                  @closed="${this._toggleViewMenu}"
                >
                  <button
                    class="btn"
                    variant="toolbar"
                    slot="anchor"
                    id="view_change_btn"
                    aria-label="${getText('View Options')}"
                    @click="${this._toggleViewMenu}"
                  >
                    <vst-ui-icon .icon="${view}"></vst-ui-icon>
                  </button>
                  <vst-ui-content-layout-options
                    slot="content"
                    .layoutOptions="${[
                      {
                        title: getText('Graph'),
                        id: 'graphs',
                        checked: vstLayoutStore.graph_1,
                      },
                      {
                        title: getText('Data Table'),
                        id: 'table',
                        checked: vstLayoutStore.table,
                      },
                      {
                        title: getText('Meter'),
                        id: 'meter',
                        checked: vstLayoutStore.meter,
                      },
                    ]}"
                    .graphOptions="${[
                      {
                        title: getText('1 Graph'),
                        id: 'graph_1',
                        selected: vstLayoutStore.graph_1,
                      },
                      {
                        title: getText('2 Graphs'),
                        id: 'graph_2',
                        selected: vstLayoutStore.graph_2,
                      },
                      {
                        title: getText('3 Graphs'),
                        id: 'graph_3',
                        selected: vstLayoutStore.graph_3,
                      },
                    ]}"
                    @layout-option-changed="${this._handleViewMenuItemChanged}"
                  ></vst-ui-content-layout-options>
                </vst-ui-dropdown>

                <vst-ui-dropdown
                  ?open="${this.showOtherOptions}"
                  slot="toolbar_right"
                  @closed="${() => this._toggleOtherOptions(!this.showOtherOptions)}"
                  position="bottom-right"
                  variant="dropdown"
                >
                  <vst-style-tooltip slot="anchor">
                    <button
                      id="overflow_btn"
                      class="btn ${this.notify ? `toolbar-overflow--${this.notify}` : ''}"
                      variant="toolbar"
                      @click="${() => this._toggleOtherOptions(!this.showOtherOptions)}"
                    >
                      <div class="red-dot"></div>
                      <div class="green-dot"></div>
                      <vst-ui-icon .icon="${overflow}"></vst-ui-icon>
                    </button>
                    <span role="tooltip" position="block-end-end">${getText('Other options')}</span>
                  </vst-style-tooltip>
                  <vst-ui-listbox
                    slot="content"
                    .view="${SaApp.otherOptions()}"
                    @list-item-clicked="${this._handleOtherOptionClicked}"
                  ></vst-ui-listbox>
                </vst-ui-dropdown>
              </vst-ui-toolbar>

              <sa-main-content
                id="main_content"
                class="main-content"
                .spectrumInfo="${this._sessionSpectrumInfo}"
                .advancedModeEnabled="${this.advancedModeEnabled}"
                @show-wavelength-chooser="${() => this._toggleWavelengthSelectorDialog(true)}"
                .useRightAxis="${this._useRightAxis}"
              >
              </sa-main-content>

              ${conditionalTemplate(
                this.$services,
                html`
                  <vst-ui-dialog
                    @dialog-close="${() => this._toggleWelcomeDialog(false)}"
                    id="welcome_dialog"
                    ?open="${this.showWelcomeDialog}"
                  >
                    ${conditionalTemplate(
                      this.showWelcomeDialog,
                      html`
                  <p class="caption" size="s" slot="version" id="header_version">v${
                    this.appVersion
                  }</p>
                  <svg slot="header"
                    viewBox="0 0 183 14" class="header__logo" fill="#fff" xmlns="http://www.w3.org/2000/svg">
<g>
	<path class="st0" d="M7.5,0.5L7.3,2.3c-1-0.4-1.8-0.6-2.5-0.6c-0.6,0-1.2,0.1-1.6,0.4C2.7,2.4,2.4,2.9,2.4,3.5
		c0,0.4,0.1,0.7,0.4,1.1c0.3,0.3,0.6,0.6,1.1,0.8C4.3,5.5,4.8,5.7,5.3,6s1,0.5,1.4,0.8c0.4,0.3,0.7,0.7,1,1.2C8,8.6,8.1,9.2,8.1,9.8
		c0,1.3-0.5,2.3-1.4,3c-0.9,0.6-2.1,1-3.5,1c-0.7,0-1.6-0.2-2.7-0.6l0.2-1.8c1.1,0.4,2,0.6,2.8,0.6c0.6,0,1.2-0.2,1.8-0.6
		c0.5-0.4,0.8-0.9,0.8-1.6c0-0.5-0.2-1-0.6-1.4C5.1,8.2,4.7,7.9,4.1,7.6C3.6,7.4,3,7.1,2.4,6.8C1.9,6.5,1.4,6.1,1,5.6
		S0.4,4.4,0.4,3.7c0-1.1,0.4-2,1.2-2.7c0.8-0.6,1.8-1,3.1-1C5.7,0.1,6.6,0.2,7.5,0.5z"/>
	<path class="st0" d="M10.6,13.6V0.3h3.5c0.7,0,1.4,0.1,1.9,0.2c0.6,0.1,1.1,0.3,1.6,0.6s0.8,0.7,1.1,1.2c0.3,0.5,0.4,1.2,0.4,1.9
		c0,1.4-0.4,2.4-1.3,3s-2,1-3.4,1h-1.9v5.3L10.6,13.6L10.6,13.6z M12.5,2v4.6h1.9c0.7,0,1.3-0.2,1.9-0.6c0.5-0.4,0.8-1,0.8-1.8
		c0-0.5-0.2-1-0.5-1.3c-0.3-0.4-0.7-0.6-1.1-0.8S14.6,2,14.1,2H12.5z"/>
	<path class="st0" d="M21.4,13.6V0.3h7.4V2h-5.5v3.9h5v1.7h-5V12h5.5v1.7L21.4,13.6L21.4,13.6z"/>
	<path class="st0" d="M41.2,0.6L41,2.4c-0.8-0.5-1.8-0.7-2.7-0.7c-1.5,0-2.8,0.5-3.7,1.5s-1.4,2.2-1.4,3.7s0.5,2.8,1.4,3.7
		c1,1,2.1,1.4,3.6,1.4c1.2,0,2.2-0.2,2.9-0.6l0.1,1.8c-0.8,0.3-1.8,0.5-3,0.5c-2.1,0-3.8-0.6-5.1-1.9s-1.9-2.9-1.9-5
		c0-2,0.7-3.7,2-4.9c1.3-1.3,3-1.9,5-1.9C39.3,0.1,40.3,0.3,41.2,0.6z"/>
	<path class="st0" d="M46.2,13.6V2H42V0.3h10.2V2h-4.1v11.6H46.2z"/>
	<path class="st0" d="M54,13.6V0.3h3.3c0.6,0,1.1,0,1.6,0.1c0.4,0,0.9,0.1,1.4,0.3c0.3,0.1,0.7,0.3,1,0.6s0.6,0.6,0.8,1.1
		s0.3,1,0.3,1.6c0,0.8-0.3,1.5-0.8,2s-1.2,0.9-2,1l0,0c0.3,0.1,0.5,0.3,0.7,0.4c0.2,0.2,0.4,0.5,0.6,0.9l2.5,5.3h-2.2l-2-4.6
		c-0.1-0.3-0.3-0.6-0.4-0.8c-0.2-0.2-0.3-0.3-0.5-0.4s-0.4-0.1-0.5-0.1c-0.2,0-0.4,0-0.7,0H56v5.9H54z M55.9,2v4h1.6
		c0.9,0,1.6-0.2,2.1-0.6s0.8-0.9,0.8-1.5s-0.2-1.1-0.7-1.4S58.6,2,57.7,2H55.9z"/>
	<path class="st0" d="M64.2,13.6l5.7-13.3h2l5.6,13.3h-2.1L74,10.3h-6.5l-1.4,3.3H64.2z M73.5,8.7l-2.6-6.6l-2.6,6.6H73.5z"/>
	<path class="st0" d="M79.4,13.6V0.3h1.9v11.6h5.6v1.7H79.4z"/>
	<path class="st0" d="M92.8,13.6l5.7-13.3h2l5.6,13.3H104l-1.4-3.3h-6.5l-1.4,3.3H92.8z M102,8.7l-2.6-6.6l-2.6,6.6H102z"/>
	<path class="st0" d="M108,13.6V0.3h2.6l5.9,10.7l0,0V0.3h1.9v13.3H116l-6-10.9l0,0v10.9H108z"/>
	<path class="st0" d="M120.2,13.6l5.7-13.3h2l5.6,13.3h-2.1l-1.4-3.3h-6.5l-1.4,3.3H120.2z M129.5,8.7l-2.6-6.6l-2.6,6.6H129.5z"/>
	<path class="st0" d="M135.4,13.6V0.3h1.9v11.6h5.6v1.7H135.4z"/>
	<path class="st0" d="M146.9,13.6V8l-5.2-7.7h2.2l4,6l3.9-6h2.2L148.8,8v5.6H146.9z"/>
	<path class="st0" d="M162.2,0.5l-0.3,1.8c-0.9-0.4-1.8-0.6-2.5-0.6c-0.6,0-1.2,0.1-1.6,0.4c-0.5,0.3-0.7,0.7-0.7,1.4
		c0,0.4,0.1,0.7,0.4,1.1c0.3,0.3,0.6,0.6,1.1,0.8c0.4,0.2,0.9,0.4,1.4,0.7s1,0.5,1.4,0.8s0.8,0.7,1.1,1.2s0.4,1.1,0.4,1.8
		c0,1.3-0.5,2.3-1.4,3c-0.9,0.6-2.1,1-3.5,1c-0.7,0-1.6-0.2-2.7-0.6l0.2-1.8c1.1,0.4,2,0.6,2.8,0.6c0.6,0,1.2-0.2,1.8-0.6
		c0.5-0.4,0.8-0.9,0.8-1.6c0-0.5-0.2-1-0.6-1.4c-0.4-0.4-0.9-0.7-1.4-0.9c-0.6-0.3-1.1-0.5-1.7-0.8c-0.6-0.3-1-0.7-1.4-1.2
		s-0.6-1.2-0.6-1.9c0-1.1,0.4-2,1.2-2.7c0.8-0.6,1.8-1,3.1-1C160.3,0.1,161.3,0.2,162.2,0.5z"/>
	<path class="st0" d="M165.4,13.6V0.3h1.9v13.3H165.4z"/>
	<path class="st0" d="M177,0.5l-0.3,1.8c-0.9-0.4-1.8-0.6-2.5-0.6c-0.6,0-1.2,0.1-1.6,0.4c-0.5,0.3-0.7,0.7-0.7,1.4
		c0,0.4,0.1,0.7,0.4,1.1c0.3,0.3,0.6,0.6,1.1,0.8c0.4,0.2,0.9,0.4,1.4,0.7s1,0.5,1.4,0.8s0.8,0.7,1.1,1.2s0.4,1.1,0.4,1.8
		c0,1.3-0.5,2.3-1.4,3c-0.9,0.6-2.1,1-3.5,1c-0.7,0-1.6-0.2-2.7-0.6l0.2-1.8c1.1,0.4,2,0.6,2.8,0.6c0.6,0,1.2-0.2,1.8-0.6
		c0.5-0.4,0.8-0.9,0.8-1.6c0-0.5-0.2-1-0.6-1.4c-0.4-0.4-0.9-0.7-1.4-0.9c-0.6-0.3-1.1-0.5-1.7-0.8c-0.6-0.3-1-0.7-1.4-1.2
		S170,4.4,170,3.7c0-1.1,0.4-2,1.2-2.7c0.8-0.6,1.8-1,3.1-1C175.1,0.1,176.1,0.2,177,0.5z"/>
</g>
<g>
	<path class="st0" d="M182.3,4.1c-0.4,0.4-1,0.7-1.6,0.7c-0.6,0-1.2-0.2-1.6-0.7c-0.5-0.4-0.7-1-0.7-1.6s0.2-1.2,0.7-1.6
		c0.5-0.4,1-0.7,1.6-0.7c0.6,0,1.2,0.2,1.6,0.7s0.7,1,0.7,1.6S182.8,3.7,182.3,4.1z M179.5,3.8c0.3,0.4,0.7,0.5,1.2,0.5
		c0.5,0,0.9-0.2,1.2-0.5s0.5-0.8,0.5-1.3s-0.2-1-0.5-1.3s-0.7-0.5-1.2-0.5c-0.5,0-0.9,0.2-1.2,0.5C179.1,1.5,179,2,179,2.5
		S179.1,3.5,179.5,3.8z M179.8,3.8V1.2h1c0.3,0,0.6,0.1,0.7,0.2s0.2,0.3,0.2,0.6c0,0.4-0.2,0.7-0.7,0.7l0.7,1.1h-0.5l-0.7-1.1h-0.3
		v1.1H179.8z M180.3,1.6v0.7h0.4c0.2,0,0.3,0,0.4-0.1c0.1-0.1,0.1-0.2,0.1-0.3c0-0.2-0.2-0.4-0.5-0.4H180.3z"/>
</g>
</svg>
</div>
      <sa-welcome
        slot="content"
        .dataCollectionError="${this.dataCollectionError}"
        .collectionMode="${this.sessionCollectionMode}"
        .spectrumMode="${this.sessionSpectrumMode}"
        .advancedModeEnabled="${this.advancedModeEnabled}"
        .supportedSpectrumModes=${this.connectedDevice?.supportedSpectrumModes}
        @open-file="${this.onOpenFile}"
        @add-soft-device="${this._addSoftDevice}"
        @session-type-selected="${this.onWelcomeSessionSelected}"
      ></sa-welcome>
      <vst-ui-connection-status
        slot="footer"
        .hasConnectedDevice="${!!this.connectedDevice}"
        .deviceName="${this.connectedDevice ? this.connectedDevice.displayName : ''}"
        .disconnectedText="${getText('No Spectrometer connected.')}"
        .connectionActionText="${getText('Connect a Spectrometer')}"
        .showDisconnectButton="${this.connectedDevice && this.connectedDevice.type === 'bluetooth'}"
        ?getAttention="${this.connectionStatusAttention}"
        @device-info-clicked="${this.showDeviceInfo}"
        @connect-btn-clicked="${this._chooseDevice}"
        @disconnect-device-clicked="${this.disconnectDevice}"
      ></vst-ui-connection-status>
              `,
                    )}
                  </vst-ui-dialog>
                `,
              )}

              <vst-ui-dialog
                id="device_manager_dialog"
                ?open="${this.showDeviceManagerDialog}"
                @dialog-close="${this._closeDeviceManager}"
              >
                ${conditionalTemplate(
                  this.showDeviceManagerDialog,
                  html`<h2 slot="header">${getText('Spectrometers')}</h2>
                    <vst-core-device-manager
                      slot="content"
                      requireOneDevice
                      .text="${SaApp.deviceManagerStrings()}"
                      .deviceInfoTitleText="${getText('Spectrometer Information')}"
                      .webUSBOptions=${webUSBOptions}
                      .windowsButNot10Message=${getText(
                        'This version of Windows supports only USB connection to the Go Direct SpectroVis Plus.',
                      )}
                      @complete-workflow="${this._closeDeviceManager}"
                    >
                    </vst-core-device-manager> `,
                )}
              </vst-ui-dialog>

              <vst-ui-dialog
                id="presentation_dialog"
                ?open="${this.showPresentation}"
                @dialog-close="${() => this._togglePresentationDialog(false)}"
              >
                ${conditionalTemplate(
                  this.showPresentation,
                  html`<h2 slot="header">${getText('App Preferences')}</h2>
                    <vst-core-accessibility slot="content"></vst-core-accessibility> `,
                )}
              </vst-ui-dialog>

              <vst-ui-dialog
                @dialog-close="${() => this._toggleWavelengthSelectorDialog(false)}"
                id="wavelength_selector_dialog"
                ?open="${this.showWavelengthSelectorDialog}"
              >
                ${conditionalTemplate(
                  this.showWavelengthSelectorDialog,
                  html`<h2 slot="header">${getText('Choose a Wavelength')}</h2>
                    <sa-wavelength-chooser
                      slot="content"
                      .spectrumInfo="${this._sessionSpectrumInfo}"
                      spectrumMode="${this.sessionSpectrumMode}"
                      @update-spec-wavelength="${this.updateSpecWavelength}"
                      @show-calibrate="${() => this._toggleCalibrateDialog(true)}"
                      @save-wavelength="${this.saveWavelength}"
                      @led-wavelength-changed="${e => this._ledWavelengthChanged(e.detail)}"
                    >
                    </sa-wavelength-chooser> `,
                )}
              </vst-ui-dialog>
              <div class="popover-wrapper" id="popover_wrapper"></div>
              <div id="toast_wrapper"></div>
              <vst-ui-wait hidden></vst-ui-wait>
              <slot name="tooltip_wrapper"></slot>
            </div>
          `
        : ''}

      <vst-ui-dialog
        ?open="${this.showAboutDialog}"
        @dialog-close="${() => this._toggleAboutDialog(false)}"
      >
        ${conditionalTemplate(
          this.showAboutDialog,
          html`
            <h2 slot="header">${getText('About')}</h2>
            <sa-about slot="content"></sa-about>
          `,
        )}
      </vst-ui-dialog>

      <vst-ui-dialog
        ?open="${this.showChangelogDialog}"
        @dialog-close="${() => this._toggleChangelogDialog(false)}"
      >
        <h2 slot="header">${getText(`What's New`)}</h2>
        <vst-ui-changelog slot="content" .releases="${changelog}"></vst-ui-changelog>
      </vst-ui-dialog>

      <vst-ui-dialog
        ?open="${this.showCalibrate}"
        @dialog-close="${() => this._completeCalibration()}"
      >
        ${conditionalTemplate(
          this.showCalibrate,
          html`
            <h2 slot="header">${getText('Calibrate')}</h2>
            <sa-calibrate
              slot="content"
              @dialog-close="${() => {
                this._toggleCalibrateDialog(false);
                this._reapplySpecSettings();
              }}"
              tabindex="0"
            ></sa-calibrate>
          `,
          '',
        )}
      </vst-ui-dialog>

      <vst-ui-dialog-manager>
        ${this.dialogs.templates.map(template => template.call(this))}
      </vst-ui-dialog-manager>
    `;
  }
}

customElements.define('sa-app', SaApp);

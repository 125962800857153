import { LitElement, html } from 'lit';
import { classMap } from 'lit/directives/class-map.js';
import { when } from 'lit/directives/when.js';
import { ContextConsumer } from '@lit-labs/context';
import { getText } from '@utils/i18n.js';
import { globalStyles } from '@components/styles/vst-style-global.css.js';
import { overflowVertical } from '@components/vst-ui-icon/index.js';
import { menuStyles } from '@styles/vst-style-menu/vst-style-menu.css.js';
import graphOptionsContext from '@common/contexts/graph-options-context.js';
import { OutsideClickReceiver } from '@mixins/outside-click-receiver-mixin.js';
import vstUiGraphContextMenuStyles from './vst-ui-graph-context-menu.css.js';
import '@components/vst-ui-icon/vst-ui-icon.js';

export class VstUiGraphContextMenu extends OutsideClickReceiver(LitElement) {
  static get properties() {
    return {
      // #region private state reactive properties
      _hidden: { state: true },
      _leftAttached: { state: true },
      // #endregion
      // #region public reactive properties
      // #endregion
    };
  }

  static get styles() {
    return [globalStyles, menuStyles, vstUiGraphContextMenuStyles];
  }

  constructor() {
    super();

    /**
     * The Gestures lib's `track` event also emits a click that will bubble up
     * to the document and close the opening menu immediately. Workaround is to
     * ignore the first outside click.
     * @type {Boolean}
     */
    this._ignoreFirstOutsideClick = true;

    this._attachmentObserver = null;

    /** @type {{ value: import('@common/contexts/graph-options-context').GraphOptionsContextValue}} */
    this._graphContext = new ContextConsumer(this, graphOptionsContext, undefined, true);
  }

  // #region Lit Lifecycle Callbacks
  disconnectedCallback() {
    super.disconnectedCallback();

    const menuContainer = this.shadowRoot.querySelector('.menu-container');
    this._attachmentObserver.unobserve(menuContainer);
  }

  firstUpdated() {
    this._setUpMenuIntersectionObserver();
  }
  // #endregion

  // #region getters/setters
  // #endregion

  // #region private methods
  _createMenuItem({ disabled = false, id, text } = {}) {
    return html`
      <li role="presentation">
        <button
          id="${id}-button"
          class="menu-item"
          ?disabled=${disabled}
          role="menuitem"
          @click=${e => this._dispatchGraphToolsEvent(e, id)}
        >
          ${text}
        </button>
      </li>
    `;
  }

  _dispatchGraphToolsEvent(e, type) {
    this.dispatchEvent(
      new CustomEvent('graph-tools-item-clicked', {
        composed: true,
        bubbles: true,
        detail: {
          target: e.target,
          type,
        },
      }),
    );
  }

  _handleClose() {
    this._hidden = true;
  }

  /**
   * Sets up an IntersectionObserver to check if the menu container is all
   * the way inside the plot area
   */
  _setUpMenuIntersectionObserver() {
    const menuContainer = this.shadowRoot.querySelector('.menu-container');
    const plotBox = this.parentElement;
    this._attachmentObserver = new IntersectionObserver(
      entries => {
        const targetEntry = entries.at(0);
        this._leftAttached =
          // Right side of menu container is outside the plot area
          targetEntry.intersectionRatio < 1 &&
          // Ignore when the menu container is left of the plot area
          targetEntry.boundingClientRect.left > targetEntry.rootBounds.left;
      },
      {
        root: plotBox,
        threshold: [0, 1],
      },
    );
    this._attachmentObserver.observe(menuContainer);
  }
  // #endregion

  // #region public methods
  handleOutsideClick() {
    if (!this._ignoreFirstOutsideClick) this._handleClose();
    this._ignoreFirstOutsideClick = false;
  }
  // #endregion

  render() {
    const menuClasses = { 'menu-container--left-attached': this._leftAttached };
    return html`
      <div class="menu-container ${classMap(menuClasses)}" ?hidden=${this._hidden}>
        <ul id="menu" class="menu" role="menu">
          <li role="presentation">
            <button class="menu-item" role="menuitem" @click=${this._handleClose}>
              ${getText('Close Menu')}
            </button>
          </li>
          ${when(!this._graphContext.value?.hideStatistics, () =>
            this._createMenuItem({
              id: 'statistics',
              disabled: this._graphContext.value?.disableStatistics,
              text: getText('View Statistics'),
            }),
          )}
          ${when(!this._graphContext.value?.hideIntegral, () =>
            this._createMenuItem({
              id: 'integral',
              disabled: this._graphContext.value?.disableIntegral,
              text: getText('View Integral'),
            }),
          )}
          ${when(!this._graphContext.value?.hideCurveFit, () =>
            this._createMenuItem({
              id: 'curve_fit',
              disabled: this._graphContext.value?.disableCurveFit,
              text: getText('Apply Curve Fit'),
            }),
          )}
          ${when(!this._graphContext.value?.hideFFT, () =>
            this._createMenuItem({
              id: 'fft',
              disabled: this._graphContext.value?.disableFFT,
              text: getText('Apply FFT'),
            }),
          )}
          ${when(!this._graphContext.value?.hideHistogram, () =>
            this._createMenuItem({
              id: 'histogram',
              disabled: this._graphContext.value?.disableHistogram,
              text: getText('Apply Histogram'),
            }),
          )}
          ${when(!this._graphContext.value?.hideStrikethrough, () =>
            this._createMenuItem({
              id: 'strikethrough',
              disabled: this._graphContext.value?.disableStrikethrough,
              text: getText('Strikethrough'),
            }),
          )}
          ${when(!this._graphContext.value?.hideStrikethrough, () =>
            this._createMenuItem({
              id: 'restore-all-data',
              disabled: this._graphContext.value?.disableRestoreAll,
              text: getText('Restore All'),
            }),
          )}
          ${when(!this._graphContext.value?.hideAddAnnotation, () =>
            this._createMenuItem({
              id: 'annotation',
              disabled: this._graphContext.value?.disableAddAnnotation,
              text: getText('Add Annotation'),
            }),
          )}
        </ul>
      </div>
      ${when(
        this._hidden,
        () => html`
          <button
            id="show-menu-button"
            type="button"
            aria-haspopup="menu"
            aria-controls="menu"
            @click=${() => {
              this._hidden = false;
            }}
          >
            <vst-ui-icon
              .icon=${overflowVertical}
              color="var(--vst-color-fg-primary)"
            ></vst-ui-icon>
            <span visually-hidden>${getText('Show Menu')}</span>
          </button>
        `,
      )}
    `;
  }
}
customElements.define('vst-ui-graph-context-menu', VstUiGraphContextMenu);

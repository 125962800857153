import { createCollection } from '../../../_internal/createCollection.js';
import { checkId } from '../checkId.js';
import { priv } from '../priv.js';
import { renderDataSet } from '../render/renderDataSet.js';
import { renderClear } from '../render/renderClear.js';
import { createDataSet } from './createDataSet.js';
import { expandColumns } from '../expandColumns.js';

export const createDataSetCollection = ({ dataSetGrid, cellResolver, interactionContext }) => {
  const dataSets = createCollection();

  let totalColumns = [];
  let readonly = true;

  const getColumns = () => totalColumns.slice(0);

  const getColspan = () => totalColumns.length;

  const getRawColumns = () => totalColumns;

  const getDataSets = () => dataSets.toList();

  const resolvePositions = () => {
    totalColumns = [];
    readonly = true;
    let gridPosition = 0;
    for (let dataSetPosition = 0; dataSetPosition < dataSets.length; dataSetPosition++) {
      const dataSet = dataSets.findByIndex(dataSetPosition);
      dataSet[priv].setGridPosition(gridPosition);
      dataSet[priv].setPosition(dataSetPosition);
      const { columns } = dataSet[priv];

      for (let columnPosition = 0; columnPosition < columns.length; columnPosition++) {
        const column = columns.findByIndex(columnPosition);
        column[priv].setGridPosition(gridPosition);
        column[priv].setPosition(columnPosition);
        gridPosition++;
        readonly = readonly && column.readonly;
        totalColumns.push(column);
      }
    }
  };

  const addDataSet = ({ id, context, columns: configColumns } = {}) => {
    interactionContext.clear();
    checkId(dataSets, id, 'dataset');
    const dataSet = createDataSet({
      id,
      context,
      columns: configColumns,
      dataSetGrid,
      cellResolver,
      interactionContext,
      resolvePositions,
    });

    dataSets.append(dataSet.id, dataSet);
    dataSet[priv].load();
    resolvePositions();
    const gridColumns = getColumns();
    const maxLength = expandColumns(gridColumns);

    renderDataSet({ dataSet, gridColumns, maxLength });

    return dataSet;
  };

  const getDataSet = id => dataSets.findByKey(id);
  const getLength = () => dataSets.length;
  const getReadonly = () => readonly;

  const clear = () => {
    interactionContext.clear();
    renderClear({ dataSetGrid });
    dataSets.clear();
    resolvePositions();
  };

  const refreshHeaders = () => {
    dataSets.forEach(ds => {
      ds[priv].columns.forEach(col => {
        col.refreshHeader();
      });
    });
  };

  return {
    addDataSet,
    getDataSet,
    getLength,
    getColumns,
    getColspan,
    getDataSets,
    getReadonly,
    clear,
    dataSets,
    refreshHeaders,
    getRawColumns,
  };
};

// import ResizeObserver from 'resize-observer-polyfill';
// import { debounce } from 'lodash-es';

export const createGridLayout = ({
  rowHeight,
  borderSize,
  sidebarWidth,
  scrollerSize,
  padding,
  cellWidth,

  totalHeaderRows,

  gridEl,
  prepareGrid,
}) => {
  const gridHeaderEl = gridEl.querySelector('.grid_header');
  const gridHeaderSidebarBlockEl = gridEl.querySelector('.grid_header_sidebar_block');
  const gridHeaderViewportContainerEl = gridEl.querySelector('.grid_header_viewport_container');
  const gridHeaderViewportEl = gridEl.querySelector('.grid_header_viewport');
  const gridBodyViewportEl = gridEl.querySelector('.grid_body_viewport');
  const gridBodyContainerEl = gridEl.querySelector('.grid_body_container');
  const gridBodyEl = gridEl.querySelector('.grid_body');
  const gridBodySidebarEl = gridEl.querySelector('.grid_body_sidebar');

  const px = val => `${val}px`;
  const rem = val => `${val}rem`;
  const pxToRem = val =>
    `${val / parseFloat(window.getComputedStyle(document.documentElement).fontSize)}`;
  const remToPx = val =>
    `${val * parseFloat(window.getComputedStyle(document.documentElement).fontSize)}`;

  let itemsPerScreen = 0;

  const resizeElements = () => {
    const totalHeight = gridEl.clientHeight > 100 ? gridEl.clientHeight : 0;
    const totalWidth = gridEl.clientWidth > 100 ? gridEl.clientWidth : 0;

    const headerHeight = rowHeight * totalHeaderRows;
    // TODO: scrollerSize should probably be a fixed pixel width
    const bodyWidth = totalWidth - remToPx(sidebarWidth + scrollerSize);
    const bodyHeight = totalHeight - remToPx(headerHeight + scrollerSize);

    gridHeaderSidebarBlockEl.style.width = rem(sidebarWidth);
    gridHeaderSidebarBlockEl.style.height = rem(headerHeight);

    gridHeaderViewportContainerEl.style.width = px(bodyWidth);
    gridHeaderViewportContainerEl.style.height = px(totalHeight);
    gridHeaderViewportContainerEl.style.left = rem(sidebarWidth);

    gridHeaderViewportEl.style.width = px(bodyWidth);
    gridHeaderViewportEl.style.height = px(totalHeight);

    gridBodyViewportEl.style.height = px(bodyHeight);
    gridBodyViewportEl.style['max-width'] = px(totalWidth);
    gridBodyViewportEl.style['min-width'] = px(totalWidth);
    gridBodyViewportEl.style.top = rem(headerHeight);

    gridBodySidebarEl.style['min-width'] = rem(sidebarWidth);

    gridBodyContainerEl.style['max-width'] = px(bodyWidth);
    gridBodyContainerEl.style.width = px(bodyWidth);
    gridBodyContainerEl.style.left = rem(sidebarWidth);

    gridBodyEl.style['max-width'] = px(bodyWidth);
    gridBodyEl.style.width = px(bodyWidth);

    itemsPerScreen = Math.ceil(gridBodyViewportEl.offsetHeight / remToPx(rowHeight));
  };

  const extendgridBodyEl = totalRows => {
    const gridBodyElHeight = pxToRem(totalRows * remToPx(rowHeight));
    const gridBodyElStyleHeight = rem(gridBodyElHeight);

    gridBodyContainerEl.style.height = gridBodyElStyleHeight;
    gridBodyEl.style.height = gridBodyElStyleHeight;
    gridBodySidebarEl.style.height = gridBodyElStyleHeight;
  };

  const calcColspanRem = colspan => {
    const colspanWidth = cellWidth * colspan;
    return rem(colspanWidth);
  };

  let resizeHandler = () => {};

  const resize = () => {
    resizeElements();
    const topVisibleRow = parseInt(gridBodyViewportEl.scrollTop / remToPx(rowHeight));
    resizeHandler(itemsPerScreen, topVisibleRow);
  };

  resizeElements();

  // const debouncedResize = debounce(() => {
  resize();
  // }, 200);

  const ro = new ResizeObserver(() => {
    resize();
    // debouncedResize();
  });

  ro.observe(gridEl);

  const onResize = newResizeHandler => {
    resizeHandler = newResizeHandler;
  };

  prepareGrid({ gridEl, gridHeaderEl, gridBodyViewportEl });

  return {
    extendgridBodyEl,
    calcColspanRem,

    itemsPerScreen,

    rowHeight,
    borderSize,
    sidebarWidth,
    scrollerSize,
    padding,
    cellWidth,

    onResize,
    resize,

    gridEl,
    gridHeaderEl,
    gridHeaderSidebarBlockEl,
    gridHeaderViewportContainerEl,
    gridHeaderViewportEl,
    gridBodyViewportEl,
    gridBodyContainerEl,
    gridBodyEl,
    gridBodySidebarEl,
  };
};

import { SpecDevice } from '@common/services/devicemanager/SpecDevice.js';

export const initSaServices = ({ deviceManager, dataCollection, sensorWorld, rpc }) => {
  const specDevice = new SpecDevice({
    deviceManager,
    dataCollection,
    sensorWorld,
    rpc,
  });

  return {
    specDevice,
  };
};

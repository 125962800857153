import { css } from 'lit';
import { breakpoint } from '@styles/mixins/breakpoints.css.js';

export default css`
  /*
    .menu-container is a two-column grid positioned such that the selection is
    at 50% width. When the right half extends past the parent element bounds,
    the intersection observer applies the .menu-container--left-attached class,
    which swaps the menu into the left half.
  */
  .menu-container {
    bottom: 0;
    display: none;
    grid-template-areas: 'left-side right-side';
    grid-template-columns: repeat(2, 1fr);
    left: var(--left);
    position: absolute;
    top: 0;
    transform: translate(-50%, 0%);
    width: max-content;
    z-index: calc(var(--vst-z-popover) - 1);
    align-items: start;
  }
  .menu {
    /* .menu starts on the right half, appearing right of the selection */
    grid-area: right-side;
    max-height: 100%;
    overflow-y: auto;
    pointer-events: all;
  }
  .menu-container--left-attached .menu {
    /* swap .menu to the other side, appearing inside the selection */
    grid-area: left-side;
  }
  #show-menu-button {
    background: none;
    border: none;
    left: var(--left);
    padding: 0.5rem;
    position: absolute;
    top: 0;
    transform: translateX(-100%);
  }
  .menu,
  #show-menu-button {
    z-index: calc(var(--vst-z-popover) - 1);
  }

  ${breakpoint(
    'm',
    css`
      .menu-container {
        display: grid;
      }
    `,
  )}
`;

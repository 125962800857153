import { createRenderAccumulator } from './createRenderAccumulator.js';

export const createGridApi = ({ gridLayout, rowCache, headerCache, scrollState }) => {
  const getTotalRows = () => rowCache.totalRows;
  const getTotalColumns = () => headerCache.columnDataList.length;

  const createRenderContext = (accumulator, cacheState = {}) => {
    const createInsertResolver = (headerActions, gridActions, insertAction) => {
      let descendantResolver;

      const createResolveHeaderConfig =
        insertAction =>
        ({ data, children = [] }, parent = null) => {
          const header = headerCache.createHeaderCell(data, parent);
          const appendAction = insertAction(header);
          headerActions.push(appendAction);
          headerActions.push(accum =>
            accum.queueRender(() => {
              header.update(header.headerData);
            }),
          );

          if (header.rowIndex === headerCache.totalHeaderRows - 1) {
            const rowCellList = headerCache.getRowCellList(header.rowIndex);
            if (rowCellList.isLast(header.rowCellHandle)) {
              gridActions.push(acc => rowCache.appendColumn(acc, data));
            } else {
              const currentIndex = rowCellList.indexOf(header.rowCellHandle);
              gridActions.push(acc => rowCache.insertColumn(acc, data, currentIndex));
            }
          } else {
            children.forEach(child => descendantResolver(child, header));
          }
        };

      descendantResolver = createResolveHeaderConfig(header => headerCache.createAppend(header));

      return createResolveHeaderConfig(insertAction);
    };

    const appendHeader = headerConfig => {
      const headerActions = [];
      const gridActions = [];

      const resolveHeaderConfig = createInsertResolver(headerActions, gridActions, header =>
        headerCache.createAppend(header),
      );
      resolveHeaderConfig(headerConfig);

      headerActions.forEach(action => action(accumulator));
      gridActions.forEach(action => action(accumulator));

      cacheState.refreshHeaderCache = true;
    };

    const getRenderedStartRowIndex = () => rowCache.getRenderedStartRowIndex();
    const getRenderedLastRowIndex = () => rowCache.getRenderedLastRowIndex();

    const insertHeader = (index, headerConfig) => {
      const headerActions = [];
      const gridActions = [];

      const resolveHeaderConfig = createInsertResolver(headerActions, gridActions, header =>
        headerCache.createInsert(header, index),
      );
      resolveHeaderConfig(headerConfig);

      headerActions.forEach(action => action(accumulator));
      gridActions.forEach(action => action(accumulator));

      cacheState.refreshHeaderCache = true;
    };

    const changeTotalRows = totalRows => {
      rowCache.changeTotalRows(accumulator, totalRows, headerCache.columnDataList);
    };

    const updateCell = (rowIndex, cellIndex) => {
      rowCache.updateCell(accumulator, headerCache.columnDataList, rowIndex, cellIndex);
    };

    const getHeader = index => {
      const deepestRowIndex = headerCache.totalHeaderRows - 1;
      const getChildHeader = (headerCollection, index) => {
        if (index >= headerCollection.length) {
          return null;
        }

        const header = headerCollection[index];

        const remove = () => {
          const headerActions = [];
          const gridActions = [];

          const removeHeader = currentHeader => {
            if (currentHeader.rowIndex === deepestRowIndex) {
              const rowCellList = headerCache.getRowCellList(currentHeader.rowIndex);
              const cellIndex = rowCellList.indexOf(currentHeader.rowCellHandle);
              gridActions.push(accum => rowCache.removeColumn(accum, cellIndex));
            } else {
              const { children } = currentHeader;
              const lastIndex = children.length - 1;

              for (let i = lastIndex; i >= 0; i--) {
                const childHeader = children[i];
                removeHeader(childHeader);
              }
            }
            const action = headerCache.createRemove(currentHeader);
            headerActions.push(action);
          };

          removeHeader(header);
          headerActions.forEach(action => action(accumulator));
          gridActions.forEach(action => action(accumulator));
          cacheState.refreshHeaderCache = true;
        };

        const api = {
          remove,
        };

        if (header.rowIndex === deepestRowIndex) {
          const rowCellList = headerCache.getRowCellList(header.rowIndex);
          const refreshCell = rowIndex => {
            const colIndex = rowCellList.indexOf(header.rowCellHandle);
            rowCache.updateCell(accumulator, headerCache.columnDataList, rowIndex, colIndex);
          };

          api.refreshCell = refreshCell;
        } else {
          const apiGetChildHeader = index => {
            return getChildHeader(header.children, index);
          };
          const appendChildHeader = headerConfig => {
            const headerActions = [];
            const gridActions = [];

            const resolveHeaderConfig = createInsertResolver(headerActions, gridActions, header =>
              headerCache.createAppend(header),
            );

            resolveHeaderConfig(headerConfig, header);

            headerActions.forEach(action => action(accumulator));
            gridActions.forEach(action => action(accumulator));

            cacheState.refreshHeaderCache = true;
          };
          const insertChildHeader = (index, headerConfig) => {
            const headerActions = [];
            const gridActions = [];

            const resolveHeaderConfig = createInsertResolver(headerActions, gridActions, header =>
              headerCache.createInsert(header, index),
            );

            resolveHeaderConfig(headerConfig, header);

            headerActions.forEach(action => action(accumulator));
            gridActions.forEach(action => action(accumulator));

            cacheState.refreshHeaderCache = true;
          };

          api.getChildHeader = apiGetChildHeader;
          api.appendChildHeader = appendChildHeader;
          api.insertChildHeader = insertChildHeader;
        }
        return api;
      };

      return getChildHeader(headerCache.rootHeaderCells, index);
    };

    return {
      get renderedStartRowIndex() {
        return getRenderedStartRowIndex();
      },
      get renderedLastRowIndex() {
        return getRenderedLastRowIndex();
      },

      appendHeader,
      insertHeader,
      updateCell,
      getHeader,
      changeTotalRows,
    };
  };

  const applyChanges = fn => {
    const accumulator = createRenderAccumulator();
    const cacheState = { refreshHeaderCache: false };
    const renderContext = createRenderContext(accumulator, cacheState);
    fn(renderContext);
    if (cacheState.refreshHeaderCache) {
      headerCache.resolveCacheData();
    }
    headerCache.resizeCells();
    accumulator.applyChanges();
  };

  const disableScroll = () => {
    const { gridHeaderViewportEl, gridBodyViewportEl, gridBodyEl } = gridLayout;

    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line no-multi-assign
    gridHeaderViewportEl.style.overflow =
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line no-multi-assign
      gridBodyViewportEl.style.overflow =
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line no-multi-assign
      gridBodyEl.style.overflow =
        'hidden';
  };

  const enableScroll = () => {
    const { gridHeaderViewportEl, gridBodyViewportEl, gridBodyEl } = gridLayout;

    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line no-multi-assign
    gridHeaderViewportEl.style.overflow =
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line no-multi-assign
      gridBodyViewportEl.style.overflow =
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line no-multi-assign
      gridBodyEl.style.overflow =
        '';
  };

  let scrollDisabled = false;
  const getScrollDisabled = () => scrollDisabled;
  const setScrollDisabled = value => {
    scrollDisabled = value;
    if (scrollDisabled) {
      disableScroll();
    } else {
      enableScroll();
    }
  };

  const scrollTo = (col, rowIndex, onlyOutOfFocus) => {
    if (rowIndex > getTotalRows()) {
      return;
    }

    let scrollLeft = null;
    let scrollTop = null;

    const { columnDataList } = headerCache;
    const { gridBodyViewportEl, gridBodyEl } = gridLayout;
    const firstCachedRow = rowCache.getCachedRow(0);
    const firstCachedCell = firstCachedRow.getCell(0);

    if (col !== null) {
      const colIndex = columnDataList.indexOf(col);

      if (colIndex === -1) {
        return;
      }

      const cellWidth = firstCachedCell.cellEl.offsetWidth;
      const cellLeft = cellWidth * colIndex;
      const cellRight = cellLeft + cellWidth;

      if (onlyOutOfFocus) {
        const viewportWidth = gridBodyEl.offsetWidth;
        const viewportLeft = gridBodyEl.scrollLeft;
        const viewportRight = viewportLeft + viewportWidth;

        if (cellRight > viewportRight) {
          scrollLeft = cellLeft - viewportWidth + cellWidth;
        } else if (cellLeft < viewportLeft) {
          scrollLeft = cellLeft;
        }
      } else {
        scrollLeft = cellLeft;
      }
    }

    if (rowIndex !== null) {
      const rowHeight = firstCachedRow.rowEl.offsetHeight; // as pixels
      const cellTop = rowHeight * rowIndex;
      const cellBottom = cellTop + rowHeight;

      if (onlyOutOfFocus) {
        const viewportHeight = gridBodyViewportEl.offsetHeight;
        const viewportTop = gridBodyViewportEl.scrollTop;
        const viewportBottom = viewportTop + viewportHeight;

        if (cellBottom > viewportBottom) {
          scrollTop = cellTop - viewportHeight + rowHeight;
        } else if (cellTop < viewportTop) {
          scrollTop = cellTop;
        }
      } else {
        scrollTop = cellTop;
      }
    }

    if (scrollLeft !== null) {
      scrollState.suppressUiNotfication();
      gridBodyEl.scrollLeft = scrollLeft;
    }
    if (scrollTop !== null) {
      scrollState.suppressUiNotfication();
      gridBodyViewportEl.scrollTop = scrollTop;
    }
  };

  const getRelativePosition = (clientX, clientY) => {
    const { gridBodyEl, gridBodyViewportEl } = gridLayout;
    const widthRect = gridBodyEl.getBoundingClientRect();
    const heightRect = gridBodyViewportEl.getBoundingClientRect();

    const isLeft = clientX < widthRect.left;
    const isRight = clientX > widthRect.right;
    const isTop = clientY < heightRect.top;
    const isBottom = clientY > heightRect.bottom;
    const isInside = !isLeft && !isRight && !isTop && !isBottom;

    const equals = relativePosition =>
      relativePosition.isLeft === isLeft &&
      relativePosition.isRight === isRight &&
      relativePosition.isTop === isTop &&
      relativePosition.isBottom === isBottom;

    return {
      isLeft,
      isRight,
      isTop,
      isBottom,
      isInside,
      equals,
    };
  };

  const getRelativeColumnInfo = col => {
    const { gridBodyEl } = gridLayout;
    const { columnDataList } = headerCache;
    const firstCachedRow = rowCache.getCachedRow(0);
    const firstCachedCell = firstCachedRow.getCell(0);

    const colIndex = columnDataList.indexOf(col);

    if (colIndex === -1) {
      return {};
    }

    const cellWidth = firstCachedCell.cellEl.offsetWidth;
    const cellLeft = cellWidth * colIndex;
    const cellRight = cellLeft + cellWidth;

    const viewportWidth = gridBodyEl.offsetWidth;
    const viewportLeft = gridBodyEl.scrollLeft;
    const viewportRight = viewportLeft + viewportWidth;

    return {
      outsideRight: cellRight > viewportRight,
      outsideLeft: cellLeft < viewportLeft,
    };
  };

  const getRelativeRowInfo = rowIndex => {
    if (rowIndex > getTotalRows()) {
      return {};
    }

    const { gridBodyViewportEl } = gridLayout;
    const firstCachedRow = rowCache.getCachedRow(0);
    const rowHeight = firstCachedRow.rowEl.offsetHeight; // as pixels
    const cellTop = rowHeight * rowIndex;
    const cellBottom = cellTop + rowHeight;
    const viewportHeight = gridBodyViewportEl.offsetHeight;
    const viewportTop = gridBodyViewportEl.scrollTop;
    const viewportBottom = viewportTop + viewportHeight;

    return {
      outsideBottom: cellBottom > viewportBottom,
      outsideTop: cellTop < viewportTop,
    };
  };

  const getCellElement = (columnIndex, rowIndex) => {
    return rowCache.getCellElement(columnIndex, rowIndex);
  };

  const getRowSidebarElement = rowIndex => {
    return rowCache.getRowSidebarElement(rowIndex);
  };

  const getHeaderElement = (...indices) => {
    return headerCache.getHeaderElement(...indices);
  };

  const focus = () => {
    const { gridEl } = gridLayout;
    gridEl.tabIndex = 1;
    gridEl.focus();
  };

  const getTopVisibleRowIndex = () => {
    const { gridBodyViewportEl, rowHeight } = gridLayout;
    const { scrollTop } = gridBodyViewportEl;

    return Math.floor(scrollTop / rowHeight);
  };

  const getBottomVisibleRowIndex = () => {
    const { gridBodyViewportEl, rowHeight } = gridLayout;
    const { scrollTop } = gridBodyViewportEl;
    const rect = gridBodyViewportEl.getBoundingClientRect();
    const viewportBottom = scrollTop + rect.height;

    const resultRaw = viewportBottom / rowHeight;
    const resultFloor = Math.floor(resultRaw);
    const result = resultFloor - resultRaw === 0 && resultFloor > 0 ? resultFloor - 1 : resultFloor;

    return result;
  };

  const getLeftVisibleColumn = () => {
    const { gridBodyEl } = gridLayout;
    const { columnDataList } = headerCache;
    const firstCachedRow = rowCache.getCachedRow(0);
    const firstCachedCell = firstCachedRow.getCell(0);
    const cellWidth = firstCachedCell.cellEl.offsetWidth;
    const { scrollLeft } = gridBodyEl;
    const columnIndex = Math.floor(scrollLeft / cellWidth);

    return columnDataList[columnIndex];
  };

  const getRightVisibleColumn = () => {
    const { gridBodyEl } = gridLayout;
    const { columnDataList } = headerCache;
    const firstCachedRow = rowCache.getCachedRow(0);
    const firstCachedCell = firstCachedRow.getCell(0);
    const cellWidth = firstCachedCell.cellEl.offsetWidth;
    const { scrollLeft } = gridBodyEl;
    const rect = gridBodyEl.getBoundingClientRect();
    const viewportRight = scrollLeft + rect.width;

    const resultRaw = viewportRight / cellWidth;
    const resultFloor = Math.floor(resultRaw);
    const columnIndex =
      resultFloor - resultRaw === 0 && resultFloor > 0 ? resultFloor - 1 : resultFloor;

    return columnDataList[columnIndex];
  };

  // clear the states of the passed objects when necessary
  const clear = () => {
    rowCache.clear();
  };

  const resize = () => {
    gridLayout.resize();
  };

  return {
    get totalRows() {
      return getTotalRows();
    },
    get totalColumns() {
      return getTotalColumns();
    },
    get topVisibleRowIndex() {
      return getTopVisibleRowIndex();
    },
    get bottomVisibleRowIndex() {
      return getBottomVisibleRowIndex();
    },
    get leftVisibleColumn() {
      return getLeftVisibleColumn();
    },
    get rightVisibleColumn() {
      return getRightVisibleColumn();
    },
    get scrollDisabled() {
      return getScrollDisabled();
    },
    set scrollDisabled(value) {
      setScrollDisabled(value);
    },
    applyChanges,
    scrollTo,
    getRelativePosition,
    getRelativeRowInfo,
    getRelativeColumnInfo,
    getCellElement,
    getRowSidebarElement,
    getHeaderElement,
    focus,
    clear,
    resize,
  };
};

import { completeAssign } from '../../../_internal/completeAssign.js';
import { priv } from '../priv.js';
import { renderRemoveColumn } from '../render/renderRemoveColumn.js';
import { expandColumns } from '../expandColumns.js';
import { createColumnValueCollection } from './createColumnValueCollection.js';

export const createColumn = ({
  id,
  context,
  readonly = false,
  values: newValues,
  dataSet,
  cellResolver,
  interactionContext,
  resolvePositions,
} = {}) => {
  const column = {};
  const values = createColumnValueCollection({ cellResolver, column });

  const trimValues = () => {
    values.trimCollection();
  };

  const setContext = value => {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line no-param-reassign
    context = value;
    column[priv].renderContext(context);
  };

  const setTextValue = (rowIndex, textValue) => {
    const parseResult = cellResolver.textToValue(textValue);
    // User may have entered alphanumeric text.
    if (parseResult.isAlphanumeric && values.getSingle(rowIndex) !== parseResult.value) {
      // Sets the raw value, and text value override in the grid value store.
      values.setSingle(rowIndex, parseResult.value, parseResult.value);
      return true;
    }
    if (!parseResult.parseFailed && values.getSingle(rowIndex) !== parseResult.value) {
      values.setSingle(rowIndex, parseResult.value);
      return true;
    }

    return false;
  };

  const getCellData = rowIndex => {
    const { isEditing, isDeepEdit, isFocused, isSelected } = interactionContext.getCellState(
      column,
      rowIndex,
    );
    const getText = isEditing
      ? () => values.getEditText(rowIndex)
      : () => values.getReadText(rowIndex);
    const setValue = textValue => setTextValue(rowIndex, textValue);

    return {
      getText,
      setValue,

      isSelected,
      isEditing,
      isDeepEdit,
      isFocused,
    };
  };

  const scrollTo = onlyOutOfFocus => {
    dataSet.grid[priv].grid.scrollTo(column, null, onlyOutOfFocus);
  };

  const scrollToCell = (rowIndex, onlyOutOfFocus) => {
    dataSet.grid[priv].grid.scrollTo(column, rowIndex, onlyOutOfFocus);
  };

  let gridPosition = -1;

  const setGridPosition = pos => {
    gridPosition = pos;
  };

  let position = -1;

  const setPosition = pos => {
    position = pos;
  };

  const remove = () => {
    if (dataSet[priv].columns.length === 1) {
      throw Error('Datasets must have at least one column');
    }

    interactionContext.clear();
    renderRemoveColumn({ column });
    const { columns } = column.dataSet[priv];
    columns.removeByKey(column.id);
    resolvePositions();
    gridPosition = -1;
    position = -1;
  };

  const refresh = () => {
    const dataSetGrid = dataSet.grid;
    const { grid } = dataSetGrid[priv];

    grid.applyChanges(context => {
      values.refresh(rowIndex => context.updateCell(rowIndex, gridPosition));
    });
  };

  const refreshHeader = () => {
    setContext(context);
  };

  const setValues = (newValueSet = []) => {
    const newValues = newValueSet.slice(0);
    values.trimArray(newValues);
    const dataSetGrid = dataSet.grid;
    const { grid } = dataSetGrid[priv];
    const gridColumns = dataSetGrid[priv].getRawColumns();
    const currentGridLength = grid.totalRows;
    const maxLength = expandColumns(gridColumns, newValues.length);

    grid.applyChanges(context => {
      if (maxLength > currentGridLength) {
        context.changeTotalRows(maxLength);
      }

      values.setAll(newValues, rowIndex => context.updateCell(rowIndex, gridPosition));
    });
  };

  const getValues = () => {
    return values.getAll().slice(0);
  };

  const getValue = rowIndex => values[rowIndex];
  const setValue = (rowIndex, value) => {
    if (!values.matches(rowIndex, value)) {
      const dataSetGrid = dataSet.grid;
      const { grid } = dataSetGrid[priv];
      const gridColumns = dataSetGrid[priv].getRawColumns();
      values.setSingle(rowIndex, value);
      values.trimCollection();
      const currentGridLength = grid.totalRows;
      const maxLength = expandColumns(gridColumns, newValues.length);
      grid.applyChanges(context => {
        if (maxLength > currentGridLength) {
          context.changeTotalRows(maxLength);
        }
        context.updateCell(rowIndex, gridPosition);
      });
    }
  };

  const load = () => {
    values.load(newValues.slice(0));
  };

  const setReadonly = value => {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line no-param-reassign
    readonly = !!value;
    refresh();
  };

  return completeAssign(column, {
    get id() {
      return id;
    },
    get context() {
      return context;
    },
    set context(value) {
      setContext(value);
    },
    get readonly() {
      return readonly;
    },
    set readonly(value) {
      setReadonly(value);
    },
    get dataSet() {
      return dataSet;
    },
    get position() {
      return position;
    },
    get gridPosition() {
      return gridPosition;
    },

    getValues,
    setValues,
    getValue,
    setValue,

    refresh,
    refreshHeader,
    remove,
    scrollTo,
    scrollToCell,

    [priv]: {
      values,
      getCellData,
      trimValues,
      setContext: () => {},
      setPosition,
      setGridPosition,
      load,
    },
  });
};

import { action, observable, makeObservable } from 'mobx';

class GraphStore {
  constructor() {
    this.isLegendVisible = false;
    this.isMiniGraphVisible = false;
    this.isMiniGraphDisabled = true;

    makeObservable(this, {
      isLegendVisible: observable,
      isMiniGraphVisible: observable,
      isMiniGraphDisabled: observable,
      setIsLegendVisible: action,
      setIsMiniGraphVisible: action,
      setIsMiniGraphDisabled: action,
    });
  }

  setIsLegendVisible(state) {
    this.isLegendVisible = state;
  }

  setIsMiniGraphVisible(state) {
    this.isMiniGraphVisible = state;
  }

  setIsMiniGraphDisabled(state) {
    this.isMiniGraphDisabled = state;
  }
}

export const graphStore = new GraphStore();

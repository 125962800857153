import { observable, makeObservable, makeAutoObservable, action, computed } from 'mobx';
import { MultiGraphAppearance } from './MultigraphAppearance';

/**
 * @classdesc A record which contains columnId and row index(es) for either a
 * point or a range. TODO: (jk) make this private similar to how
 * MultiGraphAppearance keeps its internal class hidden in a factory method.
 */
export class TargetRecord {
  /**
   * @type {string} udm id for a column.
   */
  columnId = '';

  /**
   * @type {number} row index when annotation type is POINT
   */
  pointIndex = null;

  /**
   * @type {number} start row index when type is RANGE
   */
  rangeStartIndex = null;

  /**
   * @type {number} end row index when type is RANGE
   */
  rangeEndIndex = null;

  /**
   * Construct a TargetRecord
   * @param {Object} params
   * @param {string} params.columnId
   * @param {number} params.pointIndex
   * @param {number} params.rangeStartIndex
   * @param {number} params.rangeEndIndex
   */
  constructor({ columnId, pointIndex, rangeStartIndex, rangeEndIndex }) {
    if (columnId !== undefined) this.columnId = `${columnId}`;
    if (pointIndex !== undefined) this.pointIndex = pointIndex;
    if (rangeStartIndex !== undefined) this.rangeStartIndex = rangeStartIndex;
    if (rangeEndIndex !== undefined) this.rangeEndIndex = rangeEndIndex;

    makeAutoObservable(this);
  }

  /**
   * @returns {RawTargetData} suitable for exporting to udm
   */
  get udmExport() {
    return {
      columnId: parseInt(this.columnId),
      pointIndex: this.pointIndex,
      rangeStartIndex: this.rangeStartIndex,
      rangeEndIndex: this.rangeEndIndex,
    };
  }
}

/**
 * @enum {string} Describes an annotation's behavior.
 * @readonly
 */
export const AnnotationType = Object.freeze({
  /** free-floating, unassociated with plotted data. */
  FREE: 'freeform',
  /** associated with an index into a particular column's data */
  POINT: 'point',
  /** associated with a start and end index into a particular columm's data */
  RANGE: 'range',
});

/**
 * @classdesc Data model representing an on screen annotation.
 */
export class Annotation extends MultiGraphAppearance {
  /**
   * @type {number} unique udm identifier for this anonotation.
   */
  id = 0;

  /**
   * @type {string} the annotation's text
   */
  text = '';

  /**
   * @type {Annotation.Type} the annotation's type
   */
  type = AnnotationType.FREE;

  /**
   * @type {TargetRecord[]} array of columnIds
   */
  targetRecords = [];

  /**
   * Construct an annotation record.
   * @param {RawAnnotationData} options
   */
  constructor({ id, graphUdmId, text, type, appearanceInfo, targetRecords }) {
    super({ appearanceInfo, graphUdmId });
    if (id !== undefined) this.id = id;
    if (text !== undefined) this.text = text;
    if (type !== undefined) this.type = type;
    if (targetRecords !== undefined)
      this.targetRecords = targetRecords.map(params => new TargetRecord(params));

    makeObservable(this, {
      text: observable,
      type: observable,
      targetRecords: observable,

      udmExport: computed,

      setText: action,
      setPointTarget: action,
      setRangeTarget: action,
    });
  }

  /**
   * Add a columnId associated with a pointIndex. If a column already exists
   * with the id, then its pointIndex value will be changed to the new value.
   * @param {number} columnId udm id of the column
   * @param {number} pointIndex a row index for the column.
   */
  setPointTarget(columnId, pointIndex) {
    if (this.type === AnnotationType.RANGE) {
      console.error('Annotation is already in RANGE mode. Cannot add a POINT target.');
      return;
    }
    this.type = AnnotationType.POINT;

    const record = this.targetRecords.find(rec => rec.columnId === columnId);
    if (record) {
      record.pointIndex = pointIndex;
    } else {
      this.targetRecords.push(new TargetRecord({ columnId, pointIndex }));
    }
  }

  /**
   * Add a columnId associated with range indexes, more specifically row indexes
   * into the column representing a range of data.
   * @param {number} columnId udm id
   * @param {number} rangeStartIndex start row index
   * @param {number} rangeEndIndex end row index
   */
  setRangeTarget(columnId, rangeStartIndex, rangeEndIndex) {
    if (this.type === AnnotationType.POINT) {
      console.error('Annotation is already in POINT mode. Cannot add a RANGE target.');
      return;
    }

    console.assert(rangeStartIndex < rangeEndIndex, 'Range start must be less than range end.');

    this.type = AnnotationType.RANGE;

    const record = this.targetRecords.find(rec => rec.columnId === columnId);
    if (record) {
      record.rangeStartIndex = rangeStartIndex;
      record.rangeEndIndex = rangeEndIndex;
    } else {
      this.targetRecords.push(new TargetRecord({ columnId, rangeStartIndex, rangeEndIndex }));
    }
  }

  /**
   * Set the text observable
   * @param {string} value Value
   */
  setText(value) {
    this.text = value;
  }

  /**
   * Get the point index for a column
   * @param {number} columnId udm id of column
   * @returns {number?} row index associated with the column or null if no
   * column with that id can be found. Value only valid if annotation type is
   * POINT.
   */
  getPointIndexForColumn(columnId) {
    return this.targetRecords.find(rec => rec.columnId === columnId)?.pointIndex ?? null;
  }

  /**
   * Get the range indexes for a column.
   * @param {number} columnId udm id of column
   * @returns {{startIndex: number, endIndex: number}?} range indexes of column
   * or null if column cannot be found. Values are only valid is annotation
   * type is RANGE.
   */
  getRangeIndexesForColumn(columnId) {
    const record = this.targetRecords.find(rec => rec.columnId === columnId);
    if (record) {
      return { startIndex: record.rangeStartIndex, endIndex: record.rangeEndIndex };
    }
    return null;
  }

  /**
   * Checks target column list for the presence of a particular columnd Id.
   * @param {number} columnId id to look for
   * @returns {boolean} true if the column is in the list, else false.
   */
  containsTargetColumn(columnId) {
    return this.targetRecords.some(rec => rec.columnId === columnId);
  }

  /**
   * @type {RawAnnotationData} an object suitable for storing and retrieving
   * annotation info from the persistence store.
   */
  get udmExport() {
    return {
      ...this.mixinUdmExport,
      id: this.id,
      text: this.text,
      type: this.type,
      targetRecords: this.targetRecords.map(rec => rec.udmExport),
    };
  }
}

/**
 * @typedef {Object} RawTargetData
 * @property {number} columnId
 * @property {number} pointIndex
 * @property {number} rangeStartIndex
 * @property {number} rangeEndIndex
 */

/**
 * @typedef {Object} RawAnnotationData
 * @property {number} [id]
 * @property {string} text
 * @property {Annotation.Type} type
 * @property {Object[]} appearanceInfo (inherited from MultiGraphAppearance)
 * @property {RawTargetData[]} [targetRecords]
 */

// import * as csv from 'csv-string';
import * as csv from 'papaparse';
import { isLocaleRationalNumber } from '@utils/i18n.js';
import { priv } from './priv.js';
import { expandColumns } from './expandColumns.js';
import { toLocaleNumber, fromLocaleNumber } from './localeNumber.js';

const { cordova } = window;

/**
 * Walks active elements along the DOM and shadow DOM looking for an input.
 * @returns {boolean} true if there is an active INPUT, false if not.
 */
const _detectActiveTextInput = () => {
  const _recurse = element => {
    if (!element) return false;
    if (element.tagName === 'INPUT') return true;

    const { shadowRoot } = element;
    if (shadowRoot) return _recurse(shadowRoot.activeElement);
    return false;
  };

  // Start at the very top:
  return _recurse(document.activeElement);
};

export const createClipboardContext = ({
  getSelection,
  dataSetGrid,
  callbacks,
  cellResolver,
  allowsAlphanumeric,
}) => {
  const cordovaClipboard =
    cordova && cordova.plugins && cordova.plugins.clipboard ? cordova.plugins.clipboard : null; // TODO: it would be good to extract this out from vst-grid

  let workaroundClipboard = null;
  let openContextMenu = null;

  const closeContextMenu = () => {
    if (openContextMenu) {
      openContextMenu.closeUI();
      openContextMenu = null;
    }
  };

  const enableScroll = () => {
    const { grid } = dataSetGrid[priv];
    grid.scrollDisabled = false;
  };

  const disableScroll = () => {
    const { grid } = dataSetGrid[priv];
    grid.scrollDisabled = true;
  };

  const deleteTarget = selection => {
    const columns = selection.columns || dataSetGrid[priv].getRawColumns();
    const rowStart = selection.rows ? selection.rows.start : 0;
    const rowEnd = selection.rows ? selection.rows.end : dataSetGrid.totalRows - 1;

    const colLength = columns.length;
    const { grid } = dataSetGrid[priv];

    const changes = [];

    grid.applyChanges(context => {
      for (let colIndex = 0; colIndex < colLength; colIndex++) {
        const column = columns[colIndex];
        if (!column.readonly) {
          const { values } = column[priv];
          for (let rowIndex = rowStart; rowIndex <= rowEnd; rowIndex++) {
            const colPosition = column.gridPosition;
            values.setSingle(rowIndex, cellResolver.emptyValue);
            context.updateCell(rowIndex, colPosition);
            changes.push({ column, rowIndex, value: cellResolver.emptyValue });
          }
          values.trimCollection();
        }
      }
    });
    callbacks.onUserInputChange(changes);
  };

  const deleteAction = selection => {
    deleteTarget(selection);
    closeContextMenu();
  };

  const getCSVText = selection => {
    const columnValues = (selection.columns || dataSetGrid[priv].getRawColumns()).map(
      col => col[priv].values,
    );
    const rowStart = selection.rows ? selection.rows.start : 0;
    const rowEnd = selection.rows ? selection.rows.end : dataSetGrid.totalRows - 1;

    const selectionArray = [];
    const colLength = columnValues.length;

    for (let rowIndex = rowStart; rowIndex <= rowEnd; rowIndex++) {
      const rowArray = [];
      selectionArray.push(rowArray);
      for (let colIndex = 0; colIndex < colLength; colIndex++) {
        const values = columnValues[colIndex];
        const value = values.getSingle(rowIndex);
        if (allowsAlphanumeric) {
          const localized = toLocaleNumber(value);
          if (localized.length) rowArray.push(localized);
          else rowArray.push(value);
        } else {
          rowArray.push(toLocaleNumber(value));
        }
      }
    }

    window.csv = csv;
    return csv.unparse(selectionArray, { delimiter: '\t' });
  };

  const copyTarget = (e, selection) => {
    const csvText = getCSVText(selection);
    workaroundClipboard = csvText;

    if (e) {
      if (window.getSelection().toString() === '') e.clipboardData.setData('text/plain', csvText);
      else e.clipboardData.setData('text/plain', window.getSelection().toString());
    }
  };

  const copy = (e, selection) => {
    copyTarget(e, selection);
    closeContextMenu();
  };

  const isNativeSelected = () => !document.getSelection().isCollapsed;

  const copyHandler = e => {
    if (_detectActiveTextInput()) return;

    const selection = getSelection();
    if (selection) {
      copy(e, selection);
      if (!isNativeSelected() && e) {
        e.preventDefault();
      }
    }
  };

  const runClipboardCopy = () => {
    const selection = getSelection();
    if (!selection) return;

    if (navigator.clipboard) {
      (async () => {
        // not certain how timing might be affected in other platforms, so just for the web we move this into its own async call
        try {
          await navigator.clipboard.writeText(getCSVText(getSelection()));
        } catch (error) {
          copyHandler();
        }
      })();
    } else {
      const isCopied =
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line no-undef
        document.execCommand('copy') || (createNativeSelection() && document.execCommand('copy'));
      if (!isCopied) {
        copyHandler();
      }
    }
  };

  document.addEventListener('copy', copyHandler);
  document.addEventListener('promoted-copy', runClipboardCopy);

  const paste = (clipboardSelection, selection) => {
    // csv parser always uses ',' as back up delimiter.  Need to sanitize international numbers for it
    const swapComma = cell => (isLocaleRationalNumber(cell.trim()) ? cell.replace(',', '.') : cell);
    const _clipboardSelection = clipboardSelection
      .split('\n')
      .map(line =>
        line
          .split('\t')
          .map(cell => swapComma(cell))
          .join('\t'),
      )
      .join('\n');

    const clipboardData = csv.parse(_clipboardSelection, { delimitersToGuess: [' ', '\t'] }).data;
    const clipboardRowLength = clipboardData.length === 0 ? 0 : clipboardData[0].length;
    const clipboardColumnLength = clipboardData.length > 0 ? clipboardData.length : 0;

    const gridColumns = dataSetGrid[priv].getRawColumns();
    const selectionColumns = selection.columns || gridColumns;
    const getMatchedGridColumns = () => {
      const startIndex = gridColumns.indexOf(selectionColumns[0]);
      const endIndex = startIndex + clipboardColumnLength;
      return gridColumns.slice(startIndex, endIndex);
    };
    const columns =
      selectionColumns.length >= clipboardColumnLength ? selectionColumns : getMatchedGridColumns();

    const colLength = columns.length;
    const rowStart = selection.rows ? selection.rows.start : 0;
    const { grid } = dataSetGrid[priv];

    const changes = [];

    grid.applyChanges(context => {
      for (
        let colIndex = 0, clipboardColIndex = 0;
        clipboardColIndex < clipboardRowLength && colIndex < colLength;
        colIndex++, clipboardColIndex++
      ) {
        const column = columns[colIndex];
        const { values } = column[priv];

        if (!column.readonly) {
          for (
            let rowIndex = rowStart, clipboardRowIndex = 0;
            clipboardRowIndex < clipboardData.length;
            rowIndex++, clipboardRowIndex++
          ) {
            const clipboardRow = clipboardData[clipboardRowIndex];

            const clipboardTextValue = clipboardRow[clipboardColIndex] ?? '';
            const clipboardParseResult = cellResolver.textToValue(
              `${fromLocaleNumber(clipboardTextValue)}`,
            );
            let clipboardValue;
            const colPosition = column.gridPosition;

            if (allowsAlphanumeric) {
              // TODO: Fix this the next time the file is edited.
              // eslint-disable-next-line no-nested-ternary
              clipboardValue = clipboardParseResult.parseFailed
                ? clipboardTextValue.length && clipboardTextValue !== 'NaN'
                  ? clipboardTextValue
                  : cellResolver.emptyValue
                : clipboardParseResult.value;
              values.setSingle(rowIndex, clipboardValue, clipboardTextValue);
            } else {
              clipboardValue = !clipboardParseResult.parseFailed
                ? clipboardParseResult.value
                : cellResolver.emptyValue;
              values.setSingle(rowIndex, clipboardValue);
            }
            context.updateCell(rowIndex, colPosition);
            changes.push({ column, rowIndex, value: clipboardValue });
          }
        }

        values.trimCollection();
      }

      columns
        .filter(col => !col.readonly)
        .forEach(col => {
          col[priv].trimValues();
        });

      const currentTotalRows = dataSetGrid.totalRows;

      const maxSize = expandColumns(dataSetGrid[priv].getRawColumns());

      if (maxSize > currentTotalRows) {
        context.changeTotalRows(maxSize);
      }
      closeContextMenu();
    });

    callbacks.onUserInputChange(changes);
  };

  const pasteWorkaround = () => {
    const selection = getSelection();
    if (selection) {
      if (workaroundClipboard) {
        // fallthrough to internal copy/paste only
        paste(workaroundClipboard, selection);
      }
    }
  };

  // paste is disabled in chrome for security reasons, should work outside of webpages
  const pasteHandler = e => {
    if (_detectActiveTextInput()) return;

    const selection = getSelection();
    const clipboardCopySelection = e.clipboardData.getData('text');

    if (clipboardCopySelection && selection) {
      paste(clipboardCopySelection, selection);
    }
  };

  const promotedPasteHandler = ({ detail: clipboardCopySelection }) => {
    const selection = getSelection();
    if (clipboardCopySelection && selection) {
      paste(clipboardCopySelection, selection);
    }
  };

  document.addEventListener('paste', pasteHandler);
  document.addEventListener('promoted-paste', promotedPasteHandler);

  const cut = (e, selection) => {
    copyTarget(e, selection);
    deleteTarget(selection);
    closeContextMenu();
  };

  const cutHandler = e => {
    if (_detectActiveTextInput()) return;

    const selection = getSelection();
    if (selection) {
      cut(e, selection);

      if (!isNativeSelected() && e) {
        e.preventDefault();
      }
    }
  };

  const promotedCutHandler = () => {
    const selection = getSelection();
    if (selection) {
      runClipboardCopy();
      deleteTarget(selection);
      closeContextMenu();
    }
  };

  document.addEventListener('cut', cutHandler);
  document.addEventListener('promoted-cut', promotedCutHandler);

  let executeDelete;

  const toggleSelectContextMenu = ({ clientX, clientY, cellEl, isTouch, onContextClose }) => {
    if (openContextMenu) {
      openContextMenu.closeUI();
      openContextMenu = null;

      if (isTouch) {
        return;
      }
    }

    openContextMenu = {
      closeUI: () => {
        onContextClose();
        if (openContextMenu.customCloseFn) {
          openContextMenu.customCloseFn();
          openContextMenu.clearClipboardHandle();
        }
        enableScroll();
      },
    };

    let actionSelected = false;
    let clipboard;

    const clearClipboardHandle = () => {
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line no-multi-assign
      clipboard.copy =
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line no-multi-assign
        clipboard.cut =
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line no-multi-assign
        clipboard.paste =
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line no-multi-assign
        clipboard.delete =
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line no-multi-assign
        clipboard.close =
          () => {};
    };

    const closeUI = () => {
      if (openContextMenu && openContextMenu.clipboard === clipboard) {
        openContextMenu.closeUI();
      }
      clearClipboardHandle();
    };

    // safari requires a native selection for execCommand('copy')
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line no-unused-vars, consistent-return
    const createNativeSelection = () => {
      if (!isNativeSelected()) {
        const selection = document.getSelection();
        const selectionNode = document.createElement('textarea');
        const csvText = getCSVText(getSelection());
        selectionNode.value = csvText;
        document.body.appendChild(selectionNode);
        setTimeout(() => document.body.removeChild(selectionNode));

        const range = document.createRange();
        range.selectNode(selectionNode);
        selection.addRange(range);

        return !selection.isCollapsed;
      }
    };

    // Performs the copy operation using the cordova clipboard.
    const cordovaCopy = () => {
      const selection = getSelection();
      const csvText = getCSVText(selection);
      if (cordovaClipboard) {
        cordovaClipboard.copy(csvText);
      }
    };

    // Performs the cut operation using the cordova clipboard.
    const cordovaCut = () => {
      cordovaCopy();

      const selection = getSelection();
      deleteTarget(selection);
    };

    // Performs the paste operation from the cordova clipboard.
    const cordovaPaste = () => {
      if (cordovaClipboard) {
        cordovaClipboard.paste(text => {
          const selection = getSelection();
          if (text && selection) {
            paste(text, selection);
          }
        });
      }
    };

    // Returns true if cordova clipboard operations are available to this runtime.
    const isCordovaAvailable = () => {
      return cordovaClipboard !== null;
    };

    clipboard = {
      get isTouch() {
        return isTouch;
      },
      copy: () => {
        if (actionSelected) {
          return;
        }
        actionSelected = true;

        if (isCordovaAvailable()) {
          cordovaCopy();
          closeContextMenu();
        } else {
          runClipboardCopy();
        }
      },
      cut: () => {
        if (actionSelected) {
          return;
        }
        actionSelected = true;

        if (isCordovaAvailable()) {
          cordovaCut();
          closeContextMenu();
        } else {
          const selection = getSelection();
          if (selection) {
            runClipboardCopy();
            deleteTarget(selection);
          }
        }
      },
      paste: onUnsupportedCallback => {
        if (actionSelected) {
          return;
        }
        actionSelected = true;

        if (isCordovaAvailable()) {
          cordovaPaste();
          closeContextMenu();
        } else if (
          (navigator.clipboard || {}).readText &&
          !((window.chrome || {}).app || {}).runtime
        ) {
          (async () => {
            const clipboardText = await navigator.clipboard.readText();
            const selection = getSelection();
            paste(clipboardText, selection);
          })();
        } else {
          const isPasted = document.execCommand('paste');

          if (!isPasted) {
            if (workaroundClipboard) pasteWorkaround();
            else {
              // (눈_눈) Firefox doesn't support programmatic paste in any form, so we need a notification to tell them to use the keyboard (if they can)
              // TODO: Fix this the next time the file is edited.
              // eslint-disable-next-line no-lonely-if
              if (onUnsupportedCallback && typeof onUnsupportedCallback === 'function')
                onUnsupportedCallback();
            }
          }
        }
      },
      delete: () => {
        if (actionSelected) {
          return;
        }
        actionSelected = true;

        executeDelete();
      },
      close: () => {
        closeUI();
      },
      onClose: fn => {
        openContextMenu.customCloseFn = fn;
      },
    };

    openContextMenu.clipboard = clipboard;
    openContextMenu.clearClipboardHandle = clearClipboardHandle;

    disableScroll();
    callbacks.onShowSelectContextMenu({ cellEl, clientX, clientY, clipboard });
  };

  executeDelete = () => {
    const selection = getSelection();
    if (selection) {
      deleteAction(selection);
    }
    closeContextMenu();
  };

  const getMenuOpen = () => {
    return !!openContextMenu;
  };

  return {
    get menuOpen() {
      return getMenuOpen();
    },

    toggleSelectContextMenu,
    executeDelete,
    closeContextMenu,
  };
};

export const applyVerticalScroll = ({
  rowCache,
  headerCache,
  gridLayout,
  accumulator,
  scrollState,
}) => {
  const { gridBodyViewportEl, rowHeight } = gridLayout;

  const { onScroll } = scrollState;

  const onVerticalScroll = e => {
    const { scrollTop } = e.target;
    const baseFontSize = parseFloat(window.getComputedStyle(document.documentElement).fontSize);
    const currentTopRow = parseInt(scrollTop / (rowHeight * baseFontSize));
    const { columnDataList } = headerCache;

    onScroll();

    if (rowCache.rerender(accumulator, columnDataList, currentTopRow)) {
      accumulator.applyChanges();
    }

    if (e.preventDefault) {
      e.preventDefault();
    }
  };

  gridBodyViewportEl.addEventListener('scroll', onVerticalScroll);
};

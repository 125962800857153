import { action, observable, computed, makeObservable } from 'mobx';
import { fitType } from '@services/analysis/api/fitType.js';
import { getText } from '@utils/i18n.js';

import { formatter } from '@utils/formatter.js';

const sigfig = 4;

const formattedValue = value => {
  return formatter.sigFig(value, sigfig);
};

/**
 * A MobX store for curve fits
 */
class VstCurvefitStore {
  constructor() {
    // TODO: find better way to handle this
    this._releaseHandle = async () => {};
    this._updateCustomFit = async () => {};

    this.builtins = [
      {
        type: 'PROPORTIONAL',
        name: getText('Proportional', 'mathematical', 'curvefit'),
        y: `ax`,
        coefficients: ['a'],
        id: fitType.PROPORTIONAL,
      },
      {
        type: 'LINEAR',
        name: getText('Linear', 'mathematical', 'curvefit'),
        y: `mx + b`,
        coefficients: ['m', 'b'],
        id: fitType.LINEAR,
      },
      {
        type: 'QUADRATIC',
        name: getText('Quadratic', 'mathematical', 'curvefit'),
        y: `ax^2 + bx + c`,
        coefficients: ['a', 'b', 'c'],
        id: fitType.QUADRATIC,
      },
      {
        type: 'POWER',
        name: getText('Power', 'mathematical', 'curvefit'),
        y: `ax^b`,
        coefficients: ['a', 'b'],
        id: fitType.POWER,
      },
      {
        type: 'INVERSE',
        name: getText('Inverse', 'mathematical', 'curvefit'),
        y: `a/x`,
        coefficients: ['a'],
        id: fitType.INVERSE,
      },
      {
        type: 'INVERSE_SQUARED',
        name: getText('Inverse Squared', 'mathematical', 'curvefit'),
        y: `a/x^2`,
        coefficients: ['a'],
        id: fitType.INVERSE_SQUARED,
      },
      {
        type: 'NATURAL_EXPONENT',
        name: getText('Natural Exponent', 'mathematical', 'curvefit'),
        y: `a exp(-cx) + b`,
        coefficients: ['a', 'b', 'c'],
        id: fitType.NATURAL_EXPONENT,
      },
      {
        type: 'NATURAL_LOG',
        name: getText('Natural Log', 'mathematical', 'curvefit'),
        y: `a * ln(bx)`,
        coefficients: ['a', 'b'],
        id: fitType.NATURAL_LOG,
      },
      {
        type: 'SINE',
        name: getText('Sine', 'mathematical', 'curvefit'),
        y: `a * sin(bx + c) + d`,
        coefficients: ['a', 'b', 'c', 'd'],
        id: fitType.SINE,
      },
      {
        type: 'COSINE',
        name: getText('Cosine', 'mathematical', 'curvefit'),
        y: `a * cos(bx + c) + d`,
        coefficients: ['a', 'b', 'c', 'd'],
        id: fitType.COSINE,
      },
      {
        type: 'COSINE_SQUARED',
        name: getText('Cosine Squared', 'mathematical', 'curvefit'),
        y: `a * cos(bx + c)^2 + d`,
        coefficients: ['a', 'b', 'c', 'd'],
        id: fitType.COSINE_SQUARED,
      },
    ];

    this.customs = [];

    makeObservable(this, {
      builtins: observable,
      customs: observable,
      fits: computed,
      updateFit: action,
      removeFit: action,
      removeAllFits: action,
      _newFit: action,
    });
  }

  get fits() {
    return [...this.builtins, ...this.customs];
  }

  getFit(type) {
    return (
      this.customs.find(fit => fit.type === type) || this.builtins.find(fit => fit.type === type)
    );
  }

  /**
   * Get fit by id (type enum)
   *
   * @param {fitType} id fit type
   * @return {Object} fit
   */
  getFitById(id) {
    return [...this.customs, ...this.builtins].find(fit => fit.id === id);
  }

  _getCustomFit(type) {
    return this.customs.find(fit => fit.type === type);
  }

  getFitInfo(type, { coefficients = [], uncertainties = [], correlation = null, rmse = 0 }) {
    const result = {
      y: '',
      rmse: 0,
      coefficients: [],
    };

    const fit = this.getFit(type);
    if (fit) {
      result.y = fit.y;
      result.rmse = formattedValue(rmse);

      if (coefficients) {
        for (let i = 0; i < fit.coefficients.length; i++) {
          const value = formattedValue(coefficients[i]);
          const uncertainty = formattedValue(uncertainties[i]);
          result.coefficients.push({ name: fit.coefficients[i], value, uncertainty });
        }
      }

      if (Number.isFinite(correlation)) {
        result.r = formattedValue(correlation);
      }
    }

    return result;
  }

  _newFit({ type, y, handle, coefficients }) {
    const fit = {
      type,
      id: fitType.CUSTOM,
      name: type,
      y,
      handle,
      coefficients,
    };
    this.customs.push(fit);
    return fit;
  }

  async updateFit({ type, y, handle, coefficients }) {
    let fit = this._getCustomFit(type);
    if (fit) {
      await this._releaseHandle(fit.handle);
      // due to how mobx tracks object properties we aren't able to use Object.assign to update these props
      fit.type = type;
      fit.y = y;
      fit.handle = handle;
      fit.coefficients = coefficients;
    } else {
      fit = this._newFit({ type, y, handle, coefficients });
    }

    await this._updateCustomFit(type, y);

    return fit;
  }

  async removeFit(type) {
    const fit = this._getCustomFit(type);
    await this._releaseHandle(fit.handle);
    this.customs.remove(fit);
  }

  async removeAllFits() {
    const fits = this.customs.slice();
    for (let i = 0; i < fits.length; ++i) {
      const fit = fits[i];
      // eslint-disable-next-line no-await-in-loop
      await this.removeFit(fit.type);
    }
  }
}

export const vstCurvefitStore = new VstCurvefitStore();

import { throttle } from 'lodash-es';
import * as Gestures from '@polymer/polymer/lib/utils/gestures.js';
import { LitElement, css, html } from 'lit';
import { globalStyles } from '@styles/vst-style-global.css.js';

export class VstUiDraggable extends LitElement {
  static get properties() {
    return {
      dragContainer: {
        type: Object,
      },
      xPos: {
        type: Number,
      },
      yPos: {
        type: Number,
      },
      draggableId: {
        type: Number,
      },
      emitPositionOn: {
        type: String,
      },
    };
  }

  constructor() {
    super();
    this.dragContainer = document.body; // use document as default container
    this.draggableId = 0;
    Gestures.addListener(this, 'track', e => this.dragHandler(e));
    this.emitPositionOn = '';
  }

  firstUpdated() {
    setTimeout(() => {
      this.throttleEnforceContainment = throttle(this.enforceContainment.bind(this), 25);
      this.dragContainer.addEventListener('resize', this.throttleEnforceContainment);
    }, 100);

    this.shadowRoot.querySelector('slot').addEventListener('slotchange', () => {
      this.slottedElements = Array.from(this.children);
    });
  }

  connectedCallback() {
    super.connectedCallback();
    if (this.emitPositionOn) {
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line wc/require-listener-teardown
      this.addEventListener(`${this.emitPositionOn}`, () => {
        this.emitNewPosition({ isInitialPlacement: true, dragEnd: false });
      });
    }
  }

  disconnectedCallback() {
    this.dragContainer.removeEventListener('resize', this.throttleEnforceContainment);
  }

  updated(changedProperties) {
    changedProperties.forEach((oldValue, propName) => {
      switch (propName) {
        case 'xPos':
          this._xPosChanged();
          break;
        case 'yPos':
          this._yPosChanged();
          break;
        default:
      }
    });
  }

  emitNewPosition({ isInitialPlacement = false, dragEnd = false } = {}) {
    const draggableRect = this.getBoundingClientRect();
    const containerRect = this.dragContainer.getBoundingClientRect();

    // fixed position
    // drag end has bad get boudning client rect results, so use props instead
    const draggableYCenter = !dragEnd
      ? draggableRect.top + draggableRect.height / 2
      : this.yPos + draggableRect.height / 2;

    const draggableRight = !dragEnd ? draggableRect.right : this.xPos + draggableRect.width;

    this.dispatchEvent(
      new CustomEvent('draggable-moved', {
        bubbles: true,
        composed: true,
        detail: {
          isInitialPlacement,
          draggableId: this.draggableId,
          maxTranslate: this.maxTranslate,
          draggableRect,
          containerRect,
          absolutePosition: {
            topLeft: {
              x: draggableRect.left - containerRect.left,
              y: draggableRect.top - containerRect.top,
            },
            centerRight: {
              x: draggableRight - containerRect.left,
              y: draggableYCenter - containerRect.top,
            },
          },
        },
      }),
    );
  }

  dragHandler(e) {
    const { dragContainer } = this;

    const draggingEl = this;
    const containerEl = dragContainer;

    const event = e.detail;
    const { state, dx, dy } = event;
    let translateX = 0;
    let translateY = 0;

    if (state === 'start') {
      this.slottedElements.forEach(element => {
        element.dragging = true;
      });
      draggingEl.style.willChange = 'transform';

      this.containerRect = containerEl.getBoundingClientRect();
      this.draggingRect = draggingEl.getBoundingClientRect();

      // from here on out the element will be fixed position
      draggingEl.style.position = 'fixed';
      this.xPos = this.draggingRect.left;
      this.yPos = this.draggingRect.top;

      this.maxTranslate = {
        left: this.containerRect.left - this.draggingRect.left,
        right: this.containerRect.right - this.draggingRect.right,
        top: this.containerRect.top - this.draggingRect.top,
        bottom: this.containerRect.bottom - this.draggingRect.bottom,
      };
    } else if (state === 'track') {
      requestAnimationFrame(() => {
        // X
        // if our translate is within the bounds, move it
        if (dx && this.maxTranslate.left <= dx && dx <= this.maxTranslate.right) {
          translateX = dx;
        } else if (this.maxTranslate.left > dx) {
          // if we're too far left, set it to left edge
          translateX = this.maxTranslate.left;
        } else if (this.maxTranslate.right < dx) {
          // if we're too far right, set it to right edge
          translateX = this.maxTranslate.right;
        }

        // Y
        if (this.maxTranslate.top <= dy && dy <= this.maxTranslate.bottom) {
          translateY = dy;
        } else if (this.maxTranslate.top > dy) {
          translateY = this.maxTranslate.top;
        } else if (this.maxTranslate.bottom < dy) {
          translateY = this.maxTranslate.bottom;
        }

        draggingEl.style.transform = `translate(${translateX}px, ${translateY}px)`;

        // if (hasTail) {
        //   // determine the center of the box
        //   const draggingCenterX = this.draggingRect.right + translateX - (this.draggingRect.width / 2);
        //   const draggingCenterY = this.draggingRect.bottom + translateY - (this.draggingRect.height / 2);
        //   // fire these up as an event, include draggingEl. Analysis controller listens and passes the coords to draggingEl which will handle it's own tail
        // }
      });
    } else if (state === 'end') {
      this.draggingRect = draggingEl.getBoundingClientRect();

      setTimeout(() => {
        // timeout hack because sometimes if you flick really fast the transform doesn't get removed.
        // draggingEl.xPos = this.draggingRect.left - this.containerRect.left; // For absolute positioning
        // draggingEl.yPos = this.draggingRect.top - this.containerRect.top; // For absolute positioning

        draggingEl.xPos = this.draggingRect.left; // For fixed positioning
        draggingEl.yPos = this.draggingRect.top; // For fixed positioning
        draggingEl.style.removeProperty('transform');
        draggingEl.style.removeProperty('will-change');

        this.slottedElements.forEach(element => {
          element.dragging = false;
        });
        this.emitNewPosition({ isInitialPlacement: false, dragEnd: true });
      }, 10);
    }
    this.emitNewPosition();
  }

  enforceContainment() {
    const containerEl = this.dragContainer;
    const draggingEl = this;

    const isFixedPositioned = window.getComputedStyle(this).position === 'fixed';

    if (isFixedPositioned && !this.hidden) {
      const containerRect = containerEl.getBoundingClientRect();
      const draggingRect = draggingEl.getBoundingClientRect();

      // enforce horizontal containment
      const maxAllowedLeftPos = containerRect.right - draggingRect.width;
      this.xPos = Math.max(containerRect.left, Math.min(draggingRect.left, maxAllowedLeftPos));

      // enfore vertical containment
      const maxAllowedTopPos = containerRect.bottom - draggingRect.height;
      this.yPos = Math.max(containerRect.top, Math.min(draggingRect.top, maxAllowedTopPos));
    }
  }

  resetPosition() {
    this.style.removeProperty('left');
    this.style.removeProperty('right');
    this.style.removeProperty('top');
    this.style.removeProperty('bottom');
    this.style.removeProperty('position');
    this.style.removeProperty('transform');
    this.style.removeProperty('will-change');
  }

  _xPosChanged() {
    this.style.left = `${this.xPos}px`;
    this.style.right = 'auto';
  }

  _yPosChanged() {
    this.style.top = `${this.yPos}px`;
    this.style.bottom = 'auto';
  }

  static get styles() {
    return [
      globalStyles,
      css`
        :host {
          border-radius: var(--vst-radius);
        }
      `,
    ];
  }

  render() {
    return html`<slot></slot>`;
  }
}

customElements.define('vst-ui-draggable', VstUiDraggable);

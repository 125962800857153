import { LitElement, html /* css */ } from 'lit';
import { globalStyles } from '@styles/vst-style-global.css.js';
import vstUiRangeStyles from './vst-ui-range.css.js';

export class VstUiRange extends LitElement {
  static get properties() {
    return {
      label: {
        type: String,
      },
      min: {
        type: Number,
      },
      max: {
        type: Number,
      },
      step: {
        type: String, // .123, 1, 10, 'any'
      },
      tickmarks: {
        type: Array,
      },
      value: {
        type: Number,
      },
      variant: {
        type: String,
        reflect: true,
      },
    };
  }

  constructor() {
    super();
    this.label = '';
    this.min = 0;
    this.max = 0;
    this.step = 1;
    this.tickmarks = [];
    this.value = 0;
    this.variant = 'default';
  }

  firstUpdated() {
    this.inputEl = this.shadowRoot.getElementById('range_input');
  }

  _valueChanged(e) {
    if (this.inputEl) {
      this.value = parseFloat(this.inputEl.value);
    }

    let eventName;
    if (e.type === 'input') {
      eventName = 'value-input';
    } else if (e.type === 'change') {
      eventName = 'value-changed';
    }

    this.dispatchEvent(new CustomEvent(eventName, { detail: { value: this.value } }));
  }

  static get styles() {
    return [globalStyles, vstUiRangeStyles];
  }

  render() {
    const renderTickmarks = tickmarks => html`
      <div class="range__tickmark-list">
        ${tickmarks.map(tickmark => html` <span class="range__tickmark">${tickmark}</span> `)}
      </div>
    `;

    return html`
      ${this.label ? html` <label class="label" for="range_input">${this.label}</label> ` : ''}
      <input
        class="range__input"
        id="range_input"
        type="range"
        variant="${this.variant}"
        min="${this.min}"
        max="${this.max}"
        step="${this.step}"
        .value="${this.value}"
        @pointerdown="${event => event.stopPropagation()}"
        @input="${this._valueChanged}"
        @change="${this._valueChanged}"
      />
      ${this.tickmarks.length > 0 ? renderTickmarks(this.tickmarks) : ''}
    `;
  }
}

customElements.define('vst-ui-range', VstUiRange);

import { css } from 'lit';

export default css`
  :host {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: calc(var(--vst-z-popover) - 1);
  }

  .background {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .wrapper {
    max-inline-size: 37.5rem;
  }

  .spinner {
    display: inline-block;
    width: 2.5rem;
    height: 2.5rem;
    --vst-ui-spinner-color: var(--vst-color-text-brand);
    --vst-ui-spinner-thickness: 0.25rem;
  }

  .message-wrapper {
    display: flex;
    align-items: center;
  }

  .message {
    flex: 1;
    font-size: 1em;
    font-weight: bold;
    margin: 0;
  }

  :host(.has-message) .message-wrapper {
    padding: 2.125em 1.75em;
    text-align: center;
    border-radius: var(--vst-radius);
  }

  :host(.has-message) .message {
    margin: 0 0 0 calc(var(--gutter) * 2);
  }

  :host([fillscreen]) {
    position: absolute;
  }
  :host([fillscreen]) .background {
    width: 100%;
    height: 100%;
    background: var(--vst-color-predictions-overlay-bg);
  }
`;

import { sprintf } from '@libs/sprintf.js';
import { getText } from '@utils/i18n.js';
import { closeCommonDialogEvent } from '@utils/closeCommonDialogEvent.js';

export async function promptConfirmNewSession(appName) {
  return new Promise(resolve => {
    this.dispatchEvent(
      new CustomEvent('open-dialog', {
        bubbles: true,
        composed: true,
        detail: {
          dialog: 'message_box',
          params: {
            title: getText('Start New Experiment?'),
            choiceRequired: true,
            content: sprintf(
              getText(
                '%s is currently collecting data. Do you want to stop collection and start a new experiment?',
              ),
              appName,
            ),
            actions: [
              {
                id: 'cancel',
                message: getText('Cancel'),
                variant: 'text',
                onClick: async () => {
                  resolve({ cancelled: true });
                  this.dispatchEvent(closeCommonDialogEvent('message_box'));
                },
              },
              {
                id: 'confirm',
                message: getText('New Experiment'),
                onClick: async () => {
                  resolve({ confirmed: true });
                  this.dispatchEvent(closeCommonDialogEvent('message_box'));
                },
              },
            ],
          },
        },
      }),
    );
  });
}

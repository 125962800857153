import { LitElement, html } from 'lit';
import { getText } from '@utils/i18n.js';
import { globalStyles } from '@styles/vst-style-global.css.js';

import { trash, pen } from '@icons';

import vstUiDataMarkStyles from './vst-ui-data-mark.css.js';

import '@components/vst-ui-icon/vst-ui-icon.js';

class VstUiDataMark extends LitElement {
  static get properties() {
    return {
      data: { type: Array },
      editing: { type: Boolean, reflect: true },
      open: { type: Boolean, reflect: true },
      note: { type: String },
      dataMarkId: { type: Number },
      dragging: { type: Boolean },
    };
  }

  constructor() {
    super();
    this.data = [];
    /** type {boolean} Whether the user is updating the note */
    this.editing = false;
    /** type {boolean} whether the user has clicked inside the data mark div */
    this.open = false;
    this.note = '';
    this.dataMarkId = 0;
    this.dragging = false;
  }

  connectedCallback() {
    super.connectedCallback();

    // adds a listener that checks if the user has clicked in or out of the data mark
    // and opens or closes the data mark accordingly
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line wc/require-listener-teardown
    document.addEventListener('click', e => {
      const isDataMarkClicked = e.composedPath().includes(this);
      this.setDataMarkOpen(isDataMarkClicked);
      if (!isDataMarkClicked) this.setEditing(isDataMarkClicked);
    });
  }

  async updated(changedProperties) {
    // need to alert draggable when note has changed so it can change lines
    // on box resize
    changedProperties.forEach(async (oldValue, propName) => {
      const dispatchNoteChange = () => {
        this.dispatchEvent(
          new CustomEvent('data-mark-size-changed', {
            bubbles: true,
            composed: true,
          }),
        );
      };

      switch (propName) {
        case 'note':
          dispatchNoteChange();
          break;
        case 'editing':
          if (this.editing) this.shadowRoot.querySelector('#data-mark-notes-input').focus();
          dispatchNoteChange();
          break;
        case 'open':
          dispatchNoteChange();
          break;
        case 'data':
          // data changes much more often than is needed, which sends conflicting
          // positioning updates while dragging
          if (this.data.length && !this.dragging) dispatchNoteChange();
          break;
        default:
          break;
      }
    });
  }

  static get styles() {
    return [globalStyles, vstUiDataMarkStyles];
  }

  inputChanged({ target: { value } }) {
    if (this.note !== value) this.updateNote(value);
  }

  deleteDataMark() {
    this.dispatchEvent(
      new CustomEvent('data-mark-deleted', {
        detail: { id: this.dataMarkId },
      }),
    );
  }

  /**
   * Sets the editing property and focuses the notes input when true.
   * @param { Boolean } state - Whether the user is editing
   */
  setEditing(state) {
    if (this.dragging) return;
    if (state) this.setDataMarkOpen(false);
    this.dispatchEvent(
      new CustomEvent('edit-state-changed', {
        detail: { dataMarkId: this.dataMarkId, editing: state },
      }),
    );
  }

  /**
   * Sets the 'open' property and focuses the data mark when true.
   * @param { Boolean } state - Whether the user has opened the data mark
   */
  setDataMarkOpen(state) {
    if (this.dragging) return;
    this.open = state;
  }

  updateNote(value) {
    this.dispatchEvent(
      new CustomEvent('note-updated', {
        detail: { dataMarkId: this.dataMarkId, value },
      }),
    );
  }

  render() {
    return html`
      <div id="data-mark-container-outer" class="container" tabindex="0">
        <div
          class="stack container"
          id="data-mark-container-inner"
          inset="xs"
          @keyup="${event => (event.keyCode === 13 ? this.setDataMarkOpen(true) : '')}"
        >
          <div class="inline" gap="xs">
            <div id="outside">
              <button
                class="btn"
                variant="icon"
                size="xs"
                id="delete-data-mark-btn"
                ?hidden="${!this.editing}"
                @click="${this.deleteDataMark}"
              >
                <vst-ui-icon .icon="${trash}"></vst-ui-icon>
              </button>
              ${this.editing || this.open
                ? html`
                    <button
                      class="btn"
                      variant="icon"
                      size="xs"
                      id="edit-data-mark-btn"
                      @click=${e => {
                        this.setEditing(!this.editing);
                        e.stopPropagation();
                      }}
                    >
                      <vst-ui-icon .icon="${pen}"></vst-ui-icon>
                    </button>
                  `
                : ''}
              <div class="stack">
                ${this.data.map(
                  data => html`
                    <div class="box">
                      <div class="inline" gap="2xs">
                        <div>
                          <p class="label" size="s">${data.key}:</p>
                          ${data.units
                            ? html` <p class="caption">${data.value} ${data.units}</p> `
                            : html` <p class="caption">${data.value}</p> `}
                        </div>
                      </div>
                    </div>
                  `,
                )}
              </div>
            </div>
          </div>
          <p class="caption note" space="2xs" ?hidden="${!this.note || this.editing}">
            ${this.note}
          </p>
        </div>
        <div class="inline" id="notes-wrapper" ?hidden="${!this.editing}">
          <div>
            <input
              id="data-mark-notes-input"
              placeholder="${getText('Note')}"
              .value=${this.note}
              @input=${this.inputChanged}
              @keyup=${e => {
                if (e.key === 'Enter') {
                  this.setEditing(false);
                  e.stopPropagation();
                }
              }}
            />

            <p class="note" space="2xs" ?hidden="${!this.note || this.editing}">${this.note}</p>
          </div>
        </div>
      </div>
    `;
  }
}
customElements.define('vst-ui-data-mark', VstUiDataMark);

import { createContext } from '@lit-labs/context';

export default createContext(Symbol('graph-options'));

/**
 * @typedef {Object} GraphOptionsContextValue
 * @property {boolean} disableAddAnnotation Whether to disable Add Annotation
 * @property {boolean} disableCurveFit Whether to disable Curve Fit
 * @property {boolean} disableFFT Whether to disable FFT
 * @property {boolean} disableHistogram Whether to disable Histogram
 * @property {boolean} disableIntegral Whether to disable View Integral
 * @property {boolean} disableRestoreAll Whether to disable Restore All
 * @property {boolean} disableStatistics Whather to disable Statistics
 * @property {boolean} disableStrikethrough Whather to disable Strikethrough
 * @property {boolean} hideAddAnnotation Whether to hide Add Annotation
 * @property {boolean} hideCurveFit Whether to hide Curve Fit
 * @property {boolean} hideFFT Whether to hide FFT
 * @property {boolean} hideHistogram Whether to hide Histogram
 * @property {boolean} hideIntegral Whether to hide View Integral
 * @property {boolean} hideStatistics Whether to hide Statistics
 * @property {boolean} hideStrikethrough Whether to hide Strikethrough
 */

import { LitElement, html, css } from 'lit';
import { Requester } from '@components/mixins/vst-core-requester-mixin.js';
import { getText } from '@utils/i18n.js';
import { globalStyles } from '@styles/vst-style-global.css.js';
import '@components/vst-ui-listbox/vst-ui-listbox.js';
import { moveUp, moveDown, addIcon, deleteIcon, meterSettings } from '@icons';
import { Meter } from '@common/mobx-stores/Meter.js';

class VstCoreMeterActions extends Requester(LitElement) {
  static get properties() {
    return {
      _meterActions: { state: true },
      meterId: { type: Number },
      name: { type: String },
    };
  }

  constructor() {
    super();
    this.name = '';
    this.meterId = 0;
  }

  firstUpdated() {
    [this.$dataWorld] = this.requestServices(['dataWorld']);

    this._meterActions = [
      {
        id: 'column-options',
        title: getText('Column Options'),
        selectAction: () => {
          const meter = this.$dataWorld.getMeterById(this.meterId);
          const columnOptionSettings = {
            columnAction: 'edit',
            sourceColumnId: meter.columnId,
            sourceGroupId: this.$dataWorld.getColumnById(meter.columnId).groupId,
            title: 'Column Options',
          };
          this.dispatchEvent(
            new CustomEvent('open-dialog', {
              bubble: true,
              composed: true,
              detail: {
                dialog: 'column_options',
                params: {
                  columnOptionSettings,
                },
              },
            }),
          );
        },
        renderIcon: () => {
          return html`<vst-ui-icon class="item-icon" .icon=${meterSettings}></vst-ui-icon>`;
        },
      },
      {
        id: 'move-up',
        title: getText('Move Up'),
        selectAction: () => {
          const meter = this.$dataWorld.getMeterById(this.meterId);
          this.$dataWorld.decrementMeterPosition(meter);
        },
        renderIcon: () => {
          return html`<vst-ui-icon class="item-icon" .icon=${moveUp}></vst-ui-icon>`;
        },
      },
      {
        id: 'move-down',
        title: getText('Move Down'),
        selectAction: () => {
          const meter = this.$dataWorld.getMeterById(this.meterId);
          this.$dataWorld.incrementMeterPosition(meter);
        },
        renderIcon: () => {
          return html`<vst-ui-icon class="item-icon" .icon=${moveDown}></vst-ui-icon>`;
        },
      },
      {
        id: 'add-remove-meter',
        title: getText('Add/Remove Meters'),
        selectAction: () => {
          this.dispatchEvent(
            new CustomEvent('add-remove-meters-clicked', { bubbles: true, composed: true }),
          );
        },
        renderIcon: () => {
          return html`<vst-ui-icon class="item-icon" .icon=${addIcon}></vst-ui-icon>`;
        },
      },
      {
        id: 'remove',
        title: getText('Remove'),
        selectAction: () => {
          const meter = this.$dataWorld.getMeterById(this.meterId);
          meter.overrideMeterVisibility(Meter.VisibilityOverride.FORCE_HIDDEN);
          this.$dataWorld.setMeterPosition(meter);
          this.$dataWorld.updateColumnGroup(this.$dataWorld.getColumnById(meter.columnId).groupId, {
            metered: false,
          });
        },
        renderIcon: () => {
          return html`<vst-ui-icon class="item-icon" .icon=${deleteIcon}></vst-ui-icon>`;
        },
      },
    ];
  }

  onListItemClicked(event) {
    const id = event.detail;
    this._meterActions.find(o => o.id === id).selectAction();
    this.dispatchEvent(
      new CustomEvent('meter-action-clicked', {
        bubbles: true,
        composed: true,
      }),
    );
  }

  static get styles() {
    return [
      globalStyles,
      css`
        :host {
          padding: var(--vst-space-m) var(--vst-space-s);
        }
        .meter-name {
          padding: var(--vst-space-xs) var(--vst-space-s);
          border-radius: var(--vst-radius);
          background-color: var(--vst-color-brand-bg);
          color: var(--vst-primary-color);
          border-left: var(--vst-space-3xs) solid var(--vst-color-brand-300);
        }
        .meter-name h2 {
          color: var(--vst-color-dark-300);
        }
      `,
    ];
  }

  render() {
    return html`
      <div class="meter-name"><h2 class="heading" size="m">${this.name}</h2></div>
      ${this._meterActions
        ? html`
            <vst-ui-listbox
              variant="no-lines"
              id="listbox"
              .view="${{ items: this._meterActions }}"
              @list-item-clicked="${this.onListItemClicked}"
              @list-switch-changed="${this.onListSwitchChanged}"
              @list-item-option-selected="${this.onListOptionSelected}"
            >
            </vst-ui-listbox>
          `
        : ''}
    `;
  }
}

customElements.define('vst-core-meter-actions', VstCoreMeterActions);

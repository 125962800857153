import { priv } from './priv.js';

const rowOverscrollInterval = 200;
const columnOverscrollInterval = 200;
const dataSetOverscrollInterval = 400;

export const createOverscrollContext = ({
  dataSetGrid,
  getInteraction,
  getUiSelectionOccurring,
  uiExpandDataSetSelection,
  uiExpandRowSelection,
  uiExpandColumnSelection,
  uiExpandCellSelection,
}) => {
  let overscroll = null;

  const clearOverscroll = () => {
    if (overscroll) {
      clearInterval(overscroll.intervalHandle);
      overscroll = null;
    }
  };

  const checkOverscroll = (clientX, clientY) => {
    const bounds = dataSetGrid[priv].grid.getRelativePosition(clientX, clientY);
    const interaction = getInteraction();
    const uiSelectionOccurring = getUiSelectionOccurring();

    if (bounds.isInside || !interaction || interaction.editingCell || !uiSelectionOccurring) {
      clearOverscroll();
      return;
    }

    if (!overscroll) {
      const startDirectionAction = (fn, interval) => {
        overscroll = {
          bounds,
        };
        const onAction = () => {
          fn(getInteraction(), !overscroll.moved);
          if (overscroll) {
            overscroll.moved = true;
          }
        };

        overscroll.intervalHandle = setInterval(onAction, interval);
      };

      const moveUp = (interaction, firstMove) => {
        const lowestIndex = interaction.endRowIndex;
        const { grid } = dataSetGrid[priv];

        if (firstMove && grid.getRelativeRowInfo(lowestIndex).outsideTop) {
          grid.scrollTo(null, lowestIndex, true);
        } else if (lowestIndex > 0) {
          const nextRowIndex = lowestIndex - 1;
          grid.scrollTo(null, nextRowIndex, true);
          if (interaction.rowsSelected) {
            uiExpandRowSelection(nextRowIndex);
          } else {
            uiExpandCellSelection(interaction.endCol, nextRowIndex);
          }
        } else {
          clearOverscroll();
        }
      };

      const moveDown = (interaction, firstMove) => {
        const highestIndex = interaction.endRowIndex;
        const { grid } = dataSetGrid[priv];

        if (firstMove && grid.getRelativeRowInfo(highestIndex).outsideBottom) {
          grid.scrollTo(null, highestIndex, true);
        } else if (highestIndex < dataSetGrid.totalRows - 1) {
          const nextRowIndex = highestIndex + 1;
          grid.scrollTo(null, nextRowIndex, true);
          if (interaction.rowsSelected) {
            uiExpandRowSelection(nextRowIndex);
          } else {
            uiExpandCellSelection(interaction.endCol, nextRowIndex);
          }
        } else {
          clearOverscroll();
        }
      };

      const moveLeft = (interaction, firstMove) => {
        if (interaction.dataSetsSelected) {
          const lowestIndex = interaction.endDataSetIndex;
          const { grid } = dataSetGrid[priv];
          const { dataSets } = dataSetGrid[priv];
          const moveToNextDataSet = () => {
            if (lowestIndex > 0) {
              const nextDataSetIndex = lowestIndex - 1;
              const nextDataSet = dataSets.findByIndex(nextDataSetIndex);
              const { columns } = nextDataSet[priv];
              const nextCol = columns.findByIndex(0);

              grid.scrollTo(nextCol, null, true);
              uiExpandDataSetSelection(nextDataSet);
            } else {
              clearOverscroll();
            }
          };

          if (firstMove) {
            const nextDataSetIndex = lowestIndex;
            const nextDataSet = dataSets.findByIndex(nextDataSetIndex);
            const { columns } = nextDataSet[priv];
            const nextCol = columns.findByIndex(0);

            if (grid.getRelativeColumnInfo(nextCol).outsideLeft) {
              grid.scrollTo(nextCol, null, true);
            } else {
              moveToNextDataSet();
            }
          } else {
            moveToNextDataSet();
          }
        } else {
          const lowestIndex = interaction.endColIndex;
          const { grid } = dataSetGrid[priv];
          const columns = dataSetGrid[priv].getRawColumns();
          const moveToNextColumn = () => {
            if (lowestIndex > 0) {
              const nextColIndex = lowestIndex - 1;
              const nextCol = columns[nextColIndex];

              grid.scrollTo(nextCol, null, true);
              if (interaction.columnsSelected) {
                uiExpandColumnSelection(nextCol);
              } else {
                uiExpandCellSelection(nextCol, interaction.endRowIndex);
              }
            } else {
              clearOverscroll();
            }
          };

          if (firstMove) {
            const nextColIndex = lowestIndex;
            const nextCol = columns[nextColIndex];
            if (grid.getRelativeColumnInfo(nextCol).outsideLeft) {
              grid.scrollTo(nextCol, null, true);
            } else {
              moveToNextColumn();
            }
          } else {
            moveToNextColumn();
          }
        }
      };

      const moveRight = (interaction, firstMove) => {
        if (interaction.dataSetsSelected) {
          const highestIndex = interaction.endDataSetIndex;
          const { grid } = dataSetGrid[priv];
          const { dataSets } = dataSetGrid[priv];
          const moveToNextDataSet = () => {
            if (highestIndex < dataSets.length - 1) {
              const nextDataSetIndex = highestIndex + 1;
              const nextDataSet = dataSets.findByIndex(nextDataSetIndex);
              const { columns } = nextDataSet[priv];
              const nextCol = columns.findByIndex(columns.length - 1);

              grid.scrollTo(nextCol, null, true);
              uiExpandDataSetSelection(nextDataSet);
            } else {
              clearOverscroll();
            }
          };

          if (firstMove) {
            const nextDataSetIndex = highestIndex;
            const nextDataSet = dataSets.findByIndex(nextDataSetIndex);
            const { columns } = nextDataSet[priv];
            const nextCol = columns.findByIndex(columns.length - 1);
            if (grid.getRelativeColumnInfo(nextCol).outsideRight) {
              grid.scrollTo(nextCol, null, true);
            } else {
              moveToNextDataSet();
            }
          } else {
            moveToNextDataSet();
          }
        } else {
          const highestIndex = interaction.endColIndex;
          const { grid } = dataSetGrid[priv];
          const columns = dataSetGrid[priv].getRawColumns();
          const moveToNextColumn = () => {
            if (highestIndex < columns.length - 1) {
              const nextColIndex = highestIndex + 1;
              const nextCol = columns[nextColIndex];

              grid.scrollTo(nextCol, null, true);
              if (interaction.columnsSelected) {
                uiExpandColumnSelection(nextCol);
              } else {
                uiExpandCellSelection(nextCol, interaction.endRowIndex);
              }
            } else {
              clearOverscroll();
            }
          };

          if (firstMove) {
            const nextColIndex = highestIndex;
            const nextCol = columns[nextColIndex];
            if (grid.getRelativeColumnInfo(nextCol).outsideRight) {
              grid.scrollTo(nextCol, null, true);
            } else {
              moveToNextColumn();
            }
          } else {
            moveToNextColumn();
          }
        }
      };

      if (bounds.isTop && !interaction.columnsSelected && !interaction.dataSetsSelected) {
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line no-multi-assign
        bounds.isLeft = bounds.isRight = false;
        startDirectionAction(moveUp, rowOverscrollInterval);
      } else if (bounds.isBottom && !interaction.columnsSelected && !interaction.dataSetsSelected) {
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line no-multi-assign
        bounds.isLeft = bounds.isRight = false;
        startDirectionAction(moveDown, rowOverscrollInterval);
      } else if (bounds.isLeft && !interaction.rowsSelected) {
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line no-multi-assign
        bounds.isTop = bounds.isBottom = false;
        const overscrollInterval = interaction.dataSetsSelected
          ? dataSetOverscrollInterval
          : columnOverscrollInterval;
        startDirectionAction(moveLeft, overscrollInterval);
      } else if (bounds.isRight && !interaction.rowsSelected) {
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line no-multi-assign
        bounds.isTop = bounds.isBottom = false;
        const overscrollInterval = interaction.dataSetsSelected
          ? dataSetOverscrollInterval
          : columnOverscrollInterval;
        startDirectionAction(moveRight, overscrollInterval);
      }

      return;
    }

    if (
      (!bounds.isLeft && overscroll.bounds.isLeft) ||
      (!bounds.isRight && overscroll.bounds.isRight) ||
      (!bounds.isTop && overscroll.bounds.isTop) ||
      (!bounds.isBottom && overscroll.bounds.isBottom)
    ) {
      clearOverscroll();
    }
  };

  return {
    checkOverscroll,
    clearOverscroll,
  };
};

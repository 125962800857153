import { css } from 'lit';
import { breakpoint } from '@styles/mixins/breakpoints.css.js';

export const saWelcomeStyles = css`
  :host {
    background-color: var(--vst-color-bg);
    overflow: hidden;
    block-size: 100%;
  }

  .new-session__btn {
    inline-size: 100%;
    border: 0;
    padding: var(--vst-space-xs) 0;
    background-color: transparent;
    text-align: start;
    margin: 0;
  }

  .new-session__icon {
    display: block;
    inline-size: 3rem;
    block-size: 3rem;
    padding: var(--vst-space-xs);
    border: 1px solid var(--vst-color-warning-ui);
    border-radius: 100%;
    overflow: hidden;
  }

  .new-session__btn--active {
    color: var(--vst-color-brand);
  }

  .new-session__subcat {
    margin-inline-start: 77px;
    list-style-type: disc;
  }

  .main-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }

  ${breakpoint(
    's',
    css`
      .main-content {
        display: grid;
        grid-gap: var(--vst-space-xl);
        grid-template-columns: 1fr auto;
      }
    `,
  )}

  .overline {
    margin-block-end: var(--vst-space-s);
  }

  .vernier-links {
    padding-inline-start: 0px;
    margin: 0;
    list-style: none;
  }

  .link {
    font-size: var(--vst-font-size-s);
  }

  vst-ui-logo-vernier {
    inline-size: 100%;
    max-inline-size: 12rem;
  }

  .stack section,
  .btn[variant='outline'] {
    inline-size: 100%;
  }

  .loading-spinner {
    inline-size: 3rem;
    block-size: 3rem;
  }

  .session-icon {
    display: grid;
    grid-template-areas: 'icon';
  }

  .session-icon > * {
    grid-area: icon;
  }

  vst-ui-accordion {
    --order: 3;
  }

  vst-ui-accordion > .inline {
    margin-inline-end: var(--vst-space-xs);
  }

  vst-ui-spinner {
    display: none;
  }

  .starting-session vst-ui-spinner {
    display: block;
  }

  span.heading {
    cursor: pointer;
    transition: all 0.3s;
  }

  span.heading:hover,
  span.heading:focus {
    color: var(--vst-color-brand);
  }

  [disabled] {
    opacity: var(--vst-opacity-disabled);
    pointer-events: none;
  }
`;

import { LitElement, html } from 'lit';

import { getText } from '@utils/i18n.js';

import { globalStyles } from '@styles/vst-style-global.css.js';

import vstCoreDownloadDataStyles from './vst-core-download-data.css.js';

import '@components/vst-ui-form/vst-ui-form.js';

const _getSelectedRadio = radioButtonsEls => {
  for (let i = 0; i < radioButtonsEls.length; ++i) {
    if (radioButtonsEls[i].checked) {
      return radioButtonsEls[i].value;
    }
  }
  return false;
};

class VstCoreDownloadData extends LitElement {
  static get properties() {
    return {
      view: {
        type: Object,
      },
    };
  }

  constructor() {
    super();
    this.view = {};
  }

  updated(changedProperties) {
    changedProperties.forEach((oldValue, propName) => {
      if (propName === 'view') {
        this.viewChanged();
      }
    });
  }

  firstUpdated() {
    this.downloadFilenameEl = this.shadowRoot.getElementById('filename');
    this.downloadDecimalFormatEls = this.shadowRoot.querySelectorAll('[name="decimal_format"]');
    this.downloadFilenameEl.focus();
  }

  viewChanged() {
    // set the default decimal format to what was passed up via the toolbar controller.
    const radios = Array.from(this.shadowRoot.querySelectorAll('[name="decimal_format"]'));
    radios.forEach(radio => {
      if (radio.value === this.view.decimal) {
        radio.checked = true;
      }
    });
    // show or hide the input
    if (this.view.hideFileName) {
      this.shadowRoot.querySelector('#filename_wrapper').style.display = 'none';
    }
  }

  submitForm() {
    const inputVal = this.downloadFilenameEl.value;
    const decimalFormat = _getSelectedRadio(this.downloadDecimalFormatEls);
    const submittedValues = {
      inputVal,
      decimalFormat,
    };
    this.dispatchEvent(new CustomEvent('download-data', { detail: submittedValues }));
    this.dispatchEvent(new CustomEvent('close-popover'));
  }

  static get styles() {
    return [globalStyles, vstCoreDownloadDataStyles];
  }

  render() {
    return html`
      <vst-ui-form @submit="${this.submitForm}">
        <form>
          <div id="filename_wrapper" margin="block-end-m">
            <label for="filename"
              ><strong><span>${getText('Filename')}</span>:</strong></label
            >
            <div class="input-wrapper">
              <input
                type="text"
                placeholder="${getText('My-File-Name')}"
                id="filename"
                tabindex="0"
                autocomplete="off"
                autocorrect="off"
                autocapitalize="off"
                spellcheck="false"
              />
              <label for="filename" class="file-extension" margin="inline-start-xs">.csv</label>
            </div>
          </div>
          <strong><span>${getText('Decimal Format')}</span>:</strong>
          <div class="decimal-wrapper">
            <label for="decimal_period">
              <input type="radio" name="decimal_format" id="decimal_period" value="." checked />12.3
            </label>
            <label for="decimal_comma">
              <input type="radio" name="decimal_format" id="decimal_comma" value="," />12,3
            </label>
          </div>
          <button type="submit" class="btn" margin="block-start-s inline-start-auto">
            ${getText('Export CSV')}
          </button>
        </form>
      </vst-ui-form>
    `;
  }
}

customElements.define('vst-core-download-data', VstCoreDownloadData);

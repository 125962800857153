import { debounce } from 'lodash-es';
import { LitElement, html } from 'lit';
import { info } from '@icons';
import { globalStyles } from '@styles/vst-style-global.css.js';
import { getText } from '@utils/i18n.js';

import '@components/vst-ui-icon/vst-ui-icon.js';
import '@components/vst-ui-form/vst-ui-form.js';
import '@components/vst-ui-spinner/vst-ui-spinner.js';

import { saSettingsStyles } from './sa-settings.css.js';

const MIN_INTEGRATION_TIME = 1;
const MAX_INTEGRATION_TIME = 500;
const MIN_WAVELENGTH_SMOOTHING = 1;
const MAX_WAVELENGTH_SMOOTHING = 10;
const MIN_TEMPORAL_AVERAGING = 1;
const MAX_TEMPORAL_AVERAGING = 10;

export class SaSettings extends LitElement {
  static get properties() {
    return {
      // #region private state reactive properties
      _maxCollectionInterval: { state: true },
      _minCollectionInterval: { state: true },
      _specConnectedBle: { state: true },
      // #endregion
      // #region public reactive properties
      // same object lives in vst-sa-welcome.js
      advancedModeEnabled: { type: Boolean },
      canSetIntegrationTime: { type: Boolean },
      changingSpectrumModes: { type: Boolean }, // used to disable settings fields when changing spectrum modes in fluorescence session mode
      collectionInterval: { type: Number }, // in seconds
      disableCalibrate: { type: Boolean },
      integrationTime: { type: Number },
      isCollecting: { type: Boolean },
      isDcFluorescence: { type: Boolean },
      isSavingSettings: { type: Boolean },
      ledIntensity: { type: Number },
      ledWavelength: { type: Number },
      sessionCollectionMode: { type: String }, // time-based, events-with-entry, full-spectrum
      showCalibrate: { type: Boolean },
      showInterval: { type: Boolean },
      specConnected: { type: Object },
      spectrumMode: { type: String }, // absorbance, transmittance, intensity, fluorescence, raw
      temporalAveraging: { type: Number },
      wavelengthSmoothing: { type: Number },
      // #endregion
    };
  }

  // static get observableProperties() {
  // 	return {
  //
  // 	}
  // }

  static get styles() {
    return [globalStyles, saSettingsStyles];
  }

  constructor() {
    super();
    this._maxCollectionInterval = 3600;
    this._minCollectionInterval = 0;
    this.advancedModeEnabled = false;
    this.changingSpectrumModes = false;
    this.collectionInterval = 0;
    this.debouncedSubmitForm = debounce(() => this.submitForm(), 800);
    this.disableCalibrate = false;
    this.integrationTime = 0;
    this.isCollecting = false;
    this.isSavingSettings = false;
    this.ledIntensity = 100;
    this.ledWavelength = 405;
    this.sessionCollectionMode = '';
    this.specConnected = null; // is object
    this.specMaxCollectionInterval = 3600;
    this.specMinCollectionInterval = 0;
    this.spectraTimeScale = 0;
    this.spectraTransportTime = 0;
    this.spectrumMode = '';
    this.temporalAveraging = 6;
    this.wavelengthSmoothing = 1;
  }

  // #region Lit Lifecycle Callbacks
  updated(changedProperties) {
    changedProperties.forEach(async (oldValue, propName) => {
      switch (propName) {
        case 'specConnected':
          if (this.specConnected !== oldValue) {
            this._specConnectedChanged(this.specConnected);
          }
          // update spec settings
          this._specConnectedBle = SaSettings._isSpecConnectedBle(this.specConnected);
          this._maxCollectionInterval = this.calcMaxCollectionInterval(this.specConnected);
          this.disableCalibrate = !this.canCalibrate();
          break;
        case 'spectraTimeScale':
          this._minCollectionInterval = this.calcMinCollectionInterval();
          break;
        case 'spectraTransportTime':
          this._minCollectionInterval = this.calcMinCollectionInterval();
          break;
        case 'integrationTime':
          this._minCollectionInterval = this.calcMinCollectionInterval();
          break;
        case 'temporalAveraging':
          this._minCollectionInterval = this.calcMinCollectionInterval();
          break;
        case 'wavelengthSmoothing':
          this._minCollectionInterval = this.calcMinCollectionInterval();
          break;
        case 'spectrumMode':
          this.isDcFluorescence = SaSettings._computedIsDcFluorescence(this.spectrumMode);
          this.showCalibrate = SaSettings._showCalibrate(this.spectrumMode);
          this.canSetIntegrationTime = SaSettings._canSetIntegrationTime(this.spectrumMode);
          this.disableCalibrate = !this.canCalibrate();
          this._specConnectedChanged(this.specConnected);
          break;
        case 'sessionCollectionMode':
          this.showInterval = SaSettings._showInterval(this.sessionCollectionMode);
          break;
        case 'isCollecting':
          this.disableCalibrate = !this.canCalibrate();
          break;
        case 'changingSpectrumModes':
          // init spec after changing mode
          if (!this.changingSpectrumModes && oldValue === true) {
            this.initSpecSettings();
          }
          break;
        default:
      }
    });
  }
  // #endregion

  // #region getters/setters
  get _canSetTimeBasedCollectionParameters() {
    return (
      this.sessionCollectionMode !== 'time-based' ||
      (this.sessionCollectionMode === 'time-based' && !this.isCollecting)
    );
  }
  // #endregion

  // #region private methods
  static _canSetIntegrationTime(spectrumMode) {
    return spectrumMode !== 'absorbance' && spectrumMode !== 'transmittance';
  }

  _collectionIntervalInput(e) {
    this.collectionInterval = parseInt(e.target.value);
    this.debouncedSubmitForm();
  }

  static _computedIsDcFluorescence(spectrumMode) {
    return spectrumMode === 'fluorescence';
  }

  _deviceSupportsSpectrumMode(spectrumMode) {
    return this.specConnected?.supportedSpectrumModes?.includes(spectrumMode);
  }

  _integrationTimeInput(e) {
    this.integrationTime = parseInt(e.target.value);
    this.debouncedSubmitForm();
  }

  static _isSpecConnectedBle(spec) {
    if (spec) {
      return spec.type === 'bluetooth';
    }
    return false;
  }

  _isSpectrumMode(spectrumMode) {
    return spectrumMode === this.spectrumMode;
  }

  _ledIntensityChanged(e) {
    this.ledIntensity = parseInt(e.target.value);
    this.debouncedSubmitForm();
  }

  _ledWavelengthChanged(e) {
    this.ledWavelength = parseInt(e.target.value);
    this.submitForm();
  }

  static _showCalibrate(spectrumMode) {
    return spectrumMode !== 'raw';
  }

  static _showInterval(sessionCollectionMode) {
    return sessionCollectionMode === 'time-based';
  }

  _specConnectedChanged(specConnected) {
    if (specConnected) {
      this.initSpecSettings();
    } else {
      this.specConnected = null;
    }
  }

  _temporalAveragingInput(e) {
    this.temporalAveraging = parseInt(e.target.value);
    this.debouncedSubmitForm();
  }

  _wavelengthSmoothingInput(e) {
    this.wavelengthSmoothing = parseInt(e.target.value);
    this.debouncedSubmitForm();
  }
  // #endregion

  // #region public methods
  calcMaxCollectionInterval() {
    return this.specConnected ? this.specMaxCollectionInterval : 200;
  }

  calcMinCollectionInterval() {
    const otherFieldsAreValid =
      this.integrationTime >= MIN_INTEGRATION_TIME &&
      this.integrationTime <= MAX_INTEGRATION_TIME &&
      this.temporalAveraging >= MIN_TEMPORAL_AVERAGING &&
      this.temporalAveraging <= MAX_TEMPORAL_AVERAGING &&
      this.wavelengthSmoothing >= MIN_WAVELENGTH_SMOOTHING &&
      this.wavelengthSmoothing <= MAX_WAVELENGTH_SMOOTHING;
    if (this.specConnected && otherFieldsAreValid) {
      const backendOffset = 1.2; // Jimmy: data rate is much better now.  the 1 is to bump to next highest integer.
      const minimumIntervalGuess =
        (this.integrationTime * this.spectraTimeScale + this.spectraTransportTime) *
          (this.temporalAveraging / 1000) +
        backendOffset;
      return Math.max(this.specMinCollectionInterval, Math.floor(minimumIntervalGuess));
    }
    return 1;
  }

  calibrate() {
    this.dispatchEvent(new CustomEvent('show-calibrate'));
  }

  canCalibrate() {
    return !this.isCollecting && this._deviceSupportsSpectrumMode(this.spectrumMode);
  }

  changeSpectrumMode(e) {
    const mode = e.target.value;
    this.dispatchEvent(new CustomEvent('set-spectrum-mode', { detail: { mode } }));
  }

  disconnectBleSpec() {
    this.dispatchEvent(new CustomEvent('disconnect-ble-spec'));
    this.specConnected = null;
  }

  async initSpecSettings() {
    this.spectraTimeScale = this.specConnected.spectraTimeScale;
    this.spectraTransportTime = this.specConnected.spectraTransportTime;
    this.specMinCollectionInterval = this.specConnected.minCollectionDelta;
    this.specMaxCollectionInterval = this.specConnected.maxCollectionDelta;

    await this.updateComplete;
    this.specParamsFormEl = this.shadowRoot.querySelector('#spec_params');
    this.specParamsInputEls = this.specParamsFormEl
      ? Array.from(this.specParamsFormEl.querySelectorAll('input, select'))
      : null;
    this.retainDisabledState();

    if (this.spectrumMode === 'fluorescence') {
      this.ledWavelength = this.specConnected.ledWavelength;
      this.ledIntensity = this.specConnected.ledIntensity;

      if (this.specConnected.supportedLedWavelengths.includes(this.ledWavelength)) {
        this.shadowRoot
          .querySelector('#excitation_wavelength')
          .querySelector(`option[value="${this.ledWavelength}"]`).selected = true;
      } else {
        this.shadowRoot
          .querySelector('#excitation_wavelength')
          .querySelector(`option[value="custom"]`).selected = true;
        // TODO, show the custom input here.
      }
    }
  }

  retainDisabledState() {
    this.originalDisabledStates = this.specParamsInputEls.map(inputEl => {
      return inputEl.disabled;
    });
  }

  showDeviceDetails() {
    this.dispatchEvent(new CustomEvent('device-info-clicked'));
  }

  showDeviceManager() {
    this.dispatchEvent(new CustomEvent('show-device-manager'));
  }

  submitForm() {
    const hasInvalidField = this.specParamsInputEls.find(inputEl => !inputEl.validity.valid);
    if (hasInvalidField) {
      this.specParamsInputEls.forEach(inputEl => {
        inputEl.disabled = inputEl.validity.valid;
      });
    } else {
      this.specParamsInputEls.forEach((inputEl, index) => {
        inputEl.disabled = this.originalDisabledStates[index];
      });
    }

    this.specParamsFormEl.dispatchEvent(new Event('submit'));
  }

  submitSpecSettings() {
    const {
      integrationTime,
      wavelengthSmoothing,
      temporalAveraging,
      collectionInterval,
      ledWavelength,
      ledIntensity,
    } = this;
    this.dispatchEvent(
      new CustomEvent('update-spec-settings', {
        detail: {
          integrationTime: parseInt(integrationTime),
          collectionInterval: parseInt(collectionInterval),
          wavelengthSmoothing: parseInt(wavelengthSmoothing),
          temporalAveraging: parseInt(temporalAveraging),
          LEDWavelength: parseInt(ledWavelength),
          LEDIntensity: parseInt(ledIntensity),
        },
      }),
    );
  }
  // #endregion

  render() {
    return html`
      ${!this.specConnected
        ? html`
            <div class="no-spec-connected">
              <h2 class="heading" size="m">${getText('No Spectrometer Connected')}</h2>
              <button
                id="device_manager_btn"
                class="btn"
                type="button"
                @click="${this.showDeviceManager}"
              >
                ${getText('Connect a Spectrometer')}
              </button>
            </div>
          `
        : html`
            <div class="spec-connected">
              <div class="spec-connected__summary-container">
                <div class="spec-connected__summary">
                  <p class="caption" margin="inline-end-2xs">
                    ${this.specConnected.displayName} ${getText('connected')}
                  </p>
                  <button
                    class="btn"
                    variant="icon"
                    size="xs"
                    @click="${this.showDeviceDetails}"
                    type="button"
                  >
                    <vst-ui-icon .icon="${info}"></vst-ui-icon>
                  </button>
                </div>
                ${this._specConnectedBle
                  ? html`
                      <button class="link" type="button" @click="${this.disconnectBleSpec}">
                        ${getText('Disconnect')}
                      </button>
                    `
                  : ''}
              </div>
            </div>
            <vst-ui-form @submit="${this.submitSpecSettings}">
              <form id="spec_params" class="spec-params">
                <fieldset
                  class="spec-params__fieldset"
                  ?disabled="${this.changingSpectrumModes}"
                  ?hidden="${!this.advancedModeEnabled}"
                >
                  <legend class="spec-params__legend">
                    <div class="spec-params__legend-grid">
                      <div>${getText('Sensor Mode')}</div>
                      <vst-ui-spinner
                        class="spec-params__spinner"
                        active="true"
                        ?hidden="${!this.changingSpectrumModes}"
                      ></vst-ui-spinner>
                    </div>
                  </legend>
                  <ul class="spec-params__dc-spectrum-modes">
                    <li class="spec-params__dc-spectrum-mode">
                      <input
                        ?disabled="${!this._deviceSupportsSpectrumMode('absorbance') ||
                        this.isCollecting}"
                        @change=${this.changeSpectrumMode}
                        .checked="${this._isSpectrumMode('absorbance')}"
                        type="radio"
                        name="set_spectrum_mode"
                        value="absorbance"
                        id="set_spectrum_mode__absorbance"
                      />
                      <label class="title" for="set_spectrum_mode__absorbance"
                        >${getText('Absorbance')}</label
                      >
                    </li>
                    <li class="spec-params__dc-spectrum-mode">
                      <input
                        ?disabled="${!this._deviceSupportsSpectrumMode('fluorescence') ||
                        this.isCollecting}"
                        @change=${this.changeSpectrumMode}
                        .checked="${this._isSpectrumMode('fluorescence')}"
                        type="radio"
                        name="set_spectrum_mode"
                        value="fluorescence"
                        id="set_spectrum_mode__fluorescence"
                      />
                      <label class="title" for="set_spectrum_mode__fluorescence"
                        >${getText('Fluorescence')}</label
                      >
                    </li>
                    <li class="spec-params__dc-spectrum-mode">
                      <input
                        ?disabled="${!this._deviceSupportsSpectrumMode('transmittance') ||
                        this.isCollecting}"
                        @change=${this.changeSpectrumMode}
                        .checked="${this._isSpectrumMode('transmittance')}"
                        type="radio"
                        name="set_spectrum_mode"
                        value="transmittance"
                        id="set_spectrum_mode__transmittance"
                      />
                      <label class="title" for="set_spectrum_mode__transmittance"
                        >${getText('Transmittance')}</label
                      >
                    </li>
                    <li class="spec-params__dc-spectrum-mode">
                      <input
                        ?disabled="${!this._deviceSupportsSpectrumMode('intensity') ||
                        this.isCollecting}"
                        @change=${this.changeSpectrumMode}
                        .checked="${this._isSpectrumMode('intensity')}"
                        type="radio"
                        name="set_spectrum_mode"
                        value="intensity"
                        id="set_spectrum_mode__intensity"
                      />
                      <label class="title" for="set_spectrum_mode__intensity"
                        >${getText('Emissions')}</label
                      >
                    </li>
                    <li class="spec-params__dc-spectrum-mode">
                      <input
                        ?disabled="${!this._deviceSupportsSpectrumMode('raw') || this.isCollecting}"
                        @change=${this.changeSpectrumMode}
                        .checked="${this._isSpectrumMode('raw')}"
                        type="radio"
                        name="set_spectrum_mode"
                        value="raw"
                        id="set_spectrum_mode__raw"
                      />
                      <label class="title" for="set_spectrum_mode__raw"
                        >${getText('Raw Data')}</label
                      >
                    </li>
                  </ul>
                </fieldset>
                <fieldset class="spec-params__fieldset" ?disabled="${this.changingSpectrumModes}">
                  <legend class="spec-params__legend">
                    <div class="spec-params__legend-grid">
                      <div>${getText('Collection Settings')}</div>
                      <vst-ui-spinner
                        class="spec-params__spinner"
                        active="true"
                        ?hidden="${!this.isSavingSettings}"
                      ></vst-ui-spinner>
                    </div>
                  </legend>
                  <button
                    type="button"
                    class="btn"
                    variant="outline"
                    margin="block-xs"
                    id="spec_params_calibrate_btn"
                    @click="${this.calibrate}"
                    ?disabled="${this.disableCalibrate}"
                    ?hidden="${!this.showCalibrate}"
                  >
                    ${getText('Calibrate')}
                  </button>
                  <div class="stack" gap="m">
                    <label class="title sidebar" gap="m">
                      <div>
                        <span>${getText('Integration Time')}</span>
                        <div class="input-group" fixed>
                          <input
                            @input="${this._integrationTimeInput}"
                            id="integration_time"
                            type="number"
                            min="${MIN_INTEGRATION_TIME}"
                            max="${MAX_INTEGRATION_TIME}"
                            step="1"
                            required
                            .value="${this.integrationTime}"
                            ?disabled="${!this.canSetIntegrationTime ||
                            !this._canSetTimeBasedCollectionParameters}"
                          />
                          <p class="caption">ms</p>
                        </div>
                      </div>
                    </label>

                    <label class="title sidebar" gap="m">
                      <div>
                        <span>${getText('Wavelength Smoothing')}</span>
                        <div class="input-group" fixed>
                          <input
                            id="wavelength_smoothing"
                            type="number"
                            min="${MIN_WAVELENGTH_SMOOTHING}"
                            max="${MAX_WAVELENGTH_SMOOTHING}"
                            step="1"
                            required
                            .value="${this.wavelengthSmoothing}"
                            ?disabled=${!this._canSetTimeBasedCollectionParameters}
                            @input="${this._wavelengthSmoothingInput}"
                          />
                          <p class="caption">nm</p>
                        </div>
                      </div>
                    </label>

                    <label class="title sidebar" gap="m">
                      <div>
                        <span>${getText('Temporal Averaging')}</span>
                        <div class="input-group" fixed>
                          <input
                            @input="${this._temporalAveragingInput}"
                            id="temporal_averaging"
                            type="number"
                            min="${MIN_TEMPORAL_AVERAGING}"
                            max="${MAX_TEMPORAL_AVERAGING}"
                            step="1"
                            required
                            .value="${this.temporalAveraging}"
                            ?disabled="${this._specConnectedBle ||
                            !this._canSetTimeBasedCollectionParameters}"
                          />
                          <p class="caption"></p>
                        </div>
                      </div>
                    </label>
                    ${this.isDcFluorescence
                      ? html`
                          <!--<legend class="spec-params__legend">[[getText('LED Settings')]]</legend>-->
                            <label class="title sidebar" gap="m">
                              <div>
                                <span>${getText('Excitation Wavelength')}</span>
                                <div class="input-group" fixed>
                                  <select
                                    id="excitation_wavelength"
                                    @change="${this._ledWavelengthChanged}"
                                    ?disabled="${this.isCollecting}"
                                  >
                                  ${this.specConnected.supportedLedWavelengths.map(
                                    ledWavelength =>
                                      html`<option
                                        value="${ledWavelength}"
                                        ?selected="${this.ledWavelength === ledWavelength}"
                                      >
                                        ${ledWavelength}
                                      </option>`,
                                  )}
                                  </select>
                                  <p class="caption">nm</p>
                                </div>
                              </div>
                            </label>
                            ${
                              this.specConnected.ledCustomWavelengthSupport
                                ? html`
                                    <label class="title sidebar" gap="m">
                                      <div>
                                        <span>${getText('LED Intensity')}</span>
                                        <div class="input-group" fixed>
                                          <input
                                            class="spec-params__input-scale"
                                            @input="${this._ledIntensityChanged}"
                                            value="${this.ledIntensity}"
                                            id="led_intensity"
                                            type="number"
                                            min="1"
                                            max="100"
                                            required
                                          />
                                          <p class="caption">%</p>
                                        </div>
                                      </div>
                                    </label>
                                  `
                                : ''
                            }
                          </div>
                      `
                      : ''}
                    ${this.showInterval
                      ? html`
                          <label class="title sidebar" gap="m">
                            <div>
                              <span>${getText('Collection Interval')}</span>
                              <div class="input-group" fixed>
                                <input
                                  @input="${this._collectionIntervalInput}"
                                  id="collection_interval"
                                  type="number"
                                  step="1"
                                  min="${this._minCollectionInterval}"
                                  max="${this._maxCollectionInterval}"
                                  required
                                  .value="${this.collectionInterval}"
                                  ?disabled="${!this._canSetTimeBasedCollectionParameters}"
                                />
                                <p class="caption">s</p>
                              </div>
                            </div>
                          </label>
                        `
                      : ''}
                  </div>
                </fieldset>
              </form>
            </vst-ui-form>
          `}
    `;
  }
}
customElements.define('sa-settings', SaSettings);

import {
  GRAPH_BASE_COLUMN_ID_UPDATE,
  initGraph,
  initAnalysis,
  updateGraphBaseColumnId,
  updateGraphOptions,
  updateIsSessionEmpty,
  updateColumnIds,
} from '@common/stores/actions.js';

export * from '@common/stores/actions.js';

export const GRAPHS_SHOW_WAVELENGTH_STRIP_UPDATE = 'GRAPHS_SHOW_WAVELENGTH_STRIP_UPDATE';
export const MINI_GRAPH_OPTIONS_UPDATE = 'MINI_GRAPH_OPTIONS_UPDATE';
export const SPEC_CALIBRATION_STATUS_UPDATE = 'SPEC_CALIBRATION_STATUS_UPDATE';

export const updateGraphBaseColumn = (id, baseColumnId, type) => dispatch => {
  dispatch({
    type: GRAPH_BASE_COLUMN_ID_UPDATE,
    payload: baseColumnId,
    meta: { id },
  });

  dispatch({
    type: GRAPHS_SHOW_WAVELENGTH_STRIP_UPDATE,
    payload: type === 'wavelength',
    meta: { id },
  });
};

export const updateMiniGraphOptions = ({ visible = false } = {}) => ({
  type: MINI_GRAPH_OPTIONS_UPDATE,
  payload: { visible },
  meta: { id: 'graph_1' },
});

export const updateSpecCalibrationStatus = (isSpecCalibrated = false) => ({
  type: SPEC_CALIBRATION_STATUS_UPDATE,
  payload: isSpecCalibrated,
});

export const resetSession =
  (ids = []) =>
  dispatch => {
    ids.forEach(id => {
      dispatch(initGraph(id));
      dispatch(initAnalysis(id));
      dispatch(updateIsSessionEmpty(true));
    });
  };

export const updateGraphsAutoLayout = graphsLayout => dispatch => {
  const graphIds = ['graph_1', 'graph_2', 'graph_3'];

  graphsLayout
    .filter(graphLayout => graphLayout.leftColumnIds.length)
    .forEach((graphLayout, i) => {
      const id = graphIds[i];

      dispatch(updateGraphOptions(id, graphLayout.options));
      dispatch(updateGraphBaseColumnId(id, graphLayout.baseColumnId));
      dispatch(updateColumnIds(graphLayout.leftColumnIds, id, 'left'));
      dispatch(updateColumnIds(graphLayout.rightColumnIds || [], id, 'right'));
    });
};

import { prepareRow } from './prepareRow.js';

export const createRowCache = ({ gridLayout, prepareRowSidebar, prepareGridCell, minRows }) => {
  const { rowHeight, sidebarWidth, gridEl, gridBodyEl, gridBodySidebarEl } = gridLayout;
  const cacheMultiplier = 3;

  let cachedItems = [];
  let previousCachedItems = [];
  let itemsPerScreen = 0;
  let cachedItemsLen = 0;
  let shiftOffset = 0;
  const offsetItems = 2;
  let totalRows = 0;

  // clear all non-const variables
  const clear = () => {
    previousCachedItems = [];
  };

  const initCache = (accumulator, newItemsPerScreen, columnDataList) => {
    itemsPerScreen = newItemsPerScreen + offsetItems;
    cachedItemsLen = itemsPerScreen * cacheMultiplier;
    shiftOffset = itemsPerScreen * (cacheMultiplier - 2);

    previousCachedItems = cachedItems;
    cachedItems = [];

    for (let i = 0; i < cachedItemsLen; i++) {
      if (previousCachedItems.length > 0) {
        const cachedItem = previousCachedItems.pop();

        cachedItems.push(cachedItem);
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line no-continue
        continue;
      }

      const cachedItem = prepareRow({
        prepareRowSidebar,
        prepareGridCell,
        gridEl,
        length: columnDataList.length,
      });

      cachedItems.push(cachedItem);
      const { rowEl, sidebarEl, updateRow } = cachedItem;

      cachedItem.hide = () => {
        rowEl.style.display = 'none';
        sidebarEl.style.display = 'none';
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line no-multi-assign
        sidebarEl.style.transform = rowEl.style.transform = `translate3d(0px, 0px, 0px)`;
      };

      cachedItem.show = (columnDataList, rowIndex) => {
        updateRow(columnDataList, rowIndex);
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line no-multi-assign
        sidebarEl.style.transform = rowEl.style.transform = `translate3d(0px, ${
          rowIndex * rowHeight
        }rem, 0px)`;
        rowEl.style.display = cachedItem.rowElDisplayStyle;
        sidebarEl.style.display = cachedItem.sidebarElDisplayStyle;
      };

      cachedItem.rowElDisplayStyle = rowEl.style.display;
      cachedItem.sidebarElDisplayStyle = sidebarEl.style.display;

      accumulator.queueRender(() => {
        rowEl.style.position = 'absolute';
        rowEl.style.height = `${rowHeight}rem`;
        sidebarEl.style.position = 'absolute';
        sidebarEl.style.height = `${rowHeight}rem`;
        sidebarEl.style['max-height'] = `${rowHeight}rem`;
        sidebarEl.style.width = `${sidebarWidth}rem`;
        gridBodyEl.appendChild(rowEl);
        gridBodySidebarEl.appendChild(sidebarEl);
        cachedItem.hide();
      });
    }

    for (let i = 0; previousCachedItems.length > 0; ++i) {
      const { rowEl, sidebarEl } = previousCachedItems.pop();
      accumulator.queueRender(() => {
        rowEl.remove();
        sidebarEl.remove();
      });
    }
  };

  let cacheRowIndex = 0;
  let previousTopVisibleRow = 0;
  let wasForward = true;

  const _swapCache = () => {
    const temp = previousCachedItems;
    previousCachedItems = cachedItems;
    cachedItems = temp;
  };

  const _renderAll = (accumulator, newCacheRowIndex, columnDataList) => {
    const targetFinalItem = newCacheRowIndex + cachedItemsLen;
    _swapCache();

    accumulator.queueRender(() => {
      for (let rowIndex = newCacheRowIndex; rowIndex < targetFinalItem; rowIndex++) {
        const cacheIndex = rowIndex - newCacheRowIndex;
        const cachedItem = previousCachedItems[cacheIndex];
        cachedItems[cacheIndex] = cachedItem;

        if (rowIndex < totalRows) {
          cachedItem.show(columnDataList, rowIndex);
        } else {
          cachedItem.hide();
        }
      }
    });

    cacheRowIndex = newCacheRowIndex;
  };

  const _shiftNext = (accumulator, newCacheRowIndex, columnDataList) => {
    const diff = newCacheRowIndex - cacheRowIndex;

    if (diff >= cachedItemsLen - 1) {
      _renderAll(accumulator, newCacheRowIndex, columnDataList);
      return;
    }

    const shiftAmount = newCacheRowIndex - cacheRowIndex;
    const shiftFromEnd = cachedItemsLen - shiftAmount;
    const targetFinalItem = newCacheRowIndex + cachedItemsLen;
    _swapCache();

    accumulator.queueRender(() => {
      for (let rowIndex = newCacheRowIndex; rowIndex < targetFinalItem; rowIndex++) {
        const cacheIndex = rowIndex - newCacheRowIndex;
        if (cacheIndex < shiftFromEnd) {
          const previousCacheIndex = cacheIndex + shiftAmount;
          cachedItems[cacheIndex] = previousCachedItems[previousCacheIndex];
        } else {
          const previousCacheIndex = cacheIndex - shiftFromEnd;
          const cachedItem = previousCachedItems[previousCacheIndex];
          cachedItems[cacheIndex] = cachedItem;

          if (rowIndex < totalRows) {
            cachedItem.show(columnDataList, rowIndex);
          } else {
            cachedItem.hide();
          }
        }
      }
    });

    cacheRowIndex = newCacheRowIndex;
  };

  const _shiftPrevious = (accumulator, newCacheRowIndex, columnDataList) => {
    const diff = cacheRowIndex - newCacheRowIndex;

    if (diff >= cachedItemsLen - 1) {
      _renderAll(accumulator, newCacheRowIndex, columnDataList);
      return;
    }

    const shiftAmount = cacheRowIndex - newCacheRowIndex;
    const shiftFromEnd = cachedItemsLen - shiftAmount;
    const targetFinalItem = newCacheRowIndex + cachedItemsLen;
    _swapCache();

    accumulator.queueRender(() => {
      for (let rowIndex = newCacheRowIndex; rowIndex < targetFinalItem; rowIndex++) {
        const cacheIndex = rowIndex - newCacheRowIndex;
        if (cacheIndex < shiftAmount) {
          const previousCacheIndex = cacheIndex + shiftFromEnd;
          const cachedItem = previousCachedItems[previousCacheIndex];
          cachedItems[cacheIndex] = previousCachedItems[previousCacheIndex];

          if (rowIndex < totalRows) {
            cachedItem.show(columnDataList, rowIndex);
          } else {
            cachedItem.hide();
          }
          // accumulator.applyChanges();
        } else {
          const previousCacheIndex = cacheIndex - shiftAmount;
          const previousCachedItem = previousCachedItems[previousCacheIndex];
          if (previousCachedItem) {
            cachedItems[cacheIndex] = previousCachedItem;
          }
        }
      }
    });

    cacheRowIndex = newCacheRowIndex;
  };

  const rerender = (accumulator, columnDataList, topVisibleRow) => {
    const viewChange = topVisibleRow - previousTopVisibleRow;
    previousTopVisibleRow = topVisibleRow;

    if (viewChange === 0) {
      return false;
    }

    const isForward = viewChange > 0;
    const changedDirection = isForward !== wasForward;
    wasForward = isForward;

    if (isForward) {
      const shift = topVisibleRow - cacheRowIndex;

      if (shift < shiftOffset) {
        return false;
      }

      const newCacheRowIndex = topVisibleRow - 1;

      _shiftNext(accumulator, newCacheRowIndex, columnDataList);
      return true;
    }
    if (changedDirection) {
      const visibleOffset = topVisibleRow - cacheRowIndex;
      let shift = itemsPerScreen - visibleOffset + shiftOffset;

      shift = cacheRowIndex < shift ? cacheRowIndex : shift;

      const newCacheRowIndex = cacheRowIndex - shift;

      _shiftPrevious(accumulator, newCacheRowIndex, columnDataList);
      return true;
    }

    const visibleOffset = topVisibleRow - cacheRowIndex;

    if (visibleOffset >= shiftOffset) {
      return false;
    }

    let shift = itemsPerScreen - visibleOffset + shiftOffset;

    shift = cacheRowIndex < shift ? cacheRowIndex : shift;

    const newCacheRowIndex = cacheRowIndex - shift;

    if (newCacheRowIndex === cacheRowIndex) {
      return false;
    }

    _shiftPrevious(accumulator, newCacheRowIndex, columnDataList);
    return true;
  };

  const updateCell = (accumulator, columnDataList, rowIndex, cellIndex) => {
    const lowerBound = cacheRowIndex;
    const unclippedUpperBound = lowerBound + cachedItemsLen;
    const upperBound = unclippedUpperBound > totalRows ? totalRows : unclippedUpperBound;

    if (
      rowIndex >= lowerBound &&
      rowIndex < upperBound &&
      cellIndex >= 0 &&
      cellIndex < columnDataList.length
    ) {
      const cacheIndex = rowIndex - lowerBound;
      const { updateCell: updateRowCell } = cachedItems[cacheIndex];
      const columnData = columnDataList[cellIndex];
      accumulator.queueRender(() => {
        updateRowCell(columnData, rowIndex, cellIndex);
      });
    }
  };

  const appendColumn = (accumulator, columnData) => {
    const fromPos = cacheRowIndex;

    const finalItem = fromPos + cachedItemsLen;

    for (let rowIndex = fromPos; rowIndex < finalItem; rowIndex++) {
      const cacheIndex = rowIndex - fromPos;

      const { appendCell } = cachedItems[cacheIndex];
      const newCell = appendCell(accumulator);

      newCell.update(columnData, rowIndex);
    }
  };

  const insertColumn = (accumulator, columnData, index) => {
    const fromPos = cacheRowIndex;

    const finalItem = fromPos + cachedItemsLen;

    for (let rowIndex = fromPos; rowIndex < finalItem; rowIndex++) {
      const cacheIndex = rowIndex - fromPos;

      const { insertCell } = cachedItems[cacheIndex];
      const newCell = insertCell(accumulator, index);

      newCell.update(columnData, rowIndex);
    }
  };

  const removeColumn = (accumulator, index) => {
    const fromPos = cacheRowIndex;

    const finalItem = fromPos + cachedItemsLen;

    for (let rowIndex = fromPos; rowIndex < finalItem; rowIndex++) {
      const cacheIndex = rowIndex - fromPos;

      const { removeCell } = cachedItems[cacheIndex];
      removeCell(accumulator, index);
    }
  };

  const changeTotalRows = (accumulator, newTotalRows, columnDataList) => {
    if (newTotalRows < minRows || newTotalRows === totalRows) {
      return;
    }

    gridLayout.extendgridBodyEl(newTotalRows);

    if (totalRows > newTotalRows) {
      let startFromPos = cacheRowIndex;
      let newFromPos = newTotalRows - itemsPerScreen;
      if (newFromPos < 0) {
        newFromPos = 0;
      }

      if (startFromPos > newFromPos) {
        startFromPos = newFromPos;
      }

      totalRows = newTotalRows;
      _renderAll(accumulator, startFromPos, columnDataList);
    } else {
      const lowerBound = cacheRowIndex;
      const unclippedUpperBound = lowerBound + cachedItemsLen;
      const previousTotalRows = totalRows;
      totalRows = newTotalRows;

      if (unclippedUpperBound > previousTotalRows) {
        const changeLength =
          unclippedUpperBound < newTotalRows ? unclippedUpperBound : newTotalRows;
        accumulator.queueRender(() => {
          for (let rowIndex = previousTotalRows; rowIndex < changeLength; rowIndex++) {
            const cacheIndex = rowIndex - cacheRowIndex;
            const cachedItem = cachedItems[cacheIndex];
            cachedItem.show(columnDataList, rowIndex);
          }
        });
      }
    }
  };

  const getRenderedStartRowIndex = () => cacheRowIndex;
  const getRenderedLastRowIndex = () => {
    const lowerBound = cacheRowIndex;
    const unclippedUpperBound = lowerBound + cachedItemsLen;
    const upperBound = unclippedUpperBound > totalRows ? totalRows : unclippedUpperBound;

    return upperBound - 1;
  };

  const getCachedRow = index => {
    return cachedItems[index];
  };

  const resize = (accumulator, newItemsPerScreen, topVisibleRow, columnDataList) => {
    if (newItemsPerScreen !== itemsPerScreen + offsetItems) {
      const newCacheRowIndex =
        topVisibleRow > newItemsPerScreen ? topVisibleRow - newItemsPerScreen : 0;
      initCache(accumulator, newItemsPerScreen, columnDataList);
      _renderAll(accumulator, newCacheRowIndex, columnDataList);
    }
  };

  const getCellElement = (columnIndex, rowIndex) => {
    const startRowIndex = getRenderedStartRowIndex();
    const lastRowIndex = getRenderedLastRowIndex();

    if (rowIndex >= startRowIndex && rowIndex <= lastRowIndex && columnIndex >= 0) {
      const cachedRowIndex = rowIndex - startRowIndex;
      const row = cachedItems[cachedRowIndex];
      if (columnIndex <= row.length) {
        const { cellEl } = row.getCell(columnIndex);
        return cellEl;
      }
      return undefined;
    }
    return undefined;
  };

  const getRowSidebarElement = rowIndex => {
    const startRowIndex = getRenderedStartRowIndex();
    const lastRowIndex = getRenderedLastRowIndex();

    if (rowIndex >= startRowIndex && rowIndex <= lastRowIndex) {
      const cachedRowIndex = rowIndex - startRowIndex;
      const { sidebarCellEl } = cachedItems[cachedRowIndex];
      return sidebarCellEl;
    }
    return undefined;
  };

  return {
    initCache,
    rerender,
    updateCell,
    appendColumn,
    insertColumn,
    removeColumn,
    changeTotalRows,
    getRenderedLastRowIndex,
    getRenderedStartRowIndex,
    getCachedRow,
    resize,
    clear,

    getCellElement,
    getRowSidebarElement,

    get totalRows() {
      return totalRows;
    },
    get cachedItemsLen() {
      return cachedItemsLen;
    },
  };
};

import { priv } from '../priv.js';

export const createPrepareGrid =
  ({ interactionContext, dataSetGrid }) =>
  ({ gridEl }) => {
    const { events } = interactionContext;
    gridEl.oncontextmenu = () => false;

    events.registerGridEvent(gridEl, 'grid-touch-tap', () => {
      interactionContext.clear();
    });

    events.registerSystemEvent(gridEl, 'keydown', e => {
      const keyPressed = {};

      const deleteKey = 46;
      const backspaceKey = 8;
      const leftKey = 37;
      const upKey = 38;
      const rightKey = 39;
      const downKey = 40;

      if (e.which === upKey) {
        keyPressed.upKey = true;
      } else if (e.which === downKey) {
        keyPressed.downKey = true;
      } else if (e.which === rightKey) {
        keyPressed.rightKey = true;
      } else if (e.which === leftKey) {
        keyPressed.leftKey = true;
      } else if (e.keyCode === deleteKey || e.keyCode === backspaceKey) {
        keyPressed.deleteKey = true;
      } else if ((e.ctrlKey || e.metaKey || e.altKey) && e.key.length === 1 && e.key[0] === 'a') {
        keyPressed.ctrlA = true;
      } else {
        keyPressed.unknown = true;
      }

      keyPressed.shiftKey = e.shiftKey;

      const checkRowSelection = (currentIndex, nextIndex, selectMany) => {
        if (
          interactionContext.activeRowSelection(currentIndex) &&
          nextIndex >= 0 &&
          nextIndex <= dataSetGrid.totalRows - 1
        ) {
          const { grid } = dataSetGrid[priv];

          interactionContext.closeSelectContextMenu();
          if (selectMany) {
            interactionContext.uiExpandRowSelection(nextIndex);
          } else {
            interactionContext.uiStartRowSelection(nextIndex);
          }

          grid.scrollTo(null, nextIndex, true);
        }
      };

      const checkHorizontalSelection = (moveRight, selectMany) => {
        const interaction = interactionContext.getInteractionInfo();
        if (interaction.columnsSelected) {
          const column = interaction.endCol;

          if (interactionContext.activeColumnSelection(column)) {
            const columnIndex = column.gridPosition;
            const nextColumnIndex = moveRight ? columnIndex + 1 : columnIndex - 1;
            const columns = dataSetGrid[priv].getRawColumns();

            if (nextColumnIndex >= 0 && nextColumnIndex < columns.length) {
              const nextColumn = columns[nextColumnIndex];
              interactionContext.closeSelectContextMenu();
              if (selectMany) {
                interactionContext.uiExpandColumnSelection(nextColumn);
              } else {
                interactionContext.uiStartColumnSelection(nextColumn);
              }

              const { grid } = dataSetGrid[priv];
              grid.scrollTo(nextColumn, null, true);
              e.preventDefault();
            }
          }
        } else if (interaction.dataSetsSelected) {
          const dataSet = interaction.endDataSet;

          if (interactionContext.activeDataSetSelection(dataSet)) {
            const dataSetIndex = dataSet.position;
            const nextDataSetIndex = moveRight ? dataSetIndex + 1 : dataSetIndex - 1;
            const { dataSets } = dataSetGrid[priv];

            if (nextDataSetIndex >= 0 && nextDataSetIndex < dataSets.length) {
              const nextDataSet = dataSets.findByIndex(nextDataSetIndex);
              interactionContext.closeSelectContextMenu();
              if (selectMany) {
                interactionContext.uiExpandDataSetSelection(nextDataSet);
              } else {
                interactionContext.uiStartDataSetSelection(nextDataSet);
              }

              const { grid } = dataSetGrid[priv];
              const dataSetColumns = nextDataSet[priv].columns;
              const dataSetColumnIndex = moveRight ? dataSetColumns.length - 1 : 0;
              const dataSetColumn = dataSetColumns.findByIndex(dataSetColumnIndex);
              grid.scrollTo(dataSetColumn, null, true);
              e.preventDefault();
            }
          }
        }
      };

      if (keyPressed.upKey) {
        const interaction = interactionContext.getInteractionInfo();
        const currentRowIndex = interaction.endRowIndex;
        checkRowSelection(currentRowIndex, currentRowIndex - 1, keyPressed.shiftKey);
      } else if (keyPressed.downKey) {
        const interaction = interactionContext.getInteractionInfo();
        const currentRowIndex = interaction.endRowIndex;
        checkRowSelection(currentRowIndex, currentRowIndex + 1, keyPressed.shiftKey);
      } else if (keyPressed.rightKey) {
        checkHorizontalSelection(true, keyPressed.shiftKey);
      } else if (keyPressed.leftKey) {
        checkHorizontalSelection(false, keyPressed.shiftKey);
      } else if (keyPressed.deleteKey) {
        interactionContext.executeDelete();
        e.preventDefault();
      } else if (keyPressed.ctrlA) {
        interactionContext.uiSelectAll();
        e.preventDefault();
      }
    });

    let shiftKeyCode = -1;

    events.registerSystemEvent(document, 'keydown', e => {
      if (e.shiftKey) {
        shiftKeyCode = e.keyCode;
        interactionContext.shiftSelected = true;
      }
    });

    events.registerSystemEvent(document, 'keyup', e => {
      if (e.keyCode === shiftKeyCode) {
        interactionContext.shiftSelected = false;
      }
    });

    events.registerGridEvent(document, 'grid-touch-select-move', e => {
      const { clientX, clientY } = e.detail;
      interactionContext.checkOverscroll(clientX, clientY);
    });

    events.registerGridEvent(gridEl, 'grid-touch-select-start', () => {
      interactionContext.scrollDisabled = true;
    });

    events.registerGridEvent(document, 'grid-touch-select', () => {
      if (interactionContext.uiSelectionOccurring) {
        interactionContext.uiEndSelection();
        interactionContext.clearOverscroll();
        interactionContext.scrollDisabled = false;

        const interaction = interactionContext.getInteractionInfo();
        const { grid } = dataSetGrid[priv];
        let cellEl;

        if (interaction.cellReselect) {
          cellEl = interaction.cellReselect.cellEl;
          if (interactionContext.selectContextMenuOpen) {
            interactionContext.closeSelectContextMenu();
          }
        } else if (interaction.dataSetsSelected) {
          const dataSetHeaderIndex = interaction.endDataSet.position;
          cellEl = grid.getHeaderElement(dataSetHeaderIndex);
        } else if (interaction.columnsSelected) {
          const dataSetHeaderIndex = interaction.endCol.dataSet.position;
          const columnHeaderIndex = interaction.endCol.position;
          cellEl = grid.getHeaderElement(dataSetHeaderIndex, columnHeaderIndex);
        } else if (interaction.rowsSelected) {
          cellEl = grid.getRowSidebarElement(interaction.endRowIndex);
        } else if (interaction.cellsSelected) {
          cellEl = grid.getCellElement(interaction.endColIndex, interaction.endRowIndex);
        }

        if (cellEl) {
          const { left, top, width, height } = cellEl.getBoundingClientRect();
          const clientX = left + Math.round(width / 2);
          const clientY = top + Math.round(height / 2);
          interactionContext.toggleSelectContextMenu({ cellEl, clientX, clientY, isTouch: true });
        }
      }
    });

    // mouse events

    events.registerGridEvent(gridEl, 'grid-mouse-select-start', () => {
      interactionContext.clear();
    });

    events.registerGridEvent(document, 'grid-mouse-select-move', e => {
      const { clientX, clientY } = e.detail;
      interactionContext.checkOverscroll(clientX, clientY);
    });

    events.registerGridEvent(gridEl, 'grid-mouse-select', () => {
      if (interactionContext.uiSelectionOccurring) {
        interactionContext.uiEndSelection();
        interactionContext.clearOverscroll();
        interactionContext.scrollDisabled = false;
      }
    });
  };

import { LitElement, html } from 'lit';

class SaLogo extends LitElement {
  render() {
    return html`
      <svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        x="0"
        y="0"
        viewBox="0 0 240.6 42.7"
        style="enable-background:new 0 0 240.6 42.7"
        xml:space="preserve"
      >
        <style>
          .st0 {
            enable-background: new;
          }
          .st1 {
            fill: #5a5b5d;
          }
        </style>
        <g class="st0">
          <path
            class="st1"
            d="M63.5 26c-1-.4-1.5-.6-2.5-.6-1.1 0-2.4.4-2.4 1.8 0 2.6 5.8 2 5.8 6.4 0 2.9-2.2 4-4.9 4-.9 0-1.9-.3-2.8-.6l.2-1.8c.7.3 1.8.7 2.8.7 1.1 0 2.6-.6 2.6-2.2 0-3-5.8-2.2-5.8-6.4 0-2.4 1.9-3.7 4.3-3.7.9 0 1.9.2 2.9.5l-.2 1.9zM66.9 23.9h3.5c2.8 0 5.1 1 5.1 4S73.2 32 70.7 32h-1.9v5.4h-1.9V23.9zm1.9 6.4h1.9c1.2 0 2.7-.6 2.7-2.4 0-1.7-1.8-2.3-3-2.3h-1.6v4.7zM77.8 23.9h7.5v1.7h-5.6v3.9h5.1v1.7h-5.1v4.4h5.6v1.7h-7.5V23.9zM97.8 26.2c-.8-.5-1.8-.8-2.8-.8-3.1 0-5.2 2.2-5.2 5.3 0 3.2 2.3 5.3 5.1 5.3 1 0 2.3-.3 2.9-.7l.1 1.9c-1 .4-2.3.5-3.1.5-4.3 0-7.1-2.7-7.1-7 0-4.1 3-6.9 7.1-6.9 1 0 2.1.2 3 .6v1.8zM102.9 25.6h-4.2v-1.7H109v1.7h-4.2v11.8h-1.9V25.6zM110.9 23.9h3.3c2.7 0 5.2.4 5.2 3.6 0 1.6-1.2 2.8-2.8 3.1.6.2.9.5 1.3 1.4l2.5 5.4h-2.2l-2.1-4.7c-.6-1.3-1.2-1.3-2.3-1.3h-1.2v6h-1.9V23.9h.2zm1.9 5.8h1.6c1.8 0 2.9-.8 2.9-2.1 0-1.2-.9-2-2.7-2h-1.9l.1 4.1zM127 23.9h2l5.7 13.5h-2.1l-1.4-3.4h-6.6l-1.4 3.4h-2l5.8-13.5zm1 1.9-2.7 6.7h5.4l-2.7-6.7zM136.7 23.9h1.9v11.8h5.6v1.7h-7.6V23.9h.1zM156 23.9h2l5.7 13.5h-2.1l-1.4-3.4h-6.6l-1.4 3.4h-2l5.8-13.5zm.9 1.9-2.7 6.7h5.4l-2.7-6.7zM165.6 23.9h2.6l5.9 10.8V23.9h1.9v13.5h-2.5l-6.1-11.1v11.1h-1.9V23.9h.1zM183.9 23.9h2l5.7 13.5h-2.1l-1.4-3.4h-6.6l-1.4 3.4h-2l5.8-13.5zm.9 1.9-2.7 6.7h5.4l-2.7-6.7z"
          />
        </g>
        <g class="st0"><path class="st1" d="M193.5 23.9h1.9v11.8h5.6v1.7h-7.6V23.9h.1z" /></g>
        <g class="st0">
          <path
            class="st1"
            d="m205.2 31.8-5.3-7.8h2.3l4 6 3.9-6h2.3l-5.2 7.8v5.6h-1.9v-5.6h-.1zM220.4 26c-1-.4-1.5-.6-2.5-.6-1.1 0-2.4.4-2.4 1.8 0 2.6 5.8 2 5.8 6.4 0 2.9-2.2 4-4.9 4-.9 0-1.9-.3-2.8-.6l.2-1.8c.7.3 1.8.7 2.8.7 1.1 0 2.6-.6 2.6-2.2 0-3-5.8-2.2-5.8-6.4 0-2.4 1.9-3.7 4.3-3.7.9 0 2 .2 2.9.5l-.2 1.9zM223.9 23.9h1.9v13.5h-1.9V23.9zM235.4 26c-1-.4-1.5-.6-2.5-.6-1.1 0-2.4.4-2.4 1.8 0 2.6 5.8 2 5.8 6.4 0 2.9-2.2 4-4.9 4-.9 0-1.9-.3-2.8-.6l.2-1.8c.7.3 1.8.7 2.8.7 1.1 0 2.6-.6 2.6-2.2 0-3-5.8-2.2-5.8-6.4 0-2.4 1.9-3.7 4.3-3.7.9 0 2 .2 2.9.5l-.2 1.9z"
          />
        </g>
        <linearGradient
          id="SVGID_1_"
          gradientUnits="userSpaceOnUse"
          x1=".1"
          y1="7.25"
          x2="46.1"
          y2="7.25"
          gradientTransform="matrix(1 0 0 -1 0 44)"
        >
          <stop offset="0" style="stop-color:#733562" />
          <stop offset=".189" style="stop-color:#333391" />
          <stop offset=".332" style="stop-color:#00a8de" />
          <stop offset=".46" style="stop-color:#009e54" />
          <stop offset=".662" style="stop-color:#fde92b" />
          <stop offset=".9" style="stop-color:#eb2d2e" />
        </linearGradient>
        <path style="fill:url(#SVGID_1_)" d="M.1 35.4h46v2.7H.1z" />
        <path
          d="M.2 32.7c2 .2 3.8.4 5.5.3 1.9-.1 3.4-.5 4.7-1.3 1.3-.9 2.5-2.1 3.5-3.6.8-1.3 1.5-2.8 2-4.5.9-2.8 1.3-5.8 1.5-8.1v.3c.3 2.8.7 6.6 2 9.8 1.6 3.9 4.2 6 7.6 6.3h.4c2.4 0 4.1-1.8 5.4-3.2.8-.9 1.7-1.8 2.3-1.8.5 0 .9.2 1.4.5.6.3 1.1.8 1.7 1.4 1.6 1.5 3.8 3.6 7.9 4.6v-2c-3.3-1-5.1-2.6-6.6-4-.6-.6-1.2-1.2-1.9-1.6-.8-.5-1.6-.8-2.4-.8-1.5 0-2.5 1.2-3.7 2.4-.7.7-1.4 1.4-2.1 1.9s-1.5.8-2.2.7c-1.5-.1-2.7-.6-3.7-1.6-.9-.9-1.7-2.1-2.3-3.6-1.2-3-1.6-6.6-1.9-9.3-.1-1.3-.2-2.3-.4-3.1-.1-.5-.3-.8-.4-1.1-.4-.7-.9-.8-1.2-.8-.2 0-.8.1-1.1.8-.1.2-.2.5-.3.9-.1.7-.2 1.6-.3 2.6-.3 2.2-.6 5.3-1.5 8.2-.5 1.5-1.1 2.9-1.8 4-.8 1.3-1.8 2.3-2.9 3-1 .6-2.2.9-3.8 1s-3.5-.1-5.4-.3v2z"
          style="fill:#ee3524"
        />
        <g>
          <path
            class="st1"
            d="M240.1 26.9c-.4.4-.8.6-1.4.6-.5 0-1-.2-1.4-.6-.4-.4-.6-.8-.6-1.4 0-.5.2-1 .6-1.4.4-.4.8-.6 1.4-.6.5 0 1 .2 1.4.6.4.4.6.8.6 1.4-.1.5-.3 1-.6 1.4zm-2.5-.3c.3.3.6.4 1 .4s.8-.1 1-.4c.3-.3.4-.7.4-1.1 0-.4-.1-.8-.4-1.1-.3-.3-.6-.4-1-.4s-.8.1-1 .4c-.3.3-.4.7-.4 1.1 0 .5.2.8.4 1.1zm.3 0v-2.2h.8c.3 0 .5.1.6.2.1.1.2.3.2.5 0 .4-.2.6-.6.6l.6 1h-.4l-.6-.9h-.2v.9h-.4zm.5-1.9v.6h.4c.1 0 .3 0 .3-.1.1 0 .1-.1.1-.3 0-.2-.1-.3-.4-.3h-.4z"
          />
        </g>
      </svg>
    `;
  }
}

customElements.define('sa-logo', SaLogo);

import { LitElement, html } from 'lit';
import { Requester } from '@mixins/vst-core-requester-mixin.js';

import { sprintf } from '@libs/sprintf.js';
import { getText } from '@utils/i18n.js';
import { globalStyles } from '@styles/vst-style-global.css.js';
import { shouldDisplayUpdate } from '@utils/osFloorCheck.js';

import saAboutStyles from './sa-about.css.js';

import '../sa-logo.js';

class SaAbout extends Requester(LitElement) {
  static get properties() {
    return {
      _appVersion: { state: true, type: String },
    };
  }

  constructor() {
    super();
    this._logoClickCount = 0;
    if (shouldDisplayUpdate()) {
      import('@components/vst-core-autoupdate/vst-core-autoupdate.js');
    }
    this._appVersion = '';
  }

  firstUpdated() {
    [this.$appManifest] = this.requestServices(['appManifest']);
    this._appVersion = this.$appManifest.getAppVersion();
  }

  _logoClicked() {
    this._logoClickCount += 1;
    if (this._logoClickCount >= 10) {
      this.dispatchEvent(
        new CustomEvent('open-dialog', {
          bubbles: true,
          composed: true,
          detail: {
            dialog: 'feature_flags',
          },
        }),
      );
      this._logoClickCount = 0;
    }
  }

  static get styles() {
    return [globalStyles, saAboutStyles];
  }

  render() {
    return html`
      <sa-logo class="app-logo"></sa-logo>

      <div class="content stack" gap="l" style="--justify: center;">
        <p class="version-legal">
          v${this._appVersion}
          ${sprintf(getText(`© 2012-%s Vernier Science Education`), CURRENT_YEAR)}
        </p>
        ${shouldDisplayUpdate()
          ? html`<vst-core-autoupdate class="autoupdate"></vst-core-autoupdate>`
          : ''}
        <p>
          ${getText(
            `Vernier Spectral Analysis® is a tool for science students to collect and analyze data from Vernier spectrophotometers.`,
          )}
        </p>
      </div>

      <vst-ui-logo-vernier
        class="vernier-logo"
        alt="Vernier Logo"
        @click="${this._logoClicked}"
      ></vst-ui-logo-vernier>

      <footer class="terms">
        <div class="terms__links">
          <a
            class="link"
            href="https://www.vernier.com/legal-center/applications-privacy/"
            target="_blank"
            >${getText('Privacy Notice')}</a
          >
          <a
            class="link"
            href="https://www.vernier.com/legal-center/applications-terms/"
            target="_blank"
            >${getText('Terms of Use')}</a
          >
          <a class="link" href="/cookies.html" target="_blank">${getText('Cookie Notice')}</a>
        </div>
      </footer>
    `;
  }
}

customElements.define('sa-about', SaAbout);

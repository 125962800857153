import { LitElement, html } from 'lit';
import { sprintf } from '@libs/sprintf.js';

import { getText, isLocaleRationalNumber } from '@utils/i18n.js';

import { vstAuthStore } from '@stores/vst-auth.store.js';
import { globalStyles } from '@styles/vst-style-global.css.js';
import { vstUiEweStyles } from './vst-ui-ewe.css.js';

import '@components/vst-ui-form/vst-ui-form.js';

const _trimPoint = reading => {
  const { formatStr } = reading.column.group.precision;
  const { value } = reading;

  return sprintf(formatStr, value);
};

const _getNameUnits = column => (column && column.group ? column.group.getNameUnits() : '');

class VstUIEwe extends LitElement {
  static get properties() {
    return {
      _isCategoricalConfirmationDialogOpen: { state: true },
      rowNumber: {
        type: Number,
      },
      view: {
        type: Object,
      },
      columns: {
        type: Array,
      },
    };
  }

  constructor() {
    super();

    this._isCategoricalConfirmationDialogOpen = false;
    this.rowNumber = 0;
    this.view = {
      eventColumn: null,
      keepResults: [],
    };
    this.columns = [];
  }

  async firstUpdated() {
    this.eventEl = this.shadowRoot.getElementById('event');
    // This data will be stale unless a new ewe is instantiated at each collection
    this.eventColumn = this.view.eventColumn;

    this.view.keepResults.forEach(result => {
      this.columns.push(result.column);
    });

    this.rowNumber = this.columns[0].values.length + 1;

    /* updateComplete resolves on the current render, after firstUpdated.
     * awaiting it is needed to ensure eventEl is focusable. */
    await this.updateComplete;
    this.eventEl.focus();
  }

  async submitForm() {
    if (
      vstAuthStore.authorized &&
      this.view.eventColumn.dataType === 'numeric' &&
      !isLocaleRationalNumber(this.eventEl.value)
    ) {
      await import(
        '@components/vst-ui-categorical-confirmation/vst-ui-categorical-confirmation.js'
      );

      this._isCategoricalConfirmationDialogOpen = true;
    } else {
      this._updateValues();
    }
  }

  _updateValues() {
    this.dispatchEvent(
      new CustomEvent('kept-point', {
        detail: {
          userValue: this.eventEl.value,
          readings: this.view.keepResults,
          eventColId: this.eventColumn.id,
        },
      }),
    );

    this.rowNumber += 1;
    this.eventEl.value = '';
  }

  cancel() {
    this.dispatchEvent(new CustomEvent('cancel'));
  }

  static get styles() {
    return [globalStyles, vstUiEweStyles];
  }

  _renderDialogs() {
    return html` <vst-ui-dialog
      @dialog-close="${e => {
        e.stopPropagation();
        this._isCategoricalConfirmationDialogOpen = false;
      }}"
      id="categorical-confirmation"
      ?open="${this._isCategoricalConfirmationDialogOpen}"
    >
      <h2 slot="header">${getText('Entering Categorical Mode')}</h2>
      <vst-ui-categorical-confirmation
        @categorical-confirmed="${() => {
          this._updateValues();
          this._isCategoricalConfirmationDialogOpen = false;
        }}"
        @categorical-canceled="${() => {
          this._isCategoricalConfirmationDialogOpen = false;
        }}"
        slot="content"
      ></vst-ui-categorical-confirmation>
    </vst-ui-dialog>`;
  }

  render() {
    return html`
      <vst-ui-form @submit="${this.submitForm}">
        <form>
          <div class="reel" margin="block-end-m">
            <table>
              <thead>
                <tr>
                  <th class="spacer-gif"></th>
                  <th class="column-header">
                    <label for="event"><span>${_getNameUnits(this.eventColumn)}</span></label>
                  </th>
                  ${this.columns.map(
                    column =>
                      html`
                        <th class="column-header">
                          <span>${_getNameUnits(column)}</span>
                        </th>
                      `,
                  )}
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th>${this.rowNumber}</th>
                  <td class="event">
                    <input
                      type=${vstAuthStore.authorized ? 'text' : 'number'}
                      step="any"
                      id="event"
                      name="event"
                      maxlength="1000"
                    />
                  </td>
                  ${this.view.keepResults.map(
                    result => html` <td class="column-header">${_trimPoint(result)}</td> `,
                  )}
                </tr>
              </tbody>
            </table>
          </div>

          <div class="flex">
            <button
              class="btn"
              variant="text"
              margin="inline-end-xs"
              @click="${this.cancel}"
              type="button"
              id="cancel_btn"
            >
              ${getText('Cancel')}
            </button>
            <button class="btn" id="submit_btn" type="submit">${getText('Keep Point')}</button>
          </div>
        </form>
      </vst-ui-form>

      ${this._renderDialogs()}
    `;
  }
}

customElements.define('vst-ui-ewe', VstUIEwe);

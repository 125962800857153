// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-extraneous-dependencies
import EventEmitter from 'eventemitter3';

/**
 * DataSet types
 * @enum {string}
 * @readonly
 */
export const DataSetType = Object.freeze({
  FFT: 'fft',
  HISTOGRAM: 'histogram',
  REGULAR: 'regular',
});

// Events:
// 'name-changed':      string
// 'column-added':      columnId
// 'column-removed':    columnId
let nextId = 0;

export class DataSet extends EventEmitter {
  constructor({
    id = `${nextId++}`,
    type = 'regular',
    name = 'DataSet',
    position = 0,
    columnIds = [],
  }) {
    super();
    this._id = id;
    this._type = type;
    this._name = name;
    this._position = position;
    this._columnIds = columnIds;
  }

  get id() {
    return this._id;
  }

  /** @type {DataSetType} */
  get type() {
    return this._type;
  }

  get name() {
    return this._name;
  }

  set name(name) {
    this._name = name;
    this.emit('name-changed', this.name);
  }

  get position() {
    return this._position;
  }

  set position(pos) {
    this._position = pos;
    this.emit('position-changed', this.position);
  }

  get columnIds() {
    return [...this._columnIds]; // return a copy
  }

  addColumn(columnId, siblingId = null, beforeSibling = false) {
    const columnIds = this._columnIds;

    if (!columnIds.includes(columnId)) {
      let inserted = false;

      if (siblingId && siblingId !== '0') {
        let pos = columnIds.indexOf(siblingId);
        if (pos >= 0) {
          if (!beforeSibling) {
            pos++;
          }

          columnIds.splice(pos, 0, columnId);
          inserted = true;
        }
      } else if (beforeSibling) {
        columnIds.splice(0, 0, columnIds);
        inserted = true;
      }

      if (!inserted) {
        columnIds.push(columnId);
      }

      this.emit('column-added', columnId);
    }
  }

  removeColumn(columnId) {
    const columnIds = this._columnIds;
    const index = columnIds.indexOf(columnId);
    if (index >= 0) {
      columnIds.splice(index, 1);
      this.emit('column-removed', columnId);
    }
  }
}

import { css } from 'lit';
import { buttonReset } from '../styles/index.js';

export default css`
  :host {
    --tick-width: 0px;
    /*  stylelint-disable length-zero-no-unit */
    --vertical-axis-width: 3.125rem;
    --label-size: 0.67em;
    --graph-tools-color: #000;
    display: flex;
    flex: 1;
    position: relative;
    background: var(--vst-color-bg-primary);
    width: 100%;
    height: 100%;
  }

  /*  GRAPH STYLES */
  .graph {
    display: flex;
    flex-direction: column;
    flex: 1;
    background: var(--vst-color-bg-graph);
  }

  .graph-wrapper {
    background: var(--vst-color-bg-graph);
    display: flex;
    flex-direction: row;
    position: relative;
    flex: 1;
    justify-content: space-between;
  }

  .flex-col {
    display: flex;
    flex-direction: column;
    flex-grow: 0;
    flex-shrink: 1;
    flex-basis: calc(100% - var(--vertical-axis-width) - var(--tick-width));
    position: relative;
  }

  #event_box,
  #base_axis_box,
  #left_axis_box,
  #right_axis_box {
    position: absolute;
  }

  :host(:not([right-axis-enabled])) #right_axis_box {
    display: none;
  }

  #event_box {
    display: flex;
    flex: 1;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  #event_box > * {
    flex: 1;
    user-select: text;
  }

  #base_axis_box {
    right: 0;
    bottom: 0;
    cursor: ew-resize;
  }

  #left_axis_box {
    top: 0;
    left: 0;
    height: 100%;
    cursor: ns-resize;
  }

  #right_axis_box {
    top: 0;
    right: 0;
    height: 100%;
    cursor: ns-resize;
  }

  :host([axis-translate-disabled]) #base_axis_box {
    cursor: default;
  }

  :host([axis-translate-disabled]) #left_axis_box {
    cursor: default;
  }

  :host([axis-translate-disabled]) #right_axis_box {
    cursor: default;
  }

  /*  .graph-title, */
  .graph-left-axis,
  .graph-right-axis,
  .graph-base-axis {
    flex: 0 1 auto;
  }

  .graph-placeholder {
    position: relative;
    flex: 1;
    padding-block-start: 1em;
    max-width: calc(100% - 1em);
  }

  #chart_canvas {
    width: 100%;
    height: 100%;
  }

  .title:not([margin]) {
    width: inherit;
    text-align: center;
    margin: var(--vst-space-xs) 0 calc(-1 * var(--vst-space-xs)) 0;
    position: relative;
    z-index: 1;
    /*  this fixes it from getting cutoff for some reason */
  }

  .graph-axis-label-unset {
    color: #646464;
  }

  .graph-base-axis {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    /*  height: 40px; */
    width: 100%;
  }

  .graph-left-axis {
    position: relative;
    flex: 1;
    /*  if you remove this the y-axix alignment across all graphs will break :( */
    min-inline-size: var(--vertical-axis-width);
  }

  .graph-right-axis {
    position: relative;
    flex: 1;
    /*  if you remove this the y-axix alignment across all graphs will break :( */
    min-inline-size: var(--vertical-axis-width);
  }

  .left-axis-label-wrapper,
  .right-axis-label-wrapper {
    display: flex;
    justify-content: center;
    pointer-events: none;
    transform: rotate(-90deg);
    transform-origin: left;
    position: absolute;
    bottom: 1.125rem;
    left: 50%;
    text-align: center;
    margin: 0;
    z-index: calc(var(--vst-z-toolbar) - 1);
    width: 100%;
  }

  ${buttonReset(css`.base-axis-label,
  .left-axis-label,
  .right-axis-label`)}

  .base-axis-label,
  .left-axis-label,
  .right-axis-label {
    position: relative;
    pointer-events: auto;
    background: var(--vst-color-btn-graph-bg);
    border: 1px solid var(--vst-color-btn-graph-border);
    border-radius: var(--vst-radius);
    padding: var(--vst-space-xs) var(--vst-space-s);
    font-size: var(--label-size);
    font-weight: bold;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-inline-size: 100%;
    color: var(--vst-color-fg-primary);
    margin: var(--vst-space-xs);
    z-index: var(--vst-z-toolbar);
    transition: all 0.3s;
    align-items: center;
    justify-content: center;
  }

  .base-axis-wrapper {
    position: relative;
  }

  .base-axis-label:hover,
  .left-axis-label:hover,
  .right-axis-label:hover,
  .base-axis-label:active,
  .left-axis-label:active,
  .right-axis-label:active {
    box-shadow: var(--vst-shadow-s);
    border-color: var(--vst-color-btn-graph-border-hover);
    background: var(--vst-color-btn-graph-bg-hover);
  }

  .base-axis-label[disabled],
  .left-axis-label[disabled],
  .right-axis-label[disabled] {
    border-color: transparent;
    box-shadow: none;
    cursor: default;
  }

  .left-axis-label,
  .right-axis-label {
    display: flex;
  }
  .left-axis-label__colname-color-wrapper,
  .right-axis-label__colname-color-wrapper {
    padding: 0 0.35em;
    overflow: hidden;
  }
  .left-axis-label__color-indicator-wrapper,
  .right-axis-label__color-indicator-wrapper {
    display: flex;
    flex: 1;
  }
  .left-axis-label__color-indicator,
  .right-axis-label__color-indicator {
    flex: 1;
    height: 0.125rem;
  }
  .left-axis-label__colname-wrapper,
  .right-axis-label__colname-wrapper {
    display: flex;
    justify-content: center;
  }
  .left-axis-label__colname,
  .right-axis-label__colname {
    overflow: hidden;
    text-overflow: clip;
  }
  .left-axis-label__colunits,
  .right-axis-label__colunits {
    margin-inline-start: 0.1875rem;
    flex-shrink: 0;
  }

  #plot_box {
    position: absolute;
    cursor: crosshair;
  }

  .rainbow-wrapper {
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
    pointer-events: none;
  }

  .graph-actions {
    position: absolute;
    bottom: var(--vst-space-xs);
    left: var(--vst-space-xs);
    display: grid;
    grid-template-columns: 1fr 1fr auto;
    grid-gap: var(--vst-space-xs);
    z-index: var(--vst-z-graph-tools-btn);
  }

  ${buttonReset(css`.graph-actions__btn`)}
  .graph-actions__btn {
    font-size: 1em;
    width: 2.25em;
    height: 2.25em;
    background: transparent;
    padding: 0;
    border: 1px solid var(--vst-color-bg-secondary);
    border-radius: var(--vst-radius);
    transition: var(--transition-default);
  }
  .graph-actions__btn:hover {
    border-color: var(--vst-color-bg-primary);
    box-shadow: var(--vst-shadow-xs);
  }
  .graph-actions__btn:hover icon-graph-tools {
    opacity: 1;
  }
  .graph-actions__btn:active {
    box-shadow: var(--vst-shadow-xs), var(--box-shadow-inset);
  }
  .btn[variant='graph'].graph-actions__btn--look-at-me {
    --icon-color: var(--vst-color-info);
    background-color: var(--vst-color-info-bg);
    border-color: var(--vst-color-info);
  }
  .btn[variant='graph'].graph-actions__btn--look-at-me:hover {
    transition: none;
    background-color: var(--vst-color-bg-secondary);
    border-color: var(--vst-color-info);
  }
  .btn[variant='graph'].graph-actions__btn--look-at-me:active {
    box-shadow: var(--box-shadow-inset);
  }
  .btn[variant='graph'].graph-actions__btn--look-at-me vst-ui-icon {
    opacity: 1;
  }
  .graph-actions ::slotted(.btn) {
    --btn-font-size: 1em;
    --btn-height: 2.25em;
    --btn-icon-side-length: 1.5rem;
    --btn-icon-margin-inline-start: calc((2.25em - 1.5rem) / 2);
  }
  .btn[variant='graph'] vst-ui-icon {
    margin: 0 auto;
    display: block;
    --vst-ui-icon-color: var(--icon-color, var(--vst-color-fg-primary));
  }

  /* ////////////////////////////// */
  /*  Overrides while downloading an image */
  /*  Hide all the graph actions buttons */
  :host(.download-image) {
    /*  hide the borders */
    /*  TODO: fix minigraph alignment issues on export. For now we hide it. */
  }
  :host(.download-image) .graph-actions__btn {
    display: none;
  }
  :host(.download-image) .left-axis-label,
  :host(.download-image) .right-axis-label,
  :host(.download-image) .base-axis-label {
    border: 0;
  }
  :host(.download-image) ::slotted([slot='mini_graph']) {
    display: none;
  }
  :host(.download-image) paper-tooltip {
    display: none;
  }

  /* ////////////////////////////// */
  /*  Mini Graph */
  /*  TODO: I'm not in love with this putting these styles here because they should probably be in mini-graph.css.js.  If we put it there, we need to use custom properties for each of these overrides. For now this keeps us from using ::shadow however. */
  :host(.mini-graph) {
    --vertical-axis-width: 1.5rem;
    background: var(--vst-color-bg-secondary);
    width: 12rem;
    height: 8.5rem;
    font-size: 0.8rem;
  }
  :host(.mini-graph) .graph-title {
    display: none;
  }
  :host(.mini-graph) .base-axis-label,
  :host(.mini-graph) .left-axis-label,
  :host(.mini-graph) .right-axis-label {
    padding: 0;
  }
  :host(.mini-graph) .graph-left-axis,
  :host(.mini-graph) .graph-right-axis {
    min-inline-size: 0;
    width: 1.125rem;
    flex: none;
  }
  :host(.mini-graph) .left-axis-label-wrapper,
  :host(.mini-graph) .right-axis-label-wrapper {
    height: auto;
    left: 0.75em;
    bottom: 1em;
  }

  ::slotted(vst-ui-draggable.legend) {
    position: absolute;
    right: 1rem;
    top: 1rem;
    z-index: var(--vst-z-graph-legend);
    border: 1px solid var(--vst-color-bg-primary);
    box-shadow: var(--vst-shadow-s);
  }
`;

export const applyHorizontalScroll = ({ gridLayout, scrollState }) => {
  const { gridHeaderViewportEl, gridBodyEl } = gridLayout;
  const { onScroll } = scrollState;

  let ignoreBodyScrolling = false;
  let ignoreHeaderScolling = false;

  const headerOnScroll = () => {
    if (ignoreHeaderScolling) {
      ignoreHeaderScolling = false;
      return;
    }

    onScroll();
    ignoreBodyScrolling = true;
    gridBodyEl.scrollLeft = gridHeaderViewportEl.scrollLeft;
  };

  gridHeaderViewportEl.addEventListener('scroll', headerOnScroll);

  const bodyOnScroll = () => {
    if (ignoreBodyScrolling) {
      ignoreBodyScrolling = false;
      return;
    }
    onScroll();
    ignoreHeaderScolling = true;
    gridHeaderViewportEl.scrollLeft = gridBodyEl.scrollLeft;
  };

  gridBodyEl.addEventListener('scroll', bodyOnScroll);
};

import { pointerTracker } from '@utils/pointerTracker.js';

let minSize = 0;
let coreContentLayoutEl = null;
let contentLayoutElRect = null;
let panelEls = null;
let panel1ElRect = null;
let panel2ElRect = null;
let panel3ElRect = null;
let orientation = '';
let startX = null;
let startY = null;
let dx = null;
let dy = null;

const clamp = (val, min, max) => Math.min(Math.max(min, val), max);

export const resizePanels = {
  init(contentLayoutEl, panelDivider1El, panelDivider2El, panelDivider3El) {
    coreContentLayoutEl = contentLayoutEl;
    panelEls = contentLayoutEl.querySelectorAll('.content-layout__panel');

    minSize = parseFloat(getComputedStyle(coreContentLayoutEl).getPropertyValue('--min-size'));

    pointerTracker(panelDivider1El, this._handleDividerResize.bind(this));
    pointerTracker(panelDivider2El, this._handleDividerResize.bind(this));

    if (panelDivider3El) {
      pointerTracker(panelDivider3El, this._handleDividerResize.bind(this));
    }
  },

  /*
        if visiblepanels = 2
          set panel-1-size to % value and panel-2-size to 1fr;

        if visiblepanels = 3
          if etarget = divider1
            set panel-1 size to % value

            get the height of panel2 and 3 combined
            set panel-2 size to % of that height (but in fr)
            set panel-3 size to its % of that height (but in fr)

          elseif etarget = divider2
            get the height of panel2 and 3 combined
            set panel-2 size to % of that height (but in fr)
            set panel-3 size to its % of that height (but in fr)

        if visiblepanels = 3
          if etarget = divider1
            set col-1 size to % value
            set col-2 size to 1fr;

          elseif etarget = divider2
            set panel-1 size to % value
            set panel-2 size to 1fr

          elseif etarget = divider3
            set panel-3 size to % value
            set panel-4 size to 1fr
    */

  _handleDividerResize(e) {
    const { state, x, y } = e.detail;

    const divider1Dragging = () => {
      if (orientation === 'portrait') {
        const panel1Size = clamp(panel1ElRect.height + dy, minSize, contentLayoutElRect.height);
        const panel1Percent = (panel1Size / contentLayoutElRect.height) * 100;
        coreContentLayoutEl.style.setProperty('--panel1-size', `${panel1Percent}%`);
      } else {
        const panel1Size = clamp(panel1ElRect.width + dx, minSize, contentLayoutElRect.width);
        const panel1Percent = (panel1Size / contentLayoutElRect.width) * 100;
        coreContentLayoutEl.style.setProperty('--panel1-size', `${panel1Percent}%`);
      }
    };

    const divider2Dragging = () => {
      const panel3Max = panel2ElRect.height + panel3ElRect.height - minSize;
      const panel3Size = clamp(panel3ElRect.height - dy, minSize, panel3Max);
      const panel3Percent = (panel3Size / contentLayoutElRect.height) * 100;
      coreContentLayoutEl.style.setProperty('--panel3-size', `${panel3Percent}%`);
    };

    const panel4Divider1Dragging = () => {
      if (orientation === 'portrait') {
        const col1Size = clamp(y, minSize, contentLayoutElRect.height - minSize);
        const col1Percent = (col1Size / contentLayoutElRect.height) * 100;
        coreContentLayoutEl.style.setProperty('--col1-size', `${col1Percent}%`);
      } else {
        const col1Size = clamp(x, minSize, contentLayoutElRect.width);
        const col1Percent = (col1Size / contentLayoutElRect.width) * 100;
        coreContentLayoutEl.style.setProperty('--col1-size', `${col1Percent}%`);
      }
    };

    const panel4Divider2Dragging = () => {
      const panel1Size = clamp(panel1ElRect.height + dy, minSize, contentLayoutElRect.height);
      const panel1Percent = (panel1Size / contentLayoutElRect.height) * 100;
      coreContentLayoutEl.style.setProperty('--panel1-size', `${panel1Percent}%`);
    };

    const panel4Divider3Dragging = () => {
      const panel3Size = clamp(panel3ElRect.height + dy, minSize, contentLayoutElRect.height);
      const panel3Percent = (panel3Size / contentLayoutElRect.height) * 100;
      coreContentLayoutEl.style.setProperty('--panel3-size', `${panel3Percent}%`);
    };

    if (state === 'start') {
      startX = x;
      startY = y;
      orientation = getComputedStyle(coreContentLayoutEl).getPropertyValue('--orientation').trim();
      contentLayoutElRect = coreContentLayoutEl.getBoundingClientRect();

      panelEls = coreContentLayoutEl.querySelectorAll('.content-layout__panel:not([hidden]'); // need to queryselector again since the panels could have been reordered by swappable

      panel1ElRect = panelEls[0].getBoundingClientRect();

      if (coreContentLayoutEl.visiblePanels >= 3) {
        panel2ElRect = panelEls[1].getBoundingClientRect();
        panel3ElRect = panelEls[2].getBoundingClientRect();
      }
    } else if (state === 'moving' && (x !== startX || y !== startY)) {
      dx = x - startX;
      dy = y - startY;
      if (coreContentLayoutEl.visiblePanels === 2) {
        divider1Dragging();
      } else if (coreContentLayoutEl.visiblePanels === 3) {
        if (e.currentTarget.id === 'panel_divider_1') {
          divider1Dragging();
        } else if (e.currentTarget.id === 'panel_divider_2') {
          divider2Dragging();
        }
      } else if (coreContentLayoutEl.visiblePanels === 4) {
        if (e.currentTarget.id === 'panel_divider_1') {
          panel4Divider1Dragging();
        } else if (e.currentTarget.id === 'panel_divider_2') {
          panel4Divider2Dragging();
        } else if (e.currentTarget.id === 'panel_divider_3') {
          panel4Divider3Dragging();
        }
      }
    }
  },
};

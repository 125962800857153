import { css } from 'lit';

import { breakpoint } from '@styles/mixins/breakpoints.css.js';
import { animationFadeIn, animationSlideIn } from '@styles/mixins/animations.css.js';

export const vstCoreContentLayoutStyles = css`
  vst-core-content-layout {
    --min-size: 25%;
    --drag-handle-size: 4px;
    --orientation: portrait;

    --panel1-size: 66%;
    --panel2-size: calc(100% - var(--panel1-size));
    --panel3-size: 50%;
    --panel4-size: calc(100% - var(--panel3-size));
    --col1-size: 66%;
    --col2-size: calc(100% - var(--col1-size));
  }

  ${breakpoint(
    'm',
    css`
      vst-core-content-layout {
        --min-size: 15%;
      }
    `,
  )}

  :host {
    display: flex;
    height: 100%;
    flex: 1;
    overflow: hidden;
  }

  .content-layout {
    width: 100%;
    height: 100%;
    display: grid;
    grid-gap: 0;
    background-color: var(--vst-color-bg);
  }

  /* Portrait Grids */
  .content-layout--visible-2 {
    grid-template-rows: minmax(var(--min-size), var(--panel1-size)) var(--drag-handle-size) minmax(
        var(--min-size),
        var(--panel2-size)
      );
  }

  .content-layout--visible-3 {
    grid-template-rows:
      minmax(var(--min-size), var(--panel1-size)) var(--drag-handle-size) minmax(
        var(--min-size),
        var(--panel2-size)
      )
      var(--drag-handle-size) minmax(var(--min-size), var(--panel3-size));
  }

  .content-layout--visible-4 {
    grid-template-rows: minmax(var(--min-size), var(--col1-size)) var(--drag-handle-size) minmax(
        var(--min-size),
        var(--col2-size)
      );
  }

  .content-layout--visible-4 .content-layout__subgrid {
    display: grid;
  }

  .content-layout--visible-4 .content-layout__subgrid-1 {
    grid-template-rows: minmax(var(--min-size), var(--panel1-size)) var(--drag-handle-size) minmax(
        var(--min-size),
        var(--panel2-size)
      );
  }

  .content-layout--visible-4 .content-layout__subgrid-2 {
    grid-template-rows: minmax(var(--min-size), var(--panel3-size)) var(--drag-handle-size) minmax(
        var(--min-size),
        var(--panel4-size)
      );
  }

  /* Divider Styling */
  .content-layout__divider {
    border: 0;
    padding: 0;
    cursor: row-resize;
    position: relative;
    background-color: var(--vst-color-bg-primary);
    z-index: 2;
    block-size: 100%;
    inline-size: 100%;
  }

  .content-layout__divider:focus {
    box-shadow: none;
  }

  .content-layout__divider:focus::after {
    box-shadow: var(--vst-outline);
  }

  .content-layout__divider:not(:focus-visible)::after {
    box-shadow: none;
  }

  #panel_divider_1 {
    z-index: 3;
  }
  .content-layout__divider:hover {
    background-color: var(--vst-color-bg-light);
  }

  .content-layout__divider:focus {
    outline: 0;
  }

  .content-layout__divider:focus::after {
    background: var(--vst-color-bg);
  }

  .content-layout__divider::after {
    display: block;
    content: '';
    width: 2rem;
    height: 1rem;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background: var(--vst-color-divider-thumb-bg);
    border: 1px solid var(--vst-color-divider-thumb-border);
    border-radius: 0.1875rem;
  }

  .content-layout__divider:active::after {
    background: var(--vst-color-divider-thumb-bg-active);
  }

  /* Portrait Divider Positioning */
  #panel_divider_1 {
    grid-row: 2 / 3;
  }

  #panel_divider_2 {
    grid-row: 4 / 5;
  }

  .content-layout--visible-4 .content-layout__divider {
    grid-row: auto !important;
  }

  /* Animations */
  /* TODO: fix this in a permanant way, this is a temp fix for iOS on GA 4.11 */
  /* .content-layout__panel:not(#video), */
  .content-layout__divider {
    animation-name: slideIn;
    animation-duration: var(--startup-animation-duration);
    animation-timing-function: var(--startup-animation-timing-function);
    animation-iteration-count: 1;
    animation-delay: var(--startup-animation-base-delay);
    transform: translateX(100%);
    animation-fill-mode: forwards;
  }

  .content-layout__divider {
    animation-name: fadeIn;
    animation-delay: var(--startup-animation-secondary-delay);
    opacity: 0;
    transform: none;
  }

  @media (prefers-reduced-motion), print {
    .content-layout__panel:not(#video),
    .content-layout__divider {
      animation: none;
      transform: none;
      opacity: 1;
    }
  }

  ${animationFadeIn}
  ${animationSlideIn}

  ${breakpoint(
    'landscape',
    css`
      vst-core-content-layout {
        --orientation: landscape;
        --panel1-size: 66%;
      }

      /* Landscape Grids */
      .content-layout--visible-2 {
        grid-template-rows: 100%;
        grid-template-columns: minmax(var(--min-size), var(--panel1-size)) var(--drag-handle-size) minmax(
            var(--min-size),
            1fr
          );
      }
      .content-layout--visible-3 {
        --panel2-size: calc(100% - var(--panel3-size));
        grid-template-rows: minmax(var(--min-size), var(--panel2-size)) var(--drag-handle-size) minmax(
            var(--min-size),
            var(--panel3-size)
          );
        grid-template-columns: minmax(var(--min-size), var(--panel1-size)) var(--drag-handle-size) minmax(
            var(--min-size),
            1fr
          );
      }

      .content-layout--visible-4 {
        grid-template-rows: 100%;
        grid-template-columns: minmax(var(--min-size), var(--col1-size)) var(--drag-handle-size) minmax(
            var(--min-size),
            var(--col2-size)
          );
      }

      .content-layout__subgrid-1 {
        grid-template-rows: minmax(var(--min-size), var(--panel1-size)) var(--drag-handle-size) minmax(
            var(--min-size),
            var(--panel2-size)
          );
      }

      .content-layout__subgrid-2 {
        grid-template-rows: minmax(var(--min-size), var(--panel3-size)) var(--drag-handle-size) minmax(
            var(--min-size),
            var(--panel4-size)
          );
      }

      /* Landscape Panels */
      /* What we want is the first item with a certain class to be big one. Since nth-of-type can't do a class some adjacent magic is needed
    https://stackoverflow.com/questions/6447045/css3-selector-first-of-type-with-class-name */
      .content-layout--visible-3 ::slotted(.content-layout__panel:not([hidden])) {
        grid-row: 1 / -1;
      }

      .content-layout__panel:not([hidden]) ~ .content-layout__panel:not([hidden]) {
        grid-row: auto;
      }

      /* Landscape Divider Styling */
      .content-layout__divider--divider1,
      .content-layout--visible-2 .content-layout__divider--divider2 {
        cursor: col-resize;
      }
      .content-layout__divider--divider1::after,
      .content-layout--visible-2 .content-layout__divider--divider2::after {
        width: 1rem;
        height: 2rem;
      }

      .content-layout__divider--divider1::after {
        left: 25%;
      }

      /* Landscape Divider Positioning */
      #panel_divider_1 {
        grid-row: 1 / -1;
        grid-column: 2 / 3;
      }

      .content-layout--visible-2 #panel_divider_2 {
        grid-row: 1 / -1;
        grid-column: 2 / 3;
      }

      .content-layout--visible-3 #panel_divider_2 {
        grid-row: 2 / 3;
        grid-column: 3 / 4;
      }

      .content-layout--visible-4 .content-layout__divider {
        grid-row: auto !important;
        grid-column: auto !important;
      }
    `,
  )}
`;

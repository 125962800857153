// TODO: move these "selectors" to a common component that has the appropriate scope

/**
 * Computes the table row index that corresponds to the graph_1 examine pin position for a main-content component
 * @param {*} vstAnalysisGroupStore The MobX store exposing the individual analysis stores at the global level and containing analysis links
 * @param {*} mainContentComponent One of the app-specific main-content components
 */
export const calcTableExamineIndex = (examinePositions, mainContentComponent) => {
  const { closestXPt, isGestureFinished, examineHidden } = examinePositions?.graph_1 || {};
  const { coreGraphEls: [graph] = [] } = mainContentComponent;

  const baseColumn = graph && graph.getBaseColumn();
  if (!baseColumn || examineHidden || !mainContentComponent.synchronizeExaminePinAndTable()) {
    return -1;
  }

  if (baseColumn && isGestureFinished) {
    return baseColumn.values.findIndex(value => value === closestXPt);
  }

  return mainContentComponent.tableExamineIndex; // by default, don't change highlighted row
};

import { action, observable, makeObservable } from 'mobx';

/**
 * A MobX store for app layout
 */
class VstLayoutStore {
  constructor() {
    /** @type {LayoutSettings} */
    this._defaults = {
      graph_1: true,
      graph_2: false,
      graph_3: false,
      table: false,
      meter: false,
      video: false,
      notes: false,
      configurator: false,
    };
    Object.assign(this, this._defaults);

    makeObservable(this, {
      graph_1: observable,
      graph_2: observable,
      graph_3: observable,
      table: observable,
      meter: observable,
      video: observable,
      notes: observable,
      configurator: observable,
      resetLayout: action,
      updateLayout: action,
      initLayout: action,
    });
  }

  /**
   * initialize the layout with the given defaults
   * @param {LayoutSettings} defaults the layout settings to use as defaults
   */
  initLayout(defaults) {
    Object.assign(this._defaults, defaults);
    this.resetLayout();
  }

  /**
   * @returns {number} the number of panes that are open
   */
  get openedPanes() {
    let opened = 0;
    if (['graph_1', 'graph_2', 'graph_3'].some(graph => this[graph])) opened++;
    if (this.table) opened++;
    if (this.meter) opened++;
    if (this.video) opened++;
    if (this.notes) opened++;
    if (this.configurator) opened++;
    return opened;
  }

  /**
   * reset the layout state
   */
  resetLayout() {
    Object.assign(this, this._defaults);
  }

  /**
   * Update the layout state
   *
   * @param {LayoutSettings} updates updates to the layout state
   */
  updateLayout(updates = {}) {
    Object.keys(updates).forEach(updateKey => {
      this[updateKey] = updates[updateKey];
    });
  }
}

export const vstLayoutStore = new VstLayoutStore();

/**
 * @typedef {Object} LayoutSettings
 * @property {Boolean} graph_1 the visibility state of graph_1
 * @property {Boolean} graph_2 the visibility state of graph_2
 * @property {Boolean} graph_3 the visibility state of graph_3
 * @property {Boolean} table the visibility state of the table pane
 * @property {Boolean} meter the visibility state of the meter pane
 * @property {Boolean} video the visibility state of the video pane
 * @property {Boolean} notes the visibility state of the notes pane
 * @property {Boolean} configurator the visibility state of the configurator pane
 */

import { LitElement, html } from 'lit';
import { EventBinder } from '@utils/EventBinder.js';
import { globalStyles } from '@styles/vst-style-global.css.js';
import { computeColumnSymbol } from '@utils/computeColumnSymbol.js';
import { none } from '@icons';
import '@components/vst-ui-icon/vst-ui-icon.js';
import vstCoreGraphLegendStyles from './vst-core-graph-legend.css.js';

export class VstCoreGraphLegend extends LitElement {
  static get properties() {
    return {
      leftTraces: {
        type: Array,
      },
      dataSetList: {
        type: Array,
      },
      columnGroupInfoList: {
        type: Array,
      },
    };
  }

  constructor() {
    super();

    this.leftTraces = [];
    this.dataSetList = [];
    this.columnGroupInfoList = [];

    this.eventBinder = new EventBinder();
  }

  updated(changedProperties) {
    changedProperties.forEach((oldValue, propName) => {
      switch (propName) {
        case 'leftTraces':
          this._setupLegendData();
          this.bindToNameChanges(this.leftTraces);
          break;
        default:
      }
    });
  }

  bindToNameChanges(leftTraces) {
    this.eventBinder.unbindAll();

    const dataSets = new Set(leftTraces.map(trace => trace.yColumn.dataSet));
    const columnGroups = new Set(leftTraces.map(trace => trace.yColumn.group));
    const columns = new Set(leftTraces.map(trace => trace.yColumn));

    [...dataSets, ...columnGroups].forEach(model => {
      this.eventBinder.on(model, 'name-changed', () => {
        this._setupLegendData();
      });
    });

    columns.forEach(column => {
      this.eventBinder.on(column, 'color-changed', () => {
        this._setupLegendData();
      });
      this.eventBinder.on(column, 'symbol-changed', () => {
        this._setupLegendData();
      });
    });
  }

  // TODO: experiment with using an imperitive style to make this more readable
  _setupLegendData() {
    const compareById = (a = {}, b = {}) => a.id - b.id;
    const dataSets = [...new Set(this.leftTraces.map(trace => trace.yColumn.dataSet))].sort(
      compareById,
    );
    const columnGroups = [...new Set(this.leftTraces.map(trace => trace.yColumn.group))].sort(
      compareById,
    );

    this.dataSetList = dataSets.map(set => ({ setName: set.name }));

    const tracesSortedByGroupAndSet = columnGroups.map(group => {
      return dataSets.map(set =>
        this.leftTraces.find(
          t => t.yColumn.dataSet.id === set.id && t.yColumn.group.id === group.id,
        ),
      );
    });

    this.columnGroupInfoList = tracesSortedByGroupAndSet.map((traces, i) => ({
      traces: traces.map((trace = {}) => ({
        color: trace.color,
        id: trace.id,
        symbol: trace.symbol,
      })),
      groupName: columnGroups[i].name || '',
    }));
  }

  static get styles() {
    return [globalStyles, vstCoreGraphLegendStyles];
  }

  render() {
    return html`
      <div
        class="legend"
        style="grid-template-columns: repeat(${this.dataSetList
          .length}, var(--vst-icon-size-s)) max-content;"
      >
        ${this.dataSetList.length > 1
          ? html`
              ${this.dataSetList.map(
                (setInfo, setIndex) => html`
                  ${setIndex > 0
                    ? html`
                        <div
                          class="legend__ds-hairline"
                          style="grid-column-start: ${setIndex}; grid-row-start: ${setIndex +
                          1}; grid-row-end: ${this.dataSetList.length + 1};"
                        ></div>
                      `
                    : ''}
                  <div class="legend__ds-label" style="grid-column-start: ${setIndex + 1};">
                    ${setInfo.setName}
                  </div>
                `,
              )}
            `
          : ''}
        ${this.columnGroupInfoList.map(
          groupInfo => html`
            ${groupInfo.traces.map(
              trace => html`
                <vst-ui-icon
                  .icon="${trace.id ? computeColumnSymbol(trace.symbol) : none}"
                  color="${trace.id ? trace.color : 'var(--vst-color-fg-tertiary'}"
                  class="legend__color-swatch"
                ></vst-ui-icon>
              `,
            )}
            <div class="legend__group-label">${groupInfo.groupName}</div>
          `,
        )}
      </div>
    `;
  }
}

customElements.define('vst-core-graph-legend', VstCoreGraphLegend);

import { css } from 'lit';

export const wavelengthStyles = css`
  .btn-group {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  #wavelength_instructions {
    max-inline-size: 35ch;
    padding-inline-start: var(--vst-space-xl);
  }

  input,
  select {
    max-inline-size: 6rem;
  }

  #wavelength_form {
    border: 1px solid var(--vst-color-bg-secondary);
    border-radius: var(--vst-radius);
  }

  fieldset {
    border-radius: var(--vst-radius);
    border-block-end: 1px solid var(--vst-color-bg-secondary);
    background: var(--vst-color-bg-tertiary);
  }

  spectrum-graph {
    border-radius: 0 0 var(--vst-radius) var(--vst-radius);
  }

  .stack > * {
    inline-size: 100%;
  }

  .inline {
    --justify: center;
    inline-size: 100%;
  }

  .caption {
    font-weight: 400;
  }
`;

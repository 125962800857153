import { LitElement, html } from 'lit';
import { getText } from '@utils/i18n.js';

import { globalStyles } from '@styles/vst-style-global.css.js';
import { resizePanels } from './resizePanels.js';

import { vstCoreContentLayoutStyles } from './vst-core-content-layout.css.js';

class VstCoreContentLayout extends LitElement {
  static get properties() {
    return {
      showGraphs: {
        type: Boolean,
      },
      showTable: {
        type: Boolean,
      },
      showMeter: {
        type: Boolean,
      },
      showVideo: {
        type: Boolean,
      },
      showNotes: {
        type: Boolean,
      },
      showConfigurator: {
        type: Boolean,
      },
      visiblePanels: {
        type: Number,
      },
    };
  }

  constructor() {
    super();
    this.showGraphs = false;
    this.showTable = false;
    this.showMeter = false;
    this.showVideo = false;
    this.showNotes = false;
    this.showConfigurator = false;
    this.visiblePanels = 0;
  }

  updated() {
    this._getVisiblePanels();
    this._initResizePanels();
  }

  _getVisiblePanels() {
    this.visiblePanels =
      (this.showGraphs ? 1 : 0) +
      (this.showTable ? 1 : 0) +
      (this.showMeter ? 1 : 0) +
      (this.showVideo ? 1 : 0) +
      (this.showConfigurator ? 1 : 0) +
      (this.showNotes ? 1 : 0);

    this.dispatchEvent(
      new CustomEvent('visible-panels-changed', {
        detail: {
          visiblePanels: this.visiblePanels,
        },
        bubbles: true,
        composed: true,
      }),
    );
  }

  _initResizePanels() {
    const panelDivider1El = this.shadowRoot.querySelector('#panel_divider_1');
    const panelDivider2El = this.shadowRoot.querySelector('#panel_divider_2');
    let panelDivider3El = null;

    if (this.visiblePanels === 4)
      panelDivider3El = this.shadowRoot.querySelector('#panel_divider_3');

    resizePanels.init(this, panelDivider1El, panelDivider2El, panelDivider3El);
  }

  _handleDividerKeyPress({ code, shiftKey }, axis) {
    let panelSize;
    let panelLabel;
    const minSize = parseInt(getComputedStyle(this).getPropertyValue('--min-size'));
    if (this.visiblePanels < 4) {
      panelSize = parseInt(
        getComputedStyle(this).getPropertyValue(axis === 'x' ? '--panel1-size' : '--panel3-size'),
      );
      panelLabel = axis === 'x' ? '--panel1-size' : '--panel3-size';
    } else {
      if (axis === 'x') {
        panelSize = parseInt(getComputedStyle(this).getPropertyValue('--col1-size'));
        panelLabel = '--col1-size';
      }
      if (axis === 'y1') {
        panelSize = parseInt(getComputedStyle(this).getPropertyValue('--panel1-size'));
        panelLabel = '--panel1-size';
      }
      if (axis === 'y2') {
        panelSize = parseInt(getComputedStyle(this).getPropertyValue('--panel3-size'));
        panelLabel = '--panel3-size';
      }
    }

    if (this.visiblePanels === 3 && panelLabel === '--panel3-size') {
      if (code === (axis === 'x' ? 'ArrowLeft' : 'ArrowUp'))
        panelSize = shiftKey ? panelSize + 10 : panelSize + 1;
      if (code === (axis === 'x' ? 'ArrowRight' : 'ArrowDown'))
        panelSize = shiftKey ? panelSize - 10 : panelSize - 1;
    } else {
      if (code === (axis === 'x' ? 'ArrowLeft' : 'ArrowUp'))
        panelSize = shiftKey ? panelSize - 10 : panelSize - 1;
      if (code === (axis === 'x' ? 'ArrowRight' : 'ArrowDown'))
        panelSize = shiftKey ? panelSize + 10 : panelSize + 1;
    }

    if (panelSize < minSize) panelSize = minSize;
    if (panelSize > 100 - minSize) panelSize = 100 - minSize;

    this.style.setProperty(panelLabel, `${panelSize}%`);
  }

  static get styles() {
    return [globalStyles, vstCoreContentLayoutStyles];
  }

  render() {
    return html`${this.visiblePanels === 4
      ? html`<div class="content-layout content-layout--visible-4" id="content_layout">
          <div class="content-layout__subgrid content-layout__subgrid-1">
            <slot name="priority-1"></slot>
            <button
              class="content-layout__divider"
              id="panel_divider_2"
              aria-label="${getText('Vertical Divider')}"
              @keydown="${e => this._handleDividerKeyPress(e, 'y1')}"
            ></button>
            <slot name="priority-2"></slot>
          </div>
          <button
            class="content-layout__divider content-layout__divider--divider1"
            id="panel_divider_1"
            @keydown="${e => this._handleDividerKeyPress(e, 'x')}"
            aria-label="${window.matchMedia('(orientation: landscape)').matches
              ? getText('Horizontal Divider')
              : getText('Vertical Divider')}"
          ></button>
          <div class="content-layout__subgrid content-layout__subgrid-2">
            <slot name="priority-3"></slot>
            <button
              class="content-layout__divider"
              id="panel_divider_3"
              @keydown="${e => this._handleDividerKeyPress(e, 'y2')}"
              aria-label="${getText('Vertical Divider')}"
            ></button>
            <slot name="priority-4"></slot>
          </div>
        </div> `
      : html`
          <div
            class="content-layout content-layout--visible-${this.visiblePanels}"
            id="content_layout"
          >
            <slot name="priority-1"></slot>
            <button
              class="content-layout__divider content-layout__divider--divider1"
              id="panel_divider_1"
              ?hidden="${this.visiblePanels < 2}"
              aria-label="${window.matchMedia('(orientation: landscape)').matches
                ? getText('Horizontal Divider')
                : getText('Vertical Divider')}"
              @keydown="${e => this._handleDividerKeyPress(e, 'x')}"
            ></button>
            <slot name="priority-2"></slot>
            <button
              class="content-layout__divider"
              id="panel_divider_2"
              ?hidden="${this.visiblePanels < 3}"
              @keydown="${e => this._handleDividerKeyPress(e, 'y')}"
              aria-label="${getText('Vertical Divider')}"
            ></button>
            <slot name="priority-3"></slot>
            <button class="content-layout__divider" id="panel_divider_3" hidden></button>
            <slot name="priority-4"></slot>
          </div>
        `}`;
  }
}

customElements.define('vst-core-content-layout', VstCoreContentLayout);

export const completeCellResolver = (cellResolver = {}) => {
  const defaultValueToText = value => String(value);
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line no-use-before-define
  const defaultTextToValue = text => makeParseResult('success', text);
  const defaultIsEmpty = value => value === undefined || value === null;
  const emptyString = '';

  const {
    valueToText: innerValueToText = defaultValueToText,
    textToValue: innerTextToValue = defaultTextToValue,
    isEmpty = defaultIsEmpty,
  } = cellResolver;

  /**
   * Function which is passed into the `innerTextToValue` method.
   * @param { String } status 'success', 'failure', 'alphanumeric', or 'empty' ('alphanumeric' indicates parse failed, but the value is still suitable for adding to table)
   * @param {*} value When status is 'success', contains a Number. When status is 'alphanumeric', value is a String.
   * @returns {Object} with properties you can query to figure out the result.
   */
  const makeParseResult = (status, value = undefined) => {
    console.assert(
      status === 'success' ||
        status === 'failure' ||
        status === 'alphanumeric' ||
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line eqeqeq
        status == 'empty',
    );
    return {
      value,
      parseFailed: status === 'failure' || status === 'alphanumeric',
      isEmpty: status === 'empty',
      isAlphanumeric: status === 'alphanumeric',
    };
  };
  const textToValue = text => innerTextToValue(text, makeParseResult);
  const valueToText = (value, column, isEditing) =>
    isEmpty(value) ? emptyString : innerValueToText(value, column, isEditing);

  return {
    valueToText,
    textToValue,
    isEmpty,
  };
};

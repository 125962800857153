import { priv } from '../priv.js';
import { createColumnCollection } from './createColumnCollection.js';
import { completeAssign } from '../../../_internal/completeAssign.js';
import { renderRemoveDataSet } from '../render/renderRemoveDataSet.js';

export const createDataSet = ({
  id,
  context,
  columns: configColumns = [],
  dataSetGrid,
  cellResolver,
  interactionContext,
  resolvePositions,
} = {}) => {
  const dataSet = colId => dataSet.getColumnById(colId);

  const { getColspan, getColumns, getColumnById, appendColumns, insertColumns, columns } =
    createColumnCollection({
      dataSet,
      columns: configColumns,
      cellResolver,
      interactionContext,
      resolvePositions,
    });

  let gridPosition = -1;

  const setGridPosition = pos => {
    gridPosition = pos;
  };

  let position = -1;

  const setPosition = pos => {
    position = pos;
  };

  const scrollTo = () => {
    const { columns } = dataSet[priv];
    const firstColumn = columns.findByIndex(0);

    dataSetGrid[priv].grid.scrollTo(firstColumn, null);
  };

  const setContext = newContext => {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line no-param-reassign
    context = newContext;
    dataSet[priv].renderContext(context);
  };

  const remove = () => {
    interactionContext.clear();
    renderRemoveDataSet({ dataSet });
    const { dataSets } = dataSetGrid[priv];
    dataSets.removeByKey(id);
    resolvePositions();
    gridPosition = -1;
    position = -1;
  };

  const load = () => {
    columns.forEach(col => {
      col[priv].load();
    });
  };

  return completeAssign(dataSet, {
    get id() {
      return id;
    },
    get context() {
      return context;
    },
    set context(value) {
      setContext(value);
    },
    get columns() {
      return getColumns();
    },
    get colspan() {
      return getColspan();
    },
    get gridPosition() {
      return gridPosition;
    },
    get position() {
      return position;
    },
    get grid() {
      return dataSetGrid;
    },

    getColumnById,
    appendColumns,
    insertColumns,
    remove,
    scrollTo,

    [priv]: {
      columns,
      setContext: () => {},
      setPosition,
      setGridPosition,
      load,
    },
  });
};

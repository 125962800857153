/*  eslint-disable import/named */
import { LitElement, html, css } from 'lit';
import { MobxReactionUpdate } from '@adobe/lit-mobx';
import { connect } from 'pwa-helpers';
import { autorun } from 'mobx';

import { Requester } from '@mixins/vst-core-requester-mixin.js';
import { createColorCycling } from '@utils/traceColor/traceColorCycling.js';
import { ObservableProperties } from '@mixins/vst-observable-properties-mixin.js';
import { calcTableExamineIndex } from '@utils/analysis-selectors.js';
import { globalStyles } from '@styles/vst-style-global.css.js';

import { vstCoreContentLayoutStyles } from '@components/vst-core-content-layout/vst-core-content-layout.css.js';

import '@components/vst-core-content-layout/vst-core-content-layout.js';
import '@components/vst-core-graph-group/vst-core-graph-group.js';
import '@components/vst-core-table/vst-core-table.js';

// these imports can be omitted when common is updated
import '@components/vst-core-clipboard/vst-core-clipboard.js';
import '@components/vst-core-rename/vst-core-rename.js';
import '@components/vst-core-calc-functions/vst-core-calc-functions.js';
import '@components/vst-core-message-box/vst-core-message-box.js';
// to here

import '@components/vst-ui-icon/vst-ui-icon.js';

import { vstLayoutStore } from '@stores/vst-layout.store.js';
import { VstAnalysisStore } from '@common/mobx-stores/VstAnalysisStore';
import { vstAnalysisGroupStore } from '@common/mobx-stores/vst-analysis-group.store.js';
import '@components/vst-core-meter-container/vst-core-meter-container.js';
import '../sa-graph/sa-graph.js';

import { store } from '../redux/store.js';
import { calcColumnCreated } from '../redux/actions.js';

export class SaMainContent extends connect(store)(
  Requester(MobxReactionUpdate(ObservableProperties(LitElement))),
) {
  static get properties() {
    return {
      notesText: { type: String, state: true },
      tableExamineIndex: { type: Number, state: true },
      inReplayMode: { type: Boolean, state: true },
      visiblePanels: { type: Number, state: true },
      spectrumInfo: { type: Object },
      useRightAxis: { type: Boolean },
      advancedModeEnabled: { type: Boolean },
    };
  }

  static get observableProperties() {
    return {
      examinePositions: vstAnalysisGroupStore,
    };
  }

  constructor() {
    super();
    this.inReplayMode = false;
    this.visiblePanels = 0;
    this.useRightAxis = false;
    this.advancedModeEnabled = false;

    // Set up analysis stores for each of the main graphs
    this.analysisStores = {};
    ['graph_1', 'graph_2', 'graph_3'].forEach(id => {
      this.analysisStores[id] = new VstAnalysisStore(id, vstAnalysisGroupStore);
    });
  }

  async firstUpdated() {
    this.$dataWorld = this.requestService('dataWorld');
    this.coreGraphGroupEl = this.shadowRoot.querySelector('#graph_group');
    await this.coreGraphGroupEl.updateComplete;
    this.connectedGraphEls = this.coreGraphGroupEl.connectedGraphs;
    this.coreGraphEls = [];
    this.coreTableEl = this.shadowRoot.querySelector('#table');

    // scroll to firts column on session started - this is specific to VA logic
    this.$dataWorld.on('session-started', async () => {
      const columnId = this.$dataWorld.getColumns()[0].id;
      this.coreTableEl.scrollToColumn(columnId);
    });

    this.$dataWorld.on('session-ended', () => {
      vstAnalysisGroupStore.resetLinks();
    });

    this.graphPanelSizeObserver = new ResizeObserver(entries => {
      for (const entry of entries) {
        const box = entry.contentRect;
        // set to support 2 graphs when the graph panel is 300px high for now, but I was just "eyeballing" it
        const dualSupport = box.height >= 300 || vstLayoutStore.graph_2;
        if (dualSupport !== this.graphPanelSupportsDual)
          this.dispatchEvent(
            new CustomEvent('sa-graph-size-support', {
              composed: true,
              bubbles: true,
              detail: { support: dualSupport },
            }),
          );
        this.graphPanelSupportsDual = dualSupport;
      }
    });
    this.graphPanelSizeObserver.observe(this.shadowRoot.querySelector('#graph_group'));

    // Save graph visible state here instead of in vst-core-graph. This helps persist visibility state correctly in cases where
    // an app-graph (GaGraph et al.) renders more than one core-graph (e.g. fft auxilliary graph) and programmatically toggles
    // the hidden state on the parent graph.
    autorun(() => {
      const updateUdm = (graphId, visible) => {
        const gaGraph = this.shadowRoot.getElementById(graphId);
        const udmId = gaGraph?.graph?.udmId;
        if (udmId) this.$dataWorld.changeGraphProperties(udmId, { visible });
      };

      updateUdm('graph_1', vstLayoutStore.graph_1);
      updateUdm('graph_2', vstLayoutStore.graph_2);
      updateUdm('graph_3', vstLayoutStore.graph_3);
    });
  }

  updated(changedProperties) {
    if (changedProperties.has('examinePositions')) {
      this.tableExamineIndex = calcTableExamineIndex(this.examinePositions, this);
    }
  }

  setGraphColors({ detail: graph }) {
    this.coreGraphEls.push(graph);
    this.visibleConnectedGraphs = this.connectedGraphEls.filter(g => !g.hidden);

    const traceColors = createColorCycling(this.coreGraphEls);
    this.$dataWorld.off('session-ended', traceColors.reset);
    this.$dataWorld.on('session-ended', traceColors.reset);
  }

  updateExamineOnRowClicked({ detail: { rowIndex } }) {
    const graph = this.coreGraphEls[0];
    const {
      analysisEl: { examinePin: { examinePosition: { examineHidden = true } = {} } = {} } = {},
    } = graph;
    const baseColumn = graph.getBaseColumn();

    if (!examineHidden && this.synchronizeExaminePinAndTable() && baseColumn) {
      if (rowIndex < baseColumn.values.length) {
        const rowValue = baseColumn.values[rowIndex];

        vstAnalysisGroupStore.updateExaminePosition(graph.id, {
          xPosition: rowValue,
          closestXPt: rowValue,
          isGestureFinished: undefined,
        });
      }
    }
  }

  // // this checks whether the values of all base columns match by index to determine if it's appropriate to sync examine and table row
  synchronizeExaminePinAndTable() {
    const { coreGraphEls: [graph] = [], $dataWorld } = this;
    let returnValue = true;

    const defaultBaseColumn = graph.getBaseColumn();
    const graphDataSetIds = graph.getLeftTraces().map(trace => trace.yColumn.setId);
    const allGraphBaseColumns = graphDataSetIds.map(setId =>
      $dataWorld.getColumnForSet(defaultBaseColumn, setId),
    );
    const baseColumnValues = allGraphBaseColumns.map(col => col.values);
    baseColumnValues.sort((a, b) => a.length - b.length);

    const longColumn = baseColumnValues.pop() || [];
    longColumn.forEach((val, i) => {
      const otherColumnVals = baseColumnValues.map(col => col[i]);
      otherColumnVals.forEach(otherVal => {
        returnValue = returnValue && !(val && otherVal && val !== otherVal); // return false if the longColumn value is not equal to every other (defined) column value
      });
    });

    return returnValue;
  }

  // eslint-disable-next-line no-restricted-globals
  static calcColumnCreated({ detail: calcColumnGroup } = event) {
    store.dispatch(calcColumnCreated(calcColumnGroup));
  }

  _updateVisiblePanels({ detail }) {
    this.visiblePanels = detail.visiblePanels;
  }

  static get styles() {
    return [
      globalStyles,
      vstCoreContentLayoutStyles,
      css`
        :host {
          flex: 1;
          display: flex;
          flex-direction: column;
        }

        .content-layout__panel--auto {
          grid-row: auto;
        }
      `,
    ];
  }

  render() {
    return html`
      <vst-core-content-layout
        .showGraphs="${vstLayoutStore.graph_1}"
        .showTable="${vstLayoutStore.table}"
        .showMeter="${vstLayoutStore.meter}"
        @visible-panels-changed="${this._updateVisiblePanels}"
      >
        <div
          class="content-layout__panel"
          slot="priority-1"
          ?hidden="${!vstLayoutStore.graph_1 && !vstLayoutStore.graph_2}"
        >
          <vst-core-graph-group id="graph_group" slot="graphs" class="content-layout__panel">
            <sa-graph
              slot="graph"
              class="app-graph"
              id="graph_1"
              ?hidden="${!vstLayoutStore.graph_1}"
              .analysisStore="${this.analysisStores.graph_1}"
              .spectrumInfo="${this.spectrumInfo}"
              .useRightAxis="${this.useRightAxis}"
              .advancedModeEnabled="${this.advancedModeEnabled}"
              @core-graph-ready="${this.setGraphColors}"
            >
            </sa-graph>
            <sa-graph
              slot="graph"
              class="app-graph"
              id="graph_2"
              ?hidden="${!vstLayoutStore.graph_2}"
              .analysisStore="${this.analysisStores.graph_2}"
              .spectrumInfo="${this.spectrumInfo}"
              .useRightAxis="${this.useRightAxis}"
              .advancedModeEnabled="${this.advancedModeEnabled}"
              @core-graph-ready="${this.setGraphColors}"
            >
            </sa-graph>
            <sa-graph
              slot="graph"
              class="app-graph"
              id="graph_3"
              ?hidden="${!vstLayoutStore.graph_3}"
              .analysisStore="${this.analysisStores.graph_3}"
              .spectrumInfo="${this.spectrumInfo}"
              .useRightAxis="${this.useRightAxis}"
              .advancedModeEnabled="${this.advancedModeEnabled}"
              @core-graph-ready="${this.setGraphColors}"
            >
            </sa-graph>
          </vst-core-graph-group>
        </div>

        <vst-core-table
          id="table"
          slot="priority-2"
          class="content-layout__panel content-layout__panel--auto"
          data-category="table"
          ?hidden="${!vstLayoutStore.table}"
          cellWidth="7.2"
          extractSelectedWavelength
          @row-clicked="${this.updateExamineOnRowClicked}"
          @calc-column-created="${this.calcColumnCreated}"
          .examinePinIndex="${this.tableExamineIndex}"
        >
        </vst-core-table>

        <vst-core-meter-container
          slot="priority-3"
          class="content-layout__panel"
          ?hidden="${!vstLayoutStore.meter}"
          .metersize="${vstLayoutStore.meter && !vstLayoutStore.graph_1 && !vstLayoutStore.table
            ? 'large'
            : 'medium'}"
          color="${window.matchMedia('(prefers-color-scheme: dark)').matches
            ? 'var(--vst-color-dark-100'
            : 'rgba(216, 38, 47, 1)'}"
        ></vst-core-meter-container>
      </vst-core-content-layout>
    `;
  }
}

customElements.define('sa-main-content', SaMainContent);

import { priv } from './priv.js';

export const expandColumns = (gridColumns, newLength = 0) => {
  const maxLength = gridColumns.reduce((highest, col) => {
    const colLength = col[priv].values.length;
    return colLength > highest ? colLength : highest;
  }, newLength);

  return maxLength + 1;
};

import { css } from 'lit';
import { buttonDisabled } from '../styles/index.js';

export default css`
  :host {
    --vst-grid-border-size: 1px;
    --vst-grid-header-background-color: var(--vst-color-bg-secondary);
    --vst-grid-header-font-family: var(--vst-font-family);
    --vst-grid-header-text-color: var(--vst-color-fg-primary);
    --vst-grid-body-font-family: var(--vst-font-family);
    --vst-grid-body-text-color: var(--vst-color-fg-primary);
    background: var(--vst-color-bg-secondary);
    overflow: hidden;
    position: relative;
    height: 100%;
    /*  vst-grid variables */
  }

  :host([disabled]) {
    display: none;
  }

  /*  Overrides specific to how the table sits in our app. */
  .grid_header_sidebar_block {
    border-width: 0 var(--vst-grid-border-size) var(--vst-grid-border-size)
      var(--vst-grid-border-size);
  }

  .grid_header_viewport {
    border-width: 0;
  }

  .grid-column-header.grid_header_cell_title {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    overflow: hidden;
  }

  .grid_header_cell_name {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .grid_header_cell_name::after {
    /* Add a nbsp after the name part */
    content: '\\a0';
  }

  .grid-header-units {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  /*  elements added dynamically to the table */
  .grid_header_cell {
    border-width: 0 var(--vst-grid-border-size) 0 0;
    padding: 0;
  }

  .grid_header_cell_title,
  .grid_dataset_header_cell_title {
    flex: 1;
    margin: 0 0.1875rem;
    max-block-size: 2rem;
  }

  .grid_dataset_header_cell_title {
    font-weight: bold;
  }

  ${buttonDisabled(css`.grid_header_cell_btn`)}
  .grid_header_cell_btn {
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    width: 1.75rem;
    height: 2rem;
    margin-inline-start: -0.3125rem;
    background: transparent;
    padding: 0;
    border: 0;
  }
  .grid_header_cell_btn:hover {
    opacity: 1;
  }

  .grid_header_cell_btn_icon {
    width: 1.125rem;
    height: 1.125rem;
    --vst-ui-icon-color: var(--vst-color-fg-tertiary);
  }

  vst-ui-dialog {
    --dialog-padding: var(--vst-space-l);
  }
`;

import { LitElement, html } from 'lit';
import platform from 'platform';
import { getText } from '@utils/i18n.js';
import { Requester } from '@mixins/vst-core-requester-mixin.js';
import { globalStyles } from '@styles/vst-style-global.css.js';
import { wavelengthStyles } from './sa-wavelength-chooser.css.js';

import '@components/vst-ui-spinner/vst-ui-spinner.js';

import '../sa-spectrum-graph.js';

const DEFAULT_MAX_Y_VALUE = 0.1;

class SaWavelengthChooser extends Requester(LitElement) {
  static get properties() {
    return {
      spectrumMode: {
        type: String,
      },
      isChangingSettings: {
        type: Boolean,
      },
      spectrumInfo: {
        type: Object,
      },
      _spectrumInfo: {
        state: true,
      },
      _wavelength: {
        state: true,
      },
    };
  }

  constructor() {
    super();
    this.isChangingSettings = false;
    this.spectrumMode = '';
    this._wavelength = 0;
    this.dataPairs = [];
    this.spectrumInfo = {};
    this._spectrumInfo = {};
    this._maxYValue = DEFAULT_MAX_Y_VALUE;
  }

  static get styles() {
    return [globalStyles, wavelengthStyles];
  }

  async firstUpdated() {
    const { spectrumInfo } = this;
    this.graphComponent = this.shadowRoot.querySelector('sa-spectrum-graph');
    await this.graphComponent.updateComplete;

    await this.updateComplete;
    if (this.spectrumMode === 'fluorescence') {
      if (spectrumInfo.supportedLedWavelengths?.includes(spectrumInfo.currentLedWavelength)) {
        this.shadowRoot
          .querySelector('#excitation_wavelength')
          .querySelector(`option[value="${spectrumInfo.currentLedWavelength}"]`).selected = true;
      }
    }

    setTimeout(() => {
      if (platform.os.family !== 'iOS' && platform.os.family !== 'Android') {
        this.shadowRoot.querySelector('#wavelength').focus();
      }
    }, 200);

    this._wavelength = this.spectrumInfo.selectedWavelength || this.spectrumInfo.minWavelength;
    this._maxYValue = Math.max(this.spectrumInfo.maxRange, DEFAULT_MAX_Y_VALUE);
    // FIXME (@joeybladb): Work around spectrumInfo not being updated.
    // This call will update spectrum values in the spectrum graph, including
    // the examine pin. Previously, we were relying on live values to cause the
    // pin to update, but there are cases where we don't have live updates, or
    // else the live values don't appear until after a delay. (MEG-3148)
    this._updateSpectrumInfo();
  }

  updated(changedProperties) {
    changedProperties.forEach((oldValue, propName) => {
      switch (propName) {
        case 'spectrumInfo':
          this._updateSpectrumInfo();
          break;
        default:
          break;
      }
    });
  }

  dispatchCalibrate() {
    this.dispatchEvent(new CustomEvent('show-calibrate'));
  }

  _saveWavelength() {
    const { spectrumInfo: info } = this;
    this.dispatchEvent(
      new CustomEvent('update-spec-wavelength', {
        detail: {
          ledWavelength: parseInt(info.currentLedWavelength),
          wavelength: parseInt(this._wavelength),
          referenceTrace: info.liveTrace,
          referenceRange: { min: info.minRange, max: this._maxYValue },
        },
      }),
    );
  }

  /**
   * User entered text into the field.
   * @param { Object } event
   */
  _onUserEnteredWavelength({ target: { value } }) {
    this._wavelength = Math.round(value);
  }

  /**
   * User clicked on wavelength graph.
   * @param { Object } event
   */
  _onUserPickedWavelength({ detail }) {
    this._wavelength = Math.round(detail);
  }

  /**
   * User selected from excitation wavelenght popup (fluoresence mode)
   * @param { Object } event
   */
  _ledWavelengthChanged({ target: { value } }) {
    this.dispatchEvent(new CustomEvent('led-wavelength-changed', { detail: value }));
  }

  /**
   * Called whenever spectrum data is changed (see sa-app.js). We will extract our live trace here.
   */
  _updateSpectrumInfo() {
    const { spectrumInfo: info } = this;
    const { liveTrace = null } = info;

    this._maxYValue = Math.max(this._maxYValue, info.maxRange);

    if (liveTrace) {
      liveTrace.color = info.referenceColor;
      // See if the max Y value has changed, and use it to autoscale larger the vertical range:
      if (info.scaleYLarger) {
        const newYMax = liveTrace.points.reduce(
          (last, current) => Math.max(last, current[1]),
          this._maxYValue,
        );

        if (newYMax > this._maxYValue) {
          this._maxYValue = newYMax;
        }
      }
    }
    // `_spectrumInfo` is a copy, allowing us to change properties before sending it to spectrum graph.
    this._spectrumInfo = { ...info, maxRange: this._maxYValue, liveTrace };
  }

  directionsTemplate() {
    if (this.spectrumMode === 'absorbance') {
      return html`
        <li>
          <p>${getText('Remove the blank cuvette from the spectrophotometer.')}</p>
        </li>
        <li><p>${getText('Insert a sample cuvette.')}</p></li>
        <li class="stack" gap="m">
          <p>
            ${getText('Set the desired wavelength by tapping the graph or entering the value.')}
          </p>
          <p>
            ${getText('Note: For best results, absorbance values should be between 0.1 and 1.0.')}
          </p>
        </li>
      `;
    }

    if (this.spectrumMode === 'transmittance') {
      return html`
        <li>
          <p>${getText('Remove the blank cuvette from the spectrophotometer.')}</p>
        </li>
        <li><p>${getText('Insert a sample cuvette.')}</p></li>
        <li class="stack" gap="m">
          <p>
            ${getText('Set the desired wavelength by tapping the graph or entering the value.')}
          </p>
          <p>
            ${getText(
              'Note: For best results, transmittance values should be between 10% and 95%.',
            )}
          </p>
        </li>
      `;
    }

    if (this.spectrumMode === 'fluorescence') {
      return html`
        <li>
          <p>${getText('Insert a sample cuvette. If necessary, insert an excitation LED.')}</p>
        </li>
        <li>
          <p>
            ${getText(
              'If necessary, select the appropriate excitation wavelength for your sample from the drop-down menu.',
            )}
          </p>
        </li>
        <li>
          <p>
            ${getText(
              'Set the desired emission wavelength by tapping the graph or entering the value.',
            )}
          </p>
        </li>
      `;
    }

    if (this.spectrumMode === 'intensity') {
      return html`
        <li>
          <p>${getText('Insert or connect the optical fiber cable accessory.')}</p>
        </li>
        <li>
          <p>
            ${getText(
              'Turn on your light source and aim the tip of the optical fiber at the light.',
            )}
          </p>
        </li>
        <li>
          <p>
            ${getText('Set the desired wavelength by tapping the graph or entering the value.')}
          </p>
        </li>
      `;
    }

    return '';
  }

  render() {
    return html`
      <vst-ui-form @submit="${this._saveWavelength}">
        <form class="stack" gap="m">
          <div class="sidebar">
            <div>
              <ol class="stack" gap="m" id="wavelength_instructions" fixed>
                ${this.directionsTemplate()}
              </ol>
              <div class="stack" gap="m" style="--min: 25rem" id="wavelength_form">
                <fieldset ?disabled="${this.isChangingSettings}">
                  ${this.spectrumMode === 'fluorescence'
                    ? html`
                        <label class="inline" inset="s" gap="xs">
                          <div>
                            <span class="label"> ${getText('Excitation Wavelength')}: </span>
                            <select
                              id="excitation_wavelength"
                              @change="${this._ledWavelengthChanged}"
                            >
                              ${this.spectrumInfo.supportedLedWavelengths?.map(
                                ledWavelength =>
                                  html`
                                    <option
                                      value="${ledWavelength}"
                                      selected="${ledWavelength ===
                                      this.spectrumInfo.currentLedWavelength}"
                                    >
                                      ${ledWavelength}
                                    </option>
                                    ;
                                  `,
                              )}
                            </select>
                            <span class="caption">nm</span>
                            <vst-ui-spinner ?hidden="${!this.isChangingSettings}"></vst-ui-spinner>
                          </div>
                        </label>
                      `
                    : ''}
                  <label class="inline" inset="s" gap="xs">
                    <div>
                      <span class="label">
                        ${this.spectrumMode === 'fluorescence'
                          ? getText('Emission Wavelength')
                          : getText('Selected Wavelength')}:
                      </span>
                      <input
                        id="wavelength"
                        type="number"
                        min="${this.spectrumInfo?.minWavelength}"
                        max="${this.spectrumInfo?.maxWavelength}"
                        step="any"
                        required
                        .value="${this._wavelength || this.spectrumInfo?.minWavelength}"
                        @input="${this._onUserEnteredWavelength}"
                      />
                      <span class="caption">nm</span>
                    </div>
                  </label>
                </fieldset>
                <sa-spectrum-graph
                  .wavelength="${this._wavelength}"
                  .spectrumInfo="${this._spectrumInfo}"
                  @wavelength-changed="${this._onUserPickedWavelength}"
                >
                </sa-spectrum-graph>
              </div>
            </div>
          </div>

          <div class="btn-group">
            <button
              class="btn"
              variant="text"
              id="calibrate_btn"
              @click="${this.dispatchCalibrate}"
              type="button"
            >
              ${getText('Calibrate Spectrophotometer')}
            </button>
            <button class="btn" id="submit_btn" type="submit">${getText('Done')}</button>
          </div>
        </form>
      </vst-ui-form>
    `;
  }
}

customElements.define('sa-wavelength-chooser', SaWavelengthChooser);

export const createScrollState = ({ onScrollHandler }) => {
  let suppressCount = 0;

  const suppressUiNotfication = () => {
    suppressCount++;
  };

  const onScroll = () => {
    const uiScroll = suppressCount === 0;
    onScrollHandler(uiScroll);
    if (suppressCount > 0) {
      suppressCount--;
    }
  };

  return {
    suppressUiNotfication,
    onScroll,
  };
};

import { clamp, findLastIndex, throttle, isEqual, difference, mapValues } from 'lodash-es';
import { LitElement, html, svg } from 'lit';
import { styleMap } from 'lit/directives/style-map.js';
import { ObservableProperties } from '@mixins/vst-observable-properties-mixin.js';
import { createRef, ref } from 'lit/directives/ref.js';
import { repeat } from 'lit/directives/repeat.js';

import { Requester } from '@components/mixins/vst-core-requester-mixin.js';
import * as Gestures from '@polymer/polymer/lib/utils/gestures.js';
import { sprintf } from '@libs/sprintf.js';
import { EventBinder } from '@utils/EventBinder.js';
import { changeAlpha } from '@utils/helpers.js';
import { getText } from '@utils/i18n.js';
import { vstPresentationStore } from '@stores/vst-presentation.store.js';
import { close as iconClose, grabHandle as iconGrabHandle } from '@components/vst-ui-icon/index.js';
import { globalStyles } from '@styles/vst-style-global.css.js';

import '@components/vst-ui-icon/vst-ui-icon.js';
import '@components/vst-ui-graph-annotation/vst-ui-graph-annotation.js';
import '@components/vst-ui-data-mark/vst-ui-data-mark.js';
import '@components/vst-ui-draggable/vst-ui-draggable.js';
import { autorun, action, makeObservable } from 'mobx';
import { closeCommonDialogEvent } from '@utils/closeCommonDialogEvent.js';
import { MobxReactionUpdate } from '@adobe/lit-mobx/lib/mixin.js';
import { isValidVal } from '@common/components/vst-core-graph/Trace.js';
import { AnnotationType } from '@common/mobx-stores/Annotation.js';
import { Actor } from '@common/stores/actions.js';
import isValidTraceForDatamark from './isValidTraceForDataMark.js';
import vstCoreGraphAnalysisStyles from './vst-core-graph-analysis.css.js';
import { preventFlagOverlap } from './preventFlagOverlap.js';

/**
 * @typedef {Object} ManualFitPosition
 * @prop {number} x1 First handle's base axis position
 * @prop {number} y1 First handle's left axis position
 * @prop {number} x2 Second handle's base axis position
 * @prop {number} y2 Second handle's left axis position
 * @prop {number} x3 Third handle's base axis position
 * @prop {number} y3 Third handle's left axis position
 */

// data mark position stored as percent int on backend, but percent lacks
// precision and causes lines to be off by ~px so use permille instead
const PER_CENT_CALC = 1000;
const DATA_MARK_BOX_VERTICAL_OFFSET = 30;
const DATAMARK_MARGIN = 5;

function increasePrecision(str, byCount = 1) {
  const pieces = str.split('.');
  let num = pieces[1];

  num = num.replace('f', '');
  num = parseInt(num) + byCount;
  const newStr = `${pieces[0]}.${num}f`;

  return newStr;
}

class VstCoreGraphAnalysis extends Requester(MobxReactionUpdate(ObservableProperties(LitElement))) {
  static get observableProperties() {
    return {
      colorMode: vstPresentationStore,
    };
  }

  static get properties() {
    return {
      _bracketPositions: { state: true },
      _dataMarks: { type: Array },
      _dataMarksData: { state: true },
      _infoBoxPositions: { state: true },
      _isDataMarkMoving: { type: Boolean },
      _manualFitPosition: { state: true },
      _manualFitPositionPx: { state: true },
      activeManualFit: {
        type: Object,
      },
      graphId: { type: String },
      pinching: { type: Boolean },
      selections: { type: Array },
      interpolate: { type: Boolean, reflect: true },
      tangent: { type: Boolean, reflect: true },
      examineHidden: { type: Boolean },
      examinePx: { type: Number },
      examineUnits: { type: String },
      xPoint: { type: Object },
      yPoints: { type: Array },
      examineFormatSt: { type: String },
      examinePreventClose: { type: Boolean, reflect: true },
      examineFlagsAllFit: { type: Boolean },
      examinePin: {
        hasChanged(newVal, oldVal) {
          return !isEqual(newVal, oldVal);
        },
        type: Object,
      },
      selectionsWithData: { type: Object },
      splitSelections: { type: Object },
      readOnly: { type: Boolean, reflect: true },
      dataMarks: { type: Array },
      parentGraphId: { type: Number },
      leftTraces: { type: Array },
      isCollecting: { type: Boolean },
      dataMarkBoxPositions: { type: Object },
      isReplayActive: { type: Boolean },
    };
  }

  constructor() {
    super();
    /** @type {import('@common/services/dataworld/DataWorld.js').DataWorld} */
    this.$dataWorld = null;
    this._bracketPositions = {};
    this._dataMarksData = [];
    this._infoBoxPositions = {};
    this._isDataMarkMoving = false;
    /** @type {ManualFitPosition} manual fit handle positions, but in graph units */
    this._manualFitPosition = null;
    /** @type {ManualFitPosition} manual fit handle positions */
    this._manualFitPositionPx = null;
    /** @type {ManualFitPosition} copy of _manualFitPositionPx at drag start */
    this._startingManualFitPositionPx = null;
    /** @type {Number} */
    this._startX = null;
    this.graphId = '';
    /** @return {import('@common/mobx-stores/ManualFit.js').ManualFit} The active manual fit */
    this.activeManualFit = null;
    this.pinching = false;
    this.selections = [];
    this.interpolate = false;
    this.tangent = false;
    this.examineHidden = true;
    this.examinePx = 0;
    this.examineUnits = '';
    this.xPoint = {};
    this.yPoints = [];
    this.examineFormatSt = '%.2f';
    this.examinePreventClose = false;
    this.examineFlagsAllFit = true;
    this.examinePin = {};
    this.selectionsWithData = {};
    this.splitSelections = {};
    this.readOnly = false;
    this.dataMarks = [];
    this._dataMarks = [];

    this.coreGraphEl = {};
    this.graphInstance = {}; // TODO: deprecated - remove me
    this.isCollecting = false;
    this.dataMarkBoxPositions = {};
    this.leftTraces = [];
    this._dataMarkWrapperRef = createRef();
    this.isReplayActive = false;
    makeObservable(this, {
      _completeManualFitUpdate: action,
    });
  }

  firstUpdated() {
    [this.$accessibility, this.$dataWorld, this.$dataAnalysis, this.$popoverManager] =
      this.requestServices(['accessibility', 'dataWorld', 'dataAnalysis', 'popoverManager']);

    this.graph = this.parentElement;
    this.yPoints = [];
    this.tapCount = 0;

    this.examWrapperEl = this.shadowRoot.querySelector('#graph_examine_wrapper');
    this.examEl = this.shadowRoot.querySelector('#graph_examine');
    this.analysisWrapperEl = this.shadowRoot.querySelector('#analysis_wrapper');
    this.graphExamineHandleEl = this.shadowRoot.querySelector('#graph_examine_handle');
    this.pointYValueEls = this.shadowRoot.querySelectorAll(
      '.point-overflow-window__y-value',
      '.point-highlight__y-value',
    );
    this.selectionWrapperEl = this.shadowRoot.querySelector('#selection_wrapper');
    this.annotationWrapperEl = this.shadowRoot.querySelector('#annotation_wrapper');

    this.getSelectionWrapperBoundRect = this.selectionWrapperEl.getBoundingClientRect.bind(
      this.selectionWrapperEl,
    );

    this.boundGetClosestX = this.getClosestX.bind(this);

    Gestures.addListener(this.analysisWrapperEl, 'track', this.selectionHandler.bind(this));
    Gestures.addListener(this.analysisWrapperEl, 'down', this.selectionHandler.bind(this));
    Gestures.addListener(this.analysisWrapperEl, 'up', this.examineHandler.bind(this));

    this.shadowRoot.querySelectorAll('#manual-fit-handles button').forEach(button => {
      Gestures.addListener(button, 'track', this._handleManualFitDrag.bind(this));
      Gestures.addListener(button, 'down', event => {
        event.stopPropagation();
        event.detail.sourceEvent.stopPropagation?.();
      });
      Gestures.addListener(button, 'up', event => {
        event.stopPropagation();
        event.detail.sourceEvent.stopPropagation?.();
      });
    });

    Gestures.addListener(this.examEl, 'track', this.examineHandler.bind(this));

    Gestures.addListener(this.graphExamineHandleEl, 'track', this.examineHandler.bind(this));

    this.pointYValueEls.forEach(yValueEl => {
      Gestures.addListener(yValueEl, 'track', this.examineHandler.bind(this));
    });

    const _updateExamineAndTangent = throttle(this.updateExamineAndTangent.bind(this), 300);

    this._handleGraphResize = () => {
      this._updateManualFitHandles();
      _updateExamineAndTangent();
      requestAnimationFrame(() => this._triggerAnnotationPositionUpdate());
    };

    this.graph.addEventListener('resize', this._handleGraphResize);

    this.addEventListener('touchstart', event => {
      if (event.touches.length > 1) {
        this.pinching = true;
      }
    });

    this.addEventListener('touchend', event => {
      if (this.pinching && event.touches.length === 0) {
        this.pinching = false;
      }
    });

    this.addEventListener('touchcancel', event => {
      if (this.pinching && event.touches.length === 0) {
        this.pinching = false;
      }
    });

    this.addEventListener('selection-deleted', event => {
      const selectionId = event.detail;
      const { [selectionId]: removedBracket, ...bracketPositions } = this._bracketPositions;
      this._bracketPositions = { ...bracketPositions };
    });

    this.graphInstance.addEventListener('data-values-updated', () => {
      this._triggerAnnotationPositionUpdate();
    });

    // need to set info box position back to range max on grid update or they
    // just get stuck at the same place forever on opened files
    this.graphInstance.addEventListener('graph-grid-updated', () => {
      const baseAxis = this.graphInstance.getAxis('base');

      this.selections.forEach(selection => {
        const {
          range: { max },
        } = selection;

        selection.infoBoxPosition.x = baseAxis.p2c(max);
      });

      this._triggerAnnotationPositionUpdate();
    });

    this.addEventListener('infobox-position-changed', event => {
      const { selectionId, bracketPositions, infoBoxPosition, isDragging = false } = event.detail;
      const baseAxis = this.graphInstance.getAxis('base');
      const selection = this.selections.find(s => s.id === selectionId);

      if (selection && (!this._infoBoxPositions[selectionId] || !infoBoxPosition)) {
        this._infoBoxPositions = {
          ...this._infoBoxPositions,
          [selectionId]: { x: baseAxis.p2c(selection.range.max), y: 0 },
        };
      } else if (infoBoxPosition) {
        const { x, y, isCollapsed } = infoBoxPosition;
        this._infoBoxPositions = { ...this._infoBoxPositions, [selectionId]: { x, y } };

        const selectionInfo = this.selectionsWithData[selectionId] || {};
        const udmId = selectionInfo.udmId || selectionInfo.manualFitId || 0;
        if (!isDragging && udmId) {
          this.$dataWorld.setInfoBoxInfo(udmId, {
            x: this.basePxToPercent(x),
            y: this.leftPxToPercent(y),
            isCollapsed,
          });
        }
      }
      if (bracketPositions) {
        this._bracketPositions = { ...this._bracketPositions, [selectionId]: bracketPositions };
      }
    });

    this.graphInstance.addEventListener(
      'graph-grid-updated',
      this.handleDataMarksGridUpdate.bind(this),
    );

    // This autorun monitors changes to the main data marks store (DataWorld._dataMarks)
    this._autorunCancel1 = autorun(() => {
      this.$dataWorld._dataMarks.forEach(() => {}); // noop just to make sure datamark list gets updated automatically
      this.dataMarks = this.getDataMarksForActiveTraces();
    });

    // This autorun handles changes to datamark appearance positions. E.g. so datasharing clients can follow changes on the host.
    this._autorunCancel2 = autorun(() => {
      // Grab positions of datamarks on our graph.
      const positions = [];
      this.$dataWorld._dataMarks.forEach(m => {
        m.appearanceInfo.forEach(a => {
          if (a.graphId === this.parentGraphId)
            positions.push({ id: m.id, x: a.position.x, y: a.position.y });
        });
      });

      // If positions have changed, we'll update our box position array.
      if (!isEqual(this._cachedDataMarkPositions, positions)) {
        positions.forEach(pos => {
          if (this.dataMarkBoxPositions[pos.id] && pos.x !== -1 && pos.y !== -1) {
            this.dataMarkBoxPositions[pos.id].x = this.basePercentToPx(pos.x);
            this.dataMarkBoxPositions[pos.id].y = this.leftPercentToPx(pos.y);
          }
        });
        this.requestUpdate();
        this._cachedDataMarkPositions = positions;
      }
    });

    this.eventBinder = new EventBinder();

    this.eventBinder.bindListeners({
      source: this.$dataWorld,
      target: this,
      eventMap: {
        'column-group-updated': 'updateDataMarksData',
        'column-values-changed': 'updateDataMarksData',
        'column-name-changed': 'updateDataMarksData',
      },
    });
  }

  willUpdate(changedProperties) {
    // When activeManualFit changes, set up the handles
    if (changedProperties.has('activeManualFit')) {
      this._manualFitPositionPx = null;
      this._updateManualFitHandles();
    }
  }

  updated(changedProperties) {
    changedProperties.forEach((oldValue, propName) => {
      switch (propName) {
        case 'examinePin':
          this._examinePinChanged(this.examinePin, oldValue);
          break;
        case 'selectionsWithData':
          this._selectionsWithDataChanged(this.selectionsWithData, oldValue);
          break;
        case 'leftTraces':
          this.dataMarks = this.getDataMarksForActiveTraces();
          this.resetInactiveDataMarkLocations();
          this.updateDataMarksData();
          if (!this.readOnly) {
            this.updateDataMarkBoxPositions();
          }
          break;
        case 'dataMarks':
          this.updateDataMarksData();
          this.removeOldDataMarkLines();
          this.requestUpdate();
          break;
        default:
      }
    });
  }

  disconnctedCallback() {
    super.disconnctedCallback();
    this.graph.removeEventListener('resize', this._handleGraphResize);
    this.eventBinder.unbindAll();
    // I'm not sure if core analysis gets recycled or not, but it's good practice to cancel autoruns at end of life.
    if (this._autorunCancel1) this._autorunCancel1();
    if (this._autorunCancel2) this._autorunCancel2();
  }

  /**
   * Checks whether the given annotation's range is on the graph
   * @param {Annotation} annotation Annotation
   * @param {import('@common/components/vst-core-graph/Trace.js')} trace Trace
   * @returns {Boolean}
   */
  _checkThatAnnotationRangeIsOnGraph(annotation, trace) {
    const baseColumnId = trace.baseColumn.id;
    const baseRange = this.graphInstance.getBaseRange();
    const leftRange = this.graphInstance.getLeftRange();

    let minIndex = 0;
    let maxIndex = 0;

    switch (annotation.type) {
      case AnnotationType.FREE:
        return true;

      case AnnotationType.POINT: {
        const pointIndex = annotation.getPointIndexForColumn(baseColumnId);
        minIndex = pointIndex;
        maxIndex = pointIndex;
        break;
      }

      case AnnotationType.RANGE: {
        const { startIndex, endIndex } = annotation.getRangeIndexesForColumn(baseColumnId);
        minIndex = startIndex;
        maxIndex = endIndex;
        break;
      }

      default:
        return false;
    }

    const yValuesInRange = trace.yColumn.values
      .slice(minIndex, maxIndex + 1)
      // Some values can be undefined or NaN, exclude them
      .filter(isValidVal);
    const minYValue = Math.min(...yValuesInRange);
    const maxYValue = Math.max(...yValuesInRange);

    const xValuesInRange = trace.baseColumn.values
      .slice(minIndex, maxIndex + 1)
      // Some values can be undefined or NaN, exclude them
      .filter(isValidVal);
    const minXValue = Math.min(...xValuesInRange);
    const maxXValue = Math.max(...xValuesInRange);

    const struckRowsInRange = trace.baseColumn.struckRows.filter(
      struckRowIndex => struckRowIndex >= minIndex && struckRowIndex <= maxIndex,
    );

    return (
      minYValue <= leftRange.max &&
      maxYValue >= leftRange.min &&
      minXValue <= baseRange.max &&
      maxXValue >= baseRange.min &&
      struckRowsInRange.length < xValuesInRange.length
    );
  }

  _completeManualFitUpdate() {
    // Cache the position in plot units, e.g. for graph resize
    this._manualFitPosition = this._manualFitPlotPosition;
    // Update the ManualFit
    this.activeManualFit.coefficients = this._manualFitCoefficients;
    // Emit an event with the new coefficients
    this.dispatchEvent(
      new CustomEvent('manual-fit-position-changed', {
        bubbles: true,
        composed: true,
        detail: this._manualFitCoefficients,
      }),
    );
  }

  /**
   * Create SVG lines for the given annotation
   * @param {Annotation} annotation Annotation
   * @returns {import('lit').TemplateResult} SVG fragment
   */
  _createHairlineSvg(annotation) {
    return this._getTargetsForAnnotation(annotation).map(
      target => svg`
        <line
          class="annotation-hairline"
          data-annotation-id=${annotation.id}
          data-target-column-id=${target.columnId}
        />
      `,
    );
  }

  _handleManualFitDrag(event) {
    event.stopPropagation();
    if (!('touches' in event.detail.sourceEvent)) event.detail.sourceEvent.stopPropagation?.();
    const { state } = event.detail;
    if (event.type !== 'track') return;
    if (state === 'start') {
      this._startingManualFitPositionPx = { ...this._manualFitPositionPx };
      return;
    }
    if (state === 'end') {
      this._startingManualFitPositionPx = null;
      return;
    }

    const target = event.composedPath().at(0);
    const { handle } = target.dataset;
    const { x, y } = event.detail;
    const clientRect = this.getBoundingClientRect();

    this._translateManualFitHandles(handle, x, y, clientRect);
    this._completeManualFitUpdate();
  }

  _handleManualFitKeydown(event) {
    const { code, shiftKey } = event;
    if (!['ArrowUp', 'ArrowRight', 'ArrowDown', 'ArrowLeft'].includes(code)) return;

    const target = event.composedPath().at(0);
    const clientRect = this.getBoundingClientRect();
    const { handle } = target.dataset;
    this._startingManualFitPositionPx = { ...this._manualFitPositionPx };

    let x = this._manualFitPositionPx[`x${handle}`] + clientRect.left;
    let y = this._manualFitPositionPx[`y${handle}`] + clientRect.top;
    const increment = shiftKey ? 50 : 10;

    switch (code) {
      case 'ArrowUp':
        y -= increment;
        break;
      case 'ArrowRight':
        x += increment;
        break;
      case 'ArrowDown':
        y += increment;
        break;
      case 'ArrowLeft':
        x -= increment;
        break;
      default:
        break;
    }

    this._translateManualFitHandles(handle, x, y, clientRect);
    this._completeManualFitUpdate();
  }

  /**
   * Get targets for the given annotation
   * @param {Annotation} annotation Annotation
   * @return {import('../../mobx-stores/Annotation.js').TargetRecord} Target records for the plotted traces
   */
  _getTargetsForAnnotation(annotation) {
    const plottedColumnIds = this.coreGraphEl.getAnalysisTraces().map(trace => trace.yColumn.id);
    return annotation.targetRecords.filter(target => plottedColumnIds.includes(target.columnId));
  }

  /**
   * Translate manual fit handles and apply constraints
   * @param {string} handleName
   * @param {number} x
   * @param {number} y
   */
  _translateManualFitHandles(handleName, x, y, clientRect) {
    const { _startingManualFitPositionPx: start } = this;
    const newPosition = { ...this._manualFitPositionPx };
    function updateHandleProps(positionUpdate, handle, xVal, yVal) {
      if (xVal || xVal === 0) positionUpdate[`x${handle}`] = xVal;
      if (yVal || yVal === 0) positionUpdate[`y${handle}`] = yVal;
    }

    switch (handleName) {
      case '1': {
        updateHandleProps(
          newPosition,
          handleName,
          // The upper bound with regard to x2 keeps the handle from crossing
          // over or getting stuck behind the middle handle
          clamp(x - clientRect.left, 0, newPosition.x2 - 5),
          clamp(y - clientRect.top, 0, clientRect.height),
        );
        const { x1, y1, x2, x3, y3 } = newPosition;
        // NB: CSS coordinate system.
        const slope = (y3 - y1) / (x3 - x1);
        // Update the middle handle's y based on the slope between this handle
        // and the third handle.
        // Since this is a pivot, that changes the "box" to be relative to (x3, y3)
        updateHandleProps(newPosition, '2', null, slope * (x2 - x1) + y1);
        break;
      }
      case '2': {
        const x2x1Delta = start.x1 - start.x2;
        const y2y1Delta = start.y1 - start.y2;
        const x2x3Delta = start.x3 - start.x2;
        const y2y3Delta = start.y3 - start.y2;

        const x1 = clamp(x + x2x1Delta - clientRect.left, 0, clientRect.width);
        const y1 = clamp(y + y2y1Delta - clientRect.top, 0, clientRect.height);

        const x2 = clamp(x - clientRect.left, 0, clientRect.width);
        const y2 = clamp(y - clientRect.top, 0, clientRect.height);

        const x3 = clamp(x + x2x3Delta - clientRect.left, 0, clientRect.width);
        const y3 = clamp(y + y2y3Delta - clientRect.top, 0, clientRect.height);

        const inXBounds = x1 > 0 && x1 < clientRect.width && x3 > 0 && x3 < clientRect.width;
        const inYBounds = y1 > 0 && y1 < clientRect.height && y3 > 0 && y3 < clientRect.height;
        // Update the middle handle, but constrain by the min and max of the
        // left and right handles' clamp range
        updateHandleProps(newPosition, handleName, inXBounds ? x2 : null, inYBounds ? y2 : null);
        // Update the left and right handles based on the middle handle
        updateHandleProps(newPosition, '1', inXBounds ? x1 : null, inYBounds ? y1 : null);
        updateHandleProps(newPosition, '3', inXBounds ? x3 : null, inYBounds ? y3 : null);
        break;
      }
      case '3': {
        updateHandleProps(
          newPosition,
          handleName,
          // The lower bound with regard to x2 keeps the handle from crossing
          // over or getting stuck behind the middle handle
          clamp(x - clientRect.left, newPosition.x2 + 5, clientRect.width),
          clamp(y - clientRect.top, 0, clientRect.height),
        );
        const { x1, y1, x2, x3, y3 } = newPosition;
        // NB: CSS coordinate system.
        const slope = (y3 - y1) / (x3 - x1);
        // Update the middle handle's y based on the slope between this handle
        // and the third handle.
        // Since this is a pivot, that changes the "box" to be relative to (x3, y3)
        updateHandleProps(newPosition, '2', null, slope * (x2 - x1) + y1);
        break;
      }
      default:
        break;
    }

    this._manualFitPositionPx = newPosition;
  }

  _triggerAnnotationPositionUpdate() {
    const annotationEls = this.shadowRoot.querySelectorAll('vst-ui-graph-annotation');
    annotationEls.forEach(el => {
      el.resize();
    });
  }

  /**
   * Update manual fit handle positions based on their current graph position,
   * e.g. on add or graph resize
   */
  _updateManualFitHandles() {
    if (!this.activeManualFit) return;

    const hasPosition = this._manualFitPositionPx !== null;

    const baseAxis = this.graphInstance.getAxis('base');
    const leftAxis = this.graphInstance.getAxis('left');
    const [slope, intercept] = this.activeManualFit.coefficients;
    const { min: baseMin, max: baseMax } = baseAxis.range;
    const run = baseMax - baseMin;
    const fx = x => slope * x + intercept;

    this._manualFitPositionPx = {
      x1: baseAxis.p2c(hasPosition ? this._manualFitPosition.x1 : baseMin + run * 0.2),
      y1: leftAxis.p2c(hasPosition ? this._manualFitPosition.y1 : fx(baseMin + run * 0.2)),
      x2: baseAxis.p2c(hasPosition ? this._manualFitPosition.x2 : baseMin + run * 0.5),
      y2: leftAxis.p2c(hasPosition ? this._manualFitPosition.y2 : fx(baseMin + run * 0.5)),
      x3: baseAxis.p2c(hasPosition ? this._manualFitPosition.x3 : baseMin + run * 0.8),
      y3: leftAxis.p2c(hasPosition ? this._manualFitPosition.y3 : fx(baseMin + run * 0.8)),
    };
    this._manualFitPosition = this._manualFitPlotPosition;
  }

  async _examinePinChanged(newVal = {}, oldVal = {}) {
    const { examineSettings } = newVal;
    if (!examineSettings) return;

    const [newHidden, oldHidden] = [newVal, oldVal].map(
      ({ examinePosition = {} }) => examinePosition.examineHidden,
    );
    const isHiddenChanged = newHidden !== oldHidden;

    this.tangent = examineSettings.tangentEnabled;
    this.interpolate = examineSettings.interpolate;

    this.examineHidden = newHidden;

    if (isHiddenChanged) {
      await this.updateComplete; // allow pin to render so that pin and flags position correctly
    }

    this.updateExamineAndTangent();
  }

  async _selectionsWithDataChanged(selectionsWithData = {}) {
    await import('@components/vst-core-graph-selection/vst-core-graph-selection.js');

    const existingIds = this.selections.map(selection => selection.id);
    const changedIds = Object.keys(selectionsWithData);
    const toAddIds = difference(changedIds, existingIds);

    toAddIds.forEach(id => {
      const selection = this.createSelection({
        ...selectionsWithData[id],
        id,
      });
      this.selections.push(selection);
    });

    const toRemoveIds = difference(existingIds, changedIds);
    toRemoveIds.forEach(id => {
      this.getSelectionElementById(id).remove();
      const { [id]: removedBracket, ...bracketPositions } = this._bracketPositions;
      this._bracketPositions = { ...bracketPositions };

      const index = this.selections.findIndex(sel => sel.id === id);
      if (index > -1) {
        this.selections.splice(index, 1);
      }
    });

    const { rightAxisEnabled } = this.coreGraphEl;

    changedIds.forEach(id => {
      const selection = selectionsWithData[id];
      const selectionEl = this.getSelectionElementById(id);

      selectionEl.highlightOnly = selection.highlightOnly;
      selectionEl.udmId = selection.udmId;
      selectionEl.permanent = selection.permanent;
      selectionEl.enabledAnalysisType = selection.analysisType;
      selectionEl.infoBoxData = selection.infoBoxData;
      selectionEl.traces = this.coreGraphEl?.traces.filter(
        trace => trace.axis === 'left' || rightAxisEnabled,
      );

      if (selectionEl && !isEqual(selectionEl.range, selection.range)) {
        // TODO: move this check to selection component
        selectionEl.range = selection.range;
      }
      this.bindInfoBox(selectionEl, selection.analysisType);
    });
  }

  updateDataMarksData() {
    if (this.isReplayActive) return;
    this.dataMarks.forEach(dataMark => {
      this._dataMarksData[dataMark.id] = this.getDataMarkData(dataMark);
    });
    this.requestUpdate();
  }

  async handleDataMarksGridUpdate() {
    // grid updates will remove lines when changing size of export image
    if (this.readOnly) return;

    this.dataMarks.forEach(dataMark => {
      const { width } = this.dataMarkBoxPositions[dataMark.id];
      const x = this.dataMarkToBasePxLocation(dataMark);
      const y = this.dataMarkToLeftPxLocation(dataMark);

      // check if data mark is still on graph
      const nudge = x - width;
      const areDataMarkPointsOnGraph = x > 0;

      const { x: savedX } = dataMark.getPositionOnGraph(this.parentGraphId);

      if (!areDataMarkPointsOnGraph) {
        // reposition and let all of graph if points are off graph
        dataMark.setPositionOnGraph(this.parentGraphId, { x: -1, y: -1 });
      } else if (areDataMarkPointsOnGraph && nudge < 0 && savedX === -1) {
        // nudge it box is falling off graph but points are still on graph
        dataMark.setPositionOnGraph(this.parentGraphId, {
          x: this.basePxToPercent(x - nudge + DATAMARK_MARGIN),
          y: this.leftPxToPercent(y - DATA_MARK_BOX_VERTICAL_OFFSET),
        });
      } else if (this.isReplayActive) {
        // replay needs the last good saved position of the data mark
        dataMark.setPositionOnGraph(this.parentGraphId, {
          x: this.basePxToPercent(x),
          y: this.leftPxToPercent(y - DATA_MARK_BOX_VERTICAL_OFFSET),
        });
      }
      dataMark.setHasMoved(this.parentGraphId, false);
    });
    this.updateDataMarkBoxPositions();

    // When resetting position, need to start over from scratch with a clean data
    // mark element so transform and transition back to absolute from fixed position
    // applied in the right order and data mark lands in correct spot
    this.dataMarks = [];
    await this.updateComplete;
    this.dataMarks = this.getDataMarksForActiveTraces();
  }

  resetInactiveDataMarkLocations() {
    const traceSetIds = this.leftTraces.map(trace => trace.yColumn.setId);

    const dataMarks = this.$dataWorld
      .getDataMarksForGraph(this.parentGraphId)
      .filter(dataMark => !traceSetIds.includes(dataMark.setId));

    dataMarks.forEach(dataMark => {
      dataMark.setPositionOnGraph(this.parentGraphId, { x: -1, y: -1 });
      dataMark.setHasMoved(this.parentGraphId, false);
    });
  }

  handleDataMarkMoving(e) {
    const { state } = e.detail;
    if (state === 'start') {
      this._isDataMarkMoving = true;
    }
    if (state === 'end') {
      this._isDataMarkMoving = false;
    }
  }

  handleDataMarkTextUpdate(e) {
    const { dataMarkId, value } = e.detail;
    const dataMark = this.$dataWorld
      .getDataMarksForGraph(this.parentGraphId)
      .find(dataMark => dataMark.id === dataMarkId);
    if (!dataMark) return;
    dataMark.setText(value);
  }

  handleDataMarkEditingUpdate(e) {
    const { dataMarkId, editing } = e.detail;
    const dataMark = this.$dataWorld
      .getDataMarksForGraph(this.parentGraphId)
      .find(dataMark => dataMark.id === dataMarkId);
    if (dataMark) dataMark.setEditingOnGraph(editing ? this.parentGraphId : 0);
  }

  handleDeleteDataMark(e) {
    const { id } = e.detail;
    this.dispatchEvent(
      new CustomEvent('open-dialog', {
        bubbles: true,
        composed: true,
        detail: {
          dialog: 'message_box',
          params: {
            title: getText('Delete Data Mark'),
            content: getText('Do you want to permanently delete this data mark?'),
            actions: [
              {
                id: 'cancel',
                message: getText('Cancel'),
                variant: 'text',
                onClick: async () => {
                  this.dispatchEvent(closeCommonDialogEvent('message_box'));
                },
              },
              {
                id: 'delete',
                message: getText('Delete'),
                variant: 'danger',
                onClick: async () => {
                  this.dispatchEvent(closeCommonDialogEvent('message_box'));
                  const dataMark = this.$dataWorld
                    .getDataMarksForGraph(this.parentGraphId)
                    .find(dataMark => dataMark.id === id);
                  this.$dataWorld.removeDataMark(dataMark);
                },
              },
            ],
          },
          onClose: () => {},
        },
      }),
    );
  }

  /**
   * Attempts to find a suitable base column for the given datamark.
   * @param {DataMark} dataMark
   * @returns {Column?} base column for the data mark or null if one can't be found.
   */
  _findDataMarkBaseColumn(dataMark) {
    let col = this.$dataWorld
      .getColumnsForSet(dataMark.setId)
      .filter(column => column.prefersBase)
      .find(col => col.type === 'time');

    // This could be a datashare session, in which case we have NO idea about what the column's role etc. is.
    if (!col)
      col = this.$dataWorld
        .getColumnsForSet(dataMark.setId)
        .find(col => col.name === getText('Time'));
    if (!col) console.warn(`Could not find base column for dataMark ${dataMark.id}`);

    return col;
  }

  getDataMarkData(dataMark) {
    const xColumn = this._findDataMarkBaseColumn(dataMark);

    const data = [
      {
        key: xColumn.name,
        value: xColumn.getFormattedValue(this.getDataMarkCoordinate(dataMark, true)),
        units: xColumn.units,
      },
    ];

    this.leftTraces
      .filter(trace => dataMark.setId === trace.yColumn.setId)
      .forEach(trace => {
        const { yColumn } = trace;
        const yData = {
          key: yColumn.name,
          value: yColumn.getFormattedValue(this.getDataMarkCoordinate(dataMark, false, yColumn.id)),
          units: yColumn.units,
        };
        data.push(yData);
      });
    return data;
  }

  getDataMarkCoordinate(dataMark, isBase, columnId) {
    if (isBase) {
      const baseColumn = this._findDataMarkBaseColumn(dataMark);
      return baseColumn.values[dataMark.rowIndex];
    }
    return this.$dataWorld.getColumnById(columnId).values[dataMark.rowIndex];
  }

  /**
   * calculates base px location at which to place data mark
   * @param {DataMark} dataMark
   * @returns {number} top px offset of data mark
   */
  dataMarkToBasePxLocation(dataMark) {
    const column = this._findDataMarkBaseColumn(dataMark);
    // hack: rerenders datamark from previous set for split second on collect
    // when base column is undefined, or when column is deleted
    if (!column) return 0;
    return this.graphInstance.getAxis('base').p2c(column.values[dataMark.rowIndex]);
  }

  /**
   * calculates left axis px location at which to place data mark
   * @param {DataMark} dataMark
   * @param {string} leftColumnId left column ID
   * @returns {number} left px offset of data mark
   */
  dataMarkToLeftPxLocation(dataMark, leftColumnId) {
    const validTrace = this.leftTraces.find(trace => isValidTraceForDatamark(trace, dataMark));
    const validLeftColumnId = validTrace ? validTrace.yColumn.id : null;
    const _leftColumnId = leftColumnId || validLeftColumnId;

    const column = this.$dataWorld.getColumnById(_leftColumnId);
    // hack: rerenders datamark from previous set for split second on collect
    // when base column is undefined, or when column is deleted
    if (!column) return 0;
    return this.graphInstance.getAxis('left').p2c(column.values[dataMark.rowIndex]);
  }

  findActiveColumnIdForDataMark(dataMark) {
    const activeTrace = this.leftTraces.find(trace => dataMark.setId === trace.yColumn.setId);
    return activeTrace ? activeTrace.yColumn.id : null;
  }

  /**
   * Converts base axis px offset from analysis wrapper to percent
   * @param {Number} pxOffset
   * @returns base axis percent offset
   */
  basePxToPercent(pxOffset) {
    return Math.round((pxOffset / this.getBoundingClientRect().width) * PER_CENT_CALC);
  }

  /**
   * Converts left axix px offset from analysis wrapper to percent
   * @param {Number} pxOffset
   * @returns left axis percent offset
   */
  leftPxToPercent(pxOffset) {
    return Math.round((pxOffset / this.getBoundingClientRect().height) * PER_CENT_CALC);
  }

  /**
   * Converts base axis percent offset from analysis wrapper to px
   * @param {Number} percentOffset
   * @returns base axis px offset
   */
  basePercentToPx(percentOffset) {
    return (this.getBoundingClientRect().width * percentOffset) / PER_CENT_CALC;
  }

  /**
   * Converts left axis percent offset from analysis wrapper to px
   * @param {Number} percentOffset
   * @returns left axis px offset
   */
  leftPercentToPx(percentOffset) {
    return (this.getBoundingClientRect().height * percentOffset) / PER_CENT_CALC;
  }

  /**
   * Determines the left px offset where the data mark element should be located within
   * the graph area at the time of render.  Calculates the first time and then sets
   * the position on the data mark for next time
   * @param {DataMark} dataMark
   * @returns {Number} px location of data mark element should be located
   */
  getBaseDataMarkElLocation(dataMark) {
    // FIXME: (@ejdeposit) remove special case handling for export and just use position
    // set within data mark intead. still needed for data marks on left edge that
    // have been nudged
    if (this.readOnly) {
      if (!this.dataMarkBoxPositions[dataMark.id]) return this.dataMarkToBasePxLocation(dataMark);
      return this.dataMarkBoxPositions[dataMark.id].x;
    }

    // use saved location from data mark if possible
    const { x: dataMarkPercentXOffset } = dataMark.getPositionOnGraph(this.parentGraphId);

    const dataMarkXPxOffset = this.basePercentToPx(dataMarkPercentXOffset);

    // check if saved location exists
    if (dataMarkPercentXOffset !== -1) {
      return dataMarkXPxOffset;
    }

    const x = this.dataMarkToBasePxLocation(dataMark);

    return x;
  }

  /**
   * Determines the top px offset where the data mark element should be located within
   * the graph area at the time of render.  Calculates the first time and then sets
   * the position on the data mark for next time
   * @param {DataMark} dataMark
   * @returns {Number} px location of data mark element should be located
   */
  getLeftDataMarkElLocation(dataMark) {
    // FIXME: (@ejdeposit) remove special case handling for export and just use position
    // set within data mark intead. still needed for data marks on left edge that
    // have been nudged
    if (this.readOnly) {
      if (!this.dataMarkBoxPositions[dataMark.id]) return this.dataMarkToLeftPxLocation(dataMark);
      return this.dataMarkBoxPositions[dataMark.id].y;
    }

    // use saved location from data mark if possible
    const { y: dataMarkPercentYOffset } = dataMark.getPositionOnGraph(this.parentGraphId);

    const dataMarkYPxOffset = this.leftPercentToPx(dataMarkPercentYOffset);

    // check if saved location exists
    if (dataMarkPercentYOffset !== -1) {
      return dataMarkYPxOffset;
    }
    const y = this.dataMarkToLeftPxLocation(dataMark) - DATA_MARK_BOX_VERTICAL_OFFSET;

    return y;
  }

  updateDataMarkElPosition(e) {
    const {
      isInitialPlacement,
      draggableId,
      draggableRect,
      absolutePosition: {
        centerRight: { x, y },
        topLeft: { x: draggableLeftEdge },
      },
    } = e.detail;
    const { width } = draggableRect;

    // Check to see if draggable's call to boudningClientRect just returns all
    // zeros, otherwise appears as if data mark needs nudge onto graph, which
    // messes up the saved position
    const draggableRectValues = Object.values(JSON.parse(JSON.stringify(draggableRect)));
    if (draggableRectValues.every(value => value === 0)) {
      return;
    }

    const dataMarkIsDoneMoving = !isInitialPlacement && !this._isDataMarkMoving;
    const dataMark = this.dataMarks.find(dataMark => dataMark.id === draggableId);
    const isDataMarkPointOnGraph = this.dataMarkToBasePxLocation(dataMark) > 0;

    const dataMarkNeedsNudgeOntoGraph =
      isInitialPlacement && draggableLeftEdge < 0 && isDataMarkPointOnGraph;

    const nudge = dataMarkNeedsNudgeOntoGraph ? DATAMARK_MARGIN - draggableLeftEdge : 0;

    if (!isInitialPlacement) {
      dataMark.setHasMoved(this.parentGraphId, true);
    }

    this.dataMarkBoxPositions[draggableId] = {
      x: x + nudge,
      y,
      isInitialPlacement,
      width,
    };

    // save data mark location if it is moved by user or if it needs to be nudged
    // setting location causes craziness on drag, so only do it when not moving
    if ((dataMarkNeedsNudgeOntoGraph || dataMarkIsDoneMoving) && !this.readOnly) {
      dataMark.setPositionOnGraph(this.parentGraphId, {
        x: this.basePxToPercent(x + nudge),
        y: this.leftPxToPercent(y),
      });
    }

    this.requestUpdate();
  }

  getDataMarksForActiveTraces() {
    const traceSetIds = this.leftTraces.map(trace => trace.yColumn.setId);

    const dataMarks = this.$dataWorld
      .getDataMarksForGraph(this.parentGraphId)
      .filter(
        dataMark =>
          traceSetIds.includes(dataMark.setId) &&
          (this.leftTraces.some(trace => isValidTraceForDatamark(trace, dataMark)) ||
            this.isReplayActive),
      );

    return dataMarks;
  }

  removeOldDataMarkLines() {
    const activeDataMarkIds = this.dataMarks.map(dataMark => dataMark.id.toString());
    const oldDataMarkIds = Object.keys(this.dataMarkBoxPositions);
    oldDataMarkIds.forEach(dataMarkId => {
      if (!activeDataMarkIds.includes(dataMarkId)) {
        delete this.dataMarkBoxPositions[dataMarkId];
      }
    });
  }

  async addDataMark() {
    const baseColumnGroup = this.$dataWorld.getColumnById(this.graph.baseColumnId).group;
    const [leftColumnId] = this.coreGraphEl.leftColumnIds;

    if (baseColumnGroup && leftColumnId) {
      const rowIndex = this.$dataWorld.getColumnById(leftColumnId).values.length - 1;
      const graphUdmIds = this.$dataWorld.getGraphUdmIds();
      // This should automagically update our own `dataMarks` property
      this.$dataWorld.addDataMark(rowIndex, this.$dataWorld.currentDataSet.id, graphUdmIds);
    }
  }

  updateDataMarkBoxPositions() {
    this.dataMarks.forEach(dataMark => {
      const x = this.dataMarkToBasePxLocation(dataMark);
      const y = this.dataMarkToLeftPxLocation(dataMark) - DATA_MARK_BOX_VERTICAL_OFFSET;

      const dataMarkBoxPosition = this.dataMarkBoxPositions[dataMark.id];

      const isInitialPlacement = dataMarkBoxPosition
        ? dataMarkBoxPosition.isInitialPlacement
        : true;

      const width = dataMarkBoxPosition ? dataMarkBoxPosition.width : 0;

      const { x: savedXOffset, y: savedYOffset } = dataMark.getPositionOnGraph(this.parentGraphId);

      if (savedXOffset === -1 || savedYOffset === -1) {
        this.dataMarkBoxPositions[dataMark.id] = { x, y, isInitialPlacement, width };
      }
    });
  }

  /**
   * Update hairlines for the target annotation
   *
   * Performs an out-of-band DOM update to the hairline attributes.
   *
   * Hairlines are derived state, but the positions aren't known when the
   * template is evaluated. Tracking hairlines as state was complex and had lots
   * of issues staying in sync with annotations, so they are derived from
   * visibleAnnotations.
   *
   * Which hairlines should be drawn is known at render, but the position has to
   * be calculated in response to this "position-updated" event, i.e. when the
   * vst-ui-graph-annotation is drawn.
   *
   * @param {Event} event Event
   */
  _updateAnnotationHairline(event) {
    const { annotation } = event.detail;
    const annotationBounds = event.target.getBoundingClientRect();
    const containerBounds = this.annotationWrapperEl.getBoundingClientRect();

    if (annotation.type === AnnotationType.FREE) return;

    this._getTargetsForAnnotation(annotation).forEach(target => {
      const trace = this.coreGraphEl
        .getAnalysisTraces()
        .find(trace => trace.yColumn.id === target.columnId);
      const baseAxis = this.graphInstance.getAxis('base');
      const yAxis = this.graphInstance.getAxis(trace.axis);
      let xDataPointPx;
      let yDataPointPx;

      if (annotation.type === AnnotationType.POINT) {
        const pointIndex = annotation.getPointIndexForColumn(trace.baseColumn.id);
        const seriesAlignedIndex = trace.getIndexOffsetFromSeries(pointIndex);
        const [xPoint, yPoint] = trace.seriesData[seriesAlignedIndex];
        xDataPointPx = baseAxis.p2c(xPoint);
        yDataPointPx = yAxis.p2c(yPoint);
      } else if (annotation.type === AnnotationType.RANGE) {
        const { startIndex, endIndex } = annotation.getRangeIndexesForColumn(trace.baseColumn.id);
        const seriesAlignedStartIndex = trace.getIndexOffsetFromSeries(startIndex);
        const seriesAlignedEndIndex = trace.getIndexOffsetFromSeries(endIndex);
        const seriesSegment = trace.seriesData.slice(
          seriesAlignedStartIndex,
          seriesAlignedEndIndex + 1,
        );
        const calculateMidpoint = !(seriesSegment.length % 2);
        const middleSegmentStartIndex = Math.floor((seriesSegment.length - 1) / 2);
        const [x1, y1] = seriesSegment.at(middleSegmentStartIndex);
        const middleSegmentEndIndex = Math.floor(seriesSegment.length / 2);
        const [x2, y2] = seriesSegment.at(middleSegmentEndIndex);
        const [middleX, middleY] = calculateMidpoint ? [(x1 + x2) / 2, (y1 + y2) / 2] : [x2, y2];
        xDataPointPx = baseAxis.p2c(middleX);
        yDataPointPx = yAxis.p2c(middleY);
      }

      const xAnnotationPx =
        annotationBounds.left - containerBounds.left + annotationBounds.width / 2;
      const yAnnotationPx =
        annotationBounds.top - containerBounds.top + annotationBounds.height / 2;

      const hairlineEl = this.shadowRoot.querySelector(
        `line[data-annotation-id="${annotation.id}"][data-target-column-id="${target.columnId}"]`,
      );

      if (hairlineEl) {
        hairlineEl.setAttribute('x1', xDataPointPx);
        hairlineEl.setAttribute('y1', yDataPointPx);
        hairlineEl.setAttribute('x2', xAnnotationPx);
        hairlineEl.setAttribute('y2', yAnnotationPx);
      }
    });
  }

  tapHandler(e) {
    if (e.key === 'Enter') {
      this.fireExaminePositionUpdate({ examineHidden: false });
      setTimeout(() => this.graphExamineHandleEl.focus(), 0);
    }

    if (this.pinching) {
      return;
    }

    // TODO: double-click handler should probably be more robust, to handle position delta
    this.tapCount++;
    if (this.tapCount === 1) {
      setTimeout(() => {
        if (this.tapCount !== 1) {
          this.dblClickHandler.call(this, e);
        }
        this.tapCount = 0;
      }, 300);
    }
  }

  dblClickHandler() {
    if (this.readOnly) return;
    this.fireExaminePositionUpdate({ examineHidden: true });
    this.graph.autoscale(undefined, Actor.USER);
  }

  examineHandler(e) {
    if (this.axisMoving) return;
    const { graphInstance } = this;
    const { $popoverManager } = this;

    let ignoreEvent;
    ignoreEvent = this.pinching;
    ignoreEvent = ignoreEvent || ($popoverManager.hasPopovers() && !$popoverManager.hasDialogs());

    if (ignoreEvent) {
      return;
    }

    if (this.isSelecting) {
      this.isSelecting = false;
    } else {
      this.dispatchEvent(new CustomEvent('delete-temp-selection'));

      const elRect = this.getBoundingClientRect();
      let relativePx = e.detail.x - elRect.left;

      // prevent examine point from moving beyond the graph's right or left edge
      if (elRect.right - e.detail.x < 0) {
        relativePx = elRect.width - 1;
      }

      if (e.detail.x - elRect.left < 0) {
        relativePx = 0;
      }

      if (e.type === 'track') {
        this.isExamineTracking = e.detail.state === 'track';
        e.stopPropagation();
      }

      const baseAxis = graphInstance.getAxis('base');
      const isCategorical = this.coreGraphEl.containsCategorical;

      const pxToClosestX = px => this.getClosestX({ px }).pt;
      const pxToClosestIndex = px => Math.round(baseAxis.p2i(baseAxis.c2p(px)));
      const closestXPt = !isCategorical ? pxToClosestX(relativePx) : pxToClosestIndex(relativePx);
      const xPosition = !isCategorical ? baseAxis.c2p(relativePx) : pxToClosestIndex(relativePx);

      this.fireExaminePositionUpdate({
        closestXPt,
        xPosition: this.interpolate ? xPosition : closestXPt,
        isGestureFinished: e.type === 'up',
        examineHidden: false,
        isRangeIndexBased: isCategorical,
      });
    }
  }

  fireExaminePositionUpdate(positionUpdate) {
    this.dispatchEvent(
      new CustomEvent('examine-positioning-changed', {
        detail: {
          graphId: this.graphId,
          positionUpdate,
        },
      }),
    );
  }

  fireExamineSettingsUpdate(settingsUpdate) {
    this.dispatchEvent(
      new CustomEvent('examine-settings-changed', {
        detail: {
          graphId: this.graphId,
          settingsUpdate,
        },
      }),
    );
  }

  _isXFlagInView(closestXPx, examEl, analysisElRect) {
    const xFlagEl = examEl.querySelector('.graph-examine__handle');
    const deleteBtnEl = this.shadowRoot.querySelector('.graph-examine__delete');

    const xFlagRect = xFlagEl.getBoundingClientRect();
    const halfFlagWidth = xFlagRect.width / 2;

    if (closestXPx < halfFlagWidth) {
      // the flag is overlapping the left bound
      xFlagEl.classList.add('graph-examine__handle--flip-right');
    } else {
      xFlagEl.classList.remove('graph-examine__handle--flip-right');
    }

    if (closestXPx > analysisElRect.width - halfFlagWidth) {
      // the flag is overlapping the right bound
      xFlagEl.classList.add('graph-examine__handle--flip-left');
      deleteBtnEl?.classList.add('graph-examine__delete--flip-left');
    } else {
      xFlagEl.classList.remove('graph-examine__handle--flip-left');
      deleteBtnEl?.classList.remove('graph-examine__delete--flip-left');
    }
  }

  _isYFlagsInView(closestXPx, examWrapperEl, examineFlagsAllFit, analysisElRect) {
    const flagEl = examineFlagsAllFit
      ? this.shadowRoot.querySelector('.point-highlight__y-value')
      : this.shadowRoot.querySelector('.point-overflow-window');
    if (flagEl) {
      const flagRect = flagEl.getBoundingClientRect();
      if (analysisElRect.width - closestXPx < flagRect.width) {
        examWrapperEl.classList.add('flip-y-flag-left');
      } else {
        examWrapperEl.classList.remove('flip-y-flag-left');
      }
    }
  }

  updateExamineAndTangent() {
    const {
      interpolate,
      tangent,
      examEl,
      examWrapperEl,
      examineFlagsAllFit,
      graphInstance: graph,
      coreGraphEl,
      examinePin,
    } = this;
    if (!examinePin) return;
    const { examinePosition: { xPosition, examineHidden = true, isRangeIndexBased } = {} } =
      examinePin;
    const analysisElRect = this.getBoundingClientRect();
    const baseColumn = coreGraphEl && coreGraphEl.baseColumn;
    const getAxis = axis => graph.getAxis(axis);
    const baseAxis = getAxis('base');

    if (xPosition === null || !(examEl && graph)) {
      return;
    }

    const examineUnits = baseColumn?.group?.units ?? '';
    let examineFormatStr = baseColumn?.formatStr ?? '%.0f'; // TODO: use new precision object

    // if the graph is categorical, interpret the xPosition as a category index
    const closestXPt = !isRangeIndexBased
      ? this.getClosestX({ pt: xPosition }).pt
      : baseAxis.i2p(xPosition);
    const tracePointsForClosestX = !isRangeIndexBased
      ? this.getPointsForX({ xPoint: closestXPt })
      : this.getPointsForX({ index: xPosition });

    const closestXPx = baseAxis.p2c(closestXPt);
    this._isXFlagInView(closestXPx, examEl, analysisElRect); // should we flip the x flag
    setTimeout(() => {
      this._isYFlagsInView(closestXPx, examWrapperEl, examineFlagsAllFit, analysisElRect); // should we flip the y flags
    });

    let computeYPoint;
    let formatYPoint;

    if (interpolate) {
      const examinePx = graph.getAxis('base').p2c(xPosition);
      const closestX = this.getClosestX({ px: examinePx });
      computeYPoint = VstCoreGraphAnalysis.toInterpolatedYPoint(
        VstCoreGraphAnalysis.calculateInterpolatedPoints(
          closestX,
          this.getPointsForX.bind(this),
          examinePx,
        ),
      );
      formatYPoint = VstCoreGraphAnalysis.formatInterpolatedYPoint(getAxis);
      examineFormatStr = increasePrecision(examineFormatStr, 2);
    } else if (tangent) {
      const computeTangentSlope = (...args) => this.$dataAnalysis.computeTangentSlope(...args);
      computeYPoint = VstCoreGraphAnalysis.getComputeYPointWithTangentInfo(computeTangentSlope);
      formatYPoint = VstCoreGraphAnalysis.formatYPoint(getAxis, getText);
    } else {
      computeYPoint = VstCoreGraphAnalysis.toYPoint;
      formatYPoint = VstCoreGraphAnalysis.formatYPoint(getAxis, getText);
    }

    const yPoints = tracePointsForClosestX.map(computeYPoint);
    const xPoint = VstCoreGraphAnalysis.toXPoint(yPoints[0], tracePointsForClosestX[0], closestXPt);

    const xValueFormatted = VstCoreGraphAnalysis.formatXValue(
      examineFormatStr,
      xPoint,
      examineUnits,
    );
    const yPointsFormatted = yPoints.map(formatYPoint);
    const yPointsInView = yPointsFormatted.filter(
      yPoint => parseFloat(yPoint.top) <= analysisElRect.height && parseFloat(yPoint.top) >= 0,
    ); // only show yPoints that are in view on the graph
    const accessibilityService = this.$accessibility;
    const bodyFontSize = parseFloat(window.getComputedStyle(document.body).fontSize);
    if (tangent) {
      this.style.setProperty('--y-value-flag-height', '3rem');
    } else {
      this.style.removeProperty('--y-value-flag-height');
    }
    const closeXEl = this.examEl.querySelector('.graph-examine__delete');
    const flagHeight =
      parseFloat(getComputedStyle(this).getPropertyValue('--y-value-flag-height')) *
      bodyFontSize *
      accessibilityService.scale;
    const allowedFlagOverlap = accessibilityService.scale * 9; // magic number 9. we allow 4.5px overlap on each side of the flag.
    const bottomBounds = parseFloat(analysisElRect.height);
    const topBounds = closeXEl ? closeXEl.clientTop + closeXEl.clientHeight : 0;

    this.examineFlagsAllFit =
      yPointsInView.length * (flagHeight - allowedFlagOverlap) <= bottomBounds - topBounds; // check if all the flags will fit on the graph
    if (this.examineFlagsAllFit) {
      this.yPoints = preventFlagOverlap(
        yPointsInView,
        flagHeight,
        topBounds,
        bottomBounds,
        allowedFlagOverlap,
      );
    } else {
      this.yPoints = yPointsInView;
    }

    this.xPoint = xValueFormatted;
    this.examinePx = VstCoreGraphAnalysis.calculateExaminePos(
      graph.getAxis('base'),
      xPoint,
      examEl.offsetWidth,
    );

    // apply tangent lines to graph
    coreGraphEl.removeAllTangentTraces();
    if (tangent && !examineHidden) {
      yPoints.forEach(({ baseColId, yColId, slope, axis }) => {
        const graphProps = {
          accessibilityScale: coreGraphEl.accessibilityScale,
          getAxis: axisKey => graph.getAxis(axisKey),
        };
        const tangentTrace = this.$dataAnalysis.generateTangentTraceData(
          baseColId,
          yColId,
          xPoint,
          slope,
          axis,
          graphProps,
        );
        coreGraphEl.addTangentTrace(tangentTrace);
      });
      coreGraphEl.updatePlotData();
    }
  }

  static calculateExaminePos(xGraph, xPoint, examElOffsetWidth) {
    return xGraph.p2c(xPoint) - examElOffsetWidth / 2;
  }

  static toXPoint({ xPoint } = {}, { point: { x } } = { point: {} }, closestXPt) {
    return xPoint || x || closestXPt;
  }

  static toYPoint({ yCol, yUnits, traceColor, point, axis }) {
    return {
      formatStr: yCol && yCol.formatStr,
      xPoint: point.x,
      yPoint: point.y,
      units: yUnits,
      color: traceColor,
      point,
      axis,
    };
  }

  static getComputeYPointWithTangentInfo(computeTangentSlope) {
    return tracePoint => {
      const { point, baseUnits, yUnits, baseColId, yColId } = tracePoint;
      const slopeResult = computeTangentSlope(baseColId, yColId, point.x);
      return {
        ...VstCoreGraphAnalysis.toYPoint(tracePoint),
        slope: slopeResult.success ? slopeResult.slope : Number.NaN,
        slopeUnits: baseUnits ? `${yUnits || 1}/${baseUnits}` : `${yUnits}`,
        baseColId,
        yColId,
      };
    };
  }

  static toInterpolatedYPoint(interpolatePoint) {
    return ({ yCol, traceColor, yUnits, point, axis }, i) => {
      const { xPoint, yPoint } = interpolatePoint(i);
      return {
        formatStr: yCol && yCol.formatStr,
        xPoint,
        yPoint,
        units: yUnits,
        color: traceColor,
        point,
        axis,
      };
    };
  }

  static formatXValue(examineFormatStr = '%.2f', xVal, units) {
    return {
      value: typeof xVal === 'number' ? sprintf(examineFormatStr, xVal) : xVal,
      units,
    };
  }

  static formatYPoint(getAxis, _getText) {
    return ({
      yPoint,
      xPoint,
      units,
      point,
      color,
      slope,
      slopeUnits,
      formatStr = '%.2f',
      axis = 'left',
    }) => {
      const baseAxis = getAxis('base');
      const yAxis = getAxis(axis);

      return {
        value: sprintf(formatStr, yPoint),
        units,
        top: `${yAxis.p2c(yPoint)}px`,
        left: `${baseAxis.p2c(xPoint)}px`,
        point,
        slope: !Number.isNaN(slope)
          ? Number.parseFloat(slope).toPrecision(3)
          : _getText('Not defined', 'mathematical'), // Give 3 significant figures of slope
        slopeUnits,
        traceColor: color,
      };
    };
  }

  static formatInterpolatedYPoint(getAxis) {
    return ({ yPoint, xPoint, units, point, color, formatStr = '%.2f', axis = 'left' }) => {
      const baseAxis = getAxis('base');
      const yAxis = getAxis(axis);

      return {
        value: sprintf(increasePrecision(formatStr, 2), yPoint),
        units,
        top: `${yAxis.p2c(yPoint)}px`,
        left: `${baseAxis.p2c(xPoint)}px`,
        point,
        traceColor: changeAlpha(color, 1),
      };
    };
  }

  static calculateInterpolatedPoints(closestXPoint, getPointsForX, examinePx) {
    const { px, pt, nextClosest, closestBelow, closestAbove } = closestXPoint;
    const closest = { px, pt };

    let leftX;
    let rightX;
    // if there is a point both above and below (in x-value) point of interest, use them for interpolate calculations
    // otherwise use the nearest two points
    if (closestBelow && closestAbove) {
      leftX = closestBelow;
      rightX = closestAbove;
    } else {
      leftX = closest.pt < nextClosest.pt ? closest : nextClosest;
      rightX = closest.pt > nextClosest.pt ? closest : nextClosest;
    }

    let percentage = (examinePx - leftX.px) / (rightX.px - leftX.px);
    percentage = isFinite(percentage) ? percentage : 0; // eslint-disable-line no-restricted-globals

    const xPoint = rightX.pt * percentage + (1 - percentage) * leftX.pt;

    const leftY = getPointsForX({ xPoint: leftX.pt });
    const rightY = getPointsForX({ xPoint: rightX.pt });

    return i => {
      let yPoint = 0;
      if (!leftY[i]) {
        yPoint = rightY[i].point.y * percentage;
      } else if (!rightY[i]) {
        yPoint = leftY[i].point.y * percentage;
      } else {
        yPoint = rightY[i].point.y * percentage + (1 - percentage) * leftY[i].point.y;
      }

      return { xPoint, yPoint };
    };
  }

  selectionHandler(e) {
    if (this.pinching || this.axisMoving || this._isDataMarkMoving) {
      return;
    }

    const elRect = this.getBoundingClientRect();
    const { state } = e.detail;
    let currentPxRange = {};
    const xPos = e.detail.x - elRect.left;

    if (e.type === 'down') {
      this._startX = xPos;
    }

    if (state === 'track' && this._startX) {
      if (e.detail.dx > 25 || e.detail.dx < -25 || this.isSelecting) {
        this.isSelecting = true;

        if (this.examWrapperEl && !this.examineHidden) {
          this.fireExaminePositionUpdate({ examineHidden: true });
        }

        if (e.detail.dx < 0) {
          currentPxRange = {
            min: Math.max(xPos, 0),
            max: this._startX,
          };
        } else {
          currentPxRange = {
            min: this._startX,
            max: Math.min(xPos, elRect.width),
          };
        }

        // TODO: support selections and analysis on graphs with multiple categorical data sets
        // For categorical data, the selection range min/max values contain indexes
        // Although technically the index corresponds to which base-axis category label
        // the selection edge is positioned on, when analysis is computed in DataAnalysis
        // the indexes are interpreted as indexes into the data columns.
        // When only one data set is included on a categorical graph, these two semantics
        // of indexes should be the same, but otherwise analysis will be broken

        const baseAxis = this.graphInstance.getAxis('base');
        const pxToClosestX = px => this.getClosestX({ px }).pt;
        const pxToClosestIndex = px => Math.round(baseAxis.p2i(baseAxis.c2p(px)));
        const pxToRangeVal = !this.coreGraphEl.containsCategorical
          ? pxToClosestX
          : pxToClosestIndex;

        this.dispatchEvent(
          new CustomEvent('new-selection-gesture', {
            detail: {
              graphId: this.graphId,
              currentRange: mapValues(currentPxRange, pxToRangeVal),
              isRangeIndexBased: this.coreGraphEl.containsCategorical,
            },
          }),
        );
      }
    } else if (state === 'end') {
      this._startX = null;
      setTimeout(() => {
        this.isSelecting = false;
      });
      this.dispatchEvent(new CustomEvent('new-selection-gesture-end'));
    }
  }

  deleteExamineHandler(e) {
    if (this.pinching) {
      return;
    }

    this.fireExaminePositionUpdate({ examineHidden: true });
    e.stopPropagation();
  }

  infoBoxMoveHandler(infoBoxEl, e) {
    const infoBoxHeaderEl = infoBoxEl.shadowRoot.querySelector('#header');
    const infoBoxHeaderHeight = infoBoxHeaderEl.getBoundingClientRect().height;
    const infoBoxHeaderWidth = infoBoxHeaderEl.getBoundingClientRect().width;
    const analysisRect = this.getBoundingClientRect();
    const event = e.detail;
    const { state } = event;

    const updatePosition = () => {
      requestAnimationFrame(() => {
        const { boxTopAtStart, boxLeftAtStart } = this.infoBoxMoveHandler;
        let { dy, dx } = event;

        // set dx and dy in order to keep infoBox inside the analysis element
        if (boxTopAtStart + dy < analysisRect.top && dy < 0) {
          dy = analysisRect.top - boxTopAtStart;
        } else if (boxTopAtStart + dy + infoBoxHeaderHeight > analysisRect.bottom && dy > 0) {
          dy = analysisRect.bottom - boxTopAtStart - infoBoxHeaderHeight;
        }
        if (boxLeftAtStart + dx < analysisRect.left && dx < 0) {
          dx = analysisRect.left - boxLeftAtStart;
        } else if (boxLeftAtStart + dx + infoBoxHeaderWidth > analysisRect.right && dx > 0) {
          dx = analysisRect.right - boxLeftAtStart - infoBoxHeaderWidth;
        }

        infoBoxEl.translateYLength = `${dy}px`;
        infoBoxEl.translateXLength = `${dx}px`;
      });
    };

    if (state === 'start') {
      infoBoxEl.classList.add('translating');
      this.infoBoxMoveHandler.boxTopAtStart = infoBoxEl.getBoundingClientRect().top;
      this.infoBoxMoveHandler.boxLeftAtStart = infoBoxEl.getBoundingClientRect().left;
    } else if (state === 'track') {
      updatePosition();
    } else if (state === 'end') {
      const boxRect = infoBoxEl.getBoundingClientRect();
      requestAnimationFrame(() => {
        infoBoxEl.topOffset = `${((boxRect.top - analysisRect.top) / analysisRect.height) * 100}%`;
        infoBoxEl.translateYLength = '';

        infoBoxEl.leftOffset = infoBoxEl.flippedLeft
          ? boxRect.right - analysisRect.left
          : boxRect.left - analysisRect.left; // NEW works if not flipped
        infoBoxEl.translateXLength = '';

        setTimeout(() => {
          infoBoxEl.classList.remove('translating');
        });
      });
    }
  }

  /**
   * @param {object} args Selection characteristics
   * @param {string} args.id
   * @param {object} args.infoBox
   * @param {boolean} args.isRangeIndexBased
   * @param {number} args.max
   * @param {number} args.min
   * @param {boolean} args.permanent
   * @param {boolean} args.persistForSession
   * @param {{min: number, max:number}} args.range
   */
  createSelection(args) {
    const selection = document.createElement('vst-core-graph-selection');
    selection.graphInstance = this.graphInstance;
    selection.getClosestX = this.boundGetClosestX;
    selection.readOnly = this.readOnly;
    this.selectionWrapperEl.appendChild(selection);

    selection.id = args.id;
    selection.permanent = args.permanent;
    // set-up range via point range object
    selection.range = args.range;
    selection.isRangeIndexBased = args.isRangeIndexBased;

    // set-up range via pixel min and max variables
    if (args.min !== undefined && args.max !== undefined) {
      selection.min = args.min;
      selection.max = args.max;
    }

    if (args.persistForSession) {
      selection.persistForSession = args.persistForSession;
    }

    if (args.infoBox && args.infoBox.x !== -1 && args.infoBox.y !== -1) {
      const { x, y, isCollapsed } = args.infoBox;
      selection.infoBoxPosition = {
        x: this.basePercentToPx(x),
        y: this.leftPercentToPx(y),
        isCollapsed,
      };
    }

    return selection;
  }

  async _deleteAnnotation(annotation) {
    await this.$dataWorld.removeGraphAnnotation(annotation).catch(error => {
      console.error(error);
    });
    // Remove line highlight
    this.coreGraphEl.updatePlotData();
  }

  /**
   * Adds annotation to associated graph.
   * @param {boolean} [allowTargeting=false] if true, allows us to create a targeted
   * annotation (aka annotation that points at a column index or range of
   * indices). Otherwise, if false, will create a free floating annotation.
   */
  async addAnnotation(allowTargeting = false) {
    try {
      const annotation = await this.$dataWorld.addGraphAnnotation(this.graph.udmId, {});
      const traces = this.coreGraphEl.getAnalysisTraces();
      if (!annotation.id) throw new Error('No udmId generated for annotation');

      if (allowTargeting) {
        if (!this.examineHidden) {
          traces.forEach(trace => {
            const index = trace.baseColumn.values.findIndex(
              (value, i) =>
                value === this.examinePin.examinePosition.xPosition &&
                isValidVal(trace.yColumn.values[i]),
            );
            if (index > -1) {
              annotation.setPointTarget(trace.baseColumn.id, index);
              annotation.setPointTarget(trace.yColumn.id, index);
            }
          });
        } else if (this.selections.filter(selection => !selection.permanent).length) {
          const lastSelection = this.selections.at(-1);
          const selection = this.selectionsWithData[lastSelection.id];
          const { min, max } = selection.range;
          const isRange = min !== max;

          if (isRange) {
            traces.forEach(trace => {
              // Find the first index for a base value in range that has a valid
              // corresponding y value
              const minIndex = trace.baseColumn.values.findIndex(
                (value, i) => value >= min && value < max && isValidVal(trace.yColumn.values[i]),
              );
              // Find the last index for a base value in range that has a valid
              // corresponding y value
              const maxIndex = findLastIndex(
                trace.baseColumn.values,
                (value, i) => value <= max && i > minIndex && isValidVal(trace.yColumn.values[i]),
              );
              if (minIndex > -1 && maxIndex > -1) {
                annotation.setRangeTarget(trace.baseColumn.id, minIndex, maxIndex);
                annotation.setRangeTarget(trace.yColumn.id, minIndex, maxIndex);
              }
            });
          } else {
            traces.forEach(trace => {
              const index = trace.baseColumn.values.findIndex(value => value === min);
              if (index > -1 && isValidVal(trace.yColumn.values[index])) {
                annotation.setPointTarget(trace.baseColumn.id, index);
                annotation.setPointTarget(trace.yColumn.id, index);
              }
            });
          }
        }
      }

      annotation.setEditingOnGraph(this.graph.udmId);
      annotation.setPositionOnGraph(this.graph.udmId, { x: 500, y: 500 });
      this.coreGraphEl.updatePlotData();
    } catch (error) {
      console.error(error);
    }
  }

  async bindInfoBox(el, which) {
    if (!which) {
      return;
    }
    await el.updateComplete;
    await this.updateComplete;

    const { infoBoxEl } = el;
    if (infoBoxEl) {
      if (infoBoxEl.eventBinder) {
        infoBoxEl.eventBinder.unbindAll();
        infoBoxEl.eventBinder = null;
      }

      infoBoxEl.style.display = 'none'; // force a reflow since the infobox background doesn't render on iOS v11.2.6
      void infoBoxEl.offsetHeight; // eslint-disable-line no-void
      infoBoxEl.style.display = '';

      infoBoxEl.eventBinder = new EventBinder();

      infoBoxEl.eventBinder.on(infoBoxEl, `infobox-moving`, e => {
        if (this.pinching) {
          return;
        }

        this.infoBoxMoveHandler(infoBoxEl, e);
      });
    }
  }

  getGraphBaseRange() {
    if (this.graphInstance) {
      return this.graphInstance.getBaseRange();
    }
    return {};
  }

  getGraphPxWidth() {
    return this.clientWidth;
  }

  getSelectionElementById(id) {
    return this.selections.find(s => s.id === id);
  }

  getBaseColumn() {
    return this.coreGraphEl.getBaseColumn();
  }

  _getBaseColumnUnits() {
    const baseColumn = this.getBaseColumn();
    return baseColumn ? baseColumn.group.units : null;
  }

  _getBaseColumnFormatString() {
    const baseColumn = this.getBaseColumn();
    return baseColumn ? baseColumn.formatStr : '%0.3f';
  }

  /**
   * Determines which plotted points have the closest horizontal position to the passed pixel value or x-point value.
   *
   * @param {object} params Desctibes a horizontal position on the graph with either a pixel or x-point value.
   *    If both are included, the x-point value is used.
   * @param {number} params.pt Optional graph x-direction point value to use. Required if `param.pt` is nullish.
   * @param {number} params.px Optional graph canvas horizontal pixel value to use. Required if `param.pt` is nullish.
   *
   * @returns {object} Bundle of info about the horizontal position of several plotted points that are nearest to the passed position.
   */
  getClosestX({ px, pt }) {
    const baseAxis = this.graphInstance.getAxis('base');
    const xPointOrig = pt ?? baseAxis.c2p(px);

    let pointsCollection;
    if (this.interpolate && this.coreGraphEl.fitTraces.length) {
      pointsCollection = this.coreGraphEl.fitTraces.map(trace => trace.dataPoints);
    } else {
      pointsCollection = this.coreGraphEl.getAnalysisTraces().map(trace => trace.getDataPoints());
    }

    const emptyPoint = {
      xVal: null,
      delta: Infinity,
      index: -1,
    };

    let closestPoints = {
      closest: {
        ...emptyPoint,
        xVal: xPointOrig,
      },
      nextClosest: { ...emptyPoint },
      closestBelow: { ...emptyPoint },
      closestAbove: { ...emptyPoint },
    };

    // search data points of all (curve-fit) traces to find closest
    pointsCollection.forEach(points => {
      for (let i = 0; i < points.length; i++) {
        const x = points[i][0];
        const y = points[i][1];

        if (!Number.isNaN(x) && !Number.isNaN(y)) {
          const newPoint = {
            xVal: x,
            delta: Math.abs(xPointOrig - x),
            index: i,
          };

          closestPoints = VstCoreGraphAnalysis._updateClosestPoints(
            xPointOrig,
            closestPoints,
            newPoint,
          );
        }
      }
    });

    const { closest, nextClosest, closestBelow, closestAbove } = closestPoints;

    // convert world point back to pixel
    return {
      pt: closest.xVal,
      px: baseAxis.p2c(closest.xVal),
      index: closest.index,
      nextClosest:
        nextClosest.xVal !== null
          ? { pt: nextClosest.xVal, px: baseAxis.p2c(nextClosest.xVal) }
          : null,
      closestBelow:
        closestBelow.xVal !== null
          ? { pt: closestBelow.xVal, px: baseAxis.p2c(closestBelow.xVal) }
          : null,
      closestAbove:
        closestAbove.xVal !== null
          ? { pt: closestAbove.xVal, px: baseAxis.p2c(closestAbove.xVal) }
          : null,
    };
  }

  /**
   * @param {number} originalXVal The x value of the original reference point - typically representing a user's click
   * @param {object} closestPoints Contains the closest point above and next closest point: { closest, nextClosest, closestBelow, closestAbove }
   * @param {object} newPoint Point object to evaluate whether closer than the current closest points: { xVal, delta, index }
   * @returns Updated set of closest and next closest points
   */
  static _updateClosestPoints(originalXVal, closestPoints, newPoint) {
    let { closest, nextClosest, closestBelow, closestAbove } = closestPoints;

    if (newPoint.delta < closest.delta) {
      nextClosest = closest;
      closest = { ...newPoint };
    } else if (newPoint.delta < nextClosest.delta) {
      nextClosest = { ...newPoint };
    }

    if (newPoint.xVal <= originalXVal && newPoint.delta < closestBelow.delta) {
      closestBelow = { ...newPoint };
    }

    if (newPoint.xVal >= originalXVal && newPoint.delta < closestAbove.delta) {
      closestAbove = { ...newPoint };
    }

    return { closest, nextClosest, closestBelow, closestAbove };
  }

  /**
   * get all the samples for each trace for the given horixontal position, either x point or index
   *
   * @param {object} params Desctibes a horizontal position on the graph with either an x-point value or an index.
   *    If both are included, the x-point value is used.
   * @param {number} params.xPoint Optional graph x-direction point value to use. Required if `params.index` is nullish.
   * @param {number} params.index Optional index value to use. Required if `params.xPoint` is not finite.
   *
   * @returns hash indexed by trace id, where each value is an array of objects { index: x:, y: }
   */
  getPointsForX({ xPoint, index }) {
    const { fitTraces } = this.coreGraphEl;
    const tracePoints = []; // map indexed by trace for all matching points
    let traceItems;

    const usingFitTraces = this.interpolate && fitTraces.length > 0;
    if (usingFitTraces) {
      traceItems = fitTraces.map(({ traceColor, yColumnId, dataPoints, axis }) => ({
        traceColor,
        yColumnId,
        dataPoints,
        axis,
      }));
    } else {
      traceItems = this.coreGraphEl.getAnalysisTraces().map(trace => ({
        traceColor: trace.color,
        yColumnId: trace.yColumn.id,
        dataPoints: trace.getDataPoints(),
        yColumn: trace.yColumn,
        baseColumn: trace.baseColumn,
        axis: trace.axis,
      }));
    }

    const isValid = value => Number.isFinite(value) || typeof value === 'string';
    const matchesXPosition = (x, i) =>
      Number.isFinite(xPoint) ? Math.abs(x - xPoint) < 0.0000000000001 : i === index;

    // look through trace items and find which is closest
    traceItems.forEach(item => {
      const points = item.dataPoints;

      for (let i = 0; i < points.length; i++) {
        const x = points[i][0];
        const y = points[i][1];

        if (isValid(x) && isValid(y)) {
          if (matchesXPosition(x, i)) {
            const baseColumn = item.baseColumn || undefined; // currently, baseColumn is only needed for tangent (which is incompatible with interpolate)
            const yColumn = item.yColumn || this.$dataWorld.getColumnById(item.yColumnId);
            // add point
            tracePoints.push({
              yCol: yColumn,
              baseColId: baseColumn ? baseColumn.id : undefined,
              yColId: item.yColumnId,
              baseUnits: baseColumn ? baseColumn.group.units : '',
              yUnits: yColumn ? yColumn.group.units : '',
              traceColor: item.traceColor,
              axis: item.axis,
              point: {
                index: i,
                x,
                y,
              },
            });
          }
        }
      }
    });

    return tracePoints;
  }

  _handleExamineKeydown(event) {
    this.dispatchEvent(
      new CustomEvent('examine-key-pressed', {
        detail: { currentPoint: this.xPoint, code: event.code, shift: event.shiftKey },
      }),
    );
  }

  static get styles() {
    return [globalStyles, vstCoreGraphAnalysisStyles];
  }

  /**
   * Manual fit coefficients
   *
   * @return {number[]} manual fit coefficients
   */
  get _manualFitCoefficients() {
    const { x1, y1, x3, y3 } = this._manualFitPlotPosition;
    const slope = (y3 - y1) / (x3 - x1);
    const intercept = y1 - slope * x1;
    return [slope, intercept];
  }

  /**
   * Manual fit position in graph units
   *
   * @return {ManualFitPosition} Manual fit position in graph units
   */
  get _manualFitPlotPosition() {
    const baseAxis = this.graphInstance.getAxis('base');
    const leftAxis = this.graphInstance.getAxis('left');
    return {
      x1: baseAxis.c2p(this._manualFitPositionPx.x1),
      y1: leftAxis.c2p(this._manualFitPositionPx.y1),
      x2: baseAxis.c2p(this._manualFitPositionPx.x2),
      y2: leftAxis.c2p(this._manualFitPositionPx.y2),
      x3: baseAxis.c2p(this._manualFitPositionPx.x3),
      y3: leftAxis.c2p(this._manualFitPositionPx.y3),
    };
  }

  get examineOffGraph() {
    const { xPosition = 0 } = this.examinePin?.examinePosition ?? {};
    const { min = 0, max = 1 } = this.coreGraphEl?.options?.baseRange ?? {};
    return xPosition > max || xPosition < min;
  }

  get visibleAnnotations() {
    // Filter out annotations that aren't freeform or within the graph's ranges
    return (
      this.$dataWorld?.annotations.filter(
        annotation =>
          (annotation.type === AnnotationType.FREE &&
            annotation.isShownOnGraph(this.graph.udmId)) ||
          this.coreGraphEl
            .getAnalysisTraces()
            .some(
              trace =>
                annotation.containsTargetColumn(trace.yColumn.id) &&
                this._checkThatAnnotationRangeIsOnGraph(annotation, trace),
            ),
      ) || []
    );
  }

  render() {
    const annotationBoundingBox = this.annotationWrapperEl?.getBoundingClientRect();
    const moveIcon = () => html`
      <svg><path style="fill:#c1d5d7;" d="m 23.793256,29.787531 v 10.040485 h -3.651084 l 6.389399,12.778796 6.389398,-12.778796 H 29.269884 V 29.787531 l 0.45451,-0.519171 h 10.040482 v 3.651087 L 52.54367,26.530045 39.764876,20.140647 v 3.651085 H 29.724394 l -0.45451,-0.516121 V 13.235127 h 3.651085 L 26.531571,0.45633011 20.142172,13.235127 h 3.651084 v 10.040484 l -0.517647,0.516121 H 13.235123 V 20.140647 L 0.45633011,26.530045 13.235123,32.919447 V 29.26836 h 10.040486 z"></svg>
      <svg viewBox="0 0 24 24"><path style="fill: var(--vst-color-bg-graph); stroke: var(--vst-color-fg-tertiary);" d="M12 1L23 12L12 23L1 12Z"></svg>
    `;
    return html`
      <div
        class="analysis-wrapper"
        id="analysis_wrapper"
        tabindex="0"
        @keyup="${e => (e.key === 'Enter' ? this.tapHandler(e) : '')}"
        @click="${this.tapHandler}"
      >
        <div id="examine_selection_wrapper">
          <svg id="hairlines">
            ${repeat(
              Object.entries(this._bracketPositions),
              ([selectionId]) => selectionId,
              ([selectionId, hairlineInfoList]) =>
                hairlineInfoList.map(
                  hairlineInfo =>
                    svg`
                   <line
                    class="${selectionId}"
                    style=${styleMap({
                      stroke: hairlineInfo.color,
                    })}
                    x1="${this._infoBoxPositions[selectionId].x}"
                    y1="${this._infoBoxPositions[selectionId].y}"
                    x2="${hairlineInfo.x}"
                    y2="${hairlineInfo.y}"
                   />`,
                ),
            )}
            ${repeat(
              this.dataMarks,
              dataMark => dataMark.id,
              dataMark =>
                this.leftTraces
                  .filter(trace => isValidTraceForDatamark(trace, dataMark))
                  .map(
                    trace =>
                      svg`<circle class="data-mark" cx="${this.dataMarkToBasePxLocation(
                        dataMark,
                      )}" cy="${this.dataMarkToLeftPxLocation(
                        dataMark,
                        trace.yColumn.id,
                      )}" r="2"/>`,
                  ),
            )}
            ${repeat(
              this.dataMarks,
              dataMark => dataMark.id,
              dataMark =>
                this.leftTraces
                  .filter(trace => isValidTraceForDatamark(trace, dataMark))
                  .map(
                    trace =>
                      svg`
                        <line
                         class="data-mark"
                         x1="${this.dataMarkToBasePxLocation(dataMark)}"
                         y1="${this.dataMarkToLeftPxLocation(dataMark, trace.yColumn.id)}"
                         x2="${
                           this.dataMarkBoxPositions[dataMark.id.toString()]?.x ||
                           this.dataMarkToBasePxLocation(dataMark)
                         }"
                         y2="${
                           this.dataMarkBoxPositions[dataMark.id.toString()]?.y ||
                           this.dataMarkToLeftPxLocation(dataMark, trace.yColumn.id)
                         }"
                        />`,
                  ),
            )}
            ${repeat(
              this.visibleAnnotations,
              annotation => annotation.id,
              annotation => this._createHairlineSvg(annotation),
            )}
          </svg>
          <div
            class="graph-examine-wrapper ${this.isExamineTracking ? 'is-tracking' : ''}"
            id="graph_examine_wrapper"
            ?hidden="${this.examineHidden || this.examineOffGraph}"
          >
            <div class="graph-examine" id="graph_examine" style="left: ${this.examinePx}px;">
              ${!this.examinePreventClose
                ? html`
                    <button
                      class="graph-examine__delete"
                      id="graph_examine_delete"
                      ?hidden="${this.readOnly}"
                      @click="${this.deleteExamineHandler}"
                    >
                      <vst-ui-icon class="icon-close" .icon="${iconClose}"></vst-ui-icon>
                    </button>
                  `
                : ''}
              <div class="graph-examine__pin">
                <div
                  class="graph-examine__handle drag-handle"
                  id="graph_examine_handle"
                  tabindex="0"
                  ?read-only="${this.readOnly}"
                  @keydown="${this._handleExamineKeydown}"
                >
                  <vst-ui-icon .icon="${iconGrabHandle}"></vst-ui-icon>
                  <div>
                    <span>${this.xPoint.value}</span>
                    <span>${this.xPoint.units}</span>
                  </div>
                  <vst-ui-icon .icon="${iconGrabHandle}"></vst-ui-icon>
                </div>
              </div>

              ${!this.examineFlagsAllFit
                ? html`
                    <ul
                      class="point-overflow-window ${this.tangent
                        ? 'point-overflow-window--tangent'
                        : ''}"
                      @mouseup="${e => e.stopPropagation()}"
                      @touchend="${e => e.stopPropagation()}"
                    >
                      ${this.yPoints.map(
                        yPoint => html`
                          ${this.tangent
                            ? html`
                                <li
                                  class="point-overflow-window__y-value"
                                  style="color: ${yPoint.traceColor};"
                                >
                                  <div>${yPoint.value} ${yPoint.units}</div>
                                  <div>
                                    ${getText('Slope')}: ${yPoint.slope} ${yPoint.slopeUnits}
                                  </div>
                                </li>
                              `
                            : ''}
                          ${this.interpolate
                            ? html`
                                <li
                                  class="point-overflow-window__y-value"
                                  style="background-color: ${yPoint.traceColor}; color: #fff;"
                                >
                                  ${yPoint.value} ${yPoint.units}
                                </li>
                              `
                            : ''}
                          ${!this.interpolate && !this.tangent
                            ? html`
                                <li
                                  class="point-overflow-window__y-value"
                                  style="color: ${yPoint.traceColor};"
                                >
                                  ${yPoint.value} ${yPoint.units}
                                </li>
                              `
                            : ''}
                        `,
                      )}
                    </ul>
                  `
                : ''}
            </div>

            ${this.examineFlagsAllFit
              ? html`
                  ${this.yPoints.map(yPoint =>
                    this.tangent
                      ? html`
                          <div
                            class="point-highlight point-highlight--tangent"
                            style="top: ${yPoint.top}; left: ${yPoint.left}; border-color: ${yPoint.traceColor};"
                          >
                            <div
                              class="point-highlight__y-value"
                              style="top: ${yPoint.flagTop}; --bg: ${this.colorMode === 'dark'
                                ? 'var(--vst-color-bg-primary)'
                                : 'rgba(255,255,255,0.95)'}; color: ${this.colorMode === 'dark'
                                ? 'var(--vst-color-fg-primary)'
                                : yPoint.traceColor};"
                            >
                              <div>${yPoint.value} ${yPoint.units}</div>
                              <div>${getText('Slope')}: ${yPoint.slope} ${yPoint.slopeUnits}</div>
                            </div>
                          </div>
                        `
                      : html`
                          ${this.interpolate
                            ? html`
                                <div
                                  class="point-highlight"
                                  style="top: ${yPoint.top}; left: ${yPoint.left}; border-color: ${yPoint.traceColor};"
                                >
                                  <div
                                    class="point-highlight__y-value"
                                    style="top: ${yPoint.flagTop}; --bg: ${this.colorMode === 'dark'
                                      ? 'var(--vst-color-bg-primary)'
                                      : yPoint.traceColor}; color: ${this.colorMode === 'dark'
                                      ? 'var(--vst-color-fg-primary)'
                                      : 'var(--vst-color-bg)'};"
                                  >
                                    ${yPoint.value} ${yPoint.units}
                                  </div>
                                </div>
                              `
                            : html`
                                <div
                                  class="point-highlight"
                                  style="top: ${yPoint.top}; left: ${yPoint.left}; border-color: ${yPoint.traceColor};"
                                >
                                  <div
                                    class="point-highlight__y-value"
                                    style="top: ${yPoint.flagTop}; --bg: ${this.colorMode === 'dark'
                                      ? 'var(--vst-color-bg-primary)'
                                      : 'rgba(255,255,255,0.95)'};  color: ${this.colorMode ===
                                    'dark'
                                      ? 'var(--vst-color-fg-primary)'
                                      : yPoint.traceColor};"
                                  >
                                    ${yPoint.value} ${yPoint.units}
                                  </div>
                                </div>
                              `}
                        `,
                  )}
                `
              : html`
                  ${this.yPoints.map(
                    yPoint => html`
                      <div
                        class="point-highlight"
                        style="top: ${yPoint.top}; left: ${yPoint.left}; border-color: ${yPoint.traceColor};"
                      ></div>
                    `,
                  )}
                `}
          </div>
          <div class="selection-wrapper" id="selection_wrapper">
            ${Object.values(this.splitSelections).map(
              splitSelection =>
                html`
                  <vst-ui-split-selection
                    .id="${splitSelection.id}"
                    .splitRegions="${splitSelection.splitRegions}"
                    .graphInstance="${this.graphInstance}"
                    .getPlotRegionBoundingRect="${this.getSelectionWrapperBoundRect}"
                    .getClosestX="${this.boundGetClosestX}"
                  >
                  </vst-ui-split-selection>
                `,
            )}
          </div>
          <div id="manual-fit-handles" ?hidden=${!this.activeManualFit || this.readOnly}>
            <button
              type="button"
              style="left: ${this._manualFitPositionPx?.x1}px; top: ${this._manualFitPositionPx
                ?.y1}px;"
              data-handle="1"
              @keydown=${this._handleManualFitKeydown}
            >
              ${moveIcon()}
              <span visually-hidden>${getText('Left manual fit handle')}</span>
            </button>
            <button
              type="button"
              style="left: ${this._manualFitPositionPx?.x2}px; top: ${this._manualFitPositionPx
                ?.y2}px;"
              data-handle="2"
              @keydown=${this._handleManualFitKeydown}
            >
              ${moveIcon()}
              <span visually-hidden>${getText('Center manual fit handle')}</span>
            </button>
            <button
              type="button"
              style="left: ${this._manualFitPositionPx?.x3}px; top: ${this._manualFitPositionPx
                ?.y3}px;"
              data-handle="3"
              @keydown=${this._handleManualFitKeydown}
            >
              ${moveIcon()}
              <span visually-hidden>${getText('Right manual fit handle')}</span>
            </button>
          </div>
          <div class="data-mark-wrapper" id="data-mark-wrapper" ${ref(this._dataMarkWrapperRef)}>
            ${repeat(
              this.dataMarks,
              dataMark => dataMark.id,
              dataMark =>
                html`<vst-ui-draggable
                  .xPos=${this.getBaseDataMarkElLocation(dataMark)}
                  .yPos=${this.getLeftDataMarkElLocation(dataMark)}
                  style="${!dataMark.getHasMoved(this.parentGraphId) || this.readOnly
                    ? 'position: absolute; '
                    : 'position: fixed;'}transform: translate(-100%, -50%);"
                  .draggableId=${dataMark.id}
                  .dragContainer=${this._dataMarkWrapperRef.value}
                  emitPositionOn="data-mark-size-changed"
                  @draggable-moved=${this.updateDataMarkElPosition}
                >
                  <vst-ui-data-mark
                    ?hidden=${!this.leftTraces.some(
                      trace => !Number.isNaN(trace.yColumn.values[dataMark.rowIndex]),
                    )}
                    .note=${dataMark.text}
                    .data=${this._dataMarksData[dataMark.id] || []}
                    .isShown=${dataMark.isShown}
                    .position=${dataMark.position}
                    .dataMarkId=${dataMark.id}
                    .editing=${dataMark.editingGraphId === this.parentGraphId}
                    @note-updated=${this.handleDataMarkTextUpdate}
                    @edit-state-changed=${this.handleDataMarkEditingUpdate}
                    @data-mark-deleted=${this.handleDeleteDataMark}
                    @track=${this.handleDataMarkMoving}
                    @up=${e => {
                      // Stop taps on the data mark from placing an examine line
                      e.stopPropagation();
                    }}
                  ></vst-ui-data-mark>
                </vst-ui-draggable> `,
            )}
          </div>
        </div>
        <div class="annotation-wrapper" id="annotation_wrapper">
          ${repeat(
            this.visibleAnnotations,
            annotation => annotation.id,
            annotation => html`
              <vst-ui-graph-annotation
                .annotation=${annotation}
                .bounds=${annotationBoundingBox}
                .graphUdmId=${this.graph.udmId}
                @position-updated=${e => this._updateAnnotationHairline(e)}
                @annotation-deleted=${() => this._deleteAnnotation(annotation)}
              >
              </vst-ui-graph-annotation>
            `,
          )}
        </div>
      </div>
    `;
  }
}

customElements.define('vst-core-graph-analysis', VstCoreGraphAnalysis);

import { LitElement, html } from 'lit';

import { globalStyles } from '@styles/vst-style-global.css.js';
import vstUiSplashScreenStyles from './vst-ui-splash-screen.css.js';

class VstUiSplashScreen extends LitElement {
  static get properties() {
    return {
      showProgress: {
        type: Boolean,
      },
      percentLoaded: {
        type: Number,
      },
      errorMsg: {
        type: String,
      },
    };
  }

  constructor() {
    super();
    this.showProgress = false;
    this.percentLoaded = 0;
    this.errorMsg = '';
  }

  hide() {
    this.classList.add('transition-out');

    this.addEventListener('animationend', e => {
      if (e.animationName !== 'fadeOut') {
        return;
      }
      // this.hidden = true;
      this.getRootNode().host.shadowRoot.removeChild(this); // remove whole element from the dom
    });
  }

  static get styles() {
    return [globalStyles, vstUiSplashScreenStyles];
  }

  render() {
    return html`
      <div class="wrapper">
        <slot name="logo"></slot>
        <progress
          class="progress-bar"
          .value="${this.percentLoaded}"
          max="100"
          ?hidden="${!this.showProgress}"
        ></progress>
        ${this.errorMsg
          ? html` <p class="caption" variant="error" margin="block-start-l">${this.errorMsg}</p> `
          : ''}
      </div>
    `;
  }
}

customElements.define('vst-ui-splash-screen', VstUiSplashScreen);

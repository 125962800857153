const intlNumberFormat = new Intl.NumberFormat(undefined, { maximumFractionDigits: 20 });

const thousandSeparator = (1111).toLocaleString().replace(/1/g, '');
const decimalSeparator = (1.1).toLocaleString().replace(/1/g, '');
const negativeNumIndicator = (-1).toLocaleString().replace(/1/g, '');

export const separators = {
  thousand: thousandSeparator,
  decimal: decimalSeparator,
  negativeSign: negativeNumIndicator,
};

export const toLocaleNumber = (number, _intlNumberFormat = intlNumberFormat) => {
  if (!Number.isNaN(parseFloat(number))) {
    // As of iOS 12, formatToParts() is not supported.
    // If we detect that it's missing we will call `format()` instead.
    // GA4-2868 JK 20190608.

    if (typeof _intlNumberFormat.formatToParts === 'function') {
      const getPartValue = part => {
        const isMinusPart = part.type === 'minusSign';
        const isGroupPart = part.type === 'group'; // thousands separator
        let partValue = part.value;

        if (isMinusPart) {
          // always use the ascii hyphen-minus symbol (U+002D) to indicate negative, never the U+2212 minus symbol
          // TODO: Fix this the next time the file is edited.
          // eslint-disable-next-line no-return-assign
          return (partValue = '-');
        }
        if (isGroupPart) {
          // TODO: Fix this the next time the file is edited.
          // eslint-disable-next-line no-return-assign
          return (partValue = '');
        }

        return partValue;
      };

      return _intlNumberFormat.formatToParts(number).reduce((numbStr, part) => {
        return numbStr + getPartValue(part);
      }, '');
    }
    // Take absolute value of number, and then add a minus sign to the return value if necessary.
    const isNegative = number < 0;
    const formattedValue = _intlNumberFormat
      .format(Math.abs(number))
      .replace(separators.thousandSeparator, ''); // do not add the thousands separator;
    return isNegative ? `-${formattedValue}` : formattedValue;
  }
  return '';
};

// Credit: https://stackoverflow.com/a/42213804
export const fromLocaleNumber = (string = '', _separators = separators) => {
  /// On iOS, the thousand separator can be "" in certain locales (es), which leads to a throw when we create the regex. GA4-3860 JK 20191014.
  if (_separators.thousand !== '') {
    const thousandExp = new RegExp(`\\${_separators.thousand}`, 'g');
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line no-param-reassign
    string = string.replace(thousandExp, '');
  }
  const decimalExp = new RegExp(`\\${_separators.decimal}`);
  const negativeExp = new RegExp(`\\${_separators.negativeSign}`);
  return parseFloat(string.replace(decimalExp, '.').replace(negativeExp, '-')); // use U+002D as negative sign
};

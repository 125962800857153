import { css } from 'lit';
import { breakpoint } from '@styles/mixins/breakpoints.css.js';
import { dialogDefault } from '@styles/mixins/dialogs.css.js';

export default css`
  :host {
    block-size: 100%;
  }

  ${dialogDefault};
  ${breakpoint(
    'm',
    css`
      :host {
        width: 31.25rem;
      }
    `,
  )}

  .content {
    min-block-size: 100%;
    display: flex;
    flex-direction: column;
    background: var(--vst-color-bg);
  }

  ${breakpoint(
    's',
    css`
      .content {
        align-items: flex-start;
      }
    `,
  )}

  .release {
    margin-block-end: var(--vst-space-l);
  }

  .heading[size='l'] {
    margin: 0;
  }

  .release__change-list {
    list-style: none;
    margin: 0;
    padding-inline-start: 0;
  }
  .release__change-list ul {
    padding-inline-start: 1.125rem;
    list-style-type: circle;
  }
  .release__change {
    display: flex;
    flex-direction: column;
    color: var(--vst-color-fg-tertiary);
    margin-block-end: var(--vst-space-m);
  }

  ${breakpoint(
    's',
    css`
      .release__change {
        display: grid;
        grid-template-columns: minmax(min-content, 5em) 1fr;
        grid-column-gap: var(--vst-space-m);
        align-items: start;
      }
    `,
  )}

  .release .inline-icon {
    width: 1rem;
    height: 1rem;
    display: inline-block;
  }

  .label {
    font-size: var(--vst-font-size-xs);
    inline-size: 30%;
    padding: var(--vst-space-2xs);
    padding-inline-start: var(--vst-space-xs);
    text-transform: uppercase;
    font-weight: bold;
    border-bottom: 2px solid var(--vst-color-success-dark);
  }

  ${breakpoint(
    's',
    css`
      .label {
        inline-size: 100%;
      }
    `,
  )}

  .label--new {
    background: var(--vst-color-tag-success-bg);
    border-color: var(--vst-color-tag-success-border);
    color: var(--vst-color-tag-success);
  }

  .label--change {
    background: var(--vst-color-tag-warning-bg);
    border-color: var(--vst-color-tag-warning-border);
    color: var(--vst-color-tag-warning);
  }

  .label--pro {
    background-color: var(--vst-color-tag-important-bg);
    border-color: var(--vst-color-tag-important-border);
    color: var(--vst-color-tag-important);
  }

  .label--fix {
    background: var(--vst-color-tag-info-bg);
    border-color: var(--vst-color-tag-info-border);
    color: var(--vst-color-tag-info);
  }

  .label--wip {
    background: var(--vst-color-tag-important-bg);
    border-color: var(--vst-color-tag-important-border);
    color: var(--vst-color-tag-important);
  }

  .label--issue {
    background: var(--vst-color-tag-danger-bg);
    border-color: var(--vst-color-tag-danger-border);
    color: var(--vst-color-tag-danger);
  }

  .details {
    grid-column: 2;
  }
`;

export const createPrepareRowSidebar = ({ interactionContext }) => {
  const { events } = interactionContext;

  const focusGrid = gridEl => {
    gridEl.tabIndex = 1;
    gridEl.focus();
  };

  return ({ sidebarCellEl, gridEl }) => {
    const span = document.createElement('span');
    let rowIndex = 0;

    sidebarCellEl.appendChild(span);

    events.registerGridEvent(
      sidebarCellEl,
      'grid-touch-tap',
      e => {
        e.preventDefault();
        e.stopPropagation();
        interactionContext.uiStartRowSelection(rowIndex);
        focusGrid(gridEl);
      },
      false,
    );

    events.registerGridEvent(
      sidebarCellEl,
      'grid-touch-select-start',
      () => {
        interactionContext.uiStartRowSelection(rowIndex);
        focusGrid(gridEl);
      },
      false,
    );

    events.registerGridEvent(
      sidebarCellEl,
      'grid-touch-select-enter',
      () => {
        interactionContext.uiExpandRowSelection(rowIndex);
        focusGrid(gridEl);
      },
      false,
    );

    events.registerGridEvent(
      sidebarCellEl,
      'grid-mouse-select-start',
      e => {
        e.preventDefault();
        e.stopPropagation();

        if (interactionContext.shiftSelected && interactionContext.rowsSelected) {
          interactionContext.scrollDisabled = true;
          interactionContext.uiExpandRowSelection(rowIndex);
        } else if (!interactionContext.activeRowSelection(rowIndex)) {
          interactionContext.scrollDisabled = true;
          interactionContext.uiStartRowSelection(rowIndex);
        } else {
          interactionContext.clear();
        }
        focusGrid(gridEl);
      },
      false,
    );

    events.registerGridEvent(
      sidebarCellEl,
      'grid-mouse-select-enter',
      e => {
        e.preventDefault();
        e.stopPropagation();
        interactionContext.uiExpandRowSelection(rowIndex);
        focusGrid(gridEl);
      },
      false,
    );

    events.registerGridEvent(sidebarCellEl, 'grid-mouse-context-menu', e => {
      if (!interactionContext.activeRowSelection(rowIndex)) {
        interactionContext.uiStartRowSelection(rowIndex);
      }

      const { clientX, clientY } = e.detail;
      interactionContext.toggleSelectContextMenu({
        clientX,
        clientY,
        isTouch: false,
        cellEl: sidebarCellEl,
      });

      e.stopPropagation();
    });

    return index => {
      span.innerText = index + 1;
      rowIndex = index;
    };
  };
};

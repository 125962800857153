import { LitElement, html, css } from 'lit';
import { Requester } from '@mixins/vst-core-requester-mixin.js';
import { uniqueId } from 'lodash-es';

import { globalStyles } from '@styles/vst-style-global.css';

import { getText } from '@utils/i18n.js';

import '@components/vst-core-graph-analysis/vst-core-graph-analysis.js';
import './sa-wavelength-rainbow.js';

import { VstAnalysisStore } from '@common/mobx-stores/VstAnalysisStore';
import { vstAnalysisGroupStore } from '@common/mobx-stores/vst-analysis-group.store.js';

class SaSpectrumGraph extends Requester(LitElement) {
  static get properties() {
    return {
      id: {
        type: String,
        reflect: true,
      },
      wavelength: {
        type: Number,
        reflect: true,
        notify: true,
      },
      readOnly: {
        type: Boolean,
        reflect: true,
      },
      examineHidden: {
        type: Boolean,
      },
      isMiniGraph: {
        type: Boolean,
        reflect: true,
      },
      spectrumInfo: {
        type: Object,
      },
      _graphOptions: {
        state: true,
      },
      _analysisStore: {
        state: true,
      },
      graphInstance: {
        type: Object,
      },
    };
  }

  static get styles() {
    return [
      globalStyles,
      css`
        :host {
          display: flex;
          flex: 1;
        }
      `,
    ];
  }

  constructor() {
    super();
    this.readOnly = false;
    this.examineHidden = false;
    this.isMiniGraph = false;
    this._graphOptions = {
      appearance: {
        lines: true,
        points: false,
      },
      baseRange: { min: 0, max: 1 },
      leftRange: { min: 0, max: 1 },
    };

    // Create a store we can use to cause the examine line to do things when the user taps the graph.
    this.id = uniqueId('spectrum-graph-');
    this._analysisStore = new VstAnalysisStore(this.id, vstAnalysisGroupStore);

    // Park the examine line roughly at the edge of the graph.
    this._analysisStore.updateExaminePosition({ closestXPt: 400, xPosition: 400 });

    this.spectrumInfo = {};
  }

  async firstUpdated() {
    this.$dataWorld = this.requestService('dataWorld');

    this.coreGraphEl = this.shadowRoot.querySelector('vst-core-graph');
    await this.coreGraphEl.updateComplete;
    this.graphInstance = this.coreGraphEl.graphInstance;
    this._onSpectrumInfoUpdated();
    this._forceExamineLine(this.wavelength);
  }

  updated(changedProperties) {
    changedProperties.forEach((oldValue, propName) => {
      switch (propName) {
        case 'wavelength':
          this._forceExamineLine(this.wavelength);
          break;
        case 'spectrumInfo':
          this._onSpectrumInfoUpdated();
          break;
        default:
          break;
      }
    });
  }

  /**
   * This gets called whenever spectrumInfo property changes.
   */
  _onSpectrumInfoUpdated() {
    const { spectrumInfo: info } = this;
    const { referenceTrace, liveTrace, columnColor } = info;
    const { coreGraphEl } = this;

    if (coreGraphEl) {
      const format = (name, units) => `${name}${units && units.length ? ` (${units})` : ''}`;
      coreGraphEl.baseColumnLabel = format(
        getText(info.baseName, 'sensormap'),
        getText(info.baseUnits, 'sensormap'),
      ); // TODO: (@joeybladb) this terrible hack needs to go away.
      coreGraphEl.leftColumnLabels = [
        { text: format(getText(info.columnName, 'sensormap'), getText(info.units, 'sensormap')) },
      ];
    }

    const baseRange = { min: info.minWavelength, max: info.maxWavelength };
    const leftRange =
      info.scaleYLarger && info.referenceRange && this.isMiniGraph
        ? info.referenceRange
        : { min: info.minRange, max: info.maxRange };

    this._graphOptions = {
      ...this._graphOptions,
      baseRange,
      leftRange,
    };

    // Extract trace info and apply correctly.
    let newTraces = [];

    // In minigraph mode we display both a live trace in the sensor column color, and a reference trace (from the wavelength chooser).
    if (this.isMiniGraph) {
      if (liveTrace) {
        newTraces = [
          {
            ...liveTrace,
            color: columnColor,
          },
        ];
      }
      if (referenceTrace) {
        newTraces.push(referenceTrace);
      }
    } else if (liveTrace) {
      newTraces = [liveTrace];
    }

    coreGraphEl.rawTracePairs = newTraces;
  }

  _updateWavelength({ detail }) {
    this.wavelength = detail.wavelength;
  }

  get examinePin() {
    const { examinePosition = {}, examineSettings = {} } = this._analysisStore || {};
    return { examinePosition, examineSettings };
  }

  updateExaminePinPosition({ detail: { positionUpdate } }) {
    if (this.isMiniGraph) return;
    this._analysisStore?.updateExaminePosition(positionUpdate);
    this.dispatchEvent(new CustomEvent('wavelength-changed', { detail: positionUpdate.xPosition }));
  }

  updateExaminePinSettings({ detail: { settingsUpdate } }) {
    this._analysisStore?.updateExamineSettings(settingsUpdate);
  }

  _forceExamineLine(wavelength) {
    this._analysisStore.deleteTempSelection();

    const examinePosition = {
      closestXPt: wavelength,
      examineHidden: false,
      isGestureFinished: true,
      isRangeIndexBased: false,
      xPosition: wavelength,
    };
    this._analysisStore.updateExaminePosition(examinePosition);
  }

  render() {
    return html`
      <vst-core-graph
        class="${this.isMiniGraph ? 'mini-graph' : ''}"
        disableAxisTranslate
        disableAxisLabelBtns
        hideGraphActionBtns
        disableMenu
        .options="${this._graphOptions}"
      >
        <vst-core-graph-analysis
          slot="analysis"
          ?readOnly="${this.isMiniGraph}"
          examinePreventClose
          .examinePx="${this.wavelength}"
          .examinePin="${this.examinePin}"
          .examineHidden="${this.examineHidden || this.isMiniGraph}"
          @obsolete-analysis="${this._cleanupCurrentAnalysis}"
          @examine-positioning-changed="${this.updateExaminePinPosition}"
          @examine-settings-changed="${this.updateExaminePinSettings}"
        >
        </vst-core-graph-analysis>
        <sa-wavelength-rainbow .graph=${this.graphInstance} slot="rainbow"> </sa-wavelength-rainbow>
      </vst-core-graph>
    `;
  }
}

customElements.define('sa-spectrum-graph', SaSpectrumGraph);

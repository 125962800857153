import { action, observable, computed, makeObservable } from 'mobx';

/**
 * @classdesc mixin class that imbues a subclass with the ability to persist
 * information about multiple graphs. For example DataMark and Annotation
 * extend this class allowing them to be presented on multiple graphs. Stored
 * information includes graph ID, position, visibility, and collapsed state.
 */
export class MultiGraphAppearance {
  /**
   * If non-zero, indicates that user is presently editing the
   * object's text or other values on that particular graph. This value is not
   * persisted.
   * @type {number}
   */
  editingGraphId = 0;

  /**
   * Construct the MGA superclass instance.
   * @param {Object} params
   * @param {number} params.graphUdmId an initial graph id. This graph will be
   * added to the appearances array.
   * @param {Object[]} params.appearanceInfo array of private data structures.
   * @see MultiGraphAppearance._newAppearance().
   */
  constructor({ appearanceInfo, graphUdmId }) {
    // Rebuild appearance instances from generic objects:
    this.appearanceInfo =
      appearanceInfo?.map(info => MultiGraphAppearance._newAppearance(info)) ?? [];

    // If a non-zero graphUdmId is specified, we'll add an appearance.
    if (graphUdmId !== undefined && graphUdmId > 0) this._createAppearance(graphUdmId);

    makeObservable(this, {
      appearanceInfo: observable.deep,
      editingGraphId: observable,

      mixinUdmExport: computed,

      setShownOnGraph: action,
      setCollapseOnGraph: action,
      setPositionOnGraph: action,
      setEditingOnGraph: action,
    });
  }

  /**
   * @returns {Object} an object suitable for storing in udm.
   * @note gave this a different name than `udmExport` to workaround mobx
   * which can't observe a computed property with the same name in the subclass.
   */
  get mixinUdmExport() {
    return {
      appearanceInfo: this.appearanceInfo.map(info => info.udmExport),
    };
  }

  /**
   * Imports an object from the udm store.
   * @param {Object} newValues an object retrieved from the udm store.
   */
  udmImport(newValues) {
    const { appearanceInfo = null } = newValues;
    if (appearanceInfo) {
      this.appearanceInfo = appearanceInfo.map(info => MultiGraphAppearance._newAppearance(info));
    }
  }

  /**
   * Shows or hides this instance on a specific graph.
   * @param {number} graphId which graph we wish to show or hide on.
   * @param {boolean} [shown] true to show, or false to hide.
   */
  setShownOnGraph(graphId, shown = true) {
    // Fetch an appearance: pass in shown as the `create` parameter, otherwise we don't really need to
    // create an appearance record if we're just going to hide it. In the case it already exists,
    // we can keep the record around.
    let appearance = this._getAppearance(graphId);
    if (shown && !appearance) appearance = this._createAppearance(graphId);
    if (appearance) appearance.isShown = shown;
  }

  /**
   * Returns whether this instance should be shown on a graph
   * @param {number} graphId id of graph
   * @returns { boolean } true if graph should be shown.
   */
  isShownOnGraph(graphId) {
    const appearance = this._getAppearance(graphId);
    return appearance ? appearance.isShown : false;
  }

  /**
   * Returns whether this instance contains a reference to the given graphId,
   * whether it's marked as shown or not. This is NOT the same as
   * `isShownOnGraph()` above.
   * @param {number} graphId udmId of a graph
   * @returns {boolean} true if this instance is associated with the graph.
   */
  containsGraph(graphId) {
    return !!this._getAppearance(graphId);
  }

  /**
   * Sets whether this instance is collapsed or not on a particular graph. In
   * this context `collapsed` means minified, shrunk, temporarily hidden yet
   * still visibly accessible on the graph somehow? This was originally one
   * of the COAs of the data marking feature which was removed for brevity; I've
   * left this here, however, for future expansion.
   * @param {number} graphId id of graph
   * @param {boolean} collapsed true or false.
   */
  setCollapseOnGraph(graphId, collapsed = true) {
    let appearance = this._getAppearance(graphId);
    if (!appearance) appearance = this._createAppearance(graphId);
    appearance.isCollapsed = collapsed;
  }

  /**
   * Returns whether this instance should be collapsed or not.
   * @param {number} graphId
   * @returns {boolean} true or false
   */
  isCollapsedOnGraph(graphId) {
    const appearance = this._getAppearance(graphId);
    return appearance ? appearance.isCollapsed : false;
  }

  /**
   * Set the position of this instance on a specified graph.
   * @param {number} graphId graph id
   * @param {{x: number, y: number} } position position, as {x, y}
   */
  setPositionOnGraph(graphId, position) {
    let appearance = this._getAppearance(graphId);
    if (!appearance) appearance = this._createAppearance(graphId);
    appearance.position = position;
  }

  /**
   * Returns the position for this instance on the given graph.
   * @param {number} graphId a graph id
   * @returns {{x: number, y: number}} position of this instance on the graph.
   */
  getPositionOnGraph(graphId) {
    const appearance = this._getAppearance(graphId);
    return appearance ? appearance.position : { x: -1, y: -1 };
  }

  /**
   * Marks this object as editing on a particular graphId.
   * @param {number} graphId udm Id of the graph that the user is currently
   * editing this object on, or zero if done editing.
   */
  setEditingOnGraph(graphId) {
    this.editingGraphId = graphId;
  }

  /**
   * Get an appearance record for a specified graph.
   * @param {number} graphId identifies the associated graph.
   * @returns { Object? } private class instance, or null if it doesn't exit.
   * @private
   */
  _getAppearance(graphId) {
    return this.appearanceInfo.find(i => i.graphId === graphId);
  }

  /**
   * Create an internal appearance record for a given graphId and add it to the
   * list. Callers must first use `_getAppearence()` to determine if an
   * appearance has already been created for the graph.
   * @param {number} graphId udm id of the graph this instance will live on.
   * @returns { Object } private class instance.
   */
  _createAppearance(graphId) {
    const apperance = MultiGraphAppearance._newAppearance({ graphId });
    this.appearanceInfo.push(apperance);
    return apperance;
  }

  /**
   * Creates a new, internally private Appearance record.
   * @param {Object} info appearance information.
   * @private
   */
  static _newAppearance(info = {}) {
    /**
     * Record for per-graph object appearance.
     */
    class _Appearance {
      constructor(params = {}) {
        this._graphId = params.graphId ?? 0;
        this.isShown = params.isShown ?? true;
        this.isCollapsed = false;
        // FIXME: (@edeposit) eliminate magic number by converting between -1 on
        // backend and null on front end
        this.position = params.position ?? { x: -1, y: -1 };
        makeObservable(this, {
          isShown: observable,
          isCollapsed: observable,
          position: observable,

          graphId: computed,
          udmExport: computed,
        });
      }

      get graphId() {
        return this._graphId;
      }

      /**
       * @returns {Object} suitable for persisting in the back end store.
       */
      get udmExport() {
        return {
          graphId: parseInt(this.graphId),
          isShown: this.isShown,
          isCollapsed: this.isCollapsed,
          position: { ...this.position },
        };
      }
    }

    return new _Appearance(info);
  }
}

import { html, LitElement } from 'lit';
import { when } from 'lit/directives/when.js';
import { MobxReactionUpdate } from '@adobe/lit-mobx/lib/mixin.js';

import { globalStyles } from '@styles/vst-style-global.css.js';
import vstUiWaitStyles from './vst-ui-wait.css.js';

import '@components/vst-ui-spinner/vst-ui-spinner.js';

class VstUiWait extends MobxReactionUpdate(LitElement) {
  static get properties() {
    return {
      elements: {
        type: Array,
      },
      message: {
        type: String,
      },
      fillScreen: {
        type: Boolean,
        reflect: true,
      },
      auxiliaryMeter: {
        type: Object,
      },
    };
  }

  constructor() {
    super();

    this.elements = [];
    this.message = '';
    this.fillScreen = false;
    /**
     * A standalone meter for data collection triggering workflow.
     * @type {import('@common/mobx-stores/Meter.js').Meter}
     */
    this.auxiliaryMeter = null;
  }

  updated(changedProperties) {
    changedProperties.forEach((oldValue, propName) => {
      if (propName === 'message') {
        this._messageChanged();
      }
    });
  }

  _messageChanged() {
    if (this.message !== '') {
      this.classList.add('has-message');
    } else {
      this.classList.remove('has-message');
    }
  }

  static get styles() {
    return [globalStyles, vstUiWaitStyles];
  }

  render() {
    const { auxiliaryMeter: meter } = this;
    return html`
      <div class="background">
        <div class="wrapper">
          <div class="message-wrapper">
            <vst-ui-spinner class="spinner"></vst-ui-spinner>
            <p class="message" id="message">${this.message ?? ''}</p>
          </div>
          ${when(
            meter,
            () => html`<vst-ui-meter
              id="aux-meter"
              isVisible="true"
              .value=${meter.value}
              .units=${meter.units}
              .name=${meter.name}
              .sensorId=${meter.sensorInfo.id}
              .color=${meter.color}
              metersize="medium"
            ></vst-ui-meter>`,
          )}
        </div>
      </div>
    `;
  }
}

customElements.define('vst-ui-wait', VstUiWait);

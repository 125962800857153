// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line no-unused-vars
import { groupBy, uniqueId, pick, omit, isEqual, mapValues } from 'lodash-es';
import { makeScalingModeRangeRestrictor } from '@utils/redux.js';
import { vstAnalysisGroupStore } from '../mobx-stores/vst-analysis-group.store';

export const GRAPH_INIT = 'GRAPH_INIT';
export const ANALYSIS_INIT = 'ANALYSIS_INIT';
export const GRAPH_IS_DRAW_PREDICTIONS_ACTIVE_UPDATE = 'GRAPH_IS_DRAW_PREDICTIONS_ACTIVE_UPDATE';
export const GRAPH_BASE_COLUMN_ID_UPDATE = 'GRAPH_BASE_COLUMN_ID_UPDATE';
export const GRAPH_BASE_UNITS_UPDATE = 'GRAPH_BASE_UNITS_UPDATE';
export const GRAPH_AUTOSCALE_PADDING_UPDATE = 'GRAPH_AUTOSCALE_PADDING_UPDATE';
export const GRAPH_IS_LEGEND_VISIBLE_UPDATE = 'GRAPH_IS_LEGEND_VISIBLE_UPDATE';

export const GRAPH_UDM_ID_UPDATE = 'GRAPH_UDM_ID_UPDATE';
export const GRAPH_OPTIONS_UPDATE = 'GRAPH_OPTIONS_UPDATE';
export const AXIS_SCALING_MODE_UPDATE = 'AXIS_SCALING_MODE_UPDATE';
export const GRAPH_RIGHT_AXIS_ENABLED_UPDATE = 'GRAPH_RIGHT_AXIS_ENABLED_UPDATE';

export const ACCESSIBILITY_SCALE_UPDATE = 'ACCESSIBILITY_SCALE_UPDATE';
export const IS_SESSION_EMPTY_UPDATE = 'IS_SESSION_EMPTY_UPDATE';

export const AXIS_DATA_SET_ID_ADDITION = 'AXIS_DATA_SET_ID_ADDITION';
export const AXIS_DATA_SET_ID_REMOVAL = 'AXIS_DATA_SET_ID_REMOVAL';
export const AXIS_COLUMN_ID_ADDITION = 'AXIS_COLUMN_ID_ADDITION';
export const AXIS_COLUMN_ID_REMOVAL = 'AXIS_COLUMN_ID_REMOVAL';

export const AXIS_COLUMN_IDS_UPDATE = 'AXIS_COLUMN_IDS_UPDATE';
export const AXIS_DATA_SET_IDS_UPDATE = 'AXIS_DATA_SET_IDS_UPDATE';

export const ANNOTATION_ADDITION = 'ANNOTATION_ADDITION';
export const ANNOTATION_UPDATE = 'ANNOTATION_UPDATE';
export const ANNOTATION_REMOVAL = 'ANNOTATION_REMOVAL';

export const POPOVER_ADDITION = 'POPOVER_ADDITION';
export const POPOVER_REMOVAL = 'POPOVER_REMOVAL';

export const ENABLE_REPLAY = 'ENABLE_REPLAY';
export const DISABLE_REPLAY = 'DISABLE_REPLAY';
export const SET_REPLAY_TIMESTOP = 'SET_REPLAY_TIMESTOP';

export const Actor = Object.freeze({
  USER: 'user',
  AUTOMATIC: 'automatic',
});

/**
 * Update graph joins
 *
 * @param {object[]} graphs List of graphs
 */
function updateGraphJoins(graphs) {
  // When a graph's base column changes, update the linked graphs
  const graphsWithBaseColumn = graphs.filter(
    graph => Boolean(graph.baseColumnId) && graph.scalingModes.base === 'automatic_scaling',
  );
  const graphsGroupedByBaseColumnId = groupBy(graphsWithBaseColumn, graph => graph.baseColumnId);
  const graphsIdsToJoin = Object.values(graphsGroupedByBaseColumnId)
    // Find the first group of graphs having more than 1 graph
    .find(graphs => graphs.length > 1)
    // Use the id property
    ?.map(graph => graph.id);

  // Remove all links
  vstAnalysisGroupStore.toggleGraphExamineLinks(false, []);
  // Rebuild links
  if (graphsIdsToJoin) vstAnalysisGroupStore.toggleGraphExamineLinks(true, graphsIdsToJoin);
}

export const initGraph = id => ({
  type: GRAPH_INIT,
  meta: { id },
});

export const initAnalysis = graphId => ({
  type: ANALYSIS_INIT,
  meta: { graphId },
});

export const openDrawPredictions = ({ graphId }) => ({
  type: GRAPH_IS_DRAW_PREDICTIONS_ACTIVE_UPDATE,
  payload: true,
  meta: { id: graphId },
});

export const closeDrawPredictions = ({ graphId }) => ({
  type: GRAPH_IS_DRAW_PREDICTIONS_ACTIVE_UPDATE,
  payload: false,
  meta: { id: graphId },
});

export const calcColumnCreated = columnGroup => (dispatch, getState) => {
  if (columnGroup.calcDependentGroups.length) {
    const dependentGroupIds = columnGroup.calcDependentGroups;

    Object.keys(getState().graphs).forEach(graphId => {
      const graph = getState().graphs[graphId];

      graph.columnGroupIds.left.forEach(groupId => {
        if (dependentGroupIds.includes(groupId)) {
          const groupIds = graph.columnGroupIds.left.filter(
            groupId => !dependentGroupIds.includes(groupId),
          );
          groupIds.push(columnGroup.id);

          dispatch({
            type: AXIS_COLUMN_IDS_UPDATE,
            payload: groupIds,
            meta: { graphId, axis: 'left' },
          });
        }
      });

      // TODO: uncomment code below once we convert baseColumnId to baseColumnGroupId: GA4-1682
      // if (dependentGroupIds.includes(graph.baseColumnGroupId)) {
      //   dispatch({
      //     type: GRAPH_BASE_COLUMN_GROUP_ID_UPDATE,
      //     payload: baseColumnGroupId,
      //     meta: { id: graphId }
      //   });
      // }
    });
  }
};

export const automaticAutoscaleUpdate = (graphId, proposedRanges) => (dispatch, getState) => {
  const graphState = getState().graphs[graphId];
  const ignoreManualMode = false;

  dispatch({
    type: GRAPH_OPTIONS_UPDATE,
    payload: mapValues(
      proposedRanges,
      makeScalingModeRangeRestrictor(graphState, ignoreManualMode),
    ),
    meta: { id: graphId, actor: Actor.AUTOMATIC },
  });
};

export const axisScalingModeUpdate = (graphId, axis, mode) => (dispatch, getState) => {
  dispatch({
    type: AXIS_SCALING_MODE_UPDATE,
    payload: mode,
    meta: {
      graphId,
      axis,
    },
  });

  const rangeProp = `${axis}Range`; // i.e. 'baseRange', 'leftRange', etc.
  const graph = getState().graphs[graphId];
  if (mode === 'show_zero_scaling' && rangeProp && graph) {
    const currentRange = graph.options[rangeProp];
    dispatch(automaticAutoscaleUpdate(graphId, { [rangeProp]: currentRange })); // autoscale axis
  }

  updateGraphJoins(Object.values(getState().graphs));
};

const priorGraphScaling = {};

export const enableReplay = () => (dispatch, getState) => {
  dispatch({
    type: ENABLE_REPLAY,
  });
  const { graphs } = getState();
  const graphIds = Object.keys(graphs);
  graphIds.forEach(graphId => {
    priorGraphScaling[graphId] = {
      left: graphs[graphId].scalingModes.left,
      right: graphs[graphId].scalingModes.right,
      base: graphs[graphId].scalingModes.base,
    };
    axisScalingModeUpdate(graphId, 'base', 'manual_scaling')(dispatch, getState);
    axisScalingModeUpdate(graphId, 'left', 'manual_scaling')(dispatch, getState);
    axisScalingModeUpdate(graphId, 'right', 'manual_scaling')(dispatch, getState);
  });
};

export const disableReplay = () => (dispatch, getState) => {
  dispatch({
    type: DISABLE_REPLAY,
  });
  const graphIds = Object.keys(priorGraphScaling);
  graphIds.forEach(graphId => {
    axisScalingModeUpdate(graphId, 'base', priorGraphScaling[graphId].base)(dispatch, getState);
    axisScalingModeUpdate(graphId, 'left', priorGraphScaling[graphId].left)(dispatch, getState);
    axisScalingModeUpdate(graphId, 'right', priorGraphScaling[graphId].right)(dispatch, getState);
  });
};

export const setReplayTimestamp = timestop => ({
  type: SET_REPLAY_TIMESTOP,
  payload: timestop,
});

export const updateGraphEntityOptions =
  ({ options, graphId }, _uniqueId = uniqueId) =>
  dispatch => {
    /*
  const p2cIfExists = (entityAxis, pos) => (pos && entityAxis && entityAxis.p2c(pos)) || pos;
  We used to use this to convert to canvas pixels, but this really introduces a race condition in ChartJS where if the chart is not fully setup, but p2c techniaclly exists, we get erroneous x/y positions
  now we just ship down the raw positions and `p2c` at the moment of need
  */
    const annotations = options.annotations || [];

    annotations.forEach(annotation => {
      const id = _uniqueId('annotation-');
      dispatch({
        type: ANNOTATION_ADDITION,
        payload: omit(
          {
            ...annotation,
            udmId: annotation.annotationId,
            id,
          },
          'annotationId',
        ),
        meta: { id, graphId },
      });
    });
  };

export const updateGraphBaseColumnId = (id, baseColumnId) => (dispatch, getState) => {
  dispatch({
    type: GRAPH_BASE_COLUMN_ID_UPDATE,
    payload: baseColumnId,
    meta: { id },
  });

  updateGraphJoins(Object.values(getState().graphs));
};

export const updateGraphBaseUnits = (id, baseUnits) => ({
  type: GRAPH_BASE_UNITS_UPDATE,
  payload: baseUnits,
  meta: { id },
});

export const updateGraphAutoscalePadding = padding => ({
  type: GRAPH_AUTOSCALE_PADDING_UPDATE,
  payload: padding,
});

export const updateGraphIsLegendVisible = (graphId, isLegendVisible) => ({
  type: GRAPH_IS_LEGEND_VISIBLE_UPDATE,
  payload: isLegendVisible,
  meta: { graphId },
});

// prettier-ignore
export const updateGraphOptions = (id, updates, actor = Actor.AUTOMATIC) => (dispatch, getState) => {
  if (actor === Actor.AUTOMATIC && (updates.baseRange || updates.leftRange || updates.rightRange)) {
    const graphState = getState().graphs[id];
    const {
      baseRange = graphState.options.baseRange,
      leftRange = graphState.options.leftRange,
    } = updates;
    const proposedRanges = mapValues(
      { baseRange, leftRange },
      makeScalingModeRangeRestrictor(graphState, false),
    );
    // eslint-disable-next-line no-param-reassign
    updates = { ...updates, ...proposedRanges };
  }

  dispatch({
    type: GRAPH_OPTIONS_UPDATE,
    payload: updates,
    meta: {
      id: updates.id || id,
      actor,
    },
  });
};

export const autoUpdateBaseRange = graphsBaseRangeMax => (dispatch, getState) => {
  const graphIds = ['graph_1', 'graph_2', 'graph_3'];

  graphIds.forEach((id, i) => {
    const graphState = getState().graphs[id];
    const currentBaseMax = graphState.options.baseRange.max;
    const newBaseRangeMax = graphsBaseRangeMax[i];
    if (newBaseRangeMax !== currentBaseMax) {
      dispatch(updateGraphOptions(id, { baseRange: { max: graphsBaseRangeMax[i] } }));
    }
  });
};

export const userAutoscaleUpdate = (graphId, proposedRanges) => (dispatch, getState) => {
  const graphState = getState().graphs[graphId];
  const ignoreManualMode = true;

  dispatch({
    type: GRAPH_OPTIONS_UPDATE,
    payload: mapValues(
      proposedRanges,
      makeScalingModeRangeRestrictor(graphState, ignoreManualMode),
    ),
    meta: { id: graphId, actor: Actor.USER },
  });
};

export const updateGraphUdmId = (graphId, udmId) => ({
  type: GRAPH_UDM_ID_UPDATE,
  payload: udmId,
  meta: { graphId },
});

export const graphRightAxisEnabledUpdate = (graphId, enabled) => ({
  type: GRAPH_RIGHT_AXIS_ENABLED_UPDATE,
  payload: enabled,
  meta: { graphId },
});

export const addColumnId = (columnId, graphId, axis) => ({
  type: AXIS_COLUMN_ID_ADDITION,
  payload: { columnId },
  meta: { columnId, graphId, axis },
});

export const removeColumnId = (columnId, graphId, axis) => ({
  type: AXIS_COLUMN_ID_REMOVAL,
  meta: { columnId, graphId, axis },
});

export const updateColumnIds = (columnIds, graphId, axis) => ({
  type: AXIS_COLUMN_IDS_UPDATE,
  payload: columnIds,
  meta: { graphId, axis },
});

export const addAnnotation =
  ({ props = {}, graphId }, _uniqueId = uniqueId) =>
  dispatch => {
    const id = _uniqueId('annotation-');
    dispatch({
      type: ANNOTATION_ADDITION,
      payload: {
        ...props,
        id,
      },
      meta: { id, graphId },
    });
  };

export const removeAnnotation = id => (dispatch, getState) => {
  const { analysis } = getState();

  Object.keys(analysis).forEach(graphId =>
    dispatch({
      type: ANNOTATION_REMOVAL,
      meta: { id, graphId },
    }),
  );
};

export const updateAnnotation =
  ({ id, updates }) =>
  (dispatch, getState) => {
    const { annotations } = getState();
    const annotation = annotations[id] || {};

    if (annotation.annotationText && !updates.annotationText) {
      dispatch(removeAnnotation(id)); // if all text is removed, delete the annotation
    } else {
      dispatch({
        type: ANNOTATION_UPDATE,
        payload: { ...updates },
        meta: { id },
      });
    }
  };

export const cleanUpAnalysis = graphId => (dispatch, getState) => {
  const {
    analysis: { [graphId]: analysis },
  } = getState();

  if (!analysis) {
    return;
  }

  analysis.annotations.forEach(id =>
    dispatch({
      type: ANNOTATION_REMOVAL,
      meta: { id, graphId },
    }),
  );
};

export const updateAccessibilityScale = (scale = 1) => ({
  type: ACCESSIBILITY_SCALE_UPDATE,
  payload: scale,
});

export const updateIsSessionEmpty = (isEmpty = true) => ({
  type: IS_SESSION_EMPTY_UPDATE,
  payload: isEmpty,
});

export const addPopover = id => ({
  type: POPOVER_ADDITION,
  meta: { id },
});

export const removePopover = id => ({
  type: POPOVER_REMOVAL,
  meta: { id },
});

import { css } from 'lit';
import { button } from '../styles/index.js';

export default css`
  :host {
    --y-value-flag-height: 1.75rem;
    /*  this value gets accessed by core-graph-analysis.js while determining if the y-point flags overlap each other */
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
  }

  :host(.hidden) {
    display: none;
  }

  .analysis-wrapper {
    position: absolute;
    width: 100%;
    height: 100%;
    user-select: text;
    --vst-outline-offset: 0px;
  }

  .analysis-wrapper:focus {
    border-radius: var(--vst-radius);
  }

  .analysis-wrapper:not(:focus-visible) {
    border-radius: 0;
  }

  .data-mark-wrapper,
  .selection-wrapper,
  .annotation-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    pointer-events: none;
  }

  .data-mark-wrapper {
    overflow: hidden;
  }

  vst-ui-draggable {
    z-index: var(--vst-z-infobox);
  }

  /* clip-path is incompatible with $capture service */
  /* TODO: make everything draw to the canvas and drop the $capture service */
  :host(:not([readonly])) #examine_selection_wrapper {
    clip-path: polygon(0 0, 0 100vh, 100% 100vh, 100% 0);
  }

  :host(:not([readonly])) .annotation-wrapper {
    clip-path: polygon(0 0, 0 100%, 100% 100%, 100% 0);
  }

  vst-ui-draggable,
  vst-ui-graph-annotation {
    pointer-events: all;
  }
  vst-ui-data-mark {
    cursor: pointer;
  }

  #manual-fit-handles button {
    background: transparent;
    border: 1px solid transparent;
    height: 30px;
    margin: 8px;
    position: absolute;
    transform: translate(-23px, -23px) /*rotate(45deg)*/;
    width: 30px;
    z-index: calc(var(--vst-z-selection) + 1);

    &:nth-child(1),
    &:nth-child(3) {
      cursor: move;
    }

    &:nth-child(2) {
      cursor: move;
    }
  }
  #manual-fit-handles button svg {
    left: 0;
    pointer-events: none;
    position: absolute;
    top: 0;
  }
  #manual-fit-handles button svg:nth-child(1) {
    height: 53px;
    transform: translate(-13px, -13px);
    width: 53px;
  }
  #manual-fit-handles button svg:nth-child(2) {
    height: 20px;
    transform: translate(4px, 4px);
    width: 20px;
  }

  .graph-examine-wrapper.is-tracking .graph-examine,
  .graph-examine-wrapper.is-tracking .point-highlight {
    transition: none;
  }

  .graph-examine {
    position: absolute;
    height: 100%;
    padding: 0 0.875rem;
    cursor: ew-resize;
    transition: left 200ms var(--transition-easing);
    z-index: calc(var(--vst-z-selection) + 1);
  }
  .graph-examine__pin {
    position: relative;
    width: 1px;
    height: 100%;
    background: var(--vst-color-fg-primary);
  }

  .graph-examine__handle vst-ui-icon {
    width: 0.375rem;
    --vst-ui-icon-color: var(--vst-color-fg-tertiary);
  }

  .graph-examine__handle {
    background-color: var(--vst-color-examine-handle-bg);
    border: 1px solid var(--vst-color-examine-handle-bg);
  }

  .graph-examine__handle--flip-right {
    transform: translateX(0);
  }
  .graph-examine__handle--flip-left {
    transform: translateX(calc(-100% - 1px));
  }
  .graph-examine__handle[read-only] {
    display: block;
  }
  .graph-examine__handle[read-only] vst-ui-icon {
    display: none;
  }

  ${button(css`.graph-examine__delete`, 'cancel')}
  .graph-examine__delete {
    width: 2rem;
    height: 2rem;
    position: absolute;
    z-index: calc(var(--vst-z-selection) + 1);
    top: 0;
    left: 0.875rem;
    transition: var(--transition-faster);
  }
  .graph-examine__delete--flip-left {
    transform: translateX(-100%);
  }

  .point-overflow-window {
    list-style: none;
    margin: 0;
    padding: 0;
    position: absolute;
    top: 2rem;
    left: 1.05rem;
    max-block-size: calc(100% - 2rem);
    font-size: 0.75rem;
    white-space: nowrap;
    background: rgba(255, 255, 255, 0.95);
    border-radius: var(--vst-radius);
    overflow-x: hidden;
    overflow-y: auto;
  }
  .point-overflow-window--tangent {
    left: 2.175rem;
  }
  .point-overflow-window__y-value {
    padding: 0.15em 0.5em;
  }

  .point-highlight {
    position: absolute;
    z-index: calc(var(--vst-z-selection) - 1);
    width: 0.625rem;
    height: 0.625rem;
    border-radius: 100%;
    border: 0.1875rem solid;
    background: #000;
    box-shadow: 0px 0px 0px 2px var(--vst-color-bg-graph);
    transform: translate(-50%, -50%);
    transition: all 100ms var(--transition-easing);
  }
  .point-highlight__y-value {
    display: block;
    position: absolute;
    height: var(--y-value-flag-height, 1.75rem);
    z-index: var(--vst-z-selection);
    font-size: 0.75rem;
    padding: 0.4em 0.5em;
    white-space: nowrap;
    background: var(--bg);
    border-radius: var(--vst-radius);
    transform: translate(0.5625rem, -0.75rem);
    transition: all 250ms var(--transition-easing);
    border: 1px solid var(--vst-color-bg-primary);
  }
  .point-highlight--tangent .point-highlight__y-value {
    transform: translate(1.6875rem, -1.25rem);
  }

  .flip-y-flag-left .point-highlight__y-value {
    transform: translate(calc(-100% - 0.5625rem), -0.625rem);
  }

  .flip-y-flag-left .point-highlight--tangent .point-highlight__y-value {
    transform: translate(calc(-100% - 1.375rem), -1.25rem);
  }

  .flip-y-flag-left .point-overflow-window {
    transform: translateX(calc(-100% - 1.05rem));
  }

  .flip-y-flag-left .point-overflow-window--tangent {
    transform: translateX(calc(-100% - 3.17rem));
  }

  .data-mark,
  .annotation-hairline {
    position: absolute;
    height: 100%;
    z-index: calc(var(--vst-z-selection) + 1);
  }
  svg#hairlines {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
  }
  svg#hairlines line {
    stroke: var(--vst-color-fg-tertiary);
  }
`;

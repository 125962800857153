import { css } from 'lit';

export default css`
  :host {
    display: block;
    height: var(--chrome-menubar-height, 32px);
    width: 100vw;
    background-color: var(--vst-color-bg-tertiary);
    border-bottom: 1px solid var(--vst-color-bg-primary);
  }

  .menubar {
    display: flex;
    align-items: center;
    height: var(--chrome-menubar-height, 32px);
    position: relative;
  }
  .menubar__title {
    font-size: 12px;
    font-weight: normal;
    text-align: center;
    margin: 0;
    padding: 0;
    flex: 1;
    align-content: center;
    justify-content: center;
  }
  .menubar__btn-wrapper {
    display: flex;
    width: calc(var(--chrome-menubar-height, 32px) * 3);
    position: absolute;
    right: 0;
    top: 0;
  }
  .menubar__btn {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0;
    width: var(--chrome-menubar-height, 32px);
    height: var(--chrome-menubar-height, 32px);
    background-color: transparent;
    line-height: 0;
    outline: 0;
  }
  .menubar__btn:hover {
    background-color: var(--vst-color-bg-secondary);
  }
  .menubar__btn-icon {
    width: 10px;
    height: 10px;
  }
  .menubar--draggable {
    -webkit-app-region: drag;
  }
  .menubar--draggable button {
    -webkit-app-region: no-drag;
  }
`;

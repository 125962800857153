import { css } from 'lit';

export default css`
  :host {
    background: var(--vst-color-bg);
    padding: var(--vst-space-xs);
    border-radius: var(--vst-radius);
    line-height: 1;
    font-size: var(--vst-font-size-xs);
    display: flex;
    max-block-size: 30vh;
    max-inline-size: 30vh;
    overflow: auto;
  }

  .legend {
    display: grid;
    grid-gap: var(--vst-space-xs);
    align-items: center;
    cursor: grab;
    grid-auto-rows: var(--vst-icon-size-s);
  }
  .legend:active {
    cursor: grabbing;
  }
  .legend__color-swatch {
    inline-size: var(--vst-icon-size-xs);
    block-size: var(--vst-icon-size-xs);
    justify-self: center;
  }
  .legend__ds-label {
    grid-column: 1 / -1;
    color: var(--vst-color-fg-secondary);
  }
  .legend__ds-hairline {
    background: var(--vst-color-fg-secondary);
    width: 0.0625rem;
    height: 100%;
    justify-self: center;
  }
  .legend__group-label,
  .legend__ds-label {
    max-inline-size: 15rem;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

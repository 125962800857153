export const prepareRow = ({ prepareGridCell, prepareRowSidebar, gridEl, length }) => {
  const rowEl = document.createElement('div');
  rowEl.className = 'grid_body_row';
  const sidebarEl = document.createElement('div');
  sidebarEl.className = 'grid_body_sidebar_row';
  const sidebarCellEl = document.createElement('p');
  sidebarCellEl.className = 'grid_body_sidebar_cell';
  sidebarEl.appendChild(sidebarCellEl);

  const cells = [];

  const createCell = () => {
    const cellEl = document.createElement('div');
    cellEl.classList.add('grid_body_cell');
    // Firefox fires these events at the cell and doesn't bubble them up to the document, so we have to grab the data and push it up ourselves
    cellEl.addEventListener('paste', event => {
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line no-unused-vars
      const clipboard = event.clipboardData.getData('text');
      document.dispatchEvent(
        new CustomEvent('promoted-paste', {
          detail: event.clipboardData.getData('text'),
        }),
      );
    });
    cellEl.addEventListener('copy', () => {
      document.dispatchEvent(new CustomEvent('promoted-copy'));
    });
    cellEl.addEventListener('cut', () => {
      document.dispatchEvent(new CustomEvent('promoted-cut'));
    });

    const update = prepareGridCell({ gridEl, cellEl });

    return {
      cellEl,
      update,
    };
  };

  for (let i = 0; i < length; i++) {
    const newCell = createCell();
    cells.push(newCell);
    const { cellEl } = newCell;
    rowEl.appendChild(cellEl);
  }

  const appendCell = accumulator => {
    const newCell = createCell();
    cells.push(newCell);

    const { cellEl } = newCell;

    accumulator.queueRender(() => {
      rowEl.appendChild(cellEl);
    });

    return newCell;
  };

  const insertCell = (accumulator, cellIndex) => {
    if (cellIndex >= cells.length) {
      return;
    }

    const newCell = createCell();
    const nextCell = cells[cellIndex];
    const { cellEl } = newCell;
    const { cellEl: nextCellEl } = nextCell;

    cells.splice(cellIndex, 0, newCell);

    accumulator.queueRender(() => {
      rowEl.insertBefore(cellEl, nextCellEl);
    });

    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line consistent-return
    return newCell;
  };

  const removeCell = (accumulator, cellIndex) => {
    const { cellEl } = cells.splice(cellIndex, 1)[0];

    accumulator.queueRender(() => {
      cellEl.remove();
    });
  };

  // this is 'updateRow' from createRowCache.js
  const updateCell = (columnData, rowIndex, cellIndex) => {
    const { update } = cells[cellIndex];
    update(columnData, rowIndex);
  };

  const sidebarUpdate = prepareRowSidebar({ gridEl, sidebarCellEl });

  const updateRow = (columnDataList, rowIndex) => {
    sidebarUpdate(rowIndex);

    for (let cellIndex = 0; cellIndex < cells.length; cellIndex++) {
      const { update } = cells[cellIndex];
      const columnData = columnDataList[cellIndex];
      update(columnData, rowIndex);
    }
  };

  const getCell = index => {
    return cells[index];
  };

  return {
    get length() {
      return cells.length;
    },

    appendCell,
    insertCell,
    removeCell,

    updateRow,
    updateCell,

    getCell,

    rowEl,
    sidebarCellEl,
    sidebarEl,
  };
};

import { LitElement, html /* css */ } from 'lit';
import {
  chromeWindowAuto,
  chromeWindowClose,
  chromeWindowMinimize,
  chromeWindowMaximize,
} from '@components/vst-ui-icon/index.js';

import { getText } from '@utils/i18n.js';

import { globalStyles } from '@styles/vst-style-global.css.js';
import vstUiChromebarStyles from './vst-ui-chromebar.css.js';

import '@components/vst-ui-icon/vst-ui-icon.js';

export class VstUiChromebar extends LitElement {
  static get properties() {
    return {
      appName: {
        type: String,
      },
      isMaximized: {
        type: Boolean,
      },
    };
  }

  constructor() {
    super();
    this.appName = document.title;
    this.isMaximized = false;
    this.tapCount = 0;
  }

  // eslint-disable-next-line class-methods-use-this
  closeClicked() {
    document.dispatchEvent(new CustomEvent('close-app', { bubbles: true, composed: true }));
  }

  // eslint-disable-next-line class-methods-use-this
  minimizeClicked() {
    const chromeWindow = window.chrome.app.window.current();
    chromeWindow.minimize();
  }

  toggleMaximizeClicked() {
    const chromeWindow = window.chrome.app.window.current();
    if (chromeWindow.isMaximized()) {
      chromeWindow.restore();
      this.isMaximized = false;
    } else {
      chromeWindow.maximize();
      this.isMaximized = true;
    }
  }

  titleBarClicked() {
    this.tapCount++;
    if (this.tapCount === 1) {
      setTimeout(() => {
        if (this.tapCount > 1) {
          this.toggleMaximizeClicked();
        }
        this.tapCount = 0;
      }, 250);
    }
  }

  static get styles() {
    return [globalStyles, vstUiChromebarStyles];
  }

  render() {
    return html`
      <header
        class="menubar menubar--draggable"
        @keyup="${e => (e.keyCode === 13 ? this.titleBarClicked : '')}"
        @click="${this.titleBarClicked}"
      >
        <strong class="menubar__title">${this.appName}</strong>
        <div class="menubar__btn-wrapper">
          <button
            class="menubar__btn"
            @click="${this.minimizeClicked}"
            aria-label="${getText('Minimize Application')}"
          >
            <vst-ui-icon
              class="menubar__btn-icon"
              color="#5a5a5a"
              .icon="${chromeWindowMinimize}"
            ></vst-ui-icon>
          </button>
          <button
            class="menubar__btn"
            @click="${this.toggleMaximizeClicked}"
            aria-label="${getText('Maximize Application')}"
          >
            <vst-ui-icon
              class="menubar__btn-icon"
              color="#5a5a5a"
              .icon="${this.isMaximized ? chromeWindowAuto : chromeWindowMaximize}"
            ></vst-ui-icon>
          </button>
          <button
            class="menubar__btn"
            @click="${this.closeClicked}"
            aria-label="${getText('Close Application')}"
          >
            <vst-ui-icon
              class="menubar__btn-icon"
              color="#5a5a5a"
              .icon="${chromeWindowClose}"
            ></vst-ui-icon>
          </button>
        </div>
      </header>
    `;
  }
}

customElements.define('vst-ui-chromebar', VstUiChromebar);

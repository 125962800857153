import { LitElement, html } from 'lit';

import { globalStyles } from '@styles/vst-style-global.css.js';

import vstCoreMessageBoxStyles from './vst-core-message-box.css.js';

const _computeClass = button => `btn ${button.style}`;

class VstCoreMessageBox extends LitElement {
  static get properties() {
    return {
      view: {
        type: Object,
      },
    };
  }

  constructor() {
    super();

    this.view = {
      title: '',
      body: '',
      bodyAsHTML: false,
      buttons: [
        {
          id: '',
          message: '',
          style: '',
        },
      ],
    };
  }

  firstUpdated() {
    setTimeout(() => {
      if (this.view.bodyAsHTML) {
        const bodyContext = this.shadowRoot.querySelector('#body_content');
        bodyContext.innerHTML = this.view.body;
      }
    });
  }

  buttonClicked(e) {
    const { id } = e.target;
    this.dispatchEvent(new CustomEvent('button-clicked', { detail: { msg: id } }));
  }

  static get styles() {
    return [globalStyles, vstCoreMessageBoxStyles];
  }

  render() {
    return html`
      <p class="body-content" id="body_content">${this.view.body}</p>
      <div class="btn-container">
        ${this.view.buttons.map(
          button =>
            html`
              <button
                id="${button.id}"
                @click="${this.buttonClicked}"
                class="${_computeClass(button)}"
              >
                ${button.message}
              </button>
            `,
        )}
      </div>
    `;
  }
}

customElements.define('vst-core-message-box', VstCoreMessageBox);

import { priv } from './priv.js';

export const createSelectionApi = ({ interactionContext, dataSetGrid }) => {
  const getSelection = () => interactionContext.getSelection();

  const select = ({
    all = null,
    rowIndex = null,
    endRowIndex = null,
    column = null,
    endColumn = null,
    dataSet = null,
    endDataSet = null,
    scrollTo = true,
  } = {}) => {
    let scrollColumn = null;
    let scrollRowIndex = null;

    if (all) {
      interactionContext.selectAll();
    } else if (dataSet !== null || endDataSet !== null) {
      if (endDataSet === null) {
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line no-param-reassign
        endDataSet = dataSet;
      } else if (dataSet === null) {
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line no-param-reassign
        dataSet = endDataSet;
      }

      interactionContext.startDataSetSelection(dataSet);
      interactionContext.expandDataSetSelection(endDataSet);

      scrollColumn = dataSet[priv].columns.findByIndex(0);
    } else {
      if (rowIndex !== null) {
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line no-param-reassign
        endRowIndex = endRowIndex !== null ? endRowIndex : rowIndex;
      } else if (endRowIndex !== null) {
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line no-param-reassign
        rowIndex = endRowIndex;
      }

      if (column !== null) {
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line no-param-reassign
        endColumn = endColumn !== null ? endColumn : column;
      } else if (endColumn !== null) {
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line no-param-reassign
        column = endColumn;
      }

      if (rowIndex !== null) {
        scrollRowIndex = rowIndex < endRowIndex ? rowIndex : endRowIndex;
      }

      if (column !== null) {
        scrollColumn = column.gridPosition < endColumn.gridPosition ? column : endColumn;
      }

      if (rowIndex !== null && column !== null) {
        interactionContext.startCellSelection(column, rowIndex);
        interactionContext.expandCellSelection(endColumn, endRowIndex);
      } else if (rowIndex !== null) {
        interactionContext.startRowSelection(rowIndex);
        interactionContext.expandRowSelection(endRowIndex);
      } else if (column !== null) {
        interactionContext.startColumnSelection(column);
        interactionContext.expandColumnSelection(endColumn);
      }
    }

    if ((scrollRowIndex !== null || scrollColumn !== null) && scrollTo) {
      dataSetGrid[priv].grid.scrollTo(scrollColumn, scrollRowIndex);
    }
  };

  const scrollToRow = (rowIndex, onlyOutOfFocus) => {
    dataSetGrid[priv].grid.scrollTo(null, rowIndex, onlyOutOfFocus);
  };

  return {
    getSelection,
    select,
    scrollToRow,
  };
};

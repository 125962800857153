import { set, get, merge, immutableDelete } from '@utils/dot-prop-immutable-es.js';
import {
  ANALYSIS_INIT,
  ACCESSIBILITY_SCALE_UPDATE,
  IS_SESSION_EMPTY_UPDATE,
  POPOVER_ADDITION,
  POPOVER_REMOVAL,
  ANNOTATION_ADDITION,
  ANNOTATION_UPDATE,
  ANNOTATION_REMOVAL,
  ENABLE_REPLAY,
  DISABLE_REPLAY,
  SET_REPLAY_TIMESTOP,
} from './actions';

export { graphs } from './reducers/graphs';

const defaultGraphAnalysis = {
  annotations: [],
  examinePosition: {},
  examineSettings: {},
};

// immutable array push that can handle uninitialized array
const immutableArrayPush = (obj, arrayParentPath, arrayName, item) =>
  set(obj, arrayParentPath, parent => {
    const { [arrayName]: array = [] } = parent;
    return {
      ...parent,
      [arrayName]: [...array, `${item}`],
    };
  });

// immutably deletes first element in array that causes testFunc to return true when passed in
const immutableArrayDelete = (baseObj, arrayPath, testFunc) => {
  const array = get(baseObj, arrayPath) || [];
  const index = array.findIndex(testFunc);
  return index === -1 ? baseObj : immutableDelete(baseObj, `${arrayPath}.${index}`);
};

export const analysis = (state = {}, action) => {
  const { meta: { graphId } = {} } = action;

  switch (action.type) {
    case ANALYSIS_INIT:
      return merge(state, graphId, { ...defaultGraphAnalysis });
    case ANNOTATION_ADDITION:
      return immutableArrayPush(state, graphId, 'annotations', `${action.meta.id}`);
    case ANNOTATION_REMOVAL:
      return immutableArrayDelete(
        state,
        `${graphId}.annotations`,
        item => item === `${action.meta.id}`,
      );
    default:
      return state;
  }
};

export const annotations = (state = {}, action) => {
  const { meta: { id } = {} } = action;

  switch (action.type) {
    case ANNOTATION_ADDITION:
    case ANNOTATION_UPDATE:
      return set(state, id, { ...action.payload });
    case ANNOTATION_REMOVAL:
      return immutableDelete(state, action.meta.id);
    default:
      return state;
  }
};

export const accessibility = (state = { scale: 1 }, action) => {
  switch (action.type) {
    case ACCESSIBILITY_SCALE_UPDATE:
      return {
        ...state,
        scale: action.payload,
      };
    default:
      return state;
  }
};

export const isSessionEmpty = (state = true, action) => {
  switch (action.type) {
    case IS_SESSION_EMPTY_UPDATE:
      return action.payload;
    default:
      return state;
  }
};

export const popovers = (state = [], action) => {
  switch (action.type) {
    case POPOVER_ADDITION:
      return [...state, action.meta.id];
    case POPOVER_REMOVAL:
      return state.filter(id => id !== action.meta.id);
    default:
      return state;
  }
};

export const links = (state = {}, action) => {
  switch (action.type) {
    case ENABLE_REPLAY:
      return set(state, `replayMode`, true);
    case DISABLE_REPLAY:
      return set(state, `replayMode`, false);
    case SET_REPLAY_TIMESTOP:
      return set(state, `replayTimestamp`, action.payload);
    default:
      return state;
  }
};

import { LitElement, html, css } from 'lit';
import { throttle, isEqual, mapValues } from 'lodash-es';
import { connect } from 'pwa-helpers';
import { MobxReactionUpdate } from '@adobe/lit-mobx/lib/mixin.js';

import { ObservableProperties } from '@mixins/vst-observable-properties-mixin.js';
import { Requester } from '@mixins/vst-core-requester-mixin.js';
import { formatter } from '@utils/formatter.js';
import { EventBinder } from '@utils/EventBinder.js';

import {
  makeAnalysisForSelectionsMethod,
  initAnalysisMiddleware,
} from '@utils/graphSelectionAnalysis.js';
import { getText } from '@utils/i18n.js';
import { globalStyles } from '@styles/vst-style-global.css.js';

import { vstCurvefitStore } from '@common/mobx-stores/vst-curvefit.store.js';
import { vstAuthStore } from '@stores/vst-auth.store.js';
import { close as iconClose } from '@components/vst-ui-icon/index.js';
import { Actor } from '@common/stores/actions.js';
import { graphStore } from '../stores/graph.store.js';

import '@components/vst-style-tooltip/vst-style-tooltip.js';

/* eslint-disable import/named */
import {
  openDrawPredictions,
  closeDrawPredictions,
  updateGraphEntityOptions,
  updateGraphBaseColumnId,
  updateGraphUdmId,
  updateGraphOptions,
  updateGraphBaseUnits,
  automaticAutoscaleUpdate,
  userAutoscaleUpdate,
  axisScalingModeUpdate,
  graphRightAxisEnabledUpdate,
  addColumnId,
  removeColumnId,
  updateColumnIds,
  cleanUpAnalysis,
} from '../redux/actions.js';
/* eslint-enable import/named */

import { store } from '../redux/store.js';

import '../sa-wavelength-rainbow.js';
import '../sa-spectrum-graph.js';
import '@components/vst-core-graph/vst-core-graph.js';
import '@components/vst-core-graph-analysis/vst-core-graph-analysis.js';
import '@components/vst-core-graph-legend/vst-core-graph-legend.js';
import '@components/vst-core-graph-plot-manager/vst-core-graph-plot-manager.js';
import '@components/vst-core-graph-selection/vst-core-graph-selection.js';
import '@components/vst-ui-graph-annotation/vst-ui-graph-annotation';
import '@components/vst-ui-graph-selection/vst-ui-graph-selection.js';
import '@components/vst-core-infobox/vst-core-infobox.js';
import '@components/vst-ui-draggable/vst-ui-draggable.js';

const MOUSE_MOVE_DELTA = 6;

export class SaGraph extends connect(store)(
  Requester(MobxReactionUpdate(ObservableProperties(LitElement))),
) {
  static get properties() {
    return {
      _isSessionEmpty: { state: true },
      _rightColumnIds: { state: true },
      advancedModeEnabled: { type: Boolean },
      id: {
        type: String,
      },
      graph: {
        type: Object,
      },
      selectionsById: {
        type: Object,
      },
      selectionsWithData: {
        type: Object,
      },
      accessibilityScale: {
        type: Number,
      },
      importedGraphState: {
        type: Object,
      },
      hidden: {
        type: Boolean,
        reflect: true,
      },
      visibleTraces: {
        type: Array,
      },
      readOnly: {
        type: Boolean,
        reflect: true,
      },
      spectrumInfo: {
        type: Object,
      },
      useRightAxis: { type: Boolean },
      graphInstance: {
        type: Object,
      },
    };
  }

  static get observableProperties() {
    return {
      isMiniGraphVisible: graphStore,
      isMiniGraphDisabled: graphStore,
      isLegendVisible: graphStore,
    };
  }

  static get styles() {
    // TODO: restore former vst-sa-graph.scss styles for the mini graph
    return [
      globalStyles,
      css`
        .legend {
          display: flex;
          background: var(--vst-color-bg);
        }

        .legend vst-core-graph-legend {
          flex-shrink: 0;
        }

        #legend_close_btn {
          align-self: flex-start;
        }

        #mini_graph_close_btn {
          position: absolute;
          top: 0;
          right: 0;
        }

        #mini_graph_wrapper {
          left: 0;
          right: auto;
        }
      `,
    ];
  }

  constructor() {
    super();
    this._isSessionEmpty = true;
    this._rightColumnIds = [];
    this.advancedModeEnabled = false;
    this.graph = {};
    this.selectionsById = {};
    this.accessibilityScale = 1; // TODO: is '1' correct?
    this.importedGraphState = {};
    this.visibleTraces = [];
    this.leftTraces = [];
    this.useRightAxis = false;
  }

  stateChanged(state) {
    const oldGraphBaseId = (this.graph || {}).baseColumnId;
    this._isSessionEmpty = state.isSessionEmpty;
    this.graph = state.graphs[this.id];
    if (this.graph?.rightAxisEnabled) {
      this._rightColumnIds = this?.graph?.columnIds?.right;
    } else if (this._rightColumnIds.length !== 0) {
      this._rightColumnIds = [];
    }

    if (oldGraphBaseId !== (this.graph || {}).baseColumnId) {
      // let sa-app know if the base id changes for a graph
      this.dispatchEvent(
        new CustomEvent('graph-base-id-change', {
          composed: true,
          bubbles: true,
          detail: { graphs: Object.values(state.graphs) },
        }),
      );
    }
    this.accessibility = state.accessibility.scale;
  }

  shouldUpdate() {
    return this.graph;
  }

  updated(changedProperties) {
    changedProperties.forEach(async (oldValue, propName) => {
      switch (propName) {
        case 'visibleTraces':
          this.leftTraces = this.visibleTraces.filter(trace => trace.axis !== 'right');
          this._visibleTracesChanged(this.visibleTraces);
          break;
        case 'selectionsById':
          this._onSelectionsByIdChanged(this.selectionsById, oldValue);
          break;
        case 'graph':
          this._graphStateChanged(this.graph, oldValue);
          break;
        case 'useRightAxis':
          if (this.useRightAxis) {
            this.updateRightAxisEnabled({ detail: true });
          }
          break;
        default:
      }
    });
  }

  async firstUpdated() {
    [this.$dataWorld, this.$dataCollection, this.$dataAnalysis, this.$popoverManager, this.$toast] =
      this.requestServices([
        'dataWorld',
        'dataCollection',
        'dataAnalysis',
        'popoverManager',
        'toast',
      ]);

    this.miniGraphStartX = 0;
    this.miniGraphStartY = 0;

    this.coreGraphEl = this.shadowRoot.querySelector(`vst-core-graph`);

    this.eventBinder = new EventBinder();

    this.throttledApplySelectionsAnalysis = throttle(() => this._applySelectionsAnalysis(), 100);

    this.eventBinder.bindListeners({
      source: this.$dataWorld,
      target: this,
      eventMap: {
        'session-started': 'onSessionStarted',
        'session-ended': 'onSessionEnded',
        'data-set-ready': 'onDataSetReady',
        'data-set-added': '_cleanupCurrentAnalysis',
        'imported-graph-state-ready': 'onImportedGraphStateReady',
        'column-values-changed': 'throttledApplySelectionsAnalysis',
      },
    });

    const createSelection = (...args) => this.analysisStore.createSelection(...args);

    const { updateUdmWithAppState, updateAppWithUdmState, reset, _getAnalysisState } =
      initAnalysisMiddleware(this.$dataWorld, createSelection);

    this.updateUdmAnalysis = updateUdmWithAppState;
    this.applyImportedAnalysisState = updateAppWithUdmState;
    this.resetAnalysisMiddleware = reset;
    this._getAnalysisMiddlewareState = _getAnalysisState;

    this.addObservableProperty({
      store: this.analysisStore,
      key: 'selections',
      property: 'selectionsById',
    });

    await this.coreGraphEl.updateComplete;
    this.graphInstance = this.coreGraphEl.graphInstance;

    this.dispatchEvent(new CustomEvent('core-graph-ready', { detail: this.coreGraphEl }));

    const { coreGraphEl } = this;
    const graphMethods = {
      getBaseRange: coreGraphEl.getBaseRange.bind(coreGraphEl),
      getPxWidth: coreGraphEl.analysisEl.getGraphPxWidth.bind(coreGraphEl.analysisEl),
    };

    const getUnitsForColumnId = columnId => {
      const column = this.$dataWorld.getColumnById(columnId);
      return column && column.group.units;
    };

    this.getAnalysisDataForSelections = makeAnalysisForSelectionsMethod(
      this.$dataAnalysis,
      graphMethods,
      getUnitsForColumnId,
    );

    // set dragging containers for graph legend ui
    this.graphLegendWrapper = [
      ...this.shadowRoot.querySelectorAll('[slot=graph_legend]'),
      ...this.shadowRoot.querySelectorAll('[slot=mini_graph]'),
    ];
    this.graphLegendWrapper.forEach(legend => {
      legend.dragContainer = this;
    });
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    this.eventBinder.unbindAll();
  }

  miniGraphMousedown({ pageX, pageY }) {
    this.miniGraphStartX = pageX;
    this.miniGraphStartY = pageY;
  }

  miniGraphMouseup({ pageX, pageY }) {
    const diffX = Math.abs(pageX - this.miniGraphStartX);
    const diffY = Math.abs(pageY - this.miniGraphStartY);

    // hard coded delta
    if (diffX < MOUSE_MOVE_DELTA && diffY < MOUSE_MOVE_DELTA) {
      this.dispatchWavelengthChooser();
    }
  }

  async onSessionStarted({ imported }) {
    const { coreGraphEl, importedGraphState } = this;
    const { analysisEl, hasCoreAnalysis } = coreGraphEl;

    if (hasCoreAnalysis) {
      analysisEl.fireExaminePositionUpdate({ examineHidden: true });
      analysisEl.fireExamineSettingsUpdate({
        interpolate: false,
        tangentEnabled: false,
      });
    }

    // If this is a brand new file, OR this is a legacy file saved by a version
    // of the app which didn't bother to populate graphs 2 and 3 with UDM info,
    // we'll go ahead and initialize that UDM info here (MEG-3224).
    const needsUDMInitialization = Object.keys(importedGraphState ?? {}).length === 0;
    if (!imported || needsUDMInitialization) {
      coreGraphEl.addGraphToUdm();
    } else if (importedGraphState) {
      this.coreGraphEl.applyImportedGraphState(importedGraphState);
      // While Redux is synchronous, LitElement is asynchronous, so sadly we need await the update of both components before using the state
      await this.coreGraphEl.updateComplete;
      await this.updateComplete;
      const { integrals, curveFits, statistics } = importedGraphState;
      this.applyImportedAnalysisState({ integrals, curveFits, statistics });
      this.importedGraphState = null;
    }

    coreGraphEl.enableFitToNewData();
  }

  onSessionEnded() {
    this.coreGraphEl.udmId = 0;
    this._cleanupCurrentAnalysis();
    this.resetAnalysisMiddleware();
    this.analysisStore?.reset();

    this.coreGraphEl.removeBaseColumn();
    this.coreGraphEl.removeRegularTraces();
    this.coreGraphEl.disableFitToNewData();
    this.graphLegendWrapper.forEach(legend => {
      legend.resetPosition();
    });
  }

  // TODO this is duplicated across all app graphs
  // plots latest columns on graphs
  onDataSetReady() {
    const { $dataWorld, coreGraphEl } = this;

    if ($dataWorld.sessionType === 'ManualEntry') {
      const newLeftColumnIds = coreGraphEl.leftColumnIds.map(columnId => {
        const plottedGroupId = $dataWorld.getColumnById(columnId)?.groupId;
        const columnsFromCurrentSet = $dataWorld.getColumnsForSet($dataWorld.currentDataSet.id);
        const newColumn = columnsFromCurrentSet.find(col => col.groupId === plottedGroupId);
        return newColumn?.id;
      });
      const newRightColumnIds = coreGraphEl.rightColumnIds.map(columnId => {
        const plottedGroupId = $dataWorld.getColumnById(columnId)?.groupId;
        const columnsFromCurrentSet = $dataWorld.getColumnsForSet($dataWorld.currentDataSet.id);
        const newColumn = columnsFromCurrentSet.find(col => col.groupId === plottedGroupId);
        return newColumn?.id;
      });
      // update graph to plot any new columns
      store.dispatch(updateColumnIds(newLeftColumnIds, this.id, 'left'));
      store.dispatch(updateColumnIds(newRightColumnIds, this.id, 'right'));
    }
  }

  onImportedGraphStateReady(options) {
    // Graph index is now a one-based integer value, e.g. 1, 2, 3 etc. Zero indicates an uninitiated state.
    const [, graphNumber] = this.id.split('_');
    const graphIndex = parseInt(graphNumber);

    if (graphIndex === options.index) {
      this.importedGraphState = { ...options };
    }
  }

  _graphStateChanged(newGraph = {}, oldGraph = {}) {
    if (!isEqual(newGraph.rightAxisEnabled, oldGraph.rightAxisEnabled)) {
      const visibleTrace = trace => trace.axis !== 'right' || newGraph.rightAxisEnabled;
      this.visibleTraces = this.coreGraphEl.getAllRegularTraces().filter(visibleTrace);
    }
  }

  onGraphTracesUpdated() {
    const visibleTrace = trace => trace.axis !== 'right' || this.graph.rightAxisEnabled;
    this.visibleTraces = this.coreGraphEl.getAllRegularTraces().filter(visibleTrace);
    if (!this.importedGraphState) {
      this.coreGraphEl.autoscale();
    }
  }

  onUserRequestedAutoscale({ detail: { rangeUpdates, isZoomToSelectionRange } }) {
    const message = isZoomToSelectionRange
      ? getText('Zoom to Selection')
      : getText('Zoom to all Data');
    this.$toast.makeToast(message, { duration: 2000 });
    store.dispatch(userAutoscaleUpdate(this.id, rangeUpdates)); // update Redux graph ranges
  }

  async onApplyCurveFit(target) {
    const fitsPreviewSelected = (curveFitType, isInitialSelection) => {
      if (isInitialSelection) {
        this._updateDefaultAnalysisSelection({
          analysisType: 'curveFits',
          curveFitType,
          permanent: false,
        });
      } else {
        const nonPermanentId = SaGraph._firstNonPermanentSelectionKey(this.selectionsById);
        this.analysisStore.modifySelection(nonPermanentId, { curveFitType });
      }
    };

    const fitsPreviewCancelled = () => {
      const nonPermanentId = SaGraph._firstNonPermanentSelectionKey(this.selectionsById);
      const selection = this.selectionsById[nonPermanentId];

      if (selection.autoGenerated) {
        this.analysisStore.deleteSelection(selection.id);
      } else {
        this.analysisStore.modifySelection(nonPermanentId, { analysisType: '', curveFitType: '' });
      }
    };

    const fitsPreviewApplied = () => {
      const nonPermanentId = SaGraph._firstNonPermanentSelectionKey(this.selectionsById);
      this.analysisStore.modifySelection(nonPermanentId, { permanent: true });
    };
    await import('@components/vst-core-curvefit-selector/vst-core-curvefit-selector.js');
    if (vstAuthStore.authorized)
      await import('@components/vst-core-curvefit-selector/vst-core-custom-curvefit.js');
    this.$popoverManager
      .presentPopover('vst-core-curvefit-selector', {
        anchor: target,
        orientation: 'right',
        properties: {
          selectedFit: vstCurvefitStore.getFit('LINEAR'),
          supportedFits: vstCurvefitStore.fits,
        },
        events: ({ completeWorkflow }) => ({
          'curve-fit-selected': e => {
            const { fitType, isInitialSelection } = e.detail;
            fitsPreviewSelected(fitType, isInitialSelection);
          },
          'curve-fit-canceled': () => {
            fitsPreviewCancelled();
            completeWorkflow();
          },
          'curve-fit-applied': () => {
            fitsPreviewApplied();
            completeWorkflow();
          },
        }),
      })
      .then(result => {
        if (result?.cancelled) fitsPreviewCancelled();
      });
  }

  async onEditGraphOptions(target) {
    const updateOptions =
      name =>
      ({ detail: value }) => {
        store.dispatch(updateGraphOptions(this.id, { [name]: value }, Actor.USER));
      };

    const { coreGraphEl } = this;
    const properties = { ...coreGraphEl.options };
    const { baseRange, leftRange, rightRange } = properties;
    properties.baseMin = formatter.sigFig(baseRange.min, 4);
    properties.baseMax = formatter.sigFig(baseRange.max, 4);
    properties.leftMin = formatter.sigFig(leftRange.min, 4);
    properties.leftMax = formatter.sigFig(leftRange.max, 4);
    properties.rightMin = formatter.sigFig(rightRange.min, 4);
    properties.rightMax = formatter.sigFig(rightRange.max, 4);
    properties.baseScaling = coreGraphEl.axisScalingModes.base;
    properties.leftScaling = coreGraphEl.axisScalingModes.left;
    properties.rightScaling = coreGraphEl.axisScalingModes.right;
    properties.rightAxisEnabled = coreGraphEl.rightAxisEnabled;

    // this import can be omitted when common is updated with appropriate dependencies
    await import('@components/vst-core-graph-options/vst-core-graph-options');

    this.$popoverManager.presentPopover('vst-core-graph-options', {
      title: getText('Graph Options'),
      anchor: target,
      orientation: 'right',
      properties,
      events: () => ({
        'title-changed': updateOptions('title'),
        'appearance-changed': updateOptions('appearance'),
        'base-range-changed': updateOptions('baseRange'),
        'left-range-changed': updateOptions('leftRange'),
        'right-range-changed': updateOptions('rightRange'),
        'line-weight-changed': updateOptions('lineWeight'), // NOTE: hidden feature for marketing
        'label-size-changed': updateOptions('labelSize'), // NOTE: hidden feature for marketing
        'scaling-mode-changed': coreGraphEl.updateScalingMode.bind(coreGraphEl),
        'right-axis-toggled': ({ detail: enabled }) => {
          store.dispatch(graphRightAxisEnabledUpdate(this.id, enabled));
        },
      }),
    });
  }

  _cleanupCurrentAnalysis({ addedDataSet } = {}) {
    const { coreGraphEl, graphInstance } = this;

    if (coreGraphEl && coreGraphEl.hasCoreAnalysis && graphInstance) {
      if (!addedDataSet || addedDataSet.type === 'regular') {
        coreGraphEl.analysisEl.fireExaminePositionUpdate({ examineHidden: true });
        // TODO: find where the baseColumnId is being changed during import - firing the obsolete-analysis event from vst-core-graph - that is causing this to run and clear out the annotations on file open, so we can remove this check again
        if (!this.importedGraphState?.annotations) {
          store.dispatch(cleanUpAnalysis(this.id));
        }
        this.analysisStore.reset();
        coreGraphEl.removeAllTangentTraces();
      }
    }
  }

  _updateDefaultAnalysisSelection(selectionProps) {
    const { coreGraphEl } = this;

    const nonPermanentId = SaGraph._firstNonPermanentSelectionKey(this.selectionsById); // get id of !permanent selection

    // modify the !permanent selection or create new one
    if (nonPermanentId) {
      this.analysisStore.modifySelection(nonPermanentId, selectionProps);
    } else {
      const baseRange = coreGraphEl.getBaseRange();
      const [min, max] = [baseRange.min, baseRange.max].map(
        pt => coreGraphEl.getClosestX({ pt }).pt,
      );
      this.analysisStore.createSelection({
        ...selectionProps,
        autoGenerated: true,
        range: { min, max },
      });
    }
  }

  static _firstNonPermanentSelectionKey(selectionsByKey) {
    const [nonPermanentKey] =
      Object.entries(selectionsByKey).find(
        ([, selection]) => !selection.permanent && !selection.highlightOnly,
      ) || [];
    return nonPermanentKey;
  }

  _visibleTracesChanged(visibleTraces = []) {
    const { coreGraphEl } = this;

    if (coreGraphEl && coreGraphEl.hasCoreAnalysis && visibleTraces.length) {
      this._updateAllAnalysis();
    } else {
      this._cleanupCurrentAnalysis();
    }
  }

  _onSelectionsByIdChanged(newSelections = {}, oldSelections = {}) {
    if (!isEqual(newSelections, oldSelections)) {
      this.throttledApplySelectionsAnalysis();
    }
  }

  // compute and apply all analysis
  _updateAllAnalysis() {
    const { coreGraphEl } = this;

    if (coreGraphEl.hasCoreAnalysis) {
      coreGraphEl.analysisEl.updateExamineAndTangent();
    }

    this._applySelectionsAnalysis();
  }

  async _applySelectionsAnalysis() {
    const { selectionsById, visibleTraces, coreGraphEl } = this;

    if (!this.getAnalysisDataForSelections) {
      return;
    }

    const traceHasData = trace => trace.yColumn.values.some(Number.isFinite);
    const selectionsWithTraceInfo = mapValues(selectionsById, selection => ({
      ...selection,
      tracesInfo: visibleTraces.filter(traceHasData).map(t => ({
        xid: t.baseColumn.id,
        yid: t.yColumn.id,
        xData: this.$dataWorld.getColumnById(t.baseColumn.id)?.values || [],
        yData: this.$dataWorld.getColumnById(t.yColumn.id)?.values || [],
        traceColor: t.color,
        axis: t.axis,
      })),
    }));

    const selectionsData = await this.getAnalysisDataForSelections(selectionsWithTraceInfo);
    const selectionsWithData = mapValues(selectionsWithTraceInfo, (selection, id) => ({
      ...selection,
      ...selectionsData[id],
    }));

    coreGraphEl.setSelectionsData(selectionsWithData); // apply graph traces

    // update udm
    const { addedAnalysis, changedAnalysis } = await this.updateUdmAnalysis(
      this.coreGraphEl.udmId,
      selectionsWithData,
    );
    addedAnalysis.forEach(analysis => {
      const { selectionId, udmId } = analysis;
      selectionsWithData[selectionId].udmId = udmId;
    });
    changedAnalysis.forEach(analysis => {
      const { selectionId, udmId } = analysis;
      selectionsWithData[selectionId].udmId = udmId;
    });

    // apply infoboxes
    this.selectionsWithData = selectionsWithData;
  }

  _getSelectionElementById(id) {
    const { coreGraphEl: { analysisEl } = {} } = this;

    return analysisEl && analysisEl.getSelectionElementById(id);
  }

  _handleGraphToolsEvent({ detail }) {
    switch (detail?.type) {
      case 'graph_legend':
        graphStore.setIsLegendVisible(detail?.checked);
        break;
      case 'mini_graph':
        graphStore.setIsMiniGraphVisible(detail?.checked);
        break;
      case 'statistics':
        this._updateDefaultAnalysisSelection({ analysisType: 'statistics', permanent: true });
        break;
      case 'integral':
        this._updateDefaultAnalysisSelection({ analysisType: 'integrals', permanent: true });
        break;
      case 'curve_fit':
        this.onApplyCurveFit(detail.target);
        break;
      case 'annotation':
        this.coreGraphEl.analysisEl.addAnnotation();
        break;
      case 'prediction':
        store.dispatch(openDrawPredictions({ graphId: this.id }));
        break;
      case 'graph_options':
        this.onEditGraphOptions(detail.target);
        break;
      default:
    }
  }

  get examinePin() {
    const { examinePosition = {}, examineSettings = {} } = this.analysisStore || {};
    return { examinePosition, examineSettings };
  }

  updateExaminePinPosition({ detail: { positionUpdate } }) {
    this.analysisStore?.updateExaminePosition(positionUpdate);
  }

  updateExaminePinSettings({ detail: { settingsUpdate } }) {
    this.analysisStore?.updateExamineSettings(settingsUpdate);
  }

  handleNewSelectionGesture({ detail: { currentRange: dragRange } }) {
    this.analysisStore.handleNewSelectionGesture({ dragRange });
  }

  modifySelection({ detail: { elementId, selectionId, selectionUpdates = {} } = {} }) {
    this.analysisStore.modifySelection(selectionId || elementId, selectionUpdates);
  }

  deleteSelection({ detail: selectionId }) {
    this.analysisStore.deleteSelection(selectionId);
  }

  deleteTempSelection() {
    this.analysisStore.deleteTempSelection();
  }

  // Redux action dispatchers
  static openDrawPredictions({ detail: { graphEl } }) {
    store.dispatch(openDrawPredictions({ graphId: graphEl.id }));
  }

  static closeDrawPredictions({ detail: { graphEl } }) {
    store.dispatch(closeDrawPredictions({ graphId: graphEl.id }));
  }

  static updateGraphBaseUnits({ target: { id }, detail: { baseUnits } }) {
    store.dispatch(updateGraphBaseUnits(id, baseUnits));
  }

  static updateGraphBaseColumnId({ target: { id }, detail: { baseColumnId } }) {
    store.dispatch(updateGraphBaseColumnId(id, baseColumnId));
  }

  static updateGraphEntityOptions({ target: { id }, detail: { options, entity } }) {
    store.dispatch(updateGraphEntityOptions({ options, entity, graphId: id }));
  }

  static updateGraphOptions({ target: { id }, detail: graphOptions }) {
    store.dispatch(updateGraphOptions(id, graphOptions, Actor.USER));
  }

  static updateAutomaticAutoscale({ target: { id }, detail: { rangeUpdates } }) {
    store.dispatch(automaticAutoscaleUpdate(id, rangeUpdates));
  }

  static updateGraphUdmId({ target: { id }, detail: udmId }) {
    store.dispatch(updateGraphUdmId(id, udmId));
  }

  static updateAxisScalingMode({ target: { id: graphId }, detail }) {
    Object.entries(detail).forEach(([axis, mode]) => {
      store.dispatch(axisScalingModeUpdate(graphId, axis, mode));
    });
  }

  static addColumnId({ target: { id: graphId }, detail: { columnId, axis } }) {
    store.dispatch(addColumnId(columnId, graphId, axis));
  }

  static removeColumnId({ target: { id: graphId }, detail: { columnId, axis } }) {
    store.dispatch(removeColumnId(columnId, graphId, axis));
  }

  static updateColumnIds({ target: { id: graphId }, detail: { columnIds, axis } }) {
    store.dispatch(updateColumnIds(columnIds, graphId, axis));
  }

  static cleanUpAnalysis({ target: { id: graphId } }) {
    store.dispatch(cleanUpAnalysis(graphId));
  }

  updateRightAxisEnabled({ detail: enabled }) {
    store.dispatch(graphRightAxisEnabledUpdate(this.id, enabled));
  }

  static hideLegend() {
    graphStore.setIsLegendVisible(false);
  }

  static hideMiniGraph() {
    graphStore.setIsMiniGraphVisible(false);
  }

  // TODO: restore SA's real autolayout logic, such as method updateGraphLayout()
  static _getGraphOptions() {
    const graphOptions = {
      baseRange: {
        min: 0,
        max: 3,
      },
      appearance: {
        lines: false,
        points: true,
      },
      sensorRange: {
        min: 0,
        max: 3,
      },
    };

    return graphOptions;
  }

  static _getGraphPlottedGroups({ groups = [], requestedRightGroupId } = {}) {
    // eslint-disable-next-line no-param-reassign
    groups = groups.filter(g => g.type !== 'calc');

    // recursive search for calc column replacements of the passed in (base) group. Returns final replacement and replaced ids
    const findAnyCalcColReplacementGroup = (groups, { toReplace, replacedGroupIds = [] }) => {
      const replacementGroup = groups.find(
        group => group.replaceDependent && group.calcDependentGroups[0] === toReplace.id,
      );
      if (replacementGroup) {
        return findAnyCalcColReplacementGroup(groups, {
          toReplace: replacementGroup,
          replacedGroupIds: [...replacedGroupIds, toReplace.id],
        });
      }
      return { group: toReplace, replacedGroupIds };
    };

    const preferredBaseGroup = groups.find(group => group.prefersBase);
    const { group: baseGroup, replacedGroupIds: replacedBaseIds } = findAnyCalcColReplacementGroup(
      groups,
      { toReplace: preferredBaseGroup },
    );
    const baseGroupId = baseGroup ? baseGroup.id : null;

    const plottedGroups = groups.filter(
      group => group.plotted && group.id !== baseGroupId && !replacedBaseIds.includes(group.id),
    );
    const verticalGroupIds = plottedGroups.map(g => g.id);

    // split verticalGroups between left and right axis
    const useRequestedRightId =
      requestedRightGroupId &&
      verticalGroupIds.length > 1 &&
      verticalGroupIds.includes(requestedRightGroupId);
    const rightColumnGroupId = useRequestedRightId ? requestedRightGroupId : undefined;
    const rightColumnGroupIds = rightColumnGroupId ? [rightColumnGroupId] : [];
    const leftColumnGroupIds = verticalGroupIds.filter(id => id !== rightColumnGroupId);

    // TODO: just use the baseGroupId once we add this to the core-graph
    const baseColumnId = baseGroup && baseGroup.columns[0] ? baseGroup.columns[0].id : null;

    return {
      baseColumnId,
      leftColumnGroupIds,
      rightColumnGroupIds,
    };
  }

  dispatchWavelengthChooser() {
    this.dispatchEvent(
      new CustomEvent('show-wavelength-chooser', { bubbles: true, composed: true }),
    );
  }

  render() {
    const showWavelengthRainbow =
      this.$dataWorld?.getColumnById(this.graph.baseColumnId)?.type === 'wavelength';

    return html`
      <vst-core-graph
        id="${this.id}"
        ?hidden="${this.hidden}"
        ?disableAxisLabelBtns="${this.readOnly}"
        ?hideGraphActionBtns="${this.readOnly}"
        ?disableMenu="${this.readOnly}"
        .baseColumnId="${this.graph.baseColumnId}"
        .baseUnits="${this.graph.baseUnits}"
        .isSessionEmpty=${this._isSessionEmpty}
        .leftColumnIds="${this.graph.columnIds.left}"
        .rightColumnIds="${this._rightColumnIds}"
        .autoscalePadding="${this.graph.autoscalePadding}"
        .options="${this.graph.options}"
        .axisScalingModes="${this.graph.scalingModes}"
        .rightAxisEnabled="${this.graph.rightAxisEnabled}"
        .accessibilityScale="${this.accessibilityScale}"
        .selectionsById="${this.selectionsById}"
        .interpolateEnabled="${this.analysisStore?.examineSettings?.interpolate}"
        .tangentEnabled="${this.analysisStore?.examineSettings?.tangentEnabled}"
        .isLegendVisible="${
          this.isLegendVisible && !this.graph.rightAxisEnabled && !this.advancedModeEnabled
        }"
        .isLegendDisabled="${this.graph.rightAxisEnabled || this.advancedModeEnabled}"
        .isMiniGraphVisible="${this.isMiniGraphVisible}"
        ?isMiniGraphDisabled="${this.isMiniGraphDisabled}"
        .showWavelengthRainbow="${showWavelengthRainbow}"
        miniGraphSupported
        @graph-tools-item-clicked="${this._handleGraphToolsEvent}"
        @graph-base-column-id-update="${SaGraph.updateGraphBaseColumnId}"
        @graph-entity-options-update="${SaGraph.updateGraphEntityOptions}"
        @data-values-updated="${this._updateAllAnalysis}"
        @base-units-updated="${SaGraph.updateGraphBaseUnits}"
        @left-or-right-axis-clicked="${this.onLeftOrRightAxisClicked}"
        @update-all-curve-fits="${this.throttledApplySelectionsAnalysis}"
        @obsolete-analysis="${this._cleanupCurrentAnalysis}"
        @regular-traces-updated="${this.onGraphTracesUpdated}"
        @right-axis-enabled-update="${this.updateRightAxisEnabled}"
        @udm-id-set="${SaGraph.updateGraphUdmId}"
        @options-change="${SaGraph.updateGraphOptions}"
        @selection-deleted="${this.deleteSelection}"
        @selection-modified="${this.modifySelection}"
        @axis-column-id-added="${SaGraph.addColumnId}"
        @axis-column-id-removed="${SaGraph.removeColumnId}"
        @axis-column-ids-updated="${SaGraph.updateColumnIds}"
        @axis-scaling-mode-changed="${SaGraph.updateAxisScalingMode}"
        @automatic-autoscale="${SaGraph.updateAutomaticAutoscale}"
        @user-requested-autoscale="${this.onUserRequestedAutoscale}"
      >
        <vst-ui-draggable
          class="legend"
          slot="graph_legend"
          id="graph_legend_wrapper"
          ?hidden="${
            !this.isLegendVisible || this.graph.rightAxisEnabled || this.advancedModeEnabled
          }"
        >
          <vst-core-graph-legend .leftTraces="${this.leftTraces}"></vst-core-graph-legend>
          <vst-style-tooltip>
            <button
              id="legend_close_btn"
              class="btn"
              size="s"
              variant="icon"
              @click="${SaGraph.hideLegend}"
            >
              <vst-ui-icon .icon="${iconClose}" color="var(--vst-color-fg-tertiary)"></vst-ui-icon>
            </button>
            <span role="tooltip" position="block-start-end">${getText('Close graph legend')}</span>
          </vst-style-tooltip>
        </vst-ui-draggable>
        <vst-ui-draggable
          class="legend"
          slot="mini_graph"
          id="mini_graph_wrapper"
          ?hidden="${!this.isMiniGraphVisible}"
        >
          <sa-spectrum-graph
            id="mini_graph"
            isMiniGraph
            readOnly
            .wavelength="${this.spectrumInfo.selectedWavelength}"
            .spectrumInfo="${this.spectrumInfo}"
            @mousedown="${this.miniGraphMousedown}"
            @mouseup="${this.miniGraphMouseup}"
          ></sa-spectrum-graph>
          <vst-style-tooltip ?hidden=${this.hasAttribute('export')}>
            <button
              id="mini_graph_close_btn"
              class="btn"
              size="s"
              variant="icon"
              tooltip="block-start-end"
              @click="${SaGraph.hideMiniGraph}"
            >
              <vst-ui-icon .icon="${iconClose}" color="var(--vst-color-fg-tertiary)"></vst-ui-icon>
            </button>
            <span role="tooltip">${getText('Close wavelength graph')}</span>
          <vst-style-tooltip>
        </vst-ui-draggable>
        <vst-core-graph-analysis
          slot="analysis"
          .examinePin="${this.examinePin}"
          .selectionsById="${this.selectionsById}"
          .selectionsWithData="${this.selectionsWithData}"
          .readOnly="${this.readOnly}"
          @examine-positioning-changed="${this.updateExaminePinPosition}"
          @examine-settings-changed="${this.updateExaminePinSettings}"
          @delete-temp-selection="${this.deleteTempSelection}"
          @new-selection-gesture="${this.handleNewSelectionGesture}"
        >
        </vst-core-graph-analysis>
        <sa-wavelength-rainbow
          .graph=${this.graphInstance}
          ?hidden=${!showWavelengthRainbow}
          slot="rainbow"
        >
        </sa-wavelength-rainbow>
      </vst-core-graph>
    `;
  }
}

customElements.define('sa-graph', SaGraph);

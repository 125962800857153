import { LitElement, html } from 'lit';

import { getText } from '@utils/i18n.js';

import { Requester } from '@components/mixins/vst-core-requester-mixin.js';
import { globalStyles } from '@styles/vst-style-global.css.js';
import { vstCoreAccessibilityStyles } from './vst-core-accessibility.css.js';

import '@components/vst-ui-range/vst-ui-range.js';

export class VstCoreAccessibility extends Requester(LitElement) {
  static get properties() {
    return {
      accessibilityScale: { type: Number, reflect: true },
    };
  }

  constructor() {
    super();
    this.accessibilityScale = 1;
  }

  firstUpdated() {
    this.$accessibility = this.requestService('accessibility');
    this.accessibilityScale = this.$accessibility.scale;
    this.$accessibility.on('accessibility-scale-changed', newScale => {
      this.accessibilityScale = newScale;
    });
  }

  accessibilityScaleChanged(e) {
    this.accessibilityScale = parseFloat(e.target.value);
    this.$accessibility.scale = this.accessibilityScale;
  }

  static get styles() {
    return [globalStyles, vstCoreAccessibilityStyles];
  }

  render() {
    return html`
      <vst-ui-presentation
        slot="content"
        ?font-size="${this.dialogs?.presentation?.params?.fontSize}"
        ?theme="${this.dialogs?.presentation?.params?.theme}"
        ?zoom="${this.dialogs?.presentation?.params?.zoom}"
      >
      </vst-ui-presentation>
      <vst-ui-range
        min=".5"
        max="2"
        step="0.25"
        margin="block-start-m"
        .value="${this.accessibilityScale}"
        label="${getText('Scale Factor')}"
        .tickmarks="${[0.5, 0.75, 1, 1.25, 1.5, 1.75, 2]}"
        @value-input="${this.accessibilityScaleChanged}"
        @value-changed="${this.accessibilityScaleChanged}"
      ></vst-ui-range>
    `;
  }
}
customElements.define('vst-core-accessibility', VstCoreAccessibility);

import { css } from 'lit';
import { buttonReset } from '../styles/index.js';

export default css`
  :host {
    background-color: var(--vst-color-bg);
    border: 1px solid var(--vst-color-bg-primary);
    display: block;
    font-size: 0.875rem;
    overflow: hidden;
    position: absolute;
    z-index: var(--vst-z-annotation);
  }

  :host([editing]) {
    border: 1px solid var(--vst-color-brand-300);
    border-radius: 4px;
    z-index: var(--vst-z-popover);
  }

  :host([editing]) .annotation__actions,
  :host([editing]) .annotation__form {
    display: flex;
  }

  :host([editing]) .annotation__static {
    display: none;
  }

  .annotation__static {
    cursor: pointer;
    font-family: var(--vst-font-family);
    font-size: 1em;
    max-inline-size: 15rem;
    padding: 0.5em 0.675em;
    white-space: normal;
  }

  .annotation__form {
    display: none;
    padding: 0.5em 0.5em 0.5em 0;
    width: 15rem;
  }

  .annotation__input {
    background-color: var(--vst-color-bg);
    border: 1px solid var(--vst-color-bg-secondary);
    border-radius: 4px;
    color: var(--vst-color-fg-primary);
    flex: 1;
    font-family: var(--vst-font-family);
    font-size: 1em;
    line-height: var(--vst-line-height-m);
    padding: 0.5em 0.675em;
    width: 100%;
  }

  input#annotation_input.annotation__input:hover {
    border: 1px solid var(--vst-color-brand-300);
  }

  input#annotation_input.annotation__input:focus,
  input#annotation_input.annotation__input:active {
    border: 2px solid var(--vst-color-brand-300);
    outline: none;
  }

  ${buttonReset(css`.annotation__actions`)}
  .annotation__actions {
    align-items: center;
    background-color: transparent;
    border: 0;
    justify-content: center;
  }
`;

import { uniqueId } from 'lodash-es';

import EventEmitter from 'eventemitter3';

import { createEmptyRange, mergeRanges } from '@utils/helpers.js';

import {
  circleSymbol,
  circleOutlineSymbol,
  squareSymbol,
  squareOutlineSymbol,
  diamondSymbol,
  diamondOutlineSymbol,
  rectangleSymbol,
  rectangleOutlineSymbol,
  rectangleInvertedSymbol,
  rectangleInvertedOutlineSymbol,
  plusSymbol,
  plusOutlineSymbol,
  triangleSymbol,
  triangleOutlineSymbol,
  triangleInvertedSymbol,
  triangleInvertedOutlineSymbol,
} from '@components/vst-ui-icon/index.js';
import { ErrorBarType } from '@api/common/ColumnGroup.js';

export const isValidVal = val => val !== undefined && val !== null && !Number.isNaN(val);
// The Trace object contains a yColumn and a baseColumn
// The Trace object is driven/managed by the Graph object
//
//
// 'data-points-updated' - triggered when the data points corresponding to the trace are updated
//
//

// CONSTRUCTOR
export class Trace extends EventEmitter {
  constructor(graph, options = {}) {
    super();
    this.experimentId = options.experimentId || 0;
    this.id = uniqueId('trace-');
    this.graph = graph;

    this.getGraphBaseRange = graph.getBaseRange.bind(graph);
    this.getGraphLeftRange = graph.getLeftRange.bind(graph);
    this.getGraphRightRange = graph.getRightRange.bind(graph);

    this.type = options.type || 'regular';
    this.symbol = options.symbol;
    this.axis = options.axis;
    this.fillPoint = options.fillPoint ?? true;

    this.drawOptions = {};

    if (this.type !== 'regular') {
      this.drawOptions = {
        ...{
          lines: true,
          points: false,
        },
      };
    }

    this.yColumn = options.yColumn;
    this.baseColumn = options.baseColumn;

    this.range = createEmptyRange();
    this.seriesData = [];
    this.lastRowIndex = -1; // last row index, used to determine whether we can append or not
    this.trimAllSeriesData(); // initialize series data from column values

    this.latestBucket = null;
    this.numPointsFromLatestBucket = 0;

    this.color = options.color || '#000000'; // UNSET value
    this.lineWeight = options.lineWeight || 2;

    this.updateSymbol(this.symbol);

    console.assert(this.graph);
    console.assert(this.yColumn);
    console.assert(this.baseColumn);
  }

  static generateSeriesData(columnRows, baseValues, yValues) {
    const data = [];
    let lastBaseValue = -Infinity;
    let isIncreasing = true;
    const dataRange = {
      baseMin: Infinity,
      baseMax: -Infinity,
      yMin: Infinity,
      yMax: -Infinity,
      valid: false,
    };

    for (let i = 0; i < columnRows.length; ++i) {
      const row = columnRows[i];
      const baseValue = baseValues[row];
      const yValue = yValues[row];

      if (isValidVal(baseValue) && isValidVal(yValue)) {
        data.push([baseValue, yValue]);

        // update data range with the new base/y pair
        dataRange.baseMin = Math.min(dataRange.baseMin, baseValue);
        dataRange.baseMax = Math.max(dataRange.baseMax, baseValue);
        dataRange.yMin = Math.min(dataRange.yMin, yValue);
        dataRange.yMax = Math.max(dataRange.yMax, yValue);
        dataRange.valid = true;

        isIncreasing = isIncreasing && lastBaseValue < baseValue;
        lastBaseValue = baseValue;
      }
    }

    return {
      data,
      dataRange,
      isIncreasing,
    };
  }

  /**
   * Gets the number of rows removed up to but not including the given
   * index
   * @param {number} index Row index
   */
  getIndexOffsetFromSeries(index) {
    return (
      index -
      this.yColumn.values.filter(
        (value, i) => i < index && (this.yColumn.isRowStruck(i) || !isValidVal(value)),
      ).length
    );
  }

  static trimData(data, baseDistPerPx, yDistPerPx, yPxHeight, seedBucket) {
    const trimmedData = [];
    const buckets = seedBucket ? [seedBucket] : [];

    // get the bucket for given xIndex, create on demand if neccessary
    function getBucket(xIndex, pt) {
      let bucket = buckets.find(b => b.xIndex === xIndex);

      if (!bucket) {
        bucket = {
          xIndex, // store the xIndex in the object so we can match against it later
          x: xIndex * baseDistPerPx,
          points: [pt], // if it ends up only one sample is used, points[0] is the real sample
          avg: {
            // y average calculation
            xSum: 0,
            ySum: 0,
            count: 0,
          },
          min: { x: Infinity, y: Infinity },
          max: { x: -Infinity, y: -Infinity },
        };
        buckets.push(bucket);
      } else {
        bucket.points.push(pt);
      }

      return bucket;
    }

    // iterate through data and fill in buckets with values
    const dataLength = data.length;
    for (let i = 0; i < dataLength; ++i) {
      const [x, y] = data[i];

      // determine where this point belongs
      const bucket = getBucket(Math.floor(x / baseDistPerPx), data[i]);

      // min-max calculation
      // determine min and max based on the y value
      // capture the x value for these min/max points
      if (y < bucket.min.y) {
        bucket.min.x = x;
        bucket.min.y = y;
      }
      if (y > bucket.max.y) {
        bucket.max.x = x;
        bucket.max.y = y;
      }

      // average value
      bucket.avg.xSum += x;
      bucket.avg.ySum += y;
      bucket.avg.count++;
    }

    // turn buckets into a trimmedData array
    let seriesPointsAdded = 0;
    const bucketsLength = buckets.length;
    for (let i = 0; i < bucketsLength; ++i) {
      const bucket = buckets[i];
      if (bucket) {
        // add min/max values
        if (bucket.avg.count === 1) {
          trimmedData.push(bucket.points[0]); // single sample
          seriesPointsAdded = 1;
        } else if (bucket.max.y - bucket.min.y > yDistPerPx * 2) {
          // are min/max more than 3-pixels apart?
          if (bucket.points.length < yPxHeight) {
            trimmedData.push(...bucket.points);
            seriesPointsAdded = bucket.points.length;
          } else {
            trimmedData.push([bucket.min.x, bucket.min.y]);
            trimmedData.push([bucket.max.x, bucket.max.y]);
            seriesPointsAdded = 2;
          }
        } else {
          trimmedData.push([
            // take the average
            bucket.avg.xSum / bucket.avg.count,
            bucket.avg.ySum / bucket.avg.count,
          ]);
          seriesPointsAdded = 1;
        }
      }
    }

    return {
      trimmedData,
      lastBucket: buckets[buckets.length - 1],
      lastBucketPointsCount: seriesPointsAdded,
    };
  }

  get hasErrorBars() {
    return (
      this.baseColumn.group.errorBarType !== ErrorBarType.NONE ||
      this.yColumn.group.errorBarType !== ErrorBarType.NONE
    );
  }
}

// PUBLIC
Object.assign(Trace.prototype, {
  getBaseColumn() {
    return this.baseColumn;
  },

  getColor() {
    return this.color;
  },

  getPlotSizeInfo() {
    const pxWidth = this.graph.plotAreaPxWidth;
    const pxHeight = this.graph.plotAreaPxHeight;
    const baseRange = this.getGraphBaseRange();
    const yRange = this.axis === 'right' ? this.getGraphRightRange() : this.getGraphLeftRange();
    const baseDistPerPx = (baseRange.max - baseRange.min) / pxWidth;
    const yDistPerPx = (yRange.max - yRange.min) / pxHeight;

    return { pxWidth, pxHeight, baseDistPerPx, yDistPerPx };
  },

  /**
   * Append new rows
   *
   * @param {number[]} rowIndexes - Array of sequential row indices
   */
  appendToSeriesDataAndRange(rowIndexes) {
    const baseValues = this.baseColumn.filteredValues;
    const yValues = this.yColumn.filteredValues;

    const { data, dataRange } = Trace.generateSeriesData(rowIndexes, baseValues, yValues);
    const { baseMin, baseMax, yMin, yMax, valid } = dataRange;
    const range = {
      ...createEmptyRange(),
      base: { min: baseMin, max: baseMax, valid },
      [this.axis]: { min: yMin, max: yMax, valid }, // set 'left' or 'right' prop
    };

    if (valid && data.length > 0) {
      const { pxHeight, baseDistPerPx, yDistPerPx } = this.getPlotSizeInfo();

      const trimDataResult = Trace.trimData(
        data,
        baseDistPerPx,
        yDistPerPx,
        pxHeight,
        this.latestBucket,
      );
      const { trimmedData, lastBucket, lastBucketPointsCount } = trimDataResult;

      this.lastRowIndex = rowIndexes[rowIndexes.length - 1];

      if (this.numPointsFromLatestBucket > 0) {
        // Since we're appending, the new values may have updated the last bucket
        // added previously, so we'll remove what was added last time around and
        // append the new trimmed data.
        this.seriesData.splice(
          -this.numPointsFromLatestBucket,
          this.numPointsFromLatestBucket,
          ...trimmedData,
        );
      } else {
        this.seriesData.splice(this.seriesData.length, 0, ...trimmedData);
      }

      this.range = mergeRanges([this.range, range]);

      this.latestBucket = lastBucket;
      this.numPointsFromLatestBucket = lastBucketPointsCount;

      this.emit('data-points-updated');
    }
  },

  updateAllSeriesDataAndRange({ notify } = {}) {
    const baseValues = this.baseColumn.filteredValues;
    const yValues = this.yColumn.filteredValues;

    const allRowIndexes = this.baseColumn.filteredValues.map((_, i) => i);
    const { data, dataRange, isIncreasing } = Trace.generateSeriesData(
      allRowIndexes,
      baseValues,
      yValues,
    );
    const { baseMin, baseMax, yMin, yMax, valid } = dataRange;
    const range = {
      ...createEmptyRange(),
      base: { min: baseMin, max: baseMax, valid },
      [this.axis]: { min: yMin, max: yMax, valid }, // set 'left' or 'right' prop
    };

    this.lastRowIndex = data.length - 1;

    if (isIncreasing) {
      // trim data
      const { pxHeight, baseDistPerPx, yDistPerPx } = this.getPlotSizeInfo();
      const trimDataResult = Trace.trimData(data, baseDistPerPx, yDistPerPx, pxHeight);
      const { trimmedData, lastBucket, lastBucketPointsCount } = trimDataResult;

      this.seriesData = trimmedData;
      this.latestBucket = lastBucket;
      this.numPointsFromLatestBucket = lastBucketPointsCount;
    } else {
      this.seriesData = data;
    }

    this.range = range;

    if (notify) {
      this.emit('data-points-updated');
    }
  },

  updateSymbol(symbol) {
    this.symbol = symbol;
    this.fillPoint = !this.symbol.includes('Outline');

    const symbols = {
      circleSymbol,
      circleOutlineSymbol,
      squareSymbol,
      squareOutlineSymbol,
      diamondSymbol,
      diamondOutlineSymbol,
      rectangleSymbol,
      rectangleOutlineSymbol,
      rectangleInvertedSymbol,
      rectangleInvertedOutlineSymbol,
      plusSymbol,
      plusOutlineSymbol,
      triangleSymbol,
      triangleOutlineSymbol,
      triangleInvertedSymbol,
      triangleInvertedOutlineSymbol,
    };

    this.drawOptions.symbol = (pointSize, color) => {
      const symbolIcon = symbols[`${this.symbol}Symbol`];
      const [viewBoxWidth, viewBoxHeight] = symbolIcon.viewBox.split(' ').slice(-2);
      const canvas = document.createElement('canvas');
      [canvas.width, canvas.height] = [viewBoxWidth, viewBoxHeight];
      const context = canvas.getContext('2d');
      const path = new Path2D(symbolIcon.paths[0]);
      path.closePath();
      context.strokeStyle = color;
      context.lineWidth = symbolIcon.lineWidth || 2;
      context.fillStyle = color;
      if (symbolIcon.stroke) context.stroke(path);
      else context.fill(path);

      // TODO: for time purposes, I'm going to leave this because I'm having trouble working out proper scaling, but we should remove the need for a second canvas and just scale the `canvas` element and return it.
      const sizedCanvas = document.createElement('canvas');
      const sizedContext = sizedCanvas.getContext('2d');
      const wider = canvas.width > canvas.height;
      if (wider) {
        sizedCanvas.width = 2 * (symbolIcon.graphSizeModifier || 1) * pointSize;
        sizedCanvas.height = sizedCanvas.width * (viewBoxHeight / viewBoxWidth);
      } else {
        sizedCanvas.height = 2 * (symbolIcon.graphSizeModifier || 1) * pointSize;
        sizedCanvas.width = (sizedCanvas.height * viewBoxWidth) / viewBoxHeight;
      }

      sizedContext.drawImage(
        canvas,
        sizedCanvas.width / 4,
        sizedCanvas.height / 4,
        sizedCanvas.width / 2,
        sizedCanvas.height / 2,
      );
      return sizedCanvas;
    };

    this.emit('point-symbols-updated');
  },

  trimAllSeriesData() {
    this.updateAllSeriesDataAndRange({ notify: false });
  },

  _onValuesChanged(rowIndexes, column) {
    const { values } = column;

    const areSequentialRows = rowIndexes.every((row, i, _rowIndexes) => row - i === _rowIndexes[0]); // are rowIndexes of the form: N, N+1, N+2, ... ?
    const rowsPositionedAtEnd = rowIndexes[0] === values.length - rowIndexes.length; // are these rows appended to the end of the existing rows? (check only valid if areSequentialRows is true)
    const overwritePrevValues = rowIndexes.some(row => row <= this.lastRowIndex); // are any of the rows overwriting previous rows?

    const isAppendedUpdate = areSequentialRows && rowsPositionedAtEnd && !overwritePrevValues;

    if (isAppendedUpdate) {
      this.appendToSeriesDataAndRange(rowIndexes);
    } else {
      this.updateAllSeriesDataAndRange({ notify: true });
    }
  },

  _onStrikethroughChanged() {
    this.updateAllSeriesDataAndRange({ notify: true });
  },

  // bind to column events
  bindAll() {
    this.yColumn.on('row-values-updated', this._onValuesChanged, this);
    this.yColumn.on('strikethrough-changed', this._onStrikethroughChanged, this);
    this.yColumn.on('symbol-changed', this.updateSymbol, this);
    this.baseColumn.on('row-values-updated', this._onValuesChanged, this);
    this.baseColumn.on('strikethrough-changed', this._onStrikethroughChanged, this);
    this.baseColumn.on('column-dataType-updated', newDataType => {
      this.emit('base-column-data-type-updated', newDataType);
    });
  },

  // unbind from column events
  unbindAll() {
    this.yColumn.off('row-values-updated', this._onValuesChanged, this);
    this.yColumn.off('strikethrough-changed', this._onStrikethroughChanged, this);
    this.yColumn.off('symbol-changed', this.updateSymbol, this);
    this.baseColumn.off('row-values-updated', this._onValuesChanged, this);
    this.baseColumn.off('strikethrough-changed', this._onStrikethroughChanged, this);
    this.baseColumn.off('column-dataType-updated');
  },

  getDataPoints() {
    const baseValues = this.baseColumn.filteredValues;
    const yValues = this.yColumn.filteredValues;
    const length = Math.min(baseValues.length, yValues.length);

    const points = [];
    for (let i = 0; i < length; i++) {
      points.push([baseValues[i], yValues[i]]);
    }

    return points;
  },

  /**
   * Find all points having base values within a range and return their y values
   * @param {Object} baseRange
   * @returns {Array} List of yColumn values for points within baseRange
   */
  getYValuesInBaseRange(baseRange = this.range.base) {
    const baseValues = this.baseColumn.filteredValues;
    const yValues = this.yColumn.filteredValues;
    const length = Math.min(baseValues.length, yValues.length);

    const yValuesInRange = [];
    for (let i = 0; i < length; i++) {
      if (baseValues[i] >= baseRange.min && baseValues[i] <= baseRange.max) {
        yValuesInRange.push(yValues[i]);
      }
    }

    return yValuesInRange;
  },
});

// keep in sync with VSTAnalysis library: enum VSTAnalysis_FitType
export const fitType = {
  NONE: 0, //
  PROPORTIONAL: 1, // ax
  LINEAR: 2, // mx + b
  QUADRATIC: 3, // ax^2 + bx + c
  // POLYNOMIAL: 4, // ax^n + bx^n-1 + ... + k
  POWER: 5, //  ax^b
  // VARIABLE_POWER: 6, // ax^n (requires user to input power (n))
  INVERSE: 7, // a / x
  INVERSE_SQUARED: 8, // a / x^2
  // NTH_INVERSE: 9, // a / x^n + b (requires user to input power (n))
  NATURAL_EXPONENT: 10, // a + exp(-cx) + b
  NATURAL_LOG: 11, //  a * ln(bx) -- natural log
  // BASE_10_EXPONENT: 12, //  a * 10^(bx)
  // BASE_10_LOG: 13, //  a * log(bx) -- base-10 log
  SINE: 14, //  a * sin(bx + c) + d
  COSINE_SQUARED: 15, //  a * cos(bx + c) ² + d
  // NORMALIZED_GAUSSIAN: 16, // 1 / ((sqrt(2*PI)*S) * exp(-(x-M)^2 / (2*S^2))
  // GAUSSIAN: 17, // a * exp(-(x-b)^2 / c^2) + d
  COSINE: 18, // a * cos(bx + c) + d
  // INVERSE_EXPONENT: 19, // a * (1 - exp(-cx)) + b
  // DAMPED_HARMONIC: 20, // a * exp(-bx) * sin(cx + d) + e
  CUSTOM: 21, // user-defined
};

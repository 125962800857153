import { css } from 'lit';
import { breakpoint } from '@styles/mixins/breakpoints.css.js';
import { buttonReset, bottombarButton } from '../styles/index.js';

export default css`
  ${buttonReset(css`button.sensor`)}
  .sensor, button.sensor-checkbox-btn {
    background: var(--vst-color-sensor-bg);
    border-radius: var(--vst-radius);
    border: 0;
    font-size: 1em;
  }

  button.sensor-checkbox-btn {
    padding: 0;
    color: var(--vst-color-fg-primary);
  }

  .sensor__reading {
    font-weight: bold;
    font-variant-numeric: tabular-nums;
    white-space: nowrap;
    line-height: 1;
  }

  .sensor__name {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .sensor__reading-units {
    white-space: nowrap;
  }
  .sensor .divider-symbol {
    margin-inline-end: 0.35em;
  }

  .sensor[disabled],
  .over-flow-menu[disabled] {
    cursor: default;
    pointer-events: none;
  }

  :host([metersize='medium']) .sensor__reading,
  :host([metersize='large']) .sensor__reading {
    color: var(--vst-color-fg-primary);
  }

  /* non min meters */
  div.sensor {
    display: grid;
    grid-template-rows: auto auto;
    grid-template-columns: 1fr auto;
    row-gap: var(--vst-space-2xs);
  }

  div.sensor .sensor__name {
    font-size: var(--vst-font-size-l);
    grid-row: 1 / 2;
    grid-column: 1 / 2;
  }
  div.sensor .sensor__reading {
    grid-row: 2 / 3;
    grid-column: 1 / 3;
  }
  .checkbox,
  .over-flow-menu {
    justify-self: end;
    align-self: start;
    grid-row: 1 / 2;
    grid-column: 2 / 3;
  }
  .checkbox {
    margin-inline-end: 0;
  }

  /*  specific to the large meters */
  :host([metersize='large']) {
    min-block-size: 5rem;
  }
  :host([metersize='large']) .sensor {
    width: 100%;
    padding: var(--vst-space-l);
  }

  ${breakpoint(
    'm',
    css`
      :host([metersize='large']) .sensor__name {
        font-size: var(--vst-font-size-xl);
      }
    `,
  )}

  :host([metersize='large']) .sensor__name {
    font-size: var(--vst-font-size-l);
    max-inline-size: 27em;
  }

  ${breakpoint(
    'm',
    css`
      :host([metersize='large']) .sensor__reading {
        font-size: var(--vst-font-size-2xl);
      }
    `,
  )}

  :host([metersize='large']) .sensor__reading {
    font-size: var(--vst-font-size-xl);
  }

  /*  specific to the graph/table/meter meters */
  :host([metersize='medium']) .sensor {
    padding: var(--vst-space-2xs) var(--vst-space-xs);
  }

  :host([metersize='medium']) .sensor__name {
    max-inline-size: 100%;
    color: var(--vst-color-fg-primary);
  }
  :host([metersize='medium']) .sensor__reading {
    font-size: var(--vst-font-size-xl);
    min-block-size: var(--vst-font-size-xl);
    /*  force a height so the box doesn't jump as a it goes from no reading to a reading. */
  }

  /*  specific to the bottombar meters */
  :host([metersize='mini']) {
    display: flex;
  }
  ${bottombarButton(css`:host([metersize='mini']) .sensor`)}

  :host([metersize='mini']) .sensor__name {
    max-inline-size: 14em;
    white-space: nowrap;
  }

  :host([show-name-only]) .sensor__reading {
    visibility: hidden;
  }

  :host([metersize='mini']) .sensor__reading {
    color: var(--vst-color-fg-primary);
  }

  :host([disabled]) .sensor__reading,
  :host([disabled]) .sensor__reading-units,
  :host([disabled]) .sensor__name {
    color: var(--vst-color-fg-tertiary);
  }

  :host([hidden]) {
    display: none;
  }
`;

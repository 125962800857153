import EventTarget from '@ungap/event-target';
import { autorun } from 'mobx';
import { vstPresentationStore } from '@stores/vst-presentation.store.js';
import { cloneDeep } from 'lodash-es';

/**
 * @typedef {object} AxisRange
 * @property {number} min minimum value of the range.
 * @property {number} max maximum value.
 */

export class Axis extends EventTarget {
  /**
   * An abstraction over the `scale` chart.js object
   * @param {Graph} graph the Graph instance this is associated to
   * @param {String} id the id associating the axis to the chart.js scale (examples: 'x', 'yAxis1', 'yAxis2')
   * @param {Object} options the chart.js scale options for the axis
   */
  constructor(graph, id, options /* gridOptions */) {
    super();
    this.autoscaleMode = '';
    this.axisBox = document.createElement('div');
    this.axisBtnEl = document.createElement('button');

    this.defaultOptions = {
      display: true,
      position: 'bottom',
      type: 'linear',
      axis: 'x',
      min: 0,
      max: 1,
      title: {
        display: true,
        text: 'x',
      },
      offset: false,
      grid: {
        color: ({ tick: { value } = {} }) =>
          value === 0 ? this._gridAxisColor : this._gridLineColor,
        lineWidth: options => {
          if (!options.tick) return 1;
          const {
            tick: { value },
          } = options;
          return value === 0 ? 2 : 1;
        },
        tickLength: 4,
        tickWidth: 0,
        offset: false,
      },
      ticks: {
        font: {
          family: `-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif`,
          size: 12,
        },
        color: () => this._tickLabelColor,
        includeBounds: false,
      },
    };

    console.assert(graph, 'must supply graph');

    this.graph = graph;
    this.id = id;
    this.options = { ...cloneDeep(this.defaultOptions), ...options };

    autorun(() => {
      console.assert(vstPresentationStore.colorMode);
      requestAnimationFrame(() => {
        const { coreGraphEl } = this.graph;
        const bgColor = getComputedStyle(coreGraphEl).getPropertyValue('--vst-color-bg-primary');
        const fgColor = getComputedStyle(coreGraphEl).getPropertyValue('--vst-color-fg-primary');

        this._gridLineColor = bgColor;
        this._gridAxisColor = fgColor;
        this._tickLabelColor = fgColor;
        this.graph.plot.update();
      });
    });
  }

  get name() {
    return this.options.name;
  }

  /**
   * value of 'x' or 'y'
   */
  get direction() {
    return this.options.axis;
  }

  get categorical() {
    return this.graph.categorical && this.direction === 'x';
  }

  /**
   * the range in viewport pixels
   * @returns {Object}
   */
  get rangePx() {
    return {
      min: this._dataPointSpaceToCanvasPlotSpace(this.options.min),
      max: this._dataPointSpaceToCanvasPlotSpace(this.options.max),
    };
  }

  /**
   * the offset from the top or left (depending on the axis)
   * @returns {Number}
   */
  get plotOffset() {
    return this.options.axis === 'x'
      ? this.graph.plot.scales[this.id].left
      : this.graph.plot.scales[this.id].top;
  }

  /**
   * the size of the axis labels
   * @returns {Number}
   */
  get labelSize() {
    return this.graph.scales[this.id][this.options.axis === 'x' ? 'height' : 'width'];
  }

  /**
   * range getter
   * @returns {Object}
   */
  get range() {
    return { min: this.options.min, max: this.options.max };
  }

  /**
   * rangeLength getter - the distance from the range min to the range max
   * @returns {Number}
   */
  get rangeLength() {
    return Math.abs(this.options.max - this.options.min);
  }

  get indexRange() {
    if (this.categorical) {
      // TODO: support analysis on multiple categorical data sets
      const firstDataset = this.graph.plot.data.datasets[0]?.data;
      return { min: 0, max: firstDataset.length - 1 };
    }

    console.warn(`Axis 'index' is only defined for a categorical axis`);
    return null;
  }

  /**
   * Takes in a plotted data value and returns the viewport pixel associated
   * @param {Number} value a value on the axis
   * @returns {Number}
   */
  _dataPointSpaceToCanvasPlotSpace(value) {
    const pixel = this.graph.plot.scales[this.id].getPixelForValue(value) || 0;
    return pixel;
  }

  /**
   * Takes the plotted data value and returns the viewport pixel, offset to just the plot area
   * p2c = data (p)oint space to(2) (c)anvas plot space - from legacy `flot` usage
   * @param {Number} value a value on the axis
   * @returns {Number}
   */
  p2c(value) {
    const pixel = this.graph.plot.scales[this.id].getPixelForValue(value) || 0;
    return pixel - this.plotOffset;
  }

  /**
   * Takes a viewport pixel value and translates it to a chart data value
   * @param {Number} pixel a pixel value on the screen
   * @returns {Number}
   */
  _canvasPlotSpaceToDataPointSpace(pixel) {
    const value = this.graph.plot.scales[this.id].getValueForPixel(pixel) || 0;
    return value;
  }

  /**
   * Temporarily override the Chart.js scale's range indpendent of `this.setRange()`.
   * This allows us to sub in an alternate range for c2p() et al. calculations, e.g. during pinch-to-zoom.
   * @param {Object} temporaryRange
   * @param {Number} temporaryRange.min new minimum.
   * @param {Number} temporaryRange.max new maximum.
   * @param {Function} action a synchronous action that takes one param (the axis in question). Action can call conversion routines and safely assume that temporaryRange has been applied.
   */
  overrideScaleRange(temporaryRange, action) {
    const { min, max } = temporaryRange;

    const scale = this.graph.plot.scales[this.id];
    console.assert(scale, 'Scale must be non-null.');

    // Save range:
    const savedMin = scale.min;
    const savedMax = scale.max;

    // Substitute new range.
    scale.min = min;
    scale.max = max;
    scale.configure();

    // Perform action.
    try {
      action(this);
    } catch (err) {
      console.error(err);
    }

    // Restore saved range.
    scale.min = savedMin;
    scale.max = savedMax;
    scale.configure();
  }

  /**
   * Takes a viewport pixel value, corrects for the plot offset, and translates it to a chart data value
   * c2p = (c)anvas plot space to(2) data (p)oint space - from legacy `flot` usage
   * @param {Number} pixel a pixel value on the screen
   * @returns {Number}
   */
  c2p(pixel) {
    const value = this.graph.plot.scales[this.id].getValueForPixel(pixel + this.plotOffset) || 0;
    return value;
  }

  /**
   * For a categorical axis, convert the passed point value to an index describing which category
   * the point falls into
   * p2i = plot (point) space to category (index) space
   * @param {number} point A point coordinate cooresponding to the underlying plot
   * @returns A zero-based index representing one of the categories postioned along the axis, from left-to-right
   */
  p2i(pointValue) {
    if (this.categorical) {
      // currently the plot positions categories around coordinates of the same value as category index. We round to ensure an integer.
      return Math.round(pointValue);
    }

    console.warn(`Axis 'index' is only defined for a categorical axis`);
    return null;
  }

  /**
   * For a categorical axis, convert the passed category index value to an point coordinate describing the plot
   * position of that category
   * p2i = category (index) space to(2) plot (point) space
   * @param {number} index A zero-based index representing one of the categories postioned along the axis, from left-to-right
   * @returns A point coordinate cooresponding to the underlying plot
   */
  i2p(index) {
    if (this.categorical) {
      // currently the plot positions categories around coordinates of the same value as category index
      return index;
    }

    console.warn(`Axis 'index' is only defined for a categorical axis`);
    return null;
  }

  /**
   * Determines if the given point is over the axis on the chart
   * @param {Number} point a pixel value point originating from the top left of the chart canvas
   * @returns {Boolean}
   */
  overTicks(point) {
    return this.options.axis === 'x'
      ? point < this._dataPointSpaceToCanvasPlotSpace(this.options.min)
      : point > this._dataPointSpaceToCanvasPlotSpace(this.options.min);
  }

  /**
   * a method call to return the range (DEPRECATED: use range getter)
   * @returns {Object}
   */
  getRange() {
    return this.range;
  }

  /**
   * Set the range of the axis
   * @param {Object} range the range
   * @param {Number} range.min the minimum of the range
   * @param {Number} range.max the maximum of the range (aliased to rawMax)
   * @param {boolean} [report = true] if true, will dispatch an event to notify
   * related components of the range change. If false will silently set the min
   * and max ranges.
   */
  setRange({ min, max: rawMax }, report = true) {
    const max = min === rawMax ? rawMax + 1 : rawMax;
    this.options.min = min;
    this.options.max = max;
    if (report) this.dispatchEvent(new CustomEvent('axis-range-updated', { detail: { min, max } }));
  }

  /**
   * Translate the axis range by a given delta unless it's a categorical axis
   * @param {Number} delta the change to the axis range
   * @param {boolean} [setRange=true] if true will go ahead and set the range,
   * if false, will only compute the range.
   * @returns {AxisRange} the result of translating the range by the delta.
   */
  translate(delta, setRange = true) {
    if (this.categorical) return { min: this.options.min, max: this.options.max };

    const newRange = { min: this.options.min - delta, max: this.options.max - delta };
    if (setRange) this.setRange(newRange);
    return newRange;
  }
}

import { observable, action, reaction, makeObservable } from 'mobx';

/**
 * A MobX store class containing a group of analysis stores. It provides the ability
 * to synchronize analysis between separate analysis stores.
 */
export class VstAnalysisGroupStore {
  constructor() {
    this.examineLinks = new Set();
    /** @type {Object<string, import('@common/mobx-stores/VstAnalysisStore.js').VstAnalysisStore>} */
    this.analysisStores = {};
    this.examinePositions = {};
    this.examineSettings = {};

    makeObservable(this, {
      examineLinks: observable,
      examinePositions: observable,
      examineSettings: observable,
      registerAnalysisStore: action,
      resetLinks: action,
      toggleGraphExamineLinks: action,
    });
  }

  /**
   * Delete linked selection
   *
   * @param {string} graphId Graph ID
   */
  deleteLinkedSelections(graphId) {
    Array.from(this.examineLinks)
      // Exclude the examineLink for the given graph ID
      .filter(examineGraphId => examineGraphId !== graphId)
      .forEach(examineGraphId => {
        this.analysisStores[examineGraphId].deleteTempSelection();
      });
  }

  /**
   * Reset the store examine links state
   */
  resetLinks() {
    this.examineLinks = new Set();
  }

  /**
   * Register an analysis store (e.g. for a graphs) with the group of stores
   *
   * @param {number} id Identifier for the analysis store to serve as a lookup key
   * @param {VstAnalysisStore} store Instance of the individual-graph analysis store
   */
  registerAnalysisStore(id, store) {
    reaction(
      () => store.examinePosition,
      positionValue => {
        this.examinePositions = { ...this.examinePositions, [id]: positionValue };
      },
      { fireImmediately: true },
    );

    reaction(
      () => store.examineSettings,
      settingsValue => {
        this.examineSettings = { ...this.examineSettings, [id]: settingsValue };
      },
      { fireImmediately: true },
    );

    this.analysisStores = {
      ...this.analysisStores,
      [id]: store,
    };
  }

  /**
   * Updates the examine pin position within the analysis store corresponding to 'graphId'
   * and the pin positions in all linked stores, if any
   *
   * @param {*} graphId The id of the graph for which the position update has been requested
   * @param {*} update The updates for the examine pin position change
   */
  updateExaminePosition(graphId, update = {}) {
    const graphIds = this.examineLinks.has(graphId) ? [...this.examineLinks] : [graphId];

    graphIds.forEach(graphId => {
      const analysisStore = this.analysisStores[graphId];
      analysisStore?.updateExaminePosition(update, true);
    });
  }

  /**
   * If any analysis stores are linked to the indicated graph, this updates
   * the examine pin position of those graphs
   *
   * @param {*} graphId The id of the graph for which any linked graphs will be updated
   * @param {*} update The updates for the examine pin position change of any linked graphs
   */
  updateLinkedExaminePositions(graphId, update = {}) {
    if (!this.examineLinks.has(graphId)) return;

    [...this.examineLinks].forEach(graphId => {
      const analysisStore = this.analysisStores[graphId];
      analysisStore?.updateExaminePosition(update, true);
    });
  }

  /**
   * Update selections for linked graphs
   *
   * @param {string} graphId Graph ID
   */
  updateLinkedSelections(graphId) {
    if (!this.examineLinks.has(graphId)) return;

    const tempSelection = Object.values(this.analysisStores[graphId].selections).find(
      selection => !selection.permanent,
    );

    // Create or update selection in other graphs (see handleNewSelectionGesture)
    Array.from(this.examineLinks)
      // Exclude the examineLink for the given graph ID
      .filter(examineGraphId => examineGraphId !== graphId)
      .forEach(examineGraphId => {
        if (tempSelection) {
          this.analysisStores[examineGraphId].createSelection({
            ...tempSelection,
            analysisType: '',
            highlightOnly: true,
          });
        }
      });
  }

  /**
   * This action sets state to link or unlink examine pins on separate graphs
   * If we are linking, this also updates the examine pin position to initalize the linking state
   *
   * @param {Boolean} joinState - the state of the join we are setting - false: unlink, true: link
   * @param {Array} graphIds - the graph ids that will be joined
   */
  toggleGraphExamineLinks(joinState, graphIds) {
    if (!joinState) {
      this.examineLinks = new Set();
    } else {
      this.examineLinks = new Set(graphIds);

      // NOTE: This assumes that the analysis stores are *visually* ordered by increasing id/key
      const analysisStoreKeys = Object.keys(this.analysisStores).sort();
      const graphExaminePositions = analysisStoreKeys.map(
        key => this.analysisStores[key]?.examinePosition,
      );
      const firstGraphExaminePosition = graphExaminePositions.find(graph => !graph.examineHidden);
      if (firstGraphExaminePosition) {
        this.updateExaminePosition(graphIds[0], firstGraphExaminePosition);
      }
    }
  }
}

// the singleton to be used for the main-content group of graph analyses
export const vstAnalysisGroupStore = new VstAnalysisGroupStore();

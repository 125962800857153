import { createCollection } from '../../../_internal/createCollection.js';
import { priv } from '../priv.js';
import { checkId } from '../checkId.js';
import { renderColumns } from '../render/renderColumns.js';
import { createColumn } from './createColumn.js';
import { expandColumns } from '../expandColumns.js';

export const createColumnCollection = ({
  dataSet,
  columns: configColumns,

  cellResolver,
  interactionContext,
  resolvePositions,
}) => {
  if (configColumns.length < 1) {
    throw Error(`dataSet needs at least one column.`);
  }

  const columns = createCollection();
  const checkColumnId = id => checkId(columns, id, 'column');

  const checkColumnIdWith = (id, errorMsg, match) => {
    checkColumnId(id);
    if (match(id)) {
      throw Error(errorMsg);
    }
  };

  configColumns.forEach(({ id, context, readonly, values } = {}) => {
    checkId(columns, id, 'column');
    const column = createColumn({
      id,
      context,
      readonly,
      values,
      dataSet,
      cellResolver,
      interactionContext,
      resolvePositions,
    });

    columns.append(column.id, column);
  });

  const getColspan = () => columns.length;
  const getColumns = () => columns.toList();
  const getColumnById = id => columns.findByKey(id);
  const checkIdWithArray = (id, array) =>
    checkColumnIdWith(id, `column config '${id}' has duplicate`, match => {
      const { length } = array.filter(item => item.id === match);
      return length > 1;
    });

  const appendColumns = (newColumnsConfig = []) => {
    interactionContext.clear();
    const newColumns = newColumnsConfig.map(({ id, context, readonly, values = [] } = {}) => {
      checkIdWithArray(id, newColumnsConfig);
      const column = createColumn({
        id,
        context,
        readonly,
        values,
        dataSet,
        cellResolver,
        interactionContext,
        resolvePositions,
      });
      return column;
    });

    newColumns.forEach(newColumn => columns.append(newColumn.id, newColumn));
    newColumns.forEach(newColumn => newColumn[priv].load());

    const { columns: gridColumns } = dataSet.grid;
    const maxLength = expandColumns(gridColumns);

    resolvePositions();
    renderColumns({ newColumns, dataSet, maxLength, append: true });

    return newColumns;
  };

  const insertColumns = (index = 0, newColumnsConfig = []) => {
    interactionContext.clear();
    const newColumns = newColumnsConfig.map(({ id, context, readonly, values = [] } = {}) => {
      checkIdWithArray(id, newColumnsConfig);
      if (index >= columns.length) {
        throw Error(`index ${index} for column ${id} is out of bounds.`);
      }
      const column = createColumn({
        id,
        context,
        readonly,
        values,
        dataSet,
        cellResolver,
        interactionContext,
        resolvePositions,
      });
      return column;
    });

    let currentIndex = index;
    newColumns.forEach(newColumn => {
      columns.insert(currentIndex, newColumn.id, newColumn);
      currentIndex++;
    });
    newColumns.forEach(newColumn => newColumn[priv].load());

    const { columns: gridColumns } = dataSet.grid;
    const maxLength = expandColumns(gridColumns);

    resolvePositions();
    renderColumns({ newColumns, dataSet, maxLength, index });

    return newColumns;
  };

  return {
    getColspan,
    getColumns,
    getColumnById,
    appendColumns,
    insertColumns,
    columns,
  };
};

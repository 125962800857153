/**
 * @typedef {object} GestureRangeMessage
 * @property {boolean} gestureEnding a flag indicating whether this is the
 * last gesture message in the series.
 * @property {object} [left] left axis range (aka y axis)
 * @property {number} left.min minmum range value
 * @property {number} left.max maximum range value
 * @property {object} [base] base axis range (aka x axis)
 * @property {number} base.min minmum range value
 * @property {number} base.max maximum range value
 * @property {object} [right] left axis range (right y axis)
 * @property {number} right.min minmum range value
 * @property {number} right.max maximum range value
 */

/**
 * Consolidates touch surface gesture handling into one class.
 */
export class GraphGestures {
  /**
   * Constructs an instance of GraphGestures.
   * @param {Axis} xAxis of the plot we are handling gestures for.
   * @param {Axis} yAxis of the plot we are handling gestures for.
   * @param {Axis} rightAxis of the plot we are handling gestures for [optional].
   * @param {EventTarget} touchElement the element that is receiving gestures.
   * @param {Function} gestureRangeHandler a receiver for `GestureRangeMessage`
   * objects which are generated whenever the user performs a two finger pinch
   * on the specified touchElement.
   */
  constructor(xAxis, yAxis, rightAxis, touchElement, gestureRangeHandler) {
    this._xAxis = xAxis;
    this._yAxis = yAxis;
    this._rightAxis = rightAxis;
    this._element = touchElement;
    this._handler = gestureRangeHandler;
    this.handlePinch = this.handlePinch.bind(this);

    // We don't need to worry about cancel and end, because our state will always get reset on the next `touchstart`.
    touchElement.addEventListener('touchstart', this.handlePinch);
    touchElement.addEventListener('touchmove', this.handlePinch);
    touchElement.addEventListener('touchend', this.handlePinch);
    touchElement.addEventListener('touchcancel', this.handlePinch);
  }

  /**
   * Handles pinch to zoom
   * @param {TouchEvent} event from the element that is receiving gestures.
   */
  handlePinch(event) {
    if (event.touches.length !== 2) return;

    const { _xAxis, _yAxis, _rightAxis } = this;
    const { x1, y1, x2, y2 } = this._adjustEventPositions(event);
    const { type } = event;

    // For the first event, we need to snapshot our starting ranges etc.
    if (type === 'touchstart') {
      this._xStartRange = _xAxis.range;
      this._yStartRange = _yAxis.range;

      this._startPoint1 = { x: _xAxis.c2p(x1), y: _yAxis.c2p(y1) };
      this._startPoint2 = { x: _xAxis.c2p(x2), y: _yAxis.c2p(y2) };

      // Right axis is optional
      if (_rightAxis) {
        this._rightStartRange = _rightAxis.range;
        this._rightStartY1 = _rightAxis.c2p(y1);
        this._rightStartY2 = _rightAxis.c2p(y2);
      }

      this._startingDistance = Math.hypot(
        this._startPoint2.x - this._startPoint1.x,
        this._startPoint2.y - this._startPoint1.y,
      );

      // Grab the angle made by the pinch fingers.
      let theta = Math.abs(Math.atan2(y2 - y1, x2 - x1));
      theta = theta > Math.PI / 2 ? Math.PI - theta : theta;

      const piOverEight = Math.PI / 8;
      const threePiOverEight = (3 * Math.PI) / 8;

      // Determine how we're going to constrain the pinch motion.
      if (theta >= 0 && theta < piOverEight) {
        this._pinchType = 'horizontal';
      } else if (piOverEight <= theta && theta <= threePiOverEight) {
        this._pinchType = 'diagonal';
      } else {
        this._pinchType = 'vertical';
      }
    }

    // Convert new touches into logical coordinates based on starting range.
    let newX1;
    let newY1;
    let newX2;
    let newY2;

    _xAxis.overrideScaleRange(this._xStartRange, axis => {
      newX1 = axis.c2p(x1);
      newX2 = axis.c2p(x2);
    });

    _yAxis.overrideScaleRange(this._yStartRange, axis => {
      newY1 = axis.c2p(y1);
      newY2 = axis.c2p(y2);
    });

    // Calculate scale, and then calculate the new x and y ranges.
    const scale = this._startingDistance / Math.hypot(newX2 - newX1, newY2 - newY1);

    let newXMin = this._startPoint1.x - (newX1 - this._xStartRange.min) * scale;
    let newXMax = this._startPoint2.x + (this._xStartRange.max - newX2) * scale;
    let newYMin = this._startPoint1.y - (newY1 - this._yStartRange.min) * scale;
    let newYMax = this._startPoint2.y + (this._yStartRange.max - newY2) * scale;

    // Guard against edge conditions resulting from exploding math.
    if (Number.isNaN(scale) || newXMin >= newXMax || newYMin >= newYMax) {
      return;
    }

    // Constrain pinches horizontally or vertically by reverting to starting range.
    switch (this._pinchType) {
      case 'horizontal':
        newYMin = this._yStartRange.min;
        newYMax = this._yStartRange.max;
        break;
      case 'vertical':
        newXMin = this._xStartRange.min;
        newXMax = this._xStartRange.max;
        break;
      default:
    }

    const changes = {
      gestureEnding: type === 'touchend' || type === 'touchcancel',
      base: { min: newXMin, max: newXMax },
      left: { min: newYMin, max: newYMax },
    };

    // If we've got a right Y-axis, then repeat all of the above steps for it.
    if (this._rightAxis) {
      _rightAxis.overrideScaleRange(this._rightStartRange, axis => {
        newY1 = axis.c2p(y1);
        newY2 = axis.c2p(y2);
      });

      if (this._pinchType === 'horizontal') {
        newYMin = this._rightStartRange.min;
        newYMax = this._rightStartRange.max;
      } else {
        newYMin = this._rightStartY1 - (newY1 - this._rightStartRange.min) * scale;
        newYMax = this._rightStartY2 + (this._rightStartRange.max - newY2) * scale;
      }
      changes.right = { min: newYMin, max: newYMax };
    }

    this._handler(changes);
  }

  /**
   * Convert touch positions into coordinates local to the target element
   * @param {Touch} event
   * @returns {Object} {x1, y1, x2, y2}
   */
  _adjustEventPositions(event) {
    const [touch1, touch2] = event.touches;
    const { top, left } = this._element.getBoundingClientRect();

    return {
      x1: touch1.pageX - left,
      y1: touch1.pageY - top,
      x2: touch2.pageX - left,
      y2: touch2.pageY - top,
    };
  }
}
